import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import { Routes } from '@/utils/routeUtils';
import HospitalsEdit from './HospitalsEdit';
import HospitalsIndex from './HospitalsIndex';
import HospitalsNew from './HospitalsNew';
import HospitalsShow from './HospitalsShow';

const Hospitals = () => {
  return (
    <Protected allowedRoles={PolicyGroups.nonBillingAdmins}>
      <Switch>
        <Route exact path={Routes.hospitals} component={HospitalsIndex} />
        <Route path={Routes.hospitalsHealthSystem} component={HospitalsNew} />
        <Route
          path={Routes.hospitalsEditHealthSystem}
          component={HospitalsEdit}
        />
        <Route path={Routes.hospitalsNew} component={HospitalsNew} />
        <Route path={Routes.hospitalsEdit} component={HospitalsEdit} />
        <Route path={Routes.hospitalsShow} component={HospitalsShow} />
      </Switch>
    </Protected>
  );
};

export default Hospitals;
