import React, { useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { setCurrentCarePlan } from '@/actions/reducers/bhi';
import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import EnrollmentActions from '@/contexts/enrollment/components/EnrollmentActions';
import UnsavedConfirmationModal from '@/contexts/modals/UnsavedConfirmationModal';
import { isEnrollmentPath } from '@/contexts/patients/utils/patientUtils';
import { getSelectedPatient } from '@/selectors/api';
import { CarePlan } from '@/types/care_plan';
import { Attributable, Nullable, SideEffect, UUID } from '@/types/generic';
import { parseErrorData, parseResponseData } from '@/utils/apiUtils';
import { arraysMatch } from '@/utils/arrayUtils';
import { timePeriodUnitDropdowns } from '@/utils/dropdownUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from './Checkbox';
import Input from './Input';
import Select from './Select';

interface Group {
  name: string;
  options: Option[];
}

interface Option {
  type?: string;
  label: string;
  value: string;
}

interface Props {
  carePlan: Nullable<CarePlan>;
  formatBody?: (options: any) => object;
  isSubmitDisabled?: boolean;
  newOptionFields: Attributable;
  onChange: SideEffect;
  onSuccess?: SideEffect;
  options: Group[];
  originalSelectedIds: any[];
  saveText?: string;
  selectedIds: any[];
  selectionParentIdKey: string;
  shouldHideGroupName?: boolean;
}

const BhiEntryChecklist = (props: Props) => {
  const {
    carePlan,
    formatBody,
    isSubmitDisabled,
    newOptionFields,
    onChange,
    onSuccess,
    options,
    originalSelectedIds,
    saveText,
    selectedIds,
    selectionParentIdKey,
    shouldHideGroupName,
  } = props;

  const dispatch = useDispatch();

  const history = useHistory();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState<boolean>(
    false,
  );
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [navigationPath, setNavigationPath] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmittingBack, setIsSubmittingBack] = useState<boolean>(false);

  const patient = useSelector(getSelectedPatient);

  if (!options) {
    return null;
  }

  const isFormUpdated = !arraysMatch(selectedIds, originalSelectedIds);

  const body = formatBody(selectedIds);

  const isEnrollment = isEnrollmentPath();

  const defaultSaveText = isEnrollment ? 'Save and Next' : 'Save';

  const handleSuccess = (res: CarePlan) => {
    setIsSuccessful(true);
    dispatch(setCurrentCarePlan(res));

    if (!isEnrollment) {
      dispatch(flashSuccess('Patient updated'));
    } else if (onSuccess) {
      onSuccess(res);
    }
  };

  const handleSuccessAndBack = (res: CarePlan) => {
    setIsSuccessful(true);
    dispatch(setCurrentCarePlan(res));

    history.push(buildPath(Routes.patientsEnrollmentIndex, { id: patient.id }));
  };

  const saveForm = async () => {
    setIsSubmitting(true);

    let response = null;

    try {
      if (carePlan) {
        const url = `api/v1/care_plans/${carePlan.data.id}`;
        response = await Api.utility.patch(url, body);
      } else {
        const url = 'api/v1/care_plans';
        response = await Api.utility.post(url, body);
      }

      setIsSubmitting(false);
      handleSuccess(parseResponseData(response));
    } catch (err) {
      setIsSubmitting(false);
      dispatch(flashError(parseErrorData(err)));
    }
  };

  const saveFormAndBack = async () => {
    setIsSubmittingBack(true);

    let response = null;

    try {
      if (carePlan) {
        const url = `api/v1/care_plans/${carePlan.id}`;
        response = await Api.utility.patch(url, body);
      } else {
        const url = 'api/v1/care_plans';
        response = await Api.utility.post(url, body);
      }

      setIsSubmittingBack(false);
      handleSuccessAndBack(parseResponseData(response));
    } catch (err) {
      setIsSubmittingBack(false);
      dispatch(flashError(parseErrorData(err)));
    }
  };

  const handleCloseModal = () => {
    setNavigationPath(null);
    setIsConfirmationVisible(false);
  };

  const handleNavigate = () => {
    history.push(navigationPath);
  };

  const handleSaveConfirm = () => {
    saveForm();
  };

  const handlePromptWarning = location => {
    if (isSuccessful || !isFormUpdated || isConfirmationVisible) {
      return true;
    }

    setIsConfirmationVisible(true);
    setNavigationPath(location.pathname);

    return false;
  };

  const handleToggleInput = (value: string, isTimePeriod?: boolean) => {
    const index = selectedIds.findIndex(
      option => option[selectionParentIdKey] === value,
    );

    if (index !== -1) {
      const destroyValue = selectedIds[index]._destroy;

      if (destroyValue === undefined) {
        onChange(
          selectedIds.filter(option => option[selectionParentIdKey] !== value),
        );
      } else {
        const updatedOptions = [...selectedIds];
        updatedOptions[index]._destroy = true;

        onChange(updatedOptions);
      }
    } else {
      const newSelection = {
        [selectionParentIdKey]: value,
        ...newOptionFields,
      };

      if (isTimePeriod) {
        newSelection.time_period_unit = 'days';
        newSelection.time_period_value = '';
      }

      onChange([...selectedIds, newSelection]);
    }
  };

  const handleEditCheckboxValue = (
    value: any,
    selectionId: UUID,
    field: string,
  ): void => {
    const updatedOptions = [...selectedIds];
    const index = updatedOptions.findIndex(
      o => o[selectionParentIdKey] === selectionId,
    );

    updatedOptions[index][field] = value;

    onChange(updatedOptions);
  };

  const renderTextInput = (option, selectedOption) => {
    if (!!selectedOption && option.type === 'free_text') {
      return (
        <div className="form-checklist__textarea">
          <textarea
            onChange={event =>
              handleEditCheckboxValue(
                event.target.value,
                selectedOption[selectionParentIdKey],
                'text',
              )
            }
            value={selectedOption.text || ''}
          />
        </div>
      );
    }
  };

  const renderOptionLabel = (option, selectedOption) => {
    if (option.type === 'time_period') {
      const inputs = (() => {
        if (selectedOption) {
          return (
            <div className="form-checkbox__inputs">
              <Input
                onChange={value =>
                  handleEditCheckboxValue(
                    value,
                    selectedOption[selectionParentIdKey],
                    'time_period_value',
                  )
                }
                type="number"
                value={selectedOption?.time_period_value || ''}
              />
              <Select
                onChange={value =>
                  handleEditCheckboxValue(
                    value,
                    selectedOption[selectionParentIdKey],
                    'time_period_unit',
                  )
                }
                options={timePeriodUnitDropdowns}
                value={selectedOption?.time_period_unit || ''}
              />
            </div>
          );
        } else {
          return (
            <div className="form-checkbox__inputs">
              <Input isDisabled value={null} />
              <Select
                isDisabled
                options={timePeriodUnitDropdowns}
                value={null}
              />
            </div>
          );
        }
      })();

      const children = option.label
        .split('--TIMEPERIOD--')
        .map((v: string, i: number) => <span key={`${v}_${i}`}>{v}</span>);

      children.splice(1, 0, inputs);

      return children;
    } else {
      return <span>{option.label}</span>;
    }
  };

  const renderGroup = (group: Group) => {
    return (
      <div className="column is-6" key={group.name}>
        <div className="form-checklist__group">
          {shouldHideGroupName || <h5>{group.name}</h5>}
          <div className="form-checkslist__group-options">
            {group.options.map(option => {
              const selectedOption = selectedIds.find(
                o => o[selectionParentIdKey] === option.value && !o._destroy,
              );

              const isTimePeriod = option.type === 'time_period';

              return (
                <div className="form-checklist__option" key={option.value}>
                  <label
                    className="form-checkbox-wrapper"
                    onClick={
                      !isTimePeriod
                        ? () => handleToggleInput(option.value)
                        : null
                    }>
                    <Checkbox
                      onClick={
                        isTimePeriod
                          ? () => handleToggleInput(option.value, true)
                          : null
                      }
                      isChecked={!!selectedOption}
                    />
                    {renderOptionLabel(option, selectedOption)}
                  </label>

                  {renderTextInput(option, selectedOption)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="form-checklist">
        <div className="columns is-multiline">
          {options.map((group: Group) => {
            return renderGroup(group);
          })}
        </div>
      </div>

      <EnrollmentActions>
        <Button
          color="secondary"
          isDisabled={isSubmitDisabled}
          isSubmitting={isSubmitting || isSubmittingBack}
          onClick={saveForm}>
          {saveText || defaultSaveText}
        </Button>

        {isEnrollment && (
          <Button
            color="white"
            isDisabled={isSubmitDisabled}
            isSubmitting={isSubmitting || isSubmittingBack}
            onClick={saveFormAndBack}>
            Save And Back To Sections List
          </Button>
        )}
      </EnrollmentActions>

      <Prompt message={handlePromptWarning} />

      <UnsavedConfirmationModal
        isVisible={isConfirmationVisible}
        onClose={handleCloseModal}
        onNavigate={handleNavigate}
        onSave={handleSaveConfirm}
      />
    </>
  );
};

export default BhiEntryChecklist;
