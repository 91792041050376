import React from 'react';
import { useParams } from 'react-router-dom';

import BackLink from '@/components/button/BackLink';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import EnrollmentForm from '@/contexts/enrollment/components/EnrollmentForm';
import EnrollmentSidebar from '@/contexts/enrollment/components/EnrollmentSidebar';
import PatientHeader from '@/contexts/patients/components/PatientHeader';
import { buildPath, Routes } from '@/utils/routeUtils';
import EnrollmentBanner from '../components/EnrollmentBanner';
import { IdParams } from '@/types/params';

const EnrollmentIndex = () => {
  const { id } = useParams<IdParams>();

  const enrollmentPath = buildPath(Routes.patientsEnrollmentIndex, { id });
  const patientPath = buildPath(Routes.patientsCareManagement, { id });

  return (
    <>
      <div className="is-hidden-touch">
        <Container>
          <BackLink to={patientPath}>Back to Patient</BackLink>
        </Container>

        <PatientHeader />
      </div>

      <div className="is-hidden-desktop">
        <BackLink to={enrollmentPath}>Back to Sections List</BackLink>
      </div>

      <div id="scroller" />

      <div className="is-hidden-touch">
        <EnrollmentBanner />
      </div>

      <Panel>
        <div className="columns">
          <div className="column is-2 is-hidden-touch">
            <EnrollmentSidebar />
          </div>

          <div className="column is-10">
            <EnrollmentForm />
          </div>
        </div>
      </Panel>
    </>
  );
};

export default EnrollmentIndex;
