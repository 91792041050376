import React from 'react';
import { useParams } from 'react-router-dom';

import Panel from '@/components/panel/Panel';
import Barriers from '@/contexts/enrollment/modules/Barriers';
import Conditions from '@/contexts/enrollment/modules/Conditions';
import Goals from '@/contexts/enrollment/modules/Goals';
import Outcomes from '@/contexts/enrollment/modules/Outcomes';
import Symptoms from '@/contexts/enrollment/modules/Symptoms';
import Tracking from '@/contexts/enrollment/modules/Tracking';
import PatientLayout from '../components/PatientLayout';
import { ResourceParams } from '@/types/params';

const resources = {
  conditions: Conditions,
  barriers: Barriers,
  symptoms: Symptoms,
  goals: Goals,
  outcomes: Outcomes,
  metrics: Tracking,
};

const Resource = () => {
  const { resource } = useParams<ResourceParams>();

  const renderTabContent = () => {
    const component = resources[resource];

    if (!component) {
      throw new Error('Invalid resource component');
    }

    return React.createElement(resources[resource]);
  };

  return (
    <PatientLayout>
      <Panel>{renderTabContent()}</Panel>
    </PatientLayout>
  );
};

export default Resource;
