import React from 'react';

import Constraint from '@/components/constraint/Constraint';
import Panel from '@/components/panel/Panel';

interface Props {
  content: string;
  title: string;
}

const StaticPage = (props: Props) => {
  const { content, title } = props;

  const LOGO_ICON = require('assets/images/logo/logo-icon.png');

  return (
    <Panel>
      <Constraint size="regular">
        <div className="columns">
          <div className="column">
            <div className="static-page">
              <div className="static-page__header">
                <div className="static-page__logo">
                  <img
                    className="footer__icon"
                    src={LOGO_ICON}
                    alt="HealthClix logo"
                  />
                </div>

                <h2>{title}</h2>
              </div>

              <span className="static-page__content">{content}</span>
            </div>
          </div>
        </div>
      </Constraint>
    </Panel>
  );
};

export default StaticPage;
