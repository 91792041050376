import { createSelector } from 'reselect';

import { AppState } from '@/types/app';
import { ErrorState } from '@/types/errors';

export const selectErrorState = (state: AppState): ErrorState => state.errors;

export const selectErrorCode = createSelector(
  selectErrorState,
  state => state.errorCode,
);

export const selectHasError = createSelector(
  selectErrorCode,
  state => state !== null,
);
