import React from 'react';

import Button from '@/components/button/Button';
import InputGroup from '@/components/form/InputGroup';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Panel from '@/components/panel/Panel';
import { Validations } from '@/types/form';
import { SideEffect } from '@/types/generic';
import { stateOptions, statusOptions } from '@/utils/dropdownUtils';

interface Props {
  addresses: any;
  onUpdateAddressField: SideEffect;
  renderRemoveButton?: boolean;
  addressValidations: Validations[];
}

const AddressPanel = (props: Props) => {
  const {
    addresses,
    onUpdateAddressField,
    renderRemoveButton,
    addressValidations,
  } = props;

  const renderLocationPanels = () => {
    return addresses.map((address, index: number) => {
      if (address._destroy) {
        return null;
      }

      const validations = addressValidations[index] || {};

      return (
        <Panel key={index} withNarrowMargin>
          <Section title="Location">
            <div className="columns is-mobile">
              <div className="column is-6-tablet is-4-desktop">
                <InputGroup
                  label="Location Label"
                  onChange={value =>
                    onUpdateAddressField(index, 'label', value)
                  }
                  placeholder="Enter location label"
                  value={address.label}
                />
              </div>
            </div>

            <div className="columns is-mobile">
              <div className="column is-6-tablet is-4-desktop">
                <SelectGroup
                  label="Status"
                  onChange={value =>
                    onUpdateAddressField(index, 'status', value)
                  }
                  options={statusOptions}
                  placeholder="Select Status"
                  value={address.status}
                />
              </div>
            </div>

            <div className="columns is-mobile">
              <div className="column is-6-tablet is-4-desktop">
                <InputGroup
                  label="Street Address"
                  onChange={value =>
                    onUpdateAddressField(index, 'line_1', value)
                  }
                  placeholder="Enter street address"
                  validationMessage={validations.line_1}
                  value={address.line_1}
                />
              </div>
            </div>

            <div className="columns is-mobile">
              <div className="column is-6-tablet is-4-desktop">
                <InputGroup
                  label="Address Line 2"
                  onChange={value =>
                    onUpdateAddressField(index, 'line_2', value)
                  }
                  placeholder="Enter apt, suite, etc."
                  value={address.line_2}
                />
              </div>
            </div>

            <div className="columns is-mobile">
              <div className="column is-6-tablet is-4-desktop">
                <InputGroup
                  label="City"
                  onChange={value => onUpdateAddressField(index, 'city', value)}
                  placeholder="Enter City"
                  validationMessage={validations.city}
                  value={address.city}
                />
              </div>
            </div>

            <div className="columns is-mobile">
              <div className="column is-6-mobile is-3-tablet is-2-desktop">
                <SelectGroup
                  label="State"
                  onChange={value =>
                    onUpdateAddressField(index, 'state', value)
                  }
                  options={stateOptions}
                  validationMessage={validations.state}
                  value={address.state}
                  placeholder="Select State"
                />
              </div>

              <div className="column is-6-mobile is-3-tablet is-2-desktop">
                <InputGroup
                  label="Zip Code"
                  onChange={value =>
                    onUpdateAddressField(index, 'zip_code', value)
                  }
                  placeholder="Enter Zip Code"
                  type="zipcode"
                  validationMessage={validations.zip_code}
                  value={address.zip_code}
                />
              </div>
            </div>

            {renderRemoveButton && (
              <div className="hospital-new__remove-location-wrapper">
                <Button
                  style="delete"
                  onClick={() => onUpdateAddressField(index, '_destroy', true)}>
                  Remove Location
                </Button>
              </div>
            )}
          </Section>
        </Panel>
      );
    });
  };
  return renderLocationPanels();
};

export default AddressPanel;
