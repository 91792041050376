import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ImmunizationsEdit from '@/contexts/patients/modules/immunizations/ImmunizationsEdit';
import ImmunizationsIndex from '@/contexts/patients/modules/immunizations/ImmunizationsIndex';
import ImmunizationsNew from '@/contexts/patients/modules/immunizations/ImmunizationsNew';
import { Routes } from '@/utils/routeUtils';

const Patients = () => {
  return (
    <Switch>
      <Route
        path={Routes.patientsImmunizationsEdit}
        component={ImmunizationsEdit}
      />
      <Route
        path={Routes.patientsImmunizationsNew}
        component={ImmunizationsNew}
      />
      <Route
        path={Routes.patientsImmunizations}
        component={ImmunizationsIndex}
      />
    </Switch>
  );
};

export default Patients;
