import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setCurrentCarePlan } from '@/actions/reducers/bhi';
import Button from '@/components/button/Button';
import Container from '@/components/container/Container';
import {
  getHasPatientConsented,
  getSelectedPatient,
  getSelectedPatientEnrollmentStatus,
  selectIsSelectedPatientEnrolledBhi,
  selectIsSelectedPatientEnrolledRpm,
  selectIsSelectedPatientEnrolledStandard,
} from '@/selectors/api';
import {
  selectCurrentCarePlanId,
  selectCurrentCarePlanStatus,
} from '@/selectors/bhi';
import usePutRequest from '@/utils/api/usePutRequest';
import { buildPath, Routes } from '@/utils/routeUtils';

const EnrollmentBanner = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const bhiCarePlanStatus = useSelector(selectCurrentCarePlanStatus);
  const carePlanId = useSelector(selectCurrentCarePlanId);
  const enrollmentStatus = useSelector(getSelectedPatientEnrollmentStatus);
  const isEnrolledBhi = useSelector(selectIsSelectedPatientEnrolledBhi);
  const isEnrolledStandard = useSelector(
    selectIsSelectedPatientEnrolledStandard,
  );
  const isEnrolledRpm = useSelector(selectIsSelectedPatientEnrolledRpm);
  const hasPatientConsented = useSelector(getHasPatientConsented);
  const patient = useSelector(getSelectedPatient);

  const body = {
    enrollment_step: 'complete',
  };

  const carePlanBody = {
    care_plan: {
      status: 'complete',
    },
  };

  const handleEnrollSuccess = () => {
    const careManagementPath = buildPath(Routes.patientsCareManagement, {
      id: patient.id,
    });

    const bhiPath = isEnrolledStandard
      ? buildPath(Routes.patientsCareManagement, { id: patient.id })
      : buildPath(Routes.patientsBhiPlan, { id: patient.id });

    if (
      patient.attributes.is_enrolled_ccm ||
      patient.attributes.is_enrolled_rpm
    ) {
      history.push(careManagementPath);
    } else {
      history.push(bhiPath);
    }
  };

  const handleCarePlanSuccess = () => {
    dispatch(setCurrentCarePlan(null));
    handleEnrollSuccess();
  };

  const [completeEnrollment, isSubmitting] = usePutRequest({
    dispatch,
    url: `api/v1/patients/${patient.id}`,
    body,
    dispatchToStore: true,
    key: 'selectedPatient',
    onSuccess: handleEnrollSuccess,
  });

  // Submits final update for care plan
  const [completeCarePlan, isSubmittingCarePlan] = usePutRequest({
    dispatch,
    url: `api/v1/care_plans/${carePlanId}`,
    body: carePlanBody,
    onSuccess: handleCarePlanSuccess,
  });

  const handleClickComplete = () => {
    if (isEnrolledStandard) {
      completeEnrollment();
    }

    if (isEnrolledBhi) {
      completeCarePlan();
    }
  };

  const handleClickFinishLater = () => {
    history.push(Routes.patients);
  };

  const headerText = (() => {
    return enrollmentStatus === 'complete' ? 'Care Plan' : 'Initial Care Plan';
  })();

  const isButtonDisabled = (() => {
    if (!isEnrolledBhi) {
      if (isEnrolledRpm && hasPatientConsented) {
        return false;
      } else {
        // If only enrolled standard, can submit when enrollment is complete
        return enrollmentStatus !== 'ready_to_submit';
      }
    } else if (isEnrolledStandard) {
      // If enrolled standard and BHI, both enrollment and care plan must be complete
      return (
        bhiCarePlanStatus !== 'ready_to_submit' &&
        enrollmentStatus !== 'ready_to_submit'
      );
    } else {
      // Otherwise (BHI only) patient needs to only have care plan
      return bhiCarePlanStatus !== 'ready_to_submit';
    }
  })();

  if (enrollmentStatus === 'complete' && !isEnrolledBhi) {
    return null;
  }

  return (
    <Container>
      <div className="enrollment-banner">
        <div className="enrollment-banner__left">
          <h4>{headerText}</h4>
        </div>

        <div className="enrollment-banner__right">
          <Button onClick={handleClickFinishLater}>Finish Later</Button>
          <Button
            color="secondary"
            isDisabled={isButtonDisabled}
            isSubmitting={isSubmitting || isSubmittingCarePlan}
            onClick={handleClickComplete}>
            Complete Plan
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default EnrollmentBanner;
