import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import { FormComponentConfig } from '@/types/form';
import useDelRequest from '@/utils/api/useDelRequest';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { stateOptions, statusOptions } from '@/utils/dropdownUtils';
import { flattenIncludes } from '@/utils/responseUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import HealthSystemForm from './components/HealthSystemForm';
import { IdParams } from '@/types/params';

const HealthSystemsEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { id } = useParams<IdParams>();

  const [response, setResponse] = useState(null);

  const backPath = buildPath(Routes.healthSystems);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'health_systems',
    onSuccess: (res: any) => {
      const responseData = parseResponseData(res);
      setResponse(flattenIncludes(responseData, ['address']));
    },
  });

  const handleDeleteSuccess = () => {
    const path = buildPath(Routes.healthSystems);

    history.push(path);
  };

  const [deleteHealthSystem] = useDelRequest({
    dispatch,
    onSuccess: handleDeleteSuccess,
    url: `api/v1/health_systems/${id}`,
  });

  const handleDeleteHealthSystem = () => {
    deleteHealthSystem();
  };

  useEffect(() => {
    getEntry();
  }, []);

  const alterForm = (form: any) => {
    const { line_1, line_2, city, state, zip_code, ...formFields } = form;

    return {
      ...formFields,
      address_attributes: {
        line_1,
        line_2,
        city,
        state,
        zip_code,
      },
    };
  };

  const initialUrl = () => {
    const url = response.attributes.logo_thumbnail_url;
    return url || '';
  };

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      alterFormBeforeSubmit: alterForm,
      deleteText: 'Delete Health System',
      path: `api/v1/health_systems/${id}`,
      model: 'health_system',
      method: 'PUT',
      onDelete: handleDeleteHealthSystem,
      onSuccess: () => {
        history.push(backPath);
      },
      onCancel: () => history.push(backPath),
      submitText: 'Save',
    },
    state: {
      line_1: '',
      line_2: '',
      city: '',
      state: '',
      zip_code: '',
      image_id: '',
      ...response.attributes,
    },
    rules: {
      name: { required: true },
      phone_number: { required: true, type: 'phone' },
      status: { required: true },
    },
    layout: [
      {
        title: 'Health System Details',
        renderPanel: true,
        fields: [
          [
            {
              label: 'Health System Name',
              field: 'name',
              placeholder: 'Enter Health System Name',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Phone Number',
              field: 'phone_number',
              placeholder: 'Enter phone number',
              type: 'phonenumber',
              size: 4,
            },
          ],
          [
            {
              label: 'Status',
              field: 'status',
              placeholder: 'Select status',
              type: 'select',
              options: statusOptions,
              size: 4,
            },
          ],
          [
            {
              label: 'Logo',
              field: 'image_id',
              type: 'image',
              size: 4,
              initialUrl: initialUrl(),
            },
          ],
        ],
      },
      {
        title: 'Address',
        renderPanel: true,
        fields: [
          [
            {
              label: 'Street Address',
              field: 'line_1',
              placeholder: 'Enter street address',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Address Line 2',
              field: 'line_2',
              placeholder: 'Enter address',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'City',
              field: 'city',
              placeholder: 'Enter city',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'State',
              field: 'state',
              placeholder: 'Select state',
              type: 'select',
              options: stateOptions,
              size: 2,
            },
            {
              label: 'Zip Code',
              field: 'zip_code',
              placeholder: 'Enter zip code',
              type: 'zipcode',
              size: 2,
            },
          ],
        ],
      },
    ],
  };

  return (
    <div className="health-system-new">
      <HealthSystemForm
        backPath={backPath}
        backText="Cancel"
        config={config}
        removePanel={true}
      />
    </div>
  );
};

export default HealthSystemsEdit;
