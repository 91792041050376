import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Container from '@/components/container/Container';
import ErrorMessage from '@/components/form/ErrorMessage';
import InputGroup from '@/components/form/InputGroup';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Page from '@/components/page/Page';
import Panel from '@/components/panel/Panel';
import { Validations } from '@/types/form';
import { Level } from '@/types/permission';
import usePostRequest from '@/utils/api/usePostRequest';
import { parseErrorData } from '@/utils/apiUtils';
import { updateArrayIndex } from '@/utils/arrayUtils';
import { stateOptions } from '@/utils/dropdownUtils';
import { validate } from '@/utils/form';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useDispatch } from 'react-redux';
import RoleAndPermissions from './components/RoleAndPermissions';
import { Role } from './components/RolePanel';

const EMPTY_ROLE = {
  level: 'standard' as Level,
  ids: [],
};

const FORM_STATE = {
  city: '',
  email: '',
  first_name: '',
  last_name: '',
  line_1: '',
  line_2: '',
  phone_number_extension: '',
  state: '',
  zip_code: '',
  roles: [EMPTY_ROLE],
};

const UsersNew = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});

  const handleFailure = (response: any) => {
    const errorMessage = parseErrorData(response);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const handleSuccess = () => {
    dispatch(flashSuccess('User invited'));
    history.push(Routes.users);
  };

  const backPath = buildPath(Routes.users);

  const formatBody = () => {
    return {
      care_manager: {
        email: form.email,
        first_name: form.first_name,
        last_name: form.last_name,
        phone_number_extension: form.phone_number_extension,
        address_attributes: {
          city: form.city,
          line_1: form.line_1,
          line_2: form.line_2,
          state: form.state,
          zip_code: form.zip_code,
        },
      },
      roles: form.roles,
    };
  };

  const rules = {
    city: { required: true },
    email: { required: true, type: 'email' },
    first_name: { required: true },
    last_name: { required: true },
    line_1: { required: true },
    state: { required: true },
    zip_code: { required: true },
    phone_number_extension: { required: true },
  };

  const [submitForm, isSubmitting] = usePostRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: 'api/v1/care_managers',
  });

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);

    if (isValid) {
      setValidations({});
    } else {
      return setValidations(validationMessages);
    }

    submitForm();
  };

  const handleCreateNewRole = () => {
    setForm({
      ...form,
      roles: [...form.roles, EMPTY_ROLE],
    });
  };

  const handleUpdateRole = (index: number, role: Role) => {
    const updatedForm = {
      ...form,
      roles: updateArrayIndex(form.roles, index, role),
    };

    setForm(updatedForm);
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  return (
    <Page>
      <Container>
        <BackLink to={backPath}>Cancel</BackLink>
      </Container>

      <Panel>
        <Container>
          <Section title="Profile Details">
            <div className="columns">
              <div className="column is-4">
                <InputGroup
                  label="First Name"
                  onChange={value => updateField('first_name', value)}
                  placeholder="First name"
                  validationMessage={validations.first_name}
                  value={form.first_name}
                />
              </div>

              <div className="column is-4">
                <InputGroup
                  label="Last Name"
                  onChange={value => updateField('last_name', value)}
                  placeholder="Last name"
                  validationMessage={validations.last_name}
                  value={form.last_name}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-4">
                <InputGroup
                  label="Email Address"
                  onChange={value => updateField('email', value)}
                  placeholder="Email"
                  validationMessage={validations.email}
                  value={form.email}
                />
              </div>

              <div className="column is-4">
                <InputGroup
                  label="Extension"
                  onChange={value =>
                    updateField('phone_number_extension', value)
                  }
                  placeholder="Extension"
                  type="phonenumber"
                  validationMessage={validations.phone_number_extension}
                  value={form.phone_number_extension}
                />
              </div>
            </div>
          </Section>
        </Container>
      </Panel>

      <RoleAndPermissions
        roles={form.roles}
        onClickAddRole={handleCreateNewRole}
        onUpdateRole={handleUpdateRole}
      />

      <Panel>
        <Container>
          <Section title="Address">
            <div className="columns">
              <div className="column is-4">
                <InputGroup
                  label="Street Address"
                  onChange={value => updateField('line_1', value)}
                  placeholder="Street Address"
                  validationMessage={validations.line_1}
                  value={form.line_1}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-4">
                <InputGroup
                  label="Address Line 2"
                  onChange={value => updateField('line_2', value)}
                  placeholder="Address Line 2"
                  validationMessage={validations.line_2}
                  value={form.line_2}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-4">
                <InputGroup
                  label="City"
                  onChange={value => updateField('city', value)}
                  placeholder="City"
                  validationMessage={validations.city}
                  value={form.city}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-2">
                <SelectGroup
                  label="State"
                  onChange={value => updateField('state', value)}
                  options={stateOptions}
                  validationMessage={validations.state}
                  value={form.state}
                />
              </div>

              <div className="column is-2">
                <InputGroup
                  label="Zip Code"
                  onChange={value => updateField('zip_code', value)}
                  placeholder="Zip Code"
                  type="zipcode"
                  validationMessage={validations.zip_code}
                  value={form.zip_code}
                />
              </div>
            </div>
          </Section>
        </Container>
      </Panel>

      <Container>
        <ErrorMessage isRightSide message={error} />

        <div className="form__actions">
          <div className="form__actions-left" />
          <div className="form__actions-right">
            <Button color="white" onClick={handleClickCancel}>
              Cancel
            </Button>

            <Button
              color="secondary"
              isSubmitting={isSubmitting}
              onClick={handleClickSubmit}>
              Save
            </Button>
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default UsersNew;
