import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import ModalContent from '@/components/modal/ModalContent';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { formatShortDateTime } from '@/utils/dateUtils';
import { buildPath } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';

interface Props {
  alertId: string;
}

const AlertsModal = (props: Props) => {
  const { alertId } = props;

  const dispatch = useDispatch();

  const [alert, setAlert] = useState(null);

  const [getAlert, isAlertLoading] = useGetRequest({
    dispatch,
    model: 'alerts',
    url: buildPath(
      'api/v1/alerts/:id',
      { id: alertId },
      {
        include: 'device',
      },
    ),
    onSuccess: (response: any) => {
      setAlert(parseResponseData(response));
    },
  });

  useEffect(() => {
    if (alertId) {
      getAlert();
    }
  }, [alertId]);

  if (!alert || isAlertLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className="patient-remote-care__modal-wrapper">
      <h3 className="patient-remote-care__modal-header">Alert</h3>
      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Date"
            value={formatShortDateTime(alert.attributes.created_at)}
          />
        </div>
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Device"
            value={alert.attributes.device.serial_number}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent label="Value" value={alert.attributes.value} />
        </div>
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Visible to Patient"
            value={titleizeSnakeCase(alert.attributes.visible_to_patient)}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertsModal;
