import React from 'react';

import { UUID } from '@/types/generic';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import AppointmentCard from './AppointmentCard';

interface Props {
  appointments: VirtualAppointment[];
  handleClickAppointment: (appointment: VirtualAppointment) => void;
  handleClickCompleteQuestionnaire: (appointmentId: UUID) => void;
  handleClickConfirmAppointment: (appointment: VirtualAppointment) => void;
  title: string;
}

const AppointmentSection = (props: Props): JSX.Element => {
  const {
    appointments,
    handleClickAppointment,
    handleClickCompleteQuestionnaire,
    handleClickConfirmAppointment,
    title,
  } = props;

  return (
    <div className="columns">
      <div className="column">
        <div className="appointment-section">
          <div className="appointment-section__top">
            <h5 className="appointment-section__title">{title}</h5>
          </div>

          <div className="appointment-section__card-container">
            {appointments.map((appointment: VirtualAppointment) => (
              <AppointmentCard
                appointment={appointment}
                key={appointment.id}
                onCompleteQuestionnaire={handleClickCompleteQuestionnaire}
                onConfirmAppointment={handleClickConfirmAppointment}
                onViewDetails={handleClickAppointment}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentSection;
