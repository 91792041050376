import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Api from '@/api/Api';
import { getCurrentUser } from '@/selectors/users';
import { Practice } from '@/types/v2/practice';
import { Specialist } from '@/types/v2/specialist';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import PracticeHeader from './PracticeHeader';
import PracticeSidebar from './PracticeSidebar';

interface Props {
  children: ReactNode;
}

const PracticeLayout = (props: Props) => {
  const { children } = props;

  const loader = useLoadingState('appointment');
  const notifier = useNotifier();

  const user = useSelector(getCurrentUser);

  const specialist = useMemo(() => {
    return new Modeler<Specialist>(user).build();
  }, [user]);

  const [practice, setPractice] = useState<Practice>(null);

  const getPractice = async (): Promise<void> => {
    loader.startLoading('practice');

    try {
      const url = buildPath(
        Routes.api2.practice,
        { id: specialist.practice_id },
        null,
        ['hospital', 'health_system', 'addresses'],
      );
      const response = await Api.utility.get(url);
      const resource = new Modeler<Practice>(response.data, {
        generations: 2,
      }).build();

      setPractice(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('practice');
  };

  useEffect(() => {
    if (user) {
      getPractice();
    }
  }, [user]);

  if (loader.isLoading('practice') || !practice) {
    return <loader.activity />;
  }

  return (
    <>
      <PracticeHeader practice={practice} />

      <div className="patient-layout">
        <div className="columns is-multiline">
          <div className="column is-full-touch is-2 has-no-vertical-padding-touch">
            <PracticeSidebar />
          </div>

          <div className="column is-full-touch is-10">{children}</div>
        </div>
      </div>
    </>
  );
};

export default PracticeLayout;
