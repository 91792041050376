import classNames from 'classnames';
import React from 'react';

import { SideEffect } from '@/types/generic';

type SwitchState = 'active' | 'inactive';

interface Props {
  label?: string;
  isActive: boolean;
  onChange?: SideEffect;
  value?: SwitchState;
}

const ToggleSwitch = (props: Props): JSX.Element => {
  const { label, isActive, onChange, value } = props;

  return (
    <div
      className={classNames('toggle-switch', {
        'is-active': isActive,
      })}
      onClick={onChange}>
      {label && <span className="toggle-switch__label">{label}</span>}
      <div className="switch">
        <span className="slider"></span>
      </div>
    </div>
  );
};

export default ToggleSwitch;
