import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import SelectGroup from '@/components/form/SelectGroup';
import Panel from '@/components/panel/Panel';
import { PageHeader, Table } from '@/components/table';
import DateTimeCell from '@/components/table/DateTimeCell';
import { selectCurrentUserId } from '@/selectors/users';
import { TableConfig } from '@/types/table';
import { DeviceReading, DeviceReadingLabel } from '@/types/v2/device_reading';
import { formatIsoDateTime } from '@/utils/dateUtils';
import {
  bloodGlucoseColumns,
  bloodO2SaturationColumns,
  bloodPressureColumns,
  typeLabelToNumber,
} from '@/utils/deviceReadingUtils';
import useSimpleForm from '@/utils/form/useSimpleForm';
import { buildPath, Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { perPageOptions } from '@/utils/tableUtils';
import { fullName } from '@/utils/userUtils';
import AlertLevel from '../../remote_patient_care/AlertLevel';
import ReadingLegend from '../../remote_patient_care/modules/ReadingLegend';
import { getReadingLevel } from '../../remote_patient_care/utils';

const state = {
  per: '24',
};

const oneWeekAgo = moment().subtract(1, 'week');

type FormState = typeof state;

interface Params {
  reading_type: DeviceReadingLabel;
}

const RpmLatestReadings = (): JSX.Element => {
  const userId = useSelector(selectCurrentUserId);

  const form = useSimpleForm<FormState>(state);

  const { reading_type } = useParams<Params>();

  // See notes in device_reading.rb for type map
  const readingColumns = (() => {
    switch (reading_type as DeviceReadingLabel) {
      case 'blood_glucose':
        return bloodGlucoseColumns;

      case 'blood_pressure':
        return bloodPressureColumns;

      case 'blood_oxygen':
        return bloodO2SaturationColumns;
    }
  })();

  const getReadingResultLevel = (result: DeviceReading) => {
    return getReadingLevel(result.reading_type, result.value_1);
  };

  const renderReadingIcon = (result: DeviceReading) => {
    return <AlertLevel level={getReadingResultLevel(result)} />;
  };

  const getReadingRowClass = (result: DeviceReading) => {
    const level = getReadingResultLevel(result);

    return level ? `is-${level}` : null;
  };

  const config: TableConfig = {
    initialSort: 'reading_time:desc',
    searchPath: Routes.api2.deviceReadings,
    searchQuery: {
      ...form.data,
      end_at: formatIsoDateTime(),
      include: 'patient',
      reading_type,
      start_at: formatIsoDateTime(oneWeekAgo),
      user_id: userId,
    },
    rows: {
      addRowClass: getReadingRowClass,
    },
    emptyState: 'No device readings',
    columns: [
      {
        attribute: 'alert_type',
        isVisibleMobile: true,
        formatEntry: renderReadingIcon,
        width: 0.4,
      },
      {
        header: 'Date',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'reading_time',
        formatEntry: entry => {
          return <DateTimeCell date={entry.reading_time} />;
        },
      },
      {
        header: 'Patient Name',
        isSortable: true,
        attribute: 'patients.first_name',
        formatEntry: (entry: DeviceReading) => {
          const name = fullName(entry.patient);
          const url = buildPath(Routes.patientsShow, { id: entry.patient.id });

          return <Link to={url}>{name}</Link>;
        },
      },
      ...readingColumns,
    ],
  };

  const pageTitle = `Latest ${titleizeSnakeCase(reading_type)} Readings`;

  return (
    <>
      <Constraint size="large">
        <div className="columns">
          <div className="column">
            <BackLink to={Routes.patients}>Back to List</BackLink>
          </div>
        </div>
      </Constraint>

      <Panel>
        <Constraint size="large">
          <div className="columns">
            <div className="column">
              <PageHeader>{pageTitle}</PageHeader>
            </div>

            <div className="column">
              <div className="is-justified-right-tablet">
                <SelectGroup
                  label="Show"
                  options={perPageOptions}
                  onChange={value => form.update('per', value)}
                  value={form.data.per}
                />
              </div>
            </div>
          </div>

          <ReadingLegend
            readingType={typeLabelToNumber(reading_type as DeviceReadingLabel)}
          />
          <Table config={config} shouldUseModeler />
        </Constraint>
      </Panel>
    </>
  );
};

export default RpmLatestReadings;
