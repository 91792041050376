import React, { useEffect } from 'react';
import { useResponsive } from 'react-hooks-responsive';
import { useDispatch } from 'react-redux';

import { setCurrentBrand } from '@/actions/actions/brand';
import { setCurrentUser } from '@/actions/reducers/users';
import { User } from '@/types/user';
import DesktopHeader from './desktop_header/DesktopHeader';
import SmallHeader from './small_header/SmallHeader';

const breakpoints = { xs: 0, sm: 480, md: 1024 };

interface Props {
  brand?: string;
  user?: User;
}

const Header = (props: Props) => {
  const { screenIsAtLeast } = useResponsive(breakpoints);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.user) {
      const links = document.querySelectorAll('a[href]');

      dispatch(setCurrentUser(props.user));

      // If a user is provided (only on Rails pages using the React header) then recreate all links
      // so they are treated at HTML links, not JS events
      links.forEach(link => {
        const el = link.cloneNode(true);
        link.parentNode.replaceChild(el, link);
      });
    }

    if (props.brand) {
      dispatch(setCurrentBrand(props.brand));
    }
  }, []);

  const checkScreenSize = () => {
    if (screenIsAtLeast('md')) {
      return <DesktopHeader {...props} />;
    } else {
      return <SmallHeader {...props} />;
    }
  };

  return checkScreenSize();
};

export default Header;
