import React from 'react';

interface Props {
  isChecked?: boolean;
}

const Radio = (props: Props) => {
  const { isChecked } = props;

  return (
    <div className="form-radio">
      {isChecked && <div className="form-radio__fill" />}
    </div>
  );
};

export default Radio;
