import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import Constraint from '@/components/constraint/Constraint';
import Panel from '@/components/panel/Panel';
import { getCurrentUser } from '@/selectors/users';
import { UUID } from '@/types/generic';
import { MissedCall } from '@/types/v2/missed_call';
import { Voicemail } from '@/types/voicemail';
import useGetRequest from '@/utils/api/useGetRequest';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import DashboardCallHistory from './components/DashboardCallHistory';
import DashboardCalls from './components/DashboardCalls';
import DashboardPerformance from './components/DashboardPerformance';
import UserMissedCalls from './UserMissedCalls';
import UserVoicemails from './UserVoicemails';
import useNotifier from '@/utils/messages/useNotifier';
import { Task } from '@/types/v2/tasks';
import TaskCard from '../tasks/components/TaskCard';
import { EmptyBlock } from '@/components/dashboard';
import Paginator from '@/components/paginator/Paginator';
import { getSelectedPatient } from '@/selectors/api';
import Button from '@/components/button/Button';





const UserDashboard = () => {
  const dispatch = useDispatch();
  const notifier = useNotifier();
  const history = useHistory();

  const user = useSelector(getCurrentUser);
  const patient = useSelector(getSelectedPatient);



  const [calls, setCalls] = useState<any>({});
  const [performance, setPerformance] = useState<any>({});
  const [voicemails, setVoicemails] = useState<Voicemail[]>([]);
  const [missedCalls, setMissedCalls] = useState<MissedCall[]>([]);
  const [voicemailsPage, setVoicemailsPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoadingVoicemails, setIsLoadingVoicemails] = useState<boolean>(
    false,
  );
  const [tasks, setTasks] = useState<Task[]>([]);
  const [tasksPage, setTasksPage] = useState<number>(1);
  const [totalTasksPages, setTotalTasksPages] = useState<number>(1);

  const todaysDate = moment().format('L');

  const startOfMonth = moment()
    .startOf('month')
    .format('YYYY-MM-DD');

  const endOfMonth = moment()
    .endOf('month')
    .format('YYYY-MM-DD');

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    url: buildPath(
      'api/v1/calls',
      {},
      {
        user_id: user.data.id,
        start_at: startOfMonth,
        end_at: endOfMonth,
        per: null,
        include: 'patient',
      },
    ),
    model: 'calls',
    onSuccess: (response: any) => {
      setCalls(response);
    },
  });

  const [getDashboardData, isLoadingDashboardData] = useGetRequest({
    dispatch,
    url: `api/v1/dashboards/user/${user.data.id}`,
    model: 'dashboard',
    onSuccess: (response: any) => {
      setPerformance(response.data);
    },
  });

  const getVoicemails = async (): Promise<void> => {
    const url = buildPath(Routes.api.voicemails, null, {
      user_id: user.data.id,
      include: 'recording_attachment,patient',
      page: voicemailsPage.toString(),
      per: '10',
      sort: 'created_at:desc',
    });

    const response = await Api.utility.get(url);
    const model = new Modeler<Voicemail[]>(response.data).build();

    setTotalPages(response.data.meta.total);
    setVoicemails(model);
  };

  const getMissedCalls = async () => {
    const url = buildPath(
      Routes.api2.missedCalls,
      null,
      {
        per: '5',
        sort: 'created_at:desc',
        user_id: user.data.id,
      },
      ['patient'],
    );

    const response = await Api.utility.get(url);
    const resource = new Modeler<MissedCall[]>(response.data).build();

    setMissedCalls(resource);
  };

  const getTasks = async (): Promise<void> => {
    try {
      const url = buildPath(
        Routes.api2.tasks,
        {},
        {
          assigned_to_id: user.data.id,
          page: tasksPage.toString(),
          per: '10',
          task_status: 'pending',
        },
        ['patient', 'assigned_to'],
      );

      const response = await Api.utility.get(url);

      const resource = new Modeler<Task[]>(response.data, {
        generations: 2,
      }).build();

      setTasks(resource);
      setTotalTasksPages(response.data.meta.total);
    } catch (err) {
      notifier.error(err);
    }
  };

  useEffect(() => {
    getDashboardData();
    getEntry();
    getVoicemails();
    getMissedCalls();
  }, []);

  useEffect(() => {
    getTasks();
  }, [tasksPage]);

  useEffect(() => {
    getVoicemails();
  }, [voicemailsPage]);

  // At some point we should look for a library that can do this on the back end, but the purpose of the 'silent'
  // flag here is to automatically delete a voicemail entry where the provided URL has a duration of Infinity. This
  // seems to happen when no audio file comes back (a rare case that maybe a voicemail was started then immediately
  // finished?). I would think Twilio would correct this but unfortuantely that is not the case.
  const handleDeleteVoicemail = async (
    id: UUID,
    silent = false,
  ): Promise<any> => {
    const url = `api/v1/voicemails/${id}`;

    try {
      await Api.utility.delete(url);

      setVoicemails(
        voicemails.filter((voicemail: Voicemail) => voicemail.id !== id),
      );

      if (!silent) {
        dispatch(flashSuccess('Message deleted'));
      }
    } catch (err) {
      if (!silent) {
        dispatch(flashError('Error deleting message'));
      }
    }
  };

  const handleTaskCardPress = (task: Task) => {
    const path = buildPath(Routes.tasksShow, {
      id: task.patient.id,
      task_id: task.id,
    });

    history.push(path);
  };

  const handleCheckTaskPress = async (task: Task): Promise<void> => {
    try {
      const body = {
        task: {
          completed_by_id: user.data.id,
          task_status: 'completed',
        },
      };

      const url = buildPath(Routes.api2.task, { id: task.id });

      const response = await Api.utility.patch(url, body);

      const resource = new Modeler<Task>(response.data, {
        generations: 2,
      }).build();

      setTasks(tasks => tasks.filter(task => task.id !== resource.id));
    } catch (err) {
      notifier.error(err);
    }
  };

  const renderTasks = () => {
    if (tasks && tasks.length > 0) {
      return (
        <>
          {tasks.map((task: Task) => (
            <TaskCard
              isCareManagerDashboard
              key={task.id}
              onCheckTaskPress={handleCheckTaskPress}
              onTaskCardPress={handleTaskCardPress}
              task={task}
            />
          ))}

          <Paginator
            currentPage={tasksPage}
            onChangePage={page => setTasksPage(page)}
            totalPages={totalTasksPages}
          />
        </>
      );
    } else if (tasks && tasks.length === 0) {
      return <EmptyBlock style="compact">There are no open tasks</EmptyBlock>;
    } else {
      return null;
    }
  };

  if (
    isLoading ||
    isLoadingDashboardData ||
    isLoadingVoicemails ||
    !calls.data
  ) {
    return <ActivityIndicator />;
  }

  return (
    <Constraint size="large">
      <Panel withNarrowMargin withNarrowPadding>
        <DashboardCalls calls={calls} formattedDate={todaysDate} />
      </Panel>

      <h3 className="dashboard-header">Tasks</h3>
      <Panel withNarrowMargin withNarrowPadding>
        <div className="column is-6 task_buttons tasks__actions-container">
          <Button
            color="white"
            onClick={() =>
              history.push(buildPath(Routes.userTaskCompleted, { id: user.data.id }))
            }
          >
            See Completed Tasks
          </Button>
          <Button
            color="secondary"
          >
            Pending Tasks
          </Button>
        </div>


        <div className="dashboard-panel">{renderTasks()}</div>
      </Panel>

      <UserVoicemails
        currentPage={voicemailsPage}
        onChangePage={page => setVoicemailsPage(page)}
        onClickDelete={handleDeleteVoicemail}
        onNoDuration={id => handleDeleteVoicemail(id, true)}
        totalPages={totalPages}
        voicemails={voicemails}
      />

      <UserMissedCalls missedCalls={missedCalls} />

      <h3 className="dashboard-header">Performance</h3>
      <Panel withNarrowMargin withNarrowPadding>
        <DashboardPerformance performance={performance} />
      </Panel>

      <h3 className="dashboard-header">Call History</h3>
      {calls &&
        calls.data.map(call => {
          const patient = call.attributes.patient;

          if (call.attributes.status !== 'scheduled') {
            return (
              <Panel withNarrowPadding withNarrowMargin key={call.id}>
                <DashboardCallHistory call={call} patient={patient} />
              </Panel>
            );
          }
        })}
    </Constraint>
  );
};

export default UserDashboard;
