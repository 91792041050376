import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import AccessControl from '@/components/access_control/AccessControl';
import { PolicyGroups } from '@/components/router';
import { selectCurrentUserRole } from '@/selectors/users';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useSelector } from 'react-redux';
import { logoutPath } from '../utils';
import { PatientLinksLeft, PatientLinksRight } from './PatientLinks';
import { SpecialistLinksLeft, SpecialistLinksRight } from './SpecialistLinks';

const DesktopHeaderLinks = () => {
  const userRole = useSelector(selectCurrentUserRole);
  const logoutLink = logoutPath(userRole);

  return (
    <div className="header__nav-link-container">
      <div className="header__nav-left">
        <div className="header__nav-link">
          <AccessControl allowedRoles={PolicyGroups.patientOnly}>
            <PatientLinksLeft />
          </AccessControl>

          <AccessControl allowedRoles={PolicyGroups.practiceManagers}>
            <SpecialistLinksLeft />
          </AccessControl>

          <AccessControl allowedRoles={PolicyGroups.nonBillingAny}>
            <NavLink
              className="header__nav-item"
              activeClassName="header__nav-item is-active"
              exact
              to={Routes.dashboard}>
              Dashboard
            </NavLink>
          </AccessControl>

          <AccessControl allowedRoles={PolicyGroups.nonBillingAdmins}>
            <NavLink
              className="header__nav-item"
              activeClassName="header__nav-item is-active"
              exact
              to={Routes.dashboardLive}>
              Live View
            </NavLink>
          </AccessControl>

          <AccessControl allowedRoles={PolicyGroups.nonBillingAny}>
            <NavLink
              className="header__nav-item"
              activeClassName="header__nav-item is-active"
              to={Routes.patients}>
              Patients
            </NavLink>

            <NavLink
              className="header__nav-item"
              activeClassName="header__nav-item is-active"
              to={Routes.calls}>
              Call Schedule
            </NavLink>
          </AccessControl>
        </div>
      </div>

      <div className="header__nav-right">
        <div className="header__nav-link">
          <AccessControl allowedRoles={PolicyGroups.atLeastCareManager}>
            <div className="header__nav-dropdown">
              <div className="header__nav-item is-dropdown">Admin</div>
              <div className="header__nav-arrow" />
              <div className="header__nav-dropdown-container">
                <AccessControl allowedRoles={PolicyGroups.nonBillingAdmins}>
                  <Link
                    className="header__nav-dropdown-content"
                    to={Routes.users}>
                    Manage Care Managers
                  </Link>

                  <Link
                    className="header__nav-dropdown-content"
                    to={Routes.externalUsers.root}>
                    Manage External Users
                  </Link>
                  <Link
                    className="header__nav-dropdown-content"
                    to={Routes.patientEditAll}>
                    Manage Patients
                  </Link>

                  <Link
                    className="header__nav-dropdown-content"
                    to={Routes.practices}>
                    Manage Practices
                  </Link>

                  <Link
                    className="header__nav-dropdown-content"
                    to={Routes.hospitals}>
                    Manage Hospitals
                  </Link>

                  <Link
                    className="header__nav-dropdown-content"
                    to={Routes.healthSystems}>
                    Manage Health Systems
                  </Link>

                  <Link
                    className="header__nav-dropdown-content"
                    to={Routes.devices}>
                    Manage Devices
                  </Link>

                  <Link
                    className="header__nav-dropdown-content"
                    to={Routes.specialists}>
                    Manage Specialists
                  </Link>
                  <Link
                    className="header__nav-dropdown-content"
                    to={Routes.send_notification}>
                    Manage Push Notification
                  </Link>

                </AccessControl>

                <AccessControl allowedRoles={PolicyGroups.atLeastBillingAdmin}>
                  <Link
                    className="header__nav-dropdown-content"
                    to={Routes.reports}>
                    Reports
                  </Link>

                </AccessControl>

                <AccessControl allowedRoles={PolicyGroups.nonBillingAny}>
                  <Link
                    className="header__nav-dropdown-content is-borderless-bottom"
                    to={Routes.medicationsRequestNew}>
                    Request Medication
                  </Link>
                </AccessControl>

                <AccessControl allowedRoles={PolicyGroups.atLeastSuperAdmin}>
                  <Link
                    className="header__nav-dropdown-content is-last"
                    to={buildPath(Routes.adminResource, {
                      resource: 'alert_types',
                    })}>
                    Portal Configuration
                  </Link>
                </AccessControl>
              </div>
            </div>
          </AccessControl>

          <AccessControl allowedRoles={PolicyGroups.patientOnly}>
            <PatientLinksRight />
          </AccessControl>

          <AccessControl allowedRoles={PolicyGroups.practiceManagers}>
            <SpecialistLinksRight />
          </AccessControl>

          <NavLink
            className="header__nav-item"
            activeClassName="header__nav-item is-active"
            to={Routes.profile.show}>
            Profile
          </NavLink>

          <a
            className="header__nav-item"
            data-method="delete"
            href={logoutLink}>
            Log Out
          </a>
        </div>
      </div>
    </div>
  );
};

export default DesktopHeaderLinks;
