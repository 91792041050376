import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Form from '@/components/form/Form';
import Panel from '@/components/panel/Panel';
import {
  getConditionGroupOptions,
  getDeviceCategoryOptions,
  getDeviceMakeOptions,
  getDeviceNameOptions,
  getDeviceTypeOptions,
  getServiceOptions,
} from '@/selectors/options';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { getResourceName } from './helpers/links';
import { buildFormConfig } from './utils/formUtils';
import { UUID } from '@/types/generic';

interface Params {
  resource: string;
  resource_id: UUID;
}

const PortalEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { resource, resource_id } = useParams<Params>();

  const [response, setResponse] = useState(null);

  const conditionGroupOptions = useSelector(getConditionGroupOptions);
  const serviceOptions = useSelector(getServiceOptions);

  const deviceCategoryOptions = useSelector(getDeviceCategoryOptions);
  const deviceMakeOptions = useSelector(getDeviceMakeOptions);
  const deviceNameOptions = useSelector(getDeviceNameOptions);
  const deviceTypeOptions = useSelector(getDeviceTypeOptions);

  const backPath = buildPath(Routes.adminResource, { resource });

  const included = () => {
    if (resource === 'conditions') {
      return 'condition_group';
    } else if (
      resource === 'barriers' ||
      resource === 'goals' ||
      resource === 'outcomes' ||
      resource === 'symptoms'
    ) {
      return 'condition';
    } else if (resource === 'device_types') {
      return 'device_category';
    } else if (resource === 'device_makes' || resource === 'alert_types') {
      return 'device_type';
    } else if (resource === 'device_names') {
      return 'device_make';
    } else if (resource === 'device_models') {
      return 'device_name';
    } else {
      return '';
    }
  };

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    url: buildPath(
      `api/v1/${resource}/${resource_id}`,
      {},
      { include: included() },
    ),
    model: resource,
    onSuccess: (res: any) => {
      setResponse(parseResponseData(res));
    },
  });

  const [getConditionGroups] = useGetRequest({
    dispatch: useDispatch(),
    dispatchToStore: true,
    model: 'condition_groups',
    url: 'api/v1/condition_groups?per=null',
  });

  const [getDeviceCategories] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    model: 'device_categories',
    url: buildPath('api/v1/device_categories'),
  });

  const [getDeviceTypes] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    model: 'device_types',
    url: buildPath('api/v1/device_types'),
  });

  const [getDeviceMakes] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    model: 'device_makes',
    url: buildPath('api/v1/device_makes'),
  });

  const [getDeviceNames] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    model: 'device_names',
    url: buildPath('api/v1/device_names'),
  });

  useEffect(() => {
    switch (resource) {
      case 'conditions':
        getConditionGroups();
      case 'device_types':
        getDeviceCategories();
      case 'device_makes':
        getDeviceTypes();
      case 'device_names':
        getDeviceMakes();
      case 'device_models':
        getDeviceNames();
      case 'alert_types':
        getDeviceTypes();
    }
  }, []);

  useEffect(() => {
    getEntry();
  }, []);

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const initialSelection = () => {
    return {
      label: response.attributes.condition.description,
      value: response.attributes.condition.id,
    };
  };

  const options = () => {
    switch (resource) {
      case 'conditions':
        return conditionGroupOptions;
      case 'device_types':
        return deviceCategoryOptions;
      case 'device_makes':
        return deviceTypeOptions;
      case 'device_names':
        return deviceMakeOptions;
      case 'device_models':
        return deviceNameOptions;
      case 'alert_types':
        return deviceTypeOptions;
      case 'billing_codes':
        return serviceOptions;
    }
  };

  const formConfig = buildFormConfig(
    resource,
    history,
    options,
    resource_id,
    response,
    initialSelection,
    { serviceOptions },
  );

  return (
    <>
      <Container>
        <BackLink to={backPath}>{`Back to ${getResourceName(
          resource,
        )}`}</BackLink>
      </Container>

      <Panel>
        <Constraint size="large">
          <Form config={formConfig} />
        </Constraint>
      </Panel>
    </>
  );
};

export default PortalEdit;
