import React from 'react';

import { File } from '@/types/file';

interface Props {
  files: File[];
}

const FileListCell = (props: Props) => {
  const { files } = props;

  const handleViewDownload = entry => {
    window.open(entry.url, '_blank');
  };

  return (
    <div className="table__file-list">
      {files.map(file => {
        return (
          <a key={file.id} onClick={() => handleViewDownload(file)}>
            {file.name}
          </a>
        );
      })}
    </div>
  );
};

export default FileListCell;
