import React from 'react';
import { useSelector } from 'react-redux';

import {
  getCurrentUser,
  selectCurrentUserRole,
  selectIsCurrentUserProvider,
} from '@/selectors/users';
import { Routes } from '@/utils/routeUtils';
import PatientProfileDisplay from './components/PatientProfileDisplay';
import ProfileDisplay from './components/ProfileDisplay';
import SpecialistProfileDisplay from './components/SpecialistProfileDisplay';

const Profile = () => {
  const user = useSelector(getCurrentUser);
  const role = useSelector(selectCurrentUserRole);

  const passwordUrl = (() => {
    switch (role) {
      case 'patient':
        return Routes.profile.patient.password;

      case 'admin_user':
        return Routes.profile.provider.password;

      default:
        return Routes.profile.password;
    }
  })();

  switch (role) {
    case 'patient':
      return <PatientProfileDisplay user={user} passwordUrl={passwordUrl} />;

    case 'admin_user':
      return <SpecialistProfileDisplay user={user} passwordUrl={passwordUrl} />;

    default:
      return <ProfileDisplay user={user} passwordUrl={passwordUrl} />;
  }
};

export default Profile;
