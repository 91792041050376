import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import { Routes } from '@/utils/routeUtils';
import PracticesEdit from './PracticesEdit';
import PracticesIndex from './PracticesIndex';
import PracticesNew from './PracticesNew';
import PracticesShow from './PracticesShow';

const Practices = () => {
  return (
    <Protected allowedRoles={PolicyGroups.nonBillingAdmins}>
      <Switch>
        <Route exact path={Routes.practices} component={PracticesIndex} />
        <Route path={Routes.practicesHealthSystem} component={PracticesNew} />
        <Route path={Routes.practicesHospital} component={PracticesNew} />
        <Route path={Routes.practicesNew} component={PracticesNew} />
        <Route
          path={Routes.practicesEditHealthSystem}
          component={PracticesEdit}
        />
        <Route path={Routes.practicesEditHospital} component={PracticesEdit} />
        <Route path={Routes.practicesEdit} component={PracticesEdit} />
        <Route path={Routes.practicesShow} component={PracticesShow} />
      </Switch>
    </Protected>
  );
};

export default Practices;
