import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import { Routes } from '@/utils/routeUtils';
import HealthSystemsEdit from './HealthSystemsEdit';
import HealthSystemsIndex from './HealthSystemsIndex';
import HealthSystemsNew from './HealthSystemsNew';
import HealthSystemsShow from './HealthSystemsShow';

const HealthSystems = () => {
  return (
    <Protected allowedRoles={PolicyGroups.nonBillingAdmins}>
      <Switch>
        <Route
          exact
          path={Routes.healthSystems}
          component={HealthSystemsIndex}
        />
        <Route path={Routes.healthSystemsNew} component={HealthSystemsNew} />
        <Route path={Routes.healthSystemsEdit} component={HealthSystemsEdit} />
        <Route path={Routes.healthSystemsShow} component={HealthSystemsShow} />
      </Switch>
    </Protected>
  );
};

export default HealthSystems;
