import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { refreshPatientAction } from '@/actions/sagas/patientSaga';
import Api from '@/api/Api';
import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { Tab, Tabs } from '@/components/tabs';
import {
  isEnrollmentPath,
  pathSelect,
} from '@/contexts/patients/utils/patientUtils';
import { getSelectedPatient } from '@/selectors/api';
import { TableConfig } from '@/types/table';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientLayout from '../../components/PatientLayout';

const SEARCH_PATH = 'api/v1/specialists';

const SpecialistsIndex = () => {
  const dispatch = useDispatch();

  const patient = useSelector(getSelectedPatient);

  const [selectedTab, setSelectedTab] = useState<string>('specialists');

  const isEnrollment = isEnrollmentPath();

  // In the case that the user enters the form through the BHI module, an additional return path is added
  // to ensure users come back to the correct screen
  const returnPath = (() => {
    return location.pathname.includes('bhi_support')
      ? { return_to: location.pathname }
      : {};
  })();

  const editPath = buildPath(Routes.patientsSpecialistsEdit, {
    patient_id: patient.id,
  });
  const addPath = buildPath(Routes.patientsSpecialistsNew, { id: patient.id });

  const editPathEnroll = buildPath(
    Routes.patientsEnrollmentEdit,
    {
      patient_id: patient.id,
      step: 'support',
    },
    returnPath,
  );
  const addPathEnroll = buildPath(
    Routes.patientsEnrollmentNew,
    {
      id: patient.id,
      step: 'support',
    },
    returnPath,
  );

  const defaultSpecialistId = patient?.attributes.default_specialist_id;

  const setDefaultSpecialist = async entry => {
    const url = `api/v1/specialists/${entry.id}/default`;
    const body = { patient_id: patient.id };

    await Api.utility.post(url, body);

    dispatch(refreshPatientAction(patient.id));
  };

  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
    },
    emptyState: 'No providers added yet',
    columns: [
      {
        header: 'Provider Name',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'name',
      },
      {
        header: 'Specialty',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'specialty',
      },
      {
        header: 'Phone Number',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'phone_number',
        formatAttribute: formatPhoneNumber,
      },
    ],
    rows: {
      editPath: pathSelect(editPath, editPathEnroll),
      customText: (entry: any) => {
        if (entry.id === defaultSpecialistId) {
          return null;
        }

        return 'Set Default';
      },
      customAction: setDefaultSpecialist,
    },
    header: {
      addPath: pathSelect(addPath, addPathEnroll),
    },
  };

  if (isEnrollment) {
    return <Table config={tableConfig} />;
  }

  return (
    <PatientLayout>
      <Panel>
        <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
          <Tab value="specialists">Specialists</Tab>
        </Tabs>

        <Table config={tableConfig} />
      </Panel>
    </PatientLayout>
  );
};

export default SpecialistsIndex;
