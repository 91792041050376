import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GetPatientPracticeReadingThresholds,
  getPatientPracticeReadingThresholdsFailure,
  GetPatientPracticeReadingThresholdsFailure,
  getPatientPracticeReadingThresholdsSuccess,
  PatientsActions,
} from '@/actions/actions/patients';
import Api from '@/api/Api';
import { ReadingThreshold } from '@/types/v2/reading_threshold';
import { parseErrorData } from '@/utils/apiUtils';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import { flashError } from '../messageSaga';

export function* getPracticeReadingThresholdsSaga(
  action: GetPatientPracticeReadingThresholds,
) {
  try {
    const { practiceId } = action.payload;

    const url = buildPath(
      Routes.api2.readingThresholds,
      {},
      { per: null, practice_id: practiceId },
    );

    const response = yield call(Api.utility.get, url);
    const resource = new Modeler<ReadingThreshold[]>(response.data).build();

    yield put(getPatientPracticeReadingThresholdsSuccess(resource));
  } catch (err) {
    const message = parseErrorData(err);
    yield put(getPatientPracticeReadingThresholdsFailure(message));
  }
}

export function* getPracticeReadingThresholdsFailureSaga(
  action: GetPatientPracticeReadingThresholdsFailure,
) {
  yield put(flashError(action.payload.message));
}

export function* watchGetPracticeReadingThresholds() {
  yield takeLatest(
    PatientsActions.GET_PRACTICE_READING_THRESHOLDS,
    getPracticeReadingThresholdsSaga,
  );
  yield takeLatest(
    PatientsActions.GET_PRACTICE_READING_THRESHOLDS_FAILURE,
    getPracticeReadingThresholdsFailureSaga,
  );
}
