import { Call, CallAttributes } from '@/types/call';
import {
  ActiveCallType,
  CallManagerState,
  CallStatus,
  DeviceStatus,
  EntryType,
} from '@/types/call_manager';
import { Nullable } from '@/types/generic';
import { Patient } from '@/types/patient';
import moment from 'moment';

const initialState: CallManagerState = {
  activeCallType: null,
  callService: '',
  callStatus: 'offline',
  callSid: '',
  currentCall: null,
  currentConnection: null,
  deviceStatus: 'offline',
  incomingCallNumber: '',
  incomingCallPatient: null,
  incomingCallStatus: 'offline',
  incomingConnection: null,
  isActiveKeypadModalVisible: false,
  isCallModalVisible: false,
  isDialerModalVisible: false,
  isCompleteModalVisible: false,
  isTimerPaused: false,
  isTimerRunning: false,
  selectedPatient: null,
  selectedPhoneNumber: '',
  startAt: '',
  timerAmount: 0,
  timerType: 'call',
};

export enum CallManagerActions {
  ACCEPT_INCOMING_CALL = 'CALL_MANAGER/ACCEPT_INCOMING_CALL',
  DISPLAY_INCOMING_CALL = 'CALL_MANAGER/DISPLAY_INCOMING_CALL',
  REJECT_INCOMING_CALL = 'CALL_MANAGER/REJECT_INCOMING_CALL',
  SET_ACTIVE_CALL_TYPE = 'CALL_MANAGER/SET_ACTIVE_CALL_TYPE',
  SET_CALL_MODAL_VISIBILITY = 'CALL_MANAGER/SET_CALL_MODAL_VISIBILITY',
  SET_CALL_SERVICE = 'CALL_MANAGER/SET_CALL_SERVICE',
  SET_CALL_SID = 'CALL_MANAGER/SET_CALL_SID',
  SET_CALL_STATUS = 'CALL_MANAGER/SET_CALL_STATUS',
  SET_CURRENT_CALL = 'CALL_MANAGER/SET_CURRENT_CALL',
  SET_CURRENT_CONNECTION = 'CALL_MANAGER/SET_CURRENT_CONNECTION',
  SET_ACTIVE_KEYPAD_MODAL_VISIBILITY = 'CALL_MANAGER/SET_ACTIVE_KEYPAD_MODAL_VISIBILITY',
  SET_COMPLETE_MODAL_VISIBILITY = 'CALL_MANAGER/SET_COMPLETE_MODAL_VISIBILITY',
  SET_DEVICE_STATUS = 'CALL_MANAGER/SET_DEVICE_STATUS',
  SET_DIALER_MODAL_VISIBILITY = 'CALL_MANAGER/SET_DIALER_MODAL_VISIBILITY',
  SET_INCOMIING_CALL_PATIENT = 'CALL_MANAGER/SET_INCOMING_CALL_PATIENT',
  SET_IS_TIMER_PAUSED = 'CALL_MANAGER/SET_IS_TIMER_PAUSED',
  SET_IS_TIMER_RUNNING = 'CALL_MANAGER/SET_IS_TIMER_RUNNING',
  SET_SELECTED_PATIENT = 'CALL_MANAGER/SET_SELECTED_PATIENT',
  SET_SELECTED_PHONE_NUMBER = 'CALL_MANAGER/SET_SELECTED_PHONE_NUMBER',
  SET_START_AT = 'CALL_MANAGER/SET_START_AT',
  SET_TIMER_AMOUNT = 'CALL_MANAGER/SET_TIMER_AMOUNT',
  SET_TIMER_TYPE = 'CALL_MANAGER/SET_TIMER_TYPE',
  START_TIMER = 'CALL_MANAGER/START_TIMER',
  STOP_TIMER = 'CALL_MANAGER/STOP_TIMER',
}

export const acceptIncomingCall = (callSid: string) => {
  return {
    type: CallManagerActions.ACCEPT_INCOMING_CALL,
    payload: {
      callSid,
    },
  };
};

export const displayIncomingCall = (
  connection: any,
  patient: Nullable<Patient>,
) => {
  return {
    type: CallManagerActions.DISPLAY_INCOMING_CALL,
    payload: {
      connection,
      patient,
    },
  };
};

export const rejectIncomingCall = () => {
  return {
    type: CallManagerActions.REJECT_INCOMING_CALL,
  };
};

export const setCallModalVisibility = (isCallModalVisible: boolean) => {
  return {
    type: CallManagerActions.SET_CALL_MODAL_VISIBILITY,
    payload: {
      isCallModalVisible,
    },
  };
};

export const setDialerModalVisibility = (isDialerModalVisible: boolean) => {
  return {
    type: CallManagerActions.SET_DIALER_MODAL_VISIBILITY,
    payload: {
      isDialerModalVisible,
    },
  };
};

export const setActiveCallType = (activeCallType: ActiveCallType) => {
  return {
    type: CallManagerActions.SET_ACTIVE_CALL_TYPE,
    payload: {
      activeCallType,
    },
  };
};

export const setActiveKeypadModalVisibility = (
  isActiveKeypadModalVisible: boolean,
) => {
  return {
    type: CallManagerActions.SET_ACTIVE_KEYPAD_MODAL_VISIBILITY,
    payload: {
      isActiveKeypadModalVisible,
    },
  };
};

export const setCallStatus = (callStatus: CallStatus) => {
  return {
    type: CallManagerActions.SET_CALL_STATUS,
    payload: {
      callStatus,
    },
  };
};

export const setCallService = (callService: string) => {
  return {
    type: CallManagerActions.SET_CALL_SERVICE,
    payload: {
      callService,
    },
  };
};

export const setCallSid = (callSid: string) => {
  return {
    type: CallManagerActions.SET_CALL_SID,
    payload: {
      callSid,
    },
  };
};

export const setCurrentCall = (call: CallAttributes) => ({
  type: CallManagerActions.SET_CURRENT_CALL,
  payload: {
    call,
  },
});

export const setCurrentConnection = (currentConnection: any) => {
  return {
    type: CallManagerActions.SET_CURRENT_CONNECTION,
    payload: {
      currentConnection,
    },
  };
};

export const setCompleteModalVisibility = (isCompleteModalVisible: boolean) => {
  return {
    type: CallManagerActions.SET_COMPLETE_MODAL_VISIBILITY,
    payload: {
      isCompleteModalVisible,
    },
  };
};

export const setDeviceStatus = (deviceStatus: DeviceStatus) => {
  return {
    type: CallManagerActions.SET_DEVICE_STATUS,
    payload: {
      deviceStatus,
    },
  };
};

export const setIncomingCallPatient = (incomingCallPatient: Patient) => {
  return {
    type: CallManagerActions.SET_INCOMIING_CALL_PATIENT,
    payload: {
      incomingCallPatient,
    },
  };
};

export const setIsTimerPaused = (isTimerPaused: boolean) => {
  return {
    type: CallManagerActions.SET_IS_TIMER_PAUSED,
    payload: {
      isTimerPaused,
    },
  };
};

export const setIsTimerRunning = (isTimerRunning: boolean) => {
  return {
    type: CallManagerActions.SET_IS_TIMER_RUNNING,
    payload: {
      isTimerRunning,
    },
  };
};

export const setSelectedCallPatient = (selectedPatient: Patient) => {
  return {
    type: CallManagerActions.SET_SELECTED_PATIENT,
    payload: {
      selectedPatient,
    },
  };
};

export const setSelectedPhoneNumber = (selectedPhoneNumber: string) => {
  return {
    type: CallManagerActions.SET_SELECTED_PHONE_NUMBER,
    payload: {
      selectedPhoneNumber,
    },
  };
};

export const setStartAt = (startAt: string) => {
  return {
    type: CallManagerActions.SET_START_AT,
    payload: {
      startAt,
    },
  };
};

export const setTimerAmount = (timerAmount: number) => {
  return {
    type: CallManagerActions.SET_TIMER_AMOUNT,
    payload: {
      timerAmount,
    },
  };
};

export const setTimerType = (timerType: EntryType) => {
  return {
    type: CallManagerActions.SET_TIMER_TYPE,
    payload: {
      timerType,
    },
  };
};

export const startTimer = () => {
  return {
    type: CallManagerActions.START_TIMER,
  };
};

export const stopTimer = () => {
  return {
    type: CallManagerActions.STOP_TIMER,
  };
};

export default function reducer(
  state: CallManagerState = initialState,
  action: any,
): CallManagerState {
  const { type, payload } = action;

  switch (type) {
    case CallManagerActions.ACCEPT_INCOMING_CALL:
      return reduceAcceptIncomingCall(state, action);

    case CallManagerActions.DISPLAY_INCOMING_CALL:
      return {
        ...state,
        incomingCallNumber: payload.connection.parameters.From,
        incomingCallPatient: payload.patient,
        incomingCallStatus: 'pending',
        incomingConnection: payload.connection,
      };

    case CallManagerActions.REJECT_INCOMING_CALL:
      return {
        ...state,
        incomingCallPatient: null,
        incomingCallStatus: 'offline',
      };

    case CallManagerActions.SET_ACTIVE_CALL_TYPE:
      return {
        ...state,
        activeCallType: payload.activeCallType,
      };

    case CallManagerActions.SET_ACTIVE_KEYPAD_MODAL_VISIBILITY:
      return {
        ...state,
        isActiveKeypadModalVisible: payload.isActiveKeypadModalVisible,
      };

    case CallManagerActions.SET_CALL_MODAL_VISIBILITY:
      return {
        ...state,
        isCallModalVisible: payload.isCallModalVisible,
      };

    case CallManagerActions.SET_DIALER_MODAL_VISIBILITY:
      return {
        ...state,
        isDialerModalVisible: payload.isDialerModalVisible,
      };

    case CallManagerActions.SET_CALL_STATUS:
      return {
        ...state,
        callStatus: payload.callStatus,
      };

    case CallManagerActions.SET_COMPLETE_MODAL_VISIBILITY:
      return {
        ...state,
        isCompleteModalVisible: payload.isCompleteModalVisible,
      };

    case CallManagerActions.SET_CALL_SERVICE:
      return {
        ...state,
        callService: payload.callService,
      };

    case CallManagerActions.SET_CALL_SID:
      return {
        ...state,
        callSid: payload.callSid,
      };

    case CallManagerActions.SET_CURRENT_CALL:
      return {
        ...state,
        currentCall: payload.call,
      };

    case CallManagerActions.SET_CURRENT_CONNECTION:
      return {
        ...state,
        currentConnection: payload.currentConnection,
      };

    case CallManagerActions.SET_DEVICE_STATUS:
      return {
        ...state,
        deviceStatus: payload.deviceStatus,
      };

    case CallManagerActions.SET_INCOMIING_CALL_PATIENT:
      return {
        ...state,
        incomingCallPatient: payload.incomingCallPatient,
      };

    case CallManagerActions.SET_IS_TIMER_PAUSED:
      return {
        ...state,
        isTimerPaused: payload.isTimerPaused,
      };

    case CallManagerActions.SET_IS_TIMER_RUNNING:
      return {
        ...state,
        isTimerRunning: payload.isTimerRunning,
      };

    case CallManagerActions.SET_SELECTED_PATIENT:
      return {
        ...state,
        selectedPatient: payload.selectedPatient,
      };

    case CallManagerActions.SET_SELECTED_PHONE_NUMBER:
      return {
        ...state,
        selectedPhoneNumber: payload.selectedPhoneNumber,
      };

    case CallManagerActions.SET_START_AT:
      return {
        ...state,
        startAt: payload.startAt,
      };

    case CallManagerActions.SET_TIMER_AMOUNT:
      return {
        ...state,
        timerAmount: payload.timerAmount,
      };

    case CallManagerActions.SET_TIMER_TYPE:
      return {
        ...state,
        timerType: payload.timerType,
      };

    case CallManagerActions.START_TIMER:
      return {
        ...state,
        timerAmount: 0,
        isTimerRunning: true,
      };

    case CallManagerActions.STOP_TIMER:
      return {
        ...state,
        isTimerPaused: false,
        isTimerRunning: false,
      };

    default:
      return state;
  }
}

// Transfer incoming call to "active" call
const reduceAcceptIncomingCall = (
  state: CallManagerState,
  action: any,
): CallManagerState => {
  return {
    ...state,
    callSid: action.payload.callSid,
    callStatus: 'connected',
    incomingCallPatient: null,
    incomingCallStatus: 'offline',
    isTimerRunning: true,
    selectedPhoneNumber: state.incomingCallNumber,
    selectedPatient: state.incomingCallPatient,
    startAt: moment().format(),
    timerAmount: 0,
    timerType: 'call',
  };
};
