import React from 'react';

import { ChatMessage } from './Conversation';

type Props = ChatMessage;

const Bubble = ({
  author,
  downloadLink,
  fileName,
  text,
}: Props): JSX.Element => {
  return (
    <div className={`chat-bubble is-${author}`}>
      <div className="chat-bubble__body">
        <div className="chat-bubble__outline">
          <span>{text || fileName}</span>
        </div>

        <div className="chat-bubble__download">
          {downloadLink && (
            <a href={downloadLink} target="_blank">
              Download File
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Bubble;
