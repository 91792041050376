import React, { useMemo, useState } from 'react';

import Button from '@/components/button/Button';
import SelectGroup from '@/components/form/SelectGroup';
import { Attributable, SideEffect } from '@/types/generic';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { perPageOptions } from '@/utils/tableUtils';
import Icon from '../icon/Icon';
import Modal from '../modal/Modal';

// NOTE: Component expects up to 2 React nodes as children, the first being Standard Filters and the second being Advanced Filters.
// Wrap the lists of filters in a fragment to group them correctly and omit the second grouping if no advanced filters are needed.

interface Props {
  children?: any;
  includePerPage?: boolean;
  isMobileFilterFullWidth?: boolean;
  onChangeQuery: (field: string, value: string) => void;
  onClearFilters?: SideEffect;
  query: Attributable;
  perPageDesktopClass?: string;
  perPageTabletClass?: string;
  shouldAddColumnClasses?: boolean;
  shouldHideSearchDisplay?: boolean;
}

const FilterHeader = ({ includePerPage = true, ...props }: Props) => {
  const {
    children,
    isMobileFilterFullWidth,
    onChangeQuery,
    onClearFilters,
    perPageDesktopClass,
    perPageTabletClass,
    query,
    shouldAddColumnClasses,
    shouldHideSearchDisplay,
  } = props;

  const [areAdvancedFiltersVisible, setAreAdvancedFiltersVisible] = useState<
    boolean
  >(false);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(
    false,
  );

  const hasAdvancedFilters = Array.isArray(children) && !!children[1];

  const handleToggleModal = () => {
    setIsFilterModalVisible(!isFilterModalVisible);
  };

  const columnClass = () => {
    const tablet = perPageTabletClass ? `${perPageTabletClass}-tablet` : '';
    const desktop = perPageDesktopClass ? `${perPageDesktopClass}-desktop` : '';

    return `${tablet} ${desktop}`;
  };

  const renderSearchDisplay = () => {
    const activeFilters: string[] = [];

    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (key === 'search') {
          activeFilters.push(`'${query[key]}'`);
        } else if (key !== 'per') {
          activeFilters.push(titleizeSnakeCase(key.replace('_id', '')));
        }
      }
    }

    if (!activeFilters.length) {
      return null;
    }

    const filterList = activeFilters.join(', ');

    return (
      <div className="columns">
        <div className="column">
          <div className="filter-header__clear">
            <span>Search Results for: {filterList}</span>
            <a onClick={onClearFilters}>Clear search</a>
          </div>
        </div>
      </div>
    );
  };

  const wrapperColumnClass = (childList: any) => {
    return childList.length > 6 || shouldAddColumnClasses ? 'is-2-tablet' : '';
  };

  const wrapChildElements = (childList: any) => {
    return React.Children.map(childList, child => {
      return (
        <div className={`column ${wrapperColumnClass(childList)} is-6-touch`}>
          {child}
        </div>
      );
    });
  };

  const wrappedStandardFilters = useMemo(() => {
    if (!children) {
      return null;
    }

    const filters = Array.isArray(children)
      ? children[0].props.children
      : children.props.children;
    return wrapChildElements(filters);
  }, [children]);

  const wrappedAdvancedFilters = useMemo(() => {
    if (!children) {
      return null;
    }

    const filters = children[1];
    return filters ? wrapChildElements(filters.props.children) : null;
  }, [children]);

  const renderModalAdvancedFilters = () => {
    if (!hasAdvancedFilters) {
      return null;
    }

    return (
      <>
        <div className="columns">
          <div className="column">
            <h5 className="filter__subheader">Advanced Filters</h5>
          </div>
        </div>

        <div className="columns is-multiline is-mobile filter__filters">
          {wrappedAdvancedFilters}
        </div>
      </>
    );
  };

  const renderDesktopAdvancedFilters = () => {
    if (!hasAdvancedFilters || !children) {
      return null;
    }

    const action = areAdvancedFiltersVisible ? 'Hide' : 'Show';
    const rotation = areAdvancedFiltersVisible ? 180 : 0;

    const text = `${action} Advanced Filters`;

    return (
      <>
        <div className="columns is-hidden-touch">
          <div className="column">
            <button
              className="patient-filter__toggle"
              onClick={() =>
                setAreAdvancedFiltersVisible(!areAdvancedFiltersVisible)
              }>
              {text} <Icon icon="caretDown" rotation={rotation} />
            </button>
          </div>
        </div>

        {areAdvancedFiltersVisible && (
          <div className="columns is-hidden-touch is-multiline">
            {wrappedAdvancedFilters}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {!shouldHideSearchDisplay && renderSearchDisplay()}

      <div className="columns is-hidden-touch is-multiline">
        {wrappedStandardFilters}
      </div>

      {renderDesktopAdvancedFilters()}

      <div className="columns is-mobile filter-header__bottom">
        <div className="column is-hidden-desktop">
          <Button
            color="white"
            isFullWidth={isMobileFilterFullWidth}
            onClick={handleToggleModal}>
            Filter
          </Button>
        </div>

        {includePerPage && (
          <div className={`column ${columnClass()}`}>
            <SelectGroup
              label="Show"
              options={perPageOptions}
              value={query.per}
              onChange={(value: string) => onChangeQuery('per', value)}
            />
          </div>
        )}
      </div>

      <Modal isVisible={isFilterModalVisible} onCloseModal={handleToggleModal}>
        <h3 className="filter__modal-header">Filter</h3>

        <div className="columns is-multiline is-mobile filter__filters">
          {wrappedStandardFilters}
        </div>

        {renderModalAdvancedFilters()}

        <div className="columns">
          <div className="column">
            <div className="filter__submit">
              <Button onClick={handleToggleModal}>Apply</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FilterHeader;
