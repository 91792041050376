import { AxiosResponse } from 'axios';

import { Serialized, UUID } from '@/types/generic';
import { Response } from '@/types/response';

interface Query {
  [key: string]: number | string;
}

interface UrlConfig {
  model: string;
  id?: UUID;
}

export function buildRequestUrl(config: UrlConfig) {
  if (config.id) {
    return `/api/v1/${config.model}/${config.id}`;
  }

  return `/api/v1/${config.model}`;
}

export const buildRequestPath = (path: string, query: Query = {}): string => {
  const queryString = Object.keys(query)
    .map((key: string) => `${key}=${query[key]}`)
    .join('&');

  return queryString.length > 0
    ? `${cleanPath(path)}?${queryString}`
    : cleanPath(path);
};

export const cleanPath = (path: string): string =>
  path.replace(/(^\/)|(\/$)/g, '');

export const flatten = (object: Serialized<any>) => {
  return {
    ...object,
    id: object.id,
    ...(object.attributes || {}),
    relationships: object.relationships,
  };
};

export const parseErrorData = (response: Response<any>) => {
  if (response && response.response && response.response.data) {
    if (response.response.data.error) {
      return response.response.data.error;
    } else if (response.response.data.errors) {
      const error = response.response.data.errors[0];

      if (typeof error === 'string') {
        return error;
      } else if (typeof error === 'object') {
        // @ts-ignore
        return error.detail;
      }
    }
  }

  return null;
};

export const parseResponseData = (
  response: Response<any> | AxiosResponse<any>,
) => {
  if (response) {
    return {
      ...response.data,
      // @ts-ignore
      included: response.data.included || response.included,
    };
  }

  return null;
};
