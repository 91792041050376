import classNames from 'classnames';
import React, { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
  isHalf?: boolean;
  isVisible: boolean;
}

const DrawerContent = ({ children, isHalf, isVisible }: Props): JSX.Element => {
  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={classNames('video-drawer-content', {
        'is-half': isHalf,
      })}>
      <div className="video-drawer-content__content">{children}</div>
    </div>
  );
};

export default DrawerContent;
