import React from 'react';

import { SimpleBlock } from '@/components/dashboard';
import { HighAlertPatient } from '@/state/rpm';
import { DeviceReadingLabel } from '@/types/v2/device_reading';
import { formatShortDate } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';

interface Props {
  patient: HighAlertPatient;
}

const readingText = (type: DeviceReadingLabel, total: number): string => {
  const readings = total > 1 ? 'readings' : 'reading';
  const formattedType =
    type === 'blood_glucose' ? 'glucose' : type.replace('_', ' ');

  return `${total} high ${formattedType} ${readings}`;
};

const PatientBlock = ({ patient }: Props): JSX.Element => {
  const patientUrl = buildPath(Routes.patientsShow, {
    id: patient.patient_id,
  });

  const readingsText = readingText(
    patient.reading_type,
    patient.total_readings,
  );

  const footer = (() => {
    const startDate = formatShortDate(patient.start_date);
    const endDate = formatShortDate(patient.end_date);

    return `${startDate} - ${endDate}`;
  })();

  return (
    <SimpleBlock
      footer={footer}
      linkTo={patientUrl}
      text={readingsText}
      title={patient.patient_name}
    />
  );
};

export default PatientBlock;
