import AccessRestrictedPage from '@/components/page/AccessRestrictedPage';
import StaticPage from '@/components/page/StaticPage';
import { selectErrorCode } from '@/selectors/errors';
import React from 'react';
import { useSelector } from 'react-redux';

const Errors = () => {
  const errorCode = useSelector(selectErrorCode);

  switch (errorCode) {
    case 401:
      return <AccessRestrictedPage />;

    default:
      return (
        <StaticPage
          content="An unknown error has occured. Please refresh the page."
          title="Unknown Error"
        />
      );
  }
};

export default Errors;
