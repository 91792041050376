import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  acceptIncomingCall,
  rejectIncomingCall,
  setActiveKeypadModalVisibility,
  setCompleteModalVisibility,
  setIncomingCallPatient,
  setIsTimerPaused,
  setSelectedCallPatient,
  stopTimer,
} from '@/actions/reducers/call_manager';
import {
  addUserToCall,
  endCall,
  sendDialTone,
  transferIncomingCall,
  updateConnectionPatient,
} from '@/actions/sagas/callManagerSaga';
import { flashWarning } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import {
  selectActiveCallType,
  selectCallSid,
  selectIncomingCallPatient,
  selectIncomingCallStatus,
  selectIncomingConnection,
  selectIsActiveKeypadModalVisible,
  selectIsTimerPaused,
  selectIsTimerRunning,
  selectSelectedCallPatient,
  selectTimerAmount,
  selectTimerType,
} from '@/selectors/call_manager';
import { getCurrentUser } from '@/selectors/users';
import { Patient } from '@/types/patient';
import { formatSeconds } from '@/utils/timeUtils';
import { fullName } from '@/utils/userUtils';
import ActivityIndicator from '../activity/ActivityIndicator';
import Button from '../button/Button';
import SearchGroup, { formatSearchFullName } from '../form/SearchGroup';
import { FormattedResult } from '../typeahead/Typeahead';
import ActiveKeypadModal from './ActiveKeypadModal';
import AnswerButton from './AnswerButton';
import PauseButton from './PauseButton';
import StopButton from './StopButton';
import TimerDisplay from './TimerDisplay';

const CallControls = () => {
  const dispatch = useDispatch();

  const activeCallType = useSelector(selectActiveCallType);
  const currentCallSid = useSelector(selectCallSid);
  const currentUser = useSelector(getCurrentUser);
  const entryType = useSelector(selectTimerType);
  const incomingCallPatient = useSelector(selectIncomingCallPatient);
  const incomingCallStatus = useSelector(selectIncomingCallStatus);
  const incomingConnection = useSelector(selectIncomingConnection);
  const isTimerPaused = useSelector(selectIsTimerPaused);
  const isTimerRunning = useSelector(selectIsTimerRunning);
  const selectedPatient = useSelector(selectSelectedCallPatient);
  const timerAmount = useSelector(selectTimerAmount);
  const isActiveKeypadModalVisible = useSelector(
    selectIsActiveKeypadModalVisible,
  );

  const [activeTransferId, setActiveTransferId] = useState<string>('');
  const [incomingTransferId, setIncomingTransferId] = useState<string>('');
  const [isLoadingPatient, setIsLoadingPatient] = useState<boolean>(false);
  const [isTransferring, setIsTransferring] = useState<boolean>(false);

  useEffect(() => {
    setIsTransferring(false);
  }, [incomingCallStatus]);

  const handleAcceptCall = () => {
    const callSid = incomingConnection.parameters.CallSid;
    dispatch(acceptIncomingCall(callSid));
  };

  const handleRejectCall = () => {
    dispatch(rejectIncomingCall());
  };

  const handleSendDialTone = (tone: string) => {
    dispatch(sendDialTone(tone));
  };

  const handleSelectPatient = async (selection: FormattedResult) => {
    if (!selection) {
      return null;
    }

    setIsLoadingPatient(true);

    try {
      const patientId = selection.value;
      const url = `api/v1/patients/${patientId}`;

      const response = await Api.utility.get(url);
      const patient = response.data.data;

      dispatch(updateConnectionPatient(currentCallSid, patient.id));
      dispatch(setSelectedCallPatient(patient));
      dispatch(setIncomingCallPatient(patient));
    } catch (err) {
      dispatch(flashWarning('Error retrieving patient data'));
    }

    setIsLoadingPatient(false);
  };

  const handleTogglePause = () => {
    dispatch(setIsTimerPaused(!isTimerPaused));
  };

  const handleAddUserToCall = () => {
    setIsTransferring(true);
    dispatch(addUserToCall(activeTransferId, currentCallSid));
  };

  const handleTransferIncomingCall = () => {
    dispatch(transferIncomingCall(incomingTransferId, currentUser.data.id));
  };

  const handleOpenDialpad = () => {
    dispatch(setActiveKeypadModalVisibility(true));
  };

  const handleClickStop = () => {
    if (entryType === 'admin') {
      dispatch(stopTimer());
      dispatch(setCompleteModalVisibility(true));
    } else {
      dispatch(endCall());
    }
  };

  const renderActiveCall = () => {
    if (!isTimerRunning) {
      return null;
    }

    return (
      <div className="call-control">
        <div className="call-control__content">
          <div className="call-control__header">
            <TimerDisplay duration={timerAmount} />
            <a
              className="call-control__dial-toggle"
              onClick={handleOpenDialpad}>
              Open Dialpad
            </a>
            <PauseButton isPaused={isTimerPaused} onClick={handleTogglePause} />
            <StopButton onClick={handleClickStop} />
          </div>

          {isLoadingPatient && <ActivityIndicator />}
          {renderSelectedPatient()}

          {renderActiveTransfer()}
        </div>
      </div>
    );
  };

  const renderActiveTransfer = () => {
    if (activeCallType !== 'conference') {
      return null;
    }

    return (
      <div className="call-control__transfer is-active">
        <div className="call-control__transfer-select">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchFullName}
            guideValue={activeTransferId}
            label="Add Care Manager"
            minimumInputLength={1}
            onChange={value => setActiveTransferId(value)}
            placeholder="Search Care Managers"
            searchPath="api/v1/care_managers"
          />
        </div>

        <div className="call-control__transfer-action">
          <Button
            color="secondary"
            isDisabled={!activeTransferId || isTransferring}
            onClick={handleAddUserToCall}
            style="narrow">
            Add To Call
          </Button>
        </div>
      </div>
    );
  };

  const renderIncomingCall = () => {
    if (incomingCallStatus === 'offline') {
      return null;
    }

    return (
      <div className="call-control">
        <div className="call-control__content is-primary">
          <div className="call-control__header">
            <div className="call-control__label">
              <span>Incoming Call</span>
            </div>
            <div className="call-control__actions">
              <AnswerButton onClick={handleAcceptCall} type="accept" />
              <AnswerButton onClick={handleRejectCall} type="reject" />
            </div>
          </div>

          {renderIncomingPatient()}

          <div className="call-control__transfer">
            <div className="call-control__transfer-select">
              <SearchGroup
                clearFalseResultOnBlur
                formatResult={formatSearchFullName}
                guideValue={incomingTransferId}
                label="Transfer Call"
                minimumInputLength={1}
                onChange={value => setIncomingTransferId(value)}
                placeholder="Search Care Managers"
                searchPath="api/v1/care_managers"
              />
            </div>

            <div className="call-control__transfer-action">
              <Button
                color="secondary"
                isDisabled={!incomingTransferId}
                onClick={handleTransferIncomingCall}
                style="narrow">
                Transfer
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMonthlyStats = (patient: Patient) => {
    const patientStats = patient.attributes.monthly_stats;

    const ccmGoal = formatSeconds(patientStats.ccm_goal * 60, true);
    const ccmTotal = formatSeconds(patientStats.total_ccm_phone, true);
    const rpmGoal = formatSeconds(patientStats.rpm_goal * 60, true);
    const rpmTotal = formatSeconds(patientStats.total_rpm_phone, true);

    return (
      <>
        {patient.attributes.is_enrolled_ccm && (
          <>
            <div>
              CCM Goal: <span>{ccmGoal}</span>
            </div>
            <div>
              CCM Minutes this Month <span>{ccmTotal}</span>
            </div>
          </>
        )}

        {patient.attributes.is_enrolled_rpm && (
          <>
            <div>
              RPM Goal: <span>{rpmGoal}</span>
            </div>
            <div>
              RPM Minutes this Month <span>{rpmTotal}</span>
            </div>
          </>
        )}
      </>
    );
  };

  const renderIncomingPatient = () => {
    if (incomingCallPatient) {
      return (
        <div className="call-control__patient is-visible">
          <div>
            Patient: <span>{fullName(incomingCallPatient)}</span>
          </div>

          {renderMonthlyStats(incomingCallPatient)}
        </div>
      );
    }
  };

  const renderSelectedPatient = () => {
    if (!selectedPatient) {
      return (
        <div className="call-control__patient is-visible">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchFullName}
            guideValue={selectedPatient?.id}
            label="Patient"
            minimumInputLength={1}
            onChange={(_value, selection) => handleSelectPatient(selection)}
            placeholder="Select Patient"
            queryParams={{ care_manager_id: currentUser.data.id }}
            searchPath="api/v1/patients"
          />
        </div>
      );
    }

    return (
      <div className="call-control__patient is-visible">
        <div>
          Patient: <span>{fullName(selectedPatient)}</span>
        </div>

        {renderMonthlyStats(selectedPatient)}
      </div>
    );
  };

  const renderControls = () => {
    if (!isTimerRunning && incomingCallStatus === 'offline') {
      return null;
    }

    return (
      <div className="call-controls">
        {renderIncomingCall()}
        {renderActiveCall()}
      </div>
    );
  };

  return (
    <>
      {renderControls()}

      <ActiveKeypadModal
        isVisible={isActiveKeypadModalVisible}
        onAddNumber={handleSendDialTone}
      />
    </>
  );
};

export default CallControls;
