import { ErrorState } from '@/types/errors';
import { Nullable } from '@/types/generic';

const initialState: ErrorState = {
  errorCode: null,
};

export enum ErrorActions {
  SET_ERROR_CODE = 'ERRORS/SET_ERROR_CODE',
}

export interface SetErrorCode {
  type: ErrorActions.SET_ERROR_CODE;
  payload: {
    errorCode: Nullable<number>;
  };
}

type ErrorAction = SetErrorCode;

export const setErrorCode = (errorCode: Nullable<number>) => {
  return {
    type: ErrorActions.SET_ERROR_CODE,
    payload: {
      errorCode,
    },
  };
};

export default function reducer(
  state: ErrorState = initialState,
  action: ErrorAction,
): ErrorState {
  const { type, payload } = action;

  switch (type) {
    case ErrorActions.SET_ERROR_CODE:
      return {
        ...state,
        errorCode: payload.errorCode,
      };

    default:
      return state;
  }
}
