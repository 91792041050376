import React from 'react';

import { UUID } from '@/types/generic';
import { TrackingQuestionSet } from '@/types/tracking_question';
import TrackingQuestionSetDisplay from './TrackingQuestionSetDisplay';

export interface TrackingAnswer {
  id?: UUID;
  tracking_question_id: UUID;
  value: number;
}

interface Props {
  answers: TrackingAnswer[];
  onSetAnswerValue: (questionId: UUID, value: number) => void;
  questionStructure: TrackingQuestionSet[];
}

const TrackingQuestionManager = (props: Props) => {
  const { answers, onSetAnswerValue, questionStructure } = props;

  return (
    <div className="tracking-questions">
      {questionStructure.map((questionSet: TrackingQuestionSet) => (
        <TrackingQuestionSetDisplay
          answers={answers}
          key={questionSet.id}
          onSetAnswerValue={onSetAnswerValue}
          questionSet={questionSet}
        />
      ))}
    </div>
  );
};

export default TrackingQuestionManager;
