import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import { Routes } from '@/utils/routeUtils';
import DevicesShow from './DevicesShow';
import DevicesEdit from './DevicesEdit';
import DevicesIndex from './DevicesIndex'
import DevicesNew from './DevicesNew';

const Devices = () => {

    return (
        <Protected allowedRoles={PolicyGroups.atLeastSuperAdmin}>
            <Switch>
                <Route
                  exact path={Routes.devices}
                  component={DevicesIndex}
                />
                <Route
                  exact path={Routes.devicesNew}
                  component={DevicesNew}
                />
                <Route
                  exact path={Routes.devicesShow}
                  component={DevicesShow}
                />
                <Route
                  exact path={Routes.devicesEdit}
                  component={DevicesEdit}
                />
            </Switch>
        </Protected>
    )
}

export default Devices;