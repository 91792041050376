import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Checklist from '@/components/form/Checklist';
import EnrollmentHeader from '@/contexts/enrollment/components/EnrollmentHeader';
import {
  getSelectedPatient,
  getSelectedPatientIncluded,
} from '@/selectors/api';
import { getMetrics } from '@/selectors/options';
import { buildPath, Routes } from '@/utils/routeUtils';
import { getStepInformation } from '../helpers/steps';

const Tracking = () => {
  const history = useHistory();

  const included = useSelector(getSelectedPatientIncluded);
  const patient = useSelector(getSelectedPatient);
  const goals = useSelector(getMetrics);

  const [originalOptions, setOriginalOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const stepInformation = getStepInformation('tracking', patient);

  const nextScreen = buildPath(Routes.patientsEnrollment, {
    id: patient.id,
    step: stepInformation.nextStep,
  });

  const formatBody = ids => {
    return {
      enrollment_step: stepInformation.currentStep,
      patient: {
        metric_ids: ids,
      },
    };
  };

  const handleSuccess = () => {
    if (patient.attributes.is_enrolled_ccm) {
      history.push(nextScreen);
    }
  };

  const getSelectedOptions = () => {
    if (!Array.isArray(included)) {
      return [];
    }

    const options = included
      .filter(include => include.type === 'metric')
      .map(include => include.id);

    setSelectedOptions(options);
    setOriginalOptions(options);
  };

  useEffect(() => {
    getSelectedOptions();
  }, []);

  const formattedOptions = goals.data.map(symptom => ({
    label: symptom.attributes.description,
    value: symptom.id,
  }));

  const metricOptions = [
    {
      name: 'Numbers to Track',
      options: formattedOptions,
    },
  ];

  return (
    <div className="enrollment-wrapper">
      <EnrollmentHeader
        description="Which metrics are important to the patient to track?"
        linkTo={nextScreen}
        linkText={stepInformation.nextStepTitle}
        title={stepInformation.currentStepTitle}
      />

      <Checklist
        formatBody={formatBody}
        onChange={newOptions => setSelectedOptions(newOptions)}
        onSuccess={handleSuccess}
        options={metricOptions}
        originalSelectedIds={originalOptions}
        saveText="Save"
        selectedIds={selectedOptions}
        url={`api/v1/patients/${patient.id}`}
      />
    </div>
  );
};

export default Tracking;
