import { createSelector } from 'reselect';

import { PatientsState } from '@/state/patients';
import { AppState } from '@/types/app';

export const getPatientsState = (state: AppState): PatientsState =>
  state.patients;

export const getPatientsSnapshot = createSelector(
  getPatientsState,
  state => state.snapshot,
);

export const selectPracticeDeviceModels = createSelector(
  getPatientsState,
  state => state.practiceDeviceModels,
);

export const selectPracticeDeviceModelsPracticeId = createSelector(
  getPatientsState,
  state => state.practiceDeviceModelsPracticeId,
);

export const selectPracticeReadingThresholds = createSelector(
  getPatientsState,
  state => state.practiceReadingThresholds,
);
