import { BillingCodeAttributes } from '@/types/billing_code';
import { Attributable } from '@/types/generic';
import { TableConfig } from '@/types/table';
import { lookup } from '@/utils/dropdownUtils';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';

const TABLE_CONFIGS = {
  alert_types: {
    emptyState: 'No alert types added',
    searchQuery: {
      include: 'device_type',
    },
    columns: [
      {
        header: 'Name',
        isSortable: true,
        attribute: 'name',
        isVisibleMobile: true,
      },
      {
        header: 'Device Type',
        isSortable: true,
        attribute: 'device_types.name',
        formatEntry: entry => {
          return entry.device_type.name;
        },
      },
    ],
  },
  allergy_options: {
    emptyState: 'No allergies added yet',
    columns: [
      {
        header: 'Allergy Name',
        isSortable: true,
        attribute: 'name',
        isVisibleMobile: true,
      },
      {
        header: 'Allergy Type',
        isSortable: true,
        attribute: 'allergy_type',
        isVisibleMobile: true,
        formatAttribute: titleizeSnakeCase,
      },
    ],
  },
  barriers: {
    emptyState: 'No barriers added yet',
    searchQuery: {
      include: 'condition',
    },
    columns: [
      {
        header: 'Description',
        isSortable: true,
        attribute: 'description',
        isVisibleMobile: true,
      },
      {
        header: 'Condition',
        isSortable: true,
        attribute: 'conditions.description',
        formatEntry: entry => {
          return entry.condition.description;
        },
      },
    ],
  },
  billing_codes: {
    emptyState: 'No billing codes added yet',
    searchQuery: {
      include: 'service',
    },
    columns: [
      {
        header: 'Code',
        isSortable: true,
        attribute: 'code',
        isVisibleMobile: true,
      },
      {
        header: 'Name',
        isSortable: true,
        attribute: 'name',
      },
      {
        header: 'Service',
        isSortable: true,
        attribute: 'services.name',
        formatEntry: (entry: BillingCodeAttributes) => entry.service.name,
      },
    ],
  },
  caller_ids: {
    emtpyState: 'No caller IDs added yet',
    columns: [
      {
        header: 'Phone Number',
        isSortable: true,
        attribute: 'phone_number',
        isVisibleMobile: true,
        formatAttribute: formatPhoneNumber,
      },
    ],
  },
  ccm_complexities: {
    emptyState: 'No CCM complexities added yet',
    columns: [
      {
        header: 'Name',
        isSortable: true,
        attribute: 'name',
        isVisibleMobile: true,
        width: 2,
      },
      {
        header: 'Phone Minutes',
        isSortable: true,
        attribute: 'phone_minutes',
      },
      {
        header: 'CCM Minutes',
        isSortable: true,
        attribute: 'admin_minutes',
      },
      {
        header: 'Total Minutes',
        isSortable: true,
        attribute: 'total_minutes',
      },
    ],
  },
  cpt_codes: {
    emptyState: 'No CPT codes added yet',
    columns: [
      {
        header: 'Code',
        isSortable: true,
        attribute: 'code',
        isVisibleMobile: true,
      },
    ],
  },
  condition_groups: {
    emtpyState: 'No condition groups added yet',
    searchQuery: {
      include: 'service',
    },
    columns: [
      {
        header: 'Description',
        isSortable: true,
        attribute: 'name',
        isVisibleMobile: true,
      },
      {
        header: 'Service',
        isSortable: true,
        attribute: 'services.name',
        formatEntry: entry => entry.service.name,
      },
    ],
  },
  conditions: {
    emptyState: 'No conditions added yet',
    searchQuery: {
      include: 'condition_group,service',
    },
    columns: [
      {
        header: 'Description',
        isSortable: true,
        attribute: 'description',
        isVisibleMobile: true,
      },
      {
        header: 'Group',
        isSortable: true,
        attribute: 'condition_groups.name',
        formatEntry: entry => {
          return entry.condition_group.name;
        },
      },
      {
        header: 'Service',
        isSortable: true,
        attribute: 'services.name',
        formatEntry: entry => entry.service.name,
      },
    ],
  },
  device_categories: {
    emptyState: 'No device categories added',
    columns: [
      {
        header: 'Name',
        isSortable: true,
        attribute: 'name',
        isVisibleMobile: true,
      },
    ],
  },
  device_makes: {
    emptyState: 'No device makes added',
    searchQuery: {
      include: 'device_type',
    },
    columns: [
      {
        header: 'Name',
        isSortable: true,
        attribute: 'name',
        isVisibleMobile: true,
      },
      {
        header: 'Device Type',
        isSortable: true,
        attribute: 'device_types.name',
        formatEntry: entry => {
          return entry.device_type.name;
        },
      },
    ],
  },
  device_models: {
    emptyState: 'No device models added',
    searchQuery: {
      include: 'device_name',
    },
    columns: [
      {
        header: 'Name',
        isSortable: true,
        attribute: 'name',
        isVisibleMobile: true,
      },
      {
        header: 'Device Name',
        isSortable: true,
        attribute: 'device_names.name',
        formatEntry: entry => {
          return entry.device_name.name;
        },
      },
    ],
  },
  device_names: {
    emptyState: 'No device names added',
    searchQuery: {
      include: 'device_make',
    },
    columns: [
      {
        header: 'Name',
        isSortable: true,
        attribute: 'name',
        isVisibleMobile: true,
      },
      {
        header: 'Device Make',
        isSortable: true,
        attribute: 'device_makes.name',
        formatEntry: entry => {
          return entry.device_make.name;
        },
      },
    ],
  },
  device_types: {
    emptyState: 'No device types added',
    searchQuery: {
      include: 'device_category',
    },
    columns: [
      {
        header: 'Name',
        isSortable: true,
        attribute: 'name',
        isVisibleMobile: true,
      },
      {
        header: 'Device Category',
        isSortable: true,
        attribute: 'device_categories.name',
        formatEntry: entry => {
          return entry.device_category.name;
        },
      },
    ],
  },
  goals: {
    emptyState: 'No goals added yet',
    searchQuery: {
      include: 'condition',
    },
    columns: [
      {
        header: 'Description',
        isSortable: true,
        attribute: 'description',
        isVisibleMobile: true,
        width: 2,
      },
      {
        header: 'Condition',
        isSortable: true,
        attribute: 'conditions.description',
        formatEntry: entry => {
          return entry.condition.description;
        },
      },
    ],
  },
  icd_codes: {
    emptyState: 'No ICD codes added yet',
    columns: [
      {
        header: 'Code',
        isSortable: true,
        attribute: 'code',
        isVisibleMobile: true,
        width: 2,
      },
      {
        header: 'Status',
        isSortable: true,
        attribute: 'status',
        formatAttribute: titleizeSnakeCase,
      },
    ],
  },
  immunization_names: {
    emptyState: 'No immunizations added yet',
    columns: [
      {
        header: 'Name',
        isSortable: true,
        attribute: 'name',
        isVisibleMobile: true,
      },
    ],
  },
  lab_tests: {
    emptyState: 'No lab tests added yet',
    columns: [
      {
        header: 'Test Type',
        isSortable: true,
        attribute: 'test_type',
        isVisibleMobile: true,
      },
    ],
  },
  manual_entry_reasons: {
    emptyState: 'No manual entry reasons added yet',
    columns: [
      {
        header: 'Reason',
        isSortable: true,
        attribute: 'reason',
        isVisibleMobile: true,
      },
    ],
  },
  metrics: {
    emptyState: 'No metrics added yet',
    columns: [
      {
        header: 'Description',
        isSortable: true,
        attribute: 'description',
        isVisibleMobile: true,
      },
    ],
  },
  outcomes: {
    emptyState: 'No expected outcomes added yet',
    searchQuery: {
      include: 'condition',
    },
    columns: [
      {
        header: 'Description',
        isSortable: true,
        attribute: 'description',
        isVisibleMobile: true,
        width: 2,
      },
      {
        header: 'Condition',
        isSortable: true,
        attribute: 'conditions.description',
        formatEntry: entry => {
          return entry.condition.description;
        },
      },
    ],
  },
  screening_types: {
    emptyState: 'No screening types added yet',
    columns: [
      {
        header: 'Name',
        isSortable: true,
        attribute: 'name',
        width: 2,
        isVisibleMobile: true,
      },
      {
        header: 'Screening Type',
        isSortable: true,
        attribute: 'screen_type',
        formatEntry: entry => {
          const type = entry.screen_type;
          return lookup[type];
        },
      },
    ],
  },
  specialty_types: {
    emptyState: 'No specialities added yet',
    columns: [
      {
        header: 'Name',
        isSortable: true,
        attribute: 'name',
        isVisibleMobile: true,
      },
    ],
  },
  symptoms: {
    emptyState: 'No symptoms added yet',
    searchQuery: {
      include: 'condition',
    },
    columns: [
      {
        header: 'Description',
        isSortable: true,
        attribute: 'description',
        isVisibleMobile: true,
      },
      {
        header: 'Condition',
        isSortable: true,
        attribute: 'conditions.description',
        formatEntry: entry => {
          return entry.condition.description;
        },
      },
    ],
  },
};

export const buildTableConfig = (
  resource: string = '',
  queryOptions: Attributable,
): TableConfig => {
  const tableConfig = TABLE_CONFIGS[resource];

  const config: TableConfig = {
    ...tableConfig,
    rows: {
      editPath: buildPath(Routes.adminResourceEdit, { resource }),
      editId: 'resource_id',
    },
    searchPath: `/api/v1/${resource}`,
    searchQuery: {
      ...tableConfig.searchQuery,
      ...queryOptions,
    },
  };

  return config;
};
