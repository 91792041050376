import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { Tab, Tabs } from '@/components/tabs';
import { getSelectedPatient } from '@/selectors/api';
import { TableConfig } from '@/types/table';
import { formatShortDate } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import PatientLayout from '../../components/PatientLayout';
import { isEnrollmentPath, pathSelect } from '../../utils/patientUtils';

const SEARCH_PATH = 'api/v1/allergies';

const AllergiesIndex = () => {
  const patient = useSelector(getSelectedPatient);

  const [selectedTab, setSelectedTab] = useState<string>('allergies');

  const isEnrollment = isEnrollmentPath();

  const editPath = buildPath(Routes.patientsAllergiesEdit, {
    patient_id: patient.id,
  });
  const addPath = buildPath(Routes.patientsAllergiesNew, { id: patient.id });

  const editPathEnroll = buildPath(Routes.patientsEnrollmentEdit, {
    patient_id: patient.id,
    step: 'allergies',
  });
  const addPathEnroll = buildPath(Routes.patientsEnrollmentNew, {
    id: patient.id,
    step: 'allergies',
  });

  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
    },
    emptyState: 'No allergies added yet',
    columns: [
      {
        header: 'Source',
        isSortable: false,
        attribute: 'source',
      },
      {
        header: 'Type',
        isSortable: false,
        attribute: 'allergy_type',
        formatAttribute: titleizeSnakeCase,
      },
      {
        header: 'Allergy',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'name',
      },
      {
        header: 'Severity',
        isSortable: false,
        attribute: 'severity',
      },
      {
        header: 'End Date',
        isSortable: false,
        attribute: 'end_date',
        formatEntry: entry => {
          if (entry.status === 'previous') {
            return formatShortDate(entry.end_date);
          }

          return '';
        },
      },
      {
        header: 'Instructions',
        isSortable: false,
        attribute: 'instructions',
      },
    ],
    rows: {
      editPath: pathSelect(editPath, editPathEnroll),
    },
    header: {
      addPath: pathSelect(addPath, addPathEnroll),
    },
  };

  if (isEnrollment) {
    return <Table config={tableConfig} />;
  }

  return (
    <PatientLayout>
      <Panel>
        <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
          <Tab value="allergies">Allergies</Tab>
        </Tabs>

        <Table config={tableConfig} />
      </Panel>
    </PatientLayout>
  );
};

export default AllergiesIndex;
