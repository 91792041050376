import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';
import { Tab, Tabs } from '@/components/tabs';
import Calendar from '@/contexts/provider/calendar/Calendar';
import PatientLayout from '@/contexts/provider/patients/components/PatientLayout';
import PatientTableHeader from '@/contexts/provider/patients/components/PatientTableHeader';
import AppointmentModal from '@/modules/appointments/AppointmentModal';
import { getSelectedPatient } from '@/selectors/api';
import {
  canUserModifyAppointments,
  selectCurrentUserId,
} from '@/selectors/users';
import { TableConfig } from '@/types/table';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import { formatShortDateTime } from '@/utils/dateUtils';
import { buildPath, includes, Routes } from '@/utils/routeUtils';
import { titleize } from '@/utils/stringUtils';

interface Query {
  month: string;
  per: string;
  year: string;
}

const initialQuery: Query = {
  month: '',
  per: '24',
  year: '',
};

const VirtualVisitsIndex = () => {
  const history = useHistory();

  const patient = useSelector(getSelectedPatient);
  const userId = useSelector(selectCurrentUserId);
  const isAdmin = useSelector(canUserModifyAppointments);

  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [queryOptions, setQueryOptions] = useState<Query>(initialQuery);

  const queryParams = new URLSearchParams(location.search || '');
  const initialTab = queryParams.get('return_tab');
  const appointmentId = queryParams.get('appointment');

  const [selectedTab, setSelectedTab] = useState<string>(initialTab || 'list');

  const updateMonth = value => {
    setMonth(value);
  };

  const updateYear = value => {
    setYear(value);
  };

  const handleApplyFilter = () => {
    setQueryOptions({
      ...queryOptions,
      year,
      month,
    });
  };

  const handleClickView = entry => {
    const id = entry.id;

    const url = buildPath(
      Routes.provider.patients.show.virtualVisits.root,
      { patient_id: patient.id },
      {
        appointment: id,
      },
    );

    history.push(url);
  };

  const handleNavigateToCalendar = () => {
    const path = buildPath(Routes.provider.patients.show.virtualVisits.root, {
      patient_id: patient.id,
    });

    history.push(path);
  };

  const tableConfig: TableConfig = {
    searchPath: Routes.api2.virtualAppointments,
    searchQuery: {
      ...queryOptions,
      include: includes('admin_user', 'admin_user.specialist'),
      patient_id: patient.id,
    },
    emptyState: 'No virtual visits added yet',
    columns: [
      {
        header: 'Visit Date',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'scheduled_start_at',
        formatEntry: (entry: VirtualAppointment) => {
          if (entry.appointment_status === 'completed') {
            return formatShortDateTime(entry.actual_start_at);
          }

          return formatShortDateTime(entry.scheduled_start_at);
        },
      },
      {
        header: 'Duration',
        isSortable: false,
        attribute: 'duration',
        formatEntry: (entry: VirtualAppointment) => `${entry.duration} minutes`,
      },
      {
        header: 'Visit Status',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'appointment_status',
        formatAttribute: titleize,
      },
      {
        header: 'Provider',
        isSortable: false,
        attribute: 'specialists.name',
        formatEntry: (entry: VirtualAppointment) => {
          if (entry.admin_user_id) {
            return (
              <Link
                to={buildPath(
                  Routes.provider.practice.show.provider,
                  {
                    provider_id: entry.admin_user.id,
                  },
                  {
                    return: window.location.pathname,
                  },
                )}>
                {entry.admin_user.specialist.name}
              </Link>
            );
          }

          return 'Not selected';
        },
      },
    ],
    rows: {
      editPath: buildPath(Routes.provider.appointments.edit, null, {
        return: window.location.pathname,
      }),
      editId: 'appointment_id',
      shouldShowEdit: (entry: VirtualAppointment) =>
        userId === entry.patient_id ||
        userId === entry.admin_user_id ||
        isAdmin,
      viewPath: handleClickView,
    },
  };

  const renderHeader = () => {
    if (selectedTab === 'list') {
      const scheduleAppointmentLink = buildPath(
        Routes.provider.appointments.new,
        null,
        { patient: patient.id, return: window.location.pathname },
      );

      return (
        <PatientTableHeader
          link={scheduleAppointmentLink}
          linkText="Schedule Appointment"
          month={month}
          onApplyClick={handleApplyFilter}
          onMonthUpdate={updateMonth}
          onYearUpdate={updateYear}
          patient={patient}
          year={year}
        />
      );
    }
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'list':
        return (
          <Table config={tableConfig} shouldUseModeler modelerGenerations={2} />
        );
      case 'notes':
        return <div />;
      case 'calendar':
        return <Calendar patientId={patient.id} />;
    }
  };

  return (
    <PatientLayout>
      <Panel>
        <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
          <Tab value="list">Visit List</Tab>
          <Tab value="notes">Notes</Tab>
          <Tab value="calendar">Appointment Calendar</Tab>
        </Tabs>

        {renderHeader()}
        {renderTabContent()}
      </Panel>

      <AppointmentModal
        appointmentId={appointmentId}
        displayFor="specialist"
        onCloseModal={handleNavigateToCalendar}
        userId={userId}
      />
    </PatientLayout>
  );
};

export default VirtualVisitsIndex;
