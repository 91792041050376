import classNames from 'classnames';
import React from 'react';

import Icon from '@/components/icon/Icon';
import { SideEffect } from '@/types/generic';

type Increment = 'next' | 'prev';

interface Props {
  currentPage: number;
  onChangePage: SideEffect;
  totalPages: number;
}

const incrementIcon = (to: Increment) => {
  return to === 'next' ? 'caretRight' : 'caretLeft';
};

const Paginator = (props: Props) => {
  const { currentPage, onChangePage, totalPages } = props;

  const incrementPage = (to: Increment) => {
    if (to === 'next') {
      return currentPage === totalPages ? totalPages : currentPage + 1;
    }

    return currentPage > 1 ? currentPage - 1 : 1;
  };

  const renderPageIncrement = (to: Increment) => {
    const page = incrementPage(to);

    return (
      <a className="paginator__increment" onClick={() => onChangePage(page)}>
        <Icon icon={incrementIcon(to)} />
      </a>
    );
  };

  const renderPageLink = (page: number) => {
    return (
      <a
        className={classNames('paginator__page', {
          'is-active': currentPage === page,
        })}
        key={page}
        onClick={() => onChangePage(page)}>
        {page}
      </a>
    );
  };

  const renderPageLinks = () => {
    const pageNumbers = Array.from(Array(5).keys())
      .map((_value: number, index: number) => {
        return index + currentPage - 2;
      })
      .filter(pageNumber => pageNumber > 1 && pageNumber < totalPages);

    return (
      <>
        {pageNumbers[0] !== 2 && (
          <span className="paginator__ellipsis">...</span>
        )}
        {pageNumbers.map(pageNumber => renderPageLink(pageNumber))}
        {!!pageNumbers.length &&
          !!(pageNumbers[pageNumbers.length - 1] !== totalPages - 1) && (
            <span className="paginator__ellipsis">...</span>
          )}
      </>
    );
  };

  return (
    <div className="paginator">
      {renderPageIncrement('prev')}
      {renderPageLink(1)}
      {renderPageLinks()}
      {totalPages > 1 && renderPageLink(totalPages)}
      {renderPageIncrement('next')}
    </div>
  );
};

export default Paginator;
