import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { Tab, Tabs } from '@/components/tabs';
import { getSelectedPatient } from '@/selectors/api';
import { TableConfig } from '@/types/table';
import { formatShortDate } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientLayout from '../../components/PatientLayout';
import {
  getMedicalHistoryType,
  isEnrollmentPath,
  pathSelect,
} from '../../utils/patientUtils';

const MEDICAL_SEARCH_PATH = 'api/v1/medical_histories';
const OTHER_SEARCH_PATH = 'api/v1/other_histories';

interface Props {
  shouldSkipLayout?: boolean;
  shouldSkipModification?: boolean;
}

const MedicalRecordsIndex = ({
  shouldSkipLayout,
  shouldSkipModification,
}: Props) => {
  const patient = useSelector(getSelectedPatient);

  const queryParams = new URLSearchParams(location.search || '');
  const initialTab = queryParams.get('return_tab');

  const [selectedTab, setSelectedTab] = useState<string>(
    initialTab || 'medical',
  );

  const isEnrollment = isEnrollmentPath();

  const step = getMedicalHistoryType();
  const stepName = `${step}_history`;
  const emptyStateText = isEnrollment
    ? `No ${step} history added yet`
    : `No ${selectedTab} history added yet`;

  const tabQuery = { history_type: selectedTab };

  const medicalEditPath = buildPath(
    Routes.patientsMedicalHistoryEdit,
    {
      patient_id: patient.id,
    },
    tabQuery,
  );
  const medicalAddPath = buildPath(
    Routes.patientsMedicalHistoryNew,
    {
      id: patient.id,
    },
    tabQuery,
  );

  const otherEditPath = buildPath(
    Routes.patientsOtherHistoryEdit,
    {
      patient_id: patient.id,
    },
    tabQuery,
  );
  const otherAddPath = buildPath(
    Routes.patientsOtherHistoryNew,
    { id: patient.id },
    tabQuery,
  );

  const enrollEditPath = buildPath(
    Routes.patientsEnrollmentEdit,
    {
      patient_id: patient.id,
      step: stepName,
    },
    tabQuery,
  );
  const enrollAddPath = buildPath(
    Routes.patientsEnrollmentNew,
    {
      id: patient.id,
      step: stepName,
    },
    tabQuery,
  );

  const medicalTableConfig: TableConfig = {
    searchPath: MEDICAL_SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
    },
    emptyState: 'No medical history added yet',
    columns: [
      {
        header: 'ICD Code',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'icd_code',
        width: 2,
      },
      {
        header: 'Start Date',
        isSortable: false,
        attribute: 'start_date',
      },
      {
        header: 'End Date',
        isSortable: false,
        attribute: 'end_date',
      },
      {
        header: 'Comment',
        isSortable: false,
        attribute: 'comments',
      },
    ],
    rows: {
      editPath: shouldSkipModification
        ? null
        : pathSelect(medicalEditPath, enrollEditPath),
    },
    header: {
      addPath: shouldSkipModification
        ? null
        : pathSelect(medicalAddPath, enrollAddPath),
    },
  };

  const otherTableConfig = {
    searchPath: OTHER_SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
      history_type: step || selectedTab,
    },
    emptyState: emptyStateText,
    columns: [
      {
        header: 'Description',
        attribute: 'description',
        width: 2,
        isVisibleMobile: true,
      },
      {
        header: 'Date',
        formatAttribute: formatShortDate,
        attribute: 'date',
      },
    ],
    rows: {
      editPath: shouldSkipModification
        ? null
        : pathSelect(otherEditPath, enrollEditPath),
    },
    header: {
      addPath: shouldSkipModification
        ? null
        : pathSelect(otherAddPath, enrollAddPath),
    },
  };

  if (isEnrollment && step === 'medical') {
    return <Table config={medicalTableConfig} />;
  } else if (isEnrollment) {
    return <Table config={otherTableConfig} key={step} />;
  }

  const renderTabContent = () => {
    if (selectedTab === 'medical') {
      return <Table config={medicalTableConfig} />;
    }

    return <Table config={otherTableConfig} />;
  };

  const renderContent = (() => {
    return (
      <Panel>
        <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
          <Tab value="medical">Medical History</Tab>
          <Tab value="surgical">Surgical History</Tab>
          <Tab value="family">Family History</Tab>
          <Tab value="social">Social History</Tab>
        </Tabs>

        {renderTabContent()}
      </Panel>
    );
  })();

  if (shouldSkipLayout) {
    return renderContent;
  }

  return <PatientLayout>{renderContent}</PatientLayout>;
};

export default MedicalRecordsIndex;
