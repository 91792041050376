import React from 'react';
import { useSelector } from 'react-redux';

import Button from '@/components/button/Button';
import InputGroup from '@/components/form/InputGroup';
import SearchGroup from '@/components/form/SearchGroup';
import SelectGroup from '@/components/form/SelectGroup';
import { getCcmComplexityOptions } from '@/selectors/options';
import { SideEffect } from '@/types/generic';
import { callStatusEnumOptions } from '@/utils/callUtils';
import {
  ccmComplexityCodes,
  ccmMinutes,
  monthOptions,
  patientStatuses,
  renderYearOptions,
} from '@/utils/dropdownUtils';
import { patientStatusEnumOptions } from '@/utils/patientUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  advancedQueryOptions: { [key: string]: any };
  onChange: SideEffect;
  onClick: SideEffect;
}

const PatientFilterModal = (props: Props) => {
  const { advancedQueryOptions, onChange, onClick } = props;

  const ccmComplexityOptions = useSelector(getCcmComplexityOptions);

  const formatSearchResult = (result: any) => ({
    label: result.attributes.name,
    value: result.id,
  });

  const formatCareManagerResult = (result: any) => {
    return {
      label: fullName(result),
      value: result.id,
    };
  };

  return (
    <div className="patient-filter__modal">
      <h3 className="patient-filter__header">Filter</h3>

      <div className="columns is-mobile">
        <div className="column is-6">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchResult}
            guideValue={advancedQueryOptions.health_system_id}
            label="Health System"
            minimumInputLength={1}
            onChange={value => onChange('health_system_id', value)}
            placeholder={
              null == localStorage.getItem('HealthSystem')
                ? ' Search Health System'
                : localStorage.getItem('HealthSystem')
            }
            searchPath="api/v1/health_systems"
          />
        </div>
        <div className="column is-6">
          <SearchGroup
            clearFalseResultOnBlur
            isDisabled={!advancedQueryOptions.health_system_id}
            guideValue={advancedQueryOptions.hospital_id}
            formatResult={formatSearchResult}
            label="Hospital"
            minimumInputLength={1}
            onChange={value => onChange('hospital_id', value)}
            placeholder={
              null == localStorage.getItem('Hospital')
                ? 'Search Hospital'
                : localStorage.getItem('Hospital')
            }
            queryParams={{
              health_system_id: advancedQueryOptions.health_system_id,
            }}
            searchPath="api/v1/hospitals"
          />
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column is-6">
          <SearchGroup
            clearFalseResultOnBlur
            isDisabled={!advancedQueryOptions.hospital_id}
            formatResult={formatSearchResult}
            guideValue={advancedQueryOptions.practice_id}
            label="Practice"
            minimumInputLength={1}
            onChange={value => onChange('practice_id', value)}
            placeholder={
              null == localStorage.getItem('Practice')
                ? 'Search Practices'
                : localStorage.getItem('Practice')
            }
            queryParams={{ hospital_id: advancedQueryOptions.hospital_id }}
            searchPath="api/v1/practices"
          />
        </div>
        <div className="column is-6">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatCareManagerResult}
            guideValue={advancedQueryOptions.care_manager_id}
            label="Care Manager"
            minimumInputLength={1}
            onChange={value => onChange('care_manager_id', value)}
            placeholder={
              null == localStorage.getItem('CareManager')
                ? 'Search Care Manager'
                : localStorage.getItem('CareManager')
            }
            searchPath="api/v1/care_managers"
          />
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column is-6">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchResult}
            guideValue={advancedQueryOptions.specialist_id}
            label="Provider"
            minimumInputLength={1}
            onChange={value => onChange('specialist_id', value)}
            placeholder={
              null == localStorage.getItem('Specialist')
                ? 'Search Provider'
                : localStorage.getItem('Specialist')
            }
            searchPath="api/v1/specialists"
          />
        </div>
        <div className="column is-6">
          <SelectGroup
            label="Call Status"
            options={callStatusEnumOptions}
            value={advancedQueryOptions.call}
            onChange={changedValue => onChange('call', changedValue)}
            placeholder="Select Call"
          />
        </div>
      </div>

      <h5 className="patient-filter__subheader">Advanced Filters</h5>

      <div className="columns is-mobile">
        <div className="column is-6">
          <div className="columns is-mobile">
            <div className="column is-6">
              <SelectGroup
                label="Date of Birth"
                options={renderYearOptions('1900')}
                value={advancedQueryOptions.year}
                onChange={value => onChange('year', value)}
                placeholder="Select Year"
              />
            </div>

            <div className="column is-6">
              <SelectGroup
                label=" "
                options={monthOptions}
                value={advancedQueryOptions.month}
                onChange={value => onChange('month', value)}
                placeholder="Select Month"
              />
            </div>
          </div>
        </div>
        <div className="column is-6">
          <SelectGroup
            label="Complexity"
            options={ccmComplexityOptions}
            value={advancedQueryOptions.ccm_complexity_code}
            onChange={value => onChange('ccm_complexity_code', value)}
            placeholder="Select Complexity"
          />
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column is-6">
          <div className="columns is-mobile">
            <div className="column is-6">
              <SelectGroup
                label="Enrollment Date"
                options={renderYearOptions('2000')}
                value={advancedQueryOptions.enrollment_year}
                onChange={value => onChange('enrollment_year', value)}
                placeholder="Select Year"
              />
            </div>

            <div className="column is-6">
              <SelectGroup
                label=" "
                options={monthOptions}
                value={advancedQueryOptions.enrollment_month}
                onChange={value => onChange('enrollment_month', value)}
                placeholder="Select Month"
              />
            </div>
          </div>
        </div>
        <div className="column is-6">
          <SelectGroup
            label="Patient Status"
            options={patientStatusEnumOptions}
            value={advancedQueryOptions.status}
            onChange={value => onChange('status', value)}
            placeholder="Select Patient Status"
          />
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column is-6">
          <SelectGroup
            label="CCM Minutes"
            options={ccmMinutes}
            value={advancedQueryOptions.ccm_minutes}
            onChange={value => onChange('ccm_minutes', value)}
            placeholder="Select CCM Minutes"
            skipEmptyPlaceholder
          />
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column">
          <div className="patient-filter__modal-button">
            <Button color="secondary" onClick={onClick}>
              {'Apply'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientFilterModal;
