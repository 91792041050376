import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { flashError } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import CheckboxGroup from '@/components/form/CheckboxGroup';
import InputIcon from '@/components/form/InputIcon';
import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { getPatientsSnapshot } from '@/selectors/patients';
import { selectCurrentUser } from '@/selectors/users';
import { UUID } from '@/types/generic';
import { UserAttributes } from '@/types/user';
import { ContactStatus } from '@/types/patient';
import { TableConfig } from '@/types/table';
import { displayCallStatus } from '@/utils/callUtils';
import { formatDateTimeWithoutTimezone, formatFriendlyDateTime, formatShortDate } from '@/utils/dateUtils';
import { displayPatientStatus } from '@/utils/patientUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { usePrevious } from '@/utils/stateUtils';
import { formatSeconds, formatSecondsToHours } from '@/utils/timeUtils';
import { formatShortDateTime } from '@/utils/dateUtils';
import { Route, useHistory } from 'react-router-dom';
import ContactCheckbox from './components/ContactCheckbox';
import PatientFilter from './components/PatientFilter';
import ColumnSelectModal from './components/ColumnSelectModal';
import RpmDashboard from './modules/rpm_dashboard/RpmDashboard';
import Modal from '@/components/modal/Modal';
import Provider from '../chart/providers/Provider';
import { userInfo } from 'os';
import Datepicker from '@/components/form/Datepicker';
import { formatIsoDate } from '@/utils/dateUtils';
import moment from 'moment';
import { flashWarning } from '@/actions/sagas/messageSaga';
import { buildRequestPath } from '@/utils/apiUtils';

export interface ColumnState {
  patient_status: boolean;
  first_name: boolean;
  last_name: boolean;
  dob: boolean;
  provider: boolean;
  practice: boolean;
  reading_day_count: boolean;
  device_count: boolean;
  device_type_name: boolean;
  care_manager: boolean;
  ccm_phone: boolean;
  ccm_admin: boolean;
  ccm_total: boolean;
  ccm_goal: boolean;
  ccm_status: boolean;
  rpm_phone: boolean;
  rpm_admin: boolean;
  rpm_total: boolean;
  rpm_goal: boolean;
  rpm_status: boolean;
  last_call: boolean;
  last_call_status: boolean;
  contacted: boolean;
  alerts: boolean;
  program: boolean,
  last_reading_date: boolean,
}

const SEARCH_PATH = 'api/v1/patients/dashboard';
const RESET_QUERY = {
  incomplete_tasks: false,
  alerts: false,
  call: '',
  care_manager_id: '',
  ccm_complexity_code: '',
  ccm_minutes: '',
  enrollment_month: '',
  enrollment_year: '',
  health_system_id: '',
  hospital_id: '',
  month: '',
  most_recent_call_status: '',
  practice_id: '',
  rpm_complexity_code: '',
  rpm_minutes: '',
  search: '',
  services: ['ccm', 'rpm', 'bhi'],
  specialist_id: '',
  status: '',
  year: '',
  language: '',
  associated: '',
  last_reading_date:''
};

const INITIAL_COLUMN_STATE: ColumnState = {
  patient_status: false,
  first_name: false,
  last_name: false,
  dob: false,
  provider: true,
  practice: false,
  reading_day_count: false,
  device_count:true,
  device_type_name:true,
  care_manager: true,
  ccm_phone: true,
  ccm_admin: true,
  ccm_total: false,
  ccm_goal: true,
  ccm_status: true,
  rpm_phone: true,
  rpm_admin: true,
  rpm_total: false,
  rpm_goal: true,
  rpm_status: true,
  last_call: false,
  last_call_status: false,
  contacted: true,
  alerts: true,
  program: false,
  last_reading_date: true,
};

const PatientsIndex = () => {
  var health_system_id = localStorage.getItem('health_system_id');
  var hospital_id = localStorage.getItem('hospital_id');
  var practice_id = localStorage.getItem('practice_id');
  var care_manager_id = localStorage.getItem('care_manager_id');
  var specialist_id = localStorage.getItem('specialist_id');
  var call = localStorage.getItem('call');
  var ccm_complexity_code = localStorage.getItem('ccm_complexity_code');
  var ccm_minutes = localStorage.getItem('ccm_minutes');
  var enrollment_month = localStorage.getItem('enrollment_month');
  var enrollment_year = localStorage.getItem('enrollment_year');
  var month = localStorage.getItem('month');
  var most_recent_call_status = localStorage.getItem('most_recent_call_status');
  var rpm_complexity_code = localStorage.getItem('rpm_complexity_code');
  var rpm_minutes = localStorage.getItem('rpm_minutes');
  var search = localStorage.getItem('search');
  var services = localStorage.getItem('services');
  var status = localStorage.getItem('status');
  var year = localStorage.getItem('year');
  var alerts = localStorage.getItem('alert');
  var incomplete_tasks = localStorage.getItem('task');
  var language = localStorage.getItem('language');
  var associated = localStorage.getItem('associated');
  var s_date = localStorage.getItem('start_date');
  var e_date = localStorage.getItem('end_date');

  var INITIAL_QUERY = {
    incomplete_tasks:
      incomplete_tasks == null ? false : incomplete_tasks !== 'true',
    alerts: alerts == null ? false : alerts !== 'true',
    call: call == null ? '' : call,
    care_manager_id: care_manager_id == null ? '' : care_manager_id,
    ccm_complexity_code: ccm_complexity_code == null ? '' : ccm_complexity_code,
    ccm_minutes: ccm_minutes == null ? '' : ccm_minutes,
    enrollment_month: enrollment_month == null ? '' : enrollment_month,
    enrollment_year: enrollment_year == null ? '' : enrollment_year,
    health_system_id: health_system_id == null ? '' : health_system_id,
    hospital_id: hospital_id == null ? '' : hospital_id,
    month: month == null ? '' : month,
    most_recent_call_status:
      most_recent_call_status == null ? '' : most_recent_call_status,
    practice_id: practice_id == null ? '' : practice_id,
    rpm_complexity_code: rpm_complexity_code == null ? '' : rpm_complexity_code,
    rpm_minutes: rpm_minutes == null ? '' : rpm_minutes,
    search: search == null ? '' : search,
    services: services == null ? ['ccm', 'rpm', 'bhi'] : services,
    specialist_id: specialist_id == null ? '' : specialist_id,
    status: status == null ? '' : status,
    year: year == null ? '' : year,
    language: language == null ? '' : language,
    associated: associated == null ? '' : associated,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const dispatch = useDispatch();
  const snapshot = useSelector(getPatientsSnapshot);
  const currentUser = useSelector(selectCurrentUser) as UserAttributes;
  const history = useHistory();
  const [start_date, SetStartDate] = useState(
    s_date == null
      ? moment()
          .subtract(1, 'months')
          .utc()
          .format()
      : s_date,
  );
  const [end_date, SetEndDate] = useState(
    e_date == null
      ? moment()
          .endOf('day')
          .utc()
          .format()
      : e_date,
  );

  const newPatientRoute = buildPath(Routes.patientsNew);
  const [perPage, setPerPage] = useState(24);
  const [searchInput, setSearchInput] = useState('');
  const [patientID, setPatientID] = useState('');

  const [modalVisible, setModalVisible] = useState(false);
  const [columnSelectModalVisible, setColumnSelectModalVisible] = useState(
    false,
  );
  const [columns, setColumns] = useState(INITIAL_COLUMN_STATE);

  const [queryOptions, setQueryOptions] = useState(INITIAL_QUERY);
  const previousSpecialistId = usePrevious(queryOptions.specialist_id);
  const previousCareManagerId = usePrevious(queryOptions.care_manager_id);

  const previousHealthSystemId = usePrevious(queryOptions.health_system_id);
  const previousHospitalId = usePrevious(queryOptions.hospital_id);
  const previousHealthSystemIdMobile = usePrevious(
    queryOptions.health_system_id,
  );
  const previousHospitalIdMobile = usePrevious(queryOptions.hospital_id);
  const de_identify = sessionStorage.getItem('de_identify');
  const patient_export = sessionStorage.getItem('patient_export');

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setIsPageLoaded(true);
    }
  }, [isLoaded]);

  interface Query {
    end_at: string;
    start_at: string;
  }

  const initialQuery = {
    end_at: end_date,
    start_at: start_date,
  };

  const [query, setQuery] = useState<Query>(initialQuery);

  const updateQuery = (field: string, value: string) => {
    const newQuery = {
      ...query,
      [field]: value,
    };

    const startAt = moment(newQuery.start_at);
    const endAt = moment(newQuery.end_at);
    SetStartDate(
      String(
        moment(newQuery.start_at)
          .utc()
          .format(),
      ),
    );
    SetEndDate(
      String(
        moment(newQuery.end_at)
          .endOf('day')
          .utc()
          .format(),
      ),
    );

    if (startAt.isBefore(endAt) || startAt.isSame(endAt, 'day')) {
      setQuery(newQuery);
    } else {
      dispatch(flashWarning('Invalid date selection'));
    }
  };

  useEffect(() => {
    localStorage.setItem('start_date', start_date);
    localStorage.setItem('end_date', end_date);
  }, [start_date, end_date]);

  useEffect(() => {
    if (
      previousHealthSystemId &&
      previousHealthSystemId !== queryOptions.health_system_id
    ) {
      if (0 == queryOptions.health_system_id.length) {
        localStorage.removeItem('hospital_id');
        localStorage.removeItem('HealthSystem');
        localStorage.removeItem('health_system_id');
        localStorage.removeItem('Hospital');
      }
      handleChange('hospital_id', '');
    }

    if (previousHospitalId && previousHospitalId !== queryOptions.hospital_id) {
      if (0 == queryOptions.hospital_id.length) {
        localStorage.removeItem('hospital_id');
        localStorage.removeItem('Hospital');
        localStorage.removeItem('practice_id');
      }

      handleChange('practice_id', '');
    }
    if (0 == queryOptions.practice_id.length) {
      localStorage.removeItem('practice_id');
      localStorage.removeItem('Practice');
    }
  }, [
    queryOptions.health_system_id,
    queryOptions.hospital_id,
    queryOptions.practice_id,
  ]);
  useEffect(() => {
    if (previousCareManagerId == currentUser.id) {
      handleChange('status', '');
      localStorage.removeItem('status');
    }
    if (
      previousCareManagerId &&
      previousCareManagerId !== queryOptions.care_manager_id &&
      0 == queryOptions.care_manager_id.length
    ) {
      localStorage.removeItem('care_manager_id');
      localStorage.removeItem('CareManager');
    }
    if (
      previousSpecialistId &&
      previousSpecialistId !== queryOptions.specialist_id
    ) {
      if (0 == queryOptions.specialist_id.length && isPageLoaded) {
        localStorage.removeItem('specialist_id');
        localStorage.removeItem('Specialist');
      } else {
        handleChange('specialist_id', specialist_id);
      }
    }
  }, [queryOptions.care_manager_id, queryOptions.specialist_id]);

  useEffect(() => {
    if (
      previousHealthSystemIdMobile &&
      previousHealthSystemIdMobile !== queryOptions.health_system_id
    ) {
      if (0 == queryOptions.health_system_id.length) {
        localStorage.removeItem('hospital_id');
        localStorage.removeItem('HealthSystem');
        localStorage.removeItem('health_system_id');
        localStorage.removeItem('Hospital');
      }
      handleChange('hospital_id', '');
    }

    if (
      previousHospitalIdMobile &&
      previousHospitalIdMobile !== queryOptions.hospital_id
    ) {
      if (0 == queryOptions.hospital_id.length) {
        localStorage.removeItem('hospital_id');
        localStorage.removeItem('Hospital');
        localStorage.removeItem('practice_id');
        localStorage.removeItem('Practice');
      }
      handleChange('practice_id', '');
    }
  }, [
    queryOptions.health_system_id,
    queryOptions.hospital_id,
    queryOptions.practice_id,
  ]);

  useEffect(() => {
    const userColumnDefaults = localStorage.getItem('userColumnDefaults');
    if (userColumnDefaults) {
      setColumns(JSON.parse(userColumnDefaults));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('userColumnDefaults', JSON.stringify(columns));
  }, [columns]);

  const handleAddPatient = () => {
    history.push(newPatientRoute);
  };

  const handleChange = (field: string, value: any) => {
    if (value) {
      localStorage.setItem(field, value);
    }

    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleClearFilters = () => {
    var userColumnDefault = localStorage.getItem('userColumnDefaults');

    localStorage.clear();

    setQueryOptions(RESET_QUERY);

    localStorage.setItem('userColumnDefaults', userColumnDefault);
  };

  const handlePerPageChange = (value: any) => {
    setPerPage(value);
  };

  const handleSearchChange = (value: any) => {
    setSearchInput(value);
  };

  const handleSearchInput = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleClickExport = () => {
    if (patient_export == '1') {
      dispatch(
        flashWarning(
          'Export patients data not allowed, please contact admin for permission',
        ),
      );
    } else if (patient_export == '0') {
      const path = buildRequestPath('/api/v1/patient/export_csv', {
        services: services,
        incomplete_tasks: JSON.stringify(queryOptions.incomplete_tasks),
        per: 20000,
        start: start_date,
        end: end_date,
        id: patientID,
        alerts: JSON.stringify(queryOptions.alerts),
        call: queryOptions.call,
        care_manager_id: queryOptions.care_manager_id,
        ccm_complexity_code: queryOptions.ccm_complexity_code,
        ccm_minutes: queryOptions.ccm_minutes,
        enrollment_month: queryOptions.enrollment_month,
        enrollment_year: queryOptions.enrollment_year,
        health_system_id: queryOptions.health_system_id,
        hospital_id: queryOptions.hospital_id,
        month: queryOptions.month,
        most_recent_call_status: queryOptions.most_recent_call_status,
        practice_id: queryOptions.practice_id,
        rpm_complexity_code: queryOptions.rpm_complexity_code,
        rpm_minutes: queryOptions.rpm_minutes,
        search: queryOptions.search,
        specialist_id: queryOptions.specialist_id,
        status: queryOptions.status,
        year: queryOptions.year,
        language: queryOptions.language,
        associated: queryOptions.associated,
        coloumns: JSON.stringify(columns),
        sort: 'first_name:asc',
      });
      window.open(path, '_blank');
    }
  };

  const handlFilterClick = () => {
    setModalVisible(true);
  };

  const handleApplyClick = () => {
    setQueryOptions({
      ...queryOptions,
      ...queryOptions,
    });

    setModalVisible(false);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const toggleColumnSelectModal = () => {
    setColumnSelectModalVisible(!columnSelectModalVisible);
  };

  const updateColumnState = (nextState: ColumnState) => {
    setColumns(nextState);
  };

  const toggleService = (service: string) => {
    if (serviceIncluded(service)) {
      handleChange(
        'services',
        [...queryOptions.services].filter(s => s !== service),
      );
    } else {
      handleChange('services', [...queryOptions.services, service]);
    }
  };

  const toggleMyPatients = () => {
    if (queryOptions.care_manager_id) {
      localStorage.removeItem('status');

      setQueryOptions({
        ...queryOptions,
        care_manager_id: '',
        status: '',
      });
    } else {
      localStorage.setItem('status', '0');
      localStorage.setItem('care_manager_id', currentUser.id);
      setQueryOptions({
        ...queryOptions,
        care_manager_id: currentUser.id,
        status: '0',
      });
    }
  };

  const togglePatientsWithAlerts = () => {
    localStorage.setItem('alert', String(queryOptions.alerts));

    if (queryOptions.alerts) {
      setQueryOptions({
        ...queryOptions,
        alerts: false,
      });
    } else {
      setQueryOptions({
        ...queryOptions,
        alerts: true,
      });
    }
  };

  const togglePatientsWithIncompleteTasks = () => {
    localStorage.setItem('task', String(queryOptions.incomplete_tasks));
    setQueryOptions({
      ...queryOptions,
      incomplete_tasks: !queryOptions.incomplete_tasks,
    });
  };

  const serviceIncluded = (service: string) => {
    return queryOptions.services.includes(service);
  };

  const formatStatus = (entry: any, type: 'ccm' | 'rpm'): string => {
    const goal = entry[`total_${type}_goal`];

    if (!goal) {
      return '';
    }

    const totalMonthly = entry[`remaining_${type}_seconds`] || 0;

    return totalMonthly >= 0 ? 'Incomplete' : 'Complete';
  };

  const handleToggleContactStatus = async (
    id: UUID,
    value: ContactStatus,
  ): Promise<any> => {
    const url = `api/v1/patients/${id}/status`;

    const body = {
      patient: {
        contact_status: value,
      },
    };

    try {
      await Api.utility.patch(url, body);
    } catch (err) {
      dispatch(flashError('Error updating patient'));
    }
  };

  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      ...queryOptions,
      per: perPage,
      start: start_date,
      end: end_date,
    },
    initialSort: 'first_name:asc',
    columns: [
      {
        header: 'Program',
        isSortable: true,
        attribute: 'patient_program',
        hideColumn: columns.program,
      },
      {
        header: 'Patient Status',
        isSortable: true,
        attribute: 'status',
        formatAttribute: displayPatientStatus,
        hideColumn: columns.patient_status,
      },
      {
        header: 'First Name',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'first_name',
        className: 'table__cell-highlight',
        hideColumn: columns.first_name,
        formatEntry: (entry: any) => {
          if (!entry.first_name) {
            return '';
          }

          const name = de_identify == '0' ? `${entry.first_name}` : '---------';
          var url: string;
          if (entry.total_rpm_goal) {
            url = buildPath(Routes.patientsCareManagementRpm, { id: entry.id });
          } else if (entry.total_ccm_goal) {
            url = buildPath(Routes.patientsCareManagement, { id: entry.id });
          } else {
            url = buildPath(Routes.patientsBhiPlan, { id: entry.id });
          }

          return (
            <Link style={{ color: '#006989' }} to={url}>
              {name}
            </Link>
          );
        },
      },
      {
        header: 'Last Name',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'last_name',
        className: 'table__cell-highlight',
        hideColumn: columns.last_name,
        formatEntry: (entry: any) => {
          if (!entry.last_name) {
            return '';
          }
          setPatientID(entry.id);
          const name = de_identify == '0' ? `${entry.last_name}` : '---------';
          var url: string;
          if (entry.total_rpm_goal) {
            url = buildPath(Routes.patientsCareManagementRpm, { id: entry.id });
          } else if (entry.total_ccm_goal) {
            url = buildPath(Routes.patientsCareManagement, { id: entry.id });
          } else {
            url = buildPath(Routes.patientsBhiPlan, { id: entry.id });
          }

          return (
            <Link style={{ color: '#006989' }} to={url}>
              {name}
            </Link>
          );
        },
      },
      {
        header: 'DOB',
        isSortable: true,
        attribute: 'date_of_birth',
        formatAttribute: formatShortDate,
        hideColumn: columns.dob,
      },
      {
        header: 'Reading Days Count',
        isSortable: true,
        attribute: 'readings_day_count',
        hideColumn: columns.reading_day_count,
      },
      {
        header: 'Device Count',
        isSortable: true,
        attribute: 'device_counts',
        hideColumn: columns.device_count,
      },
      {
        header: 'Device Name',
        isSortable: true,
        attribute: 'devices_type_name',
        hideColumn: columns.device_type_name,
      },
      {
        header: 'Provider',
        isSortable: true,
        attribute: 'specialist_name',
        hideColumn: columns.provider,
      },
      {
        header: 'Practice',
        isSortable: true,
        attribute: 'practice_name',
        hideColumn: columns.practice,
      },
      {
        header: 'Care Manager',
        isSortable: true,
        attribute: 'care_managers.first_name',
        formatEntry: entry => {
          return [entry.care_manager_first_name, entry.care_manager_last_name]
            .filter(n => n)
            .join(' ');
        },
        hideColumn: columns.care_manager,
      },
      serviceIncluded('ccm') && {
        header: 'CCM Phone',
        isSortable: true,
        attribute: 'total_ccm_phone',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.ccm_phone,
      },
      serviceIncluded('ccm') && {
        header: 'CCM Admin',
        isSortable: true,
        attribute: 'total_ccm_admin',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.ccm_admin,
      },
      serviceIncluded('ccm') && {
        header: 'CCM Total',
        isSortable: true,
        attribute: 'total_ccm_time',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.ccm_total,
      },
      serviceIncluded('ccm') && {
        header: 'CCM Goal',
        isSortable: true,
        attribute: 'total_ccm_goal',
        formatEntry: (entry: any) => formatSeconds(entry.total_ccm_goal * 60),
        hideColumn: columns.ccm_goal,
      },
      serviceIncluded('ccm') && {
        header: 'CCM Status',
        isSortable: true,
        attribute: 'remaining_ccm_seconds',
        formatEntry: (entry: any) => formatStatus(entry, 'ccm'),
        hideColumn: columns.ccm_status,
      },
      serviceIncluded('rpm') && {
        header: 'RPM Phone',
        isSortable: true,
        attribute: 'total_rpm_phone',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.rpm_phone,
      },
      serviceIncluded('rpm') && {
        header: 'RPM Admin',
        isSortable: true,
        attribute: 'total_rpm_admin',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.rpm_admin,
      },
      serviceIncluded('rpm') && {
        header: 'RPM Total',
        isSortable: true,
        attribute: 'total_rpm_time',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.rpm_total,
      },
      serviceIncluded('rpm') && {
        header: 'RPM Goal',
        isSortable: true,
        attribute: 'total_rpm_goal',
        formatEntry: (entry: any) => formatSeconds(entry.total_rpm_goal * 60),
        hideColumn: columns.rpm_goal,
      },
      serviceIncluded('rpm') && {
        header: 'RPM Status',
        isSortable: true,
        attribute: 'remaining_rpm_seconds',
        formatEntry: (entry: any) => formatStatus(entry, 'rpm'),
        hideColumn: columns.rpm_status,
      },
      {
        header: 'Last Call',
        isSortable: true,
        attribute: 'most_recent_call_time',
        formatAttribute: formatShortDate,
        hideColumn: columns.last_call,
      },
      {
        header: 'Last Call Status',
        isSortable: true,
        attribute: 'most_recent_call_status',
        formatAttribute: displayCallStatus,
        hideColumn: columns.last_call_status,
      },
      {
        header: 'Last Reading Date',
        isSortable: true,
        attribute: 'last_reading_date',
        formatAttribute: (entry: any) => {
            return formatShortDateTime(entry);
        },
        hideColumn: columns.last_reading_date,
      },
      {
        header: 'Contacted',
        isSortable: true,
        attribute: 'contact_status',
        formatEntry: (entry: any) => {
          return (
            <ContactCheckbox
              initialValue={entry.contact_status}
              onToggleStatus={handleToggleContactStatus}
              patientId={entry.id}

            />
          );
        },
        hideColumn: columns.contacted,
      },
      {
        header: 'Most Recent Alert',
        isSortable: true,
        attribute: 'most_recent_alert',
        formatEntry: (entry: any) => {
          return formatShortDate(entry.most_recent_alert);
        },
        hideColumn: columns.alerts,
      },
    ].filter(n => n),
    rows: {
      onClickPath: (entry: any) => {
        if (entry.total_rpm_goal) {
          history.push(
            buildPath(Routes.patientsCareManagementRpm, { id: entry.id }),
          );
        } else if (entry.total_ccm_goal) {
          history.push(
            buildPath(Routes.patientsCareManagement, { id: entry.id }),
          );
        } else {
          history.push(buildPath(Routes.patientsBhiPlan, { id: entry.id }));
        }
      },
    },
  };

  return (
    <>
      <Modal
        isNarrow={true}
        isVisible={columnSelectModalVisible}
        onCloseModal={toggleColumnSelectModal}>
        <ColumnSelectModal
          columns={columns}
          onCloseModal={toggleColumnSelectModal}
          updateColumnState={updateColumnState}
        />
      </Modal>
      <RpmDashboard />

      <Panel>
        <div className="columns patients-index__header-wrapper">
          <div className="column is-4-desktop">
            <h2 className="patients-index__header">Patients</h2>
          </div>
          <div className="column is-4-desktop">
            <InputIcon
              value={searchInput}
              onChange={handleSearchChange}
              placeholder="Search"
              onClick={() => handleSearchInput('search', searchInput)}
              onPressEnter={() => handleSearchInput('search', searchInput)}
            />
          </div>
          <div className="column is-2-desktop patients-index__header-button">
            <Button
              style={patient_export == '0' ? 'filled' : 'hide'}
              color="secondary"
              onClick={handleClickExport}>
              Export to CSV
            </Button>
          </div>
          <div className="column is-2-desktop patients-index__header-button">
            <Button color="secondary" onClick={handleAddPatient}>
              Add Patient
            </Button>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="patients-index__checkboxes">
              <CheckboxGroup
                label="Chronic Care Managerment"
                onChange={() => toggleService('ccm')}
                value={serviceIncluded('ccm')}
              />
              <CheckboxGroup
                label="Remote Patient Monitoring"
                onChange={() => toggleService('rpm')}
                value={serviceIncluded('rpm')}
              />
              <CheckboxGroup
                label="Behavioral Health Integration"
                onChange={() => toggleService('bhi')}
                value={serviceIncluded('bhi')}
              />
              <CheckboxGroup
                label="My Patients"
                onChange={() => toggleMyPatients()}
                value={queryOptions.care_manager_id}
              />
              <CheckboxGroup
                label="Patients With Alerts"
                onChange={() => togglePatientsWithAlerts()}
                value={queryOptions.alerts}
              />
              <CheckboxGroup
                label="Patients With Incomplete Tasks"
                onChange={() => togglePatientsWithIncompleteTasks()}
                value={queryOptions.incomplete_tasks}
              />
            </div>
          </div>
        </div>

        <div className="level-right">
          <div className="columns is-mobile">
            <div className="column">
              <Datepicker
                label="From"
                onChange={(value: string) => updateQuery('start_at', value)}
                value={s_date == null ? query.start_at : s_date}
              />
            </div>

            <div className="column">
              <Datepicker
                label="To"
                onChange={(value: string) => updateQuery('end_at', value)}
                value={query.end_at}
              />
            </div>
          </div>
        </div>
        <PatientFilter
          closeModal={closeModal}
          toggleColumnSelectModal={toggleColumnSelectModal}
          modalVisible={modalVisible}
          columnSelectModalVisible={columnSelectModalVisible}
          onApplyClick={handleApplyClick}
          onChange={handleChange}
          onClearFilters={handleClearFilters}
          onFilterClick={handlFilterClick}
          onPerPageChange={handlePerPageChange}
          perPage={perPage}
          queryOptions={queryOptions}
        />

        <Table config={tableConfig} snapshot={snapshot} />
      </Panel>
    </>
  );
};

export default PatientsIndex;
