import React from 'react';

import Button from '@/components/button/Button';
import Modal from '@/components/modal/Modal';
import { SideEffect } from '@/types/generic';

interface Props {
  isVisible: boolean;
  onAccept: SideEffect;
  onClose: SideEffect;
}

const ConfirmDeleteModal = (props: Props) => {
  const { isVisible, onAccept, onClose } = props;

  return (
    <Modal isVisible={isVisible} onCloseModal={onClose}>
      <div className="modal__content">
        <h3 className="modal__header">Confirm delete</h3>
        <span className="modal__info">
          Are you sure you want to delete this entry?
        </span>
      </div>

      <div className="modal__actions">
        <Button color="white" onClick={onAccept}>
          Delete
        </Button>

        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
