import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  shouldDisplayAction?: boolean;
  isEnrollment?: boolean;
}

const EnrollmentActions = (props: Props) => {
  const { children, isEnrollment, shouldDisplayAction } = props;
  return (
    <div className="enrollment__actions">
      <div className="enrollment__actions-left" />
      <div
        className={classNames('enrollment__actions-right', {
          'should-display-action': shouldDisplayAction,
          'is-visible': !isEnrollment,
        })}>
        {children}
      </div>
    </div>
  );
};

export default EnrollmentActions;
