import moment from 'moment';

import { Option } from '@/types/form';
import { formatIsoDateTime, formatShortDate } from './dateUtils';

/**
 * Creates a list of of label/value options for use in Select or similar components from a list of strings
 * @param  {string[]} ...options
 * @returns Option
 */

export const createOptionsFromList = (...options: string[]): Option[] => {
  return options.map(o => ({
    label: o,
    value: o,
  }));
};

export const ccmComplexityCodes = () => {
  return [
    {
      label: '(CPT Code - 99490) - Non Complex',
      value: '(CPT Code - 99490) - Non Complex',
    },
    {
      label: '(CPT Code - 99487) - Complex',
      value: '(CPT Code - 99487) - Complex',
    },
    {
      label: '(CPT Code 99489) - Most Complex',
      value: '(CPT Code 99489) - Most Complex',
    },
  ];
};

export const timePeriodUnitDropdowns = [
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' },
];

export const genderOptions = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

export const roleOptionsSearch = [
  { label: 'All', value: '' },
  { label: 'Care Manager', value: 'standard' },
  { label: 'Admin', value: 'admin' },
  { label: 'Billing Admin', value: 'billing_admin' },
  { label: 'Super Admin', value: 'super_admin' },
];

export const languageOptions = () => {
  return [
    { label: 'English', value: 'English' },
    { label: 'Arabic', value: 'Arabic' },
    { label: 'Assyrian', value: 'Assyrian' },
    { label: 'Chinese', value: 'Chinese' },
    { label: 'Farsi', value: 'Farsi' },
    { label: 'French', value: 'French' },
    { label: 'Filipino', value: 'Filipino' },
    { label: 'Hindi', value: 'Hindi' },
    { label: 'Japanese', value: 'Japanese' },
    { label: 'Korean', value: 'Korean' },
    { label: 'Portuguese', value: 'Portuguese' },
    { label: 'Polish', value: 'Polish' },
    { label: 'Russian', value: 'Russian' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'Taiwaneze', value: 'Taiwaneze' },
  ];
};

export const patientStatuses = [
  { label: 'Active', value: 'active' },
  { label: 'Deactivated', value: 'deactivated' },
  { label: 'New Enrollment', value: 'new_enrollment' },
  { label: 'On Hold', value: 'on_hold' },
  { label: 'On Hold - not answering phone', value: 'on_hold_not_answering_phone' },
  { label: 'On Hold - not using device ', value: 'on_hold_not_using_device' },
  { label: 'Device Issue', value: 'device_issue' },
  { label: 'Ineligible Condition', value: 'ineligible_conditions' },
  { label: 'Ineligible Insurance ', value: 'ineligible_insurance' },
  { label: 'Considering', value: 'considering' },
  { label: 'Declined', value: 'declined' },
  { label: 'Not Recommended', value: 'not_recommended' },
  { label: 'Patient Deceased', value: 'patient_decreased' },
  { label: 'Switched PCP', value: 'switched_pcp' },

];


export const stateOptions = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Deleware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HA' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];
export const timeOptions = [
  { label: 'GMT(Greenwich Mean Time)', value: 'UTC' },
  { label: 'UTC(Universal Coordinated Time)', value: 'UTC' },
  { label: 'ECT(European Central Time)', value: 'GMT+01:00' },
  { label: 'EET(Eastern European Time)', value: 'GMT+02:00' },
  { label: 'ART((Arabic) Egypt Standard Time)', value: 'GMT+02:00' },
  { label: 'EAT(Eastern African Time)', value: 'GMT+03:00' },
  { label: 'MET(Middle East Time)', value: 'GMT+03:30' },
  { label: 'NET(Near East Time)', value: 'GMT+04:00' },
  { label: 'PLT(Pakistan Lahore Time)', value: 'GMT+05:00' },
  { label: 'IST(India Standard Time)', value: 'GMT+05:30' },
  { label: 'BST(Bangladesh Standard Time)', value: 'GMT+06:00' },
  { label: 'VST(Vietnam Standard Time)', value: 'GMT+07:00' },
  { label: 'CTT(China Taiwan Time	)', value: 'GMT+08:00' },
  { label: 'JST(Japan Standard Time)', value: 'GMT+09:00' },
  { label: 'ACT(Australia Central Time)', value: 'GMT+09:30' },
  { label: 'AET(Australia Eastern Time)', value: 'GMT+10:00' },
  { label: 'SST(Solomon Standard Time)', value: 'GMT+11:00' },
  { label: 'NST(New Zealand Standard Time)', value: 'GMT+12:00' },
  { label: 'MIT(Midway Islands Time)', value: 'GMT-11:00' },
  { label: 'HST(Hawaii Standard Time)', value: 'GMT-10:00' },
  { label: 'AST(Alaska Standard Time)', value: 'GMT-09:00' },
  { label: 'PST(Pacific Standard Time)', value: 'GMT-08:00' },
  { label: 'PNT(Phoenix Standard Time)', value: 'GMT-07:00' },
  { label: 'MST(Mountain Standard Time)', value: 'GMT-07:00' },
  { label: 'CST(Central Standard Time)', value: 'GMT-06:00' },
  { label: 'EST(Eastern Standard Time)', value: 'GMT-05:00' },
  { label: 'IET(Indiana Eastern Standard Time)', value: 'GMT-05:00' },
  { label: 'PRT(Puerto Rico and US Virgin Islands Time)', value: 'GMT-04:00' },
  { label: 'CNT(Canada Newfoundland Time)', value: 'GMT-03:30' },
  { label: 'AGT(Argentina Standard Time)', value: 'GMT-03:00' },
  { label: 'BET(Brazil Eastern Time)', value: 'GMT-03:00' },
  { label: 'CAT(Central African Time)', value: 'GMT-01:00' },
  
];
export const monthOptions = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const callStatusOptions = [
  { label: 'Call Successful', value: 'call_successful' },
  { label: 'Talk to Patient', value: 'talk_to_patient' },
  { label: 'Talk to Other Person', value: 'talk_to_other_person' },
  { label: 'Left Voicemail', value: 'left_voicemail' },
  { label: 'No Answer', value: 'no_answer' },
  { label: 'Wrong Number', value: 'wrong_number' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'No Status', value: 'no_status' },
];

export const renderYearOptions = (startDate: string) => {
  const startYear = moment(startDate).format('YYYY');
  const currentYear = moment().format('YYYY');
  const yearOptions = [];

  const start = +startYear;
  const current = +currentYear;

  if (start === current) {
    return [{ label: startYear, value: startYear }];
  }

  for (let i = current; i > start; i--) {
    yearOptions.push({ label: i, value: i });
  }

  return yearOptions;
};

export const ccmMinutes = [
  { label: 'All', value: ':' },
  { label: 'Over 60', value: '60:' },
  { label: '20-60', value: '20:60' },
  { label: 'Under 20', value: '0:20' },
  { label: '15-20', value: '15:20' },
  { label: '10-15', value: '10:15' },
  { label: '5-10', value: '5:10' },
  { label: '0', value: '0:0' },
];

export const lookup = {
  screening: 'Screening',
  counseling: 'Counseling',
  preventive_medication: 'Preventive Medicine',
  counseling_screening: 'Counseling, Screening',
  counseling_preventive_medication: 'Counseling, Preventive Medicine',
};

export const screenTypes = [
  { label: 'Screening', value: 'screening' },
  { label: 'Counseling', value: 'counseling' },
  { label: 'Preventive Medicine', value: 'preventive_medication' },
  { label: 'Counseling, Screening', value: 'counseling_screening' },
  {
    label: 'Counseling, Preventive Medicine',
    value: 'counseling_preventive_medication',
  },
];

export const statusFilterOptions = [
  { label: 'All', value: ' ' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export const orphanDeviceStatusFilterOptions = [
  { label: 'All', value: ' ' },
  { label: 'Associated with patient', value: 'non-orphan' },
  { label: 'Not associated with patient', value: 'orphan' },
];

export const statusOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export const userStatusOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'deactivated' },
];

export const patientsExportOptions = [
  { label: 'Allow', value: '0' },
  { label: 'Deny', value: '1' },
];

export const mockOptions = [
  { label: 'Option 1', value: '1' },
  { label: 'Option 2', value: '2' },
];

export const userStatuses = [
  { label: 'Active', value: '0' },
  { label: 'Deactivated', value: '1' },
];

export const allergyTypes = [
  { label: 'Environmental', value: 'environmental' },
  { label: 'Medication', value: 'medication' },
];

export const mathOptions = [
  { label: 'Less Than', value: 'less_than' },
  { label: 'Greater Than', value: 'greater_than' },
  { label: 'Range', value: 'range' },
];

export const notifyOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const notifyCareManagerOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const readingTypeFilterOptions = [
  { label: 'None', value: 'none' },
  { label: 'Blood Pressure', value: 'blood_pressure' },
  { label: 'Blood Glucose', value: 'blood_glucose' },
  { label: 'Blood Oxygen', value: 'blood_oxygen'},
  { label: 'Weight', value: 'weight'},
];

export const relationOptions = [
  { label: 'Parent', value: 'Parent' },
  { label: 'Sibling', value: 'Sibling' },
  { label: 'Partner', value: 'Partner' },
  { label: 'Friend', value: 'Friend' },
  { label: 'Neighbor', value: 'Neighbor' },
  { label: 'Caregiver', value: 'Caregiver' },
  { label: 'Cousin', value: 'Cousin' },
  { label: 'Other family member', value: 'Other family member' },
  { label: 'Other', value: 'Other' },
];

export const titleOptions = createOptionsFromList(
  'Mr.',
  'Ms.',
  'Mrs.',
  'M.',
  'Miss',
  'Mx.',
);
export const suffixOptions = createOptionsFromList(
  'Jr.',
  'Sr.',
  'II',
  'III',
  'IV',
);

export const createWeeklyDateOptions = (
  start: any,
  iterations: number = 20,
): [Option[], string] => {
  const startDate = moment(start).startOf('week');
  const options = [];
  let startValue;

  for (let i = 0; i < iterations; i++) {
    const testDate = moment(startDate).subtract(i, 'weeks');
    options.push({
      label: formatShortDate(testDate),
      value: formatIsoDateTime(testDate),
    });

    if (!i) {
      startValue = formatIsoDateTime(testDate);
    }
  }

  return [options, startValue];
};

export const minuteOptions = [
  { label: '10 minutes', value: '10' },
  { label: '20 minutes', value: '20' },
  { label: '30 minutes', value: '30' },
  { label: '40 minutes', value: '40' },
  { label: '50 minutes', value: '50' },
  { label: '60 minutes', value: '60' },
  { label: '70 minutes', value: '70' },
  { label: '80 minutes', value: '80' },
  { label: '90 minutes', value: '90' },
];

export const upcomingAppointmentStatusOptions = [
  {
    label: 'Any',
    value: 'any',
  },
  {
    label: 'Created',
    value: 'created',
  },
  { label: 'Confirmed', value: 'confirmed' },
];

export const pastAppointmentStatusOptions = [
  {
    label: 'Any',
    value: 'any',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
  { label: 'Cancelled', value: 'cancelled' },
];

export const appointmentYearOptions = [
  { label: 'None', value: 'none' },
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
];
