import { createSelector } from 'reselect';

import { AnswerSet } from '@/types/answer';
import { AppState } from '@/types/app';
import { BhiState } from '@/types/bhi';
import { Condition } from '@/types/condition';
import { UUID } from '@/types/generic';
import {
  TrackingQuestionAttributes,
  TrackingQuestionSet,
} from '@/types/tracking_question';
import { selectAnswerSets, selectTrackingQuestionSets } from './options';

export const selectBhiState = (state: AppState): BhiState => state.bhi;

export const selectCurrentCarePlan = createSelector(
  selectBhiState,
  state => state.current,
);

export const selectCurrentCarePlanId = createSelector(
  selectCurrentCarePlan,
  state => state?.data.id,
);

export const selectCurrentCarePlanStatus = createSelector(
  selectCurrentCarePlan,
  state => (state ? state.data.attributes.status : []),
);

export const selectCurrentCarePlanConditions = createSelector(
  selectCurrentCarePlan,
  state => (state ? state.data.attributes.conditions : []),
);

export const selectCurrentCarePlanConditionIds = createSelector(
  selectCurrentCarePlanConditions,
  state => state.map((c: Condition) => c.id),
);

export const selectIsFetchingCarePlan = createSelector(
  selectBhiState,
  state => state.isFetchingCarePlan,
);

export const selectCurrentCarePlanTrackingQuestionSets = createSelector(
  [selectCurrentCarePlanConditionIds, selectTrackingQuestionSets],
  (conditionIds: UUID[], questionSets) => {
    return questionSets.data.filter((questionSet: TrackingQuestionSet) =>
      conditionIds.includes(questionSet.attributes.condition_id),
    );
  },
);

// This adds answer set data to each of the tracking questions
export const selectedFormattedTrackingData = createSelector(
  [selectCurrentCarePlanTrackingQuestionSets, selectAnswerSets],
  (questionSets: TrackingQuestionSet[], answerSets) => {
    return questionSets.map((questionSet: TrackingQuestionSet) => {
      return {
        ...questionSet,
        attributes: {
          ...questionSet.attributes,
          tracking_questions: questionSet.attributes.tracking_questions.map(
            (question: TrackingQuestionAttributes) => ({
              ...question,
              answer_set: answerSets.data.find(
                (answerSet: AnswerSet) =>
                  answerSet.id === question.answer_set_id,
              ),
            }),
          ),
        },
      };
    });
  },
);

export const selectTotalNumberOfQuestions = createSelector(
  selectedFormattedTrackingData,
  state => {
    return state.reduce((total: number, set: TrackingQuestionSet) => {
      return total + set.attributes.tracking_questions.length;
    }, 0);
  },
);
