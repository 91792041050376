import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Api from '@/api/Api';
import ButtonLink from '@/components/button/ButtonLink';
import Constraint from '@/components/constraint/Constraint';
import QuestionnaireDisplay from '@/modules/questionniares/display/QuestionnaireDisplay';
import { getCurrentUser } from '@/selectors/users';
import { Questionnaire } from '@/types/v2/questionnaire';
import { Specialist } from '@/types/v2/specialist';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';

const Questionnaires = () => {
  const user = useSelector(getCurrentUser);

  const loader = useLoadingState('questionnaires');
  const notifier = useNotifier();

  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);

  const getQuestionnaires = async (): Promise<void> => {
    try {
      const specialist = new Modeler<Specialist>(user).build();
      const url = buildPath(
        Routes.api2.questionnaires,
        null,
        { practice_id: specialist.practice_id, sort: 'status:desc' },
        [
          'questionnaire_questions',
          'questionnaire_questions.questionnaire_answers',
        ],
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<Questionnaire[]>(response.data, {
        generations: 2,
      }).build();

      setQuestionnaires(resource);
    } catch (err) {
      notifier.error(err);
    }
  };

  const renderQuestionnaires = (() => {
    if (loader.isLoading('questionnaires')) {
      return <loader.activity />;
    }

    if (!questionnaires.length) {
      return (
        <div className="columns">
          <div className="column">
            <p className="table__is-empty">No questionnaires added</p>
          </div>
        </div>
      );
    }

    return questionnaires.map(questionnaire => (
      <div className="columns" key={questionnaire.id}>
        <div className="column">
          <QuestionnaireDisplay
            constraintSize="large"
            questionnaire={questionnaire}
            type="practice"
          />
        </div>
      </div>
    ));
  })();

  useEffect(() => {
    if (user) {
      getQuestionnaires();
    }
  }, [user]);

  return (
    <Constraint size="large">
      <div className="columns">
        <div className="column">
          <div className="is-justified-right">
            <ButtonLink
              color="white"
              to={Routes.provider.practice.show.questionnaires.new}>
              Add Questionnaire to Practice
            </ButtonLink>
          </div>
        </div>
      </div>

      {renderQuestionnaires}
    </Constraint>
  );
};

export default Questionnaires;
