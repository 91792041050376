import React, { ReactNode } from 'react';
import Icon from '../icon/Icon';

export interface Props {
  children: ReactNode;
  isCollapsed: boolean;
  onClickCollapse: () => void;
}

const DashboardHeader = ({
  children,
  isCollapsed,
  onClickCollapse,
}: Props): JSX.Element => {
  const renderCollapseLink = (() => {
    const rotation = isCollapsed ? 0 : 180;
    return (
      <a onClick={onClickCollapse}>
        <span>Collapse</span>
        <Icon icon="caretDown" rotation={rotation} />
      </a>
    );
  })();

  return (
    <div className="dashboard-section-header">
      <h1>{children}</h1>
      {renderCollapseLink}
    </div>
  );
};

export default DashboardHeader;
