import React from 'react';

import FilterHeader from '@/components/filters/FilterHeader';
import InputIcon from '@/components/form/InputIcon';
import SearchGroup, { formatSearchName } from '@/components/form/SearchGroup';
import { Routes } from '@/utils/routeUtils';

export interface FormState {
  health_system_id: string;
  hospital_id: string;
  practice_id: string;
  search: string;
  per: string;
}

interface Props {
  form: FormState;
  onChangeForm: (field: string, value: string) => void;
  onChangeSearch: (value: string) => void;
  onClearFilters: () => void;
  onStartSearch: () => void;
  searchQuery: string;
}

const SearchHeader = (props: Props): JSX.Element => {
  const {
    form,
    onChangeForm,
    onChangeSearch,
    onClearFilters,
    onStartSearch,
    searchQuery,
  } = props;

  return (
    <>
      <div className="columns is-mobile">
        <div className="column is-6">
          <h2 className="patients-index__header">Providers</h2>
        </div>

        <div className="column is-6">
          <InputIcon
            onChange={value => onChangeSearch(value)}
            onClick={onStartSearch}
            onPressEnter={onStartSearch}
            placeholder="Search"
            value={searchQuery}
          />
        </div>
      </div>

      <FilterHeader
        includePerPage
        onClearFilters={onClearFilters}
        onChangeQuery={onChangeForm}
        query={form}
        perPageDesktopClass="is-2"
        perPageTabletClass="is-3"
        shouldAddColumnClasses>
        <>
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchName}
            guideValue={form.health_system_id}
            label="Health System"
            minimumInputLength={1}
            onChange={(value: string) =>
              onChangeForm('health_system_id', value)
            }
            placeholder="Search Health Systems"
            searchPath={Routes.api.healthSystems}
          />

          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchName}
            guideValue={form.hospital_id}
            label="Hospital"
            minimumInputLength={1}
            onChange={(value: string) => onChangeForm('hospital_id', value)}
            placeholder="Search Hospitals"
            queryParams={{ health_system_id: form.health_system_id }}
            searchPath={Routes.api.hospitals}
          />

          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchName}
            guideValue={form.practice_id}
            label="Practice"
            minimumInputLength={1}
            onChange={(value: string) => onChangeForm('practice_id', value)}
            placeholder="Search Practices"
            queryParams={{ hospital_id: form.hospital_id }}
            searchPath={Routes.api.practices}
          />
        </>
      </FilterHeader>
    </>
  );
};

export default SearchHeader;
