import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setActiveKeypadModalVisibility,
  setCallSid,
  setCallStatus,
  setCompleteModalVisibility,
  setCurrentConnection,
  setDeviceStatus,
  setTimerAmount,
  stopTimer,
} from '@/actions/reducers/call_manager';
import {
  getIncomingCall,
  initializeTwilio,
} from '@/actions/sagas/callManagerSaga';
import Api from '@/api/Api';
import {
  selectCallStatus,
  selectCurrentCallId,
  selectIsCompleteModalVisible,
  selectIsTimerPaused,
  selectIsTimerRunning,
  selectSelectedCallPatient,
  selectTimerAmount,
} from '@/selectors/call_manager';
import { getCurrentUser } from '@/selectors/users';
import { useInterval } from '@/utils/activityUtils';
import { usePrevious } from '@/utils/stateUtils';
import CallCompleteModal from './CallCompleteModal';

const CallManager = () => {
  const Twilio = require('twilio-client');

  const dispatch = useDispatch();

  const [lastConnectionId, setLastConnectionId] = useState<string>('');

  const callStatus = useSelector(selectCallStatus);
  const currentCallId = useSelector(selectCurrentCallId);
  const isTimerRunning = useSelector(selectIsTimerRunning);
  const isTimerPaused = useSelector(selectIsTimerPaused);
  const timerAmount = useSelector(selectTimerAmount);
  const isCompleteModalVisible = useSelector(selectIsCompleteModalVisible);
  const patient = useSelector(selectSelectedCallPatient);
  const user = useSelector(getCurrentUser);

  const previousCallStatus = usePrevious(callStatus);

  useEffect(() => {
    Twilio.Device.ready(() => {
      dispatch(setDeviceStatus('ready'));
    });

    Twilio.Device.disconnect(() => {
      dispatch(setCallStatus('offline'));
    });

    Twilio.Device.incoming((conn: any) => {
      dispatch(getIncomingCall(conn));
    });
  }, []);

  useEffect(() => {
    if (Twilio.Device.instance._events.connect) {
      Twilio.Device.instance.removeListener(
        'connect',
        Twilio.Device.instance._events.connect,
      );
    }

    Twilio.Device.connect((conn: any) => {
      const connectionSid = conn.parameters.CallSid;
      const to = conn.parameters.To;

      dispatch(setCallSid(connectionSid));
      dispatch(setCurrentConnection(conn));

      createConnection(connectionSid, to);

      dispatch(setCallStatus('connected'));
    });
  }, [patient]);

  useEffect(() => {
    if (Twilio.Device._status === 'ready') {
      dispatch(setDeviceStatus('ready'));
    } else {
      dispatch(initializeTwilio());
    }
  }, [Twilio.Device._status]);

  useEffect(() => {
    if (callStatus === 'offline' && previousCallStatus === 'connected') {
      dispatch(stopTimer());
      dispatch(setCompleteModalVisibility(true));
      dispatch(setActiveKeypadModalVisibility(false));
    }
  }, [callStatus]);

  useInterval(() => {
    if (isTimerRunning && !isTimerPaused) {
      dispatch(setTimerAmount(timerAmount + 1));
    }
  }, 1000);

  const createConnection = (callSid: string, to: string) => {
    if (patient && lastConnectionId !== callSid) {
      setLastConnectionId(callSid);

      Api.utility.post('api/v1/connections', {
        call_id: currentCallId,
        call_sid: callSid,
        status: 'initiated',
        patient_id: patient.id,
        user_id: user.data.id,
      });
    } else if (to.includes('client:')) {
      // Incoming connections do not immediately include patient data
      setLastConnectionId(callSid);

      Api.utility.post('api/v1/connections', {
        call_id: currentCallId,
        call_sid: callSid,
        status: 'initiated',
        user_id: user.data.id,
      });
    }
  };

  const handleCloseCompleteModal = () => {
    dispatch(setCompleteModalVisibility(false));
  };

  return (
    <>
      <CallCompleteModal
        isVisible={isCompleteModalVisible}
        onCloseModal={handleCloseCompleteModal}
      />
    </>
  );
};

export default CallManager;
