import { SideEffect } from '@/types/generic';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Button from '../button/Button';
import Icon from '../icon/Icon';

interface Props {
  children: ReactNode;
  isAlignedRight?: boolean;
  isSortable?: boolean;
  onClickHeader?: SideEffect;
  shouldHideData?: boolean;
  sortDirection?: any;
  style?: any;
}

export const Header = (props: Props) => {
  const {
    children,
    isAlignedRight,
    isSortable,
    onClickHeader,
    shouldHideData,
    sortDirection,
    style,
  } = props;

  const iconRotation = () => {
    if (sortDirection === 'asc') {
      return 180;
    }

    return 0;
  };

  const renderHeaderName = () => {
    if (isSortable) {
      return (
        <Button style="icon sort" onClick={onClickHeader}>
          <div className="table__header-text" title={children as string}>
            {children}
          </div>
          {<Icon icon="caretDownFilled" rotation={iconRotation()} />}
        </Button>
      );
    }

    return children;
  };

  return (
    <th
      className={classNames('table__header', {
        'is-aligned-right': isAlignedRight,
        'is-data-invisible': shouldHideData,
      })}
      style={style}>
      {renderHeaderName()}
    </th>
  );
};

export default Header;
