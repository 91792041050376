import {
  AdminDashboardResponseData,
  CareManagerDashboardResponseData,
} from '@/state/rpm';
import { Response } from '@/types/response';

export enum RpmActions {
  GET_CARE_MANAGER_DASHBOARD = 'RPM/GET_CARE_MANAGER_DASHBOARD',
  GET_CARE_MANAGER_DASHBOARD_SUCCESS = 'RPM/GET_CARE_MANAGER_DASHBOARD_SUCCESS',
  GET_CARE_MANAGER_DASHBOARD_FAILURE = 'RPM/GET_CARE_MANAGER_DASHBOARD_FAILURE',
  GET_ADMIN_DASHBOARD = 'RPM/GET_ADMIN_DASHBOARD',
  GET_ADMIN_DASHBOARD_SUCCESS = 'RPM/GET_ADMIN_DASHBOARD_SUCCESS',
  GET_ADMIN_DASHBOARD_FAILURE = 'RPM/GET_ADMIN_DASHBOARD_FAILURE',
  TOGGLE_DASHBOARD_COLLAPSE = 'RPM/TOGGLE_DASHBOARD_COLLAPSE',
}

export interface GetCareManagerDashboard {
  type: typeof RpmActions.GET_CARE_MANAGER_DASHBOARD;
  payload: null;
}

export interface GetCareManagerDashboardSuccess {
  type: typeof RpmActions.GET_CARE_MANAGER_DASHBOARD_SUCCESS;
  payload: {
    data: CareManagerDashboardResponseData;
  };
}

export interface GetCareManagerDashboardFailure {
  type: typeof RpmActions.GET_CARE_MANAGER_DASHBOARD_FAILURE;
  payload: {
    error: Response<any>;
  };
}

export interface GetAdminDashboard {
  type: typeof RpmActions.GET_ADMIN_DASHBOARD;
  payload: null;
}

export interface GetAdminDashboardSuccess {
  type: typeof RpmActions.GET_ADMIN_DASHBOARD_SUCCESS;
  payload: {
    data: AdminDashboardResponseData;
  };
}

export interface GetAdminDashboardFailure {
  type: typeof RpmActions.GET_ADMIN_DASHBOARD_FAILURE;
  payload: {
    error: Response<any>;
  };
}

export interface ToggleDashboardCollapse {
  type: typeof RpmActions.TOGGLE_DASHBOARD_COLLAPSE;
  payload: null;
}

export type RpmAction =
  | GetCareManagerDashboard
  | GetCareManagerDashboardSuccess
  | GetCareManagerDashboardFailure
  | GetAdminDashboard
  | GetAdminDashboardSuccess
  | GetAdminDashboardFailure
  | ToggleDashboardCollapse;

export const getCareManagerDashboard = (): RpmAction => ({
  type: RpmActions.GET_CARE_MANAGER_DASHBOARD,
  payload: null,
});

export const getCareManagerDashboardSuccess = (
  data: CareManagerDashboardResponseData,
): RpmAction => ({
  type: RpmActions.GET_CARE_MANAGER_DASHBOARD_SUCCESS,
  payload: {
    data,
  },
});

export const getCareManagerDashboardFailure = (
  error: Response<any>,
): RpmAction => ({
  type: RpmActions.GET_CARE_MANAGER_DASHBOARD_FAILURE,
  payload: {
    error,
  },
});

export const getAdminDashboard = (): RpmAction => ({
  type: RpmActions.GET_ADMIN_DASHBOARD,
  payload: null,
});

export const getAdminDashboardSuccess = (
  data: AdminDashboardResponseData,
): RpmAction => ({
  type: RpmActions.GET_ADMIN_DASHBOARD_SUCCESS,
  payload: {
    data,
  },
});

export const getAdminDashboardFailure = (error: Response<any>): RpmAction => ({
  type: RpmActions.GET_ADMIN_DASHBOARD_FAILURE,
  payload: {
    error,
  },
});

export const toggleDashboardCollapse = (): RpmAction => ({
  type: RpmActions.TOGGLE_DASHBOARD_COLLAPSE,
  payload: null,
});
