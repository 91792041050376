export const formatAddressSingleLine = (address: any) => {
  const attributes = address?.attributes;

  if (!attributes) {
    return '';
  }

  const stateZip = `${attributes.state} ${attributes.zip_code}`;

  return [attributes.line_1, attributes.line_2, attributes.city, stateZip]
    .filter(n => n)
    .join(', ');
};

export const formatCityStateZip = (address: any) => {
  if (!address) {
    return '';
  }

  return `${address.city}, ${address.state} ${address.zip_code}`;
};
