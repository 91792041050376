import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FormComponentConfig } from '@/types/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';
import { IdParams } from '@/types/params';

const LabsNew = () => {
  const history = useHistory();

  const { id } = useParams<IdParams>();

  const [query] = returnTabQuery();

  const backPath = buildPath(Routes.patientsLabs, { id }, query);

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/labs',
      model: 'lab',
      mergeFields: {
        patient_id: id,
      },
      method: 'POST',
      onSuccess: () => history.push(backPath),
    },
    state: {
      test_type: '',
      test_date: '',
      result: '',
      remarks: '',
      scan_files: [],
    },
    rules: {
      test_type: { required: true },
      test_date: { required: true },
      result: { required: true },
      remarks: { required: true },
    },
    layout: [
      {
        title: 'Add Lab',
        fields: [
          [
            {
              label: 'Source',
              field: 'test_type',
              placeholder: 'Search for source',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/lab_tests',
              size: 4,
              formatTypeaheadResult: (result: any) => {
                return {
                  label: result.attributes.test_type,
                  value: result.attributes.test_type,
                };
              },
            },
            {
              label: 'Test Date',
              field: 'test_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
          ],
          [
            {
              label: 'Result',
              field: 'result',
              placeholder: 'Enter result',
              type: 'text',
              size: 8,
            },
          ],
          [
            {
              label: 'Remarks',
              field: 'remarks',
              placeholder: 'Enter remarks',
              type: 'text',
              size: 8,
            },
          ],
          [
            {
              field: 'scan_files',
              label: 'Upload Scan File',
              size: 8,
              type: 'upload',
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm backPath={backPath} backText="Back to Labs" config={config} />
  );
};

export default LabsNew;
