import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import { Rules, Validations } from '@/types/form';
import { Response } from '@/types/response';
import { parseErrorData } from '../apiUtils';
import { validate } from './utils';

const useSimpleForm = <T>(initialState: T) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState<T>(initialState);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState<boolean>(
    false,
  );
  const [validations, setValidations] = useState<Validations>({});
  const [isValid, setIsValid] = useState<boolean>(true);
  const [store, setStore] = useState<any>({});

  const update = (field: string, value: any): void => {
    if (typeof value === 'function') {
      const newValue = value(form[field]);

      setForm({
        ...form,
        [field]: newValue,
      });
    } else {
      setForm({
        ...form,
        [field]: value,
      });
    }
  };

  const validateForm = (rules: Rules): boolean => {
    const [valid, formValidations] = validate(form, rules);

    setIsValid(valid);
    setValidations(formValidations);

    return valid;
  };

  const submit = (): void => {
    setIsSubmitting(true);
  };

  const done = (): void => {
    setIsSubmitting(false);
  };

  const displayConfirmation = (): void => {
    setIsConfirmationVisible(true);
  };

  const closeConfirmation = (): void => {
    setIsConfirmationVisible(false);
  };

  const success = (message: string): void => {
    dispatch(flashSuccess(message));
  };

  const set = (state: any): void => {
    setForm(state);
  };

  const error = (message: string | Response<any>): void => {
    if (typeof message === 'string') {
      dispatch(flashError(message));
    } else {
      dispatch(flashError(parseErrorData(message)));
    }
  };

  return {
    data: form,
    update,
    set,
    setValidations,
    submit,
    done,
    displayConfirmation,
    closeConfirmation,
    isConfirmationVisible,
    isSubmitting,
    isValid,
    validations,
    success,
    error,
    store,
    setStore,
    validate: validateForm,
  };
};

export default useSimpleForm;
