import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import TypeaheadSelect from '@/components/typeahead/TypeaheadSelect';
import { getPatientSpecialistsOptions } from '@/selectors/api';
import { FormComponentConfig } from '@/types/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { useSelector } from 'react-redux';
import PatientForm from '../../components/PatientForm';
import { IdParams } from '@/types/params';

const ConsentAgreementsNew = () => {
  const history = useHistory();

  const { id } = useParams<IdParams>();

  const [query] = returnTabQuery();

  const backPath = buildPath(Routes.patientsAgreements, { id }, query);

  const patientOptions = useSelector(getPatientSpecialistsOptions);

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      alterFormBeforeSubmit: (formState: any) => {
        const { hours, minutes, time } = formState;

        const hoursInt = parseInt(hours, 10);
        const minutesInt = parseInt(minutes, 10);
        const duration = hoursInt * 60 + minutesInt;

        const date = moment();
        const selectTime = moment(time);

        date.set('hour', selectTime.hour());
        date.set('minute', selectTime.minute());

        return {
          ...formState,
          duration,
          time: date.toISOString(),
          patient_id: id,
        };
      },
      path: 'api/v1/condition_complexities',
      model: 'condition_complexity',
      method: 'POST',
      onSuccess: () => history.push(backPath),
      onCancel: () => history.push(backPath),
    },
    state: {
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      comments: '',
      cpt_code_ids: [],
      credentials: '',
      date: '',
      description: '',
      duration: '',
      specialist_id: '',
      time: '',
      hours: '',
      minutes: '',
    },
    rules: {
      agreement_type: { required: true },
      checkbox1: { required: true },
      checkbox2: { required: true },
      checkbox3: { required: true },
      checkbox4: { required: true },
      credentials: { required: true },
    },
    layout: [
      {
        title: 'Add Condition Complexity',
        fields: [
          [
            {
              label: 'Date',
              field: 'date',
              placeholder: 'Enter date',
              type: 'date',
              size: 4,
            },
            {
              label: 'Time Spent',
              field: 'hours',
              placeholder: 'Hours',
              type: 'number',
              size: 2,
            },
            {
              field: 'minutes',
              placeholder: 'Minutes',
              type: 'number',
              size: 2,
            },
          ],
          [
            {
              label: 'Provider',
              field: 'specialist_id',
              placeholder: 'Select provider',
              size: 4,
              type: 'select',
              options: patientOptions,
            },
          ],
          [
            {
              label: 'Activity Description',
              field: 'description',
              placeholder: 'Enter description',
              size: 8,
              type: 'text',
            },
          ],
          [
            {
              label: 'Credentials',
              field: 'credentials',
              placeholder: 'Enter credentials',
              size: 4,
              type: 'text',
            },
            {
              label: 'CPT Code(s)',
              field: 'cpt_code_ids',
              placeholder: 'Search for CPT code',
              size: 4,
              type: 'typeahead-select',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/cpt_codes',
              formatTypeaheadResult: (result: any) => {
                return {
                  label: result.attributes.code,
                  value: result.id,
                };
              },
            },
          ],
          [
            {
              label: 'Time',
              field: 'time',
              placeholder: 'Enter time',
              size: 4,
              type: 'time',
            },
          ],
          [
            {
              label: 'Decision Comments',
              field: 'comments',
              placeholder: 'Enter comments',
              size: 8,
              type: 'text',
            },
          ],
          [
            {
              label:
                'I made the following moderate to high complexity medical decisions during the billed calendar month',
              field: 'checkbox1',
              size: 8,
              type: 'checkbox',
            },
          ],
          [
            {
              label:
                'I provided ongoing ccm services oversight, direction and management during the month',
              field: 'checkbox2',
              size: 8,
              type: 'checkbox',
            },
          ],
          [
            {
              label:
                "I spent the following time during the month on the patient's medical decision making and ccm sevices oversight, direction and management",
              field: 'checkbox3',
              size: 8,
              type: 'checkbox',
            },
          ],
          [
            {
              label:
                'Care plan was significantly revised during the current month',
              field: 'checkbox4',
              size: 8,
              type: 'checkbox',
            },
          ],
        ],
      },
    ],
  };

  const formatResult = result => {
    return {
      label: result.attributes.code,
      value: result.id,
    };
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Agreements"
      config={config}
    />
  );
};

export default ConsentAgreementsNew;
