import React from 'react';
import { useSelector } from 'react-redux';

import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import QuestionnaireForm from '@/modules/questionniares/form/QuestionnaireForm';
import { getCurrentUser } from '@/selectors/users';
import { Specialist } from '@/types/v2/specialist';
import Modeler from '@/utils/modeler/modeler';
import { Routes } from '@/utils/routeUtils';

const PracticeQuestionnaireForm = (): JSX.Element => {
  const user = useSelector(getCurrentUser);
  const specialist = new Modeler<Specialist>(user).build();

  return (
    <>
      <Constraint size="large">
        <BackLink to={Routes.provider.practice.show.questionnaires.root}>
          Cancel
        </BackLink>
      </Constraint>

      <QuestionnaireForm
        questionnaireableId={specialist.practice_id}
        questionnaireableType="Practice"
      />
    </>
  );
};

export default PracticeQuestionnaireForm;
