import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '@/utils/routeUtils';

interface Props {
  onPressLink: () => void;
}

export const PatientLinks = ({ onPressLink }: Props) => {
  return (
    <>
      <Link
        className="header__nav-dropdown-content -first"
        to={Routes.chart.calendar.root}
        onClick={onPressLink}>
        Calendar
      </Link>

      <Link
        className="header__nav-dropdown-content"
        to={Routes.chart.providers.root}
        onClick={onPressLink}>
        Providers
      </Link>

      <Link
        className="header__nav-dropdown-content"
        to={Routes.chart.alerts.root}
        onClick={onPressLink}>
        Alerts
      </Link>
    </>
  );
};

export default PatientLinks;
