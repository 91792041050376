import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GetAdminDashboard,
  getAdminDashboardFailure,
  GetAdminDashboardFailure,
  getAdminDashboardSuccess,
  RpmActions,
} from '@/actions/actions/rpm';
import Api from '@/api/Api';
import { AdminDashboardResponseData } from '@/state/rpm';
import { parseErrorData } from '@/utils/apiUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { flashError } from '../messageSaga';

export function* getAdminDashboardSaga(_action: GetAdminDashboard) {
  try {
    const startAt = moment()
      .startOf('week')
      .utc()
      .format();

    const endAt = moment()
      .endOf('week')
      .utc()
      .format();

    const url = buildPath(Routes.api2.dashboards, null, {
      end_at: endAt,
      report: 'admin_rpm_dashboard',
      start_at: startAt,
    });

    const response = yield call(Api.utility.get, url);

    yield put(
      getAdminDashboardSuccess(response.data as AdminDashboardResponseData),
    );
  } catch (err) {
    yield put(getAdminDashboardFailure(err));
  }
}

export function* getAdminDashboardFailureSaga(
  action: GetAdminDashboardFailure,
) {
  const message = parseErrorData(action.payload.error);
  yield put(flashError(message));
}

export function* watchGetAdminDashbaord() {
  yield takeLatest(RpmActions.GET_ADMIN_DASHBOARD, getAdminDashboardSaga);
  yield takeLatest(
    RpmActions.GET_ADMIN_DASHBOARD_FAILURE,
    getAdminDashboardFailureSaga,
  );
}
