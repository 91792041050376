import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  GetCareManagerDashboard,
  getCareManagerDashboardFailure,
  GetCareManagerDashboardFailure,
  getCareManagerDashboardSuccess,
  RpmActions,
} from '@/actions/actions/rpm';
import Api from '@/api/Api';
import { getCurrentUser } from '@/selectors/users';
import { CareManagerDashboardResponseData } from '@/state/rpm';
import { parseErrorData } from '@/utils/apiUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { flashError } from '../messageSaga';

export function* getCareManagerDashboardSaga(_action: GetCareManagerDashboard) {
  try {
    const user = yield select(getCurrentUser);

    const startAt = moment()
      .startOf('day')
      .utc()
      .format();

    const endAt = moment()
      .endOf('day')
      .utc()
      .format();

    const weekStart = moment()
      .startOf('week')
      .utc()
      .format();

    const weekEnd = moment()
      .endOf('week')
      .utc()
      .format();

    const url = buildPath(Routes.api2.dashboards, null, {
      end_at: endAt,
      report: 'care_manager_rpm_dashboard',
      start_at: startAt,
      user_id: user.data.id,
      week_end: weekEnd,
      week_start: weekStart,
    });

    const response = yield call(Api.utility.get, url);

    yield put(
      getCareManagerDashboardSuccess(
        response.data as CareManagerDashboardResponseData,
      ),
    );
  } catch (err) {
    yield put(getCareManagerDashboardFailure(err));
  }
}
export function* getCareManagerDashboardFailureSaga(
  action: GetCareManagerDashboardFailure,
) {
  const message = parseErrorData(action.payload.error);
  yield put(flashError(message));
}

export function* watchGetCareManagerDashbaord() {
  yield takeLatest(
    RpmActions.GET_CARE_MANAGER_DASHBOARD,
    getCareManagerDashboardSaga,
  );
  yield takeLatest(
    RpmActions.GET_CARE_MANAGER_DASHBOARD_FAILURE,
    getCareManagerDashboardFailureSaga,
  );
}
