import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { Tab, Tabs } from '@/components/tabs';
import { getSelectedPatient } from '@/selectors/api';
import { TableConfig } from '@/types/table';
import { formatShortDate } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientLayout from '../../components/PatientLayout';

const SEARCH_PATH = 'api/v1/immunizations';

const ImmunizationsIndex = () => {
  const patient = useSelector(getSelectedPatient);

  const [selectedTab, setSelectedTab] = useState<string>('immunizations');

  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
    },
    emptyState: 'No immunizations added yet',
    columns: [
      {
        header: 'Immunizations',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'name',
        width: 2,
      },
      {
        header: 'Last Date',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'last_date',
        formatAttribute: formatShortDate,
      },
      {
        header: 'Next Date',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'next_date',
        formatAttribute: formatShortDate,
      },
      {
        header: 'Comment',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'comments',
        width: 2,
      },
    ],
    rows: {
      editPath: buildPath(Routes.patientsImmunizationsEdit, {
        patient_id: patient.id,
      }),
    },
    header: {
      addPath: buildPath(Routes.patientsImmunizationsNew, { id: patient.id }),
    },
  };

  return (
    <PatientLayout>
      <Panel>
        <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
          <Tab value="immunizations">Immunizations</Tab>
        </Tabs>

        <Table config={tableConfig} />
      </Panel>
    </PatientLayout>
  );
};

export default ImmunizationsIndex;
