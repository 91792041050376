import { FormResponse, ResponseState } from './types';

export const formatFormBody = (responses: ResponseState[]) => {
  const formattedResponses = responses.map(response => {
    (response as FormResponse).questionnaire_response_answers_attributes =
      response.questionnaire_response_answers;

    delete response.questionnaire_response_answers;

    return response;
  });

  return {
    virtual_appointment_questionnaire: {
      questionnaire_responses_attributes: formattedResponses,
    },
  };
};
