import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import useGetRequest from '@/utils/api/useGetRequest';
import { buildPath, Routes } from '@/utils/routeUtils';
import { IdParams } from '@/types/params';

const PracticesShow = () => {
  const dispatch = useDispatch();

  const { id } = useParams<IdParams>();

  const [practice, setPractice] = useState(null);

  const backPath = buildPath(Routes.practices);
  const editPath = buildPath(Routes.practicesEdit, { id });

  const [getPractice, isLoadingPractice] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    id,
    model: 'practices',
    onSuccess: (response: any) => {
      setPractice(response);
    },
  });

  useEffect(() => {
    getPractice();
  }, []);

  const renderLocations = () => {
    return practice.data.attributes.addresses.map(address => {
      return (
        <Panel key={address.id} withNarrowMargin>
          <Container>
            <div className="practice-show__wrapper">
              <h4>Address</h4>
              <p>{address.line_1}</p>
              <p>{address.line_2}</p>
              <p>{`${address.city}, ${address.state}, ${address.zip_code}`}</p>
            </div>
          </Container>
        </Panel>
      );
    });
  };

  if (!practice || isLoadingPractice) {
    return <ActivityIndicator />;
  }

  const attributes = practice.data.attributes;

  const renderLogo = () => {
    if (attributes.logo_thumbnail_url) {
      return (
        <div className="panel__header-logo">
          <img src={attributes.logo_thumbnail_url} />
        </div>
      );
    }
  };

  return (
    <div className="practice-show">
      <Container>
        <BackLink to={backPath}>Back</BackLink>
      </Container>

      <Constraint size="large">
        <Panel withNarrowMargin>
          <Container>
            <div className="practice-show__header-wrapper">
              <div className="panel__header">
                {renderLogo()}
                <h2>{attributes.name}</h2>
              </div>

              <span className="practice-show__subheader">
                {practice.data.attributes.hospital.name}
              </span>
              <span className="practice-show__subheader">
                {practice.data.attributes.health_system.name}
              </span>
              <span className="practice-show__subheader">
                {practice.data.attributes.time_zone}
              </span>
            </div>

            <div className="practice-show__wrapper">
              <Link className="practice-show__link" to={editPath}>
                Edit Practice
              </Link>
            </div>
          </Container>
        </Panel>

        <Container>
          <h3>Locations</h3>
        </Container>

        {renderLocations()}
      </Constraint>
    </div>
  );
};

export default PracticesShow;
