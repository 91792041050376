import {
  PatientsActions,
  PatientsActionTypes,
} from '@/actions/actions/patients';
import { create, PatientsState } from '@/state/patients';

export default function reducer(
  state: PatientsState = create(),
  action: PatientsActionTypes,
): PatientsState {
  switch (action.type) {
    case PatientsActions.SET_SNAPSHOT:
      return {
        ...state,
        snapshot: action.payload.snapshot,
      };

    case PatientsActions.GET_PRACTICE_DEVICE_MODELS:
      return {
        ...state,
        isLoadingPracticeDeviceModels: true,
      };

    case PatientsActions.GET_PRACTICE_DEVICE_MODELS_SUCCESS:
      return {
        ...state,
        isLoadingPracticeDeviceModels: false,
        practiceDeviceModels: action.payload.deviceModels,
        practiceDeviceModelsPracticeId: action.payload.practiceId,
      };

    case PatientsActions.GET_PRACTICE_DEVICE_MODELS_FAILURE:
      return {
        ...state,
        isLoadingPracticeDeviceModels: false,
      };

    case PatientsActions.GET_PRACTICE_READING_THRESHOLDS_SUCCESS:
      return {
        ...state,
        practiceReadingThresholds: action.payload.readingThresholds,
      };

    default:
      return state;
  }
}
