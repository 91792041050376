import { createSelector } from 'reselect';

import { nonBillingAdmins } from '@/components/access_control/PolicyGroups';
import { AppState } from '@/types/app';
import { UsersState } from '@/types/user';
import Modeler from '@/utils/modeler/modeler';

export const getUsersState = (state: AppState): UsersState => state.users;

export const getCurrentUser = createSelector(
  getUsersState,
  state => state.current,
);

// The original selector above (getCurrentUser) was added before the modeler was added to the application.
// The following selector builds a model for easier use
export const selectCurrentUser = createSelector(getCurrentUser, user =>
  new Modeler(user).build(),
);

export const selectCurrentUserId = createSelector(
  getCurrentUser,
  state => state?.data?.id,
);

export const selectCurrentUserRole = createSelector(
  getCurrentUser,
  state => state?.data?.attributes.user_role,
);

export const selectUserCapabilities = createSelector(
  getCurrentUser,
  state => state?.data?.attributes.capabilities || [],
);

export const canUserJoinVideoCall = createSelector(
  selectUserCapabilities,
  capabilities => capabilities.includes('join_video_call'),
);

export const canUserModifyAppointments = createSelector(
  selectUserCapabilities,
  capabilities => capabilities.includes('modify_appointment'),
);

export const selectIsCurrentUserAnyCareManager = createSelector(
  selectCurrentUserRole,
  role =>
    ['care_manager', 'admin', 'billing_admin', 'super_admin'].includes(role),
);

export const selectIsCurrentUserCareManager = createSelector(
  selectCurrentUserRole,
  role => role === 'care_manager',
);

export const selectIsCurrentUserAdmin = createSelector(
  selectCurrentUserRole,
  role => role === 'admin',
);

export const selectIsCurrentUserBillingAdmin = createSelector(
  selectCurrentUserRole,
  role => role === 'billing_admin',
);

export const selectIsCurrentUserSuperAdmin = createSelector(
  selectCurrentUserRole,
  role => role === 'super_admin',
);

export const selectIsCurrentUserAnyAdmin = createSelector(
  selectCurrentUserRole,
  role => nonBillingAdmins.includes(role),
);

export const selectIsCurrentUserPatient = createSelector(
  selectCurrentUserRole,
  role => role === 'patient',
);

export const selectIsCurrentUserProvider = createSelector(
  selectCurrentUserRole,
  role => role === 'admin_user',
);

export const selectUnreadAlertCount = createSelector(
  getCurrentUser,
  state => state?.data?.attributes.unread_notifications,
);
