import { Nullable, UUID } from '../generic';
import { DeviceReadingAlertLevel } from './device_reading';

export type ReadingType = 'blood_glucose' | 'blood_pressure' | 'blood_oxygen' | 'weight';

export interface ReadingThreshold {
  _destroy?: boolean;
  id?: UUID;
  max_value: Nullable<number>;
  min_value: number;
  reading_type: ReadingType;
  level: DeviceReadingAlertLevel;
}

export const readingTypes: ReadingType[] = [
  'blood_glucose',
  'blood_oxygen',
  'blood_pressure',
  'weight',
];

export const convertIntToType = (int: string): ReadingType => {
  const map = {
    '1': 'blood_glucose',
    '2': 'blood_pressure',
    '7': 'blood_oxygen',
    '8': 'weight',
  };

  return map[int];
};

export const create = (
  data: Partial<ReadingThreshold> = {},
): ReadingThreshold => ({
  min_value: 50,
  max_value: 100,
  reading_type: 'blood_pressure',
  level: 'low',
  ...data,
});
