import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { BlockStyle } from './types';

interface Props {
  children: ReactNode;
  style: BlockStyle;
}

const EmptyBlock = ({ children, style }: Props): JSX.Element => {
  return (
    <div className="dashboard-empty-block__wrapper">
      <div
        className={classNames('dashboard-empty-block', {
          'is-outlined': style === 'outlined',
          'is-compact': style === 'compact',
        })}>
        <span>{children}</span>
      </div>
    </div>
  );
};

export default EmptyBlock;
