import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import useGetRequest from '@/utils/api/useGetRequest';
import usePutRequest from '@/utils/api/usePutRequest';

import { IdParams } from '@/types/params';
import { buildPath, Routes } from '@/utils/routeUtils';
import { parseErrorData } from '@/utils/apiUtils';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import ActivityIndicator from '@/components/activity/ActivityIndicator';

import Container from '@/components/container/Container';
import BackLink from '@/components/button/BackLink';
import Panel from '@/components/panel/Panel';
import Section from '@/components/form/Section';
import Constraint from '@/components/constraint/Constraint';
import { InputGroup } from '@/components/form/InputGroup';
import Button from '@/components/button/Button';
import SelectGroup from '@/components/form/SelectGroup';
import ConfirmationModal from '@/contexts/modals/ConfirmationModal';

const SpecialistsEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<IdParams>();

  const FORM_STATE = {
    email: '',
    fax_number: '',
    name: '',
    phone_number: '',
    practice_id: '',
    scheduling_notes: '',
    specialty: '',
    target_specialist_id: '',
  };

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [specialist, setSpecialist] = useState(null);
  const [specialists, setSpecialists] = useState([]);
  const [filteredSpecialists, setFilteredSpecialists] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const backPath = buildPath(Routes.specialists);

  const handleGetSpecialistSuccess = (response: any) => {
    setSpecialist(response.data);
  };

  const handleGetSpecialistsSuccess = (response: any) => {
    setSpecialists(response.data);
  };

  const handlePutSuccess = () => {
    dispatch(flashSuccess('Specialist edited'));
    history.push(backPath);
  };

  const handleMergeFormSuccess = () => {
    dispatch(flashSuccess('Specialist merged'))
    history.push(backPath)
  }

  const handleFailure = (response: any) => {
    const errorMessage = parseErrorData(response);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const formatGetRequestBody = () => {
    const { ...formFields } = form;

    return {
      specialist: {
        ...formFields,
      },
    };
  };

  const formatMergeRequestBody = () => {
    const { target_specialist_id } = form;

    return {
      target_specialist_id: target_specialist_id,
    };
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleClickSave = () => {
    if (isSubmitting) {
      return;
    }

    submitForm();
  };

  const handleClickMerge = () => {
    setIsModalVisible(true);
  };

  const handleConfirmMerge = () => {
    mergeForm();
  };

  const [getSpecialist, isLoadingSpecialist] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    url: `api/v2/specialists/${id}`,
    model: 'specialists',
    onSuccess: handleGetSpecialistSuccess,
    onFailure: handleFailure,
  });

  const [getSpecialists, isLoadingSpecialists] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    url: `api/v2/specialists`,
    model: 'specialists',
    onSuccess: handleGetSpecialistsSuccess,
    onFailure: handleFailure,
  });

  const [submitForm, isSubmitting] = usePutRequest({
    body: formatGetRequestBody(),
    dispatch,
    onSuccess: handlePutSuccess,
    onFailure: handleFailure,
    url: `api/v2/specialists/${id}`,
  });

  const [mergeForm, isMerging] = usePutRequest({
    body: formatMergeRequestBody(),
    dispatch,
    onSuccess: handleMergeFormSuccess,
    onFailure: handleFailure,
    url: `api/v2/specialists/${id}/merge`,
  });

  useEffect(() => {
    getSpecialist();
    getSpecialists();
  }, []);

  //NOTE: This prevents the user from merging a specialist into themself
  useEffect(() => {
    if (specialist && specialists.length > 0) {
      setFilteredSpecialists(specialists.filter(s => s.id !== specialist.id))
    }
  }, [specialist, specialists])

  useEffect(() => {
    if (specialist) {
      const formattedSpecialist = {
        ...specialist.attributes,
      };
      setForm(formattedSpecialist);
    }
  }, [specialist]);

  const formatSpecialsistEntry = (entry: any) => ({
    label: entry.attributes.name,
    value: entry.id,
  });

  const specialistOptions = useMemo(() => {
    return filteredSpecialists.map(formatSpecialsistEntry);
  }, [filteredSpecialists]);

  const renderSpecialists = () => {
    if (!specialists || isLoadingSpecialists) {
      return <ActivityIndicator />;
    } else {
      return (
        <>
          <div className="columns">
            <div className="column">
              <SelectGroup
                label="Select specialist with whom to merge this specialist"
                options={specialistOptions}
                value={form.target_specialist_id}
                onChange={value => updateField('target_specialist_id', value)}
              />
            </div>
          </div>
          <Button
            color="secondary"
            isSubmitting={isSubmitting}
            onClick={handleClickMerge}>
            Merge
          </Button>
        </>
      );
    }
  };

  const renderSpecialist = () => {
    if (!specialist || isLoadingSpecialist) {
      return <ActivityIndicator />;
    } else {
      return (
        <>
          <div className="columns">
            <div className="column">
              <InputGroup
                label="Name"
                onChange={value => updateField('name', value)}
                placeholder="Enter name"
                value={form.name}
              />
            </div>
          </div>
          <Button
            color="secondary"
            isSubmitting={isSubmitting}
            onClick={handleClickSave}>
            Save
          </Button>
        </>
      );
    }
  };

  return (
    <>
      <ConfirmationModal
        isVisible={isModalVisible}
        onAccept={handleConfirmMerge}
        onClose={() => setIsModalVisible(false)}
      />
      <Container>
        <BackLink to={backPath}>Back to Specialists</BackLink>
      </Container>
      <Constraint size="large">
        <Panel>
          <Section title="Edit Specialist">
            <Container>{renderSpecialist()}</Container>
          </Section>
          <Section title="Merge Specialist">
            <Container>{renderSpecialists()}</Container>
          </Section>
        </Panel>
      </Constraint>
    </>
  );
};

export default SpecialistsEdit;
