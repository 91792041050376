import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BhiChecklist from '@/components/form/BhiChecklist';
import EnrollmentHeader from '@/contexts/enrollment/components/EnrollmentHeader';
import { getStepInformation } from '@/contexts/enrollment/helpers/steps';
import { createOptions } from '@/contexts/enrollment/utils/optionUtils';
import { getSelectedPatient } from '@/selectors/api';
import {
  selectCurrentCarePlan,
  selectCurrentCarePlanConditions,
} from '@/selectors/bhi';
import {
  getBarriers,
  getBhiServiceId,
  getGeneralConditionId,
} from '@/selectors/options';
import { Condition } from '@/types/condition';
import { UUID } from '@/types/generic';
import { Response } from '@/types/response';
import { formatIsoDateTime } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useHistory } from 'react-router-dom';

const BhiBarriers = () => {
  const history = useHistory();

  const data = useSelector(getBarriers);
  const bhiServiceId = useSelector(getBhiServiceId);
  const carePlan = useSelector(selectCurrentCarePlan);
  const conditions = useSelector(selectCurrentCarePlanConditions);
  const generalConditionId = useSelector(getGeneralConditionId);
  const patient = useSelector(getSelectedPatient);

  const [originalOptions, setOriginalOptions] = useState<UUID[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<UUID[]>([]);

  useEffect(() => {
    if (carePlan) {
      const ids = carePlan.data.attributes.barriers.map((c: Condition) => c.id);
      setOriginalOptions(ids);
      setSelectedOptions(ids);
    } else {
      setOriginalOptions([]);
      setSelectedOptions([]);
    }
  }, [carePlan]);

  const stepInformation = getStepInformation('bhi_barriers', patient);

  const nextScreen = buildPath(Routes.patientsEnrollment, {
    id: patient.id,
    step: stepInformation.nextStep,
  });

  const formatBody = () => {
    if (carePlan) {
      return {
        care_plan: {
          barrier_ids: selectedOptions,
          barriers_completed_at: formatIsoDateTime(),
        },
      };
    } else {
      return {
        care_plan: {
          barrier_ids: selectedOptions,
          patient_id: patient.id,
          service_id: bhiServiceId,
          barriers_completed_at: formatIsoDateTime(),
        },
      };
    }
  };

  const handleSuccess = (res: Response) => {
    history.push(nextScreen);
  };

  const options = createOptions(
    conditions,
    data,
    generalConditionId,
    'General personal health goals',
  );

  return (
    <div className="enrollment-wrapper">
      <EnrollmentHeader
        description="Which obstacles does the patient face in achieving their goals?"
        linkTo={nextScreen}
        linkText="Symptoms"
        title="Barriers"
      />

      <BhiChecklist
        carePlan={carePlan}
        formatBody={formatBody}
        onChange={newOptions => setSelectedOptions(newOptions)}
        onSuccess={handleSuccess}
        options={options}
        originalSelectedIds={originalOptions}
        selectedIds={selectedOptions}
      />
    </div>
  );
};

export default BhiBarriers;
