import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getImmunizationNamesOptions } from '@/selectors/options';
import { FormComponentConfig } from '@/types/form';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useSelector } from 'react-redux';
import PatientForm from '../../components/PatientForm';
import { validateBeforeSubmit } from './utils';
import { IdParams } from '@/types/params';

const ImmunizationsNew = () => {
  const history = useHistory();

  const { id } = useParams<IdParams>();

  const backPath = buildPath(Routes.patientsImmunizations, { id });

  const immunizationNames = useSelector(getImmunizationNamesOptions);

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/immunizations',
      model: 'immunization',
      mergeFields: {
        patient_id: id,
      },
      method: 'POST',
      onSuccess: () => history.push(backPath),
      validateBeforeSubmit,
    },
    state: {
      name: '',
      last_date: '',
      next_date: '',
      comments: '',
    },
    rules: {
      name: { required: true },
      last_date: { required: true },
    },
    layout: [
      {
        title: 'Add Immunization',
        fields: [
          [
            {
              label: 'Immunization',
              field: 'name',
              placeholder: 'Select immunization name',
              type: 'select',
              size: 4,
              options: immunizationNames,
            },
          ],
          [
            {
              label: 'Last Date',
              field: 'last_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
            {
              label: 'Next Date',
              field: 'next_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
          ],
          [
            {
              label: 'Comment',
              field: 'comments',
              placeholder: 'Enter comment',
              type: 'text',
              size: 8,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Immunizations"
      config={config}
    />
  );
};

export default ImmunizationsNew;
