import React from 'react';

import { SideEffect } from '@/types/generic';

interface Props {
  file: any;
  onRemove: SideEffect;
}

const FileSelect = (props: Props) => {
  const { file, onRemove } = props;

  return (
    <div className="form-file-select">
      <span>{file.name}</span>
      <a onClick={onRemove}>Remove</a>
    </div>
  );
};

export default FileSelect;
