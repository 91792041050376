import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import PatientsEdit from '@/contexts/patients/PatientsEdit';
import PatientsIndex from '@/contexts/patients/PatientsIndex';
import PatientsNew from '@/contexts/patients/PatientsNew';
import PatientsQuickAdd from '@/contexts/patients/PatientsQuickAdd';
import PatientsShow from '@/contexts/patients/PatientsShow';
import PatientRanks from '@/contexts/patients/PatientRanks';
import { Routes } from '@/utils/routeUtils';
import RpmHighAlertCareManagers from './modules/rpm_dashboard/admin/RpmHighAlertCareManagers';
import RpmMostCalls from './modules/rpm_dashboard/admin/RpmMostCalls';
import RpmNoAlertsCareManagers from './modules/rpm_dashboard/admin/RpmNoAlertsCareManagers';
import RpmHighAlerts from './modules/rpm_dashboard/care_manager/RpmHighAlerts';
import RpmLatestReadings from './modules/rpm_dashboard/care_manager/RpmLatestReadings';
import RpmAllReadings from './modules/rpm_dashboard/RpmAllReadings';

const Patients = () => {
  return (
    <Protected allowedRoles={PolicyGroups.nonBillingAny}>
      <Switch>
        <Route path={Routes.rpm.all} component={RpmAllReadings} />
        <Route path={Routes.rpm.latest} component={RpmLatestReadings} />
        <Route path={Routes.rpm.high} component={RpmHighAlerts} />
        <Route path={Routes.rpm.mostCalls} component={RpmMostCalls} />
        <Route
          path={Routes.rpm.highCareManagers}
          component={RpmHighAlertCareManagers}
        />
        <Route
          path={Routes.rpm.noReadings}
          component={RpmNoAlertsCareManagers}
        />

        <Route exact path={Routes.patientsNew} component={PatientsNew} />
        <Route exact path={Routes.patientsQuickAdd} component={PatientsQuickAdd} />
        <Route exact path={Routes.patientRanks} component={PatientRanks} />
        <Route path={Routes.patientsEdit} component={PatientsEdit} />
        <Route path={Routes.patientsShow} component={PatientsShow} />
        <Route path={Routes.patients} component={PatientsIndex} />
      </Switch>
    </Protected>
  );
};

export default Patients;
