import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Datepicker from '@/components/form/Datepicker';
import ErrorMessage from '@/components/form/ErrorMessage';
import InputGroup from '@/components/form/InputGroup';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import TextArea from '@/components/form/TextArea';
import Panel from '@/components/panel/Panel';
import { selectPracticeDeviceModels } from '@/selectors/patients';
import { Validations } from '@/types/form';
import useGetRequest from '@/utils/api/useGetRequest';
import usePostRequest from '@/utils/api/usePostRequest';
import { parseErrorData } from '@/utils/apiUtils';
import { validate } from '@/utils/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';

import { DeviceCategory } from '@/types/device_category';
import { DeviceType } from '@/types/device_type';
import { DeviceMake } from '@/types/device_make';
import { DeviceName } from '@/types/device_name';
import { DeviceModel } from '@/types/device_model';

import DeviceReader from './DeviceReader';
import { IdParams } from '@/types/params';

const DevicesNew = () => {
  const practiceDevices = useSelector(selectPracticeDeviceModels);
  const hasPracticeDevices = !!practiceDevices.length;

  const history = useHistory();

  const dispatch = useDispatch();

  const { id } = useParams<IdParams>();

  const [query] = returnTabQuery();

  const todaysDate = moment().format();

  const FORM_STATE = {
    device_category_id: '',
    device_model_id: '',
    device_make_id: '',
    device_name_id: '',
    device_type_id: '',
    serial_number: '',
    box_number: '',
    sim_number:'',
    shipping_invoice: '',
  };

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(true);

  const [deviceCategories, setDeviceCategories] = useState<DeviceCategory[]>(
    [],
  );
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [deviceMakes, setDeviceMakes] = useState<DeviceMake[]>([]);
  const [deviceNames, setDeviceNames] = useState<DeviceName[]>([]);
  const [deviceModels, setDeviceModels] = useState<DeviceModel[]>([]);

  const backPath = buildPath(Routes.devices);

  const formatDeviceEntry = (entry: any) => ({
    label: entry.attributes.name,
    value: entry.id,
  });

  const filterDeviceEntries = (entries: any[], attribute: string) => {
    return entries.filter(
      entry => entry.attributes[attribute] === form[attribute],
    );
  };

  const buildDeviceOptions = (entries: any[], attribute: string) => {
    return filterDeviceEntries(entries, attribute).map(formatDeviceEntry);
  };

  const deviceCategoryOptions = useMemo(() => {
    return deviceCategories.map(formatDeviceEntry);
  }, [deviceCategories]);

  const deviceTypeOptions = useMemo(() => {
    return buildDeviceOptions(deviceTypes, 'device_category_id');
  }, [form.device_category_id]);

  const deviceMakeOptions = useMemo(() => {
    return buildDeviceOptions(deviceMakes, 'device_type_id');
  }, [form.device_type_id]);

  const deviceNameOptions = useMemo(() => {
    return buildDeviceOptions(deviceNames, 'device_make_id');
  }, [form.device_make_id]);

  const deviceModelOptions = useMemo(() => {
    return buildDeviceOptions(deviceModels, 'device_name_id');
  }, [form.device_name_id]);

  const allDeviceModelOptions = useMemo(() => {
    return deviceModels.map(formatDeviceEntry);
  }, [deviceModels]);

  const allDeviceNameOptions = useMemo(() => {
    return deviceNames.map(formatDeviceEntry);
  }, [deviceNames]);

  const practiceDeviceOptions = useMemo(() => {
    return practiceDevices.map(practiceDevice => ({
      label: practiceDevice.name,
      value: practiceDevice.id,
    }));
  }, [practiceDevices]);

  const updateFieldUtil = (deviceOptions, optionId, dependentOptionId) => {
    const option = deviceOptions.find(option => option.id === form[optionId]);
    updateField(dependentOptionId, option?.attributes[dependentOptionId]);
  };

  useEffect(() => {
    updateField('serial_number', FORM_STATE.serial_number);
    updateFieldUtil(deviceModels, 'device_model_id', 'device_name_id');
  }, [form.device_model_id]);

  useEffect(() => {
    updateFieldUtil(deviceNames, 'device_name_id', 'device_make_id');
  }, [form.device_name_id]);

  useEffect(() => {
    updateFieldUtil(deviceMakes, 'device_make_id', 'device_type_id');
  }, [form.device_make_id]);

  useEffect(() => {
    updateFieldUtil(deviceTypes, 'device_type_id', 'device_category_id');
  }, [form.device_type_id]);

  const rules = (() => {
    if (hasPracticeDevices) {
      return {
        device_model_id: { required: true },
        serial_number: { required: true },
      };
    }

    return {
      device_category_id: { required: true },
      device_make_id: { required: true },
      device_model_id: { required: true },
      device_name_id: { required: true },
      device_type_id: { required: true },
      serial_number: { required: true },
    };
  })();

  const [getDevices] = useGetRequest({
    dispatch,
    model: 'devices',
    url: buildPath('api/v1/devices/heirarchy', {}),
    onSuccess: (res: any) => {
      setDeviceCategories(res.device_categories.data);
      setDeviceTypes(res.device_types.data);
      setDeviceMakes(res.device_makes.data);
      setDeviceNames(res.device_names.data);
      setDeviceModels(res.device_models.data);
    },
  });

  useEffect(() => {
    getDevices();
  }, []);

  const formatBody = () => {
    const { ...formFields } = form;

    return {
      device: {
        ...formFields,
      },
    };
  };

  const handleSuccess = () => {
    dispatch(flashSuccess('Device added'));
    updateField('serial_number', '');
    
  };

  const handleFailure = (response: any) => {
    const errorMessage = parseErrorData(response);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const [submitForm, isSubmitting] = usePostRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: Routes.api.devices,
  });

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);

    if (isValid) {
      setValidations({});
    } else {
      return setValidations(validationMessages);
    }

    submitForm();
  };

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const onClickToggleScanner = () => {
    setIsScannerOpen(!isScannerOpen);
  };

  const renderDeviceHierarchy = (() => {
    return (
      <>
        <div className="columns">
          <div className="column is-6-tablet is-4-desktop">
            <SelectGroup
              label="Device Model"
              onChange={value => updateField('device_model_id', value)}
              options={allDeviceModelOptions}
              validationMessage={validations.device_model_id}
              value={form.device_model_id}
            />
            <InputGroup
              label="Box Number"
              onChange={value => updateField('box_number', value)}
              placeholder="Enter Box Number"
              value={form.box_number}
            />
            <TextArea
              onChange={value => updateField('shipping_invoice', value)}
              placeholder="Enter Shipping Invoice"
              value={form.shipping_invoice}
            />
          </div>
          {!isScannerOpen && (
            <div className="column is-6-tablet is-4-desktop">
              <InputGroup
                label="Serial Number"
                onChange={value => updateField('serial_number', value)}
                placeholder="Enter Serial Number"
                validationMessage={validations.serial_number}
                value={form.serial_number}
              />
              <InputGroup
                label="SimCard Number"
                onChange={value => updateField('sim_number', value)}
                placeholder="Enter SimCard Number"
                validationMessage={validations.sim_number}
                value={form.sim_number}
              />
            </div>
          )}
        </div>
        <DeviceReader
          isScannerOpen={isScannerOpen}
          deviceModelOptions={allDeviceModelOptions}
          form={form}
          formatBody={formatBody}
          handleFailure={handleFailure}
          handleSuccess={handleSuccess}
          updateField={updateField}
        />
      </>
    );
  })();

  return (
    <div className="patient-remote-care-new">
      <Constraint size="large">
        <Panel>
          <Section title="Add Device">{renderDeviceHierarchy}</Section>
        </Panel>

        <ErrorMessage isRightSide message={error} />

        <div className="form__actions">
          <div className="form__actions-left" />
          <div className="form__actions-right">
            <Button color="white" onClick={handleClickCancel}>
              Cancel
            </Button>

            {!isScannerOpen && (
              <Button
                color="secondary"
                isSubmitting={isSubmitting}
                onClick={handleClickSubmit}>
                Save
              </Button>
            )}

            {!isScannerOpen && (
              <Button color="secondary" onClick={onClickToggleScanner}>
                Scan Serial Number
              </Button>
            )}

            {isScannerOpen && (
              <Button color="secondary" onClick={onClickToggleScanner}>
                Enter Serial Number Manually
              </Button>
            )}
          </div>
        </div>
      </Constraint>
    </div>
  );
};

export default DevicesNew;
