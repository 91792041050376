import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import Page from '@/components/page/Page';
import Panel from '@/components/panel/Panel';
import { PolicyGroups, Protected } from '@/components/router';
import useGetRequest from '@/utils/api/useGetRequest';
import { buildPath, Routes } from '@/utils/routeUtils';
import CareManagerHeader from './components/CareManagerHeader';
import DashboardCallHistory from './components/DashboardCallHistory';
import DashboardPerformance from './components/DashboardPerformance';
import { UUID } from '@/types/generic';

interface Params {
  care_manager_id: UUID;
  user_id: UUID;
}

const CareManager = () => {
  const dispatch = useDispatch();

  const { care_manager_id, user_id } = useParams<Params>();

  const params = new URLSearchParams(location.search);
  const returnPath = params.get('return');

  const [calls, setCalls] = useState<any>({});
  const [careManager, setCareManager] = useState<any>({});
  const [performance, setPerformance] = useState<any>({});
  const [permissions, setPermissions] = useState<any>([]);

  const id = care_manager_id || user_id;
  const isDashboard = !!care_manager_id;

  const startOfMonth = moment()
    .startOf('month')
    .format('YYYY-MM-DD');

  const endOfMonth = moment()
    .endOf('month')
    .format('YYYY-MM-DD');

  const [getCalls, isLoadingCalls] = useGetRequest({
    dispatch,
    url: buildPath(
      'api/v1/calls',
      {},
      {
        user_id: id,
        start_at: startOfMonth,
        end_at: endOfMonth,
        per: null,
        include: 'patient,recording_attachment',
      },
    ),
    model: 'calls',
    onSuccess: (response: any) => {
      setCalls(response);
    },
  });

  const [getCareManager, isLoadingCareManager] = useGetRequest({
    dispatch,
    url: `api/v1/care_managers/${id}`,
    model: 'care_manager',
    onSuccess: (response: any) => {
      setCareManager(response.data);
    },
  });

  const [getDashboardData, isLoadingDashboardData] = useGetRequest({
    dispatch,
    url: `api/v1/dashboards/user/${id}`,
    model: 'dashboard',
    onSuccess: (response: any) => {
      setPerformance(response.data);
    },
  });

  const [getPermissions, isLoadingPermissions] = useGetRequest({
    dispatch,
    url: `api/v1/permissions?user_id=${id}`,
    model: 'permission',
    onSuccess: (response: any) => {
      setPermissions(response.data);
    },
  });

  useEffect(() => {
    getCalls();
    getCareManager();
    getDashboardData();
    getPermissions();
  }, []);

  const isLoading = !![
    isLoadingCalls,
    isLoadingCareManager,
    isLoadingDashboardData,
    isLoadingPermissions,
  ].find(n => n);

  const [backLink, backText] = (() => {
    if (returnPath) {
      return [returnPath, 'Back'];
    }

    if (isDashboard) {
      return [Routes.dashboardLive, 'Back to Live View'];
    }

    return [Routes.users, 'Back'];
  })();

  if (isLoading || !calls) {
    return <ActivityIndicator />;
  }

  return (
    <Protected allowedRoles={PolicyGroups.atLeastAdmin}>
      <Page>
        <CareManagerHeader
          backLink={backLink}
          backText={backText}
          careManager={careManager}
          permissions={permissions}
        />

        {isDashboard && (
          <>
            <h3 className="dashboard-header">Performance</h3>
            <Panel withNarrowMargin withNarrowPadding>
              <DashboardPerformance performance={performance} />
            </Panel>
          </>
        )}

        <h3 className="dashboard-header">Call History</h3>
        {calls &&
          (calls.data || []).map(call => {
            const patient = call.attributes.patient;

            if (call.attributes.status !== 'scheduled') {
              return (
                <Panel withNarrowPadding withNarrowMargin key={call.id}>
                  <DashboardCallHistory call={call} patient={patient} />
                </Panel>
              );
            }
          })}
      </Page>
    </Protected>
  );
};

export default CareManager;
