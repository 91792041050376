import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BhiEntryChecklist from '@/components/form/BhiEntryChecklist';
import EnrollmentHeader from '@/contexts/enrollment/components/EnrollmentHeader';
import { getStepInformation } from '@/contexts/enrollment/helpers/steps';
import { createEntryOptions } from '@/contexts/enrollment/utils/optionUtils';
import { getSelectedPatient } from '@/selectors/api';
import {
  selectCurrentCarePlan,
  selectCurrentCarePlanConditions,
} from '@/selectors/bhi';
import {
  getBhiServiceId,
  getGeneralConditionId,
  getGoals,
} from '@/selectors/options';
import { UUID } from '@/types/generic';
import { Response } from '@/types/response';
import { formatIsoDateTime } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';

const BhiGoals = () => {
  const history = useHistory();

  const bhiServiceId = useSelector(getBhiServiceId);
  const carePlan = useSelector(selectCurrentCarePlan);
  const conditions = useSelector(selectCurrentCarePlanConditions);
  const generalConditionId = useSelector(getGeneralConditionId);
  const goals = useSelector(getGoals);
  const patient = useSelector(getSelectedPatient);

  const [originalOptions, setOriginalOptions] = useState<UUID[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<UUID[]>([]);

  useEffect(() => {
    if (carePlan) {
      const ids = carePlan.data.attributes.care_plan_goals.map((c: any) => ({
        _destroy: false,
        id: c.id,
        goal_id: c.goal_id,
        time_period_unit: c.time_period_unit,
        time_period_value: c.time_period_value,
      }));

      // Update based on returned info

      setOriginalOptions(ids);
      setSelectedOptions(ids);
    } else {
      setOriginalOptions([]);
      setSelectedOptions([]);
    }
  }, [carePlan]);

  const stepInformation = getStepInformation('bhi_goals', patient);

  const nextScreen = buildPath(Routes.patientsEnrollment, {
    id: patient.id,
    step: stepInformation.nextStep,
  });

  const formatBody = () => {
    if (carePlan) {
      return {
        care_plan: {
          care_plan_goals_attributes: selectedOptions,
          goals_completed_at: formatIsoDateTime(),
        },
      };
    } else {
      return {
        care_plan: {
          care_plan_goals_attributes: selectedOptions,
          patient_id: patient.id,
          service_id: bhiServiceId,
          goals_completed_at: formatIsoDateTime(),
        },
      };
    }
  };

  const handleSuccess = (res: Response) => {
    history.push(nextScreen);
  };

  const options = createEntryOptions(conditions, goals);

  return (
    <div className="enrollment-wrapper">
      <EnrollmentHeader
        description="Which goals would the patient like to achieve?"
        linkTo={nextScreen}
        linkText="Barriers"
        title="Goals"
      />

      <BhiEntryChecklist
        carePlan={carePlan}
        formatBody={formatBody}
        newOptionFields={{ time_period_unit: null, time_period_value: null }}
        onChange={newOptions => setSelectedOptions(newOptions)}
        onSuccess={handleSuccess}
        options={options}
        originalSelectedIds={originalOptions}
        selectedIds={selectedOptions}
        selectionParentIdKey="goal_id"
      />
    </div>
  );
};

export default BhiGoals;
