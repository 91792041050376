import React, { useState } from 'react';

import Button from '@/components/button/Button';
import Container from '@/components/container/Container';
import Icon from '@/components/icon/Icon';
import Panel from '@/components/panel/Panel';
import { getSelectedPatient } from '@/selectors/api';
import { Call } from '@/types/call';
import { Patient } from '@/types/patient';
import { useSelector } from 'react-redux';
import { isEnrolled } from '../utils/patientUtils';
import PatientCalls from './PatientCalls';
import PatientDetails from './PatientDetails';
import PatientDetailsExpanded from './PatientDetailsExpanded';
import Tasks from '@/contexts/tasks/Tasks';

interface Props {
  withDetailsExpanded?: boolean;
}

const PatientHeader = (props: Props) => {
  const { withDetailsExpanded } = props;

  const patient: Patient = useSelector(getSelectedPatient);

  const [isVisible, setIsVisible] = useState<boolean>(
    !!withDetailsExpanded || false,
  );

  if (!patient) {
    return null;
  }

  const buttonText = () => {
    if (isVisible) {
      return 'Hide Full Patient Details';
    }

    return 'Show Full Patient Details';
  };

  const iconRotation = () => {
    return isVisible ? 180 : 0;
  };

  const handleButtonClick = () => {
    setIsVisible(!isVisible);
  };

  const isPatientEnrolled = isEnrolled(patient);

  const renderEnrolledData = () => {
    if (isPatientEnrolled) {
      return (
        <>
          <hr />
          <PatientCalls />
        </>
      );
    }

    return null;
  };

  return (
    <Panel>
      <Container>
        <PatientDetails
          patient={patient}
          shouldDisplayCallModule={isPatientEnrolled && !withDetailsExpanded}
        />

        <hr />

        <Tasks />

        {renderEnrolledData()}

        {!withDetailsExpanded && (
          <div className="patient-calls__expand">
            <Button style="flat-large" onClick={handleButtonClick}>
              {buttonText()}
              <Icon icon="caretDown" rotation={iconRotation()} />
            </Button>
          </div>
        )}

        <PatientDetailsExpanded patient={patient} isVisible={isVisible} />
      </Container>
    </Panel>
  );
};

export default PatientHeader;
