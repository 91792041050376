import { selectBrand } from '@/selectors/brand';
import React from 'react';
import { useSelector } from 'react-redux';

const Footer = () => {
  const LOGO_ICON = require('assets/images/logo/logo-icon.png');
  const brand = useSelector(selectBrand);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          {brand === 'default' && (
            <div className="footer__left">
              <img
                className="footer__icon"
                src={LOGO_ICON}
                alt="HealthClix logo"
              />
            </div>
          )}

          <div className="footer__right">
            <a href="#">Contact Us</a>
            <span>Copyright HealthClix {new Date().getFullYear()}</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
