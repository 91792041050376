import React, { ReactNode, useState ,useEffect} from 'react';

import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';


import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';

import Api from '@/api/Api';

import Button from '@/components/button/Button';

import Container from '@/components/container/Container';

import FilterHeader from '@/components/filters/FilterHeader';

import InputIcon from '@/components/form/InputIcon';

import SearchGroup, { formatSearchName } from '@/components/form/SearchGroup';

import SelectGroup from '@/components/form/SelectGroup';

import ActionHeader from '@/components/page/ActionHeader';

import Panel from '@/components/panel/Panel';

import { Table } from '@/components/table';

import DateTimeCell from '@/components/table/DateTimeCell';

import { CallAttributes } from '@/types/call';

import { CareManager } from '@/types/careManager';

import { Attributable, Serialized, UUID } from '@/types/generic';

import { TableConfig } from '@/types/table';

import { parseErrorData } from '@/utils/apiUtils';

import { roleOptionsSearch } from '@/utils/dropdownUtils';

import { Routes } from '@/utils/routeUtils';

import { titleizeSnakeCase } from '@/utils/stringUtils';

import Modal from '@/components/modal/Modal';

import DevicesModal from '../patients/modules/remote_patient_care/DevicesModal';

import { buildPath, returnTabQuery } from '@/utils/routeUtils';

import PushMessageFormModal from './components/PushMessageFormModal';

import { userInfo } from 'os';


const SEARCH_PATH = 'api/v1/patients';


const INITIAL_QUERY = {


per: 24,

search: '',

};

//var isDevicesModalVisible=false;


const ManageSendNotification = () => {

const [isDevicesModalVisible,SetDeviceModalVisible]=useState(false);

const [FcmToken,SetFcmToken]=useState('');
const [deviceType,SetdeviceType]=useState('');

const dispatch = useDispatch();

const history = useHistory();


const [searchInput, setSearchInput] = useState<string>('');

const [queryOptions, setQueryOptions] = useState<Attributable>(INITIAL_QUERY);




const handleSearchChange = (value: any) => {

setSearchInput(value);

};


const handleChangeQuery = (field: string, value: any) => {

setQueryOptions({

...queryOptions,

[field]: value,

});

};




const handleSendNotification = async (deviceToken: UUID ,deviceType: UUID ): Promise<void> => {

if(deviceToken==null)

{

dispatch(flashError('Unable to send notification!\nUser not logged in!'))

}

else{

SetDeviceModalVisible(true);

SetFcmToken(deviceToken);
SetdeviceType(deviceType);

}



};

const closeModal = () => {

    SetDeviceModalVisible(false);
    
    handleChangeQuery('search', ' ')
    
    const path = buildPath(Routes.send_notification);
    
    history.push(path);
    
    };
    
    
    useEffect(()=>{
    
    if(isDevicesModalVisible === true){
    
    setQueryOptions(INITIAL_QUERY);
    
    }
    
    },[isDevicesModalVisible])

const renderInvitationRow = (user): ReactNode => {


if (user.attributes.invitation_accepted_at) {

return 'Accepted';

}


return (

<Button

color="secondary"

onClick={() => handleSendNotification(user.device_token,user.platform_type)}

style="filled">

Send Notification

</Button>

);

};




const tableConfig: TableConfig = {

searchPath: SEARCH_PATH,

searchQuery: {

...queryOptions,

},

columns: [


{

header: 'First Name',

isSortable: true,

attribute: 'first_name',

isVisibleMobile: true,

},

{

header: 'Last Name',

isSortable: true,

attribute: 'last_name',

isVisibleMobile: true,

},

{

header: 'Email Address',

isSortable: true,

attribute: 'email',

},



{

header: 'Send Notification',

attribute: 'invitation_accepted_at',

formatEntry: (entry) =>

renderInvitationRow(entry),

},

],

};


return (

<Panel>

<Container>

<ActionHeader title="Patients">

<InputIcon

onChange={handleSearchChange}

onClick={() => handleChangeQuery('search', searchInput)}

onPressEnter={() => handleChangeQuery('search', searchInput)}

placeholder="Search"

value={searchInput}

/>

<>

</>

</ActionHeader>



<Modal isVisible={isDevicesModalVisible} onCloseModal={closeModal}>

<PushMessageFormModal deviceId={FcmToken} deviceType={deviceType}></PushMessageFormModal>

</Modal>


<Table config={tableConfig} />

</Container>

</Panel>

);

};


export default ManageSendNotification;
