import React from 'react';
import { Link } from 'react-router-dom';

import FilterHeader from '@/components/filters/FilterHeader';
import InputIcon from '@/components/form/InputIcon';
import SearchGroup, {
  formatSearchFullName,
} from '@/components/form/SearchGroup';
import SelectGroup from '@/components/form/SelectGroup';
import CalendarDateSelector, {
  Props as DateSelectorProps,
} from '@/modules/calendar/CalendarDateSelector';
import { UUID } from '@/types/generic';
import { virtualAppointmentStatusOptions } from '@/types/v2/virtual_appointment';
import { Routes } from '@/utils/routeUtils';

export interface FormState {
  appointment_status: string;
  patient_id: UUID;
  search: string;
}

interface Props extends DateSelectorProps {
  form: FormState;
  onChangeForm: (field: string, value: string) => void;
  onChangeSearch: (value: string) => void;
  onClearFilters: () => void;
  onStartSearch: () => void;
  practiceId: UUID;
  scheduleAppointmentLink: string;
  searchQuery: string;
}

const CalendarHeader = (props: Props): JSX.Element => {
  const {
    form,
    onChangeForm,
    onChangeSearch,
    onClearFilters,
    onStartSearch,
    practiceId,
    scheduleAppointmentLink,
    searchQuery,
    ...dateSelectorProps
  } = props;

  return (
    <>
      <div className="columns is-mobile">
        <div className="column is-6">
          <CalendarDateSelector isHiddenMobile {...dateSelectorProps} />
        </div>

        <div className="column is-6 is-justified-right-tablet">
          <Link className="button is-secondary" to={scheduleAppointmentLink}>
            Schedule Appointment
          </Link>
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column is-12-mobile is-6-tablet is-4-desktop is-offset-6-tablet is-offset-8-desktop">
          <InputIcon
            onChange={value => onChangeSearch(value)}
            onClick={onStartSearch}
            onPressEnter={onStartSearch}
            placeholder="Search"
            value={searchQuery}
          />
        </div>
      </div>

      <FilterHeader
        includePerPage={false}
        onChangeQuery={onChangeForm}
        onClearFilters={onClearFilters}
        query={form}
        perPageDesktopClass="is-2"
        perPageTabletClass="is-3"
        shouldAddColumnClasses>
        <>
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchFullName}
            guideValue={form.patient_id}
            label="Patient"
            minimumInputLength={0}
            onChange={(value: string) => onChangeForm('patient_id', value)}
            placeholder="Search Patients"
            queryParams={{ practice_id: practiceId }}
            searchPath={Routes.api.patients}
          />

          <SelectGroup
            label="Appointment Status"
            onChange={value => onChangeForm('appointment_status', value)}
            options={virtualAppointmentStatusOptions}
            value={form.appointment_status}
          />
        </>
      </FilterHeader>
    </>
  );
};

export default CalendarHeader;
