import React from 'react';

import SelectGroup from '@/components/form/SelectGroup';
import { ReadingType } from '@/types/v2/reading_threshold';

import Button from '@/components/button/Button';
import ReadingThresholdInputs from './ReadingThresholdInputs';
import { ThresholdEdit } from './ReadingThresholdList';

interface Props {
  onAddAlertLevel: (readingType: ReadingType) => void;
  onChange: (index: number, field: string, value: string) => void;
  onChangeReadingType: (from: ReadingType, to: ReadingType) => void;
  onDelete: (index: number) => void;
  readingType: ReadingType;
  readingThresholds: ThresholdEdit[];
}

const ReadingThresholdGroup = ({
  onAddAlertLevel,
  onChange,
  onChangeReadingType,
  onDelete,
  readingType,
  readingThresholds,
}: Props): JSX.Element => {
  const options = [
    { label: 'Blood Pressure (Systolic mmHg)', value: 'blood_pressure' },
    { label: 'Blood Glucose (mg/dL)', value: 'blood_glucose' },
    { label: 'Blood Oxygen (SpO2)', value: 'blood_oxygen' },
    { label: 'Weight (lbs)', value: 'weight' },
  ];

  const handleChangeReadingType = (newReadingType: ReadingType): void => {
    onChangeReadingType(readingType, newReadingType);
  };

  const renderInputs = (() => {
    return readingThresholds.map(threshold => (
      <ReadingThresholdInputs
        index={threshold.index}
        onChange={onChange}
        onDelete={onDelete}
        minValue={threshold.min_value}
        maxValue={threshold.max_value}
        level={threshold.level}
        key={threshold.index}
      />
    ));
  })();

  const renderAddLevel = (() => {
    return (
      <div className="columns">
        <div className="column">
          <Button
            style="flat-large add"
            onClick={() => onAddAlertLevel(readingType)}>
            Add Alert Level
          </Button>
        </div>
      </div>
    );
  })();

  return (
    <div className="reading-threshold-group">
      <div className="columns">
        <div className="column is-4">
          <SelectGroup
            label="Reading Type"
            onChange={value => handleChangeReadingType(value)}
            options={options}
            value={readingType}
          />
        </div>
      </div>

      <div className="reading-threshold-group__inputs">{renderInputs}</div>

      {renderAddLevel}

      <hr />
    </div>
  );
};

export default ReadingThresholdGroup;
