import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { flashError } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import CheckboxGroup from '@/components/form/CheckboxGroup';
import {
  getSelectedPatient,
  getSelectedPatientEnrollment,
  selectSelectedPatientProgramText,
  selectSelectedPatientServiceText,
} from '@/selectors/api';
import { Attributable } from '@/types/generic';
import { parseErrorData } from '@/utils/apiUtils';
import { buildPath, Routes } from '@/utils/routeUtils';

const INITIAL_STATE = {
  checkbox_1: false,
  checkbox_2: false,
  checkbox_3: false,
  checkbox_4: false,
  checkbox_5: false,
};

const ConsentStep2 = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const enrollment = useSelector(getSelectedPatientEnrollment);
  const patient = useSelector(getSelectedPatient);
  const programText = useSelector(selectSelectedPatientProgramText);
  const serviceText = useSelector(selectSelectedPatientServiceText);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form, setForm] = useState<Attributable>(INITIAL_STATE);

  const handleClickNo = () => {
    const path = buildPath(Routes.patientsEnrollmentStep, {
      id: patient.id,
      step: 'consent',
      sub_step: '1',
    });

    history.push(path);
  };

  const handleClickYes = async () => {
    setIsSubmitting(true);

    const url = `api/v1/patient_enrollments/${enrollment.id}`;

    const body = {
      patient_enrollment: {
        patient_verified_understanding_at: moment().format(),
      },
    };

    try {
      await Api.utility.patch(url, body);

      const path = buildPath(Routes.patientsEnrollmentStep, {
        id: patient.id,
        step: 'consent',
        sub_step: '3',
      });

      history.push(path);
    } catch (err) {
      dispatch(flashError(parseErrorData(err)));
    }
  };

  const handleUpdateForm = (n: number, value: boolean): void => {
    setForm({
      ...form,
      [`checkbox_${n}`]: value,
    });
  };

  const renderCheckboxes = () => {
    const labels = [
      'There may possibly be a co-pay',
      `I can cancel the ${programText} at any time`,
      'My Care Plan may be shared with my other providers',
      `Only one practitioner per month can bill for the ${serviceText}`,
      'Phone conversation may be recorded for quality assurance and training purposes',
    ];

    return labels.map((label: string, index: number) => {
      return (
        <div className="consent__checkbox" key={index}>
          <CheckboxGroup
            label={label}
            onChange={(value: boolean) => handleUpdateForm(index + 1, value)}
            value={form[`checkbox_${index + 1}`]}
          />
        </div>
      );
    });
  };

  const isSubmitDisabled = () => {
    return (
      !!Object.keys(form).find((key: string) => !form[key]) || isSubmitting
    );
  };

  return (
    <div className="consent__form">
      <h5>Please verify that the patient understands the following:</h5>

      <div className="consent__fields">{renderCheckboxes()}</div>

      <div className="consent__buttons">
        <Button color="white" isDisabled={isSubmitting} onClick={handleClickNo}>
          Previous Question
        </Button>

        <Button
          color="secondary"
          isDisabled={isSubmitDisabled()}
          onClick={handleClickYes}
          style="filled">
          Save and Next
        </Button>
      </div>
    </div>
  );
};

export default ConsentStep2;
