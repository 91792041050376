import classNames from 'classnames';
import React from 'react';

interface Props {
  isNotice?: boolean;
  isRightSide?: boolean;
  message: string;
}

export const ErrorMessage = (props: Props) => {
  const { isNotice, isRightSide, message } = props;

  if (!message) {
    return null;
  }

  return (
    <span
      className={classNames('form-error-message', {
        'is-right': isRightSide,
        'is-notice': isNotice,
      })}>
      {message}
    </span>
  );
};

export default ErrorMessage;
