import React, { ReactNode, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Api from '@/api/Api';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Actions from '@/components/form/Actions';
import CheckboxGroup from '@/components/form/CheckboxGroup';
import InputGroup from '@/components/form/InputGroup';
import Panel from '@/components/panel/Panel';
import ConfirmationModal from '@/contexts/modals/ConfirmationModal';
import { UUID } from '@/types/generic';
import { Questionnaire, QuestionnaireableType } from '@/types/v2/questionnaire';
import useLoadingState from '@/utils/api/useLoadingState';
import { switchIndexes, updateArrayIndex } from '@/utils/arrayUtils';
import useSimpleForm from '@/utils/form/useSimpleForm';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import FormQuestion from './FormQuestion';
import { PositionDirection } from './PositionControl';
import { FormState, FormValidations, Question } from './types';
import { blankQuestion, formatFormBody, validateForm } from './utils';

interface Props {
  questionnaireableId: UUID;
  questionnaireableType: QuestionnaireableType;
}

interface Params {
  questionnaire_id?: UUID;
}

const initialState: FormState = {
  name: '',
  questionnaire_questions: [blankQuestion],
  status: 'standard',
};

const QuestionnaireForm = ({
  questionnaireableId,
  questionnaireableType,
}: Props): JSX.Element => {
  const history = useHistory();
  const { questionnaire_id } = useParams<Params>();
  const form = useSimpleForm<FormState>(initialState);
  const loader = useLoadingState('questionnaire');

  const isEditForm = !!questionnaire_id;

  const activeQuestions = useMemo(() => {
    return form.data.questionnaire_questions.filter(
      question => !question._destroy,
    ).length;
  }, [form.data.questionnaire_questions]);

  const handleNavigateBack = (): void => {
    const backPath =
      questionnaireableType === 'AdminUser'
        ? Routes.profile.show
        : Routes.provider.practice.show.questionnaires.root;

    history.push(backPath);
  };

  const handleUpdateQuestion = (index: number, question: Question): void => {
    const questions = [...form.data.questionnaire_questions];
    const updatedQuestions = updateArrayIndex(questions, index, question);

    form.update('questionnaire_questions', updatedQuestions);
  };

  const handleRemoveQuestion = (index: number): void => {
    const questions = [...form.data.questionnaire_questions];
    const selectedQuestion = { ...form.data.questionnaire_questions[index] };

    if (selectedQuestion.id) {
      selectedQuestion._destroy = true;
      const updatedQuestions = updateArrayIndex(
        questions,
        index,
        selectedQuestion,
      );

      form.update('questionnaire_questions', updatedQuestions);
    } else {
      questions.splice(index, 1);
      form.update('questionnaire_questions', questions);
    }
  };

  const handleAddQuestion = (): void => {
    const questions = form.data.questionnaire_questions;
    const totalQuestions = questions.filter(question => !question._destroy)
      .length;

    const newQuestion = {
      ...blankQuestion,
      position: totalQuestions + 1,
    };

    form.update('questionnaire_questions', [...questions, newQuestion]);
  };

  const handleRepositionQuestion = (
    index: number,
    direction: PositionDirection,
  ): void => {
    // If we update the position of a question there might be old questions with _destroy in between the current position
    // in the questions array and the position that the question{} "needs" to get to. This searches for the first record
    // not set to _destroy, starting from the current position and moving in the specified direction. Then all of the
    // records are updated with new positions
    const questions = form.data.questionnaire_questions;

    const totalQuestions = questions.length;
    for (
      let targetIndex = index;
      targetIndex >= 0 && targetIndex < totalQuestions;
      targetIndex += direction
    ) {
      const targetQuestion = form.data.questionnaire_questions[targetIndex];

      if (targetIndex !== index && !targetQuestion._destroy) {
        let position = 1;

        const newQuestions = switchIndexes(
          questions,
          index,
          targetIndex,
        ) as Question[];

        const positionedQuestions = newQuestions.map(question => {
          if (!question._destroy) {
            question.position = position;
            position++;
            return question;
          }
        });

        form.update('questionnaire_questions', positionedQuestions);
        break;
      }
    }
  };

  const handleClickSave = () => {
    const [isValid, validations] = validateForm(form.data);

    // Form store will hold the validations temporarily
    form.setStore(validations);

    if (isValid) {
      submitForm();
    }
  };

  const submitForm = async () => {
    form.submit();

    try {
      const body = formatFormBody(
        form.data,
        questionnaireableId,
        questionnaireableType,
      );

      if (isEditForm) {
        const url = buildPath(Routes.api2.questionnaire, {
          id: questionnaire_id,
        });
        await Api.utility.patch(url, body);
      } else {
        const url = buildPath(Routes.api2.questionnaires);
        await Api.utility.post(url, body);
      }

      form.success('Questionnaire saved');
      handleNavigateBack();
    } catch (err) {
      form.error(err);
    }

    form.done();
  };

  const getQuestionnaire = async (): Promise<void> => {
    loader.startLoading('questionnaire');

    try {
      const url = buildPath(
        Routes.api2.questionnaire,
        { id: questionnaire_id },
        null,
        [
          'questionnaire_questions',
          'questionnaire_questions.questionnaire_answers',
        ],
      );
      const response = await Api.utility.get(url);
      const resource = new Modeler<Questionnaire>(response.data, {
        generations: 2,
      }).build();

      form.set(resource);
    } catch (err) {
      form.error(err);
    }

    loader.stopLoading('questionnaire');
  };

  const handleDeleteQuestionnaire = async (): Promise<void> => {
    form.submit();

    try {
      const url = buildPath(Routes.api2.questionnaire, {
        id: questionnaire_id,
      });
      await Api.utility.delete(url);
    } catch (err) {
      form.error(err);
    }

    form.done();
  };

  useEffect((): void => {
    if (isEditForm) {
      getQuestionnaire();
    }
  }, []);

  const renderQuestions = ((): ReactNode => {
    return form.data.questionnaire_questions.map((question, index) => {
      if (!question._destroy) {
        const validationStore = (form.store as FormValidations)
          ?.questionnaire_questions;

        const validations = (validationStore || [])[index];

        return (
          <FormQuestion
            activeQuestions={activeQuestions}
            index={index}
            key={index}
            onChangePosition={handleRepositionQuestion}
            onChangeQuestion={handleUpdateQuestion}
            onRemoveQuestion={handleRemoveQuestion}
            question={question}
            validations={validations}
          />
        );
      }
    });
  })();

  const renderDeleteButton = (() => {
    if (isEditForm) {
      return (
        <a className="questionnaire-remove" onClick={form.displayConfirmation}>
          Delete Questionnaire
        </a>
      );
    }
  })();

  if (loader.areAnyLoading) {
    return <loader.activity />;
  }

  return (
    <>
      <div className="questionnaire-form">
        <Panel>
          <Constraint size="large">
            <div className="columns">
              <div className="column">
                <InputGroup
                  label="Questionnaire Name"
                  onChange={value => form.update('name', value)}
                  value={form.data.name}
                  validationMessage={form.store.name}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <CheckboxGroup
                  checkedValue="default"
                  label="Make Default"
                  onChange={value => form.update('status', value)}
                  uncheckedValue="standard"
                  value={form.data.status}
                />
              </div>
            </div>
          </Constraint>
        </Panel>

        {renderQuestions}

        <Container>
          <div className="columns">
            <div className="column">
              <Constraint size="large">
                <Button color="white" onClick={handleAddQuestion}>
                  Add Question
                </Button>
              </Constraint>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <Constraint size="large">
                <Actions>
                  <>
                    <Button color="white" onClick={handleNavigateBack}>
                      Cancel
                    </Button>

                    <Button
                      color="secondary"
                      isDisabled={form.isSubmitting}
                      onClick={handleClickSave}>
                      Save
                    </Button>
                  </>

                  {renderDeleteButton}
                </Actions>
              </Constraint>
            </div>
          </div>
        </Container>
      </div>

      <ConfirmationModal
        acceptText="Delete"
        description="Are you sure you want to delete this questionnaire?"
        header="Delete questionnaire?"
        isVisible={form.isConfirmationVisible}
        onAccept={handleDeleteQuestionnaire}
        onClose={form.closeConfirmation}
        rejectText="Cancel"
      />
    </>
  );
};

export default QuestionnaireForm;
