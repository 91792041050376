import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Datepicker from '@/components/form/Datepicker';
import SelectGroup from '@/components/form/SelectGroup';
import { Table } from '@/components/table';
import DateTimeCell from '@/components/table/DateTimeCell';
import { getSelectedPatient } from '@/selectors/api';
import { selectPracticeReadingThresholds } from '@/selectors/patients';
import { DeviceReading } from '@/types/device_reading';
import { Attributable } from '@/types/generic';
import { TableConfig } from '@/types/table';
import {
  bloodGlucoseColumns,
  bloodO2SaturationColumns,
  bloodPressureColumns,
  weightColumns
} from '@/utils/deviceReadingUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { perPageOptions } from '@/utils/tableUtils';
import AlertLevel from '../AlertLevel';
import { getReadingLevel } from '../utils';
import ReadingLegend from './ReadingLegend';
import { timePeriodOptions, typeOptions } from './utils';

const SEARCH_PATH = 'api/v1/device_readings';

const INTITIAL_QUERY = {
  reading_type: '1',
  time_slot: '',
  start_at: '',
  end_at: '',
  per: 24,
};

const ReadingsTable = () => {
  const patient = useSelector(getSelectedPatient);

  const [query, setQuery] = useState<Attributable>(INTITIAL_QUERY);
  const thresholds = useSelector(selectPracticeReadingThresholds);

  const updateQuery = (field: string, value: string | number) => {
    setQuery({
      ...query,
      [field]: value,
    });
  };

  // See notes in device_reading.rb for type map
  const readingColumns = () => {
    switch (query.reading_type) {
      case '1':
        return bloodGlucoseColumns;

      case '2':
        return bloodPressureColumns;

      case '7':
        return bloodO2SaturationColumns;
      
      case '8':
        return weightColumns;
    }
  };

  const formattedDates = () => {
    const startAt = query.start_at
      ? moment(query.start_at)
          .utc()
          .format()
      : '';
    const endAt = query.end_at
      ? moment(query.end_at)
          .endOf('day')
          .utc()
          .format()
      : '';

    return {
      start_at: startAt,
      end_at: endAt,
    };
  };

  const formattedQuery = {
    ...query,
    ...formattedDates(),
  };

  const getReadingResultLevel = (result: DeviceReading) => {
    return getReadingLevel(
      result.attributes.reading_type,
      result.attributes.value_1,
      thresholds,
    );
  };

  const renderReadingIcon = (result: DeviceReading) => {
    return <AlertLevel level={getReadingResultLevel(result)} />;
  };

  const getReadingRowClass = (result: DeviceReading) => {
    const level = getReadingResultLevel(result);

    return level ? `is-${level}` : null;
  };

  const config: TableConfig = {
    initialSort: 'reading_time:desc',
    searchPath: SEARCH_PATH,
    searchQuery: {
      ...formattedQuery,
      patient_id: patient.id,
    },
    rows: {
      addRowClass: getReadingRowClass,
      editPath: buildPath(Routes.readingsEdit, { id: patient.id }),
      editId: 'reading_id',
    },
    header: {
      addPath: buildPath(Routes.readingsNew, { id: patient.id }),
    },
    emptyState: 'No device readings yet',
    columns: [
      {
        attribute: 'alert_type',
        isVisibleMobile: true,
        formatEntry: renderReadingIcon,
        width: 0.4,
      },
      {
        header: 'Date',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'reading_time',
        formatEntry: entry => {
          return <DateTimeCell date={entry.reading_time} />;
        },
      },
      {
        header: 'Time Period',
        isSortable: true,
        attribute: 'time_slot',
        formatAttribute: titleizeSnakeCase,
      },
      ...readingColumns(),
    ],
  };

  return (
    <>
      <div className="columns">
        <div className="column is-2">
          <SelectGroup
            label="Reading Type"
            options={typeOptions}
            value={query.reading_type}
            onChange={value => updateQuery('reading_type', value)}
            skipEmptyPlaceholder
          />
        </div>
        <div className="column is-2">
          <SelectGroup
            label="Time Period"
            options={timePeriodOptions}
            value={query.time_slot}
            onChange={value => updateQuery('time_slot', value)}
            skipEmptyPlaceholder
            shouldAllowNull
          />
        </div>
        <div className="column is-2">
          <Datepicker
            label="Start Date"
            value={query.start_at}
            onChange={value => updateQuery('start_at', value)}
          />
        </div>
        <div className="column is-2">
          <Datepicker
            label="End Date"
            value={query.end_at}
            onChange={value => updateQuery('end_at', value)}
          />
        </div>
        <div className="column is-2">
          <SelectGroup
            label="Show"
            options={perPageOptions}
            value={query.per}
            onChange={value => updateQuery('per', value)}
          />
        </div>
      </div>

      <ReadingLegend readingType={query.reading_type} thresholds={thresholds} />
      <Table config={config} />
    </>
  );
};

export default ReadingsTable;
