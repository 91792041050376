import React, { useEffect, useMemo } from 'react';

import Button from '@/components/button/Button';
import SelectGroup from '@/components/form/SelectGroup';
import Modal from '@/components/modal/Modal';
import { getSelectedPatient } from '@/selectors/api';
import { selectCallService } from '@/selectors/call_manager';
import { SideEffect } from '@/types/generic';
import { fullName } from '@/utils/userUtils';
import { useSelector } from 'react-redux';

interface Props {
  isVisible: boolean;
  onClickCall: SideEffect;
  onClickDial: SideEffect;
  onCloseModal: SideEffect;
  onChangePhoneNumber: SideEffect;
  onChangeService: SideEffect;
  selectedPhoneNumber: string;
  passedPatient?: any;
}

const PhoneNumberModal = (props: Props) => {
  const {
    onChangePhoneNumber,
    onChangeService,
    onClickCall,
    onClickDial,
    selectedPhoneNumber,
    passedPatient,
  } = props;

  const callService = useSelector(selectCallService);
  const patient = useSelector(getSelectedPatient) || passedPatient;

  const options = useMemo(() => {
    const combinedOptions = [
      {
        label: `${patient.attributes.phone_number} (Primary)`,
        value: patient.attributes.phone_number,
      },
    ];

    if (patient.attributes.emergency_contact) {
      combinedOptions.push({
        label: `${patient.attributes.emergency_contact.phone_number} (Emergency Contact)`,
        value: patient.attributes.emergency_contact.phone_number,
      });
    }

    return combinedOptions;
  }, [patient]);

  const serviceOptions = useMemo(() => {
    return patient.attributes.patient_services.data.map(service => ({
      label: service.attributes.name,
      value: service.attributes.service_id,
    }));
  }, [patient]);

  useEffect(() => {
    if (patient && serviceOptions[0]) {
      onChangeService(serviceOptions[0].value);
    }
  }, [patient]);

  return (
    <Modal {...props}>
      <div className="caller__modal">
        <div className="columns is-centered">
          <div className="column is-6-desktop">
            <div className="caller__modal-header">
              <span>Call {fullName(patient)}</span>
            </div>

            <div className="caller__modal-select">
              <SelectGroup
                label="Phone Number"
                options={options}
                value={selectedPhoneNumber}
                onChange={onChangePhoneNumber}
                skipEmptyPlaceholder
              />
            </div>

            <div className="caller__modal-select">
              <SelectGroup
                label="Service Type"
                options={serviceOptions}
                value={callService}
                onChange={onChangeService}
              />
            </div>

            <div className="caller__modal-actions">
              <Button
                color="secondary"
                isDisabled={!selectedPhoneNumber}
                isFullWidth
                onClick={onClickCall}>
                Call
              </Button>

              <Button color="secondary" isFullWidth onClick={onClickDial}>
                Dial Number
              </Button>

              <a onClick={props.onCloseModal}>Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PhoneNumberModal;
