import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Form from '@/components/form/Form';
import Panel from '@/components/panel/Panel';
import {
  getConditionGroupOptions,
  getDeviceCategoryOptions,
  getDeviceMakeOptions,
  getDeviceNameOptions,
  getDeviceTypeOptions,
  getServiceOptions,
} from '@/selectors/options';
import useGetRequest from '@/utils/api/useGetRequest';
import { buildPath, Routes } from '@/utils/routeUtils';
import { getResourceName } from './helpers/links';
import { buildFormConfig } from './utils/formUtils';

interface Params {
  resource: string;
}

const PortalNew = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { resource } = useParams<Params>();

  const conditionGroupOptions = useSelector(getConditionGroupOptions);
  const serviceOptions = useSelector(getServiceOptions);

  const deviceCategoryOptions = useSelector(getDeviceCategoryOptions);
  const deviceMakeOptions = useSelector(getDeviceMakeOptions);
  const deviceNameOptions = useSelector(getDeviceNameOptions);
  const deviceTypeOptions = useSelector(getDeviceTypeOptions);

  const backPath =
    resource === 'medication_names'
      ? buildPath(Routes.adminMedications)
      : buildPath(Routes.adminResource, { resource });

  const [getConditionGroups] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    model: 'condition_groups',
    url: 'api/v1/condition_groups?per=null',
  });

  const [getDeviceCategories] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    model: 'device_categories',
    url: buildPath('api/v1/device_categories'),
  });

  const [getDeviceTypes] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    model: 'device_types',
    url: buildPath('api/v1/device_types'),
  });

  const [getDeviceMakes] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    model: 'device_makes',
    url: buildPath('api/v1/device_makes'),
  });

  const [getDeviceNames] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    model: 'device_names',
    url: buildPath('api/v1/device_names'),
  });

  useEffect(() => {
    switch (resource) {
      case 'conditions':
        getConditionGroups();
      case 'device_types':
        getDeviceCategories();
      case 'device_makes':
        getDeviceTypes();
      case 'device_names':
        getDeviceMakes();
      case 'device_models':
        getDeviceNames();
      case 'alert_types':
        getDeviceTypes();
    }
  }, []);

  const options = () => {
    switch (resource) {
      case 'conditions':
        return conditionGroupOptions;
      case 'device_types':
        return deviceCategoryOptions;
      case 'device_makes':
        return deviceTypeOptions;
      case 'device_names':
        return deviceMakeOptions;
      case 'device_models':
        return deviceNameOptions;
      case 'alert_types':
        return deviceTypeOptions;
      case 'billing_codes':
        return serviceOptions;
    }
  };

  const formConfig = buildFormConfig(
    resource,
    history,
    options,
    null,
    null,
    null,
    { serviceOptions },
  );

  return (
    <>
      <Container>
        <BackLink to={backPath}>{`Back to ${getResourceName(
          resource,
        )}`}</BackLink>
      </Container>

      <Panel>
        <Constraint size="large">
          <Form config={formConfig} />
        </Constraint>
      </Panel>
    </>
  );
};

export default PortalNew;
