import React from 'react';

interface Props {
  label: string;
  value: string;
}

const PatientStat = (props: Props) => {
  const { label, value } = props;

  return (
    <div className="patient-stat">
      <label>{label}</label>
      <span>{value}</span>
    </div>
  );
};

export default PatientStat;
