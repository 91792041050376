import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import { getPatientSpecialistsOptions } from '@/selectors/api';
import { FormComponentConfig } from '@/types/form';
import { UUID } from '@/types/generic';
import { IdParams } from '@/types/params';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';

interface Params extends IdParams {
  patient_id: UUID;
}

const AppointmentsEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { patient_id, id } = useParams<Params>();

  const patientOptions = useSelector(getPatientSpecialistsOptions);

  const [response, setResponse] = useState(null);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'appointments',
    onSuccess: (res: any) => {
      setResponse(parseResponseData(res));
    },
  });

  useEffect(() => {
    getEntry();
  }, []);

  const [query] = returnTabQuery();

  const backPath = buildPath(
    Routes.patientsAppointments,
    { id: patient_id },
    query,
  );

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: `api/v1/appointments/${id}`,
      model: 'appointment',
      method: 'PUT',
      onSuccess: () => history.push(backPath),
    },
    state: response.attributes,
    rules: {
      date: { required: true },
      facility: { required: true },
      reason: { required: true },
      specialist_id: { required: true },
    },
    layout: [
      {
        title: 'Edit Appointment',
        fields: [
          [
            {
              label: 'Date',
              field: 'date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
          ],
          [
            {
              label: 'Facility',
              field: 'facility',
              placeholder: 'Enter facility',
              type: 'text',
              size: 4,
            },
            {
              label: 'Provider',
              field: 'specialist_id',
              placeholder: 'Select provider',
              type: 'select',
              size: 4,
              options: patientOptions,
            },
          ],
          [
            {
              label: 'Reason',
              field: 'reason',
              placeholder: 'Enter reason',
              type: 'text',
              size: 8,
            },
          ],
          [
            {
              label: 'Appointment was conducted',
              field: 'is_conducted',
              type: 'checkbox',
              size: 4,
            },
          ],
          [
            {
              label: 'Apointment Summary',
              field: 'summary',
              placeholder: 'Enter appointment summary',
              type: 'text',
              size: 8,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Appointments"
      config={config}
    />
  );
};

export default AppointmentsEdit;
