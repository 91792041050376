import React from 'react';

import FilterHeader from '@/components/filters/FilterHeader';
import SelectGroup from '@/components/form/SelectGroup';
import { Option } from '@/types/form';
import { AdminUser } from '@/types/v2/admin_user';
import { Practice } from '@/types/v2/practice';
import {
  appointmentYearOptions,
  monthOptions,
  pastAppointmentStatusOptions,
  upcomingAppointmentStatusOptions,
} from '@/utils/dropdownUtils';
import { AppointmentsTab, FormState } from './Appointments';

interface Props {
  currentTab: AppointmentsTab;
  form: FormState;
  isMobile?: boolean;
  onChangeForm: (field: string, value: string) => void;
  onClearFilters: () => void;
  practices: Practice[];
  providers: AdminUser[];
}

const AppointmentsFilterHeader = (props: Props) => {
  const {
    currentTab,
    form,
    isMobile,
    onChangeForm,
    onClearFilters,
    practices,
    providers,
  } = props;

  const formatPracticeOptions = (): Option[] => {
    const practiceOptions = practices.map((practice: Practice) => ({
      label: practice.name,
      value: practice.id,
    }));

    return [{ label: 'All', value: 'all' }, ...practiceOptions];
  };

  const formatProviderOptions = (): Option[] => {
    const providerOptions = providers
      .filter(provider => provider)
      .map((provider: AdminUser) => ({
        label: `${provider.first_name} ${provider.last_name}`,
        value: provider.id,
      }));

    return [{ label: 'All', value: 'all' }, ...providerOptions];
  };

  const formatAppointmentStatusOptions = (): Option[] => {
    return currentTab === 'upcoming'
      ? upcomingAppointmentStatusOptions
      : pastAppointmentStatusOptions;
  };

  const formatMonthOptions = (): Option[] => {
    return [{ label: 'None', value: 'none' }, ...monthOptions];
  };

  return (
    <FilterHeader
      includePerPage={false}
      isMobileFilterFullWidth
      onChangeQuery={onChangeForm}
      onClearFilters={onClearFilters}
      query={form}
      shouldHideSearchDisplay>
      <>
        <SelectGroup
          label="Practice"
          onChange={(value: string) => onChangeForm('practice_id', value)}
          options={formatPracticeOptions()}
          value={!!form.practice_id ? form.practice_id : 'all'}
        />

        <SelectGroup
          label="Provider"
          onChange={(value: string) => onChangeForm('admin_user_id', value)}
          options={formatProviderOptions()}
          value={!!form.admin_user_id ? form.admin_user_id : 'all'}
        />

        <SelectGroup
          label="Appointment Status"
          onChange={(value: string) =>
            onChangeForm('appointment_status', value)
          }
          options={formatAppointmentStatusOptions()}
          value={!!form.appointment_status ? form.appointment_status : 'any'}
        />

        {isMobile && <div />}

        <SelectGroup
          label="Appointment Date"
          onChange={(value: string) => onChangeForm('month', value)}
          options={formatMonthOptions()}
          placeholder="Select Month"
          value={form.month}
        />

        <SelectGroup
          onChange={(value: string) => onChangeForm('year', value)}
          options={appointmentYearOptions}
          placeholder="Select Year"
          value={form.year}
        />
      </>
    </FilterHeader>
  );
};

export default AppointmentsFilterHeader;
