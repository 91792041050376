import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SpecialistsEdit from '@/contexts/patients/modules/specialists/SpecialistsEdit';
import SpecialistsIndex from '@/contexts/patients/modules/specialists/SpecialistsIndex';
import SpecialistsNew from '@/contexts/patients/modules/specialists/SpecialistsNew';
import { Routes } from '@/utils/routeUtils';

const Patients = () => {
  return (
    <Switch>
      <Route
        path={Routes.patientsSpecialistsEdit}
        component={SpecialistsEdit}
      />
      <Route path={Routes.patientsSpecialistsNew} component={SpecialistsNew} />
      <Route path={Routes.patientsSpecialists} component={SpecialistsIndex} />
    </Switch>
  );
};

export default Patients;
