import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '@/components/button/Button';
import BhiEntryChecklist from '@/components/form/BhiEntryChecklist';
import EnrollmentActions from '@/contexts/enrollment/components/EnrollmentActions';
import EnrollmentHeader from '@/contexts/enrollment/components/EnrollmentHeader';
import { getStepInformation } from '@/contexts/enrollment/helpers/steps';
import { createEntryOptions } from '@/contexts/enrollment/utils/optionUtils';
import { getSelectedPatient } from '@/selectors/api';
import {
  selectCurrentCarePlan,
  selectCurrentCarePlanConditions,
} from '@/selectors/bhi';
import { getBhiServiceId, getSupportOptions } from '@/selectors/options';
import { Nullable, UUID } from '@/types/generic';
import { Response } from '@/types/response';
import { formatIsoDateTime } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useHistory, useParams } from 'react-router-dom';
import ContactsIndex from '../contacts/ContactsIndex';
import SpecialistsIndex from '../specialists/SpecialistsIndex';

interface CarePlanSupportOption {
  _destroy: boolean;
  id: UUID;
  text: Nullable<string>;
}

interface Params {
  sub_step: string;
}

const BhiSupport = () => {
  const history = useHistory();

  const { sub_step } = useParams<Params>();

  const data = useSelector(getSupportOptions);
  const bhiServiceId = useSelector(getBhiServiceId);
  const carePlan = useSelector(selectCurrentCarePlan);
  const conditions = useSelector(selectCurrentCarePlanConditions);
  const patient = useSelector(getSelectedPatient);

  const [originalOptions, setOriginalOptions] = useState<
    CarePlanSupportOption[]
  >([]);
  const [selectedOptions, setSelectedOptions] = useState<
    CarePlanSupportOption[]
  >([]);

  useEffect(() => {
    if (carePlan) {
      const ids = carePlan.data.attributes.care_plan_support_options.map(
        (c: any) => ({
          _destroy: false,
          id: c.id,
          support_option_id: c.support_option_id,
          text: c.text,
        }),
      );

      setOriginalOptions(ids);
      setSelectedOptions(ids);
    } else {
      setOriginalOptions([]);
      setSelectedOptions([]);
    }
  }, [carePlan]);

  const stepInformation = getStepInformation('bhi_support', patient);

  const nextScreen = buildPath(Routes.patientsEnrollment, {
    id: patient.id,
    step: stepInformation.nextStep,
  });

  const formatBody = () => {
    if (carePlan) {
      return {
        care_plan: {
          care_plan_support_options_attributes: selectedOptions,
          support_completed_at: formatIsoDateTime(),
        },
      };
    } else {
      return {
        care_plan: {
          care_plan_support_options_attributes: selectedOptions,
          patient_id: patient.id,
          service_id: bhiServiceId,
          support_completed_at: formatIsoDateTime(),
        },
      };
    }
  };

  const handleSuccessCheckboxes = (res: Response) => {
    const hasSpecialistSupport = !!res.data.attributes.support_options.find(
      o => o.option_type === 'specialist',
    );
    const hasFamilySupportOption = !!res.data.attributes.support_options.find(
      o => o.option_type === 'family',
    );

    if (hasSpecialistSupport) {
      const nextIndex = buildPath(Routes.patientsEnrollmentStep, {
        id: patient.id,
        step: 'bhi_support',
        sub_step: 'specialists',
      });

      history.push(nextIndex);
    } else if (hasFamilySupportOption) {
      const nextIndex = buildPath(Routes.patientsEnrollmentStep, {
        id: patient.id,
        step: 'bhi_support',
        sub_step: 'family',
      });

      history.push(nextIndex);
    } else {
      history.push(nextScreen);
    }
  };

  const handleSpecialistsNext = () => {
    const hasFamilySupportOption = !!carePlan.data.attributes.support_options.find(
      o => o.option_type === 'family',
    );

    if (hasFamilySupportOption) {
      const nextIndex = buildPath(Routes.patientsEnrollmentStep, {
        id: patient.id,
        step: 'bhi_support',
        sub_step: 'family',
      });

      history.push(nextIndex);
    } else {
      history.push(nextScreen);
    }
  };

  const handleFamilyNext = () => {
    history.push(nextScreen);
  };

  const options = createEntryOptions(conditions, data);

  const renderScreen = () => {
    switch (sub_step) {
      case 'family':
        return (
          <>
            <EnrollmentHeader
              description="Add details about the patient’s support system"
              linkTo={nextScreen}
              linkText="Expected Outcomes"
              title="Support (Family)"
            />

            <div className="enrollment-table-wrapper">
              <ContactsIndex />
            </div>

            <EnrollmentActions shouldDisplayAction>
              <Button color="secondary" onClick={handleFamilyNext}>
                Next
              </Button>
            </EnrollmentActions>
          </>
        );

      case 'specialists':
        return (
          <>
            <EnrollmentHeader
              description="Add details about the patient’s support system"
              linkTo={nextScreen}
              linkText="Expected Outcomes"
              title="Support (Specialists)"
            />

            <div className="enrollment-table-wrapper">
              <SpecialistsIndex />
            </div>

            <EnrollmentActions shouldDisplayAction>
              <Button color="secondary" onClick={handleSpecialistsNext}>
                Next
              </Button>
            </EnrollmentActions>
          </>
        );

      default:
        return (
          <>
            <EnrollmentHeader
              description="Add details about the patient’s support system"
              linkTo={nextScreen}
              linkText="Expected Outcomes"
              title="Support"
            />
            <BhiEntryChecklist
              carePlan={carePlan}
              formatBody={formatBody}
              newOptionFields={{ text: '' }}
              onChange={newOptions => setSelectedOptions(newOptions)}
              onSuccess={handleSuccessCheckboxes}
              options={options}
              originalSelectedIds={originalOptions}
              selectedIds={selectedOptions}
              selectionParentIdKey="support_option_id"
            />
          </>
        );
    }
  };

  return <div className="enrollment-wrapper">{renderScreen()}</div>;
};

export default BhiSupport;
