import React, { ReactNode } from 'react';
import Container from '../container/Container';
import Panel from '../panel/Panel';

interface Props {
  children: ReactNode;
  shouldRenderWithPanel?: boolean;
  title: string;
}

export const Section = (props: Props) => {
  const { children, shouldRenderWithPanel, title } = props;

  if (shouldRenderWithPanel) {
    return (
      <Panel withNarrowMargin>
        <Container>
          <div className="form-section">
            <h4 className="form-section__title">{title}</h4>
            {children}
          </div>
        </Container>
      </Panel>
    );
  }

  return (
    <div className="form-section">
      <h4 className="form-section__title">{title}</h4>
      {children}
    </div>
  );
};

export default Section;
