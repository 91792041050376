import React from 'react';

import { SideEffect } from '@/types/generic';
import Checkbox from '@/components/form/Checkbox';

export interface Option {
  label: string;
  isChecked: boolean;
  onClick: SideEffect;
}

interface Props {
  options: Option[];
  label: string;
}

const SimpleChecklist = (props: Props) => {
  const { options, label } = props;

  return (
    <div className="simple-checklist">
      <h2 className="simple-checklist__label">{label}</h2>

      {options.map(option => {
        const { label, isChecked, onClick } = option;
        return (
          <label key={option.label} className="form-checkbox-wrapper is-simple" onClick={onClick}>
            <Checkbox isChecked={isChecked} />
            <span>{label}</span>
          </label>
        );
      })}
    </div>
  );
};

export default SimpleChecklist;
