import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  ApplicationSagaActions,
  GetApplicationData,
  getApplicationDataFailure,
  GetApplicationDataFailure,
  getApplicationDataSuccess,
} from '@/actions/reducers/application';
import Api from '@/api/Api';
import { selectIsCurrentUserAnyCareManager } from '@/selectors/users';
import { Routes } from '@/utils/routeUtils';
import { setFeatureFlags } from '../actions/feature_flags';
import { apiAction } from '../reducers/api';
import { flashError } from './messageSaga';

const getData = async (url: string) => {
  const response = await Api.utility.get(url);

  return response.data;
};

function* getApplicationDataSaga(_action: GetApplicationData) {
  try {
    let data: any;

    const isCurrentUserCareManager = yield select(
      selectIsCurrentUserAnyCareManager,
    );

    data = yield call(getData, Routes.api2.featureFlags);
    yield put(setFeatureFlags(data));

    if (isCurrentUserCareManager) {
      // NOTE: Ideally I'd like to have this done in a more Saga-like fashion (ex using yield all(...) or something similar), but because this data was all originally loaded via the requestSaga API we'd need to make some significant adjustments to ensure everything loads and provides feedback (that way the generator pauses at each yield). The purpose of this is the ensure all data is loaded before proceeding to the application proper, but unfortuantely there is no concept of a completed saga if I simply yield put(...) something since it only dispatches an action
      data = yield call(getData, 'api/v1/ccm_complexities');
      yield put(apiAction('ccm_complexities', data));

      data = yield call(getData, 'api/v1/icd_codes?per=null');
      yield put(apiAction('icd_codes', data));

      data = yield call(getData, 'api/v1/barriers?per=null&include=condition');
      yield put(apiAction('barriers', data));

      data = yield call(
        getData,
        'api/v1/conditions?per=null&include=condition_group&sort=position:asc',
      );
      yield put(apiAction('conditions', data));

      data = yield call(getData, 'api/v1/condition_groups?per=null');
      yield put(apiAction('condition_groups', data));

      data = yield call(getData, 'api/v1/device_categories?per=null');
      yield put(apiAction('device_categories', data));

      data = yield call(
        getData,
        'api/v1/device_makes?per=null&include=device_type',
      );
      yield put(apiAction('device_makes', data));

      data = yield call(
        getData,
        'api/v1/device_names?per=null&include=device_make',
      );
      yield put(apiAction('device_names', data));

      data = yield call(
        getData,
        'api/v1/device_types?per=null&include=device_category',
      );
      yield put(apiAction('device_types', data));

      data = yield call(getData, 'api/v1/goals?per=null&include=condition');
      yield put(apiAction('goals', data));

      data = yield call(getData, 'api/v1/manual_entry_reasons?per=null');
      yield put(apiAction('manual_entry_reasons', data));

      data = yield call(getData, 'api/v1/metrics?per=null');
      yield put(apiAction('metrics', data));

      data = yield call(getData, 'api/v1/screening_types?per=null');
      yield put(apiAction('screening_types', data));

      data = yield call(getData, 'api/v1/outcomes?per=null&include=condition');
      yield put(apiAction('outcomes', data));

      data = yield call(getData, 'api/v1/symptoms?per=null&include=condition');
      yield put(apiAction('symptoms', data));

      data = yield call(getData, 'api/v1/immunization_names?per=null');
      yield put(apiAction('immunization_names', data));

      data = yield call(getData, 'api/v1/protocols?per=null');
      yield put(apiAction('protocols', data));

      data = yield call(getData, 'api/v1/services?per=null');
      yield put(apiAction('services', data));

      data = yield call(
        getData,
        'api/v1/billing_codes?per=null&include=service',
      );
      yield put(apiAction('billing_codes', data));

      data = yield call(getData, 'api/v1/caller_ids?per=null');
      yield put(apiAction('caller_ids', data));

      data = yield call(getData, 'api/v1/support_options?per=null');
      yield put(apiAction('support_options', data));

      data = yield call(getData, 'api/v1/answer_sets?per=null&include=answers');
      yield put(apiAction('answer_sets', data));

      data = yield call(
        getData,
        'api/v1/tracking_question_sets?per=null&include=implication_responses,tracking_questions',
      );
      yield put(apiAction('tracking_question_sets', data));
    }

    yield put(getApplicationDataSuccess());
  } catch (err) {
    yield put(getApplicationDataFailure());
  }
}

function* getApplicationDataFailureSaga(_action: GetApplicationDataFailure) {
  yield put(flashError('Error retrieving application data'));
}

export default function* applicationSaga() {
  yield takeLatest(
    ApplicationSagaActions.GET_APPLICATION_DATA,
    getApplicationDataSaga,
  );
  yield takeLatest(
    ApplicationSagaActions.GET_APPLICATION_DATA_FAILURE,
    getApplicationDataFailureSaga,
  );
}
