import { Address } from '@/types/v2/address';
import React from 'react';

interface Props {
  address: Address;
}

const AddressBlock = ({ address }: Props): JSX.Element => {
  const { line_1, line_2, city, state, zip_code } = address;

  const renderLine2 = (() => {
    if (line_2) {
      return (
        <>
          {line_2}
          <br />
        </>
      );
    }
  })();
  return (
    <div className="address">
      {line_1}
      <br />
      {renderLine2}
      {city} {state}, {zip_code}
    </div>
  );
};

export default AddressBlock;
