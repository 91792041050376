import React, { useState } from 'react';

import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Container from '@/components/container/Container';
import Icon from '@/components/icon/Icon';
import Panel from '@/components/panel/Panel';
import { Patient } from '@/types/v2/patient';
import { returnPath, Routes } from '@/utils/routeUtils';
import PatientDetails from './PatientDetails';
import PatientDetailsExpanded from './PatientDetailsExpanded';

interface Props {
  patient: Patient;
}

const PatientHeader = (props: Props) => {
  const { patient } = props;

  const [areDetailsVisible, setAreDetailsVisible] = useState<boolean>(false);

  const buttonText = () => {
    if (areDetailsVisible) {
      return 'Hide Full Patient Details';
    }

    return 'Show Full Patient Details';
  };

  const iconRotation = () => {
    return areDetailsVisible ? 180 : 0;
  };

  const handleDetailsButtonClick = () => {
    setAreDetailsVisible(!areDetailsVisible);
  };

  return (
    <>
      <Container>
        <BackLink to={returnPath() || Routes.provider.patients.root}>
          Back to List
        </BackLink>
      </Container>

      <Panel>
        <Container>
          <PatientDetails patient={patient} />

          <div className="patient-calls__expand">
            <Button style="flat-large" onClick={handleDetailsButtonClick}>
              {buttonText()}
              <Icon icon="caretDown" rotation={iconRotation()} />
            </Button>
          </div>

          <PatientDetailsExpanded
            patient={patient}
            isVisible={areDetailsVisible}
          />
        </Container>
      </Panel>
    </>
  );
};

export default PatientHeader;
