import { Capability, UserRole } from '@/types/user';

type PolicyGroup = (Capability | UserRole)[];

export const atLeastCareManager: PolicyGroup = [
  'care_manager',
  'admin',
  'billing_admin',
  'super_admin',
];
export const atLeastAdmin: PolicyGroup = [
  'admin',
  'billing_admin',
  'super_admin',
];
export const atLeastBillingAdmin: PolicyGroup = [
  'billing_admin',
  'super_admin',
];
export const atLeastSuperAdmin: PolicyGroup = ['super_admin'];
export const nonBillingAdmins: PolicyGroup = ['admin', 'super_admin'];
export const nonBillingAny: PolicyGroup = [
  'care_manager',
  'admin',
  'super_admin',
];

export const patientOnly: PolicyGroup = ['patient'];
export const practiceManagers: PolicyGroup = [
  'join_video_call',
  'modify_appointment',
  'modify_practice',
];

export const PolicyGroups = {
  atLeastCareManager,
  atLeastAdmin,
  atLeastBillingAdmin,
  atLeastSuperAdmin,
  nonBillingAdmins,
  nonBillingAny,
  patientOnly,
  practiceManagers,
};

export const isUserAtLeast = (user, group) => {
  return group.includes(user.data.attributes.user_role);
};
