import classNames from 'classnames';
import React from 'react';

import { SideEffect } from '@/types/generic';

interface Props {
  isInvalid?: boolean;
  onChange?: SideEffect;
  onPressEnter?: SideEffect;
  placeholder?: string;
  value: string;
}

export const TextArea = (props: Props) => {
  const { isInvalid, onChange, onPressEnter, value, ...inputProps } = props;

  const handleChange = event => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ): void => {
    if (event.key === 'Enter' && onPressEnter) {
      onPressEnter();
    }
  };

  const isFilled = !!value;

  return (
    <textarea
      className={classNames('form-text-area', {
        'is-filled': isFilled,
      })}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      rows={4}
      value={value || ''}
      {...inputProps}
    />
  );
};

export default TextArea;
