import React, { useEffect, useState } from 'react';

import Api from '@/api/Api';
import Input from '@/components/form/Input';
import Icon from '@/components/icon/Icon';
import { Option } from '@/types/form';
import { Nullable, SideEffect } from '@/types/generic';
import { buildRequestPath } from '@/utils/apiUtils';
import Modeler from '@/utils/modeler/modeler';
import { usePrevious } from '@/utils/stateUtils';
import TypeaheadResults from './TypeaheadResults';

export interface FormattedResult {
  label: string;
  value: string;
}

export interface Props {
  clearFalseResultOnBlur?: boolean;
  formatResult: (result: any) => FormattedResult;
  guideValue?: string;
  ignoreValues?: string[];
  isDisabled?: boolean;
  initialSelection?: FormattedResult;
  initialValue?: string;
  isInvalid?: boolean;
  minimumInputLength?: number;
  onChange?: SideEffect;
  options?: Option[];
  placeholder?: string;
  queryParams?: any;
  searchPath?: string;
  updateValue?: FormattedResult;
  value?: string;
  shouldUseModeler?: boolean;
  modelerGenerations?: number;
  name?:string
}

export const formatSearchResult = (result: any): FormattedResult => {
  return {
    label: result.attributes.name,
    value: result.id,
  };
};

export const formatSearchResultCode = (result: any): FormattedResult => {
  return {
    label: `${result.attributes.code} - ${result.attributes.name}`,
    value: result.id,
  };
};

const Typeahead = (props: Props) => {
  const {
    clearFalseResultOnBlur,
    formatResult,
    guideValue,
    ignoreValues,
    initialSelection,
    initialValue,
    isDisabled,
    minimumInputLength,
    onChange,
    placeholder,
    queryParams,
    searchPath,
    updateValue,
    value,
    name,
    shouldUseModeler,
    modelerGenerations = 1,
  } = props;

  const setMinimumInputLengthToZeroIfItDoesNotExist = () => {
    return minimumInputLength? minimumInputLength : 0;
  }

  const MINIMUM_INPUT_LENGTH = 0;
  const SEARCH_COUNT_LIMIT = 50;

  const [areResultsVisible, setAreResultsVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isFetchingResults, setIsFetchingResults] = useState<boolean>(false);
  const [isResultSet, setIsResultSet] = useState<boolean>(false);
  const [results, setResults] = useState<FormattedResult[]>([]);
  const [selectedResult, setSelectedResult] = useState<
    Nullable<FormattedResult>
  >(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const previousUpdate = usePrevious(updateValue);

  useEffect(() => {
    if (
      updateValue &&
      updateValue.value &&
      updateValue.value !== (previousUpdate || { value: null }).value
    ) {
      handleSelectResult(updateValue);
    }
  }, [updateValue]);

  const fetchResults = async (force: boolean = false) => {
    if (inputValue.length < (setMinimumInputLengthToZeroIfItDoesNotExist() || MINIMUM_INPUT_LENGTH)) {
      if (setMinimumInputLengthToZeroIfItDoesNotExist() !== 0 || !force) {
        return setResults([]);
      }
    }

    if (isResultSet) {
      return setIsResultSet(false);
    }

    if (!isFetchingResults) {
      setIsFetchingResults(true);

      const fullPath = buildRequestPath(searchPath, {
        per: SEARCH_COUNT_LIMIT,
        search: inputValue,
        ...(queryParams || {}),
      });

      const response = await Api.utility.get(fullPath);

      setIsFetchingResults(false);

      const formattedResults = (() => {
        if (shouldUseModeler) {
          const modeledData = new Modeler<any[]>(response.data, {
            generations: modelerGenerations,
          }).build();
          return modeledData.map(item => formatResult(item));
        }

        return response.data.data.map(item => formatResult(item));
      })();

      if (ignoreValues) {
          const filteredResults = formattedResults.filter(
          item => !ignoreValues.includes(item.value),
        );
        setResults(filteredResults);
        

      } else {
        setResults(formattedResults);
        
      }
    }
  };

  useEffect(() => {
    if (selectedResult && guideValue !== selectedResult.value) {
      setInputValue('');
    }
    if(selectedResult){
      if(selectedResult.value==localStorage.getItem('health_system_id'))
      {
        localStorage.setItem('HealthSystem',selectedResult.label)
      }
     if(selectedResult.value==localStorage.getItem('hospital_id'))
      {
        localStorage.setItem('Hospital',selectedResult.label)
      }
      if(selectedResult.value==localStorage.getItem('practice_id'))
      {
        localStorage.setItem('Practice',selectedResult.label)
      }
     if(selectedResult.value==localStorage.getItem('specialist_id'))
      {
        localStorage.setItem('Specialist',selectedResult.label)
      }
      if(selectedResult.value==localStorage.getItem('care_manager_id'))
      {
        localStorage.setItem('CareManager',selectedResult.label)
      }
      
      
  }

  }, [guideValue]); // storing patient search filter labels

  useEffect(() => {
    if (initialSelection) {
      handleSelectResult(initialSelection);
    } else if (initialValue) {
      handleSelectResult({
        label: initialValue,
        value: initialValue,
      });
    } else if (value) {
      handleSelectResult({
        label: value,
        value,
      });
    }
  }, []);

  useEffect(() => {
    fetchResults();
  }, [inputValue]);

  useEffect(() => {
    if (selectedResult) {
      setIsResultSet(true);
      setInputValue(selectedResult.label);
    }

    if (onChange) {
      onChange(selectedResult?.value || '', selectedResult);
    }
  }, [selectedResult]);

  useEffect(() => {
    if (results.length > 0 && isFocused) {
      setAreResultsVisible(true);
    } else {
      setAreResultsVisible(false);
    }
  }, [results]);

  const handleBlur = () => {
    if (
      clearFalseResultOnBlur &&
      selectedResult &&
      selectedResult.label !== inputValue
    ) {
      setInputValue('');
      setSelectedResult(null);
    }

    setAreResultsVisible(false);
  };

  const handleFocus = () => {
    setIsFocused(true);

    if (setMinimumInputLengthToZeroIfItDoesNotExist() === 0) {
      fetchResults(true);
    }

    if (results.length && !!inputValue) {
      setAreResultsVisible(true);
    }
  };

  const handleSelectResult = (result: FormattedResult) => {
    setSelectedResult(result);
    setAreResultsVisible(false);
  };

  const handleUpdateText = (text: string) => {
    setInputValue(text);
  };

  return (
    <div className="typeahead">
      <div className="form-select">
        <Input
          isDisabled={isDisabled}
          onBlur={handleBlur}
          onChange={handleUpdateText}
          onFocus={handleFocus}
          placeholder={placeholder}
          value={inputValue}
        
        />

        <div className="form-select__icon">
          <Icon icon="searchBlack" color="secondary" />
        </div>
      </div>

      <TypeaheadResults
        isVisible={areResultsVisible}
        onClickResult={handleSelectResult}
        results={results}
      />
    </div>
  );
};

export default Typeahead;
