import { create, User, UsersState } from '@/types/user';

export enum UsersActions {
  SET_CURRENT_USER = 'USERS/SET_CURRENT_USER',
  SET_UNREAD_NOTIFICATIONS = 'USERS/SET_UNREAD_NOTIFICATIONS',
}

interface SetCurrentUser {
  type: typeof UsersActions.SET_CURRENT_USER;
  payload: {
    user: User;
  };
}

interface SetUnreadNotifications {
  type: typeof UsersActions.SET_UNREAD_NOTIFICATIONS;
  payload: {
    unreadNotifications: number;
  };
}

type UsersAction = SetCurrentUser | SetUnreadNotifications;

export const setCurrentUser = (user: User): UsersAction => {
  return {
    type: UsersActions.SET_CURRENT_USER,
    payload: {
      user,
    },
  };
};

export const setUnreadNotifications = (
  unreadNotifications: number,
): UsersAction => ({
  type: UsersActions.SET_UNREAD_NOTIFICATIONS,
  payload: {
    unreadNotifications,
  },
});

export default function reducer(
  state: UsersState = create(),
  action: UsersAction,
): UsersState {
  switch (action.type) {
    case UsersActions.SET_CURRENT_USER:
      return {
        ...state,
        current: action.payload.user,
      };

    case UsersActions.SET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        current: {
          ...state.current,
          data: {
            ...state.current.data,
            attributes: {
              ...state.current.data.attributes,
              unread_notifications: action.payload.unreadNotifications,
            },
          },
        },
      };

    default:
      return state;
  }
}
