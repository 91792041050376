import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DeviceReadingsForm from '@/contexts/patients/modules/device_readings/DeviceReadingsForm';
import { Routes } from '@/utils/routeUtils';

const DeviceReadings = () => {
  return (
    <Switch>
      <Route path={Routes.readingsEdit} component={DeviceReadingsForm} />
      <Route path={Routes.readingsNew} component={DeviceReadingsForm} />
    </Switch>
  );
};

export default DeviceReadings;
