import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import ErrorMessage from '@/components/form/ErrorMessage';
import ImagePreviewUpload from '@/components/form/ImagePreviewUpload';
import InputGroup from '@/components/form/InputGroup';
import SearchGroup from '@/components/form/SearchGroup';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Modal from '@/components/modal/Modal';
import Panel from '@/components/panel/Panel';
import { getProtocolsOptions } from '@/selectors/options';
import { Validations } from '@/types/form';
import useDelRequest from '@/utils/api/useDelRequest';
import useGetRequest from '@/utils/api/useGetRequest';
import usePutRequest from '@/utils/api/usePutRequest';
import { parseErrorData, parseResponseData } from '@/utils/apiUtils';
import { updateArrayIndex } from '@/utils/arrayUtils';
import { statusOptions } from '@/utils/dropdownUtils';
import { validate } from '@/utils/form';
import { getInclude } from '@/utils/responseUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import HealthSystemsNew from '../health_systems/HealthSystemsNew';
import AddressPanel from './components/AddressPanel';
import { IdParams } from '@/types/params';

const blankAddress = {
  label: '',
  line_1: '',
  line_2: '',
  city: '',
  state: '',
  zip_code: '',
  status: '',
};

const FORM_STATE = {
  addresses: [blankAddress],
  health_system_id: '',
  image_id: '',
  name: '',
  protocol_id: '',
  status: '',
  twillio_phone_number: '',
};

const HospitalsEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const location = useLocation();

  const { id } = useParams<IdParams>();

  const [response, setResponse] = useState(null);

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});
  const [addressValidations, setAddressValidations] = useState<Validations[]>([
    {},
  ]);

  const healthSystem = getInclude(response, 'health_system');

  const backPath = buildPath(Routes.hospitals);

  const protocolNameOptions = useSelector(getProtocolsOptions);

  useEffect(() => {
    getEntry();
  }, []);

  useEffect(() => {
    if (response) {
      const formattedResponse = {
        ...response.attributes,
        image_id: '',
        protocol_id: response.attributes.protocols[0].id,
      };
      setForm(formattedResponse);
    }
  }, [response]);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'hospitals',
    onSuccess: (res: any) => {
      const responseData = parseResponseData(res);

      setResponse(responseData);
    },
  });

  const handleFailure = (res: any) => {
    const errorMessage = parseErrorData(res);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const handleSuccess = () => {
    dispatch(flashSuccess('Hospital edited'));

    history.push(backPath);
  };

  const handleDeleteSuccess = () => {
    history.push(backPath);
  };

  const formatBody = () => {
    return {
      hospital: {
        addresses_attributes: form.addresses,
        health_system_id: form.health_system_id,
        image_id: form.image_id,
        name: form.name,
        protocol_ids: [form.protocol_id],
        status: form.status,
        twillio_phone_number: form.twillio_phone_number,
      },
    };
  };

  const rules = {
    name: { required: true },
    twillio_phone_number: { required: true, type: 'phone' },
    health_system_id: { required: true },
    status: { required: true },
    protocol_id: { required: true },
  };

  const addressRules = {
    line_1: { required: true },
    city: { required: true },
    state: { required: true },
    zip_code: { required: true },
  };

  const [submitForm, isSubmitting] = usePutRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: `api/v1/hospitals/${id}`,
  });

  const [deleteHospital] = useDelRequest({
    dispatch,
    onSuccess: handleDeleteSuccess,
    url: `api/v1/hospitals/${id}`,
  });

  const handleDeleteHospital = () => {
    deleteHospital();
  };

  const handleCloseModal = () => {
    const url = buildPath(Routes.hospitalsEdit, { id });

    history.push(url);
  };

  const handleClickHealthSystem = () => {
    const url = buildPath(Routes.hospitalsEditHealthSystem, { id });

    history.push(url);
  };

  const formatSearchResult = (result: any) => ({
    label: result.attributes.name,
    value: result.id,
  });

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);
    const addressValidationMessages = form.addresses.map(address =>
      validate(address, addressRules),
    );

    const areAddressesInvalid = addressValidationMessages.find(
      validation => validation[0] === false,
    );

    if (isValid && !areAddressesInvalid) {
      setValidations({});
      submitForm();
    } else {
      setValidations(validationMessages);
      setAddressValidations(
        addressValidationMessages.map(validation => validation[1]),
      );
    }
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleAddNewLocation = () => {
    setForm({
      ...form,
      addresses: [...form.addresses, blankAddress],
    });

    setAddressValidations([...addressValidations, {}]);
  };

  const updateAddressField = (index: number, field: string, value: any) => {
    const formAddresses = [...form.addresses];
    const address = formAddresses[index];

    const updatedAddress = {
      ...address,
      [field]: value,
    };

    const updatedForm = {
      ...form,
      addresses: updateArrayIndex(form.addresses, index, updatedAddress),
    };

    setForm(updatedForm);
  };

  const renderModal = () => {
    const modalBackPath = buildPath(Routes.hospitalsEdit, { id });

    if (location.pathname.includes(`health_systems`)) {
      return (
        <Modal isVisible={true} onCloseModal={handleCloseModal}>
          <div className="practice-form__modal">
            <h3 className="practice-form__modal-header">
              Add New Health System
            </h3>
            <HealthSystemsNew hidePanel isModal modalBackPath={modalBackPath} />
          </div>
        </Modal>
      );
    }
  };

  const initialUrl = () => {
    const url = response.attributes.logo_thumbnail_url;
    return url || '';
  };

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <div className="hospital-new__wrapper">
        <Container>
          <BackLink to={backPath}>Cancel</BackLink>
        </Container>

        <Constraint size="large">
          <Panel withNarrowMargin>
            <Section title="Hospital Details">
              <div className="columns is-mobile hospital-new__hospital-details-wrapper">
                <div className="column is-12-mobile is-6-tablet is-5-desktop hospital-new__health-system-wrapper">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatSearchResult}
                    initialSelection={{
                      label: healthSystem.attributes.name,
                      value: healthSystem.id,
                    }}
                    guideValue={form.health_system_id}
                    label="Health System"
                    minimumInputLength={1}
                    onChange={value => updateField('health_system_id', value)}
                    placeholder="Search Health Systems"
                    searchPath="api/v1/health_systems"
                    validationMessage={validations.health_system_id}
                  />
                  <div className="hospital-new__health-system-button-wrapper">
                    <Button
                      style="flat-large add"
                      onClick={handleClickHealthSystem}>
                      Add to New Health System
                    </Button>
                  </div>
                </div>

                <div className="column is-12-mobile is-6-tablet is-4-desktop hospital-new__hospital-wrapper">
                  <InputGroup
                    label="Hospital Name"
                    onChange={value => updateField('name', value)}
                    placeholder="Enter Hospital Name"
                    validationMessage={validations.name}
                    value={form.name}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <ImagePreviewUpload
                    initialUrl={initialUrl()}
                    label="Logo"
                    onChange={(value: string) => updateField('image_id', value)}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel withNarrowMargin>
            <Section title="Status">
              <div className="columns is-mobile">
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Status"
                    onChange={value => updateField('status', value)}
                    options={statusOptions}
                    validationMessage={validations.status}
                    value={form.status}
                  />
                </div>
              </div>

              <div className="columns is-mobile">
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Protocol"
                    onChange={value => updateField('protocol_id', value)}
                    options={protocolNameOptions}
                    validationMessage={validations.protocol_id}
                    value={form.protocol_id}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel withNarrowMargin>
            <Section title="Contact Details">
              <div className="columns is-mobile">
                <div className="column is-6-tablet is-4-desktop">
                  <InputGroup
                    label="Phone Number"
                    onChange={value =>
                      updateField('twillio_phone_number', value)
                    }
                    placeholder="Enter phone number"
                    validationMessage={validations.twillio_phone_number}
                    value={form.twillio_phone_number}
                    type="phonenumber"
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <AddressPanel
            addresses={form.addresses}
            onUpdateAddressField={updateAddressField}
            renderRemoveButton
            addressValidations={addressValidations}
          />

          <div className="hospital-new__location-button-wrapper">
            <Button style="flat-large add" onClick={handleAddNewLocation}>
              Add Location
            </Button>
          </div>

          <ErrorMessage isRightSide message={error} />

          <div className="form__actions">
            <div className="form__actions-left patient-edit__button">
              <Button style="delete" onClick={handleDeleteHospital}>
                Delete Hospital
              </Button>
            </div>
            <div className="form__actions-right">
              <Button color="white" onClick={handleClickCancel}>
                Cancel
              </Button>

              <Button
                color="secondary"
                isSubmitting={isSubmitting}
                onClick={handleClickSubmit}>
                Save
              </Button>
            </div>
          </div>
        </Constraint>
      </div>

      {renderModal()}
    </>
  );
};

export default HospitalsEdit;
