import React from 'react';
import { useSelector } from 'react-redux';

import QuestionnaireForm from '@/modules/questionniares/form/QuestionnaireForm';
import { selectCurrentUserId } from '@/selectors/users';

const ProviderQuestionnaireForm = (): JSX.Element => {
  const userId = useSelector(selectCurrentUserId);

  return (
    <QuestionnaireForm
      questionnaireableId={userId}
      questionnaireableType="AdminUser"
    />
  );
};

export default ProviderQuestionnaireForm;
