import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Api from '@/api/Api';
import Constraint from '@/components/constraint/Constraint';
import { EmptyBlock } from '@/components/dashboard';
import Panel from '@/components/panel/Panel';
import AppointmentModal from '@/modules/appointments/AppointmentModal';
import AppointmentSection from '@/modules/appointments/AppointmentSection';
import { selectCurrentUserId } from '@/selectors/users';
import { UUID } from '@/types/generic';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import { Link, useHistory, useLocation } from 'react-router-dom';
import QuestionnaireModal from '../questionnaires/QuestionnaireModal';
import AppointmentsHeader from './AppointmentsHeader';

export interface FormState {
  admin_user_id: string;
  appointment_status: string;
  month: string;
  practice_id: string;
  year: string;
}

const initialState: FormState = {
  admin_user_id: '',
  appointment_status: '',
  month: '',
  practice_id: '',
  year: '',
};

export type AppointmentsTab = 'upcoming' | 'past';

const emptyAppointments = (text: string, scheduleAppointmentLink: string) => {
  return (
    <EmptyBlock style="compact">
      <div className="appointment-empty">
        <p className="appointment-empty__text">{text}</p>
        <Link className="button is-secondary" to={scheduleAppointmentLink}>
          Schedule Appointment
        </Link>
      </div>
    </EmptyBlock>
  );
};

const Appointments = () => {
  const history = useHistory();
  const location = useLocation();
  const userId = useSelector(selectCurrentUserId);

  const loader = useLoadingState('appointments', 'cancel', 'confirm');
  const notifier = useNotifier();
  const params = new URLSearchParams(location.search);
  const appointmentId = params.get('appointment');

  const [questionnaireAppointmentId, setQuestionnaireAppointmentId] = useState<
    UUID
  >(null);
  const [form, setForm] = useState<FormState>(initialState);
  const [allAppointments, setAllAppointments] = useState<VirtualAppointment[]>(
    [],
  );
  const [appointments, setAppointments] = useState<VirtualAppointment[]>([]);
  const [currentTab, setCurrentTab] = useState<AppointmentsTab>('upcoming');
  const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(
    false,
  );
  const [hasInitialFetch, setHasInitialFetch] = useState<boolean>(false);

  const scheduleAppointmentLink = Routes.chart.appointments.new;

  const practices = useMemo(() => {
    return _.uniqBy(allAppointments, appointment => appointment.practice.id)
      .filter(appointment => {
        if (currentTab === 'upcoming') {
          return (
            appointment.appointment_status === 'created' ||
            appointment.appointment_status === 'confirmed'
          );
        } else {
          return (
            appointment.appointment_status === 'completed' ||
            appointment.appointment_status === 'cancelled'
          );
        }
      })
      .map(appointment => appointment.practice);
  }, [allAppointments, currentTab]);

  const providers = useMemo(() => {
    return _.uniqBy(
      allAppointments,
      (appointment: VirtualAppointment) => appointment.admin_user_id,
    )
      .filter(appointment => {
        if (currentTab === 'upcoming') {
          return (
            appointment.appointment_status === 'created' ||
            appointment.appointment_status === 'confirmed'
          );
        } else {
          return (
            appointment.appointment_status === 'completed' ||
            appointment.appointment_status === 'cancelled'
          );
        }
      })
      .map(appointment => appointment.admin_user);
  }, [allAppointments, currentTab]);

  const upcomingAppointments: VirtualAppointment[] = useMemo(() => {
    return appointments
      .filter(
        (appointment: VirtualAppointment) =>
          appointment.appointment_status === 'created' ||
          appointment.appointment_status === 'confirmed' ||
          appointment.appointment_status === 'started',
      )
      .filter(
        appointment =>
          moment(appointment.scheduled_start_at) >
          moment().subtract(1, 'minutes'),
      );
  }, [appointments]);

  const pastAppointments: VirtualAppointment[] = useMemo(() => {
    return appointments
      .filter(
        (appointment: VirtualAppointment) =>
          appointment.appointment_status === 'completed' ||
          appointment.appointment_status === 'cancelled' ||
          (moment(appointment.scheduled_start_at) <
            moment().subtract(1, 'minutes') &&
            appointment.appointment_status !== 'started'),
      )
      .sort(
        (a, b) =>
          new Date(a.scheduled_start_at).getTime() -
          new Date(b.scheduled_start_at).getTime(),
      );
  }, [appointments]);

  const pendingAppointments: VirtualAppointment[] = useMemo(() => {
    return upcomingAppointments
      .filter(
        (appointment: VirtualAppointment) =>
          appointment.appointment_status === 'created',
      )
      .sort(
        (a, b) =>
          new Date(a.scheduled_start_at).getTime() -
          new Date(b.scheduled_start_at).getTime(),
      );
  }, [upcomingAppointments]);

  const confirmedAppointments: VirtualAppointment[] = useMemo(() => {
    return upcomingAppointments
      .filter(
        (appointment: VirtualAppointment) =>
          appointment.appointment_status === 'confirmed' ||
          appointment.appointment_status === 'started',
      )
      .sort(
        (a, b) =>
          new Date(a.scheduled_start_at).getTime() -
          new Date(b.scheduled_start_at).getTime(),
      );
  }, [upcomingAppointments]);

  const handleClickAppointment = (appointment: VirtualAppointment): void => {
    const id = appointment.id;

    const url = buildPath(Routes.chart.root, null, {
      appointment: id,
    });

    history.push(url);
  };

  const handleClickConfirmAppointment = async (
    appointment: VirtualAppointment,
  ) => {
    loader.startLoading('confirm');

    try {
      const url = buildPath(Routes.api2.virtualAppointment, {
        id: appointment.id,
      });

      const body = {
        status: 'confirmed',
      };

      await Api.utility.patch(url, body);

      fetchEvents();

      notifier.success('Appointment confirmed');
    } catch (err) {
      notifier.error(err);
    }
  };

  const handleStartQuestionnaires = (id: UUID): void => {
    setQuestionnaireAppointmentId(id);
  };

  const handleCloseQuestionnaires = (): void => {
    setQuestionnaireAppointmentId(null);
    fetchEvents();
  };

  const handleEditQuestionnaires = (id: UUID): void => {
    handleNavigateToAppointments();
    handleStartQuestionnaires(id);
  };

  const handleNavigateToAppointments = (): void => {
    history.push(Routes.chart.root);
  };

  const handleClickCancelAppointment = useCallback((): void => {
    setIsCancelModalVisible(true);
  }, []);

  const handleCloseConfirmationModal = useCallback((): void => {
    setIsCancelModalVisible(false);
  }, []);

  const handleCancelAppointment = useCallback(async (): Promise<void> => {
    loader.startLoading('cancel');

    try {
      const url = buildPath(Routes.api2.virtualAppointment, {
        id: appointmentId,
      });

      const body = {
        virtual_appointment: {
          appointment_status: 'cancelled',
        },
      };

      await Api.utility.patch(url, body);

      const closeModalUrl = buildPath(Routes.chart.calendar.root);
      history.push(closeModalUrl);

      handleCloseConfirmationModal();
      notifier.success('Appointment cancelled');
      fetchEvents();
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('cancel');
  }, [appointmentId]);

  const handleChangeForm = (field: string, value: string): void => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleClearFilters = (): void => {
    setForm(initialState);
  };

  const formatForm = (form: FormState): FormState => {
    Object.keys(form).forEach(key => {
      if (form[key] === 'all' || form[key] === 'any' || form[key] === 'none') {
        form[key] = '';
      }
    });

    return form;
  };

  const fetchEvents = () => {
    const url = buildPath(
      Routes.api2.virtualAppointments,
      null,
      {
        per: null,
        patient_id: userId,
        ...formatForm(form),
      },
      [
        'admin_user',
        'admin_user.specialist',
        'practice',
        'virtual_appointment_questionnaires',
      ],
    );

    loader.startLoading('appointments');

    Api.utility.get(url).then(res => {
      const appointmentsResponse = new Modeler<VirtualAppointment[]>(res.data, {
        generations: 2,
      }).build();

      setAppointments(appointmentsResponse);

      if (!hasInitialFetch) {
        setHasInitialFetch(true);
      }

      if (form === initialState) {
        setAllAppointments(appointmentsResponse);
      }
    });

    loader.stopLoading('appointments');
  };

  useEffect(() => {
    fetchEvents();
  }, [form]);

  useEffect(() => {
    setForm(initialState);
  }, [currentTab]);

  const renderUpcomingAppointments = () => {
    if (hasInitialFetch && upcomingAppointments.length === 0) {
      return emptyAppointments(
        'You do not currently have any upcoming appointments',
        scheduleAppointmentLink,
      );
    } else {
      return (
        <>
          {pendingAppointments?.length > 0 && (
            <AppointmentSection
              appointments={pendingAppointments}
              title="Pending Appointments"
              handleClickAppointment={handleClickAppointment}
              handleClickCompleteQuestionnaire={handleStartQuestionnaires}
              handleClickConfirmAppointment={handleClickConfirmAppointment}
            />
          )}

          {confirmedAppointments?.length > 0 && (
            <AppointmentSection
              appointments={confirmedAppointments}
              title="Confirmed Appointments"
              handleClickAppointment={handleClickAppointment}
              handleClickCompleteQuestionnaire={handleStartQuestionnaires}
              handleClickConfirmAppointment={handleClickConfirmAppointment}
            />
          )}
        </>
      );
    }
  };

  const renderPastAppointments = () => {
    if (hasInitialFetch && pastAppointments.length === 0) {
      return emptyAppointments(
        'You do not have any past appointments',
        scheduleAppointmentLink,
      );
    } else {
      return (
        <AppointmentSection
          appointments={pastAppointments}
          title="Past Appointments"
          handleClickAppointment={handleClickAppointment}
          handleClickCompleteQuestionnaire={handleStartQuestionnaires}
          handleClickConfirmAppointment={handleClickConfirmAppointment}
        />
      );
    }
  };

  const renderAppointments = () => {
    return currentTab === 'upcoming'
      ? renderUpcomingAppointments()
      : renderPastAppointments();
  };

  return (
    <>
      <Panel>
        <Constraint size="large">
          <AppointmentsHeader
            currentTab={currentTab}
            form={form}
            onChangeForm={handleChangeForm}
            onClearFilters={handleClearFilters}
            practices={practices}
            providers={providers}
            scheduleAppointmentLink={scheduleAppointmentLink}
            setCurrentTab={setCurrentTab}
          />
        </Constraint>

        <Constraint height="full" size="extended">
          {renderAppointments()}
        </Constraint>
      </Panel>

      <AppointmentModal
        appointmentId={appointmentId}
        displayFor="patient"
        isCancelModalVisible={isCancelModalVisible}
        onCancelAppointment={handleCancelAppointment}
        onCloseConfirmationModal={handleCloseConfirmationModal}
        onCloseModal={handleNavigateToAppointments}
        onOpenConfirmationModal={handleClickCancelAppointment}
        onStartQuestionnaires={handleStartQuestionnaires}
        onEditQuestionnaires={() => handleEditQuestionnaires(appointmentId)}
        userId={userId}
      />

      <QuestionnaireModal
        appointmentId={questionnaireAppointmentId}
        onCloseModal={handleCloseQuestionnaires}
      />
    </>
  );
};

export default Appointments;
