import React from 'react';

import { Patient } from '@/types/v2/patient';
import { formatShortDate } from '@/utils/dateUtils';
import { modeledLongName } from '@/utils/patientUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';

interface Props {
  patient: Patient;
}

const PatientDetails = (props: Props) => {
  const { patient } = props;

  const renderPatientDetails = (() => {
    const fields = [
      `DOB: ${formatShortDate(patient.date_of_birth)}`,
      `${titleizeSnakeCase(patient.gender)}`,
    ];

    if (patient.address) {
      fields.push(`${patient.address.city}, ${patient.address.state}`);
    }

    return fields.join(' | ');
  })();

  return (
    <div className="patient-details">
      <div className="columns">
        <div className="column">
          <div className="patient-details__contact-status">
            <h2 className="patient-details__name">
              {modeledLongName(patient)}
            </h2>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-6">
          <div className="columns">
            <div className="column has-no-vertical-padding">
              <h4 className="patient-details__highlight">
                {renderPatientDetails}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
