import React, { useEffect, useMemo } from 'react';

import { addMessage, setMessages } from '@/actions/actions/messages';
import Api from '@/api/Api';
import Controls, { File } from '@/components/chat/Controls';
import Conversation, {
  ChatMessage,
  MessageAuthor,
} from '@/components/chat/Conversation';
import Constraint from '@/components/constraint/Constraint';
import CloseButton from '@/components/modal/CloseButton';
import Panel from '@/components/panel/Panel';
import { selectMessages } from '@/selectors/messages';
import { UUID } from '@/types/generic';
import { Message, MessageAuthorType, MessageType } from '@/types/v2/message';
import useSimpleForm from '@/utils/form/useSimpleForm';
import Modeler from '@/utils/modeler/modeler';
import { Routes } from '@/utils/routeUtils';
import { randomString, titleize } from '@/utils/stringUtils';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  appointmentId: UUID;
  authorId: UUID;
  authorType: string;
  messages: Message[];
  onClickClose: () => void;
}

const state = {
  text: '',
};

type FormState = typeof state;

const ChatInterface = ({
  appointmentId,
  authorId,
  authorType,
  messages: originalMessages,
  onClickClose,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const form = useSimpleForm<FormState>(state);

  const storedMessages = useSelector(selectMessages);

  const formatMessage = (message: Message): ChatMessage => {
    const author: MessageAuthor =
      message.author_id === authorId ? 'sender' : 'receiver';

    return {
      author,
      downloadLink: message.attachment?.url,
      fileName: message.attachment?.name,
      id: message.id,
      text: message.text,
      type: message.message_type,
    };
  };

  const messages = useMemo(() => {
    return storedMessages.map(formatMessage);
  }, [storedMessages]);

  const addPlaceholderMessage = (message: Partial<Message>): void => {
    const id = randomString();
    const idMessage = {
      id,
      ...message,
    };

    dispatch(addMessage(idMessage as Message));
  };

  const handleSendMessage = async (): Promise<void> => {
    if (!form.data.text) {
      return;
    }

    try {
      const url = Routes.api2.messages;

      const messageAttributes = {
        author_id: authorId,
        author_type: titleize(authorType) as MessageAuthorType,
        message_type: 'text' as MessageType,
        text: form.data.text,
        virtual_appointment_id: appointmentId,
      };

      addPlaceholderMessage(messageAttributes);
      form.update('text', '');

      const body = {
        message: messageAttributes,
      };

      await Api.utility.post(url, body);
    } catch (err) {
      form.error(err);
    }
  };

  const handleUploadFile = async (file: File): Promise<void> => {
    form.submit();

    try {
      const url = Routes.api2.messages;

      const body = {
        message: {
          author_id: authorId,
          author_type: titleize(authorType) as MessageAuthorType,
          message_type: 'file' as MessageType,
          virtual_appointment_id: appointmentId,
        },
        file,
      };

      const response = await Api.utility.post(url, body);
      const resource = new Modeler<Message>(response.data).build();

      dispatch(addMessage(resource));
    } catch (err) {
      form.error(err);
    }

    form.done();
  };

  useEffect(() => {
    dispatch(setMessages(originalMessages));
  }, [originalMessages]);

  return (
    <div className="chat-interface">
      <div className="chat-interface__window">
        <Panel>
          <Constraint height="full" size="large">
            <CloseButton onClick={onClickClose} />
            <h3 className="chat-header">Chat</h3>
            <Conversation messages={messages} />
          </Constraint>
        </Panel>
      </div>

      <div className="chat-interface__controls">
        <Panel>
          <Constraint size="extended">
            <Controls
              isDisabled={form.isSubmitting}
              onChangeText={value => form.update('text', value)}
              onClickSend={handleSendMessage}
              onPressEnter={handleSendMessage}
              onSelectFile={handleUploadFile}
              inputValue={form.data.text}
            />
          </Constraint>
        </Panel>
      </div>
    </div>
  );
};

export default ChatInterface;
