import React from 'react';

import FilterHeader from '@/components/filters/FilterHeader';
import Datepicker from '@/components/form/Datepicker';
import InputIcon from '@/components/form/InputIcon';
import SearchGroup, {
  formatModelFullName,
} from '@/components/form/SearchGroup';
import SelectGroup from '@/components/form/SelectGroup';
import { formatSpecialistProfile } from '@/types/v2/admin_user';
import { minuteOptions } from '@/utils/dropdownUtils';
import { includes, Routes } from '@/utils/routeUtils';

export interface FormState {
  admin_user_id?: string;
  duration_maximum: string;
  duration_minimum: string;
  end_at: string;
  patient_id: string;
  per: string;
  search: string;
  start_at: string;
}

interface Props {
  form: FormState;
  onChangeForm: (field: string, value: string) => void;
  onChangeSearch: (value: string) => void;
  onClearFilters: () => void;
  onStartSearch: () => void;
  practiceId?: string;
  searchQuery: string;
}

const VisitsSearchHeader = (props: Props): JSX.Element => {
  const {
    form,
    onChangeForm,
    onChangeSearch,
    onClearFilters,
    onStartSearch,
    practiceId,
    searchQuery,
  } = props;

  const params = {
    practice_id: practiceId,
  };

  const adminUserParams = {
    ...params,
    include: includes('specialist'),
  };

  return (
    <>
      <div className="columns is-mobile">
        <div className="column is-6">
          <h2 className="patients-index__header">Visits</h2>
        </div>

        <div className="column is-6">
          <InputIcon
            onChange={value => onChangeSearch(value)}
            onClick={onStartSearch}
            onPressEnter={onStartSearch}
            placeholder="Search"
            value={searchQuery}
          />
        </div>
      </div>

      <FilterHeader
        includePerPage
        onClearFilters={onClearFilters}
        onChangeQuery={onChangeForm}
        query={form}
        perPageDesktopClass="is-2"
        perPageTabletClass="is-3"
        shouldAddColumnClasses>
        <>
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSpecialistProfile}
            label="Provider Name"
            minimumInputLength={0}
            onChange={(value: string) => onChangeForm('admin_user_id', value)}
            placeholder="Search Providers"
            queryParams={adminUserParams}
            searchPath={Routes.api2.adminUsers}
            shouldUseModeler
          />

          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatModelFullName}
            label="Patient Name"
            minimumInputLength={0}
            onChange={(value: string) => onChangeForm('patient_id', value)}
            placeholder="Search Patients"
            queryParams={params}
            searchPath={Routes.api2.patients}
          />

          <Datepicker
            label="Date Range"
            onChange={(value: string) => onChangeForm('start_at', value)}
            placeholder="Select Date"
            value={form.start_at}
          />

          <Datepicker
            onChange={(value: string) => onChangeForm('end_at', value)}
            placeholder="Select Date"
            value={form.end_at}
          />

          <SelectGroup
            label="Visit Duration"
            onChange={value => onChangeForm('duration_minimum', value)}
            options={minuteOptions}
            value={form.duration_minimum}
            placeholder="Select Minimum"
          />

          <SelectGroup
            onChange={value => onChangeForm('duration_maximum', value)}
            options={minuteOptions}
            value={form.duration_maximum}
            placeholder="Select Maximum"
          />
        </>
      </FilterHeader>
    </>
  );
};

export default VisitsSearchHeader;
