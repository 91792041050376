import React from 'react';
import { Link } from 'react-router-dom';

import { Table } from '@/components/table';
import { Attributable } from '@/types/generic';
import { buildPath, Routes } from '@/utils/routeUtils';
import { formatSecondsToHours } from '@/utils/timeUtils';
import { displayUserStatus, fullName } from '@/utils/userUtils';

interface Props {
  perPage: any;
  queryOptions: Attributable;
}

const SEARCH_PATH = 'api/v1/dashboards/care_managers';

const AdminDashboardTable = (props: Props) => {
  const { perPage, queryOptions } = props;

  const tableConfig = {
    reloadSeconds: 10,
    searchPath: SEARCH_PATH,
    searchQuery: {
      ...queryOptions,
      per: perPage,
    },
    columns: [
      {
        header: 'Care Manager',
        isSortable: true,
        attribute: 'users.first_name',
        isVisibleMobile: true,
        formatEntry: (entry: any) => {
          const name = fullName(entry);
          const url = buildPath(Routes.dashboardCareManager, {
            care_manager_id: entry.id,
          });

          return <Link to={url}>{name}</Link>;
        },
      },
      {
        header: 'Status',
        isSortable: true,
        attribute: 'status',
        isLive: true,
        isVisibleMobile: true,
        formatAttribute: displayUserStatus,
      },
      {
        header: 'Patient',
        attribute: 'patient_first_name',
        isSortable: true,
        className: 'table__cell-highlight',
        isLive: true,
        formatEntry: (entry: any) => {
          if (!entry.patient_first_name || !entry.patient_last_name) {
            return '';
          }

          const name = `${entry.patient_first_name} ${entry.patient_last_name}`;
          const url = buildPath(Routes.patientsShow, {
            id: entry.patient_id,
          });

          return <Link to={url}>{name}</Link>;
        },
        flashValue: (entry: any) =>
          `${entry.patient_first_name} ${entry.patient_last_name}`,
      },
      {
        header: 'CCM Time',
        isSortable: true,
        className: 'table__cell-highlight',
        isLive: true,
        attribute: 'total_ccm_seconds',
        formatAttribute: (value: number) => formatSecondsToHours(value),
      },
      {
        header: 'CCM Goal',
        isSortable: true,
        isLive: true,
        attribute: 'ccm_goal_minutes',
        formatAttribute: (value: number) => `${value || 0} mins`,
      },
      {
        header: 'RPM Time',
        isSortable: true,
        className: 'table__cell-highlight',
        isLive: true,
        attribute: 'total_rpm_seconds',
        formatAttribute: (value: number) => formatSecondsToHours(value),
      },
      {
        header: 'RPM Goal',
        isSortable: true,
        isLive: true,
        attribute: 'rpm_goal_minutes',
        formatAttribute: (value: number) => `${value || 0} mins`,
      },
    ],
  };

  return <Table config={tableConfig} />;
};

export default AdminDashboardTable;
