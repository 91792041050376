import React from 'react';

import ErrorMessage from '@/components/form/ErrorMessage';
import Label from '@/components/form/Label';
import Typeahead, {
  Props as TypeaheadProps,
} from '@/components/typeahead/Typeahead';
import { SideEffect } from '@/types/generic';
import { fullName } from '@/utils/userUtils';

export interface Props extends TypeaheadProps {
  clearFalseResultOnBlur?: boolean;
  label: string;
  onChange?: SideEffect;
  validationMessage?: string;
}

export const formatSearchName = (result: any) => ({
  label: result.attributes.name,
  value: result.id,
});

export const formatSearchFullName = (result: any) => ({
  label: fullName(result),
  value: result.id,
});

export const formatModelName = ({ id, name }: any) => ({
  label: name,
  value: id,
});

export const formatModelFullName = (model: any) => ({
  label: fullName(model),
  value: model.id,
});

export const SearchGroup = (props: Props) => {
  const { label, validationMessage, ...typeaheadProps } = props;

  const isInvalid = !!validationMessage;

  return (
    <div className="form-input-group">
      <Label isInvalid={isInvalid}>{label}</Label>
      <Typeahead isInvalid={isInvalid} {...typeaheadProps} />
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </div>
  );
};

export default SearchGroup;
