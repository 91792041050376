import { useState } from 'react';
import { Dispatch } from 'redux';

import { setErrorCode } from '@/actions/reducers/errors';
import { UUID } from '@/types/generic';
import { buildRequestUrl } from '@/utils/apiUtils';

interface RequestConfig {
  dispatch: Dispatch;
  model: string;
  id?: UUID;
  dispatchToStore?: boolean;
  initializeAsLoading?: boolean;
  params?: object;
  key?: string;
  url?: string;
  onSuccess?: (response: any) => void;
  onFailure?: (err: any) => void;
}

function useGetRequest(config: RequestConfig): [() => void, boolean] {
  const {
    dispatch,
    url,
    params,
    dispatchToStore,
    model,
    id,
    key,
    initializeAsLoading,
    onSuccess,
    onFailure,
  } = config;
  const [isLoading, setIsLoading] = useState(
    initializeAsLoading ? true : false,
  );

  const makeRequest = () => {
    setIsLoading(true);

    dispatch({
      type: 'GET_DATA_SAGA',
      payload: {
        url: url || buildRequestUrl({ model, id }), // use url argument if given, otherwise attempt to construct it
        key: key || model, // default to the model name if caller does not provide `key` argument
        dispatchToStore,
        params: params || {},
      },
      onSuccess: (response: object) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      onFailure: (err: any) => {
        if (err.response?.status) {
          dispatch(setErrorCode(err.response.status));
        }

        if (onFailure) {
          onFailure(err);
        }
      },
      onComplete: () => setIsLoading(false),
    });
  };

  return [makeRequest, isLoading];
}

export default useGetRequest;
