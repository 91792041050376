import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '@/components//button/Button';
import Icon from '@/components/icon/Icon';
import { selectBrandLogo } from '@/selectors/brand';
import { getCurrentUser } from '@/selectors/users';
import { User } from '@/types/user';
import { Routes } from '@/utils/routeUtils';
import SmallHeaderLinks from './SmallHeaderLinks';
import SmallHeaderSubLinks from './SmallHeaderSubLinks';

interface Props {
  user?: User;
}

const SmallHeader = (props: Props) => {
  const LOGO = useSelector(selectBrandLogo);
  const user = props.user || useSelector(getCurrentUser);

  const [isVisible, setIsVisible] = useState(false);
  const [isSubnavVisible, setSubnavIsVisible] = useState(false);

  const handleButtonClick = () => {
    setIsVisible(!isVisible);
  };

  const renderIcon = () => {
    if (isVisible) {
      return (
        <Button onClick={handleButtonClick} style="flat">
          {<Icon icon="close" />}
        </Button>
      );
    } else {
      return (
        <Button onClick={handleButtonClick} style="flat">
          {<Icon icon="menu" />}
        </Button>
      );
    }
  };

  const renderMobileMenu = () => {
    if (user) {
      if (isSubnavVisible) {
        return (
          <SmallHeaderSubLinks
            onPress={() => setSubnavIsVisible(false)}
            onPressLink={handleButtonClick}
          />
        );
      } else {
        return (
          <SmallHeaderLinks
            onPress={() => setSubnavIsVisible(true)}
            onPressLink={handleButtonClick}
          />
        );
      }
    }
  };

  return (
    <header
      className={classNames('header', {
        'is-visible-mobile': isVisible,
      })}>
      <div className="container">
        <div className="header__mobile-nav">
          {isVisible && renderMobileMenu()}

          {renderIcon()}

          <div className="header__mobile-brand">
            <Link className="header__nav-item" to={Routes.root}>
              <img className="header__brand-image" src={LOGO} />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SmallHeader;
