import React from 'react';

import Panel from '@/components/panel/Panel';
import { Sidebar, SidebarLink } from '@/components/sidebar';
import {
  getSelectedPatient,
  getSelectedPatientEnrollmentStatus,
} from '@/selectors/api';
import { Patient } from '@/types/v2/patient';
import { buildPath, Routes } from '@/utils/routeUtils';
import { breakpoints } from '@/utils/screenUtils';
import { useResponsive } from 'react-hooks-responsive';
import { useSelector } from 'react-redux';

interface Props {
  patient: Patient;
}

const PatientSidebar = (props: Props) => {
  const { patient } = props;

  const { screenIsAtMost } = useResponsive(breakpoints);
  const isTouch = screenIsAtMost('tablet');

  const status = useSelector(getSelectedPatientEnrollmentStatus);
  const isDisabled = status !== 'complete';

  const sidebarLinks = () => {
    return (
      <Sidebar>
        <SidebarLink
          // TODO: NEED TO UPDATE ROUTES
          to={buildPath(Routes.provider.patients.show.virtualVisits.root, {
            patient_id: patient.id,
          })}
          isDisabled={isDisabled}>
          Virtual Visits
        </SidebarLink>

        <SidebarLink
          to={buildPath(Routes.provider.patients.show.medicalRecords, {
            patient_id: patient.id,
          })}
          isDisabled={isDisabled}>
          Medical Records
        </SidebarLink>

        <SidebarLink
          to={buildPath(Routes.provider.patients.show.actionHistory, {
            patient_id: patient.id,
          })}
          isDisabled={isDisabled}>
          Action History
        </SidebarLink>

        <SidebarLink
          to={buildPath(Routes.provider.patients.show.agreement, {
            patient_id: patient.id,
          })}
          isDisabled={isDisabled}>
          Agreements
        </SidebarLink>
      </Sidebar>
    );
  };

  if (isTouch) {
    return <div className="sidebar__touch">{sidebarLinks()}</div>;
  }

  return <Panel withNarrowPadding>{sidebarLinks()}</Panel>;
};

export default PatientSidebar;
