import { Serialized } from '@/types/generic';

export const flattenIncludes = (
  entry: Serialized<any>,
  includes: string[] = [],
  addEntryName?: boolean,
) => {
  const included = entry.included;

  if (!included.length) {
    return entry;
  }

  let formattedAttributes = {
    ...entry.attributes,
  };

  for (const includeName of includes) {
    const associationEntry = entry.included.find(i => i.type === includeName);

    if (!associationEntry) {
      continue;
    }

    const associationEntryName = Object.keys(associationEntry.attributes).map(
      key => {
        const newKey = `${includeName}_${key}`;
        return { [newKey]: associationEntry.attributes[key] };
      },
    );

    const formattedAssociationAttr = associationEntryName.reduce((a, b) => ({
      ...a,
      ...b,
    }));

    formattedAssociationAttr[`${includeName}_id`] = associationEntry.id;

    if (associationEntry && addEntryName) {
      formattedAttributes = {
        ...formattedAttributes,
        ...formattedAssociationAttr,
      };
    } else if (associationEntry) {
      formattedAttributes = {
        ...formattedAttributes,
        ...associationEntry.attributes,
      };
    }
  }

  return {
    ...entry,
    attributes: formattedAttributes,
  };
};

export const getInclude = (entity: any, type: string) => {
  if (!entity || !entity?.relationships) {
    return {};
  }

  const relation = entity?.relationships[type];
  const id = relation?.data?.id;

  if (!id || !entity.included) {
    return {};
  }

  return entity.included.find(include => include.id === id);
};
