import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import Api from '@/api/Api';
import Button from '@/components/button/Button';
import CheckboxGroup from '@/components/form/CheckboxGroup';
import Datepicker from '@/components/form/Datepicker';
import ErrorMessage from '@/components/form/ErrorMessage';
import FileUpload from '@/components/form/FileUpload';
import InputGroup from '@/components/form/InputGroup';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import TextAreaGroup from '@/components/form/TextAreaGroup';
import ConfirmDeleteModal from '@/contexts/modals/ConfirmDeleteModal';
import UnsavedConfirmationModal from '@/contexts/modals/UnsavedConfirmationModal';
import { Field, FormComponentConfig } from '@/types/form';
import { SideEffect } from '@/types/generic';
import { Form as FormType, FormConfig, useForm } from '@/utils/form';
import Container from '../container/Container';
import ImagePreviewUpload from './ImagePreviewUpload';
import SearchGroup from './SearchGroup';
import TabGroup from './TabGroup';
import Timepicker from './Timepicker';
import TypeaheadSelectGroup from './TypeaheadSelectGroup';

interface Props {
  config: FormComponentConfig;
  onFormReset?: SideEffect;
  resetForm?: boolean;
  shouldSubmitOnEnter?: boolean;
}

export const Form = (props: Props) => {
  const { config, onFormReset, resetForm, shouldSubmitOnEnter } = props;
  const { actions, layout, rules, state } = config;

  const {
    onDelete,
    deleteText,
    submitText,
    warnBeforeNavigation,
    ...formActions
  } = actions;

  const history = useHistory();

  const [
    isConfirmDeleteModalVisible,
    setIsConfirmDeleteModalVisible,
  ] = useState<boolean>(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState<boolean>(
    false,
  );
  const [navigationPath, setNavigationPath] = useState<string>('');

  const formConfig: FormConfig = {
    alterFormBeforeSubmit: (completeForm: any) => {
      const initialAlter = config.actions.alterFormBeforeSubmit
        ? config.actions.alterFormBeforeSubmit(completeForm)
        : completeForm;

      const mergeFields = actions.mergeFields || {};
      return {
        ...initialAlter,
        ...mergeFields,
      };
    },
    canSubmitUnchanged: false,
    enableSubmitWithRequired: true,
    rules,
    shouldDisableOnSubmit: true,
    ...formActions,
  };

  const form: FormType = useForm(state, formConfig);

  const canDeleteEntry = !!(
    config.actions.method === 'PUT' && config.actions.allowDelete
  );

  const handleClickDelete = () => {
    setIsConfirmDeleteModalVisible(true);
  };

  const handleCloseDelete = () => {
    setIsConfirmDeleteModalVisible(false);
  };

  const handleDelete = () => {
    handleCloseDelete();

    if (onDelete) {
      onDelete();
    } else {
      Api.utility.delete(config.actions.path).then(() => {
        if (config.actions.onSuccess) {
          config.actions.onSuccess({});
        }
      });
    }
  };

  useEffect(() => {
    if (resetForm) {
      form.reset();
      onFormReset();
    }
  }, [resetForm]);

  const columnClass = (field: Field) => {
    const { size, offset } = field;

    const classes = ['column'];

    if (size) {
      classes.push(`is-${size}-desktop`);
    }

    if (offset) {
      classes.push(`is-offset-${size}-desktop`);
    }

    return classes.join(' ');
  };

  const handleCloseModal = () => {
    setIsConfirmationVisible(false);
  };

  const handleNavigate = () => {
    history.push(navigationPath);
  };

  const handleSaveConfirm = () => {
    form.onSubmit();
  };

  const handlePromptWarning = location => {
    if (!form.areFieldsUpdated || isConfirmationVisible || form.isSuccessful) {
      return true;
    }

    setIsConfirmationVisible(true);
    setNavigationPath(location.pathname);

    return false;
  };

  const renderActions = () => {
    return (
      <div className="form__actions">
        <div className="form__actions-left">
          {canDeleteEntry && (
            <Button color="error" onClick={handleClickDelete} style="text">
              {actions.deleteText || 'Delete Entry'}
            </Button>
          )}
        </div>

        <div className="form__actions-right">
          {config.actions.onCancel && (
            <Button color="white" onClick={config.actions.onCancel}>
              Cancel
            </Button>
          )}

          {config.actions.onDeactivate && (
            <Button color="white" onClick={config.actions.onDeactivate}>
              Deactivate Patient
            </Button>
          )}

          <Button color="secondary" {...form.submit}>
            {actions.submitText || 'Submit'}
          </Button>
        </div>
      </div>
    );
  };

  const getFieldOptions = field => {
    if (typeof field.options === 'function') {
      return field.options(form.formState);
    }

    return field.options;
  };

  const renderField = (field: Field) => {
    const formFieldProperties = form[field.field];

    const isDisabled = field.disableIf
      ? field.disableIf(form.formState)
      : false;

    switch (field.type) {
      case 'custom':
        return field.renderCustom(form.formState);

      case 'image':
        return (
          <ImagePreviewUpload
            label={field.label}
            initialUrl={field.initialUrl}
            {...formFieldProperties}
          />
        );

      case 'tabs':
        return (
          <TabGroup
            label={field.label}
            options={getFieldOptions(field)}
            {...formFieldProperties}
          />
        );

      case 'typeahead':
        const initialValue = field.typeaheadInitialDisplay
          ? field.typeaheadInitialDisplay(formFieldProperties.value)
          : '';

        return (
          <SearchGroup
            clearFalseResultOnBlur={field.clearFalseResultOnBlur}
            formatResult={field.formatTypeaheadResult}
            initialSelection={field.initialSelection}
            initialValue={initialValue}
            label={field.label}
            placeholder={field.placeholder}
            searchPath={field.typeaheadSearchPath}
            shouldUseModeler={field.shouldUseModeler}
            modelerGenerations={field.modelerGenerations}
            queryParams={
              typeof field.queryParams === 'function'
                ? field.queryParams(form.formState)
                : field.queryParams
            }
            {...formFieldProperties}
          />
        );

      case 'typeahead-select':
        return (
          <TypeaheadSelectGroup
            formatResult={field.formatTypeaheadResult}
            label={field.label}
            placeholder={field.placeholder}
            searchPath={field.typeaheadSearchPath}
            {...formFieldProperties}
          />
        );

      case 'upload':
        return (
          <FileUpload
            label={field.label}
            fileType={field.fileType}
            {...formFieldProperties}
          />
        );

      case 'select':
        return (
          <SelectGroup
            isDisabled={isDisabled}
            label={field.label}
            options={getFieldOptions(field)}
            placeholder={field.placeholder}
            {...formFieldProperties}
          />
        );

      case 'checkbox':
        return (
          <CheckboxGroup
            checkedValue={field.checkedValue}
            label={field.label}
            uncheckedValue={field.uncheckedValue}
            {...formFieldProperties}
          />
        );

      case 'date':
        return (
          <Datepicker
            isDisabled={isDisabled}
            label={field.label}
            placeholder={field.placeholder}
            {...formFieldProperties}
          />
        );

      case 'datetime':
        return (
          <Datepicker
            enableTime
            isDisabled={isDisabled}
            label={field.label}
            placeholder={field.placeholder}
            {...formFieldProperties}
          />
        );

      case 'time':
        return (
          <Timepicker
            isDisabled={isDisabled}
            label={field.label}
            placeholder={field.placeholder}
            {...formFieldProperties}
          />
        );

      case 'textarea':
        return <TextAreaGroup label={field.label} {...formFieldProperties} />;

      default:
        return (
          <InputGroup
            isDisabled={isDisabled}
            label={field.label}
            onPressEnter={shouldSubmitOnEnter ? form.onSubmit : null}
            placeholder={field.placeholder}
            type={field.type || 'text'}
            {...formFieldProperties}
          />
        );
    }
  };

  const renderForm = () => {
    return layout.map((section, index) => {
      return (
        <Section
          key={index}
          title={section.title}
          shouldRenderWithPanel={section.renderPanel}>
          {renderSectionFields(section.fields)}
        </Section>
      );
    });
  };

  const renderRow = row => {
    return row.map((field, index) => {
      if (field.ignoreIf && field.ignoreIf(form.formState)) {
        return null;
      }

      return (
        <div className={columnClass(field)} key={index}>
          {renderField(field)}
        </div>
      );
    });
  };

  const renderSectionFields = fields => {
    return fields.map((row, index) => {
      return (
        <div className="columns" key={index}>
          {renderRow(row)}
        </div>
      );
    });
  };

  return (
    <>
      {renderForm()}

      <Container>
        <ErrorMessage isRightSide message={form.message} />
        {renderActions()}
      </Container>

      {actions.warnBeforeNavigation && <Prompt message={handlePromptWarning} />}

      <UnsavedConfirmationModal
        isVisible={isConfirmationVisible}
        onClose={handleCloseModal}
        onNavigate={handleNavigate}
        onSave={handleSaveConfirm}
      />

      <ConfirmDeleteModal
        isVisible={isConfirmDeleteModalVisible}
        onClose={handleCloseDelete}
        onAccept={handleDelete}
      />
    </>
  );
};

export default Form;
