import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@/components/button/Button';
import InputIcon from '@/components/form/InputIcon';
import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';
import { Tab, Tabs } from '@/components/tabs';
import { Attributable } from '@/types/generic';
import { TableConfig } from '@/types/table';
import { buildPath, Routes } from '@/utils/routeUtils';
import { getResourceName } from './helpers/links';
import { buildTableConfig } from './utils/tableUtils';

interface Params {
  resource: string;
}

const INITIAL_QUERY = {
  search: '',
};

const PortalContent = () => {
  const history = useHistory();

  const { resource } = useParams<Params>();

  const [searchInput, setSearchInput] = useState<string>('');
  const [queryOptions, setQueryOptions] = useState<Attributable>(INITIAL_QUERY);

  const tableConfig: TableConfig = buildTableConfig(resource, queryOptions);

  const handleSearchChange = (value: any) => {
    setSearchInput(value);
  };

  const handleChangeQuery = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleAddResource = () => {
    const path = buildPath(Routes.adminResourceNew, { resource });
    history.push(path);
  };

  return (
    <Panel>
      <Tabs currentTab={resource}>
        <Tab value={resource}>
          {resource === 'condition_groups'
            ? 'Condition Options'
            : getResourceName(resource)}
        </Tab>
      </Tabs>

      <div className="columns">
        <div className="column is-6">
          <InputIcon
            onChange={handleSearchChange}
            onClick={() => handleChangeQuery('search', searchInput)}
            onPressEnter={() => handleChangeQuery('search', searchInput)}
            placeholder="Search"
            value={searchInput}
          />
        </div>

        <div className="column is-6">
          <div className="portal-content__add">
            <Button color="secondary" onClick={handleAddResource}>
              Add {getResourceName(resource)}
            </Button>
          </div>
        </div>
      </div>

      <Table config={tableConfig} key={resource} />
    </Panel>
  );
};

export default PortalContent;
