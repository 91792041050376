import React from 'react';

import AccessControl from '@/components/access_control/AccessControl';
import Button from '@/components/button/Button';
import InputIcon from '@/components/form/InputIcon';
import SearchGroup from '@/components/form/SearchGroup';
import Modal from '@/components/modal/Modal';
import { PolicyGroups } from '@/components/router';
import { SideEffect } from '@/types/generic';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { fullName } from '@/utils/userUtils';
import CallsScheduleFilterModal from './CallsScheduleFilterModal';

interface Props {
  closeModal: SideEffect;
  mobileQueryOptions: { [key: string]: any };
  modalVisible: boolean;
  onClearFilters: VoidFunction;
  onFilterChange: SideEffect;
  onFilterClick: SideEffect;
  onModalApplyClick: SideEffect;
  onModalFilterChange: SideEffect;
  onScheduleClick: SideEffect;
  onSearchChange: SideEffect;
  onSearchClick: SideEffect;
  queryOptions: { [key: string]: any };
  searchValue: string;
}

const CallsScheduleHeader = (props: Props) => {
  const {
    closeModal,
    mobileQueryOptions,
    modalVisible,
    onClearFilters,
    onFilterChange,
    onFilterClick,
    onModalApplyClick,
    onModalFilterChange,
    onScheduleClick,
    onSearchChange,
    onSearchClick,
    queryOptions,
    searchValue,
  } = props;

  const formatSearchResult = (result: any) => ({
    label: result.attributes.name,
    value: result.id,
  });

  const formatCareManagerResult = (result: any) => {
    return {
      label: fullName(result),
      value: result.id,
    };
  };

  const renderSearchDisplay = () => {
    const activeFilters = [];
    const skipFilters = ['month', 'year', 'search'];

    for (const key of Object.keys(queryOptions)) {
      if (queryOptions[key]) {
        if (key === 'search') {
          activeFilters.push(`'${queryOptions[key]}'`);
        } else if (!skipFilters.includes(key)) {
          activeFilters.push(titleizeSnakeCase(key));
        }
      }
    }

    if (!activeFilters.length) {
      return null;
    }

    const filterList = activeFilters.join(', ');

    return (
      <div className="columns">
        <div className="column">
          <div className="patient-filter__clear">
            <span>Search Results for: {filterList}</span>
            <a onClick={onClearFilters}>Clear search</a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="columns is-mobile">
        <div className="column is-3-tablet is-hidden-mobile is-hidden-desktop call-schedule__button-wrapper">
          <Button color="white" onClick={onFilterClick}>
            Filter
          </Button>
        </div>

        <div className="column is-hidden-mobile call-schedule__button-wrapper">
          <Button color="secondary" onClick={onScheduleClick}>
            Schedule Call
          </Button>
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column is-hidden-mobile is-6-tablet is-7-desktop call-schedule__search">
          {renderSearchDisplay()}
        </div>

        <div className="column is-12-mobile is-6-tablet is-5-desktop">
          <InputIcon
            value={searchValue}
            onChange={onSearchChange}
            placeholder="Search"
            onClick={() => onSearchClick('search', searchValue)}
            onPressEnter={() => onSearchClick('search', searchValue)}
          />
        </div>
      </div>

      <div className="columns is-mobile is-hidden-tablet">
        <div className="column is-6-mobile is-3-tablet">
          <Button color="white" onClick={onFilterClick}>
            Filter
          </Button>
        </div>

        <div className="column is-6-mobile is-3-tablet">
          <Button color="secondary" onClick={onScheduleClick}>
            Schedule Call
          </Button>
        </div>
      </div>

      <div className="columns is-mobile is-hidden-tablet">
        <div className="column">{renderSearchDisplay()}</div>
      </div>

      <div className="columns is-mobile is-hidden-touch">
        <div className="column is-3">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchResult}
            guideValue={queryOptions.health_system_id}
            label="Health System"
            minimumInputLength={1}
            onChange={value => onFilterChange('health_system_id', value)}
            placeholder="Search Health Systems"
            searchPath="api/v1/health_systems"
          />
        </div>

        <div className="column is-3">
          <SearchGroup
            clearFalseResultOnBlur
            isDisabled={!queryOptions.health_system_id}
            guideValue={queryOptions.hospital_id}
            formatResult={formatSearchResult}
            label="Hospital"
            minimumInputLength={1}
            onChange={value => onFilterChange('hospital_id', value)}
            placeholder="Search Hospitals"
            queryParams={{ health_system_id: queryOptions.health_system_id }}
            searchPath="api/v1/hospitals"
          />
        </div>

        <div className="column is-3">
          <SearchGroup
            clearFalseResultOnBlur
            isDisabled={!queryOptions.hospital_id}
            formatResult={formatSearchResult}
            guideValue={queryOptions.practice_id}
            label="Practice"
            minimumInputLength={1}
            onChange={value => onFilterChange('practice_id', value)}
            placeholder="Search Practices"
            queryParams={{ hospital_id: queryOptions.hospital_id }}
            searchPath="api/v1/practices"
          />
        </div>

        <AccessControl allowedRoles={PolicyGroups.atLeastAdmin}>
          <div className="column is-3">
            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatCareManagerResult}
              guideValue={queryOptions.user_id}
              label="Care Manager"
              minimumInputLength={1}
              onChange={value => onFilterChange('user_id', value)}
              placeholder="Search Care Managers"
              searchPath="api/v1/care_managers"
            />
          </div>
        </AccessControl>
      </div>

      <Modal isVisible={modalVisible} onCloseModal={closeModal}>
        <CallsScheduleFilterModal
          mobileQueryOptions={mobileQueryOptions}
          onChange={onModalFilterChange}
          onClick={onModalApplyClick}
        />
      </Modal>
    </>
  );
};

export default CallsScheduleHeader;
