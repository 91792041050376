import { AlertTypeName } from '@/types/alert_type';
import { DeviceTypeName } from '@/types/device_type';
import { Nullable } from '@/types/generic';
import {
  convertIntToType,
  ReadingThreshold,
} from '@/types/v2/reading_threshold';
import { Level } from './AlertLevel';

type ReadingType = '1' | '2' | '7' | '8';

export const byValue = (a: ReadingThreshold, b: ReadingThreshold): number => {
  if (a.min_value === b.min_value) {
    if (a.max_value === b.max_value) {
      return 0;
    }

    return a.max_value > b.max_value ? 1 : -1;
  }

  return a.min_value > b.min_value ? 1 : -1;
};

export const getReadingLevel = (
  readingType: ReadingType,
  value: number | string,
  thresholds: ReadingThreshold[] = [],
): Nullable<Level> => {
  const readingTypeString = convertIntToType(readingType);
  const readingThresholds = thresholds
    .filter(threshold => threshold.reading_type === readingTypeString)
    .sort(byValue);

  const alertLevels: Level[] = ['high', 'medium', 'low'];

  if (typeof value === 'string') {
    value = parseInt(value, 10);
  }

  if (readingThresholds.length) {
    const selectedThreshold = readingThresholds.find(threshold => {
      if (threshold.max_value) {
        return value >= threshold.min_value && value < threshold.max_value;
      } else {
        return value >= threshold.min_value;
      }
    });

    return selectedThreshold ? selectedThreshold.level : null;
  }

  const alertThresholds = {
    bloodGlucose: [200, 120, 65],
    bloodPressure: [180, 130, 85],
  };

  const findReadingLevel = (levels: number[]): Nullable<Level> => {
    for (const [i, v] of levels.entries()) {
      if (value > v) {
        return alertLevels[i];
      }
    }
  };

  switch (readingType) {
    case '1':
      return findReadingLevel(alertThresholds.bloodGlucose);

    case '2':
      return findReadingLevel(alertThresholds.bloodPressure);

    default:
      return null;
  }
};

export const getAlertLevel = (
  alertType: AlertTypeName,
  deviceType: DeviceTypeName,
  value: number,
  thresholds: ReadingThreshold[],
): Level => {
  if (deviceType === 'Glucometer' && alertType === 'mg/dL') {
    return getReadingLevel('1', value, thresholds);
  }

  if (
    deviceType === 'Blood Pressure Monitor' &&
    alertType === 'Systolic (mmHg)'
  ) {
    return getReadingLevel('2', value, thresholds);
  }
};
