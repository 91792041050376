import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { SideEffect } from '@/types/generic';

interface Props {
  children: ReactNode;
  currentTab?: string;
  isDisabled?: boolean;
  onSelectTab?: SideEffect;
  value: string;
}

export const Tab = (props: Props) => {
  const { children, currentTab, isDisabled, onSelectTab, value } = props;

  const handleSelectTab = () => {
    if (onSelectTab) {
      onSelectTab(value);
    }
  };

  return (
    <button
      className={classNames('tab', {
        'is-active': currentTab === value,
      })}
      disabled={isDisabled}
      onClick={handleSelectTab}>
      {children}
    </button>
  );
};

export default Tab;
