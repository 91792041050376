import classNames from 'classnames';
import React, { useMemo } from 'react';

import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import { formatShortDate } from '@/utils/dateUtils';
import { titleize } from '@/utils/stringUtils';
import { formatTime } from '@/utils/timeUtils';

interface Props {
  appointment: VirtualAppointment;
  name: string;
  shouldShowStatus?: boolean;
}

const AppointmentDetails = ({
  appointment,
  name,
  shouldShowStatus,
}: Props): JSX.Element => {
  const formattedDate = useMemo(() => {
    const date = formatShortDate(appointment.scheduled_start_at);
    const startTime = formatTime(appointment.scheduled_start_at);
    const endTime = formatTime(appointment.scheduled_end_at);

    return `${date} | ${startTime}-${endTime}`;
  }, [appointment]);

  const renderStatus = (() => {
    if (shouldShowStatus) {
      return (
        <div className="column is-6">
          <h3 className="modal__subheader has-text-right-tablet">
            Status: {titleize(appointment.appointment_status)}
          </h3>
        </div>
      );
    }
  })();

  return (
    <>
      <div className="columns">
        <div className="column">
          <h3 className="modal__header">Appointment with {name}</h3>
        </div>
      </div>

      <div className="columns">
        <div
          className={classNames('column', {
            'is-6': shouldShowStatus,
          })}>
          <h3 className="modal__subheader">{formattedDate}</h3>
        </div>

        {renderStatus}
      </div>
    </>
  );
};

export default AppointmentDetails;
