import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setCurrentCall,
  setSelectedCallPatient,
} from '@/actions/reducers/call_manager';
import {
  flashError,
  flashSuccess,
  flashWarning,
} from '@/actions/sagas/messageSaga';
import { refreshPatientAction } from '@/actions/sagas/patientSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import Datepicker from '@/components/form/Datepicker';
import InputGroup from '@/components/form/InputGroup';
import SelectGroup from '@/components/form/SelectGroup';
import Modal from '@/components/modal/Modal';
import {
  getSelectedPatient,
  selectSelectedPatientCalls,
} from '@/selectors/api';
import {
  selectCallSid,
  selectCurrentCallId,
  selectSelectedCallPatient,
  selectSelectedPhoneNumber,
  selectStartAt,
  selectTimerAmount,
  selectTimerType,
} from '@/selectors/call_manager';
import { getManualEntryReasonOptions } from '@/selectors/options';
import { getCurrentUser } from '@/selectors/users';
import { Call } from '@/types/call';
import { EntryType } from '@/types/call_manager';
import { Rules, Validations } from '@/types/form';
import { SideEffect } from '@/types/generic';
import { formatShortDateTime } from '@/utils/dateUtils';
import { callStatusOptions } from '@/utils/dropdownUtils';
import { validate } from '@/utils/form';
import { combineDateAndTime } from '@/utils/timeUtils';
import { fullName } from '@/utils/userUtils';
import ActivityIndicator from '../activity/ActivityIndicator';
import SearchGroup, { formatSearchFullName } from '../form/SearchGroup';
import Timepicker from '../form/Timepicker';
import { FormattedResult } from '../typeahead/Typeahead';

interface Props {
  isVisible: boolean;
  onCloseModal: SideEffect;
}

const CallCompleteModal = (props: Props) => {
  const { isVisible } = props;

  const dispatch = useDispatch();

  const callSid = useSelector(selectCallSid);
  const currentCallId = useSelector(selectCurrentCallId);
  const currentPatient = useSelector(getSelectedPatient);
  const currentUser = useSelector(getCurrentUser);
  const duration = useSelector(selectTimerAmount);
  const patient = useSelector(selectSelectedCallPatient);
  const patientCalls = useSelector(selectSelectedPatientCalls);
  const phoneNumber = useSelector(selectSelectedPhoneNumber);
  const startAt = useSelector(selectStartAt);
  const timerType = useSelector(selectTimerType);
  const manualEntryReasonOptions = useSelector(getManualEntryReasonOptions);

  const [callNotes, setCallNotes] = useState<string>('');
  const [callStatus, setCallStatus] = useState<string>('call_successful');
  const [careManagerId, setCareManagerId] = useState<string>('');
  const [entryType, setEntryType] = useState<EntryType>(timerType);
  const [isLoadingPatient, setIsLoadingPatient] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [manualEntryReason, setManualEntryReason] = useState<string>('');
  const [nextAppointmentDate, setNextAppointmentDate] = useState<string>('');
  const [nextAppointmentStartTime, setNextAppointmentStartTime] = useState<
    string
  >('');
  const [selectedScheduledCallId, setSelectedScheduledCallId] = useState<
    string
  >('');
  const [validations, setValidations] = useState<Validations>({});
  const [callServiceId, setCallServiceId] = useState<string>('');

  const isAdmin = entryType === 'admin';
  const isCall = entryType === 'call';

  useEffect(() => {
    setSelectedScheduledCallId('');
  }, [isVisible]);

  useEffect(() => {
    setEntryType(timerType);
  }, [timerType]);

  useEffect(() => {
    if (patient) {
      setCallServiceId(patient.attributes.patient_services.data[0].attributes.service_id);
    }
  }, [patient])

  const initialSearchSelection = (() => {
    if (!patient) {
      return {
        label: '',
        value: '',
      };
    }

    return {
      label: fullName(patient),
      value: patient.id,
    };
  })();

  const careManager = currentUser.data;

  const patientCallOptions = useMemo(() => {
    if (!patientCalls) {
      return [];
    }

    return patientCalls.map((call: Call) => {
      return {
        label: formatShortDateTime(call.attributes.start_at),
        value: call.id,
      };
    });
  }, [patientCalls]);

  const serviceOptions = () => {
    if (!patient) {
      return [];
    }

    return patient.attributes.patient_services.data.map(service => ({
      label: service.attributes.name,
      value: service.attributes.service_id,
    }));
  };

  const resetForm = () => {
    setCallNotes('');
    setCallStatus('call_successful');
    setNextAppointmentDate('');
  };

  const handleSelectPatient = async (selection: FormattedResult) => {
    if (!selection || !selection.value) {
      return null;
    }

    setIsLoadingPatient(true);

    try {
      const patientId = selection.value;
      const url = `api/v1/patients/${patientId}`;

      const response = await Api.utility.get(url);
      const patientData = response.data.data;

      dispatch(setSelectedCallPatient(patientData));
    } catch (err) {
      dispatch(flashWarning('Error retrieving patient data'));
    }

    setIsLoadingPatient(false);
  };

  const handleSuccess = () => {
    if (currentPatient && patient && patient.id === currentPatient.id) {
      dispatch(refreshPatientAction(patient.id));
    }

    const message = isCall ? 'Call Saved' : 'Entry Saved';

    dispatch(setCurrentCall(null));
    dispatch(setSelectedCallPatient(null));
    dispatch(flashSuccess(message));

    resetForm();

    props.onCloseModal();
  };

  const createBody = (): any => {
    if (!props.isVisible || !patient) {
      return {};
    }

    if (isAdmin) {
      return {
        admin_entry: {
          duration,
          patient_id: patient.id,
          manual_entry_reason: manualEntryReason,
          notes: callNotes,
          service_id: callServiceId,
          start_at: startAt,
        },
      };
    } else {
      const params: any = {
        call: {
          call_type: 'standard',
          duration,
          notes: callNotes,
          patient_id: patient.id,
          patient_phone_number: phoneNumber,
          service_id: callServiceId,
          start_at: startAt,
          status: callStatus,
        },
        next_call: {
          patient_id: patient.id,
          user_id: careManagerId,
          start_at: combineDateAndTime(
            nextAppointmentDate,
            nextAppointmentStartTime,
          ),
          service_id: callServiceId,
        },
        call_sid: callSid,
      };

      if (selectedScheduledCallId) {
        params.scheduled_call_id = selectedScheduledCallId;
      }

      return params;
    }
  };

  const callRules: Rules = {
    notes: { required: true },
    service_id: { required: true },
  };

  const adminRules: Rules = {
    manual_entry_reason: { required: true },
  };

  const model = isAdmin ? 'admin_entries' : 'calls';

  const formBody = createBody();

  const handleSubmitForm = async () => {
    setIsSubmitting(true);

    const validateModel = isCall ? formBody.call : formBody.admin_entry;
    const validateRules = isCall ? callRules : adminRules;

    const [isValid, validationMessages] = validate(
      validateModel,
      validateRules,
    );

    if (isValid) {
      setValidations({});
    } else {
      setIsSubmitting(false);
      return setValidations(validationMessages);
    }

    try {
      if (isCall && currentCallId) {
        const url = `api/v1/calls/${currentCallId}`;
        await Api.utility.patch(url, formBody);
      } else {
        const url = `api/v1/${model}`;
        await Api.utility.post(url, formBody);
      }

      setIsSubmitting(false);
      handleSuccess();
    } catch (err) {
      setIsSubmitting(false);
      dispatch(flashError('Error submitting form'));
    }
  };

  const formatCareManagerResult = (result: any) => {
    return {
      label: fullName(result),
      value: result.id,
    };
  };

  const entryTypeOptions = [
    { label: 'Call Time', value: 'call' },
    { label: 'Admin Time', value: 'admin' },
  ];

  const renderFormFields = () => {
    if (!patient) {
      return (
        <div className="caller__modal-select">
          {isLoadingPatient && <ActivityIndicator />}

          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchFullName}
            guideValue={patient?.id}
            label="Patient"
            minimumInputLength={1}
            onChange={(_value, selection) => handleSelectPatient(selection)}
            placeholder="Select Patient"
            queryParams={{ care_manager_id: currentUser.data.id }}
            searchPath="api/v1/patients"
          />
        </div>
      );
    }

    if (isAdmin) {
      return (
        <>
          <div className="caller__modal-row">
            <InputGroup
              label="Notes (optional)"
              onChange={value => setCallNotes(value)}
              placeholder="Enter notes"
              value={callNotes}
            />
          </div>

          <div className="caller__modal-select">
            <SelectGroup
              label="Manual Entry Reason"
              onChange={value => setManualEntryReason(value)}
              options={manualEntryReasonOptions}
              validationMessage={validations.manual_entry_reason}
              value={manualEntryReason}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="caller__modal-row">
            <SelectGroup
              label="Scheduled Call"
              onChange={value => setSelectedScheduledCallId(value)}
              options={patientCallOptions}
              value={selectedScheduledCallId}
            />
          </div>

          <div className="caller__modal-row">
            <SelectGroup
              label="Call Status"
              onChange={value => setCallStatus(value)}
              options={callStatusOptions}
              value={callStatus}
            />
          </div>

          <div className="caller__modal-select">
            <InputGroup
              label="Call Notes"
              onChange={value => setCallNotes(value)}
              placeholder="Enter call notes"
              validationMessage={validations.notes}
              value={callNotes}
            />
          </div>

          <div className="caller__modal-header">
            <span>Schedule Next Call</span>
          </div>

          <div className="caller__modal-row">
            <Datepicker
              label="Upcomming Appointment"
              onChange={value => setNextAppointmentDate(value)}
              value={nextAppointmentDate}
            />
          </div>

          <div className="caller__modal-row">
            <Timepicker
              label="Start Time"
              onChange={value => setNextAppointmentStartTime(value)}
              placeholder="Select start time"
              value={nextAppointmentStartTime}
            />
          </div>

          <div className="caller__modal-select">
            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatCareManagerResult}
              guideValue={careManagerId}
              label="Care Manager"
              minimumInputLength={1}
              onChange={value => setCareManagerId(value)}
              placeholder="Search Care Managers"
              searchPath="api/v1/care_managers"
              initialSelection={{
                label: fullName(careManager),
                value: careManager.id,
              }}
            />
          </div>
        </>
      );
    }
  };
  
  return (
    <Modal hideClose shouldCloseOnBackgroundClick={false} {...props}>
      <div className="caller__modal">
        <div className="columns is-centered">
          <div className="column is-6-desktop">
            <div className="caller__modal-header">
              <span>
                {entryType === 'admin' ? 'Admin Time' : 'Call'} Completed
              </span>
            </div>

            <div className="caller__modal-row">
              <SelectGroup
                label="Log Time As"
                onChange={value => setEntryType(value)}
                options={entryTypeOptions}
                skipEmptyPlaceholder
                value={entryType}
              />
            </div>

            <div className="caller__modal-row">
              <SelectGroup
                label="Service Type"
                onChange={value => setCallServiceId(value)}
                options={serviceOptions()}
                value={callServiceId}
              />
            </div>

            <div className="caller__modal-row">
              <SearchGroup
                clearFalseResultOnBlur
                formatResult={formatSearchFullName}
                guideValue={patient?.id}
                label="Patient"
                minimumInputLength={1}
                onChange={(_value, selection) => handleSelectPatient(selection)}
                placeholder="Select Patient"
                queryParams={{ care_manager_id: currentUser.data.id }}
                searchPath="api/v1/patients"
                updateValue={initialSearchSelection}
              />
            </div>

            {renderFormFields()}

            <div className="caller__modal-actions">
              <Button
                color="secondary"
                isDisabled={isSubmitting}
                isFullWidth
                onClick={handleSubmitForm}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CallCompleteModal;
