import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const PageHeader = ({ children }: Props): JSX.Element => {
  return <h1 className="table-page-header">{children}</h1>;
};

export default PageHeader;
