import React from 'react';

import { Patient } from '@/types/v2/patient';
import { formatPhoneNumber } from '@/utils/phoneUtils';

interface Props {
  isVisible: boolean;
  patient: Patient;
}

const PatientDetailsExpanded = (props: Props) => {
  const { isVisible, patient } = props;

  const renderPreviousName = name => {
    return (
      <p className="patient-details-expanded__details" key={name}>
        Previous Name: {name.first_name} {name.last_name}
      </p>
    );
  };

  return (
    <div>
      {isVisible && (
        <div className="columns">
          <div className="column is-4-tablet">
            <div className="patient-details-expanded__wrapper">
              <h4 className="patient-details-expanded__title">
                Healthcare Details
              </h4>
              <p className="patient-details-expanded__details">
                Health System: {patient.practice.health_system.name}
              </p>
              <p className="patient-details-expanded__details">
                Hospital: {patient.practice.hospital.name}
              </p>
              <p className="patient-details-expanded__details">
                Practice: {patient.practice.name}
              </p>
              <p className="patient-details-expanded__details">{`Provider: ${
                patient.default_specialist
                  ? patient.default_specialist.name
                  : ''
              }`}</p>
              {patient.specialists &&
                patient.specialists.map(specialist => {
                  return (
                    <p
                      className="patient-details-expanded__details"
                      key={
                        specialist.name
                      }>{`Additional Provider: ${specialist.name}`}</p>
                  );
                })}

              <p className="patient-details-expanded__details">
                Insurance Carrier: {patient.insurance_carrier}
              </p>
              <p className="patient-details-expanded__details">
                Insurance Group ID: {patient.insurance_group_id}
              </p>
              <p className="patient-details-expanded__details">
                Insurance Member ID: {patient.insurance_member_id}
              </p>
            </div>
          </div>

          <div className="column is-4-tablet">
            <div className="patient-details-expanded__wrapper">
              <h4 className="patient-details-expanded__title">User Details</h4>
              <p className="patient-details-expanded__details">{`Phone Number: ${formatPhoneNumber(
                patient.phone_number,
              )}`}</p>
              <p className="patient-details-expanded__details">{`Emergency Phone: ${formatPhoneNumber(
                patient.emergency_contact.phone_number,
              )}`}</p>
              <p className="patient-details-expanded__details">{`Email: ${patient.email}`}</p>

              <div>
                <p>{`Address: ${patient.address.line_1}`}</p>
                <p className="patient-details-expanded__address">
                  {patient.address.line_2}
                </p>
                <p className="patient-details-expanded__address">{`${patient.address.city}, ${patient.address.state}, ${patient.address.zip_code}`}</p>
              </div>

              <p className="patient-details-expanded__details">{`Language: ${patient.language}`}</p>

              {patient.previous_names &&
                patient.previous_names.map(name => {
                  return renderPreviousName(name);
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientDetailsExpanded;
