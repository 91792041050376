import React, { useMemo, useState } from 'react';

import Api from '@/api/Api';
import Button from '@/components/button/Button';
import { UUID } from '@/types/generic';
import { VirtualAppointmentQuestionnaire } from '@/types/v2/questionnaire';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import AppointmentQuestionnaire from './AppointmentQuestionnaire';
import { QuestionnaireResponseState, ResponseState } from './types';
import { formatFormBody } from './utils';

interface Props {
  questionnaires: VirtualAppointmentQuestionnaire[];
  onSaveComplete?: () => void;
}

const Questionnaires = ({
  onSaveComplete,
  questionnaires,
}: Props): JSX.Element => {
  const loader = useLoadingState('form');
  const notifier = useNotifier();

  const initialState = useMemo(() => {
    const questionniaresById = {};

    for (const questionnaire of questionnaires) {
      questionniaresById[questionnaire.id] =
        questionnaire.questionnaire_responses;
    }

    return questionniaresById;
  }, [questionnaires]);

  const [responses, setResponses] = useState<QuestionnaireResponseState>(
    initialState,
  );

  const handleUpdateResponses = (
    questionnaireId: UUID,
    questionnaireResponses: ResponseState[],
  ): void => {
    setResponses({
      ...responses,
      [questionnaireId]: questionnaireResponses,
    });
  };

  const renderQuestionnaires = (() => {
    return questionnaires.map(questionnaire => (
      <AppointmentQuestionnaire
        key={questionnaire.id}
        questionnaire={questionnaire}
        onUpdateResponses={handleUpdateResponses}
        responses={responses[questionnaire.id]}
      />
    ));
  })();

  const handleClickSave = () => {
    submitForm();
  };

  const submitForm = async () => {
    loader.startLoading('form');

    try {
      const updatedResponses = { ...responses };

      for (const questionnaireId of Object.keys(responses)) {
        const questionnaireResponses = responses[questionnaireId];
        const body = formatFormBody(questionnaireResponses);

        const url = buildPath(
          Routes.api2.virtualAppointmentQuestionnaire,
          { id: questionnaireId },
          null,
          [
            'questionnaire_responses',
            'questionnaire_responses.questionnaire_response_answers',
          ],
        );

        const response = await Api.utility.patch(url, body);
        const resource = new Modeler<VirtualAppointmentQuestionnaire>(
          response.data,
          { generations: 2 },
        ).build();

        updatedResponses[questionnaireId] = resource.questionnaire_responses;
      }

      notifier.success('Responses saved');
      setResponses(updatedResponses);

      if (onSaveComplete) {
        onSaveComplete();
      }
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('form');
  };

  return (
    <div className="questionnaires">
      {renderQuestionnaires}

      <div className="columns">
        <div className="column">
          <div className="is-justified-center">
            <Button
              color="secondary"
              isDisabled={loader.isLoading('form')}
              onClick={handleClickSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questionnaires;
