import React, { ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import Container from '@/components/container/Container';
import FilterHeader from '@/components/filters/FilterHeader';
import InputIcon from '@/components/form/InputIcon';
import SearchGroup, { formatSearchName } from '@/components/form/SearchGroup';
import SelectGroup from '@/components/form/SelectGroup';
import ActionHeader from '@/components/page/ActionHeader';
import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';
import DateTimeCell from '@/components/table/DateTimeCell';
import { CallAttributes } from '@/types/call';
import { CareManager } from '@/types/careManager';
import { Attributable, Serialized, UUID } from '@/types/generic';
import { TableConfig } from '@/types/table';
import { parseErrorData } from '@/utils/apiUtils';
import { roleOptionsSearch } from '@/utils/dropdownUtils';
import { Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';

const SEARCH_PATH = 'api/v1/care_managers';

const INITIAL_QUERY = {
  health_system_id: '',
  hospital_id: '',
  practice_id: '',
  role: '',
  per: 24,
  search: '',
};

const UsersIndex = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchInput, setSearchInput] = useState<string>('');
  const [queryOptions, setQueryOptions] = useState<Attributable>(INITIAL_QUERY);

  const handleClearFilters = () => {
    setQueryOptions(INITIAL_QUERY);
  };

  const handleClickAddUser = () => {
    history.push(Routes.usersNew);
  };

  const handleSearchChange = (value: any) => {
    setSearchInput(value);
  };

  const handleChangeQuery = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleSendInvitation = async (userId: UUID): Promise<void> => {
    try {
      const url = `/api/v1/users/${userId}/invite`;
      await Api.utility.post(url);

      dispatch(flashSuccess('Invitation sent'));
    } catch (err) {
      dispatch(flashError(parseErrorData(err)));
    }
  };

  const renderInvitationRow = (user): ReactNode => {
    if (user.attributes.invitation_accepted_at) {
      return 'Accepted';
    }

    return (
      <Button
        color="secondary"
        onClick={() => handleSendInvitation(user.id)}
        style="filled">
        Resend Invitation
      </Button>
    );
  };

  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      ...queryOptions,
    },
    columns: [
      {
        header: 'Last Name',
        isSortable: true,
        attribute: 'last_name',
        isVisibleMobile: true,
      },
      {
        header: 'First Name',
        isSortable: true,
        attribute: 'first_name',
        isVisibleMobile: true,
      },
      {
        header: 'Email Address',
        isSortable: true,
        attribute: 'email',
      },
      {
        header: 'Role',
        attribute: 'user_role',
        formatAttribute: titleizeSnakeCase,
      },
      {
        header: 'Call History',
        attribute: 'last_call',
        formatAttribute: (call: CallAttributes) => {
          if (!call) {
            return '';
          }

          return <DateTimeCell date={call.start_at} time={call.start_at} />;
        },
      },
      {
        header: 'Invite Status',
        attribute: 'invitation_accepted_at',
        formatEntry: (entry: Serialized<CareManager>) =>
          renderInvitationRow(entry),
      },
    ],
    rows: {
      editPath: Routes.usersEdit,
      editId: 'user_id',
      viewPath: Routes.usersShow,
      viewId: 'user_id',
    },
  };

  return (
    <Panel>
      <Container>
        <ActionHeader title="Care Managers">
          <InputIcon
            onChange={handleSearchChange}
            onClick={() => handleChangeQuery('search', searchInput)}
            onPressEnter={() => handleChangeQuery('search', searchInput)}
            placeholder="Search"
            value={searchInput}
          />
          <Button color="secondary" onClick={handleClickAddUser}>
            Add User
          </Button>
        </ActionHeader>

        <FilterHeader
          onChangeQuery={handleChangeQuery}
          onClearFilters={handleClearFilters}
          query={queryOptions}
          perPageDesktopClass="is-3"
          perPageTabletClass="is-3">
          <>
            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatSearchName}
              guideValue={queryOptions.health_system_id}
              label="Health System"
              minimumInputLength={1}
              onChange={(value: string) =>
                handleChangeQuery('health_system_id', value)
              }
              placeholder="Search Health Systems"
              searchPath="api/v1/health_systems"
            />

            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatSearchName}
              guideValue={queryOptions.hospital_id}
              label="Hospital"
              minimumInputLength={1}
              onChange={(value: string) =>
                handleChangeQuery('hospital_id', value)
              }
              placeholder="Search Hospitals"
              searchPath="api/v1/hospitals"
            />

            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatSearchName}
              guideValue={queryOptions.practice_id}
              label="Practice"
              minimumInputLength={1}
              onChange={(value: string) =>
                handleChangeQuery('practice_id', value)
              }
              placeholder="Search Practices"
              searchPath="api/v1/practices"
            />

            <SelectGroup
              label="Role"
              onChange={(value: string) => handleChangeQuery('role', value)}
              options={roleOptionsSearch}
              placeholder="Select Role"
              skipEmptyPlaceholder
              value={queryOptions.role}
            />
          </>
        </FilterHeader>

        <Table config={tableConfig} />
      </Container>
    </Panel>
  );
};

export default UsersIndex;
