import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { Tab, Tabs } from '@/components/tabs';
import { getSelectedPatient } from '@/selectors/api';
import { TableConfig } from '@/types/table';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useLocation } from 'react-router-dom';
import PatientLayout from '../../components/PatientLayout';
import { isEnrollmentPath, pathSelect } from '../../utils/patientUtils';

const SEARCH_PATH = 'api/v1/contacts';

const ContactsIndex = () => {
  const location = useLocation();
  const patient = useSelector(getSelectedPatient);

  const isEnrollment = isEnrollmentPath();

  const [selectedTab, setSelectedTab] = useState<string>('family');

  const editPath = buildPath(Routes.patientsContactsEdit, {
    patient_id: patient.id,
  });
  const addPath = buildPath(Routes.patientsContactsNew, { id: patient.id });

  // In the case that the user enters the form through the BHI module, an additional return path is added
  // to ensure users come back to the correct screen
  const returnPath = (() => {
    return location.pathname.includes('bhi_support')
      ? { return_to: location.pathname }
      : {};
  })();

  const editPathEnroll = buildPath(
    Routes.patientsEnrollmentEdit,
    {
      patient_id: patient.id,
      step: 'contacts',
    },
    returnPath,
  );

  const addPathEnroll = buildPath(
    Routes.patientsEnrollmentNew,
    {
      id: patient.id,
      step: 'contacts',
    },
    returnPath,
  );

  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
      contact_type: selectedTab,
    },
    emptyState: 'No contacts added yet',
    columns: [
      {
        header: 'Name',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'name',
      },
      {
        header: 'Relation',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'relation',
      },
      {
        header: 'Email',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'email',
      },
      {
        header: 'Phone Number',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'phone_number',
        formatAttribute: formatPhoneNumber,
      },
      {
        header: 'Fax Number',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'fax_number',
      },
    ],
    rows: {
      editPath: pathSelect(editPath, editPathEnroll),
    },
    header: {
      addPath: pathSelect(addPath, addPathEnroll),
    },
  };

  if (isEnrollment) {
    return <Table config={tableConfig} />;
  }

  return (
    <PatientLayout>
      <Panel>
        <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
          <Tab value="family">Family</Tab>
        </Tabs>

        <Table config={tableConfig} />
      </Panel>
    </PatientLayout>
  );
};

export default ContactsIndex;
