import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Checklist from '@/components/form/Checklist';
import EnrollmentHeader from '@/contexts/enrollment/components/EnrollmentHeader';
import {
  getSelectedPatient,
  getSelectedPatientIncluded,
} from '@/selectors/api';
import {
  getCcmConditionOptions,
  getOtherConditionId,
} from '@/selectors/options';
import { buildPath, Routes } from '@/utils/routeUtils';
import { getStepInformation } from '../helpers/steps';

const Conditions = () => {
  const history = useHistory();

  const conditionOptions = useSelector(getCcmConditionOptions);
  const included = useSelector(getSelectedPatientIncluded);
  const otherConditionId = useSelector(getOtherConditionId);
  const patient = useSelector(getSelectedPatient);

  const [originalOptions, setOriginalOptions] = useState([]);
  const [customCondition, setCustomCondition] = useState<string>(
    patient.attributes.other_conditions || '',
  );
  const [selectedOptions, setSelectedOptions] = useState([]);

  const stepInformation = getStepInformation('conditions', patient);

  const nextScreen = buildPath(Routes.patientsEnrollment, {
    id: patient.id,
    step: stepInformation.nextStep,
  });

  const formatBody = () => {
    return {
      enrollment_step: stepInformation.currentStep,
      patient: {
        condition_ids: selectedOptions,
        other_conditions: customCondition,
      },
    };
  };

  const handleSuccess = () => {
    history.push(nextScreen);
  };

  const getSelectedOptions = () => {
    if (!Array.isArray(included)) {
      return [];
    }

    const options = included
      .filter(include => include.type === 'condition')
      .map(include => include.id);

    setSelectedOptions(options);
    setOriginalOptions(options);
  };

  useEffect(() => {
    getSelectedOptions();
  }, []);

  return (
    <div className="enrollment-wrapper">
      <EnrollmentHeader
        description="Which chronic conditions would the patient like to better manage?"
        linkTo={nextScreen}
        linkText="Symptoms"
        title="Conditions"
      />

      <Checklist
        customOptionId={otherConditionId}
        customOptionValue={customCondition}
        formatBody={formatBody}
        isSubmitDisabled={!selectedOptions.length}
        onChange={newOptions => setSelectedOptions(newOptions)}
        onChangeCustomOption={value => setCustomCondition(value)}
        onSuccess={handleSuccess}
        options={conditionOptions}
        originalSelectedIds={originalOptions}
        selectedIds={selectedOptions}
        url={`api/v1/patients/${patient.id}`}
      />
    </div>
  );
};

export default Conditions;
