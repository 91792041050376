import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useResponsive } from 'react-hooks-responsive';
import { breakpoints } from '@/utils/screenUtils';
import { BrowserMultiFormatReader } from '@zxing/library/esm/browser';
import { DecodeHintType, BarcodeFormat } from '@zxing/library/esm';
import Webcam from 'react-webcam';

import { flashError } from '@/actions/sagas/messageSaga';

import { Routes } from '@/utils/routeUtils';
import usePostRequest from '@/utils/api/usePostRequest';

interface Props {
  form: any;
  formatBody: () => object;
  handleFailure: (response: any) => void;
  handleSuccess: () => void;
  updateField: (field: string, value: any) => void;
  isScannerOpen: boolean;
  deviceModelOptions: any;
}

const DeviceReader = (props: Props) => {
  const dispatch = useDispatch();

  const {
    form,
    formatBody,
    handleFailure,
    handleSuccess,
    updateField,
    isScannerOpen,
    deviceModelOptions,
  } = props;

  const { screenIsAtMost } = useResponsive(breakpoints);

  const isMobile = screenIsAtMost('mobile');

  let videoConstraints;

  if (isMobile) {
    videoConstraints = { facingMode: { exact: 'environment' } };
  } else {
    videoConstraints = { facingMode: 'user' };
  }

  const videoEle = document.querySelector('video');

  const [serialNumber, setSerialNumber] = useState<string>('');
  const [codeType, setCodeType] = useState<string>('');
  const [stream, setStream] = useState(null);
  const [reader, setReader] = useState(new BrowserMultiFormatReader());

  const models = new Object(null);

  deviceModelOptions.forEach(entry => {
    models[entry.label] = entry.value;
  });

  const formatSerialNumber = (serialNumber: string, deviceModel: string) => {
    //format the serial number based on the device model
    let startOfSerialNumber;
    const errorMessage = 'Invalid serial number for device model';
    switch (deviceModel) {
      case models['HC-802']:
        startOfSerialNumber = serialNumber.indexOf('(21)');
        if (startOfSerialNumber === -1) {
          dispatch(flashError(errorMessage));
        } else {
          return serialNumber.slice(startOfSerialNumber + 4);
        }
        break;
      case models['TAIDOC TD3261']:
        startOfSerialNumber = serialNumber.indexOf('3261');
        if (startOfSerialNumber === -1) {
          dispatch(flashError(errorMessage));
        } else {
          return serialNumber.slice(startOfSerialNumber);
        }
        break;
      default:
        return serialNumber;
    }
  };

  useEffect(() => {
    //when the device_model_id changes on the form (i.e. the user selects a new device model), the codeType slice of state changes to reflect which type of code that device's box uses
    switch (form.device_model_id) {
      case models['HC-802']:
        setCodeType('dm');
        break;
      case models['TAIDOC TD3128']:
        setCodeType('qr');
        break;
      case models['TAIDOC TD3261']:
        setCodeType('barcode');
        break;
      case models['TAIDOC TD4289']:
        setCodeType('dm');
        break;
      case models['TAIDOC TD8255']:
        setCodeType('barcode');
        break;
      default:
        setCodeType('');
    }
  }, [form.device_model_id]);

  useEffect(() => {
    //when the stream slice of state changes, create a new device reader based on what the codeType is
    // const videoEle = document.querySelector('video');
    let format;
    let hints = new Map<DecodeHintType, any>();

    if (codeType) {
      switch (codeType) {
        case 'qr':
          format = [BarcodeFormat.QR_CODE];
          hints.set(DecodeHintType.POSSIBLE_FORMATS, format);
          break;
        case 'dm':
          format = [BarcodeFormat.DATA_MATRIX];
          hints.set(DecodeHintType.POSSIBLE_FORMATS, format);
          break;
        case 'barcode':
          format = [BarcodeFormat.CODE_128];
          hints.set(DecodeHintType.POSSIBLE_FORMATS, format);
          break;
        default:
          format = [
            BarcodeFormat.QR_CODE,
            BarcodeFormat.DATA_MATRIX,
            BarcodeFormat.CODE_128,
          ];
          hints.set(DecodeHintType.POSSIBLE_FORMATS, format);
          break;
      }
      setReader(new BrowserMultiFormatReader(hints));
    }
  }, [codeType]);

  useEffect(() => {
    //when the reader changes, call decodeFromStream to begin decoding with new reader hints
    if (videoEle) {
      reader.decodeFromStream(stream, videoEle, handleScan);
    }
  }, [reader]);

  useEffect(() => {
    //when the serialNumber slice of state changes, it's formatted based on the device model and updated on the form
    if (serialNumber) {
      const formattedSerialNumber = formatSerialNumber(
        serialNumber,
        form.device_model_id,
      );
      updateField('serial_number', formattedSerialNumber);
    }
  }, [serialNumber]);

  useEffect(() => {
    //if the user has selected a device model and a serial number has been scanned, the form is submitted
    if (form.device_model_id && form.serial_number && isScannerOpen) {
      submitForm();
    }
  }, [form.serial_number]);

  const handleScan = (data: any) => {
    try {
      if (data) {
        const { text } = data;
        setSerialNumber(text);
      }
    } catch (error) {
      handleScanError(error);
    }

  };

  const handleScanError = error => {
    dispatch(flashError(error));
  };

  const [submitForm, isSubmitting] = usePostRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: Routes.api.devices,
  });

  const onMediaStream = stream => {
    //when a media stream is detected, set it in state
    setStream(stream);
  };

  return (
    isScannerOpen && (
      <Webcam onUserMedia={onMediaStream} videoConstraints={videoConstraints} />
    )
  );
};

export default DeviceReader;
