import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Datepicker from '@/components/form/Datepicker';
import ErrorMessage from '@/components/form/ErrorMessage';
import InputGroup from '@/components/form/InputGroup';
import SelectGroup from '@/components/form/SelectGroup';
import Section from '@/components/form/Section';
import Panel from '@/components/panel/Panel';
import { genderOptions } from '@/utils/dropdownUtils';
import { Validations } from '@/types/form';
import { Patient } from '@/types/patient';
import { Response } from '@/types/response';
import usePostRequest from '@/utils/api/usePostRequest';
import { parseErrorData } from '@/utils/apiUtils';
import { validate } from '@/utils/form';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useDispatch } from 'react-redux';

const FORM_STATE = {
  date_of_birth: '',
  email: '',
  phone_number: '',
  first_name: '',
  last_name: '',
  gender: '',
};

const PatientsQuickAdd = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});

  const handleFailure = (response: any) => {
    const errorMessage = parseErrorData(response);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const handleSuccess = (response: Response<Patient>) => {
    const id = response.data.id;
    const careManagementPath = buildPath(Routes.patientsCareManagement, { id });
    const bhiPath = buildPath(Routes.patientsBhiPlan, { id });
    const patientAttributes = response.data.attributes;

    dispatch(flashSuccess('Patient added'));

    if (
      patientAttributes.is_enrolled_ccm ||
      patientAttributes.is_enrolled_rpm
    ) {
      history.push(careManagementPath);
    } else {
      history.push(bhiPath);
    }
  };

  const backPath = buildPath(Routes.patients);

  const formatBody = () => {
    const { ...formFields } = form;

    return {
      patient: {
        ...formFields,
      },
    };
  };

  const rules = {
    date_of_birth: { required: true },
    email: { type: 'email' },
    phone_number: { required: true },
    first_name: { required: true },
    last_name: { required: true },
    gender: { required: true },
  };

  const [submitForm, isSubmitting] = usePostRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: 'api/v1/patients',
  });

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);
    let isCustomValid = true;

    if (isValid && isCustomValid) {
      setValidations({});
    } else {
      return setValidations(validationMessages);
    }

    submitForm();
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  return (
    <div className="patient-new__wrapper">
      <Container>
        <BackLink to={backPath}>Back to List</BackLink>
      </Container>

      <Constraint size="large">
        <Panel>
          <Section title="Patient Details">
            <div className="columns">
              <div className="column">
                <InputGroup
                  label="First Name"
                  onChange={value => updateField('first_name', value)}
                  placeholder="First name"
                  validationMessage={validations.first_name}
                  value={form.first_name}
                />
              </div>

              <div className="column">
                <InputGroup
                  label="Last Name"
                  onChange={value => updateField('last_name', value)}
                  placeholder="Last name"
                  validationMessage={validations.last_name}
                  value={form.last_name}
                />
              </div>

              <div className="column">
                <Datepicker
                  label="Date of Birth"
                  onChange={value => updateField('date_of_birth', value)}
                  validationMessage={validations.date_of_birth}
                  value={form.date_of_birth}
                />
              </div>

              <div className="column">
                <InputGroup
                  label="Email Address"
                  onChange={value => updateField('email', value)}
                  placeholder="Email"
                  validationMessage={validations.email}
                  value={form.email}
                />
              </div>

              <div className="column">
                <InputGroup
                  label="Phone Number"
                  onChange={value => updateField('phone_number', value)}
                  placeholder="Phone Number"
                  type="phonenumber"
                  validationMessage={validations.phone_number}
                  value={form.phone_number}
                />
              </div>

              <div className="column">
                <SelectGroup
                  label="Gender"
                  onChange={value => updateField('gender', value)}
                  options={genderOptions}
                  validationMessage={validations.gender}
                  value={form.gender}
                />
              </div>
            </div>
          </Section>
        </Panel>

        <ErrorMessage isRightSide message={error} />

        <div className="form__actions">
          <div className="form__actions-left" />
          <div className="form__actions-right">
            <Button color="white" onClick={handleClickCancel}>
              Cancel
            </Button>

            <Button
              color="secondary"
              isSubmitting={isSubmitting}
              onClick={handleClickSubmit}>
              Save Patient
            </Button>
          </div>
        </div>
      </Constraint>
    </div>
  );
};

export default PatientsQuickAdd;
