import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import CheckboxGroup from '@/components/form/CheckboxGroup';
import ErrorMessage from '@/components/form/ErrorMessage';
import InputGroup from '@/components/form/InputGroup';
import RadioList from '@/components/form/RadioList';
import SearchGroup from '@/components/form/SearchGroup';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Modal from '@/components/modal/Modal';
import Panel from '@/components/panel/Panel';
import { getSelectedPatient } from '@/selectors/api';
import { getCurrentUser } from '@/selectors/users';
import { Validations } from '@/types/form';
import useGetRequest from '@/utils/api/useGetRequest';
import usePostRequest from '@/utils/api/usePostRequest';
import { parseErrorData } from '@/utils/apiUtils';
import {
  mathOptions,
  notifyCareManagerOptions,
  notifyOptions,
} from '@/utils/dropdownUtils';
import { validate } from '@/utils/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';
import AlertRecipientsNew from './AlertRecipientsNew';
import { IdParams } from '@/types/params';
import Modeler from '@/utils/modeler/modeler';
import { Device } from '@/types/v2/device';
import { AlertType } from '@/types/v2/alert_type';

const AlertsNew = () => {
  const patient = useSelector(getSelectedPatient);

  const history = useHistory();

  const dispatch = useDispatch();

  const { id } = useParams<IdParams>();

  const [query] = returnTabQuery();

  const currentUser = useSelector(getCurrentUser);

  const FORM_STATE = {
    alert_type_id: '',
    alert_recipient_id: '',
    care_manager_id: '',
    device_id: '',
    math_option: '',
    notify_alert: '',
    notify_care_manager: '',
    patient_id: patient.id,
    range_high: '',
    range_low: '',
    user_id: '',
    visibility: 'everyone',
    email: '',
    notify_email: false,
    notify_sms: false,
    sms_phone_number: '',
  };

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});

  const [recipients, setRecipients] = useState<any>([]);
  const [recipientOptions, setRecipientOptions] = useState<any>([]);

  const [alertTypes, setAlertTypes] = useState<AlertType[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);

  const backPath = buildPath(Routes.patientsRemotePatientCare, { id }, query);

  const shouldNotifyAlert = form.notify_alert === 'yes';

  const alertTypeOptions = useMemo(() => {
    return alertTypes.map(alertType => ({
      label: alertType.name,
      value: alertType.id,
    }));
  }, [alertTypes]);

  const deviceOptions = useMemo(() => {
    return devices.map(device => ({
      label: device.device_name.name,
      value: device.id,
    }));
  }, [devices]);

  useEffect(() => {
    if (!shouldNotifyAlert) {
      clearRecipientFields();
    }
  }, [form.notify_alert]);

  const rules = {
    alert_type_id: { required: true },
    device_id: { required: true },
    email: { type: 'email' },
    math_option: { required: true },
    notify_alert: { required: true },
    notify_care_manager: { required: true },
    range_high: { type: 'number' },
    range_low: { type: 'number' },
    sms_phone_number: { type: 'phone' },
    user_id: { required: true },
  };

  const [getAlertRecipients] = useGetRequest({
    dispatch,
    model: 'alert_recipients',
    url: buildPath('api/v1/alert_recipients', {}, { patient_id: patient.id }),
    onSuccess: (res: any) => {
      const formatted = res.data.map(item => ({
        label: item.attributes.name,
        value: item.id,
      }));

      setRecipients(res);
      setRecipientOptions(formatted);
    },
  });

  const [getDevices] = useGetRequest({
    dispatch,
    model: 'devices',
    url: buildPath('api/v1/devices/heirarchy', {}, { patient_id: patient.id, status: 'active' }),
    onSuccess: (res: any) => {
      const responseAlertTypes = new Modeler<AlertType[]>(
        res.alert_types,
        {},
      ).build();
      setAlertTypes(responseAlertTypes);

      const responseDevices = new Modeler<Device[]>(res.devices, {}).build();
      setDevices(responseDevices);
    },
  });

  useEffect(() => {
    getAlertRecipients();
    getDevices();
  }, []);

  useEffect(() => {
    updateMultipleFields('range_high', 'range_low', '');
  }, [form.math_option]);

  const formatBody = () => {
    const {
      email,
      notify_email,
      notify_sms,
      sms_phone_number,
      alert_recipient_id,
      notify_alert,
      ...formFields
    } = form;

    if (notify_alert === 'no') {
      return {
        alert_trigger: {
          ...formFields,
          notify_alert,
        },
      };
    }

    return {
      alert_trigger: {
        ...formFields,
        alert_recipient_id,
        notify_alert,
        alert_recipient_attributes: {
          id: alert_recipient_id,
          email,
          notify_email,
          notify_sms,
          sms_phone_number,
        },
      },
    };
  };

  const handleSuccess = () => {
    dispatch(flashSuccess('Alert added'));

    history.push(backPath);
  };

  const handleFailure = (response: any) => {
    const errorMessage = parseErrorData(response);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const [submitForm, isSubmitting] = usePostRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: 'api/v1/alert_triggers',
  });

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);

    if (isValid) {
      setValidations({});
    } else {
      return setValidations(validationMessages);
    }

    submitForm();
  };

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const initialSelection = () => {
    return {
      label: fullName(currentUser.data),
      value: currentUser.data.id,
    };
  };

  const formatUserResult = (result: any) => {
    return {
      label: fullName(result),
      value: result.id,
    };
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const updateMultipleFields = (
    field: string,
    fieldTwo: string,
    value: any,
  ) => {
    setForm({
      ...form,
      [field]: value,
      [fieldTwo]: value,
    });
  };

  const updateRecipientFields = (field: string, value: any) => {
    const alertRecipient = recipients.data.find(
      recipient => recipient.id === value,
    );

    const autoFillRecipientInfo = {
      ['email']: alertRecipient.attributes.email,
      ['sms_phone_number']: alertRecipient.attributes.sms_phone_number,
      ['voice_phone_number']: alertRecipient.attributes.voice_phone_number,
    };

    setForm({
      ...form,
      ...autoFillRecipientInfo,
      [field]: value,
    });
  };

  const clearRecipientFields = () => {
    setForm({
      ...form,
      ['alert_recipient_id']: '',
      ['email']: '',
      ['sms_phone_number']: '',
      ['notify_email']: false,
      ['notify_sms']: false,
    });
  };

  const handleClickNewRecipient = () => {
    const url = buildPath(
      Routes.patientsAlertTriggersRecipient,
      { patient_id: patient.id },
      query,
    );

    history.push(url);
  };

  const handleCloseModal = () => {
    const url = buildPath(
      Routes.patientsAlertTriggersNew,
      { id: patient.id },
      query,
    );

    history.push(url);
  };

  const renderModal = () => {
    const modalBackPath = buildPath(
      Routes.patientsAlertTriggersNew,
      { id: patient.id },
      query,
    );

    const onNewRecipientSuccess = () => {
      getAlertRecipients();
    };

    if (location.pathname.includes(`recipient`)) {
      return (
        <Modal isVisible={true} onCloseModal={handleCloseModal}>
          <div className="practice-form__modal">
            <h3 className="practice-form__modal-header">Add New Recipient</h3>

            <AlertRecipientsNew
              isModal
              modalBackPath={modalBackPath}
              onSuccess={onNewRecipientSuccess}
            />
          </div>
        </Modal>
      );
    }
  };

  const renderValueField = () => {
    const valueField =
      form.math_option === 'less_than' ? 'range_high' : 'range_low';

    if (form.math_option === 'range') {
      return (
        <div className="columns is-mobile">
          <div className="column is-6-mobile">
            <InputGroup
              isDisabled={!form.math_option}
              label="Min Value"
              onChange={value => updateField('range_low', value)}
              placeholder="Enter Min Value"
              validationMessage={validations.range_low}
              value={form.range_low}
            />
          </div>

          <div className="column is-6-mobile">
            <InputGroup
              isDisabled={!form.math_option}
              label="Max Value"
              onChange={value => updateField('range_high', value)}
              placeholder="Enter Max Value"
              validationMessage={validations.range_high}
              value={form.range_high}
            />
          </div>
        </div>
      );
    }

    return (
      <InputGroup
        isDisabled={!form.math_option}
        label="Value"
        onChange={value => updateField(valueField, value)}
        placeholder="Enter Value"
        validationMessage={validations.range_high}
        value={
          form.math_option === 'less_than' ? form.range_high : form.range_low
        }
      />
    );
  };

  return (
    <>
      <div className="patient-remote-care-new">
        <Container>
          <BackLink to={backPath}>Back to Manage Alerts</BackLink>
        </Container>

        <Constraint size="large">
          <Panel>
            <Section title="Add Alert">
              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Device"
                    onChange={value => updateField('device_id', value)}
                    options={deviceOptions}
                    validationMessage={validations.device}
                    value={form.device_id}
                  />
                </div>
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Alert Type"
                    onChange={value => updateField('alert_type_id', value)}
                    options={alertTypeOptions}
                    validationMessage={validations.alert_type_id}
                    value={form.alert_type_id}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Math Option"
                    onChange={value => updateField('math_option', value)}
                    options={mathOptions}
                    validationMessage={validations.math_option}
                    value={form.math_option}
                  />
                </div>

                <div className="column is-6-tablet is-4-desktop">
                  {renderValueField()}
                </div>
              </div>

              <div className="columns">
                <div className="column is-4 patient-remote-care-new__field has-no-padding-bottom">
                  <RadioList
                    options={notifyOptions}
                    value={form.notify_alert}
                    fieldLabel="Notify Recipient"
                    onChange={value => updateField('notify_alert', value)}
                    validationMessage={validations.notify_alert}
                    isFlex
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop patient-remote-care-new__recipient-dropdown">
                  <SelectGroup
                    isDisabled={!shouldNotifyAlert}
                    label="Recipient"
                    onChange={value =>
                      updateRecipientFields('alert_recipient_id', value)
                    }
                    options={recipientOptions}
                    value={form.alert_recipient_id}
                  />
                </div>

                <div className="column is-6-tablet is-4-desktop">
                  <Button
                    style="flat-large add"
                    onClick={handleClickNewRecipient}>
                    New Recipient
                  </Button>
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <div>
                    <CheckboxGroup
                      isDisabled={!shouldNotifyAlert}
                      label="Email Alert"
                      onChange={value => updateField('notify_email', value)}
                      value={form.notify_email}
                    />

                    <div className="patient-remote-care-new__email-field">
                      <InputGroup
                        isDisabled={!shouldNotifyAlert}
                        label="Email"
                        onChange={value => updateField('email', value)}
                        placeholder="Enter Email"
                        value={form.email}
                      />
                    </div>
                  </div>
                </div>

                <div className="column is-6-tablet is-4-desktop">
                  <CheckboxGroup
                    isDisabled={!shouldNotifyAlert}
                    label="SMS Alert"
                    onChange={value => updateField('notify_sms', value)}
                    value={form.notify_sms}
                  />

                  <div className="patient-remote-care-new__phone-field">
                    <InputGroup
                      isDisabled={!shouldNotifyAlert}
                      label="SMS Phone Number"
                      onChange={value => updateField('sms_phone_number', value)}
                      placeholder="Enter SMS Phone Number"
                      value={form.sms_phone_number}
                    />
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop patient-remote-care-new__field has-no-padding-bottom">
                  <RadioList
                    options={notifyCareManagerOptions}
                    value={form.notify_care_manager}
                    fieldLabel="Notify Care Manager"
                    onChange={value =>
                      updateField('notify_care_manager', value)
                    }
                    validationMessage={validations.notify_care_manager}
                    isFlex
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop patient-remote-care-new__field has-no-padding-top">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatUserResult}
                    guideValue={form.care_manager_id}
                    label="Care Manager"
                    minimumInputLength={1}
                    onChange={value => updateField('care_manager_id', value)}
                    placeholder="Search Care Managers"
                    searchPath="api/v1/care_managers"
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatUserResult}
                    guideValue={form.user_id}
                    label="Alert Set By"
                    minimumInputLength={1}
                    onChange={value => updateField('user_id', value)}
                    placeholder="Search"
                    searchPath="api/v1/users"
                    initialSelection={initialSelection()}
                    validationMessage={validations.user_id}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <CheckboxGroup
                    label="Alert Visible To Patient"
                    onChange={value => updateField('visibility', value)}
                    value={form.visibility}
                    checkedValue="everyone"
                    uncheckedValue="internal"
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <ErrorMessage isRightSide message={error} />

          <div className="form__actions">
            <div className="form__actions-left" />
            <div className="form__actions-right">
              <Button color="white" onClick={handleClickCancel}>
                Cancel
              </Button>

              <Button
                color="secondary"
                isSubmitting={isSubmitting}
                onClick={handleClickSubmit}>
                Save
              </Button>
            </div>
          </div>
        </Constraint>
      </div>

      {renderModal()}
    </>
  );
};

export default AlertsNew;
