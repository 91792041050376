import { Routes } from '@/utils/routeUtils';

export const logoutPath = (role: string): string => {
  switch (role) {
    case 'patient':
      return Routes.patientLogout;

    case 'specialist':
      return Routes.specialistLogout;

    default:
      return Routes.authLogout;
  }
};
