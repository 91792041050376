import { call, put, select, takeLatest } from 'redux-saga/effects';

import Api from '@/api/Api';
import {
  selectCallService,
  selectCurrentConnection,
  selectDeviceStatus,
  selectIncomingConnection,
  selectSelectedCallPatient,
  selectSelectedPhoneNumber,
} from '@/selectors/call_manager';
import { CallAttributes } from '@/types/call';
import Modeler from '@/utils/modeler/modeler';
import moment from 'moment';
import {
  CallManagerActions,
  displayIncomingCall,
  rejectIncomingCall,
  setActiveCallType,
  setCallModalVisibility,
  setCurrentCall,
  setCurrentConnection,
  setStartAt,
  setTimerType,
  startTimer,
} from '../reducers/call_manager';
import { flashError, flashSuccess, flashWarning } from './messageSaga';

export enum CallManagerSagaActions {
  ADD_USER_TO_CALL = 'CALL_MANAGER/ADD_USER',
  BEGIN_CALL = 'CALL_MANAGER/BEGIN_CALL',
  END_CALL = 'CALL_MANAGER/END_CALL',
  GET_INCOMING_CALL = 'CALL_MANAGER/GET_INCOMING_CALL',
  INITIALIZE_TWILIO = 'CALL_MANAGER/INITIALIZE_TWILIO',
  SEND_DIAL_TONE = 'CALL_MANAGER/SEND_DIAL_TONE',
  TRANSFER_INCOMING_CALL = 'CALL_MANAGER/TRANSFER_INCOMING',
  UPDATE_CONNECTION_PATIENT = 'CALL_MANAGER/UPDATE_CONNECTION_PATIENT',
}

export interface AddUserToCall {
  type: CallManagerSagaActions.ADD_USER_TO_CALL;
  payload: {
    addUserId: string;
    callSid: string;
  };
}

export interface GetIncomingCall {
  type: CallManagerSagaActions.GET_INCOMING_CALL;
  payload: {
    connection: any;
  };
}

export interface SendDialTone {
  type: CallManagerSagaActions.SEND_DIAL_TONE;
  payload: {
    digit: string;
  };
}

export interface TransferIncomingCall {
  type: CallManagerSagaActions.TRANSFER_INCOMING_CALL;
  payload: {
    addUserId: string;
    fromUserId: string;
  };
}

export interface UpdateConnectionPatient {
  type: CallManagerSagaActions.UPDATE_CONNECTION_PATIENT;
  payload: {
    callSid: string;
    patientId: string;
  };
}

export const addUserToCall = (
  addUserId: string,
  callSid: string,
): AddUserToCall => {
  return {
    type: CallManagerSagaActions.ADD_USER_TO_CALL,
    payload: {
      addUserId,
      callSid,
    },
  };
};

export const beginCall = () => {
  return {
    type: CallManagerSagaActions.BEGIN_CALL,
  };
};

export const getIncomingCall = (connection: any): GetIncomingCall => {
  return {
    type: CallManagerSagaActions.GET_INCOMING_CALL,
    payload: {
      connection,
    },
  };
};

export const endCall = () => {
  return {
    type: CallManagerSagaActions.END_CALL,
  };
};

export const initializeTwilio = () => {
  return {
    type: CallManagerSagaActions.INITIALIZE_TWILIO,
  };
};

export const sendDialTone = (digit: string): SendDialTone => {
  return {
    type: CallManagerSagaActions.SEND_DIAL_TONE,
    payload: {
      digit,
    },
  };
};

export const transferIncomingCall = (
  addUserId: string,
  fromUserId: string,
): TransferIncomingCall => {
  return {
    type: CallManagerSagaActions.TRANSFER_INCOMING_CALL,
    payload: {
      addUserId,
      fromUserId,
    },
  };
};

export const updateConnectionPatient = (
  callSid: string,
  patientId: string,
): UpdateConnectionPatient => {
  return {
    type: CallManagerSagaActions.UPDATE_CONNECTION_PATIENT,
    payload: {
      callSid,
      patientId,
    },
  };
};

// SAGA FUNCTIONS
function* acceptIncomingCallSaga() {
  try {
    const connection = yield select(selectIncomingConnection);

    yield put(setActiveCallType('conference'));

    connection.accept();
  } catch (err) {
    yield put(flashWarning('Error connecting to call'));
  }
}

function* addUserToCallSaga(action: AddUserToCall) {
  try {
    const { addUserId, callSid } = action.payload;

    const url = '/api/v1/voice_calls/transfer';

    const body = {
      voice_call: {
        add_user_id: addUserId,
        call_sid: callSid,
      },
    };

    yield call(Api.utility.post, url, body);

    yield put(flashSuccess('Contacting user...'));
  } catch (err) {
    yield put(flashWarning('Unable to transfer call'));
  }
}

function* rejectIncomingCallSaga() {
  try {
    const connection = yield select(selectIncomingConnection);

    connection.reject();
  } catch (err) {
    yield put(flashWarning('Error rejecting call'));
  }
}

function* sendDialToneSaga(action: SendDialTone) {
  const { digit } = action.payload;

  const connection = yield select(selectCurrentConnection);

  try {
    connection.sendDigits(digit);
  } catch (e) {
    yield put(flashWarning('Error sending tone'));
  }
}

// Call originating user to client
function* getIncomingCallSaga(action: GetIncomingCall) {
  const connection = action.payload.connection;

  yield put(setCurrentCall(null));

  try {
    const phoneNumber = connection.parameters.From;

    if (phoneNumber) {
      const url = `api/v1/patients/phone/${phoneNumber}`;
      const response = yield call(Api.utility.get, url);

      yield put(displayIncomingCall(connection, response.data.data || null));
    } else {
      yield put(displayIncomingCall(connection, null));
    }
  } catch (err) {
    yield put(flashError('Error connecting call'));
  }
}

// Call originating from client to user
function* beginCallSaga() {
  const Twilio = require('twilio-client');

  try {
    const deviceStatus = yield select(selectDeviceStatus);
    const selectedPhoneNumber = yield select(selectSelectedPhoneNumber);

    if (deviceStatus === 'ready') {
      const now = moment().format();

      yield put(setStartAt(now));
      yield put(setCallModalVisibility(false));
      yield put(setTimerType('call'));
      yield put(setActiveCallType('single'));

      const defaultServiceId = yield select(selectCallService);
      const patient = yield select(selectSelectedCallPatient);

      const callParams = {
        call: {
          patient_id: patient.id,
          patient_phone_number: selectedPhoneNumber,
          service_id: defaultServiceId,
          start_at: now,
          status: 'call_successful',
        },
      };

      const url = 'api/v1/calls';
      const response = yield call(Api.utility.post, url, callParams);

      const createdCall = new Modeler<CallAttributes>(response.data).build();

      yield put(setCurrentCall(createdCall));

      const dialParams = {
        phoneNumber: selectedPhoneNumber,
      };

      Twilio.Device.connect(dialParams);

      yield put(startTimer());
    } else {
      yield put(flashWarning('Caller is not yet connected'));
    }
  } catch (err) {
    yield put(flashError('Error connecting call'));
  }
}

function* endCallSaga() {
  const Twilio = require('twilio-client');

  yield put(setActiveCallType(null));
  yield put(setCurrentConnection(null));

  try {
    Twilio.Device.disconnectAll();
  } catch (err) {
    yield put(flashError('Error disconnecting call'));
  }
}

function* initializeTwilioSaga() {
  const Twilio = require('twilio-client');

  try {
    const url = 'api/v1/voice_calls/capability';

    const response = yield call(Api.utility.post, url);
    const token: string = response.data.token;

    Twilio.Device.setup(token, {
      sounds: {
        incoming: `${process.env.APPLICATION_URL}/public/ringtone.mp3`,
      },
    });
  } catch (err) {
    yield put(flashWarning('Unable to connect to call manager'));
  }
}

function* transferIncomingCallSaga(action: TransferIncomingCall) {
  try {
    const { addUserId, fromUserId } = action.payload;

    const url = '/api/v1/voice_calls/transfer';

    const body = {
      voice_call: {
        add_user_id: addUserId,
        from_user_id: fromUserId,
      },
    };

    yield call(Api.utility.post, url, body);

    yield put(rejectIncomingCall());
    yield put(flashSuccess('Call transferred'));
  } catch (err) {
    yield put(flashWarning('Unable to transfer call'));
  }
}

function* updateConnectionPatientSaga(action: UpdateConnectionPatient) {
  const { callSid, patientId } = action.payload;

  try {
    const url = `/api/v1/connections/${callSid}`;
    const body = {
      connection: {
        patient_id: patientId,
      },
    };

    yield call(Api.utility.patch, url, body);
  } catch (err) {
    yield put(flashWarning('Unable to save patient details'));
  }
}

export default function* callManagerSaga() {
  yield takeLatest(
    CallManagerActions.ACCEPT_INCOMING_CALL,
    acceptIncomingCallSaga,
  );
  yield takeLatest(CallManagerSagaActions.ADD_USER_TO_CALL, addUserToCallSaga);
  yield takeLatest(CallManagerSagaActions.BEGIN_CALL, beginCallSaga);
  yield takeLatest(
    CallManagerSagaActions.GET_INCOMING_CALL,
    getIncomingCallSaga,
  );
  yield takeLatest(CallManagerSagaActions.END_CALL, endCallSaga);
  yield takeLatest(
    CallManagerSagaActions.INITIALIZE_TWILIO,
    initializeTwilioSaga,
  );
  yield takeLatest(
    CallManagerActions.REJECT_INCOMING_CALL,
    rejectIncomingCallSaga,
  );
  yield takeLatest(
    CallManagerSagaActions.TRANSFER_INCOMING_CALL,
    transferIncomingCallSaga,
  );
  yield takeLatest(
    CallManagerSagaActions.UPDATE_CONNECTION_PATIENT,
    updateConnectionPatientSaga,
  );
  yield takeLatest(CallManagerSagaActions.SEND_DIAL_TONE, sendDialToneSaga);
}
