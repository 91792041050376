import moment from 'moment';
import React from 'react';

import AudioPlayer from '@/components/audio/AudioPlayer';
import { Call } from '@/types/call';
import { fullName } from '@/utils/userUtils';

interface Props {
  call: Call;
  patient: any;
}

const DashboardCallHistory = (props: Props) => {
  const { call, patient } = props;

  const callAttributes = call.attributes;

  return (
    <div className="dashboard-panel dashboard-call-history">
      <div className="dashboard-call-history__wrapper">
        <h3 className="dashboard-call-history__header">{fullName(patient)}</h3>

        <div className="columns is-mobile dashboard-call-history__date-wrapper">
          <div className="column is-6-mobile is-3-tablet">
            <h4>Call Date</h4>
            <p>{moment(callAttributes.start_at).format('ll')}</p>
          </div>

          <div className="column is-6-mobile is-3-tablet">
            <h4>Call Time</h4>
            <p>{moment(callAttributes.start_at).format('h:mm:ss a')}</p>
          </div>
        </div>

        <h4>Call Notes</h4>
        <span>{callAttributes.notes}</span>

        {callAttributes.recording_url && (
          <AudioPlayer isSmall url={callAttributes.recording_url} />
        )}
      </div>
    </div>
  );
};

export default DashboardCallHistory;
