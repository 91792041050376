import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import ActionHistoryIndex from '@/contexts/patients/modules/action_history/ActionHistoryIndex';
import AgreementsIndex from '@/contexts/patients/modules/agreements/AgreementsIndex';
import MedicalRecordsIndex from '@/contexts/patients/modules/medical_records/MedicalRecordsIndex';
import { UUID } from '@/types/generic';
import { Patient } from '@/types/v2/patient';
import useGetRequest from '@/utils/api/useGetRequest';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientLayout from './components/PatientLayout';
import VirtualVisits from './modules/virtual_visits/VirtualVisits';

interface Params {
  patient_id: UUID;
}

const PatientsShow = () => {
  const { patient_id: id } = useParams<Params>();

  const [patient, setPatient] = useState<Patient>(null);

  const dispatch = useDispatch();

  const [getPatient, isLoadingPatient] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    id,
    key: 'selectedPatient',
    model: 'patients',
    onSuccess: response => {
      setPatient(response);
    },
  });

  useEffect(() => {
    getPatient();
  }, []);

  if (!patient || isLoadingPatient) {
    return <ActivityIndicator />;
  }

  return (
    <Switch>
      <Route
        path={Routes.provider.patients.show.virtualVisits.root}
        component={VirtualVisits}
      />

      <Route path={Routes.provider.patients.show.medicalRecords}>
        <PatientLayout>
          <MedicalRecordsIndex shouldSkipLayout shouldSkipModification />
        </PatientLayout>
      </Route>

      <Route path={Routes.provider.patients.show.actionHistory}>
        <PatientLayout>
          <ActionHistoryIndex
            basePath={buildPath(Routes.provider.patients.show.actionHistory, {
              patient_id: id,
            })}
            shouldSkipLayout
          />
        </PatientLayout>
      </Route>

      <Route path={Routes.provider.patients.show.agreement}>
        <PatientLayout>
          <AgreementsIndex
            basePath={buildPath(Routes.provider.patients.show.agreement, {
              patient_id: id,
            })}
            shouldSkipLayout
            shouldSkipModification
          />
        </PatientLayout>
      </Route>
    </Switch>
  );
};

export default PatientsShow;
