import React from 'react';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

const ActivityIndicator = () => {
  return (
    <div className="activity-indicator">
      <Spinner />
    </div>
  );
};

export default ActivityIndicator;
