import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  flashError,
  flashSuccess,
  flashWarning,
} from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import AccessControl from '@/components/access_control/AccessControl';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Container from '@/components/container/Container';
import ErrorMessage from '@/components/form/ErrorMessage';
import InputGroup from '@/components/form/InputGroup';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Page from '@/components/page/Page';
import Panel from '@/components/panel/Panel';
import { isUserAtLeast, PolicyGroups } from '@/components/router';
import { getCurrentUser } from '@/selectors/users';
import { Validations } from '@/types/form';
import { Level, Permission, permissionLevels } from '@/types/permission';
import usePutRequest from '@/utils/api/usePutRequest';
import { parseErrorData } from '@/utils/apiUtils';
import { updateArrayIndex } from '@/utils/arrayUtils';
import { stateOptions, userStatusOptions ,patientsExportOptions } from '@/utils/dropdownUtils';
import { validate } from '@/utils/form';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';
import RoleAndPermissions from './components/RoleAndPermissions';
import { Role } from './components/RolePanel';
import { UUID } from '@/types/generic';

const EMPTY_ROLE = {
  level: 'standard' as Level,
  ids: [],
};

const FORM_STATE = {
  city: '',
  email: '',
  first_name: '',
  last_name: '',
  line_1: '',
  line_2: '',
  phone_number_extension: '',
  state: '',
  zip_code: '',
  roles: [],
  status: '',
  de_identify:'',
  patient_export:'',
};

interface Params {
  user_id: UUID;
}

const UsersEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { user_id } = useParams<Params>();

  const [error, setError] = useState('');
  const [form, setForm] = useState<any>(FORM_STATE);
  const [isLoading, setIsLoading] = useState(true);
  const [validations, setValidations] = useState<Validations>({});

  const [
    isConfirmDeleteModalVisible,
    setIsConfirmDeleteModalVisible,
  ] = useState<boolean>(false);

  const currentUser = useSelector(getCurrentUser);

  const handleFailure = (response: any) => {
    const errorMessage = parseErrorData(response);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const handleSuccess = () => {
    dispatch(flashSuccess('User updated'));
    history.push(Routes.users);
  };

  const backPath = buildPath(Routes.users);

  const formatBody = () => {
    const body: any = {
      care_manager: {
        email: form.email,
        first_name: form.first_name,
        last_name: form.last_name,
        phone_number_extension: form.phone_number_extension,
        patient_export:form.patient_export,
        address_attributes: {
          city: form.city,
          line_1: form.line_1,
          line_2: form.line_2,
          state: form.state,
          zip_code: form.zip_code,
        },
      },
      roles: form.roles, 
      de_identify: form.de_identify,
    };

    if (isUserAtLeast(currentUser, PolicyGroups.atLeastAdmin)) {
      body.care_manager.status = form.status;
    }

    return body;
  };

  const rules = {
    city: { required: true },
    email: { required: true, type: 'email' },
    first_name: { required: true },
    last_name: { required: true },
    line_1: { required: true },
    phone_number: { required: true, type: 'phone' },
    state: { required: true },
    zip_code: { required: true },
    patient_export:{ required: true },
  };

  const [submitForm, isSubmitting] = usePutRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: `api/v1/care_managers/${user_id}`,
  });

  const getUser = async () => {
    const userResponse = await Api.utility.get(
      `api/v1/care_managers/${user_id}`,
    );
    const permissionsResponse = await Api.utility.get(
      `api/v1/permissions?user_id=${user_id}`,
    );

    const user = userResponse.data.data.attributes;
    
    const formattedPermissions = permissionsResponse.data.data.map(
      (permission: Permission) => ({
        label: permission.attributes.permissible.name,
        level: permission.attributes.level,
        type: permission.attributes.permissible_type,
        value: permission.attributes.permissible.id,
      }),
    );

    const addRoles = [];

    for (const level of permissionLevels) {
      const filteredPermissions = formattedPermissions.filter(
        p => p.level === level,
      );

      if (filteredPermissions.length) {
        addRoles.push({
          level,
          ids: filteredPermissions.map(p => p.value),
          selections: filteredPermissions,
        });
      }
    }

    if (!addRoles.length) {
      addRoles.push(EMPTY_ROLE);
    }

    const newForm = {
      city: user.address?.city || '',
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      line_1: user.address?.line_1 || '',
      line_2: user.address?.line_2 || '',
      phone_number_extension: user.phone_number_extension,
      state: user.address?.state || '',
      status: user.status,
      zip_code: user.address?.zip_code || '',
      roles: addRoles,
      de_identify: user.de_identify==1?'active':'deactivated',
      patient_export: user.patient_export.toString()
    };

    setForm(newForm);
    setIsLoading(false);
  };

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);

    if (isValid) {
      setValidations({});
    } else {
      return setValidations(validationMessages);
    }

    submitForm();
  };

  const handleCreateNewRole = () => {
    setForm({
      ...form,
      roles: [...form.roles, EMPTY_ROLE],
    });
  };

  const handleClickDelete = () => {
    setIsConfirmDeleteModalVisible(true);
  };

  const handleCloseDelete = () => {
    setIsConfirmDeleteModalVisible(false);
  };

  const handleDeleteUser = async () => {
    try {
      const url = `api/v1/care_managers/${user_id}`;

      await Api.utility.delete(url);

      dispatch(flashSuccess('User deleted'));

      history.push(Routes.users);
    } catch (err) {
      dispatch(flashWarning('Unable to delete user'));
    }
  };

  const handleUpdateRole = (index: number, role: Role) => {
    const updatedForm = {
      ...form,
      roles: updateArrayIndex(form.roles, index, role),
    };

    setForm(updatedForm);
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  if (isLoading) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <Page>
        <Container>
          <BackLink to={backPath}>Cancel</BackLink>
        </Container>

        <Panel>
          <Container>
            <Section title="Profile Details">
              <div className="columns">
                <div className="column is-4">
                  <InputGroup
                    label="First Name"
                    onChange={value => updateField('first_name', value)}
                    placeholder="First name"
                    validationMessage={validations.first_name}
                    value={form.first_name}
                  />
                </div>

                <div className="column is-4">
                  <InputGroup
                    label="Last Name"
                    onChange={value => updateField('last_name', value)}
                    placeholder="Last name"
                    validationMessage={validations.last_name}
                    value={form.last_name}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <InputGroup
                    label="Email Address"
                    onChange={value => updateField('email', value)}
                    placeholder="Email"
                    validationMessage={validations.email}
                    value={form.email}
                  />
                </div>

                <div className="column is-4">
                  <InputGroup
                    label="Extension"
                    onChange={value =>
                      updateField('phone_number_extension', value)
                    }
                    placeholder="Extension"
                    type="phonenumber"
                    validationMessage={validations.phone_number_extension}
                    value={form.phone_number_extension}
                  />
                </div>
              </div>
            </Section>
          </Container>
        </Panel>

        <RoleAndPermissions
          roles={form.roles}
          onClickAddRole={handleCreateNewRole}
          onUpdateRole={handleUpdateRole}
        />

        <Panel>
          <Container>
            <Section title="Address">
              <div className="columns">
                <div className="column is-4">
                  <InputGroup
                    label="Street Address"
                    onChange={value => updateField('line_1', value)}
                    placeholder="Street Address"
                    validationMessage={validations.line_1}
                    value={form.line_1}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <InputGroup
                    label="Address Line 2"
                    onChange={value => updateField('line_2', value)}
                    placeholder="Address Line 2"
                    validationMessage={validations.line_2}
                    value={form.line_2}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <InputGroup
                    label="City"
                    onChange={value => updateField('city', value)}
                    placeholder="City"
                    validationMessage={validations.city}
                    value={form.city}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-2">
                  <SelectGroup
                    label="State"
                    onChange={value => updateField('state', value)}
                    options={stateOptions}
                    validationMessage={validations.state}
                    value={form.state}
                  />
                </div>

                <div className="column is-2">
                  <InputGroup
                    label="Zip Code"
                    onChange={value => updateField('zip_code', value)}
                    placeholder="Zip Code"
                    type="zipcode"
                    validationMessage={validations.zip_code}
                    value={form.zip_code}
                  />
                </div>
              </div>
            </Section>
          </Container>
        </Panel>

        <AccessControl allowedRoles={PolicyGroups.atLeastAdmin}>
          <Panel>
            <Container>
              <Section title="Status">
                <div className="columns">
                  <div className="column is-4">
                    <SelectGroup
                      label="Status"
                      onChange={value => updateField('status', value)}
                      options={userStatusOptions}
                      skipEmptyPlaceholder
                      validationMessage={validations.status}
                      value={form.status}
                    />
                  </div>
                </div>
              </Section>
            </Container>    
            </Panel>
          </AccessControl>


            <AccessControl allowedRoles={PolicyGroups.atLeastCareManager}>
          <Panel>
            <Container>
              <Section title="De-identify">
                <div className="columns">
                  <div className="column is-4">
                    <SelectGroup
                      label="Status"
                      onChange={value => updateField('de_identify', value)}
                      options={userStatusOptions}
                      skipEmptyPlaceholder
                      validationMessage={validations.status}
                      value={form.de_identify}
                    />
                  </div>
                </div>
              </Section>
            </Container>
          </Panel>
        </AccessControl>

        <AccessControl allowedRoles={PolicyGroups.atLeastSuperAdmin}>
          <Panel>
            <Container>
              <Section title="Patient Export">
                <div className="columns">
                  <div className="column is-4">
                    <SelectGroup
                      label="Status"
                      onChange={value => updateField('patient_export', value)}
                      options={patientsExportOptions}
                      skipEmptyPlaceholder
                      validationMessage={validations.status}
                      value={form.patient_export}
                    />
                  </div>
                </div>
              </Section>
            </Container>
          </Panel>
        </AccessControl>

        <Container>
          <ErrorMessage isRightSide message={error} />

          <div className="form__actions">
            <div className="form__actions-left">
              <AccessControl allowedRoles={PolicyGroups.atLeastSuperAdmin}>
                <Button color="error" onClick={handleClickDelete} style="text">
                  Delete User
                </Button>
              </AccessControl>
            </div>
            <div className="form__actions-right">
              <Button color="white" onClick={handleClickCancel}>
                Cancel
              </Button>

              <Button
                color="secondary"
                isSubmitting={isSubmitting}
                onClick={handleClickSubmit}>
                Save
              </Button>
            </div>
          </div>
        </Container>
      </Page>
      <ConfirmDeleteModal
        isVisible={isConfirmDeleteModalVisible}
        onClose={handleCloseDelete}
        onAccept={handleDeleteUser}
      />
    </>
  );
};

export default UsersEdit;
