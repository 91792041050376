import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LabsEdit from '@/contexts/patients/modules/labs/LabsEdit';
import LabsIndex from '@/contexts/patients/modules/labs/LabsIndex';
import LabsNew from '@/contexts/patients/modules/labs/LabsNew';
import ScreeningsEdit from '@/contexts/patients/modules/labs/ScreeningsEdit';
import ScreeningsNew from '@/contexts/patients/modules/labs/ScreeningsNew';
import { Routes } from '@/utils/routeUtils';

const Patients = () => {
  return (
    <Switch>
      <Route path={Routes.patientsLabsEdit} component={LabsEdit} />
      <Route path={Routes.patientsLabsNew} component={LabsNew} />
      <Route path={Routes.patientsScreeningsNew} component={ScreeningsNew} />
      <Route path={Routes.patientsScreeningsEdit} component={ScreeningsEdit} />
      <Route path={Routes.patientsLabsSlugs} component={LabsIndex} />
    </Switch>
  );
};

export default Patients;
