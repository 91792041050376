import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { Tab, Tabs } from '@/components/tabs';
import { getSelectedPatient } from '@/selectors/api';
import { TableConfig } from '@/types/table';
import { formatShortDate } from '@/utils/dateUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import PatientLayout from '../../components/PatientLayout';
import { isEnrollmentPath, pathSelect } from '../../utils/patientUtils';

const SEARCH_PATH = 'api/v1/medications';

const Medications = () => {
  const patient = useSelector(getSelectedPatient);

  const [_query, initialTab] = returnTabQuery();

  const [selectedTab, setSelectedTab] = useState<string>(
    initialTab || 'current',
  );

  const query = { return_tab: selectedTab };

  const isEnrollment = isEnrollmentPath();

  const editPath = buildPath(
    Routes.patientsMedicationsEdit,
    {
      patient_id: patient.id,
    },
    query,
  );
  const addPath = buildPath(
    Routes.patientsMedicationsNew,
    { id: patient.id },
    query,
  );

  const editPathEnroll = buildPath(
    Routes.patientsEnrollmentEdit,
    {
      patient_id: patient.id,
      step: 'medications',
    },
    query,
  );
  const addPathEnroll = buildPath(
    Routes.patientsEnrollmentNew,
    {
      id: patient.id,
      step: 'medications',
    },
    query,
  );

  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
      status: isEnrollment ? '' : selectedTab,
    },
    emptyState: 'No medications added yet',
    columns: [
      {
        header: 'Medication Name',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'name',
        width: 2,
      },
      {
        header: 'Direction',
        isSortable: false,
        attribute: 'directions',
      },
      {
        header: 'Quantity',
        isSortable: false,
        attribute: 'quantity',
      },
      {
        header: 'Date Started',
        isSortable: false,
        attribute: 'start_date',
        formatAttribute: formatShortDate,
        width: selectedTab === 'previous' ? 1 : 2,
      },
    ],
    rows: {
      editPath: pathSelect(editPath, editPathEnroll),
    },
    header: {
      addPath: pathSelect(addPath, addPathEnroll),
    },
  };

  if (selectedTab === 'previous' || isEnrollment) {
    tableConfig.columns.push({
      header: 'Date Ended',
      isSortable: false,
      attribute: 'end_date',
      formatEntry: (formState: any) => {
        return formState.status === 'current'
          ? ''
          : formatShortDate(formState.end_date);
      },
    });
  }

  if (isEnrollment) {
    return <Table config={tableConfig} />;
  }

  return (
    <PatientLayout>
      <Panel>
        <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
          <Tab value="current">Current Medications</Tab>
          <Tab value="previous">Past Medications</Tab>
        </Tabs>

        <Table config={tableConfig} />
      </Panel>
    </PatientLayout>
  );
};

export default Medications;
