import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import PatientForm from '@/contexts/patients/components/PatientForm';
import { isEnrollmentPath } from '@/contexts/patients/utils/patientUtils';
import { FormComponentConfig } from '@/types/form';
import { UUID } from '@/types/generic';
import { IdParams } from '@/types/params';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { validateBeforeSubmit } from './utils';

interface Params extends IdParams {
  patient_id: UUID;
  step: string;
}

const MedicationsEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { patient_id, id, step } = useParams<Params>();

  const [response, setResponse] = useState(null);

  const [getMedication, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'medications',
    onSuccess: (res: any) => {
      setResponse(parseResponseData(res));
    },
  });

  useEffect(() => {
    getMedication();
  }, []);

  const isEnrollment = isEnrollmentPath();

  const [query] = returnTabQuery();

  const backPath = isEnrollment
    ? buildPath(Routes.patientsEnrollment, { id: patient_id, step })
    : buildPath(Routes.patientsMedications, { id: patient_id }, query);

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: `api/v1/medications/${id}`,
      model: 'medication',
      method: 'PUT',
      onSuccess: () => history.push(backPath),
      onCancel: () => history.push(backPath),
      validateBeforeSubmit,
      clearFields: {
        end_date: formState => formState.status === 'current',
      },
    },
    state: response.attributes,
    rules: {
      name: { required: true },
      quantity: { required: true },
      start_date: { required: true },
      status: { required: true },
    },
    layout: [
      {
        title: 'Edit Medication',
        fields: [
          [
            {
              label: 'Medication',
              field: 'name',
              placeholder: 'Search for medication name',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/medication_names',
              size: 4,
              formatTypeaheadResult: (result: any) => {
                return {
                  label: result.attributes.name,
                  value: result.attributes.name,
                };
              },
            },
            {
              label: 'Quantity',
              field: 'quantity',
              placeholder: 'Enter quantity',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Directions',
              field: 'directions',
              placeholder: 'Enter directions',
              type: 'text',
              size: 8,
            },
          ],
          [
            {
              label: 'Start Date',
              field: 'start_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
            {
              label: 'End Date',
              field: 'end_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
              disableIf: state => state.status === 'current',
            },
          ],
          [
            {
              label: 'Patient is currently taking',
              field: 'status',
              type: 'checkbox',
              size: 4,
              offset: 4,
              checkedValue: 'current',
              uncheckedValue: 'previous',
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Medications"
      config={config}
    />
  );
};

export default MedicationsEdit;
