import React, { useEffect, useMemo } from 'react';

import { questionTypeOptions } from '@/types/v2/questionnaire';

import Constraint from '@/components/constraint/Constraint';
import InputGroup from '@/components/form/InputGroup';
import SelectGroup from '@/components/form/SelectGroup';
import Panel from '@/components/panel/Panel';
import { updateArrayIndex } from '@/utils/arrayUtils';
import FormAnswer from './FormAnswer';
import PositionControl, { PositionDirection } from './PositionControl';
import { Question, QuestionValidation } from './types';

interface Props {
  activeQuestions: number;
  index: number;
  onChangePosition: (index: number, direction: PositionDirection) => void;
  onChangeQuestion: (index: number, question: Question) => void;
  onRemoveQuestion: (index: number) => void;
  question: Question;
  validations: QuestionValidation;
}

const FormQuestion = (props: Props): JSX.Element => {
  const {
    activeQuestions,
    index: questionIndex,
    onChangePosition,
    onChangeQuestion,
    onRemoveQuestion,
    question,
    validations = { questionnaire_answers: [] },
  } = props;

  const activeAnswers = useMemo(() => {
    return question.questionnaire_answers.filter(answer => !answer._destroy)
      .length;
  }, [question.questionnaire_answers]);

  const handleRemoveQuestion = (): void => {
    onRemoveQuestion(questionIndex);
  };

  const handleUpdateQuestion = (field: string, value: any): void => {
    onChangeQuestion(questionIndex, {
      ...question,
      [field]: value,
    });
  };

  const handleChangePosition = (direction: PositionDirection): void => {
    onChangePosition(questionIndex, direction);
  };

  const handleUpdateAnswerText = (answerIndex: number, value: string) => {
    const answers = [...question.questionnaire_answers];
    answers[answerIndex].text = value;

    handleUpdateQuestion('questionnaire_answers', answers);
  };

  const handleRemoveAnswer = (answerIndex: number) => {
    const answers = [...question.questionnaire_answers];
    const selectedAnswer = { ...answers[answerIndex] };

    if (selectedAnswer.id) {
      selectedAnswer._destroy = true;
      const updatedAnswers = updateArrayIndex(
        answers,
        answerIndex,
        selectedAnswer,
      );

      handleUpdateQuestion('questionnaire_answers', updatedAnswers);
    } else {
      answers.splice(answerIndex, 1);
      handleUpdateQuestion('questionnaire_answers', answers);
    }
  };

  const handleAddAnswer = () => {
    const newAnswers = [...question.questionnaire_answers, { text: '' }];
    handleUpdateQuestion('questionnaire_answers', newAnswers);
  };

  useEffect(() => {
    if (question.question_type !== 'free_text' && !activeAnswers) {
      handleAddAnswer();
    }

    if (question.question_type === 'free_text') {
      handleUpdateQuestion('questionnaire_answers', []);
    }
  }, [question.question_type]);

  const renderAnswers = (() => {
    let answerNumber = 0;

    return question.questionnaire_answers.map((answer, answerIndex) => {
      if (!answer._destroy) {
        answerNumber++;

        const answerValidations =
          validations.questionnaire_answers[answerIndex];

        return (
          <FormAnswer
            key={answerIndex}
            answer={answer}
            answerNumber={answerNumber}
            index={answerIndex}
            onChangeText={handleUpdateAnswerText}
            onRemoveAnswer={handleRemoveAnswer}
            validations={answerValidations}
          />
        );
      }
    });
  })();

  const renderAddAnswer = (() => {
    if (question.question_type !== 'free_text' && activeAnswers < 5) {
      return (
        <div className="columns">
          <div className="column">
            <a className="questionnaire-remove" onClick={handleAddAnswer}>
              Add Option
            </a>
          </div>
        </div>
      );
    }
  })();

  return (
    <div className="questionnaire-form-question">
      <Panel>
        <Constraint size="large">
          <div className="columns">
            <div className="column">
              <div className="is-justified-right">
                <PositionControl
                  canMoveDown={question.position < activeQuestions}
                  canMoveUp={question.position > 1}
                  onChangePosition={handleChangePosition}
                />
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <InputGroup
                label="Question"
                onChange={value => handleUpdateQuestion('text', value)}
                value={question.text}
                validationMessage={validations.text}
              />
            </div>
          </div>

          <div className="columns">
            <div className="column is-4">
              <SelectGroup
                label="Question Type"
                onChange={value => handleUpdateQuestion('question_type', value)}
                options={questionTypeOptions}
                value={question.question_type}
                validationMessage={validations.question_type}
              />
            </div>
          </div>

          {renderAnswers}
          {renderAddAnswer}

          <div className="columns">
            <div className="column">
              <div className="is-justified-center">
                <a
                  className="questionnaire-remove"
                  onClick={handleRemoveQuestion}>
                  Remove Question
                </a>
              </div>
            </div>
          </div>
        </Constraint>
      </Panel>
    </div>
  );
};

export default FormQuestion;
