import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Table } from '@/components/table';
import { UUID } from '@/types/generic';
import { SearchQuery, TableConfig } from '@/types/table';
import { AdminUser } from '@/types/v2/admin_user';
import { Specialist } from '@/types/v2/specialist';
import { buildPath, includes, joins, Routes } from '@/utils/routeUtils';

interface Props {
  practiceId: UUID;
  query: SearchQuery;
}

const PracticeSpecialistsTable = ({
  practiceId,
  query,
}: Props): JSX.Element => {
  const config = useMemo((): TableConfig => {
    const searchQuery = {
      ...query,
      practice_id: practiceId,
      role: 'provider',
      include: includes(
        'specialist',
        'practice',
        'practice.hospital',
        'practice.hospital.health_system',
      ),
      joins: joins('specialist'),
    };

    return {
      searchPath: Routes.api2.adminUsers,
      searchQuery,
      initialSort: 'specialists.name:asc',
      columns: [
        {
          header: 'Specialty',
          isSortable: true,
          attribute: 'specialists.specialty',
          formatEntry: (entry: AdminUser) => entry.specialist.specialty,
        },
        {
          header: 'Name',
          isVisibleMobile: true,
          isSortable: true,
          attribute: 'specialists.name',
          formatEntry: (entry: AdminUser) => {
            const url = buildPath(Routes.chart.providers.show, {
              specialist_id: entry.id,
            });

            return <Link to={url}>{entry.specialist.name}</Link>;
          },
        },
      ],
    };
  }, [query]);

  return <Table config={config} shouldUseModeler modelerGenerations={3} />;
};

export default PracticeSpecialistsTable;
