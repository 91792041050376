import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import Panel from '@/components/panel/Panel';
import Container from '@/components/container/Container';
import useNotifier from '@/utils/messages/useNotifier';
import { buildPath, Routes } from '@/utils/routeUtils';
import useLoadingState from '@/utils/api/useLoadingState';
import { getSelectedPatient } from '@/selectors/api';
import { Task } from '@/types/v2/tasks';
import Api from '@/api/Api';
import Modeler from '@/utils/modeler/modeler';
import Paginator from '@/components/paginator/Paginator';
import TaskCard from './components/TaskCard';
import { EmptyBlock } from '@/components/dashboard';
import { selectCurrentUserId } from '@/selectors/users';

const CompletedTasks = (): JSX.Element => {
  const history = useHistory();
  const notifier = useNotifier();
  const loader = useLoadingState('tasks');

  const patient = useSelector(getSelectedPatient);
  const currentUserId = useSelector(selectCurrentUserId);

  const [tasks, setTasks] = useState<Task[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const getTasks = async (): Promise<void> => {
    try {
      const url = buildPath(
        Routes.api2.tasks,
        {},
        {
          patient_id: patient.id,
          page: page.toString(),
          per: '10',
          task_status: 'completed',
        },
        ['created_by', 'assigned_to', 'completed_by'],
      );

      const response = await Api.utility.get(url);

      const resource = new Modeler<Task[]>(response.data, {
        generations: 2,
      }).build();

      setTotalPages(response.data.meta.total);
      setTasks(resource);
    } catch (err) {
      notifier.error(err);
    }
  };

  useEffect(() => {
    getTasks();
  }, [page]);

  const renderTasks = () => {
    if (tasks && tasks.length > 0) {
      return tasks.map((task: Task) => (
        <TaskCard
          key={task.id}
          onCheckTaskPress={handleCheckTaskPress}
          onTaskCardPress={handleTaskCardPress}
          task={task}
        />
      ));
    } else if (!loader.isLoading('tasks')) {
      return (
        <EmptyBlock style="compact">There are no completed tasks</EmptyBlock>
      );
    }
  };

  const handleTaskCardPress = (task: Task) => {
    const path = buildPath(Routes.tasksShow, {
      id: patient.id,
      task_id: task.id,
    });

    history.push(path);
  };

  const handleCheckTaskPress = async (task: Task): Promise<void> => {
    try {
      const body = {
        task: {
          completed_by_id: currentUserId,
          task_status: 'pending',
        },
      };

      const url = buildPath(Routes.api2.task, { id: task.id });

      const response = await Api.utility.patch(url, body);

      const resource = new Modeler<Task>(response.data, {
        generations: 2,
      }).build();

      setTasks(tasks => tasks.filter(task => task.id !== resource.id));
    } catch (err) {
      notifier.error(err);
    }
  };

  return (
    <>
      <Constraint size="large">
        <BackLink onClick={() => history.goBack()}>Back</BackLink>
      </Constraint>

      <Panel>
        <Constraint size="large">
          <Container>
            <div className="tasks-completed">
              <h1 className="tasks-completed__header">Completed Tasks</h1>

              {renderTasks()}

              {totalPages > 1 && (
                <Paginator
                  currentPage={page}
                  onChangePage={page => setPage(page)}
                  totalPages={totalPages}
                />
              )}
            </div>
          </Container>
        </Constraint>
      </Panel>
    </>
  );
};

export default CompletedTasks;
