import { create, VideoCallsState } from '@/types/v2/video_calls';
import { VideoCallsAction, VideoCallsActions } from '../actions/video_calls';

export default function reducer(
  state: VideoCallsState = create(),
  action: VideoCallsAction,
): VideoCallsState {
  switch (action.type) {
    case VideoCallsActions.GENERATE_TOKEN:
      return {
        ...state,
        callStatus: 'inactive',
        isFetchingToken: true,
        selectedAppointment: action.payload.appointment,
        token: '',
      };

    case VideoCallsActions.GENERATE_TOKEN_SUCCESS:
      return {
        ...state,
        callStatus: 'active',
        isFetchingToken: false,
        token: action.payload.token,
      };

    case VideoCallsActions.GENERATE_TOKEN_FAILURE:
      return {
        ...state,
        isFetchingToken: false,
        selectedAppointment: null,
      };

    default:
      return state;
  }
}
