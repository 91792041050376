import React, { useEffect, useMemo } from 'react';

import InputGroup from '@/components/form/InputGroup';
import { Validations } from '@/types/form';
import { usePrevious } from '@/utils/stateUtils';

interface Props {
  onClearValues: () => void;
  onUpdateField: (field: string, value: string) => void;
  readingType: string;
  validations: Validations;
  value1: string;
  value2: string;
  value3: string;
}

const DeviceReadingValues = (props: Props): JSX.Element => {
  const { onClearValues, onUpdateField, readingType, validations } = props;

  const previousReading = usePrevious(readingType);

  const valueLabels = {
    '1': ['Blood Glucose (mg/dL)'],
    '2': ['Systolic (mmHg)', 'Diastolic (mmHg)', 'Pulse (beats/min)'],
    '7': ['Blood Oxygen (SpO2)', 'Pulse (beats/min)'],
    '8': ['Weight (lbs)'],
  };

  const selectedLabels = useMemo(() => {
    if (!readingType) {
      return [];
    }

    return valueLabels[readingType];
  }, [readingType]);

  useEffect(() => {
    if (previousReading) {
      onClearValues();
    }
  }, [readingType]);

  const inputFields = selectedLabels.map((label: string, index: number) => {
    return (
      <div className="column is-4" key={index}>
        <InputGroup
          label={label}
          onChange={value => onUpdateField(`value_${index + 1}`, value)}
          type="number"
          value={props[`value${index + 1}`]}
          validationMessage={validations[`value_${index + 1}`]}
        />
      </div>
    );
  });

  return <div className="columns">{inputFields}</div>;
};

export default DeviceReadingValues;
