import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getScreeningTypes } from '@/selectors/options';
import { FormComponentConfig } from '@/types/form';
import { lookup } from '@/utils/dropdownUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { useSelector } from 'react-redux';
import PatientForm from '../../components/PatientForm';
import { IdParams } from '@/types/params';

const ScreeningsNew = () => {
  const history = useHistory();

  const { id } = useParams<IdParams>();

  const [query] = returnTabQuery();

  const backPath = buildPath(Routes.patientsLabs, { id }, query);

  const screeningTypes = useSelector(getScreeningTypes);

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/screenings',
      model: 'screening',
      mergeFields: {
        patient_id: id,
      },
      method: 'POST',
      onSuccess: () => history.push(backPath),
    },
    state: {
      screening_type: '',
      last_date: '',
      next_date: '',
      comments: '',
    },
    rules: {
      screening_type: { required: true },
      last_date: { required: true },
      next_date: { required: true },
    },
    layout: [
      {
        title: 'Add Screening',
        fields: [
          [
            {
              label: 'Screening',
              field: 'screening_type',
              placeholder: 'Search for screening type',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/screening_types',
              formatTypeaheadResult: (result: any) => {
                return {
                  label: result.attributes.name,
                  value: result.attributes.name,
                };
              },
              size: 8,
            },
            {
              type: 'custom',
              size: 4,
              renderCustom: formState => {
                const screening = formState.screening_type;

                const comparedScreening = screeningTypes.find(
                  type => type.attributes.name === screening,
                );

                if (comparedScreening) {
                  const screenType =
                    lookup[comparedScreening.attributes.screen_type];

                  return (
                    <div className="form-custom-label">{`Screen Type: ${screenType}`}</div>
                  );
                }

                return <div className="form-custom-label">Screen Type: </div>;
              },
            },
          ],
          [
            {
              label: 'Last Date',
              field: 'last_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
            {
              label: 'Next Date',
              field: 'next_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
          ],
          [
            {
              label: 'Comment',
              field: 'comments',
              placeholder: 'Enter comment',
              type: 'text',
              size: 8,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Screenings"
      config={config}
    />
  );
};

export default ScreeningsNew;
