import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Api from '@/api/Api';
import Button from '@/components/button/Button';
import Container from '@/components/container/Container';
import FilterHeader from '@/components/filters/FilterHeader';
import InputIcon from '@/components/form/InputIcon';
import SearchGroup, { formatSearchName } from '@/components/form/SearchGroup';
import SelectGroup from '@/components/form/SelectGroup';
import ActionHeader from '@/components/page/ActionHeader';
import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';
import { UUID } from '@/types/generic';
import { TableConfig } from '@/types/table';
import { AdminUser, adminUserRoleOptions } from '@/types/v2/admin_user';
import useSimpleForm from '@/utils/form/useSimpleForm';
import useNotifier from '@/utils/messages/useNotifier';
import { buildPath, includes, joins, Routes } from '@/utils/routeUtils';

const formState = {
  health_system_id: '',
  hospital_id: '',
  practice_id: '',
  role: '',
  per: 24,
  search: '',
};

type FormState = typeof formState;

const ExternalUsersIndex = () => {
  const notifier = useNotifier();
  const history = useHistory();

  const [searchInput, setSearchInput] = useState<string>('');

  const form = useSimpleForm<FormState>(formState);

  const handleClearFilters = () => {
    form.set(formState);
  };

  const handleClickAddUser = () => {
    history.push(Routes.externalUsers.new);
  };

  const handleSearchChange = (value: any) => {
    setSearchInput(value);
  };

  const handleSendInvitation = async (id: UUID): Promise<void> => {
    try {
      const url = buildPath(Routes.api2.adminUser, { id }, { invite: 'true' });
      await Api.utility.patch(url);

      notifier.success('Invitation sent');
    } catch (err) {
      notifier.error(err);
    }
  };

  const renderInvitationRow = (user: AdminUser): ReactNode => {
    if (user.invitation_accepted_at) {
      return 'Accepted';
    }

    return (
      <Button
        color="secondary"
        onClick={() => handleSendInvitation(user.id)}
        style="filled">
        Resend Invitation
      </Button>
    );
  };

  const tableConfig: TableConfig = {
    initialSort: 'admin_users.last_name:asc',
    searchPath: Routes.api2.adminUsers,
    searchQuery: {
      ...form.data,
      include: includes('specialist', 'practice'),
      joins: joins('practice'),
    },
    columns: [
      {
        header: 'Last Name',
        isSortable: true,
        attribute: 'last_name',
        isVisibleMobile: true,
      },
      {
        header: 'First Name',
        isSortable: true,
        attribute: 'first_name',
        isVisibleMobile: true,
      },
      {
        header: 'Email Address',
        isSortable: true,
        attribute: 'email',
      },
      {
        header: 'Practice',
        isSortable: true,
        attribute: 'practices.name',
        formatEntry: (entry: AdminUser) => entry.practice.name,
      },
      {
        header: 'Role',
        attribute: 'specialists.id',
        formatEntry: (entry: AdminUser) => {
          return entry.specialist ? 'Provider' : 'Practice Admin';
        },
      },
      {
        header: 'Invite Status',
        attribute: 'invitation_accepted_at',
        formatEntry: (entry: AdminUser) => renderInvitationRow(entry),
      },
    ],
  };

  return (
    <Panel>
      <Container>
        <ActionHeader title="External Users">
          <InputIcon
            onChange={handleSearchChange}
            // FIXME: Values should be sent out from the component. Not sure why it was built like this.
            // ex: onClick={(value) => form.update('search', value)}
            onClick={() => form.update('search', searchInput)}
            onPressEnter={() => form.update('search', searchInput)}
            placeholder="Search"
            value={searchInput}
          />
          <Button color="secondary" onClick={handleClickAddUser}>
            Invite User
          </Button>
        </ActionHeader>

        <FilterHeader
          onChangeQuery={form.update}
          onClearFilters={handleClearFilters}
          query={form.data}
          perPageDesktopClass="is-3"
          perPageTabletClass="is-3">
          <>
            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatSearchName}
              guideValue={form.data.health_system_id}
              label="Health System"
              minimumInputLength={1}
              onChange={(value: string) =>
                form.update('health_system_id', value)
              }
              placeholder="Search Health Systems"
              searchPath="api/v1/health_systems"
            />

            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatSearchName}
              guideValue={form.data.hospital_id}
              label="Hospital"
              minimumInputLength={1}
              onChange={(value: string) => form.update('hospital_id', value)}
              placeholder="Search Hospitals"
              searchPath="api/v1/hospitals"
            />

            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatSearchName}
              guideValue={form.data.practice_id}
              label="Practice"
              minimumInputLength={1}
              onChange={(value: string) => form.update('practice_id', value)}
              placeholder="Search Practices"
              searchPath="api/v1/practices"
            />

            <SelectGroup
              label="Role"
              onChange={(value: string) => form.update('role', value)}
              options={adminUserRoleOptions}
              placeholder="Select Role"
              value={form.data.role}
            />
          </>
        </FilterHeader>

        <Table config={tableConfig} shouldUseModeler />
      </Container>
    </Panel>
  );
};

export default ExternalUsersIndex;
