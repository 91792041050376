import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import Button from '@/components/button/Button';
import ButtonLink from '@/components/button/ButtonLink';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import CheckboxGroup from '@/components/form/CheckboxGroup';
import Panel from '@/components/panel/Panel';
import QuestionnaireDisplay from '@/modules/questionniares/display/QuestionnaireDisplay';
import { canUserJoinVideoCall, selectCurrentUserId } from '@/selectors/users';
import { AdminUser } from '@/types/v2/admin_user';
import { NotificationConfiguration } from '@/types/v2/notification_configuration';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';
import { useSelector } from 'react-redux';

interface Props {
  user: any;
  passwordUrl: string;
}

const SpecialistProfileDisplay = (props: Props) => {
  const { passwordUrl, user } = props;

  const isProvider = useSelector(canUserJoinVideoCall);
  const userId = useSelector(selectCurrentUserId);

  const loader = useLoadingState('specialist');
  const notifier = useNotifier();

  const [specialist, setSpecialist] = useState<AdminUser>(null);
  const [notificationConfiguration, setNotificationConfiguration] = useState<
    NotificationConfiguration
  >(null);

  const getSpecialist = async () => {
    loader.startLoading('specialist');

    try {
      const url = buildPath(
        Routes.api2.adminUser,
        {
          id: userId,
        },
        null,
        [
          'specialist',
          'practice',
          'practice.health_system',
          'practice.hospital',
          'questionnaires',
          'questionnaires.questionnaire_questions',
          'questionnaires.questionnaire_questions.questionnaire_answers',
          'notification_configuration',
        ],
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<AdminUser>(response.data, {
        generations: 3,
      }).build();

      setSpecialist(resource);
      setNotificationConfiguration(resource.notification_configuration);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('specialist');
  };

  useEffect(() => {
    if (user) {
      getSpecialist();
    }
  }, []);

  const handleToggleNotificationConfiguration = (
    field: string,
    value: boolean,
  ) => {
    setNotificationConfiguration({
      ...notificationConfiguration,
      [field]: value,
    });
  };

  const handleUpdateNotificationConfiguration = async () => {
    try {
      const url = buildPath(Routes.api2.notificationConfiguration, {
        id: notificationConfiguration.id,
      });

      const body = {
        notification_configuration: {
          appointment_cancelled:
            notificationConfiguration.appointment_cancelled,
          appointment_confirmed:
            notificationConfiguration.appointment_confirmed,
          appointment_requested:
            notificationConfiguration.appointment_requested,
          appointment_rescheduled:
            notificationConfiguration.appointment_rescheduled,
        },
      };

      const response = await Api.utility.patch(url, body);
      const resource = new Modeler<NotificationConfiguration>(
        response.data,
      ).build();

      setNotificationConfiguration(resource);

      notifier.success('Notification Preferences Updated');
    } catch (err) {
      notifier.error(err);
    }
  };

  const renderQuestionnaires = (() => {
    return specialist?.questionnaires.map(questionnaire => (
      <QuestionnaireDisplay
        key={questionnaire.id}
        questionnaire={questionnaire}
      />
    ));
  })();

  if (loader.areAnyLoading || !specialist) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <Panel>
        <Container>
          <Constraint size="medium">
            <div className="profile-display">
              <div className="profile-display__header-wrapper">
                <h2>{fullName(specialist)}</h2>
              </div>

              <div className="profile-display__section">
                {isProvider && (
                  <>
                    <div className="profile-display__text">
                      <span>{specialist.specialist?.specialty}</span>
                    </div>

                    <span>{specialist.specialist?.scheduling_notes}</span>
                  </>
                )}

                <div className="profile-display__text has-margin-top">
                  <span>{specialist.practice.name}</span>
                  <span>{specialist.practice.hospital.name}</span>
                  <span>{specialist.practice.health_system.name}</span>
                </div>
              </div>

              <Link to={Routes.profile.provider.edit}>Edit Profile</Link>
            </div>
          </Constraint>
        </Container>
      </Panel>

      <div className="columns">
        <div className="column">
          <Constraint size="medium">
            <div className="is-justified-right">
              <a href={passwordUrl}>Reset Password</a>
            </div>
          </Constraint>
        </div>
      </div>

      <Panel>
        <Container>
          <Constraint size="medium">
            <div className="profile-display">
              <h4>Email Notifications</h4>
              <p>I would like to receive an email when...</p>

              <div className="profile-display__notifications-container">
                <div className="profile-display__checkbox-container">
                  <CheckboxGroup
                    label="a new appointment is requested"
                    onChange={() =>
                      handleToggleNotificationConfiguration(
                        'appointment_requested',
                        !notificationConfiguration.appointment_requested,
                      )
                    }
                    value={notificationConfiguration.appointment_requested}
                  />
                </div>

                <div className="profile-display__checkbox-container">
                  <CheckboxGroup
                    label="an appointment I requested has been confirmed"
                    onChange={() =>
                      handleToggleNotificationConfiguration(
                        'appointment_confirmed',
                        !notificationConfiguration.appointment_confirmed,
                      )
                    }
                    value={notificationConfiguration.appointment_confirmed}
                  />
                </div>

                <div className="profile-display__checkbox-container">
                  <CheckboxGroup
                    label="an appointment has been cancelled"
                    onChange={() =>
                      handleToggleNotificationConfiguration(
                        'appointment_cancelled',
                        !notificationConfiguration.appointment_cancelled,
                      )
                    }
                    value={notificationConfiguration.appointment_cancelled}
                  />
                </div>

                <div className="profile-display__checkbox-container">
                  <CheckboxGroup
                    label="a provider reschedules or makes changes to my appointment"
                    onChange={() =>
                      handleToggleNotificationConfiguration(
                        'appointment_rescheduled',
                        !notificationConfiguration.appointment_rescheduled,
                      )
                    }
                    value={notificationConfiguration.appointment_rescheduled}
                  />
                </div>
              </div>

              <div className="profile-display__update-container">
                <Button
                  color="secondary"
                  onClick={handleUpdateNotificationConfiguration}>
                  Update
                </Button>
              </div>
            </div>
          </Constraint>
        </Container>
      </Panel>

      {isProvider && (
        <div className="columns">
          <div className="column">
            <Constraint size="medium">
              <div className="is-justified-right">
                <ButtonLink
                  color="white"
                  to={Routes.provider.questionnaires.new}>
                  Add Provider Questionnaire
                </ButtonLink>
              </div>
            </Constraint>
          </div>
        </div>
      )}

      {isProvider && renderQuestionnaires}
    </>
  );
};

export default SpecialistProfileDisplay;
