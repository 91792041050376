import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Icon, { IconName } from '@/components/icon/Icon';

export type CircleButtonColor = 'red' | 'white' | 'blue';

export interface Props {
  children: ReactNode;
  color?: CircleButtonColor;
  icon: IconName;
  onClick?: () => void;
  withShadow?: boolean;
}

const CircleButton = ({
  children,
  color = 'blue',
  icon,
  onClick,
  withShadow,
}: Props): JSX.Element => {
  return (
    <div
      className={classNames('circle-button', {
        'is-red': color === 'red',
        'is-blue': color === 'blue',
        'is-white': color === 'white',
        'with-shadow': withShadow,
      })}>
      <button onClick={onClick}>
        <Icon icon={icon} size="large" />
      </button>

      <span>{children}</span>
    </div>
  );
};

export default CircleButton;
