import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import AlertLevel from '@/contexts/patients/modules/remote_patient_care/AlertLevel';
import {
  DeviceReadingAlertLevel,
  DeviceReadingLabel,
} from '@/types/v2/device_reading';
import { ReadingType } from '@/types/v2/reading_threshold';
import { formatFriendlyDateTime } from '@/utils/dateUtils';
import Stat from './Stat';
import { BlockStyle, StatBlockItem } from './types';

export interface Props {
  date?: string;
  emptyText?: string;
  footerLeft?: string;
  footerRight?: string;
  isEmpty?: boolean;
  level?: DeviceReadingAlertLevel;
  linkTo?: string;
  stats: StatBlockItem[];
  style: BlockStyle;
  title: string;
  type?: DeviceReadingLabel;
}

const mapReadingTypeToName = (type: ReadingType): string => {
  const types = {
    blood_pressure: 'Blood Pressure',
    blood_glucose: 'Glucometer',
    blood_oxygen: 'Oximiter',
    weight: 'Weight Scale',
  };

  return types[type];
};

const StatBlock = (props: Props): JSX.Element => {
  const {
    date,
    footerLeft,
    footerRight,
    level,
    linkTo,
    stats,
    style,
    title,
    type,
  } = props;

  const renderStats = (() => {
    const align = style === 'compact' ? 'left' : 'center';
    return stats.map((stat, index) => (
      <Stat align={align} key={index} {...stat} />
    ));
  })();

  const renderTitle = (() => {
    if (linkTo) {
      return <Link to={linkTo}>{title}</Link>;
    }

    return <span>{title}</span>;
  })();

  return (
    <div className="dashboard-stat-block__wrapper">
      <div
        className={classNames('dashboard-stat-block', {
          'is-outlined': style === 'outlined',
          'is-compact': style === 'compact',
        })}>
        <div className="dashboard-stat-block__content">
          <div className="dashboard-stat-block__header">
            {renderTitle}
            {level && <AlertLevel level={level} size="small" />}
          </div>

          <div className="dashboard-stat-block__body">
            <div className="dashboard-stat-block__stats">{renderStats}</div>

            <div className="dashboard-stat-block__footer">
              {type && <span>{mapReadingTypeToName(type)}</span>}
              {date && <span>{formatFriendlyDateTime(date)}</span>}
              {footerLeft && <span>{footerLeft}</span>}
              {footerRight && <span>{footerRight}</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatBlock;
