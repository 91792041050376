import classNames from 'classnames';
import React from 'react';

import { SideEffect } from '@/types/generic';

interface Props {
  isDisabled?: boolean;
  onClick: SideEffect;
}

const CallButton = (props: Props) => {
  const { onClick, isDisabled } = props;

  const ICON = require('assets/images/icons/phone.png');

  return (
    <div className="keypad__call-button">
      <button disabled={isDisabled} onClick={onClick}>
        <img src={ICON} alt="A green phone icon" />
      </button>
    </div>
  );
};

export default CallButton;
