import { BhiState } from '@/types/bhi';
import { CarePlan } from '@/types/care_plan';
import { Nullable, UUID } from '@/types/generic';

const initialState: BhiState = {
  current: null,
  isFetchingCarePlan: false,
};

export enum BhiActions {
  CLEAR_CURRENT_CARE_PLAN = 'BHI/CLEAR_CURRENT_CARE_PLAN',
  FETCH_CURRENT_CARE_PLAN = 'BHI/FETCH_CURRENT_CARE_PLAN',
  FETCH_CURRENT_CARE_PLAN_SUCCESS = 'BHI/FETCH_CURRENT_CARE_PLAN_SUCCESS',
  FETCH_CURRENT_CARE_PLAN_FAILURE = 'BHI/FETCH_CURRENT_CARE_PLAN_FAILURE',
  SET_CURRENT_CARE_PLAN = 'BHI/SET_CURRENT_CARE_PLAN',
}

export interface ClearCurrentCarePlan {
  type: BhiActions.CLEAR_CURRENT_CARE_PLAN;
  payload: null;
}

export interface FetchCurrentCarePlan {
  type: BhiActions.FETCH_CURRENT_CARE_PLAN;
  payload: {
    id: UUID;
  };
}

export interface FetchCurrentCarePlanSuccess {
  type: BhiActions.FETCH_CURRENT_CARE_PLAN_SUCCESS;
  payload: {
    carePlan: Nullable<CarePlan>;
  };
}

export interface FetchCurrentCarePlanFailure {
  type: BhiActions.FETCH_CURRENT_CARE_PLAN_FAILURE;
  payload: null;
}

export interface SetCurrentCarePlan {
  type: BhiActions.SET_CURRENT_CARE_PLAN;
  payload: {
    carePlan: Nullable<CarePlan>;
  };
}

export const clearCurrentCarePlan = () => ({
  type: BhiActions.CLEAR_CURRENT_CARE_PLAN,
  payload: null,
});

export const fetchCurrentCarePlan = (id: UUID) => ({
  type: BhiActions.FETCH_CURRENT_CARE_PLAN,
  payload: {
    id,
  },
});

export const fetchCurrentCarePlanSuccess = (carePlan: Nullable<CarePlan>) => ({
  type: BhiActions.FETCH_CURRENT_CARE_PLAN_SUCCESS,
  payload: {
    carePlan,
  },
});

export const fetchCurrentCarePlanFailure = () => ({
  type: BhiActions.FETCH_CURRENT_CARE_PLAN_FAILURE,
  payload: null,
});

export const setCurrentCarePlan = (carePlan: CarePlan) => ({
  type: BhiActions.SET_CURRENT_CARE_PLAN,
  payload: {
    carePlan,
  },
});

export default function reducer(
  state: BhiState = initialState,
  action: any,
): BhiState {
  const { type, payload } = action;

  switch (type) {
    case BhiActions.CLEAR_CURRENT_CARE_PLAN:
      return {
        ...state,
        current: null,
      };

    case BhiActions.FETCH_CURRENT_CARE_PLAN:
      return {
        ...state,
        isFetchingCarePlan: true,
      };

    case BhiActions.FETCH_CURRENT_CARE_PLAN_SUCCESS:
      return {
        ...state,
        current: payload.carePlan,
        isFetchingCarePlan: false,
      };

    case BhiActions.FETCH_CURRENT_CARE_PLAN_FAILURE:
      return {
        ...state,
        isFetchingCarePlan: false,
      };

    case BhiActions.SET_CURRENT_CARE_PLAN:
      return {
        ...state,
        current: payload.carePlan,
      };

    default:
      return state;
  }
}
