import { createSelector } from 'reselect';

import { AppState } from '@/types/app';
import { CallManagerState } from '@/types/call_manager';

export const callManagerState = (state: AppState): CallManagerState =>
  state.call_manager;

export const selectActiveCallType = createSelector(
  callManagerState,
  state => state.activeCallType,
);

export const selectCallService = createSelector(
  callManagerState,
  state => state.callService,
);

export const selectCallStatus = createSelector(
  callManagerState,
  state => state.callStatus,
);

export const selectCallSid = createSelector(
  callManagerState,
  state => state.callSid,
);

export const selectCurrentCall = createSelector(
  callManagerState,
  state => state.currentCall,
);

export const selectCurrentCallId = createSelector(selectCurrentCall, call =>
  call ? call.id : null,
);

export const selectCurrentConnection = createSelector(
  callManagerState,
  state => state.currentConnection,
);

export const selectDeviceStatus = createSelector(
  callManagerState,
  state => state.deviceStatus,
);

export const selectIncomingCallPatient = createSelector(
  callManagerState,
  state => state.incomingCallPatient,
);

export const selectIncomingCallStatus = createSelector(
  callManagerState,
  state => state.incomingCallStatus,
);

export const selectIncomingConnection = createSelector(
  callManagerState,
  state => state.incomingConnection,
);

export const selectIsActiveKeypadModalVisible = createSelector(
  callManagerState,
  state => state.isActiveKeypadModalVisible,
);

export const selectIsCallModalVisible = createSelector(
  callManagerState,
  state => state.isCallModalVisible,
);

export const selectIsDialerModalVisible = createSelector(
  callManagerState,
  state => state.isDialerModalVisible,
);

export const selectIsCompleteModalVisible = createSelector(
  callManagerState,
  state => state.isCompleteModalVisible,
);

export const selectIsTimerPaused = createSelector(
  callManagerState,
  state => state.isTimerPaused,
);

export const selectIsTimerRunning = createSelector(
  callManagerState,
  state => state.isTimerRunning,
);

export const selectSelectedPhoneNumber = createSelector(
  callManagerState,
  state => state.selectedPhoneNumber,
);

export const selectSelectedCallPatient = createSelector(
  callManagerState,
  state => state.selectedPatient,
);

export const selectStartAt = createSelector(
  callManagerState,
  state => state.startAt,
);

export const selectTimerAmount = createSelector(
  callManagerState,
  state => state.timerAmount,
);

export const selectTimerType = createSelector(
  callManagerState,
  state => state.timerType,
);

export const selectIsCallActive = createSelector(
  selectCallStatus,
  state => state === 'connected',
);
