import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Icon from '@/components/icon/Icon';
import { isEnrollmentPath } from '@/contexts/patients/utils/patientUtils';
import { getIsConditionSelected } from '@/selectors/api';

interface Props {
  description: string;
  linkText?: string;
  linkTo?: string;
  shouldDisplayLink?: boolean;
  title: string;
}

const EnrollmentHeader = (props: Props) => {
  const { description, linkText, linkTo, shouldDisplayLink, title } = props;

  const hasConditions = useSelector(getIsConditionSelected);

  const isEnrollment = isEnrollmentPath();

  return (
    <div className="enrollment-header">
      <div className="enrollment-header__top">
        <h3>{title}</h3>

        {(hasConditions || shouldDisplayLink) && linkTo && isEnrollment && (
          <Link to={linkTo}>
            <span>{linkText}</span>
            <Icon icon="caretRight" />
          </Link>
        )}
      </div>
      <span className="enrollment-header__description">{description}</span>
    </div>
  );
};

export default EnrollmentHeader;
