import React from 'react';

import ErrorMessage from '@/components/form/ErrorMessage';
import Label from '@/components/form/Label';
import Select from '@/components/form/Select';
import { Option } from '@/types/form';
import { SideEffect } from '@/types/generic';

interface Props {
  isDisabled?: boolean;
  label?: string;
  onChange?: SideEffect;
  options: Option[];
  skipEmptyPlaceholder?: boolean;
  placeholder?: string;
  shouldAllowNull?: boolean;
  validationMessage?: string;
  value: string;
}

export const SelectGroup = (props: Props) => {
  const {
    label,
    options,
    placeholder,
    validationMessage,
    skipEmptyPlaceholder,
    ...selectProps
  } = props;

  const isInvalid = !!validationMessage;

  const withEmptyInitialEmptySelect = (selectOptions: Option[]) => {
    const emptyLabel = placeholder || '-';

    return [{ label: emptyLabel, value: '' }, ...selectOptions];
  };

  return (
    <div className="form-input-group">
      <Label isInvalid={isInvalid}>{label}</Label>
      <Select
        isInvalid={isInvalid}
        options={
          skipEmptyPlaceholder ? options : withEmptyInitialEmptySelect(options)
        }
        {...selectProps}
      />
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </div>
  );
};

export default SelectGroup;
