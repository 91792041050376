import React, { useMemo } from 'react';

import Button from '@/components/button/Button';
import {
  hasAnyQuestionnaires,
  isCreated,
  isJoinable,
  VirtualAppointment,
} from '@/types/v2/virtual_appointment';
import { buildPath, Routes } from '@/utils/routeUtils';
import { Link } from 'react-router-dom';
import { Display } from './types';

interface Props {
  appointment: VirtualAppointment;
  onClickConfirmAppointment: () => void;
  displayFor: Display;
  isConfirmDisabled?: boolean;
  shouldDisabledJoin?: boolean;
}

const AppointmentModalAction = ({
  appointment,
  displayFor,
  isConfirmDisabled,
  onClickConfirmAppointment,
  shouldDisabledJoin,
}: Props): JSX.Element => {
  const hasUserConfirmed = useMemo(() => {
    if (displayFor === 'patient' && !!appointment.patient_confirmed_at) {
      return true;
    } else if (
      displayFor === 'specialist' &&
      !!appointment.specialist_confirmed_at
    ) {
      return true;
    }

    return false;
  }, [appointment, displayFor]);

  const confirmText = hasAnyQuestionnaires(appointment)
    ? 'Confirm & Answer'
    : 'Confirm';

  if (isJoinable(appointment) || hasUserConfirmed) {
    if (shouldDisabledJoin) {
      return null;
    }

    const path =
      displayFor === 'patient'
        ? Routes.chart.appointments.show
        : Routes.provider.appointments.show;

    const url = buildPath(path, {
      appointment_id: appointment.id,
    });

    return (
      <div className="columns">
        <div className="column">
          <div className="modal__content-centered">
            <Link className="button is-secondary" to={url}>
              Join Appointment
            </Link>
          </div>
        </div>
      </div>
    );
  } else if (isCreated(appointment)) {
    if (displayFor === 'specialist') {
      return (
        <div className="columns">
          <div className="column">
            <div className="modal__content-centered">
              <Button
                color="secondary"
                isDisabled={isConfirmDisabled}
                onClick={onClickConfirmAppointment}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="columns">
          <div className="column">
            <Button
              color="secondary"
              isDisabled={isConfirmDisabled}
              onClick={onClickConfirmAppointment}>
              {confirmText}
            </Button>
          </div>
        </div>
      );
    }
  }

  return null;
};

export default AppointmentModalAction;
