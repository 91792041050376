import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { FormComponentConfig } from '@/types/form';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';
import { isEnrollmentPath } from '../../utils/patientUtils';
import { IdParams } from '@/types/params';

interface Params extends IdParams {
  step: string;
}

const OtherHistoriesNew = () => {
  const history = useHistory();

  const location = useLocation();

  const { id, step } = useParams<Params>();

  const isEnrollment = isEnrollmentPath();

  const queryParams = new URLSearchParams(location.search || '');

  const historyType = step
    ? step.replace('_history', '')
    : queryParams.get('history_type');

  const query = { return_tab: historyType };

  const backPath = isEnrollment
    ? buildPath(Routes.patientsEnrollment, { id, step })
    : buildPath(Routes.patientsMedicalRecords, { id }, query);

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/other_histories',
      model: 'other_history',
      mergeFields: {
        history_type: historyType,
        patient_id: id,
      },
      method: 'POST',
      onSuccess: () => history.push(backPath),
      onCancel: () => history.push(backPath),
    },
    state: {
      description: '',
    },
    rules: {
      description: { required: true },
    },
    layout: [
      {
        title: 'Add History',
        fields: [
          [
            {
              field: 'description',
              type: 'textarea',
              size: 12,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Medical Records"
      config={config}
    />
  );
};

export default OtherHistoriesNew;
