import React from 'react';

interface Props {
  message?: string;
}

const ErrorScreen = (props: Props) => {
  const { message } = props;

  const handleClickRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="error-screen">
      <h2 className="error-screen__header">An Error Occured</h2>

      <span>
        Please <a onClick={handleClickRefresh}>refresh your screen</a>
      </span>

      {message && <p className="error-screen__message">Error: {message}</p>}
    </div>
  );
};

export default ErrorScreen;
