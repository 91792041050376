import React from 'react';

import Button from '@/components/button/Button';
import Modal from '@/components/modal/Modal';
import { SideEffect } from '@/types/generic';

interface Props {
  acceptText?: string;
  description?: string;
  header?: string;
  isVisible: boolean;
  onAccept: SideEffect;
  onClose: SideEffect;
  rejectText?: string;
}

const ConfirmationModal = ({
  acceptText = 'Yes',
  description = 'Are you sure?',
  header = 'Confirm',
  rejectText = 'No',
  ...props
}: Props) => {
  const { isVisible, onAccept, onClose } = props;

  return (
    <Modal isVisible={isVisible} onCloseModal={onClose}>
      <div className="modal__content">
        <h3 className="modal__header">{header}</h3>
        <span className="modal__info">{description}</span>
      </div>

      <div className="modal__actions">
        <Button color="white" onClick={onAccept}>
          {acceptText}
        </Button>

        <Button color="secondary" onClick={onClose}>
          {rejectText}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
