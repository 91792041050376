import { Flags } from '@/state/feature_flags';

export enum FeatureFlagActions {
  SET_FEATURE_FLAGS = 'FEATURE_FLAGS/SET_FEATURE_FLAGS',
}

export interface SetFeatureFlags {
  type: typeof FeatureFlagActions.SET_FEATURE_FLAGS;
  payload: {
    flags: Flags;
  };
}

export type FeatureFlagAction = SetFeatureFlags;

export const setFeatureFlags = (flags: Flags = {}): FeatureFlagAction => ({
  type: FeatureFlagActions.SET_FEATURE_FLAGS,
  payload: { flags },
});
