import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PatientSidebar from '@/contexts/patients/components/PatientSidebar';
import { getSelectedPatient } from '@/selectors/api';
import useGetRequest from '@/utils/api/useGetRequest';
import { buildPath } from '@/utils/routeUtils';
import PatientEnrollment from './PatientEnrollment';
import PatientHeader from './PatientHeader';

interface Props {
  children: ReactNode;
}

const PatientLayout = (props: Props) => {
  const { children } = props;

  const patient = useSelector(getSelectedPatient);

  if (!patient) {
    return null;
  }

  const dispatch = useDispatch();

  const [getLastCall] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    url: buildPath(
      'api/v1/calls/last_call',
      {},
      { patient_id: patient.id, include: 'care_manager,recording_attachment' },
    ),
    model: 'calls',
    key: 'selectedPatientLastCall',
  });

  useEffect(() => {
    getLastCall();
  }, [patient.id]);

  return (
    <>
      <PatientHeader />
      <PatientEnrollment />

      <div className="patient-layout">
        <div className="columns is-multiline">
          <div className="column is-full-touch is-2 has-no-vertical-padding-touch">
            <PatientSidebar patient={patient} />
          </div>

          <div className="column is-full-touch is-10 patient-layout__column">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientLayout;
