import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import ModalContent from '@/components/modal/ModalContent';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { buildPath } from '@/utils/routeUtils';

interface Props {
  alertRecipientId: string;
}

const AlertRecipientsModal = (props: Props) => {
  const { alertRecipientId } = props;

  const dispatch = useDispatch();

  const [alertRecipient, setAlertRecipient] = useState(null);

  const [getAlertRecipient, isAlertRecipientLoading] = useGetRequest({
    dispatch,
    model: 'alert_recipients',
    url: buildPath('api/v1/alert_recipients/:id', { id: alertRecipientId }),
    onSuccess: (response: any) => {
      setAlertRecipient(parseResponseData(response));
    },
  });

  useEffect(() => {
    if (alertRecipientId) {
      getAlertRecipient();
    }
  }, [alertRecipientId]);

  if (!alertRecipient || isAlertRecipientLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className="patient-remote-care__modal-wrapper">
      <h3 className="patient-remote-care__modal-header">Recipient</h3>
      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent label="Name" value={alertRecipient.attributes.name} />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Email Address"
            value={alertRecipient.attributes.email}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Relation"
            value={alertRecipient.attributes.relation}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="SMS Phone"
            value={formatPhoneNumber(
              alertRecipient.attributes.sms_phone_number,
            )}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Voice Phone"
            value={formatPhoneNumber(
              alertRecipient.attributes.voice_phone_number,
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertRecipientsModal;
