/**
 * Returns the name of the type of the alert from the alert number. Ex. '1' would return 'Blood Glucose'. This is based on the original FORA device mapping for alert types
 * @param  {string} type_label
 * @returns string
 */

export const typeLabelName = (label: string | number): string => {
  const stringValue = typeof label === 'number' ? label.toString() : label;

  const names = {
    '1': 'Blood Glucose',
    '2': 'Blood Pressure',
    '7': 'Blood Oxygen',
    '8': 'Weight',
  };

  return names[stringValue];
};
