import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Container from '@/components/container/Container';
import ManageFilterHeader from '@/components/filters/ManageFilterHeader';
import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';
import { buildPath, Routes } from '@/utils/routeUtils';

const SEARCH_PATH = 'api/v1/practices';

const PracticesIndex = () => {
  const history = useHistory();

  const [perPage, setPerPage] = useState(24);
  const [searchInput, setSearchInput] = useState('');
  const [queryOptions, setQueryOptions] = useState({
    search: '',
    status: '',
  });

  const handleAddPractice = () => {
    const path = buildPath(Routes.practicesNew);
    history.push(path);
  };

  const handlePerPageChange = (value: any) => {
    setPerPage(value);
  };

  const handleQueryChange = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleSearchChange = (value: any) => {
    setSearchInput(value);
  };

  const handleSearchInput = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const tableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      ...queryOptions,
      per: perPage,
    },
    columns: [
      {
        header: 'Practice Name',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'name',
        className: 'table__cell-highlight',
        width: 2,
      },
      {
        header: 'Hospital Name',
        isSortable: true,
        attribute: 'hospitals.name',
        width: 2,
        formatEntry: entry => {
          return entry.hospital.name;
        },
      },
      {
        header: 'Health System Name',
        isSortable: true,
        attribute: 'health_systems.name',
        formatEntry: entry => {
          return entry.health_system.name;
        },
      },
    ],
    rows: {
      viewPath: buildPath(Routes.practicesShow),
      editPath: buildPath(Routes.practicesEdit),
    },
  };

  return (
    <Panel>
      <Container>
        <ManageFilterHeader
          buttonText="Add Practice"
          onAddClick={handleAddPractice}
          onPerPageChange={handlePerPageChange}
          onQueryChange={handleQueryChange}
          onSearchChange={handleSearchChange}
          onSearchClick={handleSearchInput}
          perPage={perPage}
          queryOptions={queryOptions}
          searchValue={searchInput}
          title="Practice"
        />

        <Table config={tableConfig} />
      </Container>
    </Panel>
  );
};

export default PracticesIndex;
