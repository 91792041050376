import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import { Routes } from '@/utils/routeUtils';
import CallsScheduleIndex from './CallsScheduleIndex';
import CallsScheduleNew from './CallsScheduleNew';

const Calls = () => {
  return (
    <Protected allowedRoles={PolicyGroups.nonBillingAny}>
      <Switch>
        <Route path={Routes.callsScheduleNew} component={CallsScheduleNew} />
        <Route path={Routes.calls} component={CallsScheduleIndex} />
      </Switch>
    </Protected>
  );
};

export default Calls;
