import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import PatientForm from '@/contexts/patients/components/PatientForm';
import { getSelectedPatient } from '@/selectors/api';
import { getManualEntryReasonOptions } from '@/selectors/options';
import { FormComponentConfig } from '@/types/form';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { breakDuration, buildDurationFromForm } from '@/utils/timeUtils';
import { UUID } from '@/types/generic';

interface Params {
  patient_id: UUID;
  id: UUID;
}

const AdminEntriesEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { patient_id, id } = useParams<Params>();

  const manualEntryReasonOptions = useSelector(getManualEntryReasonOptions);
  const patient = useSelector(getSelectedPatient);

  const [response, setResponse] = useState(null);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'admin_entries',
    onSuccess: (res: any) => {
      const data = parseResponseData(res);
      const duration = data.attributes.duration;

      const [hours, minutes] = breakDuration(duration);

      data.attributes.hours = hours;
      data.attributes.minutes = minutes;

      setResponse(data);
    },
  });

  useEffect(() => {
    getEntry();
  }, []);

  const [query] = returnTabQuery();

  const backPath = buildPath(
    query.service === 'rpm'
      ? Routes.patientsCareManagementRpm
      : Routes.patientsCareManagement,
    { id: patient_id },
    query,
  );

  const serviceOptions = useMemo(() => {
    return patient.attributes.patient_services.data.map(service => ({
      label: service.attributes.name,
      value: service.attributes.service_id,
    }));
  }, [patient]);

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: `api/v1/admin_entries/${id}`,
      model: 'admin_entry',
      method: 'PUT',
      onCancel: () => history.push(backPath),
      onSuccess: () => history.push(backPath),
      submitText: 'Save',
      alterFormBeforeSubmit: (formState: any) =>
        buildDurationFromForm(formState),
    },
    state: response.attributes,
    rules: {
      duration: { required: true },
      manual_entry_reason: { required: true },
      notes: { required: false },
      service_id: { required: true },
      start_at: { required: true },
    },
    layout: [
      {
        title: 'Edit Admin Entry',
        fields: [
          [
            {
              label: 'Notes',
              field: 'notes',
              type: 'text',
              size: 4,
            },
            {
              label: 'Reason',
              field: 'manual_entry_reason',
              type: 'select',
              size: 4,
              placeholder: 'Select interaction reason',
              options: manualEntryReasonOptions,
            },
            {
              label: 'Duration Worked',
              field: 'hours',
              placeholder: 'Hours',
              type: 'number',
              size: 2,
            },
            {
              field: 'minutes',
              placeholder: 'Minutes',
              type: 'number',
              size: 2,
            },
          ],
          [
            {
              label: 'Date Worked',
              field: 'start_at',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
            {
              label: 'Service Type',
              field: 'service_id',
              type: 'select',
              size: 4,
              placeholder: 'Select service',
              options: serviceOptions,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Admin Entry List"
      config={config}
    />
  );
};

export default AdminEntriesEdit;
