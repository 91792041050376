export interface Flags {
  [key: string]: boolean;
}

export interface FeatureFlagState {
  flags: Flags;
}

export const create = (
  data: Partial<FeatureFlagState> = {},
): FeatureFlagState => ({
  flags: {},
  ...data,
});
