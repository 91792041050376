import ActivityIndicator from '@/components/activity/ActivityIndicator';
import { useState } from 'react';

interface LoadingState {
  [key: string]: boolean;
}

const useLoadingState = (...states: string[]) => {
  const initialState: LoadingState = {};

  for (const state of states) {
    initialState[state] = false;
  }

  const [loadingState, setLoadingState] = useState<LoadingState>(initialState);

  const startLoading = (state: string): void => {
    setLoadingState({
      ...loadingState,
      [state]: true,
    });
  };

  const stopLoading = (state: string): void => {
    setLoadingState({
      ...loadingState,
      [state]: false,
    });
  };

  const toggleLoading = (state: string): void => {
    setLoadingState({
      ...loadingState,
      [state]: !loadingState[state],
    });
  };

  const isLoading = (state: string): boolean => {
    return loadingState[state];
  };

  const doneLoading = (state: string): boolean => {
    return !loadingState[state];
  };

  const areAnyLoading = ((): boolean => {
    for (const state of Object.keys(loadingState)) {
      if (loadingState[state]) {
        return true;
      }
    }

    return false;
  })();

  const areAllComplete = !areAnyLoading;

  return {
    startLoading,
    stopLoading,
    toggleLoading,
    doneLoading,
    isLoading,
    areAnyLoading,
    areAllComplete,
    activity: ActivityIndicator,
  };
};

export default useLoadingState;
