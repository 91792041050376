import { VirtualAppointment } from './virtual_appointment';

type CallStatus = 'inactive' | 'active';

export interface VideoCallsState {
  callStatus: CallStatus;
  isFetchingToken: boolean;
  selectedAppointment: VirtualAppointment;
  token: string;
}

export const create = (
  data: Partial<VideoCallsState> = {},
): VideoCallsState => ({
  callStatus: 'inactive',
  isFetchingToken: false,
  selectedAppointment: null,
  token: '',
  ...data,
});
