import React, { ReactNode } from 'react';

import { PolicyGroups, Protected } from '@/components/router';
import PortalSidebar from './PortalSidebar';

interface Props {
  children: ReactNode;
}

const PortalLayout = (props: Props) => {
  const { children } = props;

  return (
    <Protected allowedRoles={PolicyGroups.atLeastSuperAdmin}>
      <div className="portal-layout">
        <div className="columns portal-layout__columns is-hidden-desktop">
          <div className="column portal-layout__column has-no-bottom-padding">
            <PortalSidebar />
          </div>
        </div>

        <div className="columns">
          <div className="column is-hidden-touch is-3-desktop">
            <PortalSidebar />
          </div>

          <div className="column portal-layout__column is-12-mobile is-9-desktop">
            {children}
          </div>
        </div>
      </div>
    </Protected>
  );
};

export default PortalLayout;
