import React from 'react';

import { EmptyBlock, StatBlock } from '@/components/dashboard';
import { BlockStyle, StatBlockItem } from '@/components/dashboard/types';
import { CareManagerResponse } from '@/state/rpm';
import {
  DeviceReading,
  deviceReadingValues,
  valueLabel,
} from '@/types/v2/device_reading';
import { buildPath, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  careManager: CareManagerResponse;
  date: string;
}

const CareManagerBlock = ({ careManager, date }: Props): JSX.Element => {
  const careManagerUrl = buildPath(Routes.dashboardCareManager, {
    care_manager_id: careManager.id,
  });

  const stats: StatBlockItem[] = [
    {
      value: careManager.calls,
      label: 'Calls',
    },
  ];

  const footerText = (() => {
    if (careManager.patients === 1) {
      return `1 Patient`;
    }

    return `${careManager.patients} Patients`;
  })();

  return (
    <StatBlock
      footerLeft={footerText}
      footerRight={date}
      linkTo={careManagerUrl}
      stats={stats}
      style="outlined"
      title={fullName(careManager)}
    />
  );
};

export default CareManagerBlock;
