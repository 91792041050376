import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import AudioPlayer from '@/components/audio/AudioPlayer';
import ModalContent from '@/components/modal/ModalContent';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { formatShortDate } from '@/utils/dateUtils';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { formatSeconds, formatTime } from '@/utils/timeUtils';
import { Link } from 'react-router-dom';
import { isRpmService } from '../../utils/patientUtils';

interface Props {
  callId: string;
  patientId: string;
}

const CallModal = (props: Props) => {
  const { callId, patientId } = props;

  const dispatch = useDispatch();

  const [call, setCall] = useState(null);

  const isRpm = isRpmService();

  const [getCall, isCallLoading] = useGetRequest({
    dispatch,
    model: 'calls',
    url: buildPath(
      'api/v1/calls/:id',
      { id: callId },
      { include: 'care_manager,service,recording_attachment' },
    ),
    onSuccess: (response: any) => {
      setCall(parseResponseData(response));
    },
  });

  useEffect(() => {
    if (callId) {
      getCall();
    }
  }, [callId]);

  if (!call || isCallLoading) {
    return <ActivityIndicator />;
  }

  const isCallComplete = call.attributes.status !== 'scheduled';

  const [query] = returnTabQuery();

  const editPath = buildPath(
    isRpm
      ? Routes.patientsCallsScheduleEditRpm
      : Routes.patientsCallsScheduleEdit,
    {
      call_id: callId,
      patient_id: patientId,
    },
    query,
  );

  const renderStartTime = () => {
    const text = `${formatShortDate(call.attributes.start_at)} at ${formatTime(
      call.attributes.start_at,
    )}`;

    return <ModalContent label={'Call Time'} value={text} />;
  };

  const renderIsAfterEnroll = () => {
    if (!isCallComplete) {
      return null;
    }

    if (call.attributes.is_after_enroll) {
      return <ModalContent label="Is After Enrollment" value="Yes" />;
    }

    return <ModalContent label="Is After Enrollment" value="No" />;
  };

  const careManagerName = `${call.attributes.care_manager.first_name} ${call.attributes.care_manager.last_name}`;

  return (
    <div className="patient-calls__modal-wrapper">
      <h3 className="patient-calls__modal-call-header">Call Summary</h3>

      {renderStartTime()}

      <ModalContent
        isHidden={!isCallComplete}
        label={'Status'}
        value={titleizeSnakeCase(call.attributes.status)}
      />

      <ModalContent label="Service" value={call.attributes.service.name} />

      <ModalContent
        isHidden={!isCallComplete}
        label={'Contact'}
        value={formatPhoneNumber(call.attributes.patient_phone_number)}
      />

      <ModalContent
        isHidden={!isCallComplete}
        label={'Duration'}
        value={formatSeconds(call.attributes.duration)}
      />

      <ModalContent label={'Care Manager'} value={careManagerName} />

      {renderIsAfterEnroll()}

      <ModalContent
        isHidden={!isCallComplete}
        label={'Notes'}
        value={call.attributes.notes}
      />

      <ModalContent isHidden={isCallComplete}>
        <Link to={editPath}>
          Edit
        </Link>
      </ModalContent>

      <ModalContent
        isHidden={!call.attributes.recording_url}
        label="Call Recording">
        <AudioPlayer isSmall url={call.attributes.recording_url} />
      </ModalContent>
    </div>
  );
};

export default CallModal;
