import React from 'react';

import { DeviceReading } from '@/types/v2/device_reading';
import ReadingBlock from '../components/ReadingBlock';

interface Props {
  bloodGlucoseReading: DeviceReading;
  bloodOxygenReading: DeviceReading;
  bloodPressureReading: DeviceReading;
}

const HighestDailyAlerts = ({
  bloodGlucoseReading,
  bloodOxygenReading,
  bloodPressureReading,
}: Props): JSX.Element => {
  return (
    <div className="columns">
      <div className="column is-4">
        <ReadingBlock
          deviceReading={bloodPressureReading}
          emptyText="No blood pressure readings"
          style="outlined"
        />
      </div>

      <div className="column is-4">
        <ReadingBlock
          deviceReading={bloodGlucoseReading}
          emptyText="No blood glucometer readings"
          style="outlined"
        />
      </div>

      <div className="column is-4">
        <ReadingBlock
          deviceReading={bloodOxygenReading}
          emptyText="No pulse oximiter readings"
          style="outlined"
        />
      </div>
    </div>
  );
};

export default HighestDailyAlerts;
