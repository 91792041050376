import classNames from 'classnames';
import React from 'react';

import { SideEffect } from '@/types/generic';
import ErrorMessage from './ErrorMessage';
import Label from './Label';
import RadioGroup from './RadioGroup';

interface Option {
  label: string;
  value: any;
}

interface Props {
  fieldLabel?: string;
  isEven?: boolean;
  isFlex?: boolean;
  onChange?: SideEffect;
  options: Option[];
  validationMessage?: string;
  value: any;
}

const RadioList = (props: Props) => {
  const {
    fieldLabel,
    isEven,
    isFlex,
    onChange,
    options,
    validationMessage,
    value,
  } = props;

  const handleSelectRadio = (newValue: any) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className="form-radio-list">
      {fieldLabel && (
        <label className="form-radio-list__label">{fieldLabel}</label>
      )}

      <div
        className={classNames('form-radio-list', {
          'is-flex': isFlex,
          'is-even': isEven,
        })}>
        {options.map(option => {
          return (
            <RadioGroup
              key={option.value}
              label={option.label}
              onChange={handleSelectRadio}
              selectedValue={value}
              value={option.value}
            />
          );
        })}
      </div>

      {validationMessage && <ErrorMessage message={validationMessage} />}
    </div>
  );
};

export default RadioList;
