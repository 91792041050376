import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AdminEntriesEdit from '@/contexts/patients/modules/care_management/AdminEntriesEdit';
import AdminEntriesNew from '@/contexts/patients/modules/care_management/AdminEntriesNew';
import CallsEdit from '@/contexts/patients/modules/care_management/CallsEdit';
import CallsNew from '@/contexts/patients/modules/care_management/CallsNew';
import CallsScheduleEdit from '@/contexts/patients/modules/care_management/CallsScheduleEdit';
import CallsScheduleNew from '@/contexts/patients/modules/care_management/CallsScheduleNew';
import CareManagementIndex from '@/contexts/patients/modules/care_management/CareManagementIndex';
import NotesEdit from '@/contexts/patients/modules/care_management/NotesEdit';
import NotesNew from '@/contexts/patients/modules/care_management/NotesNew';
import { Routes } from '@/utils/routeUtils';
import TasksNew from '@/contexts/tasks/TasksNew';
import CompletedTasks from '@/contexts/tasks/CompletedTasks';
import TasksShow from '@/contexts/tasks/TasksShow';

const Patients = () => {
  return (
    <Switch>
      <Route path={Routes.tasksNew} component={TasksNew} />
      <Route path={Routes.tasksCompleted} component={CompletedTasks} />
      <Route path={Routes.tasksShow} component={TasksShow} />
      <Route path={Routes.patientsCallsEdit} component={CallsEdit} />
      <Route path={Routes.patientsCallsNew} component={CallsNew} />

      <Route path={Routes.patientsNotesNew} component={NotesNew} />
      <Route path={Routes.patientsNotesEdit} component={NotesEdit} />

      <Route
        path={Routes.patientsAdminEntriesEdit}
        component={AdminEntriesEdit}
      />
      <Route
        path={Routes.patientsAdminEntriesNew}
        component={AdminEntriesNew}
      />

      <Route
        path={Routes.patientsCallsScheduleEdit}
        component={CallsScheduleEdit}
      />
      <Route
        path={Routes.patientsCallsScheduleEditRpm}
        component={CallsScheduleEdit}
      />

      <Route path={Routes.patientsCallsSchedule} component={CallsScheduleNew} />
      <Route
        path={Routes.patientsCallsScheduleRpm}
        component={CallsScheduleNew}
      />

      <Route path={Routes.patientsCallsShow} component={CareManagementIndex} />
      <Route
        path={Routes.patientsCallsShowRpm}
        component={CareManagementIndex}
      />

      <Route
        path={Routes.patientsAdminEntriesShow}
        component={CareManagementIndex}
      />
      <Route
        path={Routes.patientsAdminEntriesShowRpm}
        component={CareManagementIndex}
      />

      <Route
        path={Routes.patientsCareManagement}
        component={CareManagementIndex}
      />
      <Route path={Routes.patientsShow} component={CareManagementIndex} />
    </Switch>
  );
};

export default Patients;
