import React from 'react';

import { SideEffect } from '@/types/generic';
import { FormattedResult } from './Typeahead';

interface Props {
  isVisible: boolean;
  onClickResult: SideEffect;
  results: FormattedResult[];
}

const TypeaheadResults = (props: Props) => {
  const { isVisible, onClickResult, results } = props;

  if (!isVisible) {
    return null;
  }

  const handleSelectResult = (result: FormattedResult) => {
    onClickResult(result);
  };

  return (
    <div className="typeahead__results">
      {results.map((result: FormattedResult) => {
        return (
          <div className="typeahead__result" key={result.value}>
            <button onMouseDown={() => handleSelectResult(result)}>
              {result.label}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default TypeaheadResults;
