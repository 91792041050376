import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';

import { usePrevious } from '@/utils/stateUtils';

interface Props {
  children: ReactNode;
  className?: string;
  flashValue?: any;
  isAlignedRight?: boolean;
  shouldHideData?: boolean;
  style?: any;
}

export const Cell = (props: Props) => {
  const {
    children,
    className,
    flashValue,
    isAlignedRight,
    shouldHideData,
    style,
  } = props;

  const [showFlash, setShowFlash] = useState<boolean>(false);

  const prevValue = usePrevious(flashValue);

  const clearFlash = () => {
    setShowFlash(false);
  };

  if (prevValue && flashValue !== prevValue && !showFlash) {
    setShowFlash(true);

    setTimeout(clearFlash, 1000);
  }

  return (
    <td
      className={classNames('table__cell', {
        [className]: !!className,
        'is-aligned-right': isAlignedRight,
        'is-data-invisible': shouldHideData,
        'is-flashed': showFlash,
      })}
      style={style}>
      {children}
    </td>
  );
};

export default Cell;
