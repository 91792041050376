import React from 'react';

import { SideEffect } from '@/types/generic';

interface Props {
  onClick: SideEffect;
}

const StopButton = (props: Props) => {
  const { onClick } = props;

  return (
    <button className="caller__stop-button" onClick={onClick}>
      <div />
    </button>
  );
};

export default StopButton;
