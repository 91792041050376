import { Serialized, UUID } from '@/types/generic';

export interface CcmComplexityAttributes {
  id?: UUID;
  admin_minutes: number;
  name: string;
  phone_minutes: number;
  total_minutes: number;
}

export type CcmComplexity = Serialized<CcmComplexityAttributes>;

export const cleanComplexityCode = (code: string = '') => {
  if (!code) {
    return '';
  }

  return code.replace(/\(.*\)[\W]+/, '');
};
