import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@/components/button/Button';
import Datepicker from '@/components/form/Datepicker';
import SelectGroup from '@/components/form/SelectGroup';
import Modal from '@/components/modal/Modal';
import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';
import DateTimeCell from '@/components/table/DateTimeCell';
import { Tab, Tabs } from '@/components/tabs';
import { getSelectedPatient, getSelectedPatientIncluded } from '@/selectors/api';
import { selectPracticeReadingThresholds } from '@/selectors/patients';
import { Alert } from '@/types/alert';
import { UUID } from '@/types/generic';
import { TableConfig } from '@/types/table';
import { formatShortDate } from '@/utils/dateUtils';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { breakpoints } from '@/utils/screenUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { perPageOptions } from '@/utils/tableUtils';
import { useResponsive } from 'react-hooks-responsive';
import PatientLayout from '../../components/PatientLayout';
import AlertLevel from './AlertLevel';
import AlertRecipientsModal from './AlertRecipientsModal';
import AlertsModal from './AlertsModal';
import AlertTriggersModal from './AlertTriggersModal';
import DevicesModal from './DevicesModal';
import ReadingsTable from './modules/ReadingsTable';
import { getAlertLevel } from './utils';

import RemoteDeviceReadingsGraph from './RemoteDeviceReadingsGraph';
// import {query} from './RemoteDeviceReadingsGraph';
interface ChartData {
  labels: any[];
  datasets: any[];
}

const INITIAL_CHART_DATA = {
  labels: [],
  datasets: [],
};

const ALERTS_SEARCH_PATH = 'api/v1/alerts';
const DEVICES_SEARCH_PATH = 'api/v2/patient_devices';
const MANAGE_ALERTS_SEARCH_PATH = 'api/v1/alert_triggers';
const RECIPIENTS_SEARCH_PATH = 'api/v1/alert_recipients';

interface Params {
  alert_id: UUID;
  alert_recipient_id: UUID;
  alert_trigger_id: UUID;
  device_id: UUID;
}

const RemotePatientCareIndex = (props) => {
  const history = useHistory();

  const patient = useSelector(getSelectedPatient);
  const includes = useSelector(getSelectedPatientIncluded);
  const thresholds = useSelector(selectPracticeReadingThresholds);


  const [bpChartData, setBPChartData] = useState<ChartData>(INITIAL_CHART_DATA);
  const [bgChartData, setBGChartData] = useState<ChartData>(INITIAL_CHART_DATA);
  const [boChartData, setBOChartData] = useState<ChartData>(INITIAL_CHART_DATA);
  const [weightChartData, setWeightChartData] = useState<ChartData>(
    INITIAL_CHART_DATA,
  );
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();

  


  const formatChartData = (chartType: string, data: ChartData) => {
    if (data.datasets.length < 1) {
      return null;
    }

    return JSON.stringify({
      type: chartType,
      data: data,
    });
  };

  const {
    alert_id,
    alert_recipient_id,
    alert_trigger_id,
    device_id,
  } = useParams<Params>();

  const [perPage, setPerPage] = useState('24');
  const [reportRange, setReportRange] = useState([null, null]);
  const [reportType, setReportType] = useState('bp');

  const [_query, initialTab] = returnTabQuery();

  const [selectedTab, setSelectedTab] = useState<string>(
    initialTab || 'alerts',
  );

  const query = { return_tab: selectedTab };

  const { screenIsAtMost } = useResponsive(breakpoints);

  const isMobile = screenIsAtMost('mobile');

  const handlePerPageChange = (value: any) => {
    setPerPage(value);
  };

  const isAlertTriggersModalVisible = !!alert_trigger_id;
  const isAlertsModalVisible = !!alert_id;
  const isDevicesModalVisible = !!device_id;
  const isRecipientsModalVisible = !!alert_recipient_id;

  const closeModal = () => {
    const path = buildPath(Routes.patientsRemotePatientCare, {
      id: patient.id,
    });
    history.push(path);
  };

  const getAlertResultLevel = (result: Alert) => {
    const alertType = result.attributes.alert_type?.name;
    const deviceType = result.attributes.device_type.name;
    const value = result.attributes.value;

    return getAlertLevel(alertType, deviceType, value, thresholds);
  };

  const renderAlertIcon = (result: Alert) => {
    return <AlertLevel level={getAlertResultLevel(result)} />;
  };

  const renderAlertMathOption = result => {
    switch (result.alert_trigger.math_option) {
      case 'greater_than':
        return 'High';
      case 'less_than':
        return 'Low';
      case 'range':
        return result.value > result.alert_trigger.range_high ? 'High' : 'Low';
    }
  };

  const renderAlertResolvedStatus = result => {
    const incomplete = status =>
      status === 'pending' || status === 'in_progress';
    const task_statuses = result.tasks.map(task => task.task_status);
    return task_statuses.some(incomplete) ? 'Incomplete' : 'Complete';
  };

  const getAlertRowClass = (result: Alert) => {
    const level = getAlertResultLevel(result);

    return level ? `is-${level}` : null;
  };

  const alertsTableConfig: TableConfig = {
    searchPath: ALERTS_SEARCH_PATH,
    initialSort: 'created_at:desc',
    searchQuery: {
      include: 'device,device_model,device_type,alert_trigger,tasks',
      patient_id: patient.id,
      per: perPage,
      is_active: true,
    },
    emptyState: 'No alerts sent yet',
    columns: [
      {
        attribute: 'alert_type',
        isVisibleMobile: true,
        formatEntry: renderAlertIcon,
        width: 0.4,
      },
      {
        header: 'Date',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'created_at',
        formatEntry: entry => {
          return <DateTimeCell date={entry.created_at} />;
        },
      },
      {
        header: 'Device',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'device_models.name',
        formatEntry: entry => {
          return entry.device_model.name;
        },
      },
      {
        header: 'Value',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'value',
      },
      {
        header: 'Type',
        isSortable: true,
        attribute: 'alert_types.name',
        formatEntry: entry => {
          return entry.alert_type.name;
        },
      },
      {
        header: 'Visible To Patient',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'visible_to_patient',
        formatAttribute: titleizeSnakeCase,
      },
      {
        header: 'High/Low',
        isSortable: true,
        isVisibleMobile: true,
        formatEntry: renderAlertMathOption,
      },
      {
        header: 'Resolved?',
        isSortable: true,
        isVisibleMobile: true,
        formatEntry: renderAlertResolvedStatus,
      },
    ],
    rows: {
      addRowClass: getAlertRowClass,
      viewId: 'alert_id',
      viewPath: isMobile
        ? buildPath(
          Routes.patientsAlertsShow,
          {
            id: patient.id,
          },
          query,
        )
        : null,
    },
  };

  const manageAlertsTableConfig: TableConfig = {
    searchPath: MANAGE_ALERTS_SEARCH_PATH,
    searchQuery: {
      include: 'alert_recipient,device_type',
      patient_id: patient.id,
    },
    emptyState: 'No alerts added yet',
    columns: [
      {
        header: 'Device Type',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'device_types.name',
        formatEntry: entry => {
          return entry.device_type.name;
        },
      },
      {
        header: 'Math Option',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'math_option',
        formatAttribute: titleizeSnakeCase,
      },
      {
        header: 'Value',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'range_low',
        formatEntry: entry => {
          if (entry.math_option === 'greater_than') {
            return entry.range_low;
          } else if (entry.math_option === 'less_than') {
            return entry.range_high;
          } else {
            return `${entry.range_low} - ${entry.range_high}`;
          }
        },
      },
      {
        header: 'Notify',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'notify_alert',
        formatAttribute: titleizeSnakeCase,
      },
      {
        header: 'Recipient',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'alert_recipients.name',
        formatEntry: entry =>
          entry.alert_recipient ? entry.alert_recipient.name : '',
      },
    ],
    rows: {
      viewId: 'alert_trigger_id',
      viewPath: buildPath(
        Routes.patientsAlertTriggersShow,
        {
          id: patient.id,
        },
        query,
      ),
      editPath: buildPath(
        Routes.patientsAlertTriggersEdit,
        {
          patient_id: patient.id,
        },
        query,
      ),
    },
  };

  const manageRecipientsTable: TableConfig = {
    searchPath: RECIPIENTS_SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
    },
    emptyState: 'No recipients added yet',
    columns: [
      {
        header: 'Name',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'name',
      },
      {
        header: 'Email',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'email',
      },
      {
        header: 'Relation',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'relation',
      },
      {
        header: 'SMS Number',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'sms_phone_number',
        formatAttribute: formatPhoneNumber,
      },
      {
        header: 'Voice Number',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'voice_phone_number',
        formatAttribute: formatPhoneNumber,
      },
    ],
    rows: {
      viewId: 'alert_recipient_id',
      viewPath: buildPath(
        Routes.patientsAlertRecipientsShow,
        {
          id: patient.id,
        },
        query,
      ),
      editPath: buildPath(
        Routes.patientsAlertRecipientsEdit,
        {
          patient_id: patient.id,
        },
        query,
      ),
    },
  };

  const manageDevicesTableConfig: TableConfig = {
    searchPath: DEVICES_SEARCH_PATH,
    searchQuery: {
      include: 'device,patient,device.device_readings',
      patient_id: patient.id,
      is_active: true,
    },
    emptyState: 'No devices added yet',
    columns: [
      {
        header: 'Device Name',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'serial_number',
        formatEntry: entry => {
          return entry.device.device_name.name;
        },
      },
      {
        header: 'Last Measurement',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'serial_number',
        formatEntry: entry => {
          if (entry.device.device_readings.length) {
            const readings = entry.device.device_readings;
            var reading_time_array=[];
            for(let i=0;i<readings.length;i++)
            {
              reading_time_array.push(readings[i].reading_time);
            }
            var sorted_array=reading_time_array.sort();
           return <DateTimeCell date={sorted_array[sorted_array.length-1]} />;
          }
          return '';
        },
      },
      {
        header: 'Issue Date',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'issue_date',
        formatEntry: entry => {
          return entry.issue_date
            ? formatShortDate(entry.issue_date)
            : formatShortDate(entry.device.issue_date);
        },
      },
    ],
    rows: {
      viewId: 'device_id',
      viewPath: buildPath(
        Routes.patientsDevicesShow,
        {
          id: patient.id,
        },
        query,
      ),
      editPath: buildPath(
        Routes.patientsDevicesEdit,
        {
          patient_id: patient.id,
        },
        query,
      ),
    },
  };

  const handleAddAlert = () => {
    const alertPath = buildPath(
      Routes.patientsAlertTriggersNew,
      { id: patient.id },
      query,
    );

    history.push(alertPath);
  };

  const handleAddRecipient = () => {
    const recipientPath = buildPath(
      Routes.patientsAlertRecipientsNew,
      { id: patient.id },
      query,
    );

    history.push(recipientPath);
  };

  const handleAddDevice = () => {
    const devicePath = buildPath(
      Routes.patientsDevicesNew,
      { id: patient.id },
      query,
    );

    history.push(devicePath);
  };

  const renderButton = () => {
    switch (selectedTab) {
      case 'alerts':
        return (
          <div className="column is-2">
            <SelectGroup
              label="Show"
              options={perPageOptions}
              value={perPage}
              onChange={handlePerPageChange}
            />
          </div>
        );
      case 'manage':
        return (
          <div className="column is-12-mobile is-6-tablet table__button-wrapper">
            <Button color="secondary" onClick={handleAddAlert}>
              Add Alert
            </Button>
          </div>
        );
      case 'devices':
        return (
          <div className="column is-12-mobile is-6-tablet table__button-wrapper">
            <Button color="secondary" onClick={handleAddDevice}>
              Assign Device
            </Button>
          </div>
        );
      case 'recipients':
        return (
          <div className="column is-12-mobile is-6-tablet table__button-wrapper">
            <Button color="secondary" onClick={handleAddRecipient}>
              Add Recipient
            </Button>
          </div>
        );
    }
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'alerts':
        return <Table config={alertsTableConfig} key={selectedTab} />;
      case 'readings':
        return <ReadingsTable />;
      case 'manage':
        return <Table config={manageAlertsTableConfig} key={selectedTab} />;
      case 'devices':
        return (
          <Table
            config={manageDevicesTableConfig}
            key={selectedTab}
            shouldUseModeler={true}
            modelerGenerations={3}
          />
        );
      case 'recipients':
        return <Table config={manageRecipientsTable} key={selectedTab} />;
    }
  };
      // Data passes here through url
  const linkUrl = (() => {
    const queryParams: any = {
      type: reportType,

      bp_data: formatChartData('line', bpChartData),
      bg_data: formatChartData('line', bgChartData),
      bo_data: formatChartData('line', boChartData),
      weight_data: formatChartData('line', weightChartData)
    

    };

    if (reportRange[0]) {
      queryParams.start_at = reportRange[0];
    }

    if (reportRange[1]) {
      queryParams.end_at = reportRange[1];
    }

    return buildPath(Routes.patientRpmReport, { id: patient.id }, queryParams
    );




  })();

  const reportOptions = [
    { label: 'Blood Pressure', value: 'bp' },
    { label: 'Blood Glucose', value: 'bg' },
    { label: 'Blood Oxygen', value: 'bo' },
    { label: 'Weight', value: 'weight' },
  ];




  return (
    <>
      <PatientLayout>
        <Panel>
          <div className="tabs-wrapper with-larger-breakpoint">
            <Tabs
              currentTab={selectedTab}
              onSelectTab={setSelectedTab}
              withFlexWrap>
              <Tab value="alerts">Alerts</Tab>
              <Tab value="readings">Readings</Tab>
              <Tab value="manage">Manage Alerts</Tab>
              <Tab value="devices">Devices</Tab>
              <Tab value="recipients">Recipients</Tab>
            </Tabs>

            <div className="tabs-addition">
              <SelectGroup
                label="Report Type"
                onChange={value => setReportType(value)}
                options={reportOptions}
                skipEmptyPlaceholder
                value={reportType}
              />

              <Datepicker
                label="Report Dates (optional)"
                isRange
                onChange={value => setReportRange(value)}
                value={reportRange}
              />
              <a href={linkUrl} className="button is-secondary" target="_blank">
                View Report
              </a>
            </div>
          </div>
          <RemoteDeviceReadingsGraph
            setBPChartData={setBPChartData}
            setBGChartData={setBGChartData}
            setBOChartData={setBOChartData}
            setWeightChartData={setWeightChartData}
            setStartDate={reportRange[0]}
            setEndDate={reportRange[1]}



          />
          <div className="columns table__per-filter-wrapper">
            {renderButton()}
          </div>

          {renderTabContent()}
        </Panel>
      </PatientLayout>

      <Modal isVisible={isAlertsModalVisible} onCloseModal={closeModal}>
        <AlertsModal alertId={alert_id} />
      </Modal>

      <Modal isVisible={isAlertTriggersModalVisible} onCloseModal={closeModal}>
        <AlertTriggersModal alertTriggerId={alert_trigger_id} />
      </Modal>

      <Modal isVisible={isDevicesModalVisible} onCloseModal={closeModal}>
        <DevicesModal deviceId={device_id} />
      </Modal>

      <Modal isVisible={isRecipientsModalVisible} onCloseModal={closeModal}>
        <AlertRecipientsModal alertRecipientId={alert_recipient_id} />
      </Modal>
    </>
  );
};

export default RemotePatientCareIndex;