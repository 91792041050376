import { HTTPFormMethod } from '@/types/form';
import { buildRequestPath, cleanPath } from './apiUtils';

interface Params {
  [key: string]: string;
}

export const includes = (...incs: string[]): string => incs.join(',');
export const joins = includes;

export const formAction = (id: string | undefined): HTTPFormMethod => {
  return id ? 'PUT' : 'POST';
};

export const buildPath = (
  route: string,
  params: Params = {},
  query: Params = {},
  include?: string[],
) => {
  let formattedPath = route;

  if (params) {
    for (const param of Object.keys(params)) {
      formattedPath = formattedPath.replace(`:${param}`, params[param]);
    }
  }

  const combinedQuery = (() => {
    if (include) {
      return {
        ...query,
        include: include.join(','),
      };
    }

    return query;
  })();

  return `/${buildRequestPath(formattedPath, combinedQuery)}`;
};

export const returnPath = (): string | undefined => {
  return new URLSearchParams(window.location.search).get('return');
};

export const returnTabQuery = (): [Params, string] => {
  const queryParams = new URLSearchParams(location.search || '');
  const returnTab = queryParams.get('return_tab');
  const returnService = queryParams.get('service');

  const query: any = {};

  if (returnTab) {
    query.return_tab = returnTab;
  }

  if (returnService) {
    query.service = returnService;
  }

  return [query, returnTab];
};

const api1 = (path: string): string => {
  return `/api/v1/${cleanPath(path)}`;
};

const api2 = (path: string): string => {
  return `/api/v2/${path}`;
};

export const Routes = {
  root: '/',
  authLogout: '/users/sign_out',
  patientLogout: '/patients/sign_out',
  specialistLogout: '/specialists/sign_out',

  admin: '/admin',
  adminMedications: 'admin/medication_names',
  adminResource: '/admin/:resource',
  adminResourceNew: '/admin/:resource/new',
  adminResourceEdit: '/admin/:resource/:resource_id/edit',

  calls: '/calls',
  callsScheduleNew: '/calls/schedule',

  dashboard: '/dashboard',
  dashboardLive: '/dashboard/live',
  dashboardCareManager: '/dashboard/care_managers/:care_manager_id',

  devices: '/devices',
  devicesShow: '/devices/:id',
  devicesNew: '/devices/new',
  devicesEdit: '/devices/:id/edit',

  healthSystems: '/health_systems',
  healthSystemsShow: '/health_systems/:id',
  healthSystemsNew: '/health_systems/new',
  healthSystemsEdit: '/health_systems/:id/edit',

  hospitals: '/hospitals',
  hospitalsShow: '/hospitals/:id',
  hospitalsNew: '/hospitals/new',
  hospitalsEdit: '/hospitals/:id/edit',
  hospitalsEditHealthSystem: '/hospitals/:id/edit/health_systems',
  hospitalsHealthSystem: '/hospitals/new/health_systems',

  medicationsRequestNew: '/medication_names/new',

  profile: {
    show: '/profile',
    edit: '/profile/edit',
    password: '/profile/password',
    patient: {
      edit: '/profile/patients/edit',
      password: '/profile/patients/password',
    },
    provider: {
      edit: '/profile/providers/edit',
      password: '/profile/providers/password',
    },
  },

  reports: '/reports',

  specialists: '/specialists',
  specialistsEdit: '/specialists/:id/edit',

  users: '/care_managers',
  usersNew: '/care_managers/new',
  usersShow: '/care_managers/:user_id',
  usersEdit: '/care_managers/:user_id/edit',
  send_notification:'/send_notification',
  patients: '/patients',
  patientsShow: '/patients/:id',
  patientsNew: '/patients/new',
  patientsEdit: '/patients/:id/edit',
  patientsQuickAdd: '/patients/new/quick',
  patientRanks: '/patients/ranks/',
  patientEditAll:'/patients_edit_bulk',

  patientsResource: '/patients/:id/resources/:resource',
  patientsCarePlan: '/patients/:id/care_plan',
  patientsCarePlanDownload: '/patients/:id/care_plan/download',

  patientsEnrollmentIndex: '/patients/:id/enrollment/',
  patientsEnrollment: '/patients/:id/enrollment/:step',
  patientsEnrollmentNew: '/patients/:id/enrollment/:step/new',
  patientsEnrollmentStep: '/patients/:id/enrollment/:step/:sub_step',
  patientsEnrollmentEdit: '/patients/:patient_id/enrollment/:step/:id/edit',

  patientsActionHistory: '/patients/:id/action_history',
  patientsActionHistoryShow: '/patients/:id/action_history/:action_log_id',

  patientsAgreements: '/patients/:id/agreements',
  patientsAgreementsSlugs:
    '/patients/:id/(agreements|consent_agreements|condition_complexities)/',
  patientsConsentAgreementsNew: '/patients/:id/consent_agreements/new',
  patientsConditionComplexitiesNew: '/patients/:id/condition_complexities/new',
  patientsConditionComplexitiesShow:
    '/patients/:id/condition_complexities/:condition_complexity_id',

  patientsAllergies: '/patients/:id/allergies',
  patientsAllergiesNew: '/patients/:id/allergies/new',
  patientsAllergiesEdit: '/patients/:patient_id/allergies/:id/edit',

  patientsAppointments: '/patients/:id/appointments',
  patientsAppointmentsNew: '/patients/:id/appointments/new',
  patientsAppointmentsEdit: '/patients/:patient_id/appointments/:id/edit',

  patientsCareManagement: '/patients/:id/care_management',
  patientsCareManagementRpm: '/patients/:id/rpm_management',
  patientsBhiPlan: '/patients/:id/bhi_plan',
  patientsBhiPlanShow: '/patients/:id/bhi_plan/:plan_id',
  patientsCareManagementSlugs:
    '/patients/:id/(care_management|rpm_management|notes|admin_entries)/',

  patientsCallsNew: '/patients/:id/care_management/calls/new',
  patientsCallsSchedule: '/patients/:id/care_management/calls/schedule',
  patientsCallsScheduleEdit:
    '/patients/:patient_id/care_management/calls/schedule/:call_id/edit',
  patientsCallsScheduleRpm: '/patients/:id/rpm_management/calls/schedule',
  patientsCallsScheduleEditRpm:
    '/patients/:patient_id/rpm_management/calls/schedule/:call_id/edit',

  patientsCallsShow: '/patients/:id/care_management/calls/:call_id',
  patientsCallsShowRpm: '/patients/:id/rpm_management/calls/:call_id',
  patientsCallsEdit: '/patients/:patient_id/care_management/calls/:id/edit',

  patientsAdminEntriesShow:
    '/patients/:id/care_management/admin_entries/:admin_entry_id',
  patientsAdminEntriesShowRpm:
    '/patients/:id/rpm_management/admin_entries/:admin_entry_id',
  patientsAdminEntriesEdit: '/patients/:patient_id/admin_entries/:id/edit',
  patientsAdminEntriesNew: '/patients/:id/admin_entries/new',

  patientsContacts: '/patients/:id/contacts',
  patientsContactsNew: '/patients/:id/contacts/new',
  patientsContactsEdit: '/patients/:patient_id/contacts/:id/edit',

  patientsNotesNew: '/patients/:id/notes/new',
  patientsNotesEdit: '/patients/:patient_id/notes/:id/edit',

  patientsImmunizations: '/patients/:id/immunizations',
  patientsImmunizationsNew: '/patients/:id/immunizations/new',
  patientsImmunizationsEdit: '/patients/:patient_id/immunizations/:id/edit',

  patientsLabs: '/patients/:id/labs',
  patientsLabsSlugs: '/patients/:id/(labs|screenings)/',
  patientsLabsNew: '/patients/:id/labs/new',
  patientsLabsEdit: '/patients/:patient_id/labs/:id/edit',
  patientsScreeningsNew: '/patients/:id/screenings/new',
  patientsScreeningsEdit: '/patients/:patient_id/screenings/:id/edit',

  patientsMedicalRecords: '/patients/:id/medical_records',
  patientsMedicalRecordsSlugs:
    '/patients/:id/(medical_records|medical_histories|other_histories)/',
  patientsMedicalHistoryNew: '/patients/:id/medical_histories/new',
  patientsMedicalHistoryEdit:
    '/patients/:patient_id/medical_histories/:id/edit',
  patientsOtherHistoryNew: '/patients/:id/other_histories/new',
  patientsOtherHistoryEdit: '/patients/:patient_id/other_histories/:id/edit',

  patientsMedications: '/patients/:id/medications',
  patientsMedicationsNew: '/patients/:id/medications/new',
  patientsMedicationsEdit: '/patients/:patient_id/medications/:id/edit',

  patientsRemotePatientCare: '/patients/:id/remote_patient_care',
  patientsRemotePatientCareSlugs:
    '/patients/:id/(remote_patient_care|manage_alerts|alert_recipient|devices)/',
  patientsAlertsShow: '/patients/:id/remote_patient_care/:alert_id',
  patientsAlertRecipientsShow:
    '/patients/:id/alert_recipient/:alert_recipient_id',
  patientsAlertRecipientsNew: '/patients/:id/alert_recipient/new',
  patientsAlertRecipientsEdit: '/patients/:patient_id/alert_recipient/:id/edit',
  patientsAlertTriggersShow: '/patients/:id/manage_alerts/:alert_trigger_id',
  patientsAlertTriggersNew: '/patients/:id/manage_alerts/new',
  patientsAlertTriggersEdit: '/patients/:patient_id/manage_alerts/:id/edit',
  patientsAlertTriggersRecipient:
    'patients/:patient_id/manage_alerts/new/recipient',
  patientsEditAlertTriggersRecipient:
    '/patients/:patient_id/manage_alerts/:id/edit/recipient',
  patientsDevicesShow: '/patients/:id/devices/:device_id',
  patientsDevicesNew: '/patients/:id/devices/new',
  patientsDevicesEdit: '/patients/:patient_id/devices/:id/edit',

  patientsSpecialists: '/patients/:id/specialists',
  patientsSpecialistsNew: '/patients/:id/specialists/new',
  patientsSpecialistsEdit: '/patients/:patient_id/specialists/:id/edit',

  practices: '/practices',
  practicesShow: '/practices/:id',
  practicesNew: '/practices/new',
  practicesEdit: '/practices/:id/edit',
  practicesHealthSystem: '/practices/new/health_systems',
  practicesHospital: '/practices/new/hospitals',
  practicesEditHealthSystem: '/practices/:id/edit/health_systems',
  practicesEditHospital: '/practices/:id/edit/hospitals',

  readings: '/patients/:id/readings',
  readingsNew: '/patients/:id/readings/new',
  readingsEdit: '/patients/:id/readings/:reading_id/edit',

  tasksShow: '/patients/:id/tasks/:task_id',
  tasksNew: '/patients/:id/tasks/new',
  tasksCompleted: '/patients/:id/tasks/completed',
  userTaskCompleted:'/:id/tasks/completed',

  rpm: {
    all: '/patients/rpm/all',
    high: '/patients/rpm/high_alerts',
    highCareManagers: '/patients/rpm/cm_high_alerts',
    latest: '/patients/rpm/latest/:reading_type',
    mostCalls: '/patients/rpm/most_calls',
    noReadings: '/patients/rpm/no_readings',
  },

  externalUsers: {
    root: '/users',
    new: '/users/new',
    edit: '/users/:user_id/edit',
  },

  // Patient chart namespace
  chart: {
    root: '/chart',
    calendar: {
      root: '/chart/calendar/',
    },
    providers: {
      root: '/chart/providers',
      show: '/chart/providers/:specialist_id',
    },
    practices: {
      show: '/chart/practices/:practice_id',
    },
    alerts: {
      root: '/chart/alerts',
    },
    appointments: {
      root: '/chart/appointments',
      new: '/chart/appointments/new',
      edit: '/chart/appointments/edit/:appointment_id',
      show: '/chart/appointments/:appointment_id',
    },
  },

  // Provider namespace
  provider: {
    root: '/provider',
    calendar: {
      root: '/provider/calendar',
    },
    appointments: {
      root: '/provider/appointments',
      new: '/provider/appointments/new',
      edit: '/provider/appointments/edit/:appointment_id',
      show: '/provider/appointments/:appointment_id',
    },
    dashboard: '/provider/dashboard',
    patients: {
      root: '/provider/patients',
      show: {
        actionHistory: '/provider/patients/:patient_id/action_history',
        agreement: '/provider/patients/:patient_id/agreement',
        medicalRecords: '/provider/patients/:patient_id/medical_records',
        root: '/provider/patients/:patient_id',
        virtualVisits: {
          edit:
            '/provider/patients/:patient_id/virtual_appointments/:virtual_visit_id/edit',
          new: '/provider/patients/:patient_id/virtual_appointments/new',
          root: '/provider/patients/:patient_id/virtual_appointments',
        },
      },
    },
    alerts: {
      root: '/provider/alerts',
    },
    practice: {
      edit: '/provider/practice/:practice_id/edit',
      show: {
        billing: '/provider/practice/billing',
        calendar: '/provider/practice/calendar',
        provider: '/provider/practice/providers/:provider_id',
        providers: '/provider/practice/providers',
        questionnaires: {
          root: '/provider/practice/questionnaires',
          new: '/provider/practice/questionnaires/new',
          edit: '/provider/practice/questionnaires/:questionnaire_id/edit',
        },
        root: '/provider/practice',
        visits: '/provider/practice/visits',
      },
    },
    questionnaires: {
      new: '/provider/questionnaires/new',
      edit: '/provider/questionnaires/:questionnaire_id',
    },
  },

  // HTML routes
  patientRpmReport: '/patients/:id/rpm_report',

  // API routes
  api: {
    devices: api1('devices'),
    deviceModels: api1('device_models'),
    deviceNames: api1('device_names'),
    deviceMakes: api1('device_makes'),
    deviceTypes: api1('device_types'),
    deviceCategories: api1('device_categories'),
    healthSystems: api1('health_systems'),
    hospitals: api1('hospitals'),
    patient: api1('patients/:id'),
    patients: api1('patients'),
    practice: api1('practices/:id'),
    practices: api1('practices'),
    specialists: api1('specialists'),
    voicemails: api1('voicemails'),
    patientsNotification: api1('patient/send_notification')
  },

  api2: {
    adminUser: api2('admin_users/:id'),
    adminUsers: api2('admin_users'),
    calls: api2('calls'),
    careManagers: api2('care_managers'),
    dashboards: api2('dashboards'),
    deviceReading: api2('device_readings/:id'),
    deviceReadings: api2('device_readings'),
    deviceReadingsDownload: api2('device_readings/download'),
    devices: api2('devices'),
    device: api2('devices/:id'),
    featureFlags: api2('feature_flags'),
    messages: api2('messages'),
    missedCalls: api2('missed_calls'),
    notificationConfiguration: api2('notification_configurations/:id'),
    notifications: api2('notifications'),
    patient: api2('patients/:id'),
    patients: api2('patients'),
    patientDevice: api2('patient_devices/:id'),
    patientDevices: api2('patient_devices'),
    practice: api2('practices/:id'),
    questionnaire: api2('questionnaires/:id'),
    questionnaires: api2('questionnaires'),
    readingThresholds: api2('reading_thresholds'),
    specialist: api2('specialists/:id'),
    specialists: api2('specialists'),
    videoCalls: api2('video_calls'),
    virtualAppointment: api2('virtual_appointments/:id'),
    virtualAppointments: api2('virtual_appointments'),
    virtualAppointmentQuestionnaire: api2(
      'virtual_appointment_questionnaires/:id',
    ),
    virtualAppointmentQuestionnaires: api2(
      'virtual_appointment_questionnaires',
    ),
    task: api2('tasks/:id'),
    tasks: api2('tasks')
  },
};
