import { UUID } from '@/types/generic';
import { MessageType } from '@/types/v2/message';
import { usePrevious } from '@/utils/stateUtils';
import React, { useEffect, useRef } from 'react';
import Bubble from './Bubble';

export type MessageAuthor = 'receiver' | 'sender';

export interface ChatMessage {
  author: MessageAuthor;
  downloadLink?: string;
  fileName?: string;
  id: UUID;
  text: string;
  type: MessageType;
}

interface Props {
  messages: ChatMessage[];
}

const Conversation = ({ messages }: Props): JSX.Element => {
  const chatRef = useRef<HTMLDivElement>(null);
  const prevCount = usePrevious(messages.length);

  // Scroll to bottom of chat list whenever a new chat is added
  useEffect(() => {
    const el = chatRef.current;
    el.scrollTop = el.scrollHeight;
  }, [prevCount]);

  return (
    <div className="chat-conversation" ref={chatRef}>
      {messages.map(message => (
        <Bubble key={message.id} {...message} />
      ))}
    </div>
  );
};

export default Conversation;
