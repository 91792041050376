import React, { useEffect, useMemo, useState } from 'react';

import Container from '@/components/container/Container';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Panel from '@/components/panel/Panel';
import {
  selectCurrentUserRole,
  selectIsCurrentUserSuperAdmin,
} from '@/selectors/users';
import { SideEffect, UUID } from '@/types/generic';
import {
  canUserEditRole,
  Level,
  PermissibleType,
  permissionLevelOptions,
  userPermissionLevelOptions,
} from '@/types/permission';
import { updateArrayIndex } from '@/utils/arrayUtils';
import { useSelector } from 'react-redux';
import Permission from './Permission';

interface Selection {
  label: string;
  level: string;
  type: string;
  value: string;
}
export interface Role {
  level: Level;
  ids: UUID[];
  selections?: Selection[];
}

interface Props {
  isDisabled?: boolean;
  onChange: SideEffect;
  role: Role;
  selectedIds: UUID[];
}

const RolePanel = (props: Props) => {
  const { isDisabled, role, onChange, selectedIds } = props;

  const userRole = useSelector(selectCurrentUserRole);
  const isUserSuperAdmin = useSelector(selectIsCurrentUserSuperAdmin);

  const getSelection = (type: PermissibleType) => {
    if (role.selections) {
      const selections = role.selections.filter(
        (s: Selection) => s.type === type,
      );

      return {
        selections,
        ids: selections.map(s => s.value),
      };
    }

    return {
      selections: [null],
      ids: [''],
    };
  };

  const initialHealthSystems = getSelection('HealthSystem');
  const initialHospitals = getSelection('Hospital');
  const initialPractices = getSelection('Practice');

  const [level, setLevel] = useState<Level>(role.level || 'standard');
  const [healthSystemIds, setHealthSystemIds] = useState<UUID[]>(
    initialHealthSystems.ids,
  );
  const [hospitalIds, setHospitalIds] = useState<UUID[]>(initialHospitals.ids);
  const [practiceIds, setPracticeIds] = useState<UUID[]>(initialPractices.ids);

  useEffect(() => {
    deliverRole();
  }, [healthSystemIds, hospitalIds, level, practiceIds]);

  const deliverRole = () => {
    const filteredIds = [
      ...healthSystemIds,
      ...hospitalIds,
      ...practiceIds,
    ].filter(n => n);
    const uniqueIds = [...new Set(filteredIds)];

    const formattedRole = {
      level,
      ids: uniqueIds,
    };

    onChange(formattedRole);
  };

  const formatSearchResult = (result: any) => ({
    label: result.attributes.name,
    value: result.id,
  });

  const handleChangeLevel = (value: Level) => {
    setLevel(value);
  };

  const handleAddHealthSystem = () => {
    if (!isDisabled) {
      setHealthSystemIds([...healthSystemIds, '']);
    }
  };

  const handleAddHospital = () => {
    if (!isDisabled) {
      setHospitalIds([...hospitalIds, '']);
    }
  };

  const handleAddPractice = () => {
    if (!isDisabled) {
      setPracticeIds([...practiceIds, '']);
    }
  };

  const handleUpdateHealthSystems = (index: number, id: UUID) => {
    setHealthSystemIds(updateArrayIndex(healthSystemIds, index, id));
  };

  const handleUpdateHospitals = (index: number, id: UUID) => {
    setHospitalIds(updateArrayIndex(hospitalIds, index, id));
  };

  const handleUpdatePractices = (index: number, id: UUID) => {
    setPracticeIds(updateArrayIndex(practiceIds, index, id));
  };

  const renderPermissionLevelOptions = useMemo(() => {
    if (selectedIds && !canUserEditRole(userRole, level)) {
      return permissionLevelOptions;
    }

    return userPermissionLevelOptions(userRole);
  }, [level]);

  const renderHealthSystems = () => {
    const healthSystems = healthSystemIds.map((id: UUID, index: number) => {
      return (
        <Permission
          isDisabled={isDisabled}
          key={index}
          formatResult={formatSearchResult}
          ignoreValues={selectedIds}
          initialSelection={initialHealthSystems.selections[index]}
          label="Health System"
          onChange={(value: UUID) => handleUpdateHealthSystems(index, value)}
          placeholder="Search Health Systems"
          searchPath="api/v1/health_systems"
        />
      );
    });

    return (
      <>
        {healthSystems}

        <div className="columns">
          <div className="column is-4">
            <a
              className="permissions__add-link"
              onClick={handleAddHealthSystem}>
              Add Health System
            </a>
          </div>
        </div>
      </>
    );
  };

  const renderHospitals = () => {
    const hospitals = hospitalIds.map((id: UUID, index: number) => {
      return (
        <Permission
          isDisabled={isDisabled}
          key={index}
          formatResult={formatSearchResult}
          ignoreValues={selectedIds}
          initialSelection={initialHospitals.selections[index]}
          label="Hospital"
          onChange={(value: UUID) => handleUpdateHospitals(index, value)}
          placeholder="Search Hospitals"
          searchPath="api/v1/hospitals"
        />
      );
    });

    return (
      <>
        {hospitals}

        <div className="columns">
          <div className="column is-4">
            <a className="permissions__add-link" onClick={handleAddHospital}>
              Add Hospital
            </a>
          </div>
        </div>
      </>
    );
  };

  const renderPractices = () => {
    const practices = practiceIds.map((id: UUID, index: number) => {
      return (
        <Permission
          isDisabled={isDisabled}
          key={index}
          formatResult={formatSearchResult}
          ignoreValues={selectedIds}
          initialSelection={initialPractices.selections[index]}
          label="Practice"
          onChange={(value: UUID) => handleUpdatePractices(index, value)}
          placeholder="Search Practices"
          searchPath="api/v1/practices"
        />
      );
    });

    return (
      <>
        {practices}

        <div className="columns">
          <div className="column is-4">
            <a className="permissions__add-link" onClick={handleAddPractice}>
              Add Practice
            </a>
          </div>
        </div>
      </>
    );
  };

  return (
    <Panel>
      <Container>
        <Section title="Role and Permissions">
          <div className="columns">
            <div className="column is-4">
              <SelectGroup
                isDisabled={isDisabled}
                label="Role"
                onChange={handleChangeLevel}
                options={renderPermissionLevelOptions}
                skipEmptyPlaceholder
                value={level}
              />
            </div>
          </div>

          {renderHealthSystems()}
          {renderHospitals()}
          {renderPractices()}
        </Section>
      </Container>
    </Panel>
  );
};

export default RolePanel;
