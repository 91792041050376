import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { buildPath, Routes } from '@/utils/routeUtils';
import { modeledLongName } from '@/utils/patientUtils';

import Container from '@/components/container/Container';
import ManageFilterHeader from '@/components/filters/ManageFilterHeader';
import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';

const SEARCH_PATH = Routes.api2.devices;

const DevicesIndex = () => {
  const history = useHistory();

  const [perPage, setPerPage] = useState<number>(24);
  const [searchInput, setSearchInput] = useState<string>('');
  const [queryOptions, setQueryOptions] = useState<object>({
    search: '',
    orphan_status: '',
    context: 'DevicesIndex'
  });

  const handlePerPageChange = (value: number) => {
    setPerPage(value);
  };

  const handleAddDevice = () => {
    const path = buildPath(Routes.devicesNew);
    history.push(path);
  };

  const handleQueryChange = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleSearchChange = (value: string) => {
    setSearchInput(value);
  };

  const handleSearchInput = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const tableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      ...queryOptions,
      per: perPage,
    },
    columns: [
      {
        header: 'Device Model',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'device_model_id',
        className: 'table__cell-highlight',
        width: 1,
        formatEntry: entry => {
          return entry.device_model.name || null;
        },
      },
      {
        header: 'Serial Number',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'serial_number',
        width: 2,
      },
      {
        header: 'Patient',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'patient_id',
        width: 2,
        formatEntry: entry => {
          const { patient } = entry;
          if (patient) {
            return modeledLongName(patient);
          } else return null;
        },
      },
      {
        header: 'Box Number',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'box_number',
        width: 2,
      },
      {
        header: 'Sim Card Number',
        isVisibleMobile: true,
        attribute: 'sim_number',
        width: 2,
      },
      {
        header: 'Shipping Invoice',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'shipping_invoice',
        width: 2,
      },
    ],
    rows: {
      editPath: buildPath(Routes.devicesEdit),
    },
  };

  return (
    <Panel>
      <Container>
        <ManageFilterHeader
          buttonText="Add Device"
          onAddClick={handleAddDevice}
          onPerPageChange={handlePerPageChange}
          queryOptions={queryOptions}
          onQueryChange={handleQueryChange}
          onSearchChange={handleSearchChange}
          onSearchClick={handleSearchInput}
          perPage={perPage}
          searchValue={searchInput}
          title="Devices"
          isDevicesHeader={true}
        />
        <Table config={tableConfig} />
      </Container>
    </Panel>
  );
};

export default DevicesIndex;
