import React from 'react';
import { useParams } from 'react-router-dom';

import BackLink from '@/components/button/BackLink';
import Container from '@/components/container/Container';
import PatientHeader from '@/contexts/patients/components/PatientHeader';
import AllergiesEdit from '@/contexts/patients/modules/allergies/AllergiesEdit';
import ContactsEdit from '@/contexts/patients/modules/contacts/ContactsEdit';
import MedicalHistoryEdit from '@/contexts/patients/modules/medical_records/MedicalHistoryEdit';
import OtherHistoryEdit from '@/contexts/patients/modules/medical_records/OtherHistoryEdit';
import MedicationsEdit from '@/contexts/patients/modules/medications/MedicationsEdit';
import SpecialistsEdit from '@/contexts/patients/modules/specialists/SpecialistsEdit';
import { buildPath, Routes } from '@/utils/routeUtils';
import EnrollmentBanner from '../components/EnrollmentBanner';
import { UUID } from '@/types/generic';

interface Params {
  id: UUID;
  step: string;
}

const EnrollmentEdit = () => {
  const { id, step } = useParams<Params>();

  const backPath = buildPath(Routes.patientsCareManagement, { id });

  const forms = {
    allergies: AllergiesEdit,
    contacts: ContactsEdit,
    family_history: OtherHistoryEdit,
    medical_history: MedicalHistoryEdit,
    medications: MedicationsEdit,
    social_history: OtherHistoryEdit,
    support: SpecialistsEdit,
    surgical_history: OtherHistoryEdit,
  };

  const Component = forms[step];

  return (
    <>
      <Container>
        <BackLink to={backPath}>Back to Patient</BackLink>
      </Container>
      <PatientHeader />
      <EnrollmentBanner />
      <Component />
    </>
  );
};

export default EnrollmentEdit;
