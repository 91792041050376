import React from 'react';

import { EmptyBlock, StatBlock } from '@/components/dashboard';
import { BlockStyle, StatBlockItem } from '@/components/dashboard/types';
import {
  DeviceReading,
  deviceReadingValues,
  valueLabel,
} from '@/types/v2/device_reading';
import { buildPath, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  deviceReading: DeviceReading;
  emptyText?: string;
  style: BlockStyle;
}

const ReadingBlock = ({
  deviceReading,
  emptyText = 'No readings',
  style,
}: Props): JSX.Element => {
  if (!deviceReading) {
    return <EmptyBlock style="outlined">{emptyText}</EmptyBlock>;
  }
  const patientUrl = buildPath(Routes.patientsShow, {
    id: deviceReading.patient.id,
  });

  const stats: StatBlockItem[] = deviceReadingValues
    .filter(value => deviceReading[value] !== null)
    .map(value => ({
      label: valueLabel(value, deviceReading.reading_label),
      value: deviceReading[value],
    }));

  return (
    <StatBlock
      date={deviceReading.reading_time}
      level={deviceReading.alert_level}
      linkTo={patientUrl}
      stats={stats}
      style={style}
      title={fullName(deviceReading.patient)}
      type={style === 'outlined' ? deviceReading.reading_label : null}
    />
  );
};

export default ReadingBlock;
