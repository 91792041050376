import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setUnreadNotifications } from '@/actions/reducers/users';
import Constraint from '@/components/constraint/Constraint';
import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';
import Dot from '@/components/table/Dot';
import { selectCurrentUserId } from '@/selectors/users';
import { UUID } from '@/types/generic';
import { TableConfig } from '@/types/table';
import { Notification } from '@/types/v2/notification';
import { formatShortDateTime } from '@/utils/dateUtils';
import { Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';

const Alerts = () => {
  const userId: UUID = useSelector(selectCurrentUserId);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUnreadNotifications(0));
  }, []);

  const config: TableConfig = {
    searchPath: Routes.api2.notifications,
    emptyState: 'No notifications',
    initialSort: 'created_at:desc',
    searchQuery: {
      markAsRead: true,
      notifiable_id: userId,
    },
    columns: [
      {
        header: 'Timestamp',
        attribute: 'created_at',
        formatAttribute: formatShortDateTime,
        isVisibleMobile: true,
        width: 2,
      },
      {
        header: 'Notification',
        attribute: 'notification_type',
        formatAttribute: titleizeSnakeCase,
        isVisibleMobile: true,
        width: 5,
      },
      {
        isVisibleMobile: true,
        formatEntry: (entry: Notification) => {
          if (entry.status === 'unread') {
            return <Dot />;
          }
        },
        width: 1,
      },
    ],
  };

  return (
    <Panel>
      <Constraint>
        <Table config={config} shouldUseModeler />
      </Constraint>
    </Panel>
  );
};

export default Alerts;
