import React from 'react';

import AudioPlayer from '@/components/audio/AudioPlayer';
import Panel from '@/components/panel/Panel';
import { UUID } from '@/types/generic';
import { Voicemail } from '@/types/voicemail';
import { formatShortDateTime } from '@/utils/dateUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  onClickDelete: (id: UUID) => void;
  onNoDuration?: (id: UUID) => void;
  voicemail: Voicemail;
}

const UserVoicemail = (props: Props) => {
  const { voicemail, onClickDelete, onNoDuration } = props;

  const handleClickDelete = () => {
    onClickDelete(voicemail.id);
  };

  const patientName = () => {
    if (voicemail.patient) {
      return fullName(voicemail.patient);
    }

    return 'Unknown';
  };

  return (
    <Panel>
      <div className="voicemail">
        <div className="columns is-multiline is-mobile">
          <div className="column is-6-mobile is-2-tablet">
            <h4>Caller</h4>
            <p>{patientName()}</p>
          </div>

          <div className="column is-6-mobile is-2-tablet">
            <h4>Call Time</h4>
            <p>{formatShortDateTime(voicemail.created_at)}</p>
          </div>

          <div className="column is-12-mobile is-8-tablet">
            <AudioPlayer
              isSmall
              onNoDuration={() => onNoDuration(voicemail.id)}
              url={voicemail.recording_url}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column has-no-vertical-padding">
            <a onClick={handleClickDelete}>Delete</a>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default UserVoicemail;
