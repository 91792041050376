import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import { formatSeconds } from '@/utils/timeUtils';

interface Props {
  onNoDuration?: () => void;
  isSmall?: boolean;
  url: string;
}

const AudioPlayer = (props: Props) => {
  const { isSmall, onNoDuration, url } = props;

  const audioPlayer = useRef(null);
  const progressBar = useRef(null);

  const [duration, setDuration] = useState(null);
  const [progessValue, setProgressValue] = useState(0);
  const [timeStamp, setTimeStamp] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);

  const handleClickProgress = e => {
    const box = progressBar.current.getBoundingClientRect();

    const percent =
      ((e.clientX - box.left) / (box.right - box.left)) * duration;

    audioPlayer.current.currentTime = percent;
  };

  // Handler must be async because media.play() and media.pause() return promises and a user
  // could potentially click "pause" before the media has completed the "play" action
  const handleOnPlayClick = async () => {
    setIsTransitioning(true);

    if (isPlaying) {
      await audioPlayer.current.pause();

      setIsPlaying(false);
      setIsTransitioning(false);
    } else {
      await audioPlayer.current.play();

      setIsPlaying(true);
      setIsTransitioning(false);
    }
  };

  const handleResetAudio = () => {
    setIsPlaying(false);
    setProgressValue(0);
    audioPlayer.current.currentTime = 0;
  };

  const renderIcon = () => {
    if (isPlaying) {
      return (
        <>
          <span />
          <span />
        </>
      );
    } else {
      return <div />;
    }
  };

  const handleLoadedAudioData = () => {
    const formattedDuration = Math.round(audioPlayer.current.duration);
    const formattedTimeStamp = Math.round(audioPlayer.current.currentTime);

    if (formattedDuration === Infinity) {
      setDuration(0);
      setTimeStamp(0);

      if (onNoDuration) {
        onNoDuration();
      }
    } else {
      setDuration(formattedDuration);
      setTimeStamp(formattedTimeStamp);
    }
  };

  const handleProgress = event => {
    const percent = Math.floor(
      (audioPlayer.current.currentTime / duration) * 100,
    );
    const formattedTimeStamp = Math.round(audioPlayer.current.currentTime);

    setTimeStamp(formattedTimeStamp);
    setProgressValue(percent);

    if (audioPlayer.current.currentTime >= duration) {
      setIsPlaying(false);
      setProgressValue(0);
      setTimeStamp(0);
    }
  };

  if (duration === 0) {
    return null;
  }

  return (
    <div
      className={classNames('audio-player', {
        'is-small': isSmall,
      })}>
      <audio
        ref={audioPlayer}
        onEnded={handleResetAudio}
        onLoadedMetadata={handleLoadedAudioData}
        onTimeUpdate={event => handleProgress(event)}>
        <source src={url} />
      </audio>

      <button
        className="audio-player__pause-button"
        disabled={isTransitioning}
        onClick={handleOnPlayClick}>
        {renderIcon()}
      </button>

      <div className="audio-player__progress">
        <progress
          className={classNames({
            'is-small': isSmall,
          })}
          max={100}
          onClick={handleClickProgress}
          ref={progressBar}
          value={progessValue}
        />
      </div>

      <p className="audio-player__time">
        {formatSeconds(timeStamp)}/{formatSeconds(duration || 0)}
      </p>
    </div>
  );
};

export default AudioPlayer;
