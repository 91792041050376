import { Message } from '@/types/v2/message';

export enum MessagesActions {
  SET_MESSAGES = 'MESSAGES/SET_MESSAGES',
  ADD_MESSAGE = 'MESSAGES/ADD_MESSAGE',
}

export interface SetMessages {
  type: typeof MessagesActions.SET_MESSAGES;
  payload: {
    messages: Message[];
  };
}

export interface AddMessage {
  type: typeof MessagesActions.ADD_MESSAGE;
  payload: {
    message: Message;
  };
}

export type MessagesAction = SetMessages | AddMessage;

export const setMessages = (messages: Message[]): MessagesAction => ({
  type: MessagesActions.SET_MESSAGES,
  payload: { messages },
});

export const addMessage = (message: Message): MessagesAction => ({
  type: MessagesActions.ADD_MESSAGE,
  payload: { message },
});
