import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Modal from '@/components/modal/Modal';
import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { Tab, Tabs } from '@/components/tabs';
import { getPatientIncludedData, getSelectedPatient } from '@/selectors/api';
import { TableConfig } from '@/types/table';
import { formatShortDate } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { fullName } from '@/utils/userUtils';
import { useHistory, useParams } from 'react-router-dom';
import PatientLayout from '../../components/PatientLayout';
import ActionHistoryModal from './ActionHistoryModal';

const SEARCH_PATH = 'api/v1/action_logs';

interface Props {
  basePath?: string;
  shouldSkipLayout?: boolean;
}

interface Params {
  action_log_id: string;
}

const ActionHistoryIndex = ({ basePath, shouldSkipLayout }: Props) => {
  const history = useHistory();
  const included = useSelector(getPatientIncludedData);
  const patient = useSelector(getSelectedPatient);

  // Originally we were passing the log id as a URL param. This turned out to be difficult to use.
  // Now that the component is used inother places it's easier to transition to using query params instead.
  const { action_log_id } = useParams<Params>();

  const queryParams = new URLSearchParams(location.search || '');
  const actionLog = queryParams.get('log');

  const logId = action_log_id || actionLog;

  const [creatorName, setCreatorName] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>('actions');

  useEffect(() => {
    const creatorId = patient.relationships.creator.data.id;
    const creator = included.find(record => record.id === creatorId);

    if (creator) {
      setCreatorName(fullName(creator));
    }
  });

  const isActionHistoryModalVisible = !!logId;

  const closeModal = () => {
    const path =
      basePath || buildPath(Routes.patientsActionHistory, { id: patient.id });
    history.push(path);
  };

  const viewPath = (() => {
    if (basePath) {
      return buildPath(basePath, null, { log: ':action_log_id' });
    }

    return buildPath(Routes.patientsActionHistoryShow, {
      id: patient.id,
    });
  })();

  const tableConfig: TableConfig = {
    initialSort: 'date:desc',
    searchPath: SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
    },
    emptyState: 'No action history added yet',
    columns: [
      {
        header: 'Action Type',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'action_type',
        formatAttribute: titleizeSnakeCase,
      },
      {
        header: 'Action By',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'action_type',
        formatEntry: (entry, entryIncluded = []) => {
          const userId = entry.relationships.user.data.id;
          const user = entryIncluded.find(include => include.id === userId);

          return fullName(user);
        },
      },
      {
        header: 'Patient By',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'creator',
        formatEntry: () => {
          return creatorName;
        },
      },
      {
        header: 'Action Date',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'date',
        formatAttribute: formatShortDate,
      },
    ],
    rows: {
      viewId: 'action_log_id',
      viewPath,
    },
  };

  const renderContent = (() => (
    <Panel>
      <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
        <Tab value="actions">Action History</Tab>
      </Tabs>

      <Table config={tableConfig} />
    </Panel>
  ))();

  const renderModal = (() => (
    <Modal isVisible={isActionHistoryModalVisible} onCloseModal={closeModal}>
      <ActionHistoryModal actionHistoryId={logId} patient={patient} />
    </Modal>
  ))();

  if (shouldSkipLayout) {
    return (
      <>
        {renderContent}
        {renderModal}
      </>
    );
  }

  return (
    <>
      <PatientLayout>{renderContent}</PatientLayout>

      {renderModal}
    </>
  );
};

export default ActionHistoryIndex;
