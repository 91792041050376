import React from 'react';

import { formatPhoneNumberProgress } from '@/utils/phoneUtils';

interface Props {
  formatDigits?: boolean;
  value: string;
}

const KeypadDisplay = (props: Props) => {
  const { formatDigits, value } = props;

  return (
    <div className="keypad__display">
      <span>{formatDigits ? formatPhoneNumberProgress(value) : value}</span>
    </div>
  );
};

export default KeypadDisplay;
