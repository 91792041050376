import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import ModalContent from '@/components/modal/ModalContent';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { formatShortDate } from '@/utils/dateUtils';
import { breakDuration, formatTime } from '@/utils/timeUtils';

interface Props {
  conditionComplexityId: string;
  patientId: string;
}

const ConditionComplexityModal = (props: Props) => {
  const { conditionComplexityId, patientId } = props;

  const dispatch = useDispatch();

  const [conditionComplexity, setConditionComplexity] = useState(null);

  const [getConditionComplexity, isLoading] = useGetRequest({
    dispatch,
    id: conditionComplexityId,
    model: 'condition_complexities',
    onSuccess: (response: any) => {
      setConditionComplexity(parseResponseData(response));
    },
  });

  useEffect(() => {
    getConditionComplexity();
  }, []);

  if (!conditionComplexity || isLoading) {
    return <ActivityIndicator />;
  }

  const attributes = conditionComplexity.attributes;

  const [hours, minutes] = breakDuration(attributes.duration);

  const cptCodes = attributes.cpt_codes.map(code => code.code).join(', ');

  return (
    <div className="patient-calls__modal-wrapper">
      <h3 className="patient-calls__modal-call-header">Condition Complexity</h3>

      <div className="columns">
        <div className="column is-3">
          <ModalContent label="Date" value={formatShortDate(attributes.date)} />
        </div>

        <div className="column is-9">
          <ModalContent label="Time" value={formatTime(attributes.time)} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <ModalContent label="Provider" value={attributes.specialist.name} />
        </div>
      </div>

      <div className="columns">
        <div className="column is-3">
          <ModalContent label="Credentials" value={attributes.credentials} />
        </div>

        <div className="column is-9">
          <ModalContent label="CPT Codes" value={cptCodes} />
        </div>
      </div>

      <div className="columns">
        <div className="column is-3">
          <ModalContent label="Hours" value={hours.toString()} />
        </div>

        <div className="column is-9">
          <ModalContent label="Minutes" value={minutes.toString()} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <ModalContent label="Decision Comments" value={attributes.comments} />
        </div>
      </div>
    </div>
  );
};

export default ConditionComplexityModal;
