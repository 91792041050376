import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import CheckboxGroup from '@/components/form/CheckboxGroup';
import Panel from '@/components/panel/Panel';
import { NotificationConfiguration } from '@/types/v2/notification_configuration';
import { Patient } from '@/types/v2/patient';
import { formatCityStateZip } from '@/utils/addressUtils';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  user: any;
  passwordUrl: string;
}

const PatientProfileDisplay = (props: Props) => {
  const { passwordUrl, user } = props;

  const loader = useLoadingState('patient');
  const notifier = useNotifier();

  const [patient, setPatient] = useState<Patient>(null);
  const [notificationConfiguration, setNotificationConfiguration] = useState<
    NotificationConfiguration
  >(null);

  const getPatient = async () => {
    loader.startLoading('patient');

    try {
      const url = buildPath(
        Routes.api2.patient,
        {
          id: user.data.id,
        },
        null,
        ['address', 'notification_configuration'],
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<Patient>(response.data).build();

      setPatient(resource);
      setNotificationConfiguration(resource.notification_configuration);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('patient');
  };

  useEffect(() => {
    if (user) {
      getPatient();
    }
  }, []);

  const handleToggleNotificationConfiguration = (
    field: string,
    value: boolean,
  ) => {
    setNotificationConfiguration({
      ...notificationConfiguration,
      [field]: value,
    });
  };

  const handleUpdateNotificationConfiguration = async () => {
    try {
      const url = buildPath(Routes.api2.notificationConfiguration, {
        id: notificationConfiguration.id,
      });

      const body = {
        notification_configuration: {
          appointment_cancelled:
            notificationConfiguration.appointment_cancelled,
          appointment_confirmed:
            notificationConfiguration.appointment_confirmed,
          appointment_requested:
            notificationConfiguration.appointment_requested,
          appointment_rescheduled:
            notificationConfiguration.appointment_rescheduled,
        },
      };

      const response = await Api.utility.patch(url, body);
      const resource = new Modeler<NotificationConfiguration>(
        response.data,
      ).build();

      setNotificationConfiguration(resource);

      notifier.success('Notification Preferences Updated');
    } catch (err) {
      notifier.error(err);
    }
  };

  const renderAddress = () => {
    if (!patient.address) {
      return null;
    }

    return (
      <div className="profile-display__section">
        <span className="profile-display__details-text">
          {patient.address.line_1}
        </span>
        {patient.address.line_1 && (
          <span className="profile-display__details-text">
            {patient.address.line_2}
          </span>
        )}
        <span className="profile-display__details-text">
          {formatCityStateZip(patient.address)}
        </span>
      </div>
    );
  };

  if (loader.areAnyLoading || !patient) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <Panel>
        <Container>
          <Constraint size="medium">
            <div className="profile-display">
              <h2>{fullName(patient)}</h2>

              <div className="profile-display__section">
                <span className="profile-display__details-text">
                  {patient.email}
                </span>
                <span className="profile-display__details-text">
                  {formatPhoneNumber(patient.phone_number)}
                </span>
              </div>

              {renderAddress()}

              <Link to={Routes.profile.patient.edit}>Edit Profile</Link>
            </div>
          </Constraint>
        </Container>
      </Panel>

      <div className="columns">
        <div className="column">
          <Constraint size="medium">
            <div className="is-justified-right">
              <a href={passwordUrl}>Reset Password</a>
            </div>
          </Constraint>
        </div>
      </div>

      <Panel>
        <Container>
          <Constraint size="medium">
            <div className="profile-display">
              <h4>Email Notifications</h4>
              <p>I would like to receive an email when...</p>

              <div className="profile-display__notifications-container">
                <div className="profile-display__checkbox-container">
                  <CheckboxGroup
                    label="a new appointment is requested"
                    onChange={() =>
                      handleToggleNotificationConfiguration(
                        'appointment_requested',
                        !notificationConfiguration.appointment_requested,
                      )
                    }
                    value={notificationConfiguration.appointment_requested}
                  />
                </div>

                <div className="profile-display__checkbox-container">
                  <CheckboxGroup
                    label="an appointment I requested has been confirmed"
                    onChange={() =>
                      handleToggleNotificationConfiguration(
                        'appointment_confirmed',
                        !notificationConfiguration.appointment_confirmed,
                      )
                    }
                    value={notificationConfiguration.appointment_confirmed}
                  />
                </div>

                <div className="profile-display__checkbox-container">
                  <CheckboxGroup
                    label="an appointment has been cancelled"
                    onChange={() =>
                      handleToggleNotificationConfiguration(
                        'appointment_cancelled',
                        !notificationConfiguration.appointment_cancelled,
                      )
                    }
                    value={notificationConfiguration.appointment_cancelled}
                  />
                </div>

                <div className="profile-display__checkbox-container">
                  <CheckboxGroup
                    label="a provider reschedules or makes changes to my appointment"
                    onChange={() =>
                      handleToggleNotificationConfiguration(
                        'appointment_rescheduled',
                        !notificationConfiguration.appointment_rescheduled,
                      )
                    }
                    value={notificationConfiguration.appointment_rescheduled}
                  />
                </div>
              </div>

              <div className="profile-display__update-container">
                <Button
                  color="secondary"
                  onClick={handleUpdateNotificationConfiguration}>
                  Update
                </Button>
              </div>
            </div>
          </Constraint>
        </Container>
      </Panel>
    </>
  );
};

export default PatientProfileDisplay;
