import React, { useMemo } from 'react';

import Container from '@/components/container/Container';
import { selectCurrentUserRole } from '@/selectors/users';
import { SideEffect, UUID } from '@/types/generic';
import { canUserEditRole } from '@/types/permission';
import { useSelector } from 'react-redux';
import RolePanel, { Role } from './RolePanel';

interface Props {
  onClickAddRole: SideEffect;
  onUpdateRole: SideEffect;
  roles: Role[];
}

const RoleAndPermissions = (props: Props) => {
  const { onClickAddRole, onUpdateRole, roles } = props;

  const userRole = useSelector(selectCurrentUserRole);

  const handleUpdateRole = (index: number, newRole: Role) => {
    onUpdateRole(index, newRole);
  };

  const allRecordIds = useMemo(() => {
    const reducer = (accumulator: UUID[], currentValue: Role) =>
      accumulator.concat(currentValue.ids);
    return roles.reduce(reducer, []);
  }, [roles]);

  const renderRolePanels = () => {
    return roles.map((role: Role, index: number) => {
      const canUserEditRoles = canUserEditRole(userRole, role.level);

      return (
        <RolePanel
          isDisabled={!canUserEditRoles}
          key={index}
          onChange={(newRole: Role) => handleUpdateRole(index, newRole)}
          role={role}
          selectedIds={allRecordIds}
        />
      );
    });
  };

  return (
    <>
      {renderRolePanels()}

      <Container>
        <a className="permissions__add-link" onClick={onClickAddRole}>
          Add Role
        </a>
      </Container>
    </>
  );
};

export default RoleAndPermissions;
