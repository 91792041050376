import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getIcdCodeOptions } from '@/selectors/options';
import { FormComponentConfig } from '@/types/form';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useSelector } from 'react-redux';
import PatientForm from '../../components/PatientForm';
import { isEnrollmentPath } from '../../utils/patientUtils';
import { validateBeforeSubmit } from './utils';
import { IdParams } from '@/types/params';

interface Params extends IdParams {
  step: string;
}

const MedicalHistoriesNew = () => {
  const history = useHistory();

  const { id, step } = useParams<Params>();

  const icdCodeOptions = useSelector(getIcdCodeOptions);

  const isEnrollment = isEnrollmentPath();

  const query = { return_tab: 'medical' };

  const backPath = isEnrollment
    ? buildPath(Routes.patientsEnrollment, { id, step })
    : buildPath(Routes.patientsMedicalRecords, { id }, query);

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/medical_histories',
      model: 'medical_history',
      mergeFields: {
        patient_id: id,
      },
      method: 'POST',
      onSuccess: () => history.push(backPath),
      onCancel: () => history.push(backPath),
      validateBeforeSubmit,
    },
    state: {
      icd_code: '',
      start_date: '',
      end_date: '',
      comments: '',
    },
    rules: {
      start_date: { required: true },
      end_date: { required: true },
    },
    layout: [
      {
        title: 'Add Medical History',
        fields: [
          [
            {
              label: 'ICD Code',
              field: 'icd_code',
              placeholder: 'Search for ICD code',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/icd_codes',
              size: 4,
              formatTypeaheadResult: (result: any) => {
                return {
                  label: result.attributes.code,
                  value: result.attributes.code,
                };
              },
            },
          ],
          [
            {
              label: 'Start Date',
              field: 'start_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
            {
              label: 'End Date',
              field: 'end_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
          ],
          [
            {
              label: 'Comment',
              field: 'comments',
              placeholder: 'Enter comment',
              type: 'text',
              size: 8,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Medical Records"
      config={config}
    />
  );
};

export default MedicalHistoriesNew;
