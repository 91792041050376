import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  getAdminDashboard,
  toggleDashboardCollapse,
} from '@/actions/actions/rpm';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import Constraint from '@/components/constraint/Constraint';
import {
  DashboardHeader,
  SectionRow,
  VisibleSection,
} from '@/components/dashboard';
import Panel from '@/components/panel/Panel';
import {
  hasAttemptedPatientFetch,
  selectAdminHighAlerts,
  selectAdminMostCalls,
  selectAdminNoReadings,
  selectDashboardLastLoadedAt,
  selectIsDashboardCollapsed,
  selectIsFetchingDashboard,
} from '@/selectors/rpm';
import { Routes } from '@/utils/routeUtils';
import CareManagerHighAlerts from './CareManagerHighAlerts';
import CareManagerNoReadings from './CareManagerNoReadings';
import CareManagersMostCalls from './CareManagersMostCalls';

const RpmAdmin = (): JSX.Element => {
  const dispatch = useDispatch();

  const dashboardLastLoadedAt = useSelector(selectDashboardLastLoadedAt);
  const hasFetchedPatients = useSelector(hasAttemptedPatientFetch);
  const isDashboardCollapsed = useSelector(selectIsDashboardCollapsed);
  const isLoadingData = useSelector(selectIsFetchingDashboard);
  const mostCalls = useSelector(selectAdminMostCalls);
  const highAlerts = useSelector(selectAdminHighAlerts);
  const noReadings = useSelector(selectAdminNoReadings);

  const startAt = moment()
    .startOf('week')
    .utc()
    .format();

  const endAt = moment()
    .endOf('week')
    .utc()
    .format();

  const handleClickCollapse = () => {
    dispatch(toggleDashboardCollapse());
  };

  const shouldReloadDashboard = (() => {
    if (!dashboardLastLoadedAt) {
      return true;
    }

    const lastLoad = moment(dashboardLastLoadedAt);
    const now = moment();

    return now.diff(lastLoad, 'seconds') > 60;
  })();

  useEffect(() => {
    if (shouldReloadDashboard) {
      dispatch(getAdminDashboard());
    }
  }, []);

  if (isLoadingData && !hasFetchedPatients) {
    return <ActivityIndicator />;
  }

  return (
    <Panel>
      <Constraint size="large">
        <DashboardHeader
          isCollapsed={isDashboardCollapsed}
          onClickCollapse={handleClickCollapse}>
          Dashboard
        </DashboardHeader>

        <VisibleSection isVisible={!isDashboardCollapsed}>
          <SectionRow
            title="Care Managers: High Alert Patients"
            viewLink={Routes.rpm.highCareManagers}>
            <CareManagerHighAlerts
              careManagers={highAlerts}
              endAt={endAt}
              startAt={startAt}
            />
          </SectionRow>

          <SectionRow
            title="Care Managers: Most Calls to Patients"
            viewLink={Routes.rpm.mostCalls}>
            <CareManagersMostCalls
              careManagers={mostCalls}
              endAt={endAt}
              startAt={startAt}
            />
          </SectionRow>

          <SectionRow
            title="Care Managers: 0 Reading Patients"
            viewLink={Routes.rpm.noReadings}>
            <CareManagerNoReadings
              careManagers={noReadings}
              endAt={endAt}
              startAt={startAt}
            />
          </SectionRow>

          <div className="columns is-centered">
            <div className="column is-8-tablet is-6-desktop">
              <Link
                className="button is-white is-full-width"
                to={Routes.rpm.all}>
                View All Readings
              </Link>
            </div>
          </div>
        </VisibleSection>
      </Constraint>
    </Panel>
  );
};

export default RpmAdmin;
