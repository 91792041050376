import React from 'react';
import { Link } from 'react-router-dom';

import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { formatCityStateZip } from '@/utils/addressUtils';
import { Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  user: any;
  passwordUrl: string;
}

const ProfileDisplay = (props: Props) => {
  const { passwordUrl, user: currentUser } = props;

  const user = currentUser.data.attributes;

  const renderAddress = () => {
    if (!user.address) {
      return null;
    }

    return (
      <div className="profile-display__section">
        <span>{user.address.line_1}</span>
        {user.address.line_1 && <span>{user.address.line_2}</span>}
        <span>{formatCityStateZip(user.address)}</span>
      </div>
    );
  };

  return (
    <>
      <Panel>
        <Container>
          <Constraint size="medium">
            <div className="profile-display">
              <h2>{fullName(user)}</h2>

              <div className="profile-display__section">
                <span>{user.email}</span>
                <span>Ext. {user.phone_number_extension}</span>
              </div>

              {renderAddress()}

              <Link to={Routes.profile.edit}>Edit Profile</Link>
            </div>
          </Constraint>
        </Container>
      </Panel>
      <div className="columns">
        <div className="column">
          <Constraint size="medium">
            <div className="is-justified-right">
              <a href={passwordUrl}>Reset Password</a>
            </div>
          </Constraint>
        </div>
      </div>
    </>
  );
};

export default ProfileDisplay;
