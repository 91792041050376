import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import ErrorMessage from '@/components/form/ErrorMessage';
import ImagePreviewUpload from '@/components/form/ImagePreviewUpload';
import InputGroup from '@/components/form/InputGroup';
import SearchGroup from '@/components/form/SearchGroup';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Modal from '@/components/modal/Modal';
import Panel from '@/components/panel/Panel';
import { getProtocolsOptions } from '@/selectors/options';
import { Validations } from '@/types/form';
import usePostRequest from '@/utils/api/usePostRequest';
import { parseErrorData } from '@/utils/apiUtils';
import { updateArrayIndex } from '@/utils/arrayUtils';
import { statusOptions } from '@/utils/dropdownUtils';
import { validate } from '@/utils/form';
import { buildPath, Routes } from '@/utils/routeUtils';
import HealthSystemsNew from '../health_systems/HealthSystemsNew';
import AddressPanel from './components/AddressPanel';

const blankAddress = {
  label: '',
  line_1: '',
  line_2: '',
  city: '',
  state: '',
  zip_code: '',
  status: 'active',
};

const FORM_STATE = {
  name: '',
  twillio_phone_number: '',
  health_system_id: '',
  image_id: '',
  status: 'active',
  protocol_id: '',
  addresses: [blankAddress],
};

const HospitalsNew = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const location = useLocation();

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});
  const [addressValidations, setAddressValidations] = useState<Validations[]>([
    {},
  ]);

  const backPath = buildPath(Routes.hospitals);

  const protocolNameOptions = useSelector(getProtocolsOptions);

  const rules = {
    name: { required: true },
    twillio_phone_number: { required: true, type: 'phone' },
    health_system_id: { required: true },
    status: { required: true },
    protocol_ids: { required: true },
  };

  const addressRules = {
    line_1: { required: true },
    city: { required: true },
    state: { required: true },
    zip_code: { required: true },
  };

  const handleCloseModal = () => {
    const url = buildPath(Routes.hospitalsNew);

    history.push(url);
  };

  const handleClickHealthSystem = () => {
    const url = buildPath(Routes.hospitalsHealthSystem);

    history.push(url);
  };

  const handleFailure = (response: any) => {
    const errorMessage = parseErrorData(response);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const handleSuccess = () => {
    dispatch(flashSuccess('Hospital added'));

    history.push(backPath);
  };

  const formatBody = () => {
    return {
      hospital: {
        image_id: form.image_id,
        name: form.name,
        twillio_phone_number: form.twillio_phone_number,
        health_system_id: form.health_system_id,
        status: form.status,
        protocol_ids: [form.protocol_id],
        addresses_attributes: form.addresses,
      },
    };
  };

  const [submitForm, isSubmitting] = usePostRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: 'api/v1/hospitals',
  });

  const formatSearchResult = (result: any) => ({
    label: result.attributes.name,
    value: result.id,
  });

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);

    const addressValidationMessages = form.addresses.map(address =>
      validate(address, addressRules),
    );

    const areAddressesInvalid = addressValidationMessages.find(
      validation => validation[0] === false,
    );

    if (isValid && !areAddressesInvalid) {
      setValidations({});
      submitForm();
    } else {
      setValidations(validationMessages);
      setAddressValidations(
        addressValidationMessages.map(validation => validation[1]),
      );
    }
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const updateAddressField = (index: number, field: string, value: any) => {
    const formAddresses = [...form.addresses];
    const address = formAddresses[index];

    const updatedAddress = {
      ...address,
      [field]: value,
    };

    const updatedForm = {
      ...form,
      addresses: updateArrayIndex(form.addresses, index, updatedAddress),
    };

    setForm(updatedForm);
  };

  const handleAddNewLocation = () => {
    setForm({
      ...form,
      addresses: [...form.addresses, blankAddress],
    });

    setAddressValidations([...addressValidations, {}]);
  };

  const renderModal = () => {
    const modalBackPath = buildPath(Routes.hospitalsNew);

    if (location.pathname.includes(`health_systems`)) {
      return (
        <Modal isVisible={true} onCloseModal={handleCloseModal}>
          <div className="practice-form__modal">
            <h3 className="practice-form__modal-header">
              Add New Health System
            </h3>
            <HealthSystemsNew hidePanel isModal modalBackPath={modalBackPath} />
          </div>
        </Modal>
      );
    }
  };

  return (
    <>
      <div className="hospital-new__wrapper">
        <Container>
          <BackLink to={backPath}>Cancel</BackLink>
        </Container>

        <Constraint size="large">
          <Panel withNarrowMargin>
            <Section title="Hospital Details">
              <div className="columns is-mobile hospital-new__hospital-details-wrapper">
                <div className="column is-12-mobile is-6-tablet is-5-desktop hospital-new__health-system-wrapper">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatSearchResult}
                    guideValue={form.health_system_id}
                    label="Health System"
                    minimumInputLength={1}
                    onChange={value => updateField('health_system_id', value)}
                    placeholder="Search Health Systems"
                    searchPath="api/v1/health_systems"
                    validationMessage={validations.health_system_id}
                  />

                  <div className="hospital-new__health-system-button-wrapper">
                    <Button
                      style="flat-large add"
                      onClick={handleClickHealthSystem}>
                      Add to New Health System
                    </Button>
                  </div>
                </div>

                <div className="column is-12-mobile is-6-tablet is-4-desktop hospital-new__hospital-wrapper">
                  <InputGroup
                    label="Hospital Name"
                    onChange={value => updateField('name', value)}
                    placeholder="Enter Hospital Name"
                    validationMessage={validations.name}
                    value={form.name}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <ImagePreviewUpload
                    label="Logo"
                    onChange={(value: string) => updateField('image_id', value)}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel withNarrowMargin>
            <Section title="Status">
              <div className="columns is-mobile">
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Status"
                    onChange={value => updateField('status', value)}
                    options={statusOptions}
                    validationMessage={validations.status}
                    value={form.status}
                  />
                </div>
              </div>

              <div className="columns is-mobile">
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Protocol"
                    onChange={value => updateField('protocol_id', value)}
                    options={protocolNameOptions}
                    validationMessage={validations.protocol_id}
                    value={form.protocol_id}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel withNarrowMargin>
            <Section title="Contact Details">
              <div className="columns is-mobile">
                <div className="column is-6-tablet is-4-desktop">
                  <InputGroup
                    label="Phone Number"
                    onChange={value =>
                      updateField('twillio_phone_number', value)
                    }
                    placeholder="Enter phone number"
                    validationMessage={validations.twillio_phone_number}
                    value={form.twillio_phone_number}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <AddressPanel
            addresses={form.addresses}
            onUpdateAddressField={updateAddressField}
            addressValidations={addressValidations}
          />

          <div className="hospital-new__location-button-wrapper">
            <Button style="flat-large add" onClick={handleAddNewLocation}>
              Add Location
            </Button>
          </div>

          <ErrorMessage isRightSide message={error} />

          <div className="form__actions">
            <div className="form__actions-right">
              <Button color="white" onClick={handleClickCancel}>
                Cancel
              </Button>

              <Button
                color="secondary"
                isSubmitting={isSubmitting}
                onClick={handleClickSubmit}>
                Save
              </Button>
            </div>
          </div>
        </Constraint>
      </div>

      {renderModal()}
    </>
  );
};

export default HospitalsNew;
