import classNames from 'classnames';
import React from 'react';

import Icon from '@/components/icon/Icon';
import { Option } from '@/types/form';
import { SideEffect } from '@/types/generic';

interface Props {
  isDisabled?: boolean;
  isInvalid?: boolean;
  onChange?: SideEffect;
  options: Option[];
  shouldAllowNull?: boolean;
  value: string;
}

export const Select = (props: Props) => {
  const {
    isDisabled,
    isInvalid,
    options,
    onChange,
    shouldAllowNull,
    value,
    ...selectProps
  } = props;

  const handleChange = event => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const isSelected = !!(props.value && props.value !== '');

  return (
    <div className="form-select">
      <select
        className={classNames('form-select__input', {
          'is-selected': isSelected,
        })}
        disabled={isDisabled}
        onChange={handleChange}
        value={value || ''}
        {...selectProps}>
        {options.map((option, index) => {
          return (
            <option
              disabled={!option.value && !shouldAllowNull}
              key={`${index}_${option.value}`}
              value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>

      <div className="form-select__icon">
        <Icon icon="caretDown" color="secondary" />
      </div>
    </div>
  );
};

export default Select;
