import React from 'react';

import { QuestionnaireQuestion } from '@/types/v2/questionnaire';
import { titleizeSnakeCase } from '@/utils/stringUtils';

interface Props {
  question: QuestionnaireQuestion;
}

const QuestionDisplay = ({ question }: Props): JSX.Element => {
  const renderAnswers = (() => {
    if (!question.questionnaire_answers) {
      return null;
    }

    const answers = question.questionnaire_answers.map(answer => (
      <ul key={answer.id}>{answer.text}</ul>
    ));

    return <ul>{answers}</ul>;
  })();

  return (
    <div className="columns">
      <div className="column">
        <div className="questionnaire-question-display">
          <h5>{question.text}</h5>
          <p className="questionnaire-question-display__type">
            {titleizeSnakeCase(question.question_type)}
          </p>
          {renderAnswers}
        </div>
      </div>
    </div>
  );
};

export default QuestionDisplay;
