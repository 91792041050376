import React from 'react';

import InputGroup from '@/components/form/InputGroup';
import { Answer, AnswerValidation } from './types';

interface Props {
  answer: Answer;
  answerNumber: number;
  index: number;
  onChangeText: (index: number, value: string) => void;
  onRemoveAnswer: (index: number) => void;
  validations: AnswerValidation;
}

const FormAnswer = (props: Props): JSX.Element => {
  const {
    answer,
    index,
    answerNumber,
    onRemoveAnswer,
    onChangeText,
    validations,
  } = props;

  const handleChangeText = (value: string): void => {
    onChangeText(index, value);
  };

  const handleDeleteAnswer = () => {
    onRemoveAnswer(index);
  };

  const labelText = `Answer Option ${answerNumber}`;

  return (
    <>
      <div className="columns">
        <div className="column">
          <div className="questionnaire-form-answer">
            <InputGroup
              label={labelText}
              onChange={handleChangeText}
              value={answer.text}
              validationMessage={validations?.text}
            />
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <a className="questionnaire-remove" onClick={handleDeleteAnswer}>
            Remove Option
          </a>
        </div>
      </div>
    </>
  );
};

export default FormAnswer;
