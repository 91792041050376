import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { flashError } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import RadioList from '@/components/form/RadioList';
import {
  getSelectedPatient,
  getSelectedPatientEnrollment,
} from '@/selectors/api';
import { parseErrorData } from '@/utils/apiUtils';
import { buildPath, Routes } from '@/utils/routeUtils';

type ConsentType = 'verbal' | 'written';

const ConsentStep3 = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const enrollment = useSelector(getSelectedPatientEnrollment);
  const patient = useSelector(getSelectedPatient);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [consentType, setConsentType] = useState<ConsentType>('verbal');

  const handleClickNo = () => {
    const path = buildPath(Routes.patientsEnrollmentStep, {
      id: patient.id,
      step: 'consent',
      sub_step: '2',
    });

    history.push(path);
  };

  const handleClickYes = async () => {
    setIsSubmitting(true);

    const url = `api/v1/patient_enrollments/${enrollment.id}`;

    const body = {
      patient_enrollment: {
        patient_consent_type: consentType,
      },
    };

    try {
      await Api.utility.patch(url, body);

      const path = buildPath(Routes.patientsEnrollmentStep, {
        id: patient.id,
        step: 'consent',
        sub_step: '4',
      });

      history.push(path);
    } catch (err) {
      dispatch(flashError(parseErrorData(err)));
    }
  };

  const consentOptions = [
    {
      label: 'Verbal Consent',
      value: 'verbal',
    },
    {
      label: 'Written Consent',
      value: 'written',
    },
  ];

  return (
    <div className="consent__form">
      <h5>Please verify that the patient understands the following:</h5>

      <div className="consent__fields">
        <RadioList
          options={consentOptions}
          onChange={(value: ConsentType) => setConsentType(value)}
          value={consentType}
        />
      </div>

      <div className="consent__buttons">
        <Button color="white" isDisabled={isSubmitting} onClick={handleClickNo}>
          Previous Question
        </Button>

        <Button
          color="secondary"
          isDisabled={isSubmitting}
          onClick={handleClickYes}
          style="filled">
          Save and Next
        </Button>
      </div>
    </div>
  );
};

export default ConsentStep3;
