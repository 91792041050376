import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Panel from '@/components/panel/Panel';
import { SideEffect } from '@/types/generic';
import CloseButton from './CloseButton';

interface Props {
  children: ReactNode;
  hideClose?: boolean;
  isVisible: boolean;
  onCloseModal?: SideEffect;
  shouldCloseOnBackgroundClick?: boolean;
  isNarrow?: boolean;
}

const Modal = ({ shouldCloseOnBackgroundClick = true, ...props }: Props) => {
  const { children, hideClose, isVisible, isNarrow, onCloseModal } = props;

  const handleClickBackground = () => {
    if (shouldCloseOnBackgroundClick && onCloseModal) {
      onCloseModal();
    }
  };

  return (
    <div
      className={classNames('modal', {
        'is-active': isVisible,
      })}>
      <div className="modal-background" onClick={handleClickBackground} />
      <div className={classNames('modal-content', {
        'is-narrow': isNarrow,
      })}>
        <Panel>
          {!hideClose && <CloseButton onClick={onCloseModal} />}
          {children}
        </Panel>
      </div>
    </div>
  );
};

export default Modal;
