import React, { useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { flashSuccess } from '@/actions/sagas/messageSaga';
import Button from '@/components/button/Button';
import EnrollmentActions from '@/contexts/enrollment/components/EnrollmentActions';
import UnsavedConfirmationModal from '@/contexts/modals/UnsavedConfirmationModal';
import { isEnrollmentPath } from '@/contexts/patients/utils/patientUtils';
import { getSelectedPatient } from '@/selectors/api';
import { SideEffect } from '@/types/generic';
import usePutRequest from '@/utils/api/usePutRequest';
import { parseErrorData } from '@/utils/apiUtils';
import { arraysMatch } from '@/utils/arrayUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from './Checkbox';

interface Group {
  name: string;
  options: Option[];
}

interface Option {
  label: string;
  value: string;
}

interface Props {
  customOptionId?: string;
  customOptionValue?: string;
  formatBody?: (options: any) => object;
  isSubmitDisabled?: boolean;
  onChange: SideEffect;
  onChangeCustomOption?: SideEffect;
  onSuccess?: SideEffect;
  options: Group[];
  originalSelectedIds: string[];
  saveText?: string;
  selectedIds: string[];
  shouldHideGroupName?: boolean;
  url: string;
}

const Checklist = (props: Props) => {
  const {
    customOptionId,
    customOptionValue,
    formatBody,
    isSubmitDisabled,
    onChange,
    onChangeCustomOption,
    onSuccess,
    options,
    originalSelectedIds,
    saveText,
    selectedIds,
    shouldHideGroupName,
    url,
  } = props;

  const dispatch = useDispatch();

  const history = useHistory();

  const patient = useSelector(getSelectedPatient);

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [navigationPath, setNavigationPath] = useState('');

  if (!options) {
    return null;
  }

  const isFormUpdated = !arraysMatch(selectedIds, originalSelectedIds);

  const body = formatBody(selectedIds);

  const isEnrollment = isEnrollmentPath();

  const defaultSaveText = isEnrollment ? 'Save and Next' : 'Save';

  const handleSuccess = () => {
    setIsSuccessful(true);

    if (navigationPath) {
      handleNavigate();
    } else if (!isEnrollment) {
      dispatch(flashSuccess('Patient updated'));
    } else if (onSuccess) {
      onSuccess();
    }
  };

  const handleSuccessAndBack = () => {
    setIsSuccessful(true);

    history.push(buildPath(Routes.patientsEnrollmentIndex, { id: patient.id }));
  };

  const [saveForm, isSubmitting] = usePutRequest({
    dispatch,
    url,
    body,
    dispatchToStore: true,
    key: 'selectedPatient',
    onSuccess: handleSuccess,
  });

  const [saveFormAndBack, isSubmittingBack] = usePutRequest({
    dispatch,
    url,
    body,
    dispatchToStore: true,
    key: 'selectedPatient',
    onSuccess: handleSuccessAndBack,
  });

  const handleEditCustomOption = (event: any): void => {
    if (onChangeCustomOption) {
      onChangeCustomOption(event.target.value);
    }
  };

  const handleCloseModal = () => {
    setNavigationPath(null);
    setIsConfirmationVisible(false);
  };

  const handleNavigate = () => {
    history.push(navigationPath);
  };

  const handleSaveConfirm = () => {
    saveForm();
  };

  const handlePromptWarning = location => {
    if (isSuccessful || !isFormUpdated || isConfirmationVisible) {
      return true;
    }

    setIsConfirmationVisible(true);
    setNavigationPath(location.pathname);

    return false;
  };

  const handleToggleInput = (value: string) => {
    if (selectedIds.includes(value)) {
      onChange(selectedIds.filter(id => id !== value));
    } else {
      onChange([...selectedIds, value]);
    }
  };

  const renderTextInput = () => {
    return (
      <div className="form-checklist__textarea">
        <textarea onChange={handleEditCustomOption} value={customOptionValue} />
      </div>
    );
  };

  const renderGroup = (group: Group) => {
    return (
      <div className="column is-6" key={group.name}>
        <div className="form-checklist__group">
          {shouldHideGroupName || <h5>{group.name}</h5>}
          <div className="form-checkslist__group-options">
            {group.options.map(option => {
              const isChecked = selectedIds.includes(option.value);
              const displayInput = isChecked && option.value === customOptionId;

              return (
                <div className="form-checklist__option" key={option.value}>
                  <label
                    className="form-checkbox-wrapper"
                    onClick={() => handleToggleInput(option.value)}>
                    <Checkbox isChecked={isChecked} />
                    <span>{option.label}</span>
                  </label>

                  {displayInput && renderTextInput()}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="form-checklist">
        <div className="columns is-multiline">
          {options.map((group: Group) => {
            return renderGroup(group);
          })}
        </div>
      </div>

      <EnrollmentActions isEnrollment={isEnrollment}>
        <Button
          color="secondary"
          isDisabled={isSubmitDisabled}
          isSubmitting={isSubmitting || isSubmittingBack}
          onClick={saveForm}>
          {saveText || defaultSaveText}
        </Button>

        {isEnrollment && (
          <Button
            color="white"
            isDisabled={isSubmitDisabled}
            isSubmitting={isSubmitting || isSubmittingBack}
            onClick={saveFormAndBack}>
            Save And Back To Sections List
          </Button>
        )}
      </EnrollmentActions>

      <Prompt message={handlePromptWarning} />

      <UnsavedConfirmationModal
        isVisible={isConfirmationVisible}
        onClose={handleCloseModal}
        onNavigate={handleNavigate}
        onSave={handleSaveConfirm}
      />
    </>
  );
};

export default Checklist;
