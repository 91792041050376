import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { flashError } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import {
  getSelectedPatient,
  getSelectedPatientEnrollment,
  selectSelectedPatientProgramText,
} from '@/selectors/api';
import { parseErrorData } from '@/utils/apiUtils';
import { buildPath, Routes } from '@/utils/routeUtils';

const ConsentStep1 = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const enrollment = useSelector(getSelectedPatientEnrollment);
  const patient = useSelector(getSelectedPatient);
  const programText = useSelector(selectSelectedPatientProgramText);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClickNo = () => {
    const path = buildPath(Routes.patientsShow, { id: patient.id });
    history.push(path);
  };

  const handleClickYes = async () => {
    setIsSubmitting(true);

    const url = `api/v1/patient_enrollments/${enrollment.id}`;

    const body = {
      patient_enrollment: {
        patient_agreed_at: moment().format(),
      },
    };

    try {
      await Api.utility.patch(url, body);

      const path = buildPath(Routes.patientsEnrollmentStep, {
        id: patient.id,
        step: 'consent',
        sub_step: '2',
      });

      history.push(path);
    } catch (err) {
      dispatch(flashError(parseErrorData(err)));
    }
  };

  return (
    <div className="consent__form">
      <h5>
        You are about to enroll this patient into the {programText}. Has the
        patient agreed to participate?
      </h5>

      <div className="consent__buttons">
        <Button color="white" isDisabled={isSubmitting} onClick={handleClickNo}>
          No, Exit Enrollment
        </Button>
        <Button
          color="secondary"
          isDisabled={isSubmitting}
          onClick={handleClickYes}
          style="filled">
          Yes, Enroll Patient
        </Button>
      </div>
    </div>
  );
};

export default ConsentStep1;
