import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import CareManager from '@/contexts/dashboard/CareManager';
import { selectCurrentUserRole } from '@/selectors/users';
import { Routes } from '@/utils/routeUtils';
import { useSelector } from 'react-redux';
import AdminDashboard from './AdminDashboard';
import UserDashboard from './UserDashboard';

const Router = () => {
  const userRole = useSelector(selectCurrentUserRole);

  if (userRole === 'patient') {
    return <Redirect to={Routes.chart.root} />;
  } else if (userRole === 'admin_user') {
    return <Redirect to={Routes.provider.root} />;
  }

  return (
    <Protected allowedRoles={PolicyGroups.nonBillingAny}>
      <Switch>
        <Route component={CareManager} path={Routes.dashboardCareManager} />
        <Route component={AdminDashboard} path={Routes.dashboardLive} />
        <Route component={UserDashboard} path={Routes.dashboard} />
      </Switch>
    </Protected>
  );
};

export default Router;
