import { useState } from 'react';
import { Dispatch } from 'redux';

interface RequestConfig {
  dispatch: Dispatch;
  dispatchToStore?: boolean;
  initializeAsLoading?: boolean;
  onFailure?: (response: object) => void;
  onSuccess?: (responseData: object) => void;
  payload?: object;
  url: string;
}

function useDelRequest(config: RequestConfig): [() => void, boolean] {
  const { dispatch, url, initializeAsLoading, onSuccess, onFailure } = config;

  const [isLoading, setIsLoading] = useState(
    initializeAsLoading ? true : false,
  );

  const makeRequest = () => {
    setIsLoading(true);

    dispatch({
      type: 'DELETE_DATA_SAGA',
      payload: {
        url,
      },

      onSuccess: (response: object) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },

      onFailure: (error: object) => {
        if (onFailure) {
          onFailure(error);
        }
      },
    });
  };

  return [makeRequest, isLoading];
}

export default useDelRequest;
