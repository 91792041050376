import React, { useEffect, useState } from 'react';

import { Task } from '@/types/v2/tasks';
import { fullName } from '@/utils/userUtils';
import Icon from '@/components/icon/Icon';
import Checkbox from '@/components/form/Checkbox';

interface Props {
  isCareManagerDashboard?: boolean;
  onCheckTaskPress: (task: Task) => void;
  onTaskCardPress: (task: Task) => void;
  task: Task;
}

const TaskCard = ({
  isCareManagerDashboard,
  onCheckTaskPress,
  onTaskCardPress,
  task,
}: Props): JSX.Element => {
  const isTaskCompleted: boolean = task.task_status === 'completed';

  const [isChecked, setIsChecked] = useState<boolean>(isTaskCompleted);
  const [hasCheckedTask, setHasCheckedTask] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const canUpdateCompletedTask: boolean =
        isTaskCompleted && !isChecked && hasCheckedTask;
      const canUpdatePendingTask: boolean =
        !isTaskCompleted && isChecked && hasCheckedTask;

      if (canUpdateCompletedTask || canUpdatePendingTask) {
        onCheckTaskPress(task);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [isChecked]);

  return (
    <div className="task-card">
      <div className="task-card__left">
        <Checkbox
          isChecked={isChecked}
          onClick={() => {
            setHasCheckedTask(true);
            setIsChecked(!isChecked);
          }}
        />
        <span className="task-card__task">{task.name}</span>
      </div>
      <div
        className="task-card__action-user"
        onClick={() => onTaskCardPress(task)}>
        <span>
          {fullName(isCareManagerDashboard ? task.patient : task.assigned_to)}
        </span>
        <Icon icon="caretRight" />
      </div>
    </div>
  );
};

export default TaskCard;
