import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import {
  selectCurrentUserRole,
  selectUserCapabilities,
} from '@/selectors/users';
import { Capability, UserRole } from '@/types/user';

interface Props {
  allowedRoles: (Capability | UserRole)[];
  children: ReactNode;
}

const AccessControl = (props: Props) => {
  const { allowedRoles, children } = props;

  const userRole = useSelector(selectCurrentUserRole);
  const userCapabilities = useSelector(selectUserCapabilities);

  const isAllowed = (() => {
    const allRoles = [userRole, ...userCapabilities];
    return allowedRoles.some(role => allRoles.includes(role));
  })();

  if (isAllowed) {
    return <>{children}</>;
  }

  return null;
};

export default AccessControl;
