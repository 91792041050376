import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const Preview = (props: Props) => {
  const { children } = props;

  return (
    <div className="pdf-preview">
      <div className="pdf-preview__content">{children}</div>
    </div>
  );
};

export default Preview;
