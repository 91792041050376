import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import Panel from '@/components/panel/Panel';
import Container from '@/components/container/Container';
import { UUID } from '@/types/generic';
import useNotifier from '@/utils/messages/useNotifier';
import useLoadingState from '@/utils/api/useLoadingState';
import { buildPath, Routes } from '@/utils/routeUtils';
import { selectSelectedPatientPracticeId } from '@/selectors/api';
import Api from '@/api/Api';
import Modeler from '@/utils/modeler/modeler';
import { Task } from '@/types/v2/tasks';
import { CareManager } from '@/types/v2/care_manager';
import Checkbox from '@/components/form/Checkbox';
import { fullName } from '@/utils/userUtils';
import Input from '@/components/form/Input';
import Select from '@/components/form/Select';
import TextArea from '@/components/form/TextArea';
import Button from '@/components/button/Button';
import { IdParams } from '@/types/params';
import ConfirmationModal from '../modals/ConfirmationModal';
import { formatFriendlyDateTime } from '@/utils/dateUtils';

type Inputs = {
  name: string;
  assignee: UUID;
  notes: string;
};

interface Params extends IdParams {
  task_id: UUID;
}

interface AssigneeOption {
  label: string;
  value: UUID;
}

const TasksShow = (): JSX.Element => {
  const history = useHistory();
  const { id: patient_id, task_id } = useParams<Params>();
  const notifier = useNotifier();
  const loader = useLoadingState('task');

  const patientPracticeId = useSelector(selectSelectedPatientPracticeId);

  const [task, setTask] = useState<Task>();
  const [name, setName] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [assignedTo, setAssignedTo] = useState<AssigneeOption>();
  const [assignedToId, setAssignedToId] = useState<string>();
  const [careManagers, setCareManagers] = useState<CareManager[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>();
  const [isCompleted, setIsCompleted] = useState<boolean>();
  const [isArchiveModalShowing, setIsArchiveModalShowing] = useState<boolean>(
    false,
  );
  const [assigneeOptions, setAssigneeOptions] = useState<AssigneeOption[]>([]);

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const getTask = async (): Promise<void> => {
    loader.startLoading('task');

    try {
      const url = buildPath(Routes.api2.task, { id: task_id }, {}, [
        'created_by',
        'assigned_to',
        'completed_by',
        'created_at',
      ]);

      const response = await Api.utility.get(url);

      const resource = new Modeler<Task>(response.data, {
        generations: 2,
      }).build();

      const formatAssignee = assignee => {
        return {
          label: fullName(assignee),
          value: assignee.id,
        };
      };

      setTask(resource);
      setName(resource.name);
      setNotes(resource.notes);
      setAssignedTo(formatAssignee(resource.assigned_to));

      const isTaskCompleted: boolean = resource.task_status === 'completed';

      setIsChecked(isTaskCompleted);
      setIsCompleted(isTaskCompleted);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('task');
  };

  const getCareManagers = async (): Promise<void> => {
    try {
      const url = buildPath(
        Routes.api2.careManagers,
        null,
        { practice_id: patientPracticeId },
        [],
      );

      const response = await Api.utility.get(url);

      const resource = new Modeler<CareManager[]>(response.data, {
        generations: 1,
      }).build();

      setCareManagers(resource);
    } catch (err) {
      notifier.error(err);
    }
  };

  useEffect(() => {
    getTask();
    getCareManagers();
  }, []);

  useEffect(() => {
    if (assignedTo && careManagers) {
      const careManagerOptions = careManagers.map(
        (careManager: CareManager) => ({
          label: fullName(careManager),
          value: careManager.id,
        }),
      ).filter(careManager => !_.isEqual(careManager, assignedTo)); //if the assignee is part of the care managers array, remove them before adding them back to avoid duplicates and always have the assignee be the default value in the options array

      setAssigneeOptions([assignedTo, ...careManagerOptions]);

    }
  }, [assignedTo, careManagers]);

  const handleSubmit = async (): Promise<void> => {
    try {
      const url = buildPath(Routes.api2.task, { id: task.id });

      const taskStatus = isChecked ? 'completed' : 'pending';

      const body = {
        task: {
          name: name,
          notes: notes,
          task_status: taskStatus,
          assigned_to_id: assignedToId,
        },
      };

      const response = await Api.utility.patch(url, body);

      notifier.success('Task updated');

      history.push(
        buildPath(Routes.patientsCareManagement, { id: patient_id }),
      );
    } catch (err) {
      notifier.error(err);
    }
  };

  const handleArchive = async (): Promise<void> => {
    try {
      const url = buildPath(Routes.api2.task, { id: task.id });

      const body = {
        task: {
          task_status: 'archived',
        },
      };

      const response = await Api.utility.patch(url, body);

      setIsArchiveModalShowing(false);

      notifier.success('Task archived');

      history.push(
        buildPath(Routes.patientsCareManagement, { id: patient_id }),
      );
    } catch (err) {
      notifier.error(err);
    }
  };

  if (!task) {
    return null;
  }

  return (
    <>
      <Constraint size="large">
        <BackLink onClick={() => history.goBack()}>Back</BackLink>
      </Constraint>
      <Panel>
        <Constraint size="large">
          <Container>
            <div className="tasks-show">
              <h1 className="tasks-show__header">Task Details</h1>

              <div className="tasks-show__task-container">
                <Checkbox
                  isChecked={isChecked}
                  onClick={() => setIsChecked(!isChecked)}
                />

                <span className="tasks-show__task">
                  <Input
                    onChange={value => setName(value)}
                    value={name}
                    placeholder={task.name}
                  />
                </span>
                <span></span>
              </div>

              <div className="tasks-show__details-container">
                <div className="tasks-show__details-left">
                  {isCompleted && (
                    <div className="tasks-show__row">
                      <span className="tasks-show__label">Completed by:</span>
                    </div>
                  )}
                  <div className="tasks-show__row">
                    <span className="tasks-show__label">Assigned to:</span>
                  </div>
                  <div className="tasks-show__row">
                    <span className="tasks-show__label">Created by: </span>
                  </div>
                </div>
                <div className="tasks-show__details-right">
                  {isCompleted && (
                    <div className="tasks-show__row">
                      <span className="tasks-show__name">
                        {fullName(task.completed_by)}
                      </span>
                    </div>
                  )}
                  <div className="tasks-show__row">
                    <span className="tasks-show__name">
                      <div className="form-select">
                        <Select
                          options={assigneeOptions}
                          value={assignedToId}
                          onChange={value => setAssignedToId(value)}
                        />
                      </div>
                    </span>
                  </div>
                  <div className="tasks-show__row">
                    <div className="tasks-show__creator">
                      <span className="tasks-show__name">
                        {fullName(task.created_by)}
                      </span>
                      <span className="tasks-show__date">
                        {formatFriendlyDateTime(task.created_at)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <TextArea onChange={value => setNotes(value)} value={notes} />

              <div className="tasks-show__actions-container">
                <button
                  className="tasks-show__archive"
                  onClick={() => setIsArchiveModalShowing(true)}>
                  Archive Task
                </button>

                <Button color="secondary" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </div>
          </Container>
        </Constraint>
      </Panel>

      <ConfirmationModal
        isVisible={isArchiveModalShowing}
        onAccept={handleArchive}
        onClose={() => setIsArchiveModalShowing(false)}
      />
    </>
  );
};

export default TasksShow;
