import React from 'react';

import InputGroup from '@/components/form/InputGroup';
import SelectGroup from '@/components/form/SelectGroup';
import Icon from '@/components/icon/Icon';
import { DeviceReadingAlertLevel } from '@/types/v2/device_reading';

interface Props {
  index: number;
  minValue: number;
  maxValue: number;
  onChange: (index: number, field: string, value: string) => void;
  onDelete: (index: number) => void;
  level: DeviceReadingAlertLevel;
}

const ReadingThresholdInputs = (props: Props): JSX.Element => {
  const { index, minValue, maxValue, level, onChange, onDelete } = props;

  const handleChangeValue = (field: string, value: string): void => {
    onChange(index, field, value);
  };

  const options = [
    { label: 'Low', value: 'low' },
    { label: 'Medium', value: 'medium' },
    { label: 'High', value: 'high' },
  ];

  return (
    <div className="reading-threshold">
      <div className="columns">
        <div className="column is-3-tablet is-2-desktop">
          <InputGroup
            label="Min Value"
            onChange={value => handleChangeValue('min_value', value)}
            value={minValue.toString()}
          />
        </div>

        <div className="column is-3-tablet is-2-desktop">
          <InputGroup
            label="Max Value"
            onChange={value => handleChangeValue('max_value', value)}
            value={(maxValue || '').toString()}
          />
        </div>

        <div className="column is-2-tablet is-2-desktop">
          <SelectGroup
            label="Alert Level"
            onChange={value => handleChangeValue('level', value)}
            options={options}
            value={level}
          />
        </div>

        <div className="column is-2">
          <div className="reading-threshold__remove">
            <button onClick={() => onDelete(index)}>
              <Icon icon="close" size="small" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadingThresholdInputs;
