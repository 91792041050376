import { toast } from 'react-toastify';
import { takeLatest } from 'redux-saga/effects';

type MessageType = 'error' | 'notice' | 'success' | 'warning';

enum MessageActions {
  FLASH_MESSAGE = 'MESSAGES/FLASH_MESSAGE',
}

interface FlashMessage {
  type: MessageActions.FLASH_MESSAGE;
  payload: {
    message: string;
    type: MessageType;
  };
}

export const flashError = (message: string): FlashMessage => ({
  type: MessageActions.FLASH_MESSAGE,
  payload: {
    message,
    type: 'error',
  },
});

export const flashNotice = (message: string): FlashMessage => ({
  type: MessageActions.FLASH_MESSAGE,
  payload: {
    message,
    type: 'notice',
  },
});

export const flashSuccess = (message: string): FlashMessage => ({
  type: MessageActions.FLASH_MESSAGE,
  payload: {
    message,
    type: 'success',
  },
});

export const flashWarning = (message: string): FlashMessage => ({
  type: MessageActions.FLASH_MESSAGE,
  payload: {
    message,
    type: 'warning',
  },
});

export function* flashMessageSaga(action: FlashMessage) {
  const { payload } = action;

  toast(payload.message, {
    className: `toast__${payload.type}-main`,
    bodyClassName: `toast__${payload.type}-body`,
    progressClassName: `toast__${payload.type}-progress`,
  });
}

export default function* messageSaga() {
  yield takeLatest(MessageActions.FLASH_MESSAGE, flashMessageSaga);
}
