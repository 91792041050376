import { selectIsCurrentUserCareManager } from '@/selectors/users';
import React from 'react';
import { useSelector } from 'react-redux';

import RpmAdmin from './admin/RpmAdmin';
import RpmCareManager from './care_manager/RpmCareManager';

const RpmDashboard = (): JSX.Element => {
  const isCurrentUserCareManager = useSelector(selectIsCurrentUserCareManager);

  return isCurrentUserCareManager ? <RpmCareManager /> : <RpmAdmin />;
};

export default RpmDashboard;
