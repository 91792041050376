import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode | JSX.Element[];
}

const Actions = ({ children }: Props): JSX.Element => {
  const renderLeftAction = (() => {
    if (Array.isArray(children)) {
      return children[1];
    }
  })();

  const renderRight = (() => {
    if (Array.isArray(children)) {
      return children[0];
    }

    return children;
  })();

  return (
    <div className="form__actions">
      <div className="form__actions-left">{renderLeftAction}</div>

      <div className="form__actions-right">{renderRight}</div>
    </div>
  );
};

export default Actions;
