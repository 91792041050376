import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MedicationsEdit from '@/contexts/patients/modules/medications/MedicationsEdit';
import MedicationsIndex from '@/contexts/patients/modules/medications/MedicationsIndex';
import MedicationsNew from '@/contexts/patients/modules/medications/MedicationsNew';
import { Routes } from '@/utils/routeUtils';

const Patients = () => {
  return (
    <Switch>
      <Route
        path={Routes.patientsMedicationsEdit}
        component={MedicationsEdit}
      />
      <Route path={Routes.patientsMedicationsNew} component={MedicationsNew} />
      <Route path={Routes.patientsMedications} component={MedicationsIndex} />
    </Switch>
  );
};

export default Patients;
