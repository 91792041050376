import { matchPath } from 'react-router';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import {
  AutoJoinCall,
  generateToken,
  VideoCallsActions,
} from '@/actions/actions/video_calls';
import Api from '@/api/Api';
import { selectVideoCallsAppointment } from '@/selectors/video_calls';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';

interface Params {
  appointment_id: string;
}

function* autoJoinCallSaga(action: AutoJoinCall) {
  const id = action.payload.id;

  const history = yield getContext('history');
  const path = history.location.pathname;

  const waitingRoom = matchPath<Params>(path, Routes.chart.appointments.show);

  const selectedAppointment: VirtualAppointment = yield select(
    selectVideoCallsAppointment,
  );

  // Only auto join if 1) the user is in the waiting room and 2) the selected appointment is not the same as the one attempting to be joined (ie the user had already joined previously)
  if (
    waitingRoom &&
    waitingRoom?.params?.appointment_id === id &&
    selectedAppointment?.id !== id
  ) {
    const url = buildPath(Routes.api2.virtualAppointment, { id }, null, [
      'practice',
      'specialist',
    ]);
    const response = yield call(Api.utility.get, url);
    const appointment = new Modeler<VirtualAppointment>(response.data).build();

    yield put(generateToken(appointment));
  }
}

export function* watchAutoJoinCall() {
  yield takeLatest(VideoCallsActions.AUTO_JOIN_CALL, autoJoinCallSaga);
}
