import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@/components/form/Checkbox';

import { flashError } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import CheckboxGroup from '@/components/form/CheckboxGroup';
import InputIcon from '@/components/form/InputIcon';
import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { getPatientsSnapshot } from '@/selectors/patients';
import { selectCurrentUser } from '@/selectors/users';
import { UUID } from '@/types/generic';
import { UserAttributes } from '@/types/user';
import { ContactStatus } from '@/types/patient';
import { TableConfig } from '@/types/table';
import { displayCallStatus } from '@/utils/callUtils';
import { formatShortDate } from '@/utils/dateUtils';
import { displayPatientStatus } from '@/utils/patientUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { usePrevious } from '@/utils/stateUtils';
import { formatSeconds, formatSecondsToHours } from '@/utils/timeUtils';
import { formatShortDateTime } from '@/utils/dateUtils';
import { useHistory } from 'react-router-dom';

import Modal from '@/components/modal/Modal';
import moment from 'moment';
import { flashWarning } from '@/actions/sagas/messageSaga';
import ColumnSelectModalEdit from './components/ColumnSelectModalEdit';
import PatientEditAllModal from './components/PatientEditAllModal';
import { Link } from 'react-router-dom';
import PatientEditAllFilter from './components/PatientEditAllFilter';

export interface ColumnState {
  patient_status: boolean;
  first_name: boolean;
  last_name: boolean;
  dob: boolean;
  provider: boolean;
  practice: boolean;
  reading_day_count: boolean;
  care_manager: boolean;
  ccm_phone: boolean;
  ccm_admin: boolean;
  ccm_total: boolean;
  ccm_goal: boolean;
  ccm_status: boolean;
  rpm_phone: boolean;
  rpm_admin: boolean;
  rpm_total: boolean;
  rpm_goal: boolean;
  rpm_status: boolean;
  last_call: boolean;
  last_call_status: boolean;
  contacted: boolean;
  alerts: boolean;
  program: boolean;
  language: boolean;
  device_count: boolean;
  device_type_name: boolean;
  last_reading_date: boolean;
}

const SEARCH_PATH = 'api/v1/patients/dashboard';
const INITIAL_QUERY = {
  // incomplete_tasks: false,
  alerts: false,
  call: '',
  care_manager_id: '',
  ccm_complexity_code: '',
  ccm_minutes: '',
  enrollment_month: '',
  enrollment_year: '',
  health_system_id: '',
  hospital_id: '',
  month: '',
  language: '',
  associated: '',
  most_recent_call_status: '',
  practice_id: '',
  rpm_complexity_code: '',
  rpm_minutes: '',
  search: '',
  services: ['ccm', 'rpm', 'bhi'],
  specialist_id: '',
  status: '',
  year: '',
};
const INITIAL_COLUMN_STATE: ColumnState = {
  patient_status: false,
  first_name: false,
  last_name: false,
  dob: true,
  provider: true,
  practice: false,
  reading_day_count: true,
  care_manager: false,
  ccm_phone: true,
  ccm_admin: true,
  ccm_total: true,
  ccm_goal: true,
  ccm_status: true,
  rpm_phone: true,
  rpm_admin: true,
  rpm_total: true,
  rpm_goal: true,
  rpm_status: true,
  last_call: true,
  last_call_status: true,
  contacted: false,
  alerts: true,
  program: false,
  language: true,
  device_count: true,
  device_type_name: true,
  last_reading_date: true,
};

const PatientEditAll = () => {
  var counter = 0;
  var istoggle;
  var arr = new Array();

  const [isDevicesModalVisible, SetDeviceModalVisible] = useState(false);
  const [Counter, setCounter] = useState(0);

  const [CheckBoxValue, setCheckBoxValue] = useState(false);
  const [Show, setShow] = useState(1);
  const dispatch = useDispatch();
  const snapshot = useSelector(getPatientsSnapshot);
  const currentUser = useSelector(selectCurrentUser) as UserAttributes;
  const history = useHistory();

  const [AllPatientIdArray, setAllPatientIdArray] = useState([]);
  const [AllPatientNameArray, setAllPatientNameArray] = useState([]);
  const [array, setArray] = useState([]);
  const [NameArray, setNameArray] = useState([]);
  const [PracticeArray,setPracticeArray]=useState([]);
  const [AllPracticeArray,setAllPracticeArray]=useState([]);


  const [start_date, SetStartDate] = useState(
    moment()
      .subtract(1, 'months')
      .utc()
      .format(),
  );
  const [end_date, SetEndDate] = useState(
    moment()
      .endOf('day')
      .utc()
      .format(),
  );

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const newPatientRoute = buildPath(Routes.patientsNew);

  const [perPage, setPerPage] = useState(24);
  const [searchInput, setSearchInput] = useState('');

  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const [columnSelectModalVisible, setColumnSelectModalVisible] = useState(
    false,
  );
  const [columns, setColumns] = useState(INITIAL_COLUMN_STATE);

  const [queryOptions, setQueryOptions] = useState(INITIAL_QUERY);

  const previousHealthSystemId = usePrevious(queryOptions.health_system_id);
  const previousHospitalId = usePrevious(queryOptions.hospital_id);

  const previousHealthSystemIdMobile = usePrevious(
    queryOptions.health_system_id,
  );
  const previousHospitalIdMobile = usePrevious(queryOptions.hospital_id);
  const de_identify = sessionStorage.getItem('de_identify');
  interface Query {
    end_at: string;
    start_at: string;
  }

  const initialQuery = {
    end_at: end_date,
    start_at: start_date,
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setIsPageLoaded(true);
    }
  }, [isLoaded]);

  const [query, setQuery] = useState<Query>(initialQuery);

  const updateQuery = (field: string, value: string) => {
    const newQuery = {
      ...query,
      [field]: value,
    };

    const startAt = moment(newQuery.start_at);
    const endAt = moment(newQuery.end_at);
    SetStartDate(
      String(
        moment(newQuery.start_at)
          .utc()
          .format(),
      ),
    );
    SetEndDate(
      String(
        moment(newQuery.end_at)
          .endOf('day')
          .utc()
          .format(),
      ),
    );

    if (startAt.isBefore(endAt) || startAt.isSame(endAt, 'day')) {
      setQuery(newQuery);
    } else {
      dispatch(flashWarning('Invalid date selection'));
    }
  };

  useEffect(() => {
    if (
      previousHealthSystemId &&
      previousHealthSystemId !== queryOptions.health_system_id
    ) {
      handleChange('hospital_id', '');
    }

    if (previousHospitalId && previousHospitalId !== queryOptions.hospital_id) {
      handleChange('practice_id', '');
    }
  }, [
    queryOptions.health_system_id,
    queryOptions.hospital_id,
    queryOptions.practice_id,
  ]);

  useEffect(() => {
    if (
      previousHealthSystemIdMobile &&
      previousHealthSystemIdMobile !== queryOptions.health_system_id
    ) {
      handleChange('hospital_id', '');
    }

    if (
      previousHospitalIdMobile &&
      previousHospitalIdMobile !== queryOptions.hospital_id
    ) {
      handleChange('practice_id', '');
    }
  }, [
    queryOptions.health_system_id,
    queryOptions.hospital_id,
    queryOptions.practice_id,
  ]);

  useEffect(() => {
    const userColumnDefaults = localStorage.getItem('userColumnDefaults_edit');
    if (userColumnDefaults) {
      setColumns(JSON.parse(userColumnDefaults));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('userColumnDefaults_edit', JSON.stringify(columns));
  }, [columns]);

  const handleAddPatient = () => {
    history.push(newPatientRoute);
  };

  const handleChange = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleClearFilters = () => {
    setQueryOptions(INITIAL_QUERY);
  };

  const handlePerPageChange = (value: any) => {
    setPerPage(value);
  };

  const handleSearchChange = (value: any) => {
    setSearchInput(value);
  };

  const handleSearchInput = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handlFilterClick = () => {
    setModalVisible(true);
  };

  const handleApplyClick = () => {
    setQueryOptions({
      ...queryOptions,
      ...queryOptions,
    });

    setModalVisible(false);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeEditModal = () => {
    setShow(0);

    SetDeviceModalVisible(false);

    setEditModalVisible(false);
    setCheckBoxValue(false);

    setArray([]);
    setNameArray([]);
    setAllPatientIdArray([]);
    setPracticeArray([]);
    setAllPracticeArray([]);
    setCounter(0);
    //handleClearFilters();

    setTimeout(function() {
      setShow(1);
    }, 100); //delay is in milliseconds
  };
  const hanldeEdit = () => {
    SetDeviceModalVisible(true);
  };

  const toggleColumnSelectModal = () => {
    setColumnSelectModalVisible(!columnSelectModalVisible);
  };

  const updateColumnState = (nextState: ColumnState) => {
    setColumns(nextState);
  };

  const toggleService = (service: string) => {
    if (serviceIncluded(service)) {
      handleChange(
        'services',
        [...queryOptions.services].filter(s => s !== service),
      );
    } else {
      handleChange('services', [...queryOptions.services, service]);
    }
  };

  const toggleMyPatients = () => {
    if (queryOptions.care_manager_id) {
      setQueryOptions({
        ...queryOptions,
        care_manager_id: '',
        status: '',
      });
    } else {
      setQueryOptions({
        ...queryOptions,
        care_manager_id: currentUser.id,
        status: '0',
      });
    }
  };

  // const togglePatientsWithAlerts = () => {
  //   if (queryOptions.alerts) {
  //     setQueryOptions({
  //       ...queryOptions,
  //       alerts: false,
  //     });
  //   } else {
  //     setQueryOptions({
  //       ...queryOptions,
  //       alerts: true,
  //     });
  //   }
  // };

  // const togglePatientsWithIncompleteTasks = () => {
  //   setQueryOptions({
  //     ...queryOptions,
  //     incomplete_tasks: !queryOptions.incomplete_tasks,
  //   });
  // };

  const serviceIncluded = (service: string) => {
    return queryOptions.services.includes(service);
  };

  const formatStatus = (entry: any, type: 'ccm' | 'rpm'): string => {
    const goal = entry[`total_${type}_goal`];

    if (!goal) {
      return '';
    }

    const totalMonthly = entry[`remaining_${type}_seconds`] || 0;

    return totalMonthly >= 0 ? 'Incomplete' : 'Complete';
  };

  const SelectAll = () => {
    setCheckBoxValue(!CheckBoxValue);
    if (!CheckBoxValue) {
      setAllPatientIdArray(
        AllPatientIdArray.filter(
          (item, index) => AllPatientIdArray.indexOf(item) === index,
        ),
      );
      setPracticeArray(AllPracticeArray)
      setArray(AllPatientIdArray);
      setNameArray(AllPatientNameArray);
      setCounter(AllPatientIdArray.length);
    } else {
      setArray([]);
      setCounter(0);
      setNameArray([]);
          }
  };
  const CatchData = (res: any) => {
    setAllPatientIdArray(
      res.map(function(e) {
        return e.id;
      })
      
    );

      setAllPracticeArray( res.map(function(e) {
        return e.practice_id;
      }),);

    setAllPatientNameArray(
      res.map(function(e) {
        return e.first_name + ' ' + e.last_name;
      }),
    );

  
  };
  const handleCheck = (e, entry) => {
    if (array.includes(entry.id)) {
      counter--;
      setNameArray(
        NameArray.filter(
          value => value != entry.first_name + ' ' + entry.last_name,
        ),
      );

      setArray(array.filter(value => value != entry.id));
      setPracticeArray(PracticeArray.filter(value => value != entry.practice_id));

      setCounter(Counter - 1);
    } else {
      NameArray.push(entry.first_name + ' ' + entry.last_name);
      array.push(entry.id);
      PracticeArray.push(entry.practice_id)
      counter++;
      setCounter(Counter + 1);
    }
  };



  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      ...queryOptions,
      per: perPage,
      start: start_date,
      end: end_date,
    },
    initialSort: 'first_name:asc',
    columns: [
      {
        header: 'Select',
        isSortable: true,
        formatEntry: (entry: any) => {
          return (
            <div className="patients-index__contact">
              <button onClick={e => handleCheck(e, entry)}>
                <div>
                  <Checkbox isChecked={array.includes(entry.id)} />
                </div>
              </button>
            </div>
          );
        },
      },
      {
        header: 'Program',
        isSortable: true,
        attribute: 'patient_program',
        hideColumn: columns.program,
      },
      {
        header: 'Patient Status',
        isSortable: true,
        attribute: 'status',
        formatAttribute: displayPatientStatus,
        hideColumn: columns.patient_status,
      },
      {
        header: 'First Name',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'first_name',
        className: 'table__cell-highlight',
        hideColumn: columns.first_name,
        formatEntry: (entry: any) => {
          if (!entry.first_name) {
            return '';
          }

          const name = `${entry.first_name}`;
          var url: string;
          if (entry.total_rpm_goal) {
            url = buildPath(Routes.patientsCareManagementRpm, { id: entry.id });
          } else if (entry.total_ccm_goal) {
            url = buildPath(Routes.patientsCareManagement, { id: entry.id });
          } else {
            url = buildPath(Routes.patientsBhiPlan, { id: entry.id });
          }

          return <Link style={{ color: '#006989' }} to={url}>{name}</Link>;
        },
      },
      {
        header: 'Last Name',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'last_name',
        className: 'table__cell-highlight',
        hideColumn: columns.last_name,
        formatEntry: (entry: any) => {
          if (!entry.last_name) {
            return '';
          }

          const name = `${entry.last_name}`;
          var url: string;
          if (entry.total_rpm_goal) {
            url = buildPath(Routes.patientsCareManagementRpm, { id: entry.id });
          } else if (entry.total_ccm_goal) {
            url = buildPath(Routes.patientsCareManagement, { id: entry.id });
          } else {
            url = buildPath(Routes.patientsBhiPlan, { id: entry.id });
          }

          return <Link style={{ color: '#006989' }} to={url}>{name}</Link>;
        },
      },
      {
        header: 'DOB',
        isSortable: true,
        attribute: 'date_of_birth',
        formatAttribute: formatShortDate,
        hideColumn: columns.dob,
      },
      {
        header: 'Reading Days Count',
        isSortable: true,
        attribute: 'readings_day_count',
        hideColumn: columns.reading_day_count,
      },
      {
        header: 'Provider',
        isSortable: true,
        attribute: 'specialist_name',
        hideColumn: columns.provider,
      },
      {
        header: 'Practice',
        isSortable: true,
        attribute: 'practice_name',
        hideColumn: columns.practice,
      },
      {
        header: 'Language',
        isSortable: true,
        attribute: 'language',
        hideColumn: columns.language,
      },
      {
        header: 'Device Count',
        isSortable: true,
        attribute: 'device_counts',
        hideColumn: columns.device_count,
      },
      {
        header: 'Device Name',
        isSortable: true,
        attribute: 'devices_type_name',
        hideColumn: columns.device_type_name,
      },
      {
        header: 'Care Manager',
        isSortable: true,
        attribute: 'care_managers.first_name',
        formatEntry: entry => {
          return [entry.care_manager_first_name, entry.care_manager_last_name]
            .filter(n => n)
            .join(' ');
        },
        hideColumn: columns.care_manager,
      },
      serviceIncluded('ccm') && {
        header: 'CCM Phone',
        isSortable: true,
        attribute: 'total_ccm_phone',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.ccm_phone,
      },
      serviceIncluded('ccm') && {
        header: 'CCM Admin',
        isSortable: true,
        attribute: 'total_ccm_admin',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.ccm_admin,
      },
      serviceIncluded('ccm') && {
        header: 'CCM Total',
        isSortable: true,
        attribute: 'total_ccm_time',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.ccm_total,
      },
      serviceIncluded('ccm') && {
        header: 'CCM Goal',
        isSortable: true,
        attribute: 'total_ccm_goal',
        formatEntry: (entry: any) => formatSeconds(entry.total_ccm_goal * 60),
        hideColumn: columns.ccm_goal,
      },
      serviceIncluded('ccm') && {
        header: 'CCM Status',
        isSortable: true,
        attribute: 'remaining_ccm_seconds',
        formatEntry: (entry: any) => formatStatus(entry, 'ccm'),
        hideColumn: columns.ccm_status,
      },
      serviceIncluded('rpm') && {
        header: 'RPM Phone',
        isSortable: true,
        attribute: 'total_rpm_phone',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.rpm_phone,
      },
      serviceIncluded('rpm') && {
        header: 'RPM Admin',
        isSortable: true,
        attribute: 'total_rpm_admin',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.rpm_admin,
      },
      serviceIncluded('rpm') && {
        header: 'RPM Total',
        isSortable: true,
        attribute: 'total_rpm_time',
        formatAttribute: formatSecondsToHours,
        hideColumn: columns.rpm_total,
      },
      serviceIncluded('rpm') && {
        header: 'RPM Goal',
        isSortable: true,
        attribute: 'total_rpm_goal',
        formatEntry: (entry: any) => formatSeconds(entry.total_rpm_goal * 60),
        hideColumn: columns.rpm_goal,
      },
      serviceIncluded('rpm') && {
        header: 'RPM Status',
        isSortable: true,
        attribute: 'remaining_rpm_seconds',
        formatEntry: (entry: any) => formatStatus(entry, 'rpm'),
        hideColumn: columns.rpm_status,
      },
      {
        header: 'Last Call',
        isSortable: true,
        attribute: 'most_recent_call_time',
        formatAttribute: formatShortDate,
        hideColumn: columns.last_call,
      },
      {
        header: 'Last Call Status',
        isSortable: true,
        attribute: 'most_recent_call_status',
        formatAttribute: displayCallStatus,
        hideColumn: columns.last_call_status,
      },
      {
        header: 'Last Reading Date',
        isSortable: true,
        attribute: 'last_reading_date',
        formatAttribute: (entry: any) => {
            return formatShortDateTime(entry);
        },
        hideColumn: columns.last_reading_date,
      },
      {
        header: 'Most Recent Alert',
        isSortable: true,
        attribute: 'most_recent_alert',
        formatEntry: (entry: any) => {
          return formatShortDateTime(entry.most_recent_alert);
        },
        hideColumn: columns.alerts,
      },
    ].filter(n => n),
    rows: {
      customAction: (entry: any) => {
      },
      //   onClickPath: (entry: any) => {
      //     if (entry.total_rpm_goal) {
      //       history.push(
      //         buildPath(Routes.patientsCareManagementRpm, { id: entry.id }),
      //       );
      //     } else if (entry.total_ccm_goal) {
      //       history.push(
      //         buildPath(Routes.patientsCareManagement, { id: entry.id }),
      //       );
      //     } else {
      //       history.push(buildPath(Routes.patientsBhiPlan, { id: entry.id }));
      //     }
      //   },
    },
  };

  return (
    <>
      <Modal
        isNarrow={true}
        isVisible={columnSelectModalVisible}
        onCloseModal={toggleColumnSelectModal}>
        <ColumnSelectModalEdit
          columns={columns}
          onCloseModal={toggleColumnSelectModal}
          updateColumnState={updateColumnState}
        />
      </Modal>

      <Panel>
        <div className="columns patients-index__header-wrapper">
          <div className="column is-4-desktop">
            <h2 className="patients-index__header">Patients</h2>
          </div>
          <div className="column is-5-desktop">
            <InputIcon
              value={searchInput}
              onChange={handleSearchChange}
              placeholder="Search"
              onClick={() => handleSearchInput('search', searchInput)}
              onPressEnter={() => handleSearchInput('search', searchInput)}
            />
          </div>
          <div className="column is-3-desktop patients-index__header-button">
            <Button color="secondary" onClick={handleAddPatient}>
              Add Patient
            </Button>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="patients-index__checkboxes">
              <CheckboxGroup
                label="Chronic Care Managerment"
                onChange={() => toggleService('ccm')}
                value={serviceIncluded('ccm')}
              />
              <CheckboxGroup
                label="Remote Patient Monitoring"
                onChange={() => toggleService('rpm')}
                value={serviceIncluded('rpm')}
              />
              <CheckboxGroup
                label="Behavioral Health Integration"
                onChange={() => toggleService('bhi')}
                value={serviceIncluded('bhi')}
              />
              <CheckboxGroup
                label="My Patients"
                onChange={() => toggleMyPatients()}
                value={queryOptions.care_manager_id}
              />
              {/* <CheckboxGroup
                label="Patients With Alerts"
                onChange={() => togglePatientsWithAlerts()}
                value={queryOptions.alerts}
              />
              <CheckboxGroup
                label="Patients With Incomplete Tasks"
                onChange={() => togglePatientsWithIncompleteTasks()}
                value={queryOptions.incomplete_tasks}
              /> */}
            </div>
          </div>
        </div>

        <PatientEditAllFilter
          closeModal={closeModal}
          toggleColumnSelectModal={toggleColumnSelectModal}
          modalVisible={modalVisible}
          columnSelectModalVisible={columnSelectModalVisible}
          onApplyClick={handleApplyClick}
          onChange={handleChange}
          onClearFilters={handleClearFilters}
          onFilterClick={handlFilterClick}
          onPerPageChange={handlePerPageChange}
          perPage={perPage}
          queryOptions={queryOptions}
        />
        <div className="patients-index__checkboxes">
          <CheckboxGroup
            label="Select All"
            onChange={SelectAll}
            value={CheckBoxValue}
          />
        </div>
        <p>{Counter} patients selected</p>
        {Counter >= 1 ? (
          <div className="column is-12-desktop patients-index__header-button">
            <Button color="secondary" onClick={hanldeEdit}>
              Proceed to Edit
            </Button>
          </div>
        ) : null}
        {Show == 1 ? (
          <Table
            config={tableConfig}
            snapshot={snapshot}
            FormattedData={CatchData}
            whichModule={"PatientEditAll"}
          />
        ) : null}
        {isDevicesModalVisible == true ? (
          <Modal
            isVisible={isDevicesModalVisible}
            onCloseModal={closeEditModal}>
            <PatientEditAllModal
              patients_id={array}
              patients_name={NameArray}
              setPatient_id={setArray}
              onCloseModal={closeEditModal}
              practice_id={PracticeArray}
              ></PatientEditAllModal>
          </Modal>
        ) : null}
      </Panel>
    </>
  );
};

export default PatientEditAll;
