import { ApplicationState } from '@/types/application';

const initialState: ApplicationState = {
  isFetchingInitialData: true,
};

export enum ApplicationSagaActions {
  GET_APPLICATION_DATA = 'APPLICATION/GET_DATA',
  GET_APPLICATION_DATA_SUCCESS = 'APPLICATION/GET_DATA_SUCCESS',
  GET_APPLICATION_DATA_FAILURE = 'APPLICATION/GET_DATA_FAILURE',
}

export interface GetApplicationData {
  type: ApplicationSagaActions.GET_APPLICATION_DATA;
  payload: null;
}

export interface GetApplicationDataSuccess {
  type: ApplicationSagaActions.GET_APPLICATION_DATA_SUCCESS;
  payload: null;
}

export interface GetApplicationDataFailure {
  type: ApplicationSagaActions.GET_APPLICATION_DATA_FAILURE;
  payload: null;
}

export const getApplicationData = (): GetApplicationData => ({
  type: ApplicationSagaActions.GET_APPLICATION_DATA,
  payload: null,
});

export const getApplicationDataSuccess = (): GetApplicationDataSuccess => ({
  type: ApplicationSagaActions.GET_APPLICATION_DATA_SUCCESS,
  payload: null,
});

export const getApplicationDataFailure = (): GetApplicationDataFailure => ({
  type: ApplicationSagaActions.GET_APPLICATION_DATA_FAILURE,
  payload: null,
});

export default function reducer(
  state: ApplicationState = initialState,
  action,
): ApplicationState {
  const { type, payload } = action;

  switch (type) {
    case ApplicationSagaActions.GET_APPLICATION_DATA:
      return {
        ...state,
        isFetchingInitialData: true,
      };

    case ApplicationSagaActions.GET_APPLICATION_DATA_SUCCESS:
      return {
        ...state,
        isFetchingInitialData: false,
      };

    case ApplicationSagaActions.GET_APPLICATION_DATA_FAILURE:
      return {
        ...state,
        isFetchingInitialData: false,
      };

    default:
      return state;
  }
}
