import { createSelector } from 'reselect';

import { AppState } from '@/types/app';
import { MessagesState } from '@/types/v2/message';

export const selectMessagesState = (state: AppState): MessagesState =>
  state.messages;

export const selectMessages = createSelector(
  selectMessagesState,
  state => state.messages,
);
