import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

import { flashError, flashWarning } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import Datepicker from '@/components/form/Datepicker';

import { getSelectedPatient } from '@/selectors/api';
import { DeviceReading } from '@/types/device_reading';
import { buildRequestPath } from '@/utils/apiUtils';
import { formatIsoDate, formatShortDate } from '@/utils/dateUtils';

// interface Query {
//   end_at: string;
//   start_at: string;
// }



const RemoteDeviceReadingsGraph = props => {
  const {
    setBPChartData,
    setBGChartData,
    setBOChartData,
    setWeightChartData,
    setStartDate,
    setEndDate


  } = props;
  const query= {
  
    start_at:setStartDate,
    end_at:setEndDate

    };
  const dispatch = useDispatch();

  const [deviceReadings, setDeviceReadings] = useState<DeviceReading[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
 

  const patient = useSelector(getSelectedPatient);

  if (!patient.attributes.is_enrolled_rpm) {
    return null;
  }

  useEffect(() => {
    fetchDeviceReadings();
  },[setEndDate]);

  // Used to sort(byReadingTime) a list of DeviceReadings
  const byReadingTime = (a: DeviceReading, b: DeviceReading): number => {
    if (a.attributes.reading_time === b.attributes.reading_time) {
      return 0;
    }

    return a.attributes.reading_time > b.attributes.reading_time ? 1 : -1;
  };

  // Memoized readings by type

  const bloodPressureReadings = useMemo(() => {
    return deviceReadings
      .filter(
        (reading: DeviceReading) => reading.attributes.reading_type === '2',
      )
      .sort(byReadingTime);
  }, [deviceReadings]);

  const bloodGlucoseReadings = useMemo(() => {
    return deviceReadings
      .filter(
        (reading: DeviceReading) => reading.attributes.reading_type === '1',
      )
      .sort(byReadingTime);
  }, [deviceReadings]);

  const bloodOxygenReadings = useMemo(() => {
    return deviceReadings
      .filter(
        (reading: DeviceReading) => reading.attributes.reading_type === '7',
      )
      .sort(byReadingTime);
  }, [deviceReadings]);

  const weightReadings = useMemo(() => {
    return deviceReadings
      .filter(
        (reading: DeviceReading) => reading.attributes.reading_type === '8',
      )
      .sort(byReadingTime);
  }, [deviceReadings]);

  // Chart data is passed back to parent component for rendering in PDF

  useEffect(() => {
    if (bloodPressureReadings.length > 0) {
      const chartData = createChartData(
        bloodPressureReadings,
        'Systolic (mmHg)',
        'Diastolic (mmHg)',
        'Pulse (beats/min)'
      );
      setBPChartData(chartData);
    } else {
      const chartData = {
        labels: [],
        datasets: [],
        
      }
      setBPChartData(chartData);
    } 
  }, [bloodPressureReadings]);


  useEffect(() => {
    if (bloodGlucoseReadings.length > 0) {
      const chartData = createChartData(bloodGlucoseReadings, 'mg/dL');
      setBGChartData(chartData);
    }else {
      const chartData = {
        labels: [],
        datasets: [],
        
      }
      setBGChartData(chartData);
    } 
  }, [bloodGlucoseReadings]);

  useEffect(() => {
    if (bloodOxygenReadings.length > 0) {
      const chartData = createChartData(
        bloodOxygenReadings,
        'SpO2',
        'Pulse (beats/min)',
      );
      setBOChartData(chartData);
    }
    else {
      const chartData = {
        labels: [],
        datasets: [],
        
      }
      setBOChartData(chartData);
    } 
  }, [bloodOxygenReadings]);

  useEffect(() => {
    if (weightReadings.length > 0) {
      const chartData = createChartData(weightReadings, 'lbs');
      setWeightChartData(chartData);
    }   
    else {
      const chartData = {
        labels: [],
        datasets: [],
        
      }
      setWeightChartData(chartData);
    } 
  }, [weightReadings]);

  // API Call

  const fetchDeviceReadings = async () => {
    setIsLoading(true);

    try {
      const url = buildRequestPath('api/v1/device_readings', {
        patient_id: patient.id ,
        start_at: query.start_at ? query.start_at:'2020-05-26',
        // end_at: moment(query.end_at)
        //   .endOf('day')
        //   .format(),
        end_at: query.end_at ? query.end_at:'2019-05-01' ,
        per:null
      });

      const response = await Api.utility.get(url);

      setDeviceReadings(response.data.data);
    } catch (err) {
      dispatch(flashError('Unable to retrieve device readings'));
    }

    setIsLoading(false);
    // setStartDate(query.start_at);
    // setEndDate(query.end_at);

  };

  // Chart creating functionality

  const createChartData = (data: DeviceReading[], ...labels: string[]) => {
    const firstDay = moment(query.start_at);
    const lastDay = moment(query.end_at).add(1, 'day');
    const numberOfDays = lastDay.diff(firstDay, 'd');
    // console.log(firstDay,lastDay);
    
    // Creates an array of numbers that are the increments in days starting from the first day through the last.
    // For example, if the starting day is June first and the final day is June 7 this would produce an array of
    // the values 0 through 6
    const dayIncrements = Array.from(Array(numberOfDays).keys());
    
    const datasetData = [[], [], []];
    const dateLabels = [];
    
    for (const inc of dayIncrements) {
      const testDay = moment(firstDay).add(inc, 'days');
      
      // Get all the readings for the date we are testing that is n days ahead of the starting day
      const readings = data.filter((reading: DeviceReading) => {
        const readingData = moment(reading.attributes.reading_time);
        return readingData.isSame(testDay, 'day');
      });

      // Calculations for value_1, value_2, and value_3 will be determined based on the number of labels provided
      // For example if only the label "Systolic" is provided then only value_1 will be added to the dataset. If
      // "Systolic" and "Diastolic" are provided then both value_1 and value_2 will be added, and so on.
      for (const [i] of labels.entries()) {
        // Get total value of all readings to be calculated in average
        const total = readings.reduce(
          (accumulator, reading) =>
            accumulator + parseInt(reading.attributes[`value_${i + 1}`], 10),
          0,
        );

        const average = readings.length
          ? Math.floor(total / readings.length)
          : null;

        if (average !== null) {
          datasetData[i].push(average);

          if (i === 0) {
            dateLabels.push(formatShortDate(testDay.format()));
          }
        }
      }
    }

    const graphData = {
      labels: dateLabels,
      datasets: [],
    };

    const colors = [
      'rgba(37, 78, 112, 0.8)',
      'rgba(110, 189, 248, 0.8)',
      'rgba(114, 197, 99, 0.8)',
    ];

    for (const [i, label] of labels.entries()) {
      if (label === 'Systolic (mmHg)' || label === 'SpO2') {
        graphData.datasets.push({
          label,
          data: datasetData[i],
          backgroundColor: colors[i],
          borderColor: colors[i],
          fill: false,
          borderDash: [3, 3]

        });
      } else {
        graphData.datasets.push({
          label,
          data: datasetData[i],
          backgroundColor: colors[i],
          borderColor: colors[i],
          fill: false,

        });
      }
    }

    return graphData;
  };



  // const updateQuery = (field: string, value: string) => {
  //   const newQuery = {
  //     ...query,
  //     [field]: value,
  //   };

  //   const startAt = moment(newQuery.start_at);
  //   const endAt = moment(newQuery.end_at);

  //   if (startAt.isBefore(endAt) || startAt.isSame(endAt, 'day')) {
  //     setQuery(newQuery);
  //   } else {
  //     dispatch(flashWarning('Invalid date selection'));
  //   }
  // };


  return (
            <>
              {/* <div className="level-right">
            <div className="columns is-mobile">
              <div className="column">
                <Datepicker
                  label="From"
                  onChange={(value: string) => updateQuery('start_at', value)}
                  value={query.start_at}
                />
              </div>

              <div className="column">
                <Datepicker
                  label="To"
                  onChange={(value: string) => updateQuery('end_at', value)}
                  value={query.end_at}
                  maxDate={formatIsoDate()}
                />
              </div>
            </div>
          </div> */}
          </>

  );
};

export default RemoteDeviceReadingsGraph;