import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  withNarrowMargin?: boolean;
  withNarrowPadding?: boolean;
}

const Panel = (props: Props) => {
  const { children, withNarrowMargin, withNarrowPadding } = props;

  return (
    <div
      className={classNames('panel', {
        'has-narrow-margin': withNarrowMargin,
        'has-narrow-padding': withNarrowPadding,
      })}>
      {children}
    </div>
  );
};

export default Panel;
