import React from 'react';

import Panel from '@/components/panel/Panel';
import { MissedCall } from '@/types/v2/missed_call';
import { formatShortDateTime } from '@/utils/dateUtils';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  missedCall: MissedCall;
}

const UserMissedCall = ({ missedCall }: Props) => {
  const patientName = () => {
    if (missedCall.patient) {
      return fullName(missedCall.patient);
    }

    return 'Unknown';
  };

  return (
    <Panel>
      <div className="voicemail">
        <div className="columns is-multiline is-mobile">
          <div className="column is-6-mobile is-2-tablet">
            <h4>Caller</h4>
            <p>{patientName()}</p>
          </div>

          <div className="column is-6-mobile is-2-tablet">
            <h4>Call Time</h4>
            <p>{formatShortDateTime(missedCall.created_at)}</p>
          </div>

          <div className="column is-12-mobile is-8-tablet">
            <h4>Phone Number</h4>
            <p>{formatPhoneNumber(missedCall.phone_number)}</p>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default UserMissedCall;
