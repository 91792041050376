import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import ModalContent from '@/components/modal/ModalContent';
import {
  getPatientSpecialists,
  getSelectedPatientCareManager,
} from '@/selectors/api';
import { CarePlan, CarePlanAttributes } from '@/types/care_plan';
import { UUID } from '@/types/generic';
import { formatShortDate } from '@/utils/dateUtils';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  carePlanId: UUID;
}

const CarePlanModal = (props: Props) => {
  const { carePlanId } = props;

  const dispatch = useDispatch();

  const careManager = useSelector(getSelectedPatientCareManager);
  const specialists = useSelector(getPatientSpecialists);

  const [carePlan, setCarePlan] = useState<CarePlan>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (carePlanId) {
      getCarePlan();
    }
  }, [carePlanId]);

  const descriptionList = (attribute: string) => {
    if (carePlan) {
      return (
        <div className="care-plan__modal-row">
          {carePlan.data.attributes[attribute].map(p => {
            // If the text of the entry contains '--TIMEPERIOD--', search for the entry that corresponds to it and then
            // replace the text with a formatted value and unit. Example, 'Reach --TIMEPERIOD-- of good health' would
            // change to 'Reach 2 weeks of good health'

            if (p.description.indexOf('--TIMEPERIOD--') === -1) {
              return <span key={p.id}>{p.description}</span>;
            } else {
              const entryName = attribute.slice(0, -1);
              const entry = carePlan.data.attributes[
                `care_plan_${attribute}`
              ].find(e => e[`${entryName}_id`] === p.id);

              if (!entry) {
                return <span key={p.id}>{p.description}</span>;
              }

              const timePeriodText = `${entry.time_period_value} ${entry.time_period_unit}`;

              return (
                <span key={p.id}>
                  {p.description.replace('--TIMEPERIOD--', timePeriodText)}
                </span>
              );
            }
          })}
        </div>
      );
    }
  };

  const conditions = useMemo(() => {
    return descriptionList('conditions');
  }, [carePlan]);

  const goals = useMemo(() => {
    return descriptionList('goals');
  }, [carePlan]);

  const symptoms = useMemo(() => {
    return descriptionList('symptoms');
  }, [carePlan]);

  const barriers = useMemo(() => {
    return descriptionList('barriers');
  }, [carePlan]);

  const outcomes = useMemo(() => {
    return descriptionList('outcomes');
  }, [carePlan]);

  const support = useMemo(() => {
    if (carePlan) {
      const supportOptions = carePlan.data.attributes.care_plan_support_options.map(
        carePlanSupportOption => {
          const selectedOption = carePlan.data.attributes.support_options.find(
            supportOption =>
              supportOption.id === carePlanSupportOption.support_option_id,
          );

          return (
            <div
              className="care-plan__modal-row"
              key={carePlanSupportOption.id}>
              <span>{selectedOption.description}</span>
              {selectedOption.option_type === 'free_text' && (
                <span>{carePlanSupportOption.text}</span>
              )}
            </div>
          );
        },
      );

      const specialistOptions = specialists.map(specialist => {
        return (
          <div className="care-plan__modal-row" key={specialist.id}>
            <span>{specialist.attributes.name}</span>
            <span>{specialist.attributes.specialty}</span>
            <span>{specialist.attributes.email}</span>
            <span>{formatPhoneNumber(specialist.attributes.phone_number)}</span>
            <span>{specialist.attributes.address?.line_1}</span>
            <span>{specialist.attributes.address?.line_2}</span>
            <span>{specialist.attributes.address?.city}</span>
            <span>{specialist.attributes.address?.state}</span>
            <span>{specialist.attributes.address?.zip_code}</span>
          </div>
        );
      });

      return (
        <>
          {supportOptions} {specialistOptions}
        </>
      );
    }
  }, [carePlan]);

  const implications = useMemo(() => {
    if (carePlan) {
      return carePlan.data.attributes.implications.map(i => {
        const condition = carePlan.data.attributes.conditions.find(
          cnd => cnd.id === i.condition_id,
        );

        return (
          <div className="care-plan__modal-row" key={i.id}>
            <span>{condition.description}</span>
            <span>{i.points}</span>
            <span>{i.description}</span>
          </div>
        );
      });
    }
  }, [carePlan]);

  const getCarePlan = async () => {
    setIsLoading(true);

    try {
      const path = `api/v1/care_plans/${carePlanId}`;

      const response = await Api.utility.get(path);

      setCarePlan(response.data);
    } catch (_err) {
      dispatch('Error retrieving care plan');
    }

    setIsLoading(false);
  };

  if (!carePlan || isLoading) {
    return <ActivityIndicator />;
  }

  const c: CarePlanAttributes = carePlan.data.attributes;

  const careManagerName = fullName(careManager);

  return (
    <div className="patient-calls__modal-wrapper">
      <h3 className="patient-calls__modal-call-header">BHI Plan</h3>

      <div className="columns">
        <div className="column">
          <ModalContent label="Created" value={formatShortDate(c.created_at)} />
          <ModalContent label="Condition" value={conditions} />
          <ModalContent label="Goals" value={goals} />
          <ModalContent label="Symptoms" value={symptoms} />
        </div>

        <div className="column">
          <ModalContent label="Care Manager Name" value={careManagerName} />
          <ModalContent label="Barriers" value={barriers} />
          <ModalContent label="Support" value={support} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <ModalContent label="Expectd Outcomes" value={outcomes} />
          <ModalContent label="Tracking" value={implications} />
        </div>
      </div>
    </div>
  );
};

export default CarePlanModal;
