import React from 'react';

import { SideEffect } from '@/types/generic';
import Icon from '../icon/Icon';

interface Props {
  onClick: SideEffect;
}

const CloseButton = (props: Props) => {
  return (
    <button className="modal__close" {...props}>
      <Icon icon="close" />
    </button>
  );
};

export default CloseButton;
