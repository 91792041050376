import React from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';

import { buildPath, Routes } from '@/utils/routeUtils';
import { titleize, titleizeSnakeCase } from '@/utils/stringUtils';
import { IdParams } from '@/types/params';

interface Props {
  isActive?: boolean;
  isDisabled?: boolean;
  stepName: string;
  stepNumber: string;
  highlightText: string;
}

const EnrollmentSidebarLink = (props: Props) => {
  const { highlightText, isDisabled, stepName, stepNumber } = props;

  const history = useHistory();

  const { id } = useParams<IdParams>();

  const path = buildPath(Routes.patientsEnrollment, { id, step: stepName });

  const displayStepName = () => {
    const formattedName = stepName.includes('bhi_')
      ? titleize(stepName.replace('bhi_', '')) + ' (BHI)'
      : titleizeSnakeCase(stepName);

    return formattedName.replace('Outcomes', 'Expected Outcomes');
  };

  const renderContent = () => {
    return (
      <>
        <span>{stepNumber}</span>
        <span className="enrollment-tab__step">{displayStepName()}</span>
        <span>{highlightText}</span>
      </>
    );
  };

  const checkConsentPath = (event: any): void => {
    if (stepName === 'consent') {
      event.preventDefault();
      const nextPath = buildPath(Routes.patientsEnrollmentStep, {
        id,
        step: stepName,
        sub_step: '1',
      });

      history.push(nextPath);
    }
  };

  if (isDisabled) {
    return <div className="enrollment-tab is-disabled">{renderContent()}</div>;
  }

  return (
    <NavLink
      activeClassName="is-active"
      className="enrollment-tab"
      to={path}
      onClick={checkConsentPath}>
      {renderContent()}
    </NavLink>
  );
};

export default EnrollmentSidebarLink;
