import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import AppointmentModal from '@/modules/appointments/AppointmentModal';
import { getCurrentUser, selectCurrentUserId } from '@/selectors/users';
import { SearchQuery } from '@/types/table';
import { Specialist } from '@/types/v2/specialist';
import Modeler from '@/utils/modeler/modeler';
import { Routes } from '@/utils/routeUtils';
import { useHistory } from 'react-router-dom';
import PracticeVisitsTable from '../components/PracticeVisitsTable';
import VisitsSearchHeader, {
  FormState,
} from '../components/VisitsSearchHeader';

const initialState: FormState = {
  duration_maximum: '',
  duration_minimum: '',
  end_at: '',
  patient_id: '',
  per: '24',
  search: '',
  admin_user_id: '',
  start_at: '',
};

const Visits = () => {
  const history = useHistory();
  const user = useSelector(getCurrentUser);
  const userId = useSelector(selectCurrentUserId);

  const queryParams = new URLSearchParams(location.search || '');
  const appointmentId = queryParams.get('appointment');

  const specialist = useMemo(() => {
    return new Modeler<Specialist>(user).build();
  }, [user]);

  const [form, setForm] = useState<FormState>(initialState);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [practiceId, setPracticeId] = useState<string>(null);

  const handleChangeForm = (field: string, value: string): void => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleChangeSearch = (value: string): void => {
    setSearchQuery(value);
  };

  const handleClearFilters = (): void => {
    setForm(initialState);
  };

  const handleStartSearch = (): void => {
    handleChangeForm('search', searchQuery);
  };

  const handleCloseModal = () => {
    history.push(Routes.provider.practice.show.visits);
  };

  useEffect(() => {
    setPracticeId(specialist.practice_id);
  }, [specialist]);

  return (
    <>
      <Panel>
        <Container>
          <VisitsSearchHeader
            form={form}
            onChangeForm={handleChangeForm}
            onChangeSearch={handleChangeSearch}
            onClearFilters={handleClearFilters}
            onStartSearch={handleStartSearch}
            practiceId={practiceId}
            searchQuery={searchQuery}
          />

          {/* This Typing is needed to force compatibility in the form interface */}
          <PracticeVisitsTable
            practiceId={specialist.practice_id}
            query={(form as unknown) as SearchQuery}
          />
        </Container>
      </Panel>

      <AppointmentModal
        appointmentId={appointmentId}
        displayFor="specialist"
        onCloseModal={handleCloseModal}
        userId={userId}
      />
    </>
  );
};

export default Visits;
