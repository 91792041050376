import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import {
  getPatientPracticeDeviceModels,
  getPatientPracticeReadingThresholds,
} from '@/actions/actions/patients';
import { selectSelectedPatientPracticeId } from '@/selectors/api';
import { selectPracticeDeviceModelsPracticeId } from '@/selectors/patients';
import { Routes } from '@/utils/routeUtils';
import AlertRecipientsEdit from './AlertRecipientsEdit';
import AlertRecipientsNew from './AlertRecipientsNew';
import AlertsEdit from './AlertsEdit';
import AlertsNew from './AlertsNew';
import DevicesEdit from './DevicesEdit';
import DevicesNew from './DevicesNew';
import RemotePatientCareIndex from './RemotePatientCareIndex';

const RemotePatientCare = () => {
  const dispatch = useDispatch();

  const patientPracticeId = useSelector(selectSelectedPatientPracticeId);
  const currentPracticeId = useSelector(selectPracticeDeviceModelsPracticeId);

  dispatch(getPatientPracticeDeviceModels(patientPracticeId));
  dispatch(getPatientPracticeReadingThresholds(patientPracticeId));


  return (
    <Switch>
      <Route
        path={Routes.patientsAlertsShow}
        component={RemotePatientCareIndex}
      />
      <Route path={Routes.patientsAlertTriggersNew} component={AlertsNew} />
      <Route path={Routes.patientsAlertTriggersEdit} component={AlertsEdit} />
      <Route
        path={Routes.patientsAlertTriggersShow}
        component={RemotePatientCareIndex}
      />
      <Route path={Routes.patientsDevicesNew} component={DevicesNew} />
      <Route path={Routes.patientsDevicesEdit} component={DevicesEdit} />
      <Route
        path={Routes.patientsDevicesShow}
        component={RemotePatientCareIndex}
      />
      <Route
        path={Routes.patientsAlertRecipientsNew}
        component={AlertRecipientsNew}
      />
      <Route
        path={Routes.patientsAlertRecipientsEdit}
        component={AlertRecipientsEdit}
      />
      <Route
        path={Routes.patientsAlertRecipientsShow}
        component={RemotePatientCareIndex}
      />
      <Route
        path={Routes.patientsRemotePatientCare}
        component={RemotePatientCareIndex}
      />
    </Switch>
  );
};

export default RemotePatientCare;
