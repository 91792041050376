import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Modal from '@/components/modal/Modal';
import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { Tab, Tabs } from '@/components/tabs';
import { getSelectedPatient } from '@/selectors/api';
import { TableConfig } from '@/types/table';
import { formatShortDate } from '@/utils/dateUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { formatSeconds } from '@/utils/timeUtils';
import { useHistory, useParams } from 'react-router-dom';
import PatientLayout from '../../components/PatientLayout';
import ConditionComplexityModal from './ConditionComplexityModal';
import { UUID } from '@/types/generic';

const CONSENT_SEARCH_PATH = 'api/v1/consent_agreements';
const CONDITION_SEARCH_PATH = 'api/v1/condition_complexities';

interface Props {
  basePath?: string;
  shouldSkipLayout?: boolean;
  shouldSkipModification?: boolean;
}

interface Params {
  condition_complexity_id: UUID;
}

const AgreementsIndex = ({
  basePath,
  shouldSkipLayout,
  shouldSkipModification,
}: Props) => {
  const history = useHistory();

  const patient = useSelector(getSelectedPatient);

  const [_query, initialTab] = returnTabQuery();

  const [selectedTab, setSelectedTab] = useState<string>(
    initialTab || 'complexity',
  );

  const query = { return_tab: selectedTab };

  // Originally we were passing the complexity as a URL param. This turned out to be difficult to use.
  // Now that the component is used inother places it's easier to transition to using query params instead.
  const { condition_complexity_id } = useParams<Params>();

  const queryParams = new URLSearchParams(location.search || '');
  const complexity = queryParams.get('complexity');
  const complexityId = condition_complexity_id || complexity;
  const isModalVisible = !!complexityId;

  const handleCloseModal = () => {
    const path =
      basePath || buildPath(Routes.patientsAgreements, { id: patient.id });
    history.push(path);
  };

  const handleViewDownload = entry => {
    const attachments = entry.attributes.attachments;

    for (const attachment of attachments) {
      window.open(attachment.url, '_blank');
    }
  };

  const viewPath = (() => {
    if (basePath) {
      return buildPath(basePath, null, {
        complexity: ':condition_complexity_id',
      });
    }

    return buildPath(Routes.patientsConditionComplexitiesShow, {
      id: patient.id,
    });
  })();

  const complexityTableConfig: TableConfig = {
    searchPath: CONDITION_SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
      sort: 'date:desc',
    },
    emptyState: 'No agreements added yet',
    columns: [
      {
        header: 'Activity Date',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'date',
        formatAttribute: formatShortDate,
      },
      {
        header: 'Time Spent',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'duration',
        formatAttribute: formatSeconds,
      },
      {
        header: 'Provider',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'specialist_name',
        width: 2,
      },
      {
        header: 'Complexity',
        isSortable: false,
        isVisibleMobile: true,
        formatEntry: (entry: any) => {
          return entry.cpt_codes.map(code => (
            <div key={code.id}>{code.code}</div>
          ));
        },
      },
    ],
    rows: {
      viewPath,
      viewId: 'condition_complexity_id',
    },
    header: {
      addPath: shouldSkipModification
        ? null
        : buildPath(
            Routes.patientsConditionComplexitiesNew,
            {
              id: patient.id,
            },
            query,
          ),
    },
  };

  const consentTableConfig: TableConfig = {
    searchPath: CONSENT_SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
    },
    emptyState: 'No agreements added yet',
    columns: [
      {
        header: 'Date',
        isVisibleMobile: false,
        attribute: 'date',
        formatAttribute: formatShortDate,
      },
      {
        header: 'Protocol',
        isVisibleMobile: true,
        attribute: 'agreement_type',
        formatAttribute: value => {
          if (value === 'ccm_consent') {
            return 'CCM Consent';
          }

          return titleizeSnakeCase(value.toString());
        },
        width: 2,
      },
      {
        header: 'Relation to Patient',
        isVisibleMobile: false,
        attribute: 'relation_to_patient',
        width: 2,
      },
      {
        header: 'Provider',
        isVisibleMobile: false,
        attribute: 'specialist_name',
        width: 2,
      },
    ],
    rows: {
      viewPath: handleViewDownload,
    },
    header: {
      addPath: shouldSkipModification
        ? null
        : buildPath(
            Routes.patientsConsentAgreementsNew,
            {
              id: patient.id,
            },
            query,
          ),
    },
  };

  const renderTabContent = () => {
    if (selectedTab === 'complexity') {
      return <Table config={complexityTableConfig} key={selectedTab} />;
    }

    return <Table config={consentTableConfig} key={selectedTab} />;
  };

  const renderContent = (() => {
    return (
      <Panel>
        <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
          <Tab value="complexity">Condition Complexity</Tab>
          <Tab value="consent">Consent</Tab>
        </Tabs>

        {renderTabContent()}
      </Panel>
    );
  })();

  const renderModal = (() => {
    return (
      <Modal isVisible={isModalVisible} onCloseModal={handleCloseModal}>
        {isModalVisible && (
          <ConditionComplexityModal
            conditionComplexityId={complexityId}
            patientId={patient.id}
          />
        )}
      </Modal>
    );
  })();

  if (shouldSkipLayout) {
    return (
      <>
        {renderContent}
        {renderModal}
      </>
    );
  }

  return (
    <>
      <PatientLayout>{renderContent}</PatientLayout>

      {renderModal}
    </>
  );
};

export default AgreementsIndex;
