import React, { useEffect, useState } from 'react';

import Api from '@/api/Api';
import Constraint from '@/components/constraint/Constraint';
import Modal from '@/components/modal/Modal';
import Questionnaires from '@/modules/questionniares/response/Questionnaires';
import { UUID } from '@/types/generic';
import { VirtualAppointmentQuestionnaire } from '@/types/v2/questionnaire';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';

interface Props {
  appointmentId: UUID;
  onCloseModal: VoidFunction;
}

const QuestionnaireModal = ({
  appointmentId,
  onCloseModal,
}: Props): JSX.Element => {
  const [questionnaires, setQuestionnaires] = useState(null);

  const notifier = useNotifier();
  const loader = useLoadingState('questionnaires');

  const getQuestionanires = async (): Promise<void> => {
    loader.startLoading('questionnaires');

    try {
      const url = buildPath(
        Routes.api2.virtualAppointmentQuestionnaires,
        null,
        { virtual_appointment_id: appointmentId },
        [
          'questionnaire',
          'questionnaire.questionnaire_questions',
          'questionnaire.questionnaire_questions.questionnaire_answers',
          'questionnaire_responses',
          'questionnaire_responses.questionnaire_response_answers',
        ],
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<VirtualAppointmentQuestionnaire>(
        response.data,
        { generations: 3 },
      ).build();

      setQuestionnaires(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('questionnaires');
  };

  useEffect(() => {
    if (appointmentId) {
      getQuestionanires();
    }
  }, [appointmentId]);

  const renderContent = (() => {
    if (!appointmentId || !questionnaires) {
      return null;
    }

    if (loader.areAllComplete) {
      return (
        <Questionnaires
          questionnaires={questionnaires}
          onSaveComplete={onCloseModal}
        />
      );
    }

    return <loader.activity />;
  })();

  return (
    <Modal isVisible={!!appointmentId} onCloseModal={onCloseModal}>
      <Constraint height="medium">{renderContent}</Constraint>
    </Modal>
  );
};

export default QuestionnaireModal;
