import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';

import {
  fetchCurrentCarePlan,
  setCurrentCarePlan,
} from '@/actions/reducers/bhi';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import Enrollment from '@/contexts/enrollment/Enrollment';
import ActionHistory from '@/contexts/patients/modules/action_history/ActionHistory';
import Agreements from '@/contexts/patients/modules/agreements/Agreements';
import Allergies from '@/contexts/patients/modules/allergies/Allergies';
import Appointments from '@/contexts/patients/modules/appointments/Appointments';
import CareManagement from '@/contexts/patients/modules/care_management/CareManagement';
import Contacts from '@/contexts/patients/modules/contacts/Contacts';
import Immunizations from '@/contexts/patients/modules/immunizations/Immunizations';
import Labs from '@/contexts/patients/modules/labs/Labs';
import MedicalRecords from '@/contexts/patients/modules/medical_records/MedicalRecords';
import Medications from '@/contexts/patients/modules/medications/Medications';
import Resource from '@/contexts/patients/modules/Resource';
import Specialists from '@/contexts/patients/modules/specialists/Specialists';
import { getSelectedPatient } from '@/selectors/api';
import { selectIsFetchingCarePlan } from '@/selectors/bhi';
import { IdParams } from '@/types/params';
import { Patient } from '@/types/patient';
import useGetRequest from '@/utils/api/useGetRequest';
import { buildPath, Routes } from '@/utils/routeUtils';
import BhiPlan from './modules/bhi_plan/BhiPlan';
import CarePlan from './modules/care_plan/CarePlan';
import DeviceReadings from './modules/device_readings/DeviceReadings';
import RemotePatientCare from './modules/remote_patient_care/RemotePatientCare';

const PatientsShow = () => {
  const dispatch = useDispatch();

  const { id } = useParams<IdParams>();

  const isFetchingCarePlan: boolean = useSelector(selectIsFetchingCarePlan);
  const patient: Patient = useSelector(getSelectedPatient);

  const hasSelectedPatient = patient && patient.id === id;

  const [getPatient, isLoadingPatient] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    id,
    key: 'selectedPatient',
    model: 'patients',
    onSuccess: (response: Patient) => {
      const carePlanId = response.data.attributes.pending_care_plan_id;
      dispatch(fetchCurrentCarePlan(carePlanId));
    },
  });

  const [getPatientCalls] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    key: 'selectedPatientCalls',
    model: 'calls',
    url: buildPath(
      'api/v1/calls',
      {},
      {
        patient_id: id,
        status: 'scheduled',
        include: 'care_manager,recording_attachment',
      },
    ),
  });

  useEffect(() => {
    checkForSelectedPatient();
  }, []);

  const checkForSelectedPatient = () => {
    if (!hasSelectedPatient) {
      getPatient();
      getPatientCalls();
    }
  };

  if (!patient || isLoadingPatient || isFetchingCarePlan) {
    return <ActivityIndicator />;
  }

  return (
    <Switch>
      <Route path={Routes.patientsActionHistory} component={ActionHistory} />
      <Route path={Routes.patientsAgreementsSlugs} component={Agreements} />
      <Route path={Routes.patientsAllergies} component={Allergies} />
      <Route path={Routes.patientsAppointments} component={Appointments} />
      <Route path={Routes.patientsBhiPlan} component={BhiPlan} />
      <Route
        path={Routes.patientsCareManagementSlugs}
        component={CareManagement}
      />
      <Route path={Routes.patientsCarePlan} component={CarePlan} />
      <Route path={Routes.patientsContacts} component={Contacts} />
      <Route path={Routes.patientsEnrollmentIndex} component={Enrollment} />
      <Route path={Routes.patientsImmunizations} component={Immunizations} />
      <Route path={Routes.patientsLabsSlugs} component={Labs} />
      <Route path={Routes.patientsMedications} component={Medications} />
      <Route
        path={Routes.patientsMedicalRecordsSlugs}
        component={MedicalRecords}
      />
      <Route
        path={Routes.patientsRemotePatientCareSlugs}
        component={RemotePatientCare}
      />
      <Route path={Routes.patientsSpecialists} component={Specialists} />
      <Route path={Routes.readings} component={DeviceReadings} />
      <Route path={Routes.patientsResource} component={Resource} />
      {/* patientsShow must be last because it's most generic */}
      <Route path={Routes.patientsShow} component={CareManagement} />
    </Switch>
  );
};

export default PatientsShow;
