import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { refreshPatientAction } from '@/actions/sagas/patientSaga';
import ContactsIndex from '@/contexts/patients/modules/contacts/ContactsIndex';
import { getSelectedPatient } from '@/selectors/api';
import usePutRequest from '@/utils/api/usePutRequest';
import { buildPath, Routes } from '@/utils/routeUtils';
import EnrollmentHeader from '../components/EnrollmentHeader';
import { getStepInformation } from '../helpers/steps';

const Contacts = () => {
  const dispatch = useDispatch();

  const patient = useSelector(getSelectedPatient);

  const stepInformation = getStepInformation('contacts', patient);

  const nextScreen = buildPath(Routes.patientsEnrollment, {
    id: patient.id,
    step: stepInformation.nextStep,
  });

  const body = {
    enrollment_step: stepInformation.currentStep,
    patient: {},
  };

  const [saveForm] = usePutRequest({
    dispatch,
    url: `api/v1/patients/${patient.id}`,
    body,
    dispatchToStore: true,
    key: 'selectedPatient',
    onSuccess: () => {
      dispatch(refreshPatientAction(patient.id));
    },
  });

  useEffect(() => {
    saveForm();
  }, []);

  return (
    <div className="enrollment-wrapper">
      <EnrollmentHeader
        description="Who are the patient's family contacts?"
        linkTo={nextScreen}
        linkText={stepInformation.nextStepTitle}
        title={stepInformation.currentStepTitle}
      />

      <div className="enrollment-table-wrapper">
        <ContactsIndex />
      </div>
    </div>
  );
};

export default Contacts;
