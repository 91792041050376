import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ContactsEdit from '@/contexts/patients/modules/contacts/ContactsEdit';
import ContactsIndex from '@/contexts/patients/modules/contacts/ContactsIndex';
import ContactsNew from '@/contexts/patients/modules/contacts/ContactsNew';
import { Routes } from '@/utils/routeUtils';

const Patients = () => {
  return (
    <Switch>
      <Route path={Routes.patientsContactsEdit} component={ContactsEdit} />
      <Route path={Routes.patientsContactsNew} component={ContactsNew} />
      <Route path={Routes.patientsContacts} component={ContactsIndex} />
    </Switch>
  );
};

export default Patients;
