import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import ExternalUsersIndex from '@/contexts/users/ExternalUsersIndex';
import { Routes } from '@/utils/routeUtils';
import ExternalUsersForm from './components/ExternalUsersForm';

export const ExternalUsers = () => {
  return (
    <Protected allowedRoles={PolicyGroups.nonBillingAdmins}>
      <Switch>
        <Route path={Routes.externalUsers.new} component={ExternalUsersForm} />
        <Route
          path={Routes.externalUsers.root}
          component={ExternalUsersIndex}
        />
      </Switch>
    </Protected>
  );
};

export default ExternalUsers;
