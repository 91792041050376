import React, { useEffect, useRef, useState } from 'react';

import { setActiveKeypadModalVisibility } from '@/actions/reducers/call_manager';
import Keypad from '@/components/keypad/Keypad';
import Modal from '@/components/modal/Modal';
import { SideEffect } from '@/types/generic';
import { useDispatch } from 'react-redux';
import KeypadDisplay from '../keypad/KeypadDisplay';

interface Props {
  isVisible: boolean;
  onAddNumber: SideEffect;
}

const ActiveKeypadModal = (props: Props) => {
  const { isVisible, onAddNumber } = props;

  const isVisibleRef = useRef(false);
  const enteredNumberRef = useRef('');

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    isVisibleRef.current = isVisible;
    enteredNumberRef.current = '';
  }, [isVisible]);

  const handleSendKeySignal = (key: string) => {
    enteredNumberRef.current = `${enteredNumberRef.current}${key}`;
    onAddNumber(key);
  };

  const handleCloseModal = () => {
    dispatch(setActiveKeypadModalVisibility(false));
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (isVisibleRef.current && /\d|Backspace/.test(e.key)) {
      handleSendKeySignal(e.key);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal isVisible onCloseModal={handleCloseModal}>
      <div className="columns is-centered">
        <div className="column is-7-touch is-7-desktop">
          <KeypadDisplay value={enteredNumberRef.current} />
          <Keypad onAddNumber={handleSendKeySignal} />
        </div>
      </div>
    </Modal>
  );
};

export default ActiveKeypadModal;
