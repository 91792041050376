import React from 'react';

import ErrorMessage from '@/components/form/ErrorMessage';
import Label from '@/components/form/Label';
import TypeaheadSelect, {
  Props as TypeaheadSelectProps,
} from '@/components/typeahead/TypeaheadSelect';
import { SideEffect } from '@/types/generic';
import { FormattedResult } from '../typeahead/Typeahead';

interface Props extends TypeaheadSelectProps {
  clearFalseResultOnBlur?: boolean;
  isDisabled?: boolean;
  label: string;
  minimumInputLength?: number;
  onChange?: SideEffect;
  queryParams?: any;
  validationMessage?: string;
}

export const TypeaheadSelectGroup = (props: Props) => {
  const { label, validationMessage, ...typeaheadProps } = props;

  const isInvalid = !!validationMessage;

  return (
    <div className="form-input-group">
      <Label isInvalid={isInvalid}>{label}</Label>
      <TypeaheadSelect isInvalid={isInvalid} {...typeaheadProps} />
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </div>
  );
};

export default TypeaheadSelectGroup;
