import { UUID } from '@/types/generic';
import { QuestionnaireableType } from '@/types/v2/questionnaire';
import {
  FormBody,
  FormQuestion,
  FormState,
  FormValidations,
  Question,
} from './types';

export const blankQuestion: Question = {
  position: 1,
  question_type: 'free_text',
  questionnaire_answers: [],
  text: '',
};

export const formatFormBody = (
  form: FormState,
  id: UUID,
  type: QuestionnaireableType,
): FormBody => {
  const questions = form.questionnaire_questions.map(question => {
    const formattedQuestion = {
      ...question,
      questionnaire_answers_attributes: question.questionnaire_answers,
    };

    delete formattedQuestion.questionnaire_answers;

    return formattedQuestion as FormQuestion;
  });

  const body = {
    ...form,
    questionnaireable_id: id,
    questionnaireable_type: type,
    questionnaire_questions_attributes: questions,
  };

  delete body.questionnaire_questions;

  return {
    questionnaire: body,
  };
};

export const validateForm = (form: FormState): [boolean, FormValidations] => {
  // Because of the nesting all of the validations need to be done manually, unfortunately
  let isValid = true;
  const validations: FormValidations = {
    questionnaire_questions: [],
  };

  if (!form.name) {
    validations.name = 'Field is required';
    isValid = false;
  }

  for (const question of form.questionnaire_questions) {
    const questionValidations: any = {
      questionnaire_answers: [],
    };

    if (!question._destroy) {
      if (!question.text) {
        questionValidations.text = 'Field is required';
        isValid = false;
      }

      const activeQuestions = question.questionnaire_answers.filter(
        q => !q._destroy,
      ).length;

      if (question.question_type !== 'free_text' && activeQuestions < 2) {
        questionValidations.question_type = 'At least 2 answers are required';
        isValid = false;
      }
    }

    if (question.question_type !== 'free_text') {
      for (const answer of question.questionnaire_answers) {
        const answerValidations: any = {};

        if (!answer.text && !answer._destroy) {
          answerValidations.text = 'Field is required';
          isValid = false;
        }

        questionValidations.questionnaire_answers.push(answerValidations);
      }
    }

    validations.questionnaire_questions.push(questionValidations);
  }

  return [isValid, validations];
};
