import React, { useState } from 'react';

import Button from '@/components/button/Button';
import SearchGroup from '@/components/form/SearchGroup';
import SelectGroup from '@/components/form/SelectGroup';
import Icon from '@/components/icon/Icon';
import Modal from '@/components/modal/Modal';
import { getCcmComplexityOptions } from '@/selectors/options';
import { SideEffect } from '@/types/generic';
import { callStatusEnumOptions } from '@/utils/callUtils';
import {
  ccmMinutes,
  languageOptions,
  monthOptions,
  orphanDeviceStatusFilterOptions,
  renderYearOptions,
} from '@/utils/dropdownUtils';
import { patientStatusEnumOptions } from '@/utils/patientUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { patientsPerPage } from '@/utils/PatientSelectAllUtils';
import { fullName } from '@/utils/userUtils';
import { useSelector } from 'react-redux';
import PatientFilterModal from './PatientFilterModal';

interface Props {
  closeModal: SideEffect;
  toggleColumnSelectModal: SideEffect;
  modalVisible: boolean;
  columnSelectModalVisible: boolean;
  onApplyClick: SideEffect;
  onChange: SideEffect;
  onClearFilters: SideEffect;
  onFilterClick: SideEffect;
  onPerPageChange: SideEffect;
  perPage: any;
  queryOptions: { [key: string]: any };
}

const PatientEditAllFilter = (props: Props) => {
  const {
    closeModal,
    toggleColumnSelectModal,
    modalVisible,
    columnSelectModalVisible,
    onApplyClick,
    onChange,
    onClearFilters,
    onFilterClick,
    onPerPageChange,
    perPage,
    queryOptions,
  } = props;

  const ccmComplexityOptions = useSelector(getCcmComplexityOptions);

  const [areAdvancedFiltersVisible, setAreAdvancedFiltersVisible] = useState<
    boolean
  >(false);

  const formatSearchResult = (result: any) => ({
    label: result.attributes.name,
    value: result.id,
  });

  const formatCareManagerResult = (result: any) => {
    return {
      label: fullName(result),
      value: result.id,
    };
  };

  const renderFilterButton = () => {
    const action = areAdvancedFiltersVisible ? 'Hide' : 'Show';
    const rotation = areAdvancedFiltersVisible ? 180 : 0;

    return (
      <button
        className="patient-filter__toggle"
        onClick={() =>
          setAreAdvancedFiltersVisible(!areAdvancedFiltersVisible)
        }>
        {`${action} Advanced Filters`}{' '}
        <Icon icon="caretDown" rotation={rotation} />
      </button>
    );
  };

  const renderSearchDisplay = () => {
    const activeFilters = [];

    for (const key of Object.keys(queryOptions)) {
      if (queryOptions[key]) {
        if (key === 'search') {
          activeFilters.push(`'${queryOptions[key]}'`);
        } else if (key !== 'services') {
          activeFilters.push(titleizeSnakeCase(key));
        }
      }
    }

    if (!activeFilters.length) {
      return null;
    }

    const filterList = activeFilters.join(', ');

    return (
      <div className="columns">
        <div className="column">
          <div className="patient-filter__clear">
            <span>Search Results for: {filterList}</span>
            <a onClick={onClearFilters}>Clear search</a>
          </div>
        </div>
      </div>
    );
  };

  const renderAdvancedFilters = () => {
    if (!areAdvancedFiltersVisible) {
      return null;
    }
    return (
      <>
        <div className="columns is-mobile">
          <div className="column is-hidden-touch">
            <h4 className="patient-filter__header-small">Advanced Filters</h4>
          </div>
        </div>

        <div className="columns is-hidden-touch is-multiline">
          <div className="column is-1">
            <SelectGroup
              label="Date of Birth"
              options={renderYearOptions('1900')}
              value={queryOptions.year}
              onChange={value => onChange('year', value)}
              placeholder="Year"
            />
          </div>

          <div className="column is-1">
            <SelectGroup
              options={monthOptions}
              value={queryOptions.month}
              onChange={value => onChange('month', value)}
              placeholder="Month"
            />
          </div>

          <div className="column is-1">
            <SelectGroup
              label="Enrollment Date"
              options={renderYearOptions('2000')}
              value={queryOptions.enrollment_year}
              onChange={value => onChange('enrollment_year', value)}
              placeholder="Year"
            />
          </div>

          <div className="column is-1">
            <SelectGroup
              options={monthOptions}
              value={queryOptions.enrollment_month}
              onChange={value => onChange('enrollment_month', value)}
              placeholder="Month"
            />
          </div>

          <div className="column is-2">
            <SelectGroup
              label="Patient Status"
              options={patientStatusEnumOptions}
              value={queryOptions.status}
              onChange={value => onChange('status', value)}
              placeholder="Select Patient Status"
            />
          </div>

          <div className="column is-2">
            <SelectGroup
              label="CCM Minutes"
              options={ccmMinutes}
              value={queryOptions.ccm_minutes}
              onChange={value => onChange('ccm_minutes', value)}
              placeholder="Select CCM Minutes"
              skipEmptyPlaceholder
            />
          </div>

          <div className="column is-2">
            <SelectGroup
              label="CCM Complexity"
              options={ccmComplexityOptions}
              value={queryOptions.ccm_complexity_code}
              onChange={value => onChange('ccm_complexity_code', value)}
              placeholder="Select CCM Complexity"
            />
          </div>

          <div className="column is-2">
            <SelectGroup
              label="RPM Minutes"
              options={ccmMinutes}
              value={queryOptions.rpm_minutes}
              onChange={value => onChange('rpm_minutes', value)}
              placeholder="Select RPM Minutes"
              skipEmptyPlaceholder
            />
          </div>

          <div className="column is-2">
            <SelectGroup
              label="RPM Complexity"
              options={ccmComplexityOptions}
              value={queryOptions.rpm_complexity_code}
              onChange={value => onChange('rpm_complexity_code', value)}
              placeholder="Select RPM Complexity"
            />
          </div>

          <div className="column is-2">
            <SelectGroup
              label="Language"
              options={languageOptions()}
              value={queryOptions.language}
              onChange={value => onChange('language', value)}
              placeholder="Select language"
            />
          </div>
          <div className="column is-2">
            <SelectGroup
              label="Device Filter"
              options={orphanDeviceStatusFilterOptions}
              value={queryOptions.associated}
              onChange={value => onChange('associated', value)}
              placeholder="Select orphan/non-orphan devices"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="patient-filter">
      {renderSearchDisplay()}

      <div className="columns is-mobile">
        <div className="column is-hidden-touch">
          <h3 className="patient-filter__header">Filter</h3>
        </div>
      </div>

      <div className="columns is-mobile is-hidden-touch">
        <div className="column">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchResult}
            guideValue={queryOptions.health_system_id}
            label="Health System"
            minimumInputLength={1}
            onChange={value => onChange('health_system_id', value)}
            placeholder="Search Health Systems"
            searchPath="api/v1/health_systems"
          />
        </div>
        <div className="column">
          <SearchGroup
            clearFalseResultOnBlur
            isDisabled={!queryOptions.health_system_id}
            guideValue={queryOptions.hospital_id}
            formatResult={formatSearchResult}
            label="Hospital"
            minimumInputLength={1}
            onChange={value => onChange('hospital_id', value)}
            placeholder="Search Hospitals"
            queryParams={{ health_system_id: queryOptions.health_system_id }}
            searchPath="api/v1/hospitals"
          />
        </div>
        <div className="column">
          <SearchGroup
            clearFalseResultOnBlur
            isDisabled={!queryOptions.hospital_id}
            formatResult={formatSearchResult}
            guideValue={queryOptions.practice_id}
            label="Practice"
            minimumInputLength={1}
            onChange={value => onChange('practice_id', value)}
            placeholder="Search Practices"
            queryParams={{ hospital_id: queryOptions.hospital_id }}
            searchPath="api/v1/practices"
          />
        </div>
        <div className="column">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatCareManagerResult}
            guideValue={queryOptions.care_manager_id}
            label="Care Manager"
            minimumInputLength={1}
            onChange={value => onChange('care_manager_id', value)}
            placeholder="Search Care Managers"
            searchPath="api/v1/care_managers"
          />
        </div>
        <div className="column">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchResult}
            guideValue={queryOptions.specialist_id}
            label="Provider"
            minimumInputLength={1}
            onChange={value => onChange('specialist_id', value)}
            placeholder="Search Providers"
            searchPath="api/v1/specialists"
          />
        </div>
        <div className="column">
          <SelectGroup
            label="Call Status"
            options={callStatusEnumOptions}
            value={queryOptions.most_recent_call_status}
            onChange={changedValue =>
              onChange('most_recent_call_status', changedValue)
            }
            placeholder="Select Call"
          />
        </div>
      </div>

      {renderAdvancedFilters()}

      <div className="columns is-hidden-touch">
        <div className="column">{renderFilterButton()}</div>
      </div>

      <div className="columns patient-filter__show">
        <div className="column is-6-mobile is-hidden-desktop">
          <Button color="white" onClick={onFilterClick}>
            {'Filter'}
          </Button>
        </div>
        <div className="column is-6-mobile is-3-tablet is-2-desktop">
          <Button color="white" onClick={toggleColumnSelectModal}>
            Hide/Show
          </Button>
        </div>
        <div className="column is-6-mobile is-3-tablet is-2-desktop">
          <SelectGroup
            label="Show"
            options={patientsPerPage}
            value={perPage}
            onChange={changedValue => onPerPageChange(changedValue)}
          />
        </div>
      </div>

      <Modal isVisible={modalVisible} onCloseModal={closeModal}>
        <PatientFilterModal
          advancedQueryOptions={queryOptions}
          onChange={onChange}
          onClick={onApplyClick}
        />
      </Modal>
    </div>
  );
};

export default PatientEditAllFilter;
