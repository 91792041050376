import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import { Routes } from '@/utils/routeUtils';
import Alerts from './alerts/Alerts';
import Appointment from './appointments/Appointment';
import VirtualAppointmentForm from './appointments/VirtualAppointmentForm';
import Calendar from './calendar/Calendar';
import Dashboard from './dashboard/Dashboard';
import Patients from './patients/Patients';
import PatientsShow from './patients/PatientsShow';
import PracticeLayout from './practice/components/PracticeLayout';
import Billing from './practice/modules/Billing';
import PracticeCalendar from './practice/modules/Calendar';
import ProviderProfile from './practice/modules/ProviderProfile';
import Providers from './practice/modules/Providers';
import Questionnaires from './practice/modules/Questionnaires';
import Visits from './practice/modules/Visits';
import PracticesEdit from './practice/PracticeEdit';
import PracticeQuestionnaireForm from './questionnaires/PracticeQuestionnaireForm';
import ProviderQuestionnaireForm from './questionnaires/ProviderQuestionnaireForm';

const Provider = () => {
  return (
    <Protected allowedRoles={PolicyGroups.practiceManagers}>
      <Switch>
        <Route
          path={Routes.provider.appointments.new}
          component={VirtualAppointmentForm}
        />
        <Route
          path={Routes.provider.appointments.edit}
          component={VirtualAppointmentForm}
        />
        <Route
          path={Routes.provider.appointments.show}
          component={Appointment}
        />
        <Route path={Routes.provider.alerts.root} component={Alerts} />

        <Route
          path={Routes.provider.patients.show.root}
          component={PatientsShow}
        />
        <Route path={Routes.provider.patients.root} component={Patients} />

        <Route
          path={Routes.provider.practice.show.questionnaires.new}
          component={PracticeQuestionnaireForm}
        />

        <Route
          path={Routes.provider.practice.show.questionnaires.edit}
          component={PracticeQuestionnaireForm}
        />

        <Route
          path={Routes.provider.practice.show.provider}
          component={ProviderProfile}
        />

        <Route path={Routes.provider.practice.edit} component={PracticesEdit} />

        <Route path={Routes.provider.practice.show.root}>
          <PracticeLayout>
            <Route
              path={Routes.provider.practice.show.billing}
              component={Billing}
            />
            <Route
              path={Routes.provider.practice.show.calendar}
              component={PracticeCalendar}
            />
            <Route
              path={Routes.provider.practice.show.providers}
              component={Providers}
            />
            <Route
              path={Routes.provider.practice.show.questionnaires.root}
              component={Questionnaires}
            />
            <Route
              path={Routes.provider.practice.show.visits}
              component={Visits}
            />
          </PracticeLayout>
        </Route>

        <Route
          path={Routes.provider.questionnaires.new}
          component={ProviderQuestionnaireForm}
        />
        <Route
          path={Routes.provider.questionnaires.edit}
          component={ProviderQuestionnaireForm}
        />
        <Route path={Routes.provider.root} component={Calendar} />
      </Switch>
    </Protected>
  );
};

export default Provider;
