import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import { FormComponentConfig } from '@/types/form';
import { UUID } from '@/types/generic';
import { IdParams } from '@/types/params';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { relationOptions } from '@/utils/dropdownUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';

interface Params extends IdParams {
  patient_id: UUID;
}

const AlertRecipientsEdit = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { patient_id, id } = useParams<Params>();

  const [query] = returnTabQuery();

  const [response, setResponse] = useState(null);

  const backPath = buildPath(
    Routes.patientsRemotePatientCare,
    { id: patient_id },
    query,
  );

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'alert_recipients',
    onSuccess: (res: any) => {
      setResponse(parseResponseData(res));
    },
  });

  useEffect(() => {
    getEntry();
  }, []);

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: `api/v1/alert_recipients/${id}`,
      model: 'alert_recipient',
      method: 'PUT',
      onSuccess: () => history.push(backPath),
      onCancel: () => history.push(backPath),
      deleteText: 'Remove Recipient',
    },
    state: response.attributes,
    rules: {
      name: { required: true },
      email: { type: 'email' },
      relation: { required: true },
      sms_phone_number: { type: 'phone' },
      voice_phone_number: { type: 'phone' },
    },
    layout: [
      {
        title: 'Edit Recipient',
        fields: [
          [
            {
              label: 'Name',
              field: 'name',
              placeholder: 'Enter name',
              type: 'text',
              size: 4,
            },
            {
              label: 'Email Address',
              field: 'email',
              placeholder: 'Enter Recipient Email',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Relation to Patient',
              field: 'relation',
              placeholder: 'Select Relation',
              type: 'select',
              size: 4,
              options: relationOptions,
            },
            {
              label: 'SMS Phone',
              field: 'sms_phone_number',
              placeholder: 'Enter SMS Phone Number',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Voice Phone',
              field: 'voice_phone_number',
              placeholder: 'Enter Voice Phone Number',
              type: 'text',
              size: 4,
            },
          ],
        ],
      },
    ],
  };
  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Recipients"
      config={config}
    />
  );
};

export default AlertRecipientsEdit;
