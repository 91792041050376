import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getSelectedPatient } from '@/selectors/api';
import { getManualEntryReasonOptions } from '@/selectors/options';
import { getCurrentUser } from '@/selectors/users';
import { FormComponentConfig } from '@/types/form';
import { callStatusOptions } from '@/utils/dropdownUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { combineDateAndTime } from '@/utils/timeUtils';
import { fullName } from '@/utils/userUtils';
import { useSelector } from 'react-redux';
import PatientForm from '../../components/PatientForm';
import { IdParams } from '@/types/params';

const CallsNew = () => {
  const history = useHistory();

  const { id } = useParams<IdParams>();

  const patient = useSelector(getSelectedPatient);

  const [query] = returnTabQuery();

  const backPath = buildPath(
    query.service === 'rpm'
      ? Routes.patientsCareManagementRpm
      : Routes.patientsCareManagement,
    { id },
    query,
  );

  const manualEntryReasonOptions = useSelector(getManualEntryReasonOptions);

  const careManager = useSelector(getCurrentUser);

  const initialSelection = () => {
    return {
      label: `${careManager.data.attributes.first_name} ${careManager.data.attributes.last_name}`,
      value: careManager.data.id,
    };
  };

  const serviceOptions = useMemo(() => {
    return patient.attributes.patient_services.data.map(service => ({
      label: service.attributes.name,
      value: service.attributes.service_id,
    }));
  }, [patient]);

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      alterFormBeforeSubmit: (formState: any) => {
        const { hours, minutes, start_at_date, start_at_time } = formState;

        const hoursInt = parseInt(hours || 0, 10);
        const minutesInt = parseInt(minutes || 0, 10);

        // Duration in seconds
        const duration = (hoursInt * 60 + minutesInt) * 60;

        const startAt = combineDateAndTime(start_at_date, start_at_time);

        return {
          ...formState,
          duration,
          start_at: startAt,
          patient_id: id,
          include: 'care_manager',
        };
      },
      path: 'api/v1/calls',
      model: 'call',
      method: 'POST',
      onSuccess: () => history.push(backPath),
    },
    state: {
      duration: '',
      duration_includes_time_spent_on_phone: false,
      hours: '',
      interaction_reason: '1',
      interaction_reason_select: '',
      manual_entry_reason: '',
      minutes: '',
      patient_phone_number: '',
      service_id: serviceOptions.length === 1 ? serviceOptions[0].value : '',
      start_at_date: '',
      start_at_time: '',
      status: '',
      user_id: careManager.data.id,
    },
    rules: {
      user_id: { required: true },
      patient_phone_number: { required: true },
      start_at_date: { required: true },
      start_at_time: { required: true },
      interaction_reason: { required: true },
      manual_entry_reason: { required: true },
      service_id: { required: true },
      status: { required: true },
    },
    layout: [
      {
        title: 'Add Manual Entry',
        fields: [
          [
            {
              label: 'Care Manager',
              field: 'user_id',
              placeholder: 'Search for care manager',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/care_managers',
              size: 4,
              initialSelection: initialSelection(),
              formatTypeaheadResult: (result: any) => {
                return {
                  label: fullName(result),
                  value: result.id,
                };
              },
            },
            {
              label: 'Phone Number',
              field: 'patient_phone_number',
              type: 'select',
              size: 4,
              placeholder: 'Select phone number',
              options: [
                {
                  label: patient.attributes.phone_number,
                  value: patient.attributes.phone_number,
                },
              ],
            },
          ],
          [
            {
              label: 'Date Worked',
              field: 'start_at_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
            {
              label: 'Start Time',
              field: 'start_at_time',
              placeholder: 'Select start time',
              type: 'time',
              size: 4,
            },
          ],
          [
            {
              label: 'Duration Worked',
              field: 'hours',
              placeholder: 'Hours',
              type: 'number',
              size: 2,
            },
            {
              field: 'minutes',
              placeholder: 'Minutes',
              type: 'number',
              size: 2,
            },
          ],
          [
            {
              label: 'Interaction Reason',
              field: 'interaction_reason_select',
              type: 'select',
              size: 4,
              placeholder: 'Select interaction reason',
              options: manualEntryReasonOptions,
            },
            {
              label: 'Manual Entry Reason',
              field: 'manual_entry_reason',
              type: 'select',
              size: 4,
              placeholder: 'Select manual entry reason',
              options: manualEntryReasonOptions,
            },
          ],
          [
            {
              label: 'Service Type',
              field: 'service_id',
              type: 'select',
              size: 4,
              placeholder: 'Select service',
              options: serviceOptions,
            },
            {
              label: 'Call Status',
              field: 'status',
              type: 'select',
              size: 4,
              placeholder: 'Select status',
              options: callStatusOptions,
            },
          ],
          [
            {
              label: 'Include Time Spent on Phone',
              field: 'duration_includes_time_spent_on_phone',
              type: 'checkbox',
              size: 4,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm backPath={backPath} backText="Back to Calls" config={config} />
  );
};

export default CallsNew;
