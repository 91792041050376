import React from 'react';

import { SideEffect } from '@/types/generic';

interface Props {
  isPaused?: boolean;
  onClick: SideEffect;
}

const PauseButton = (props: Props) => {
  const { isPaused, onClick } = props;

  const renderIcon = () => {
    if (isPaused) {
      return <div />;
    }

    return (
      <>
        <span />
        <span />
      </>
    );
  };

  return (
    <button className="caller__pause-button" onClick={onClick}>
      {renderIcon()}
    </button>
  );
};

export default PauseButton;
