import React from 'react';

import { Nullable, UUID } from '@/types/generic';
import {
  QuestionnaireQuestion,
  QuestionnaireResponse,
} from '@/types/v2/questionnaire';
import FreeTextResponse from './FreeTextResponse';
import MultiAnswerResponse from './MultiAnswerResponse';
import SingleAnswerResponse from './SingleAnswerResponse';
import { FormResponseAnswer } from './types';

interface Props {
  onSelectAnswer: (questionId: UUID, answerId: UUID) => void;
  onToggleAnswer: (questionId: UUID, answerId: UUID) => void;
  onUpdateText: (questionId: UUID, text: string) => void;
  question: QuestionnaireQuestion;
  response: any;
}

const Question = (props: Props): JSX.Element => {
  const {
    onSelectAnswer,
    onToggleAnswer,
    onUpdateText,
    question,
    response,
  } = props;

  const handleUpdateText = (text: string): void => {
    onUpdateText(question.id, text);
  };

  const handleSelectAnswer = (answerId: UUID): void => {
    onSelectAnswer(question.id, answerId);
  };

  const handleToggleAnswer = (answerId: UUID): void => {
    onToggleAnswer(question.id, answerId);
  };

  const renderQuestion = (() => {
    switch (question.question_type) {
      case 'free_text':
        return (
          <FreeTextResponse
            onChange={handleUpdateText}
            value={response?.free_text}
          />
        );

      case 'multi_answer':
        return (
          <MultiAnswerResponse
            answers={question.questionnaire_answers}
            onToggleAnswer={handleToggleAnswer}
            selectedAnswers={response?.questionnaire_response_answers || []}
          />
        );

      case 'single_answer':
        const selectedAnswer = response?.questionnaire_response_answers?.find(
          answer => !(answer as FormResponseAnswer)._destroy,
        );

        return (
          <SingleAnswerResponse
            answers={question.questionnaire_answers}
            onSelectAnswer={handleSelectAnswer}
            selectedAnswer={selectedAnswer || {}}
          />
        );
    }
  })();

  return (
    <div className="columns">
      <div className="column">
        <div className="questionnaire-question">
          <h5>{question.text}</h5>
          {renderQuestion}
        </div>
      </div>
    </div>
  );
};

export default Question;
