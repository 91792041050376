import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AgreementsIndex from '@/contexts/patients/modules/agreements/AgreementsIndex';
import ConditionComplexitiesNew from '@/contexts/patients/modules/agreements/ConditionComplexitiesNew';
import ConsentAgreementsNew from '@/contexts/patients/modules/agreements/ConsentAgreementsNew';
import { Routes } from '@/utils/routeUtils';

const Patients = () => {
  return (
    <Switch>
      <Route
        path={Routes.patientsConsentAgreementsNew}
        component={ConsentAgreementsNew}
      />
      <Route
        path={Routes.patientsConditionComplexitiesNew}
        component={ConditionComplexitiesNew}
      />
      <Route
        path={Routes.patientsConditionComplexitiesShow}
        component={AgreementsIndex}
      />
      <Route path={Routes.patientsAgreements} component={AgreementsIndex} />
    </Switch>
  );
};

export default Patients;
