import React from 'react';
import { useSelector } from 'react-redux';

import Modal from '@/components/modal/Modal';
import Panel from '@/components/panel/Panel';
import DateTimeCell from '@/components/table/DateTimeCell';
import Table from '@/components/table/Table';
import { getSelectedPatient } from '@/selectors/api';
import { selectCurrentCarePlan } from '@/selectors/bhi';
import { TableConfig } from '@/types/table';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useHistory, useParams } from 'react-router-dom';
import PatientLayout from '../../components/PatientLayout';
import CarePlanModal from './CarePlanModal';
import { UUID } from '@/types/generic';

const SEARCH_PATH = 'api/v1/care_plans';

interface Params {
  plan_id: UUID;
}

const BhiPlanIndex = () => {
  const carePlan = useSelector(selectCurrentCarePlan);
  const patient = useSelector(getSelectedPatient);

  const history = useHistory();

  const { plan_id } = useParams<Params>();

  const isModalVisible = !!plan_id;

  const buttonText = carePlan ? 'Continue Care Plan' : 'Add New Care Plan';

  const handleCloseModal = () => {
    history.push(buildPath(Routes.patientsBhiPlan, { id: patient.id }));
  };

  const tableConfig: TableConfig = {
    initialSort: 'created_at:desc',
    header: {
      addPath: buildPath(Routes.patientsEnrollment, {
        id: patient.id,
        step: 'bhi_conditions',
      }),
      addText: buttonText,
    },
    searchPath: SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
      include: 'conditions,implications',
      status: 'complete',
    },
    rows: {
      viewId: 'plan_id',
      viewPath: buildPath(Routes.patientsBhiPlanShow, { id: patient.id }),
    },
    emptyState: 'No plans added yet',
    columns: [
      {
        header: 'Date',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'created_at',
        formatEntry: entry => {
          return <DateTimeCell date={entry.created_at} />;
        },
      },
      {
        header: 'Condition',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'conditions.description',
        formatEntry: (entry: any) => {
          return entry.conditions.map(condition => (
            <p key={condition.id}>{condition.description}</p>
          ));
        },
      },
      {
        header: 'Implication',
        isSortable: true,
        isVisibleMobile: false,
        attribute: 'implications.description',
        formatEntry: (entry: any) => {
          return (
            <span>{entry.implications.map(i => i.description).join('; ')}</span>
          );
        },
      },
    ],
  };

  return (
    <>
      <PatientLayout>
        <Panel>
          <Table config={tableConfig} />
        </Panel>
      </PatientLayout>

      <Modal isVisible={isModalVisible} onCloseModal={handleCloseModal}>
        <CarePlanModal carePlanId={plan_id} />
      </Modal>
    </>
  );
};

export default BhiPlanIndex;
