import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { AdminUser } from '@/types/v2/admin_user';
import { Specialist } from '@/types/v2/specialist';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, returnPath, Routes } from '@/utils/routeUtils';
import { UUID } from '@/types/generic';

interface Params {
  provider_id: UUID;
}

const ProviderProfile = () => {
  const { provider_id } = useParams<Params>();

  const loader = useLoadingState('specialist');
  const notifier = useNotifier();

  const [adminUser, setAdminUser] = useState<AdminUser>(null);

  const getSpecialist = async () => {
    loader.startLoading('specialist');

    try {
      const url = buildPath(
        Routes.api2.adminUser,
        {
          id: provider_id,
        },
        null,
        [
          'practice',
          'practice.health_system',
          'practice.hospital',
          'specialist',
        ],
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<AdminUser>(response.data, {
        generations: 2,
      }).build();

      setAdminUser(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('specialist');
  };

  useEffect(() => {
    if (provider_id) {
      getSpecialist();
    }
  }, []);

  const renderImage = () => {
    if (adminUser.specialist.image_thumbnail_url) {
      return (
        <div className="profile-display__image">
          <img src={adminUser.specialist.image_thumbnail_url} />
        </div>
      );
    }
  };

  if (loader.areAnyLoading || !adminUser) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <Container>
        <BackLink to={returnPath() || Routes.provider.practice.show.providers}>
          Back
        </BackLink>
      </Container>

      <Panel>
        <Container>
          <Constraint size="medium">
            <div className="profile-display">
              <div className="profile-display__header-wrapper">
                {renderImage()}
                <h2>{adminUser.specialist.name}</h2>
              </div>

              <div className="profile-display__section">
                <div className="profile-display__text">
                  <span>{adminUser.specialist.specialty}</span>
                </div>

                <span>{adminUser.specialist.scheduling_notes}</span>

                <div className="profile-display__text has-margin-top">
                  <span>{adminUser.practice.name}</span>
                  <span>{adminUser.practice.hospital.name}</span>
                  <span>{adminUser.practice.health_system.name}</span>
                </div>
              </div>
            </div>
          </Constraint>
        </Container>
      </Panel>
    </>
  );
};

export default ProviderProfile;
