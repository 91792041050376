import { combineReducers } from 'redux';

import AppState from '@/types/app';
import api from './api';
import application from './application';
import bhi from './bhi';
import brand from './brand';
import call_manager from './call_manager';
import errors from './errors';
import featureFlags from './feature_flags';
import messages from './messages';
import patients from './patients';
import rpm from './rpm';
import users from './users';
import videoCalls from './video_calls';

const allReducers = combineReducers({
  application,
  bhi,
  brand,
  call_manager,
  data: api,
  errors,
  featureFlags,
  patients,
  rpm,
  messages,
  users,
  videoCalls,
});

const rootReducer = (state: AppState, action: any) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
