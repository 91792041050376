import { createSelector } from 'reselect';

import { AppState } from '@/types/app';
import { BrandState } from '@/types/brand';

export const selectBrandState = (state: AppState): BrandState => state.brand;

export const selectBrand = createSelector(selectBrandState, state =>
  (state.brand || '').toLowerCase(),
);

export const selectBrandLogo = createSelector(selectBrand, brand => {
  if (brand === 'default' || !brand) {
    return require('assets/images/logo/logo.png');
  } else {
    return require(`assets/images/partners/${brand}/logo.png`);
  }
});

// TODO: This should pull from the database instead of being hardcoded
export const selectBrandName = createSelector(selectBrand, brand => {
  switch (brand) {
    case 'engage_rpm':
      return 'Engage RPM';

    default:
      return 'HealthClix';
  }
});
