import classNames from 'classnames';
import React from 'react';

import { SideEffect } from '@/types/generic';

interface Props {
  children: JSX.Element | JSX.Element[];
  currentTab: string;
  isDisabled?: boolean;
  onSelectTab?: SideEffect;
  withFlexWrap?: boolean;
}

export const Tabs = (props: Props) => {
  const { children, currentTab, isDisabled, onSelectTab, withFlexWrap } = props;

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { currentTab, isDisabled, onSelectTab }),
  );

  return (
    <div
      className={classNames('tabs', {
        'with-flex-wrap': withFlexWrap,
      })}>
      {childrenWithProps}
    </div>
  );
};

export default Tabs;
