import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { flashError } from '@/actions/sagas/messageSaga';
import {
  HTTPFormMethod,
  Rules,
  ValidateBeforeSubmit,
  Validations,
} from '@/types/form';
import { SideEffect } from '@/types/generic';
import { Response } from '@/types/response';
import usePostRequest from '@/utils/api/usePostRequest';
import usePutRequest from '@/utils/api/usePutRequest';
import { parseErrorData } from '@/utils/apiUtils';
import { mergeObjects } from '@/utils/objectUtils';
import { areFieldsUpdated, validate } from './utils';

export interface FormConfig {
  alterBodyBeforeSubmit?: (state: any) => any;
  alterFormBeforeSubmit?: (form: any) => any;
  canSubmitUnchanged?: boolean;
  clearFields?: any;
  enableSubmitWithRequired?: boolean;
  method: HTTPFormMethod;
  model: string;
  onChange?: SideEffect;
  onError?: SideEffect;
  onSuccess?: SideEffect;
  path: string;
  rules?: Rules;
  shouldDisableOnSubmit?: boolean;
  validateBeforeSubmit?: ValidateBeforeSubmit;
}

export interface Form {
  areFieldsUpdated: boolean;
  formState: any;
  isSubmitDisabled: boolean;
  isSuccessful: boolean;
  isValid: boolean;
  message: string;
  onSubmit: SideEffect;
  submit: {
    isDisabled?: boolean;
    isSubmitting?: boolean;
    onClick?: () => void;
  };
  validations: Validations;
  [key: string]: any;
}

const defaultConfig: FormConfig = {
  method: 'POST',
  model: '',
  path: '',
};

export const useForm = (
  initialState: any,
  config: FormConfig = defaultConfig,
) => {
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({ ...initialState });
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [message, setMessage] = useState('');
  const [validations, setValidations] = useState<any>({});

  const {
    alterBodyBeforeSubmit,
    alterFormBeforeSubmit,
    canSubmitUnchanged,
    clearFields,
    enableSubmitWithRequired,
    onChange,
    onError,
    onSuccess,
    rules,
    shouldDisableOnSubmit,
    method,
    model,
    path,
    validateBeforeSubmit,
  } = config;

  const form: Form = {
    areFieldsUpdated: false,
    formState: {},
    isSubmitDisabled: false,
    isSuccessful: false,
    isValid: true,
    message: '',
    onSubmit: () => null,
    submit: {},
    validations: {},
  };

  const buildModel = (attributes: any) => {
    const modelAttributes = {
      [model]: attributes,
    };

    if (alterBodyBeforeSubmit) {
      return alterBodyBeforeSubmit(modelAttributes);
    }

    return modelAttributes;
  };

  const formAttributes = alterFormBeforeSubmit
    ? alterFormBeforeSubmit(formState)
    : formState;

  const requestBody = buildModel(formAttributes);

  const handleSubmitError = (response: Response<any>) => {
    const error = parseErrorData(response);

    setIsSubmittingForm(false);
    setMessage(error || '');

    if (error) {
      dispatch(flashError(error));
    }

    if (onError) {
      onError(error);
    }
  };

  const handleSubmitSuccess = (response: Response<any>) => {
    setIsSubmittingForm(false);
    setIsSuccessful(true);

    if (onSuccess) {
      onSuccess(response);
    }
  };

  useEffect(() => {
    handleClearFields();
  });

  const [postForm] = usePostRequest({
    body: requestBody,
    dispatch,
    onFailure: handleSubmitError,
    onSuccess: handleSubmitSuccess,
    url: path,
  });

  const [putForm] = usePutRequest({
    body: requestBody,
    dispatch,
    onFailure: handleSubmitError,
    onSuccess: handleSubmitSuccess,
    url: path,
  });

  const handleClearFields = () => {
    if (clearFields) {
      for (const field of Object.keys(clearFields)) {
        const callback = clearFields[field];
        const shouldClear = callback(formState);

        if (shouldClear && !!formState[field]) {
          updateFormField(field, '');
        }
      }
    }
  };

  const updateFormField = (field: string, value: string) => {
    if (!isSuccessful) {
      setIsSuccessful(false);
    }

    setFormState({
      ...formState,
      [field]: value,
    });

    if (onChange) {
      onChange(formState);
    }
  };

  const populateFieldActions = (fieldName: string) => {
    const field = {
      onChange: (value: string) => updateFormField(fieldName, value),
      validationMessage: validations[fieldName],
      value: formState[fieldName],
    };

    form[fieldName] = field;
  };

  for (const fieldName of Object.keys(initialState)) {
    populateFieldActions(fieldName);
  }

  const handleSubmitForm = () => {
    setIsSuccessful(false);

    if (isSubmittingForm) {
      return;
    }

    const [isValid, validationMessages] = validate(formState, rules || {});

    form.isValid = isValid;
    form.validations = validationMessages;

    setMessage('');

    const customValidations = validateBeforeSubmit
      ? validateBeforeSubmit(formState)
      : null;

    const allValidations = mergeObjects(validationMessages, customValidations);

    if (!isValid || customValidations) {
      return setValidations(allValidations);
    }

    setValidations({});

    if (method === 'POST') {
      postForm();
    } else if (method === 'PUT') {
      putForm();
    }

    setIsSubmittingForm(true);
  };

  const requiredFieldsNotAllFilled = () => {
    if (!config.rules) {
      return false;
    }

    if (enableSubmitWithRequired) {
      for (const fieldName of Object.keys(initialState)) {
        if (
          config.rules[fieldName] &&
          config.rules[fieldName].required &&
          !form[fieldName].value
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const updatedForm: any = {};

  for (const fieldName of Object.keys(initialState)) {
    updatedForm[fieldName] = form[fieldName].value;
  }

  form.areFieldsUpdated = areFieldsUpdated(
    initialState,
    updatedForm,
    Object.keys(initialState),
  );

  form.formState = formState;

  const isSubmitDisabled =
    (!canSubmitUnchanged && !form.areFieldsUpdated) ||
    requiredFieldsNotAllFilled();

  form.isSubmitDisabled = isSubmitDisabled;

  form.isSubmitting = isSubmittingForm;

  form.message = message;

  form.submit = {
    isDisabled: isSubmitDisabled,
    isSubmitting: shouldDisableOnSubmit && isSubmittingForm,
    onClick: handleSubmitForm,
  };

  form.onSubmit = handleSubmitForm;

  form.isSuccessful = isSuccessful;

  const reset = () => {
    setFormState({ ...initialState });
  };

  form.reset = reset;

  return form;
};
