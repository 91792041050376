import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { Nullable, SideEffect } from '@/types/generic';

interface Props {
  additionalClass?: Nullable<string> | void;
  children: ReactNode;
  onClickRow?: SideEffect;
}

export const Row = (props: Props) => {
  const { additionalClass, children, onClickRow } = props;

  return (
    <tr
      className={classNames('table__row', {
        [additionalClass || '']: !!additionalClass,
        'is-hoverable': !!onClickRow,
      })}
      onClick={onClickRow}>
      {children}
    </tr>
  );
};

export default Row;
