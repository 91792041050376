import React, { useState } from 'react';

import { flashSuccess } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import InputIcon from '@/components/form/InputIcon';
import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';
import { Tab, Tabs } from '@/components/tabs';
import { Attributable } from '@/types/generic';
import { TableConfig } from '@/types/table';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const MEDICATION_NAMES_SEARCH_PATH = 'api/v1/medication_names';

const INITIAL_QUERY = {
  search: '',
};

const MedicationsPortalIndex = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState<string>('');
  const [queryOptions, setQueryOptions] = useState<Attributable>(INITIAL_QUERY);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [_query, initialTab] = returnTabQuery();

  const [selectedTab, setSelectedTab] = useState<string>(
    initialTab || 'approved',
  );

  const handleSearchChange = (value: any) => {
    setSearchInput(value);
  };

  const handleChangeQuery = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleAddResource = () => {
    const path = buildPath(Routes.adminResourceNew, {
      resource: 'medication_names',
    });
    history.push(path);
  };

  const handleForceUpdate = () => {
    setForceUpdate(false);
  };

  const handleClickApprove = async entry => {
    const url = `api/v1/medication_names/${entry.id}`;
    const body = { status: 'approved' };

    await Api.utility.patch(url, body);

    dispatch(flashSuccess('Medication approved'));
    setForceUpdate(true);
  };

  const handleClickRemove = async entry => {
    const url = `api/v1/medication_names/${entry.id}`;

    await Api.utility.delete(url);

    dispatch(flashSuccess('Medication request removed'));
    setForceUpdate(true);
  };

  const tabelColumns = [
    {
      header: 'Medication Name',
      isSortable: true,
      attribute: 'name',
      isVisibleMobile: true,
      width: 2,
    },
    {
      header: 'Medication Group',
      isSortable: true,
      attribute: 'group',
    },
    {
      header: 'Medication Type',
      isSortable: true,
      attribute: 'medication_type',
    },
  ];

  const approvedTableConfig: TableConfig = {
    searchPath: MEDICATION_NAMES_SEARCH_PATH,
    searchQuery: {
      ...queryOptions,
      status: 'approved',
    },
    emptyState: 'No medications added yet',
    columns: tabelColumns,
    rows: {
      editPath: buildPath(Routes.adminResourceEdit, {
        resource: 'medication_names',
      }),
      editId: 'resource_id',
    },
  };

  const pendingTableConfig: TableConfig = {
    searchPath: MEDICATION_NAMES_SEARCH_PATH,
    searchQuery: {
      ...queryOptions,
      status: 'pending',
    },
    emptyState: 'No medications added yet',
    columns: tabelColumns,
    rows: {
      customText: 'Approve',
      customAction: handleClickApprove,
      removeAction: handleClickRemove,
    },
  };

  const renderAddButton = () => {
    if (selectedTab === 'approved') {
      return (
        <div className="column is-6">
          <div className="portal-content__add">
            <Button color="secondary" onClick={handleAddResource}>
              Add Medication
            </Button>
          </div>
        </div>
      );
    }
  };

  const renderTable = () => {
    if (selectedTab === 'approved') {
      return <Table config={approvedTableConfig} key="medication_names" />;
    }

    return (
      <Table
        config={pendingTableConfig}
        forceUpdate={forceUpdate}
        onForceUpdate={handleForceUpdate}
        key="medication_names"
      />
    );
  };

  return (
    <Panel>
      <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
        <Tab value="approved">Approved</Tab>
        <Tab value="pending">Pending</Tab>
      </Tabs>

      <div className="columns">
        <div className="column is-6">
          <InputIcon
            onChange={handleSearchChange}
            onClick={() => handleChangeQuery('search', searchInput)}
            onPressEnter={() => handleChangeQuery('search', searchInput)}
            placeholder="Search"
            value={searchInput}
          />
        </div>

        {renderAddButton()}
      </div>

      {renderTable()}
    </Panel>
  );
};

export default MedicationsPortalIndex;
