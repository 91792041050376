import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import { getImmunizationNamesOptions } from '@/selectors/options';
import { FormComponentConfig } from '@/types/form';
import { UUID } from '@/types/generic';
import { IdParams } from '@/types/params';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';
import { validateBeforeSubmit } from './utils';

interface Params extends IdParams {
  patient_id: UUID;
}

const ImmunizationsEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { patient_id, id } = useParams<Params>();

  const immunizationNames = useSelector(getImmunizationNamesOptions);

  const [response, setResponse] = useState(null);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'immunizations',
    onSuccess: (res: any) => {
      setResponse(parseResponseData(res));
    },
  });

  useEffect(() => {
    getEntry();
  }, []);

  const backPath = buildPath(Routes.patientsImmunizations, { id: patient_id });

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: `api/v1/immunizations/${id}`,
      model: 'immunization',
      method: 'PUT',
      onCancel: () => history.push(backPath),
      onSuccess: () => history.push(backPath),
      validateBeforeSubmit,
    },
    state: response.attributes,
    rules: {
      name: { required: true },
      last_date: { required: true },
    },
    layout: [
      {
        title: 'Edit Immunization',
        fields: [
          [
            {
              label: 'Immunization',
              field: 'name',
              placeholder: 'Select immunization name',
              type: 'select',
              size: 4,
              options: immunizationNames,
            },
          ],
          [
            {
              label: 'Last Date',
              field: 'last_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
            {
              label: 'Next Date',
              field: 'next_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
          ],
          [
            {
              label: 'Comment',
              field: 'comments',
              placeholder: 'Enter comment',
              type: 'text',
              size: 8,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Immunizations"
      config={config}
    />
  );
};

export default ImmunizationsEdit;
