import React, { useState } from 'react';

import Constraint from '@/components/constraint/Constraint';
import Panel from '@/components/panel/Panel';
import { PolicyGroups, Protected } from '@/components/router';
import AdminDashboardActivity from './components/AdminDashboardActivity';
import AdminDashboardLiveView from './components/AdminDashboardLiveView';

const AdminDashboard = () => {
  const [searchInput, setSearchInput] = useState('');

  const [perPage, setPerPage] = useState(24);

  const [modalVisible, setModalVisible] = useState(false);

  const [queryOptions, setQueryOptions] = useState({
    care_manager_id: '',
    status: '',
    search: '',
    call_status: '',
  });

  const [mobileQueryOptions, setMobileQueryOptions] = useState({
    care_manager_id: '',
    status: '',
  });

  const handleQueryChange = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleSearchChange = (value: any) => {
    setSearchInput(value);
  };

  const handleSearchInput = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleMobileQueryChange = (field: string, value: any) => {
    setMobileQueryOptions({
      ...mobileQueryOptions,
      [field]: value,
    });
  };

  const handleMobileApplyClick = () => {
    setQueryOptions({
      ...queryOptions,
      ...mobileQueryOptions,
    });

    setModalVisible(false);
  };

  const handlePerPageChange = (value: any) => {
    setPerPage(value);
  };

  const handlFilterClick = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <Protected allowedRoles={PolicyGroups.atLeastAdmin}>
      <Constraint size="large">
        <Panel withNarrowMargin withNarrowPadding>
          <AdminDashboardLiveView onViewClick={handleQueryChange} />
        </Panel>

        <Panel>
          <AdminDashboardActivity
            closeModal={closeModal}
            modalVisible={modalVisible}
            mobileQueryOptions={mobileQueryOptions}
            onApplyMobileClick={handleMobileApplyClick}
            onFilterChange={handleQueryChange}
            onFilterClick={handlFilterClick}
            onMobileFilterChange={handleMobileQueryChange}
            onPerPageChange={handlePerPageChange}
            onSearchChange={handleSearchChange}
            onSearchClick={handleSearchInput}
            perPage={perPage}
            queryOptions={queryOptions}
            searchValue={searchInput}
          />
        </Panel>
      </Constraint>
    </Protected>
  );
};

export default AdminDashboard;
