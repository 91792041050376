import { Nullable, Serialized, UUID } from '@/types/generic';
import { Patient } from '@/types/patient';

export type UserRole =
  | 'admin'
  | 'care_manager'
  | 'billing_admin'
  | 'super_admin'
  | 'patient'
  | 'admin_user';

export type Capability =
  | 'join_video_call'
  | 'modify_practice'
  | 'modify_appointment'
  | 'view_patient';

export interface UserAttributes {
  id?: UUID;
  first_name: string;
  last_name: string;
  email: string;
  role: UserRole;
  phone_number_extension: string;
  status: number | string;
}

export type User = Serialized<UserAttributes>;

export interface UsersState {
  current: Nullable<User | Patient>;
}

export const create = (data: Partial<UsersState> = {}): UsersState => ({
  current: null,
  ...data,
});
