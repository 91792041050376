import React, { useMemo } from 'react';

import BackLink from '@/components/button/BackLink';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { Permission } from '@/types/permission';
import { User } from '@/types/user';
import { buildPath, Routes } from '@/utils/routeUtils';
import { displayUserStatus, fullName } from '@/utils/userUtils';
import { Link } from 'react-router-dom';

interface Props {
  backLink: string;
  backText: string;
  careManager: User;
  permissions: Permission[];
}

const PatientHeader = (props: Props) => {
  const { backLink, backText, careManager, permissions } = props;

  const sortedPermissions = useMemo(() => {
    const healthSystems = permissions
      .filter((permission: Permission) => {
        return permission.attributes.permissible_type === 'HealthSystem';
      })
      .map((permission: Permission) => permission.attributes.permissible.name)
      .sort();

    const hospitals = permissions
      .filter((permission: Permission) => {
        return permission.attributes.permissible_type === 'Hospital';
      })
      .map((permission: Permission) => permission.attributes.permissible.name)
      .sort();

    const practices = permissions
      .filter((permission: Permission) => {
        return permission.attributes.permissible_type === 'Practice';
      })
      .map((permission: Permission) => permission.attributes.permissible.name)
      .sort();

    return {
      healthSystems,
      hospitals,
      practices,
    };
  }, [permissions]);

  const renderPermissions = (type: string) => {
    return sortedPermissions[type].map((permission: string, index: number) => (
      <span className="dashboard-care-manager__permission" key={index}>
        {permission}
      </span>
    ));
  };

  if (!careManager || !careManager.id) {
    return null;
  }

  return (
    <>
      <Container>
        <BackLink to={backLink}>{backText}</BackLink>
      </Container>

      <Panel>
        <Container>
          <div className="dashboard-care-manager">
            <div className="columns">
              <div className="column">
                <h2 className="dashboard-care-manager__name">
                  {fullName(careManager)}
                </h2>
              </div>
            </div>

            <div className="columns">
              <div className="column is-3">
                <span className="dashboard-care-manager__highlight">
                  Care Manager
                </span>
                <span className="dashboard-care-manager__highlight">
                  {careManager.attributes.email}
                </span>
                <span className="dashboard-care-manager__highlight">
                  Ext. {careManager.attributes.phone_number_extension}
                </span>
              </div>

              <div className="column is-9">
                <span className="dashboard-care-manager__highlight">
                  {displayUserStatus(careManager.attributes.status as number)}
                </span>
              </div>
            </div>

            <hr />

            <div className="columns">
              <div className="column is-3">
                <h4>Health System</h4>
                {renderPermissions('healthSystems')}
              </div>

              <div className="column is-3">
                <h4>Hospital</h4>
                {renderPermissions('hospitals')}
              </div>

              <div className="column is-3">
                <h4>Practice</h4>
                {renderPermissions('practices')}
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <Link
                  to={buildPath(Routes.usersEdit, { user_id: careManager.id })}>
                  Manage User
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </Panel>
    </>
  );
};

export default PatientHeader;
