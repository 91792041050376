import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import PatientForm from '@/contexts/patients/components/PatientForm';
import { isEnrollmentPath } from '@/contexts/patients/utils/patientUtils';
import { FormComponentConfig } from '@/types/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { validateBeforeSubmit } from './utils';
import { IdParams } from '@/types/params';

interface Params extends IdParams {
  step: string;
}

const MedicationsNew = () => {
  const history = useHistory();

  const { id, step } = useParams<Params>();

  const isEnrollment = isEnrollmentPath();

  const [query] = returnTabQuery();

  const backPath = isEnrollment
    ? buildPath(Routes.patientsEnrollment, { id, step }, query)
    : buildPath(Routes.patientsMedications, { id }, query);

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/medications',
      model: 'medication',
      mergeFields: {
        patient_id: id,
      },
      method: 'POST',
      onSuccess: () => history.push(backPath),
      onCancel: () => history.push(backPath),
      validateBeforeSubmit,
      clearFields: {
        end_date: formState => formState.status === 'current',
      },
    },
    state: {
      directions: '',
      name: '',
      quantity: '',
      end_date: '',
      start_date: '',
      status: 'current',
    },
    rules: {
      name: { required: true },
      quantity: { required: true },
      start_date: { required: true },
      status: { required: true },
    },
    layout: [
      {
        title: 'Add Medication',
        fields: [
          [
            {
              label: 'Medication',
              field: 'name',
              placeholder: 'Search for medication name',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/medication_names',
              size: 4,
              formatTypeaheadResult: (result: any) => {
                return {
                  label: result.attributes.name,
                  value: result.attributes.name,
                };
              },
            },
            {
              label: 'Quantity',
              field: 'quantity',
              placeholder: 'Enter quantity',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Directions',
              field: 'directions',
              placeholder: 'Enter directions',
              type: 'text',
              size: 8,
            },
          ],
          [
            {
              label: 'Start Date',
              field: 'start_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
            {
              label: 'End Date',
              field: 'end_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
              disableIf: state => state.status === 'current',
            },
          ],
          [
            {
              label: 'Patient is currently taking',
              field: 'status',
              type: 'checkbox',
              size: 4,
              offset: 4,
              checkedValue: 'current',
              uncheckedValue: 'previous',
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Medications"
      config={config}
    />
  );
};

export default MedicationsNew;
