const statusDescriptions = [
  'Call Successful',
  'Talked to Patient',
  'Talked to Other Person',
  'Left Voicemail',
  'No Answer',
  'Wrong Number',
  'Inactive',
  'No Status',
  'Scheduled',
];

export const callStatusEnumOptions = statusDescriptions.map(
  (description: string, index: number) => ({
    label: description,
    value: index.toString(),
  }),
);

export const displayCallStatus = (status: number = 8) => {
  if (status < 0 || status > 8) {
    throw new Error('Invalid status option');
  }

  return statusDescriptions[status];
};
