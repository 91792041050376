import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { FormComponentConfig } from '@/types/form';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';
import { isEnrollmentPath } from '../../utils/patientUtils';
import { UUID } from '@/types/generic';

interface Params {
  id: UUID;
  step: string;
}

const AllergiesNew = () => {
  const history = useHistory();

  const { id, step } = useParams<Params>();

  const isEnrollment = isEnrollmentPath();

  const backPath = isEnrollment
    ? buildPath(Routes.patientsEnrollment, { id, step })
    : buildPath(Routes.patientsAllergies, { id });

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/allergies',
      model: 'allergy',
      mergeFields: {
        patient_id: id,
      },
      method: 'POST',
      onSuccess: () => history.push(backPath),
      onCancel: () => history.push(backPath),
      clearFields: {
        end_date: formState => formState.status === 'current',
      },
    },
    state: {
      source: '',
      allergy_type: 'environmental',
      name: '',
      severity: '',
      end_date: '',
      status: 'previous',
      instructions: '',
    },
    rules: {
      source: { required: true },
      allergy_type: { required: true },
      name: { required: true },
      severity: { required: true },
      instructions: { required: true },
    },
    layout: [
      {
        title: 'Add Allergy',
        fields: [
          [
            {
              label: 'Source',
              field: 'source',
              placeholder: 'Enter source',
              type: 'text',
              size: 8,
            },
          ],
          [
            {
              label: 'Allergy Type',
              field: 'allergy_type',
              type: 'tabs',
              size: 8,
              options: [
                {
                  label: 'Environmental',
                  value: 'environmental',
                },
                {
                  label: 'Medicine',
                  value: 'medicine',
                },
              ],
            },
          ],
          [
            {
              label: 'Allergy',
              field: 'name',
              placeholder: 'Search for allergy',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/allergy_options',
              queryParams: (formState: any) => {
                if (formState.allergy_type === 'environmental') {
                  return { allergy_type: 'environmental' };
                }

                return { allergy_type: 'medication' };
              },
              size: 8,
              formatTypeaheadResult: (result: any) => {
                return {
                  label: result.attributes.name,
                  value: result.attributes.name,
                };
              },
            },
          ],
          [
            {
              label: 'Severity',
              field: 'severity',
              placeholder: 'Enter severity',
              type: 'text',
              size: 4,
            },
            {
              label: 'End Date',
              field: 'end_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
              disableIf: state => state.status === 'current',
            },
          ],
          [
            {
              label: 'Patient is currently allergic',
              field: 'status',
              type: 'checkbox',
              size: 4,
              offset: 4,
              checkedValue: 'current',
              uncheckedValue: 'previous',
            },
          ],
          [
            {
              label: 'Instructions',
              field: 'instructions',
              placeholder: 'Enter instructions',
              type: 'text',
              size: 8,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Allergies"
      config={config}
    />
  );
};

export default AllergiesNew;
