import React from 'react';
import { useResponsive } from 'react-hooks-responsive';

import { breakpoints } from '@/utils/screenUtils';

interface Props {
  children: JSX.Element | JSX.Element[];
  title: string;
}

const ActionHeader = (props: Props) => {
  const { children, title } = props;

  const { screenIsAtMost } = useResponsive(breakpoints);

  const isMobile = screenIsAtMost('mobile');

  if (isMobile) {
    return (
      <>
        <div className="columns is-mobile">
          <div className="column">
            <h2 className="page__header">{title}</h2>
          </div>

          <div className="column">{children[1]}</div>
        </div>

        <div className="columns">
          <div className="column">{children[0]}</div>
        </div>
      </>
    );
  }

  return (
    <div className="columns">
      <div className="column is-4-desktop">
        <h2 className="page__header">{title}</h2>
      </div>
      <div className="column is-5-desktop">{children[0]}</div>
      <div className="column is-3-desktop page__header-button">
        {children[1]}
      </div>
    </div>
  );
};

export default ActionHeader;
