import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import { FormComponentConfig } from '@/types/form';
import { UUID } from '@/types/generic';
import { IdParams } from '@/types/params';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';
import { isEnrollmentPath } from '../../utils/patientUtils';

interface Params extends IdParams {
  patient_id: UUID;
  step: string;
}

const ImmunizationsEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { patient_id, id, step } = useParams<Params>();

  const [response, setResponse] = useState(null);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'other_histories',
    onSuccess: (res: any) => {
      setResponse(parseResponseData(res));
    },
  });

  useEffect(() => {
    getEntry();
  }, []);

  const isEnrollment = isEnrollmentPath();

  const queryParams = new URLSearchParams(location.search || '');

  const historyType = step
    ? step.replace('_history', '')
    : queryParams.get('history_type');

  const query = { return_tab: historyType };

  const backPath = isEnrollment
    ? buildPath(Routes.patientsEnrollment, { id, step })
    : buildPath(Routes.patientsMedicalRecords, { id: patient_id }, query);

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: `api/v1/other_histories/${id}`,
      model: 'other_history',
      method: 'PUT',
      onSuccess: () => history.push(backPath),
      onCancel: () => history.push(backPath),
    },
    state: response.attributes,
    layout: [
      {
        title: 'Add History',
        fields: [
          [
            {
              field: 'description',
              type: 'textarea',
              size: 12,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Medical Records"
      config={config}
    />
  );
};

export default ImmunizationsEdit;
