import classNames from 'classnames';
import React from 'react';

type StatAlign = 'left' | 'center';

export interface Props {
  align: StatAlign;
  label: string;
  value: string | number;
}

const Stat = ({ align, label, value }: Props): JSX.Element => {
  return (
    <div
      className={classNames('dashboard-stat', {
        'is-aligned-center': align === 'center',
      })}>
      <span className="dashboard-stat__value">{value}</span>
      <span className="dashboard-stat__label">{label}</span>
    </div>
  );
};

export default Stat;
