import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { flashError } from '@/actions/sagas/messageSaga';
import { refreshPatientAction } from '@/actions/sagas/patientSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import Datepicker from '@/components/form/Datepicker';
import {
  getSelectedPatient,
  getSelectedPatientEnrollment,
} from '@/selectors/api';
import { parseErrorData } from '@/utils/apiUtils';
import { formatIsoDate } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';

const ConsentStep4 = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const enrollment = useSelector(getSelectedPatientEnrollment);
  const patient = useSelector(getSelectedPatient);

  const [enrollmentDate, setEnrollmentDate] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClickNo = () => {
    const path = buildPath(Routes.patientsEnrollmentStep, {
      id: patient.id,
      step: 'consent',
      sub_step: '3',
    });

    history.push(path);
  };

  const handleClickYes = async () => {
    setIsSubmitting(true);

    const url = `api/v1/patient_enrollments/${enrollment.id}`;

    const body = {
      patient_enrollment: {
        patient_enrollment_date: formatIsoDate(enrollmentDate),
      },
    };

    try {
      await Api.utility.patch(url, body);

      dispatch(refreshPatientAction(patient.id));

      const path = buildPath(Routes.patientsEnrollmentStep, {
        id: patient.id,
        step: 'consent',
        sub_step: '5',
      });

      history.push(path);
    } catch (err) {
      dispatch(flashError(parseErrorData(err)));
    }
  };

  return (
    <div className="consent__form">
      <h5>What date should we use as the enrollment date?:</h5>

      <div className="consent__fields has-max-width">
        <Datepicker
          label="Date"
          onChange={(value: string) => setEnrollmentDate(value)}
          value={enrollmentDate}
        />
      </div>

      <div className="consent__buttons">
        <Button color="white" isDisabled={isSubmitting} onClick={handleClickNo}>
          Previous Question
        </Button>

        <Button
          color="secondary"
          isDisabled={isSubmitting || !enrollmentDate}
          onClick={handleClickYes}
          style="filled">
          Save and Next
        </Button>
      </div>
    </div>
  );
};

export default ConsentStep4;
