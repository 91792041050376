import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Datepicker from '@/components/form/Datepicker';
import SearchGroup, {
  formatModelName,
  formatSearchName,
} from '@/components/form/SearchGroup';
import Section from '@/components/form/Section';
import Timepicker from '@/components/form/Timepicker';
import Panel from '@/components/panel/Panel';
import { getCurrentUser } from '@/selectors/users';
import { Rules } from '@/types/form';
import { UUID } from '@/types/generic';
import { AdminUser, formatSpecialistProfile } from '@/types/v2/admin_user';
import { Patient } from '@/types/v2/patient';
import { Practice } from '@/types/v2/practice';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import useLoadingState from '@/utils/api/useLoadingState';
import useSimpleForm from '@/utils/form/useSimpleForm';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, includes, Routes } from '@/utils/routeUtils';
import { breakDateAndTime, combineDateAndTime } from '@/utils/timeUtils';
import moment from 'moment';

const initialFormState = {
  admin_user_id: '',
  practice_id: '',
  date: '',
  start_time: moment()
    .set({ hour: 12, minute: 0 })
    .utc()
    .format(),
  end_time: moment()
    .set({ hour: 13, minute: 0 })
    .utc()
    .format(),
};

interface Params {
  appointment_id?: UUID;
}

const VirtualAppointmentForm = () => {
  const history = useHistory();
  const loader = useLoadingState('specialist', 'appointment', 'practice');
  const location = useLocation();
  const notifier = useNotifier();
  const user = useSelector(getCurrentUser);

  const [appointment, setAppointment] = useState<VirtualAppointment>(null);
  const [defaultPractice, setDefaultPractice] = useState<Practice>(null);
  const [adminUser, setAdminUser] = useState<AdminUser>(null);

  const patient = useMemo(() => {
    return new Modeler<Patient>(user).build();
  }, [user]);

  // Provider ID is an optional param from the query string used to prepopulate the default selected provider for the form
  const params = new URLSearchParams(location.search);
  const provider = params.get('provider');
  const practice = params.get('practice');

  const { appointment_id } = useParams<Params>();

  const form = useSimpleForm<typeof initialFormState>(initialFormState);

  const adminUserParams = {
    practice_id: form.data.practice_id,
    include: includes('specialist'),
  };

  const isEditForm = !!appointment_id;

  const getDefaultSpecialist = async (): Promise<void> => {
    loader.startLoading('specialist');

    try {
      const url = buildPath(Routes.api2.adminUser, { id: provider }, null, [
        'practice',
        'specialist',
      ]);

      const response = await Api.utility.get(url);
      const resource = new Modeler<AdminUser>(response.data).build();

      setAdminUser(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('specialist');
  };

  const getDefaultPractice = async (): Promise<void> => {
    loader.startLoading('practice');

    try {
      const url = buildPath(Routes.api2.practice, { id: practice });

      const response = await Api.utility.get(url);
      const resource = new Modeler<Practice>(response.data).build();

      setDefaultPractice(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('practice');
  };

  const getVirtualAppointment = async (): Promise<void> => {
    loader.startLoading('appointment');

    try {
      const url = buildPath(
        Routes.api2.virtualAppointment,
        { id: appointment_id },
        null,
        [
          'patient',
          'admin_user',
          'admin_user.specialist',
          'admin_user.practice',
        ],
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<VirtualAppointment>(response.data, {
        generations: 2,
      }).build();

      setAppointment(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('appointment');
  };

  const backPath = (() => {
    if (adminUser) {
      return buildPath(Routes.chart.providers.show, {
        specialist_id: adminUser.id,
      });
    }

    return Routes.chart.appointments.root;
  })();

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const handleClickSave = () => {
    const rules: Rules = {
      date: { required: true },
      start_time: { required: true, mustOccurBefore: 'end_time' },
      end_time: {
        required: true,
        maxDurationAfter: {
          amount: 90,
          field: 'start_time',
          units: 'minutes',
        },
      },
    };

    if (form.validate(rules)) {
      submitForm();
    }
  };

  const submitForm = async () => {
    form.submit();

    const {
      date,
      end_time,
      admin_user_id,
      practice_id,
      start_time,
    } = form.data;

    const scheduledStartAt = combineDateAndTime(date, start_time);
    const scheduledEndAt = combineDateAndTime(date, end_time);

    try {
      const body = {
        virtual_appointment: {
          admin_user_id,
          practice_id,
          patient_id: patient.id,
          scheduled_end_at: scheduledEndAt,
          scheduled_start_at: scheduledStartAt,
        },
      };

      if (isEditForm) {
        const url = buildPath(Routes.api2.virtualAppointment, {
          id: appointment_id,
        });
        await Api.utility.patch(url, body);
      } else {
        const url = buildPath(Routes.api2.virtualAppointments);
        await Api.utility.post(url, body);
      }

      form.success('Appointment request saved');
      history.push(backPath);
    } catch (err) {
      form.error(err);
    }

    form.done();
  };

  useEffect((): void => {
    if (provider) {
      getDefaultSpecialist();
    }

    if (practice) {
      getDefaultPractice();
    }

    if (isEditForm) {
      getVirtualAppointment();
    }
  }, []);

  useEffect((): void => {
    form.update('admin_user_id', '');
  }, [form.data.practice_id]);

  useEffect((): void => {
    if (appointment) {
      const [startDate, startTime] = breakDateAndTime(
        appointment.scheduled_start_at,
      );
      const [endDate, endTime] = breakDateAndTime(appointment.scheduled_end_at);

      const appointmentFormState: typeof initialFormState = {
        practice_id: appointment.practice.id,
        admin_user_id: appointment.admin_user_id,
        date: startDate,
        end_time: endTime,
        start_time: startTime,
      };

      form.set(appointmentFormState);
    }
  }, [appointment]);

  const initialSpecialistSelection = useMemo(() => {
    if (appointment?.admin_user) {
      return formatSpecialistProfile(appointment.admin_user);
    } else if (adminUser) {
      return formatSpecialistProfile(adminUser);
    }
  }, [appointment, adminUser]);

  const initialPracticeSelection = useMemo(() => {
    if (appointment) {
      return formatModelName(appointment.practice);
    } else if (adminUser) {
      return formatModelName(adminUser.practice);
    } else if (defaultPractice) {
      return formatModelName(defaultPractice);
    }
  }, [appointment, adminUser, defaultPractice]);

  if (loader.areAnyLoading) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <Container>
        <BackLink to={backPath}>Back</BackLink>
      </Container>

      <Panel>
        <Constraint size="large">
          <Section title="Schedule Appointment">
            <div className="columns">
              <div className="column is-4">
                <SearchGroup
                  clearFalseResultOnBlur
                  formatResult={formatSearchName}
                  guideValue={form.data.practice_id}
                  initialSelection={initialPracticeSelection}
                  label="Practice (optional)"
                  minimumInputLength={1}
                  onChange={value => form.update('practice_id', value)}
                  placeholder="Select Practice"
                  searchPath={Routes.api.practices}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-4">
                <SearchGroup
                  clearFalseResultOnBlur
                  formatResult={formatSpecialistProfile}
                  isDisabled={isEditForm}
                  guideValue={form.data.admin_user_id}
                  initialSelection={initialSpecialistSelection}
                  label="Provider (optional)"
                  minimumInputLength={1}
                  onChange={value => form.update('admin_user_id', value)}
                  placeholder="Select Provider"
                  queryParams={adminUserParams}
                  searchPath={Routes.api2.adminUsers}
                  validationMessage={form.validations.admin_user_id}
                  shouldUseModeler
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-4">
                <Datepicker
                  label="Date"
                  limit="future"
                  onChange={value => form.update('date', value)}
                  placeholder="Select date"
                  validationMessage={form.validations.date}
                  value={form.data.date}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-4">
                <Timepicker
                  label="Start Time"
                  onChange={value => form.update('start_time', value)}
                  placeholder="Select start time"
                  value={form.data.start_time}
                  validationMessage={form.validations.start_time}
                />
              </div>

              <div className="column is-4">
                <Timepicker
                  label="End Time"
                  onChange={value => form.update('end_time', value)}
                  placeholder="Select end time"
                  value={form.data.end_time}
                  validationMessage={form.validations.end_time}
                />
              </div>
            </div>
          </Section>
        </Constraint>
      </Panel>

      <Constraint size="large">
        <div className="form__actions">
          <div className="form__actions-left" />

          <div className="form__actions-right">
            <Button color="white" onClick={handleClickCancel}>
              Cancel
            </Button>

            <Button
              color="secondary"
              isSubmitting={form.isSubmitting}
              onClick={handleClickSave}>
              Send Request
            </Button>
          </div>
        </div>
      </Constraint>
    </>
  );
};

export default VirtualAppointmentForm;
