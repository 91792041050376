import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import SelectGroup from '@/components/form/SelectGroup';
import Panel from '@/components/panel/Panel';
import { PageHeader, Table } from '@/components/table';
import DateTimeCell from '@/components/table/DateTimeCell';
import Datepicker from '@/components/form/Datepicker';
import InputGroup from '@/components/form/InputGroup';
import SearchGroup from '@/components/form/SearchGroup';
import { selectCurrentUserId, selectIsCurrentUserAnyAdmin } from '@/selectors/users';
import { UUID } from '@/types/generic';
import { TableConfig } from '@/types/table';
import { ReadingType } from '@/types/v2/device_reading';
import { formatIsoDate } from '@/utils/dateUtils';
import { buildRequestPath } from '@/utils/apiUtils';
import { typeLabelToNumber } from '@/utils/deviceReadingUtils';
import useSimpleForm from '@/utils/form/useSimpleForm';
import { buildPath, Routes } from '@/utils/routeUtils';
import { perPageOptions } from '@/utils/tableUtils';
import { fullName } from '@/utils/userUtils';
import { readingTypeFilterOptions } from '@/utils/dropdownUtils';
import AlertLevel from '../remote_patient_care/AlertLevel';
import ReadingLegend from '../remote_patient_care/modules/ReadingLegend';
import { getReadingLevel } from '../remote_patient_care/utils';

interface Query {
  [key: string]: number | string;
}

type AllReadingsResponseEntry = {
  id: UUID;
  first_name: string;
  last_name: string;
  patient_id: string;
  reading_time: string;
  reading_type: ReadingType;
  value_1: string;
};

const now = moment();
const oneWeekAgo = now.clone().subtract(1, 'week');

// Do not add/remove entries; it's used to reset the threshold filters
const formBaseState = {
  per: '24',
  start_at: formatIsoDate(oneWeekAgo),
  end_at: formatIsoDate(now),
  practice_id: '',
};

// Do not modify this; it's used to reset the threshold filters
const thresholdsInitState = {
  glucose_low: '',
  glucose_high: '',
  systolic_low: '',
  systolic_high: '',
  diastolic_low: '',
  diastolic_high: '',
  spo2_low: '',
  weight:'',
};

const state = Object.assign({}, formBaseState, thresholdsInitState);

type FormState = typeof state;

type ThresholdsState = typeof thresholdsInitState;

const RpmAllReadings = (): JSX.Element => {
  const userId = useSelector(selectCurrentUserId);
  const isAdmin = useSelector(selectIsCurrentUserAnyAdmin);

  const form = useSimpleForm<FormState>(state);
  const de_identify=sessionStorage.getItem("de_identify");

  const [filterSelectState, setFilterSelectState] = useState('none');

  // track these so they aren't lost on filter-select change
  const [thresholdsState, setThresholdsState]: [ThresholdsState, Function] =
    useState(Object.assign({}, thresholdsInitState));

  const getReadingResultLevel = (result: AllReadingsResponseEntry) => {
    return getReadingLevel(result.reading_type, result.value_1);
  };

  const renderReadingIcon = (result: AllReadingsResponseEntry) => {
    return <AlertLevel level={getReadingResultLevel(result)} />;
  };

  const getReadingRowClass = (result: AllReadingsResponseEntry) => {
    const level = getReadingResultLevel(result);

    return level ? `is-${level}` : null;
  };

  const formatPracticeResult = (result: any) => ({
    label: result.attributes.name,
    value: result.id,
  });

  const handleThresholdChange = (attrName: string, value: string): void => {
    form.update(attrName, value);
    setThresholdsState( Object.assign(thresholdsState, {[attrName]: value}) );
  };

  const handleFilterSelectChange = (selectValue: string): void => {
    // reset the form values that are hidden by current selection
    const newState = Object.assign({}, thresholdsInitState);

    if (selectValue === 'blood_glucose') {
      newState.glucose_low = thresholdsState.glucose_low;
      newState.glucose_high = thresholdsState.glucose_high;

    } else if (selectValue === 'blood_pressure') {
      newState.systolic_low = thresholdsState.systolic_low;
      newState.systolic_high = thresholdsState.systolic_high;
      newState.diastolic_low = thresholdsState.diastolic_low;
      newState.diastolic_high = thresholdsState.diastolic_high;

    } else if (selectValue === 'blood_oxygen') {
      newState.spo2_low = thresholdsState.spo2_low;
    } else if (selectValue ==='weight'){
      newState.weight = thresholdsState.weight;
    }

    // Copy only the entries from the form whose keys are in `formBaseState`
    Object.keys(formBaseState).forEach(key => {
      newState[key] = form.data[key];
    });

    form.set(newState);

    setFilterSelectState(selectValue);
  };

  const config: TableConfig = {
    reloadSeconds: 30,
    initialSort: 'reading_time:desc',
    searchPath: Routes.api2.deviceReadings,
    searchQuery: {
      ...form.data,
      end_at: form.data.end_at,
      report: 'all',
      start_at: form.data.start_at,
      practice_id: form.data.practice_id,
      user_id: userId,
    },
    rows: {
      addRowClass: getReadingRowClass,
    },
    emptyState: 'No device readings',
    columns: [
      {
        attribute: 'alert_type',
        isVisibleMobile: true,
        formatEntry: renderReadingIcon,
        width: 0.4,
      },
      {
        header: 'Date',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'reading_time',
        formatEntry: entry => {
          return <DateTimeCell date={entry.reading_time} />;
        },
      },
      {
        header: 'Patient Name',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'first_name',
        formatEntry: (entry: AllReadingsResponseEntry) => {
          const name = de_identify=='0'?fullName(entry):'------';
          const url = buildPath(Routes.patientsShow, { id: entry.patient_id });

          return <Link to={url}>{name}</Link>;
        },
      },
      {
        header: 'mg/dL',
        isSortable: true,
        attribute: 'blood_glucose',
     
      },
      {
        header: 'Pulse',
        isSortable: true,
        attribute: 'pulse',
      },
      {
        header: 'SYS (mmHg)',
        isSortable: true,
        attribute: 'systolic',
      },
      {
        header: 'DIA (mmHg)',
        isSortable: true,
        attribute: 'diastolic',
      },
      {
        header: 'SpO2(%)',
        isSortable: true,
        attribute: 'spo2',
      },
      {
        header: 'Weight(lbs)',
        isSortable: true,
        attribute: 'weight'
      }
    ],
  };

  const renderThresholdInputs = (selectValue: string): JSX.Element => {
    const renderDownloadButton = (isAdmin: boolean): JSX.Element => {
      if (isAdmin) {
        // build a copy of table config's query (excludes sort)
        const searchQuery: Query = {};

        Object.entries(config.searchQuery)
          .forEach(([key, val]) => { searchQuery[key] = String(val) });

        let downloadPath = '/' + buildRequestPath(Routes.api2.deviceReadingsDownload, searchQuery);

        return (
          <div className="column is-3">
            <a className="button" href={downloadPath}>Download</a>
          </div>
        );
      }
    }

    if (selectValue === 'blood_glucose') {
      return (
        <div className="column">
          <div className="columns">
            <div className="column is-2">
              <InputGroup
                label="Low Glucose Level"
                onChange={value => handleThresholdChange('glucose_low', value)}
                type="number"
                value={form.data.glucose_low}
              />
            </div>
            <div className="column is-2">
              <InputGroup
                label="High Glucose Level"
                onChange={value => handleThresholdChange('glucose_high', value)}
                type="number"
                value={form.data.glucose_high}
              />
            </div>

            <div className="column is-5"> </div>

            {renderDownloadButton(isAdmin)}
          </div>
        </div>
      );

    } else if (selectValue === 'blood_pressure') {
      return (
        <div className="column">
          <div className="columns">
            <div className="column is-2">
              <InputGroup
                label="Low Systolic Level"
                onChange={value => handleThresholdChange('systolic_low', value)}
                type="number"
                value={form.data.systolic_low}
              />
            </div>
            <div className="column is-2">
              <InputGroup
                label="High Systolic Level"
                onChange={value => handleThresholdChange('systolic_high', value)}
                type="number"
                value={form.data.systolic_high}
              />
            </div>
            <div className="column is-2">
              <InputGroup
                label="Low Diastolic Level"
                onChange={value => handleThresholdChange('diastolic_low', value)}
                type="number"
                value={form.data.diastolic_low}
              />
            </div>
            <div className="column is-2">
              <InputGroup
                label="High Diastolic Level"
                onChange={value => handleThresholdChange('diastolic_high', value)}
                type="number"
                value={form.data.diastolic_high}
              />
            </div>

            <div className="column is-1"> </div>

            {renderDownloadButton(isAdmin)}
          </div>
        </div>
      );

    } else if (selectValue === 'blood_oxygen') {
      return (
        <div className="column">
          <div className="columns">
            <div className="column is-2">
              <InputGroup
                label="Low SpO2 Level"
                onChange={value => handleThresholdChange('spo2_low', value)}
                type="number"
                value={form.data.spo2_low}
              />
            </div>

            <div className="column is-7"> </div>

            {renderDownloadButton(isAdmin)}
          </div>
        </div>
      );
    }else if (selectValue === 'weight') {
      return (
        <div className="column">
          <div className="columns">
            <div className="column is-2">
              <InputGroup
                label="Weight reading"
                onChange={value => handleThresholdChange('weight', value)}
                type="number"
                value={form.data.weight}
              />
            </div>

            <div className="column is-7"> </div>

            {renderDownloadButton(isAdmin)}
          </div>
        </div>
      );
    }
  };


  return (
    <>
      <Constraint size="large">
        <div className="columns">
          <div className="column">
            <BackLink to={Routes.patients}>Back to List</BackLink>
          </div>
        </div>
      </Constraint>

      <Panel>
        <Constraint size="large">
          <div className="columns">
            <div className="column">
              <PageHeader>All Readings</PageHeader>
            </div>

            <div className="column">
              <div className="is-justified-right-tablet">
                <SelectGroup
                  label="Show"
                  options={perPageOptions}
                  onChange={value => form.update('per', value)}
                  value={form.data.per}
                />
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column is-4-tablet is-3-desktop">
              <ReadingLegend
                isVertical
                readingType={typeLabelToNumber('blood_pressure')}
              />
            </div>

            <div className="column is-4-tablet is-3-desktop">
              <ReadingLegend
                isVertical
                readingType={typeLabelToNumber('blood_glucose')}
              />
            </div>

          </div>

          <div className="columns">
            <div className="column is-2-tablet is-3-desktop">
              <Datepicker
                label="Start Date"
                onChange={value => form.update('start_at', value)}
                value={form.data.start_at}
              />
            </div>
            <div className="column is-2-tablet is-3-desktop">
              <Datepicker
                label="End Date"
                onChange={value => form.update('end_at', value)}
                value={form.data.end_at}
              />
            </div>

            <div className="column is-4">
              <SearchGroup
                clearFalseResultOnBlur
                formatResult={formatPracticeResult}
                label="Filter by Practice"
                minimumInputLength={1}
                guideValue={form.data.practice_id}
                onChange={value => form.update('practice_id', value)}
                placeholder="Search Practices"
                searchPath="api/v1/practices"
              />
            </div>
          </div>

          <div className="columns">
            <div className="column is-2">
              <SelectGroup
                options={readingTypeFilterOptions}
                value={filterSelectState}
                label="Filter by reading levels:"
                onChange={value => handleFilterSelectChange(value)}
              />
            </div>

            { renderThresholdInputs(filterSelectState) }
          </div>

          <Table config={config} />
        </Constraint>
      </Panel>
    </>
  );
};

export default RpmAllReadings;
