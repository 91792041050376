import React from 'react';

import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import { fullName } from '@/utils/userUtils';

interface Props {
  appointment: VirtualAppointment;
  shouldDisplayProvider?: boolean;
}

const AppointmentPatient = ({
  appointment,
  shouldDisplayProvider,
}: Props): JSX.Element => {
  const renderProvider = (() => {
    if (shouldDisplayProvider) {
      const providerName = appointment.admin_user_id
        ? appointment.admin_user.specialist.name
        : 'Not selected';
      return <li>Provider: {providerName}</li>;
    }
  })();

  return (
    <div className="columns">
      <div className="column">
        <h4 className="modal__section-title">Patient Details</h4>

        <ul className="modal__text-list">
          <li>Patient: {fullName(appointment.patient)}</li>
          {renderProvider}
          <li>Care Manager: {fullName(appointment.patient.care_manager)}</li>
          <li>Patient ID: {appointment.patient.id}</li>
        </ul>
      </div>
    </div>
  );
};

export default AppointmentPatient;
