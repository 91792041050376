import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface Props {
  children: ReactNode;
  title: string;
  viewLink?: string;
  viewText?: string;
}

const SectionRow = ({
  children,
  title,
  viewLink,
  viewText = 'View All',
}: Props): JSX.Element => {
  return (
    <div className="dashboard-section-row">
      <div className="dashboard-section-row__header">
        <h3>{title}</h3>
        {viewLink && <Link to={viewLink}>{viewText}</Link>}
      </div>

      <div className="dashboard-section-row__content">{children}</div>
    </div>
  );
};

export default SectionRow;
