export const semanticJoin = (words: string[]): string => {
  const length = words.length;

  if (length === 1) {
    return words[0];
  }

  if (length === 2) {
    return `${words[0]} and ${words[1]}`;
  }

  if (length > 2) {
    const lastWord = words.slice(-1);
    const startingWords = words.slice(0, -1);

    return `${startingWords.join(', ')} and ${lastWord}`;
  }

  return '';
};

export const randomString = () => {
  return (
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  );
};

export const titleizeSnakeCase = (text: string = ''): string => {
  if (!text || typeof text !== 'string') {
    return '';
  }

  return text
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const titleize = (text: string = ''): string => {
  if (!text) {
    return '';
  }

  return text
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
