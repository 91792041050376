import FullCalendar, {
  EventClickArg,
  EventSourceFunc,
} from '@fullcalendar/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import dayGridPlugin from '@fullcalendar/daygrid';

import Api from '@/api/Api';
import Constraint from '@/components/constraint/Constraint';
import Panel from '@/components/panel/Panel';
import AppointmentModal from '@/modules/appointments/AppointmentModal';
import {
  canUserModifyAppointments,
  selectCurrentUser,
} from '@/selectors/users';
import { UUID } from '@/types/generic';
import { AdminUser } from '@/types/v2/admin_user';
import {
  hasProviderSelected,
  VirtualAppointment,
} from '@/types/v2/virtual_appointment';
import useLoadingState from '@/utils/api/useLoadingState';
import { fullCalendarProps } from '@/utils/calendarUtils';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { areObjectsEqual } from '@/utils/objectUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { usePrevious } from '@/utils/stateUtils';
import { fullName } from '@/utils/userUtils';
import { themeColors } from '@/variables/colors';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CalendarHeader, { FormState } from './CalendarHeader';

const initialState: FormState = {
  appointment_status: '',
  patient_id: '',
  search: '',
};

interface FormattedAppointment {
  id: UUID;
  end: string;
  start: string;
  title: string;
  color: string;
}

interface Props {
  patientId?: UUID;
  shouldDisplayForPractice?: boolean;
}

const formatAppointment = (
  appointment: VirtualAppointment,
): FormattedAppointment => ({
  id: appointment.id,
  end: appointment.scheduled_end_at,
  start: appointment.scheduled_start_at,
  title: fullName(appointment.patient),
  color: hasProviderSelected(appointment)
    ? themeColors.calendarStandard
    : themeColors.calendarNotice,
});

const Calendar = ({ patientId, shouldDisplayForPractice }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(selectCurrentUser) as AdminUser;
  const isAdmin = useSelector(canUserModifyAppointments);

  const loader = useLoadingState('cancel');
  const notifier = useNotifier();
  const params = new URLSearchParams(location.search);
  const appointmentId = params.get('appointment');

  const [form, setForm] = useState<FormState>(initialState);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(
    false,
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string>(
    moment()
      .startOf('month')
      .format(),
  );

  const prevForm = usePrevious(form);

  const calendarRef = useRef(null);
  const formRef = useRef(form);

  const baseUrl = shouldDisplayForPractice
    ? Routes.provider.practice.show.calendar
    : Routes.provider.calendar.root;

  const handleChangeForm = (field: string, value: string): void => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleChangeSearch = (value: string): void => {
    setSearchQuery(value);
  };

  const handleClearFilters = () => {
    setForm(initialState);
  };

  const handleStartSearch = () => {
    handleChangeForm('search', searchQuery);
  };

  const handleClickAppointment = (appointment: EventClickArg): void => {
    const id = appointment.event.id;

    const url = buildPath(baseUrl, null, {
      appointment: id,
    });

    history.push(url);
  };

  const handleNavigateToCalendar = () => {
    history.push(baseUrl);
  };

  const handleChangeDate = (date: string): void => {
    setSelectedDate(date);
  };

  const handleApplyDate = (): void => {
    calendarRef.current.getApi().gotoDate(selectedDate);
  };

  const handleClickCancelAppointment = useCallback((): void => {
    setIsCancelModalVisible(true);
  }, []);

  const handleCloseConfirmationModal = useCallback((): void => {
    setIsCancelModalVisible(false);
  }, []);

  const handleCancelAppointment = useCallback(async (): Promise<void> => {
    loader.startLoading('cancel');

    try {
      const url = buildPath(Routes.api2.virtualAppointment, {
        id: appointmentId,
      });

      const body = {
        virtual_appointment: {
          appointment_status: 'cancelled',
        },
      };

      await Api.utility.patch(url, body);

      const closeModalUrl = buildPath(Routes.provider.calendar.root);
      history.push(closeModalUrl);

      handleCloseConfirmationModal();
      notifier.success('Appointment cancelled');

      calendarRef.current.getApi().refetchEvents();
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('cancel');
  }, [appointmentId]);

  const fetchEvents: EventSourceFunc = useCallback((info, onSuccess) => {
    const extraParams = (() => {
      if (patientId) {
        return {
          patient_id: patientId,
        };
      }

      if (isAdmin || shouldDisplayForPractice) {
        return {
          practice_id: user.practice_id,
        };
      }

      return {
        admin_user_id: user.id,
      };
    })();

    const url = buildPath(
      Routes.api2.virtualAppointments,
      null,
      {
        start_at: info.startStr,
        end_at: info.endStr,
        ...formRef.current,
        ...extraParams,
      },
      ['patient', 'admin_user'],
    );

    Api.utility.get(url).then(res => {
      const appointments = new Modeler<VirtualAppointment[]>(res.data).build();
      const formattedEvents = appointments.map(formatAppointment);

      onSuccess(formattedEvents);
    });
  }, []);

  // Any form field changes (expect for serch query) will automatically trigger a calendar refresh
  useEffect(() => {
    formRef.current = form;

    if (prevForm && !areObjectsEqual(form, prevForm)) {
      calendarRef.current.getApi().refetchEvents();
    }
  }, [form]);

  return (
    <>
      <Panel>
        <Constraint size="large">
          <CalendarHeader
            form={form}
            onChangeDate={handleChangeDate}
            onChangeForm={handleChangeForm}
            onChangeSearch={handleChangeSearch}
            onClearFilters={handleClearFilters}
            onClickApplyDate={handleApplyDate}
            onStartSearch={handleStartSearch}
            practiceId={user.practice_id}
            scheduleAppointmentLink={Routes.provider.appointments.new}
            searchQuery={searchQuery}
            selectedDate={selectedDate}
          />

          <FullCalendar
            eventClick={handleClickAppointment}
            events={fetchEvents}
            plugins={[dayGridPlugin]}
            ref={calendarRef}
            {...fullCalendarProps}
          />
        </Constraint>
      </Panel>

      <AppointmentModal
        appointmentId={appointmentId}
        displayFor="specialist"
        isCancelModalVisible={isCancelModalVisible}
        onCancelAppointment={handleCancelAppointment}
        onCloseConfirmationModal={handleCloseConfirmationModal}
        onCloseModal={handleNavigateToCalendar}
        onOpenConfirmationModal={handleClickCancelAppointment}
        userId={user.id}
      />
    </>
  );
};

export default Calendar;
