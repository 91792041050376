import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AccessControl from '@/components/access_control/AccessControl';
import Button from '@/components/button/Button';
import Icon from '@/components/icon/Icon';
import { PolicyGroups } from '@/components/router';
import { selectCurrentUserRole } from '@/selectors/users';
import { Routes } from '@/utils/routeUtils';
import { logoutPath } from '../utils';
import PatientLinks from './PatientLinks';
import SpecialistLinks from './SpecialistLinks';

interface Props {
  onPress: () => void;
  onPressLink: () => void;
}

const SmallHeaderLinks = (props: Props) => {
  const { onPress, onPressLink } = props;

  const userRole = useSelector(selectCurrentUserRole);
  const logoutLink = logoutPath(userRole);

  return (
    <div className="header__mobile-nav-dropdown">
      <AccessControl allowedRoles={PolicyGroups.patientOnly}>
        <PatientLinks onPressLink={onPressLink} />
      </AccessControl>

      <AccessControl allowedRoles={PolicyGroups.practiceManagers}>
        <SpecialistLinks onPressLink={onPressLink} />
      </AccessControl>

      <AccessControl allowedRoles={PolicyGroups.nonBillingAny}>
        <Link
          className="header__nav-dropdown-content -first"
          to={Routes.dashboard}
          onClick={onPressLink}>
          Dashboard
        </Link>
      </AccessControl>

      <AccessControl allowedRoles={PolicyGroups.nonBillingAdmins}>
        <Link
          className="header__nav-dropdown-content"
          to={Routes.dashboardLive}
          onClick={onPressLink}>
          Live View
        </Link>
      </AccessControl>

      <AccessControl allowedRoles={PolicyGroups.nonBillingAny}>
        <Link
          className="header__nav-dropdown-content"
          to={Routes.patients}
          onClick={onPressLink}>
          Patients
        </Link>

        <Link
          className="header__nav-dropdown-content"
          to={Routes.calls}
          onClick={onPressLink}>
          Call Schedule
        </Link>
      </AccessControl>

      <AccessControl allowedRoles={PolicyGroups.atLeastCareManager}>
        <Button onClick={onPress} style="dropdown">
          Admin
          <Icon icon="caretRight" />
        </Button>
      </AccessControl>

      <Link
        className="header__nav-dropdown-content"
        to={Routes.profile.show}
        onClick={onPressLink}>
        Profile
      </Link>

      <a
        className="header__nav-dropdown-content"
        data-method="delete"
        href={logoutLink}>
        Log Out
      </a>
    </div>
  );
};

export default SmallHeaderLinks;
