import React from 'react';
import { Link } from 'react-router-dom';

export interface Props {
  footer: string;
  linkTo?: string;
  text: string;
  title: string;
}

const SimpleBlock = ({ footer, linkTo, text, title }: Props): JSX.Element => {
  const renderTitle = (() => {
    if (linkTo) {
      return <Link to={linkTo}>{title}</Link>;
    }

    return <span>{title}</span>;
  })();

  return (
    <div className="dashboard-simple-block">
      <div className="dashboard-simple-block__content">
        <div className="dashboard-simple-block__header">{renderTitle}</div>

        <div className="dashboard-simple-block__body">
          <span className="dashboard-simple-block__text">{text}</span>
          <span className="dashboard-simple-block__footer">{footer}</span>
        </div>
      </div>
    </div>
  );
};

export default SimpleBlock;
