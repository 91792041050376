import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BackLink from '@/components/button/BackLink';
import Container from '@/components/container/Container';
import EnrollmentSidebar from '@/contexts/enrollment/components/EnrollmentSidebar';
import EnrollmentEdit from '@/contexts/enrollment/modules/EnrollmentEdit';
import EnrollmentIndex from '@/contexts/enrollment/modules/EnrollmentIndex';
import EnrollmentNew from '@/contexts/enrollment/modules/EnrollmentNew';
import PatientHeader from '@/contexts/patients/components/PatientHeader';
import { Routes } from '@/utils/routeUtils';
import EnrollmentBanner from './components/EnrollmentBanner';

const Enrollment = () => {
  return (
    <Switch>
      <Route path={Routes.patientsEnrollmentNew} component={EnrollmentNew} />
      <Route path={Routes.patientsEnrollmentEdit} component={EnrollmentEdit} />
      <Route path={Routes.patientsEnrollmentStep} component={EnrollmentIndex} />
      <Route exact path={Routes.patientsEnrollmentIndex}>
        <Container>
          <BackLink to={Routes.patients}>Back to Patients</BackLink>
        </Container>

        <PatientHeader />
        <EnrollmentBanner />
        <EnrollmentSidebar />
      </Route>
      <Route path={Routes.patientsEnrollment} component={EnrollmentIndex} />
    </Switch>
  );
};

export default Enrollment;
