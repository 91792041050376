import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MedicalHistoryEdit from '@/contexts/patients/modules/medical_records/MedicalHistoryEdit';
import MedicalHistoryNew from '@/contexts/patients/modules/medical_records/MedicalHistoryNew';
import MedicalRecordsIndex from '@/contexts/patients/modules/medical_records/MedicalRecordsIndex';
import OtherHistoryEdit from '@/contexts/patients/modules/medical_records/OtherHistoryEdit';
import OtherHistoryNew from '@/contexts/patients/modules/medical_records/OtherHistoryNew';
import { Routes } from '@/utils/routeUtils';

const Patients = () => {
  return (
    <Switch>
      <Route
        path={Routes.patientsMedicalHistoryEdit}
        component={MedicalHistoryEdit}
      />
      <Route
        path={Routes.patientsMedicalHistoryNew}
        component={MedicalHistoryNew}
      />
      <Route
        path={Routes.patientsOtherHistoryNew}
        component={OtherHistoryNew}
      />
      <Route
        path={Routes.patientsOtherHistoryEdit}
        component={OtherHistoryEdit}
      />
      <Route
        path={Routes.patientsMedicalRecords}
        component={MedicalRecordsIndex}
      />
    </Switch>
  );
};

export default Patients;
