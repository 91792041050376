import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getSelectedPatient } from '@/selectors/api';
import { FormComponentConfig } from '@/types/form';
import { SideEffect } from '@/types/generic';
import { relationOptions } from '@/utils/dropdownUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';
import { IdParams } from '@/types/params';

interface Props {
  isModal?: boolean;
  modalBackPath?: string;
  onSuccess?: SideEffect;
}

const AlertRecipientsNew = (props: Props) => {
  const { isModal, modalBackPath, onSuccess } = props;

  const patient = useSelector(getSelectedPatient);

  const history = useHistory();

  const dispatch = useDispatch();

  const { id } = useParams<IdParams>();

  const [query] = returnTabQuery();

  const backPath = modalBackPath
    ? modalBackPath
    : buildPath(Routes.patientsRemotePatientCare, { id }, query);

  const colSize = isModal ? 6 : 4;

  const handleSuccess = () => {
    if (isModal) {
      onSuccess();
    }

    history.push(backPath);
  };

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/alert_recipients',
      model: 'alert_recipient',
      mergeFields: {
        patient_id: patient.id,
      },
      method: 'POST',
      onSuccess: handleSuccess,
      onCancel: () => history.push(backPath),
      submitText: 'Save',
    },
    state: {
      name: '',
      email: '',
      relation: '',
      sms_phone_number: '',
      voice_phone_number: '',
    },
    rules: {
      name: { required: true },
      email: { type: 'email' },
      relation: { required: true },
      sms_phone_number: { type: 'phone' },
      voice_phone_number: { type: 'phone' },
    },
    layout: [
      {
        title: 'Add Recipient',
        fields: [
          [
            {
              label: 'Name',
              field: 'name',
              placeholder: 'Enter name',
              type: 'text',
              size: colSize,
            },
            {
              label: 'Email Address',
              field: 'email',
              placeholder: 'Enter Recipient Email',
              type: 'text',
              size: colSize,
            },
          ],
          [
            {
              label: 'Relation to Patient',
              field: 'relation',
              placeholder: 'Select Relation',
              type: 'select',
              size: colSize,
              options: relationOptions,
            },
            {
              label: 'SMS Phone',
              field: 'sms_phone_number',
              placeholder: 'Enter SMS Phone Number',
              type: 'text',
              size: colSize,
            },
          ],
          [
            {
              label: 'Voice Phone',
              field: 'voice_phone_number',
              placeholder: 'Enter Voice Phone Number',
              type: 'text',
              size: colSize,
            },
          ],
        ],
      },
    ],
  };
  return (
    <PatientForm
      backPath={backPath}
      backText={isModal ? null : 'Back to Recipients'}
      config={config}
      removePanel={isModal}
    />
  );
};

export default AlertRecipientsNew;
