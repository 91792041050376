/**
 * Caution: do not pass null or undefined.
 * `null` is accidentally supported due to `Math.floor(null)` returning 0
 */
export const toInt = (val: number | string): number => {
  if (typeof val === 'string') {
    const valInt = parseInt(val, 10);

    return isNaN(valInt) ? 0 : Math.floor(valInt);
  }

  return Math.floor(val);
};
