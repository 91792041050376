import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import PatientForm from '@/contexts/patients/components/PatientForm';
import { isEnrollmentPath } from '@/contexts/patients/utils/patientUtils';
import { FormComponentConfig } from '@/types/form';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { UUID } from '@/types/generic';

interface Params {
  patient_id: UUID;
  id: UUID;
  step: string;
}

const AllergiesEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { patient_id, id, step } = useParams<Params>();

  const [allergy, setAllergy] = useState(null);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'allergies',
    onSuccess: (response: any) => {
      setAllergy(parseResponseData(response));
    },
  });

  useEffect(() => {
    getEntry();
  }, []);

  const isEnrollment = isEnrollmentPath();

  const backPath = isEnrollment
    ? buildPath(Routes.patientsEnrollment, { id: patient_id, step })
    : buildPath(Routes.patientsAllergies, { id: patient_id });

  if (isLoading || !allergy) {
    return <ActivityIndicator />;
  }

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: `api/v1/allergies/${id}`,
      model: 'allergy',
      method: 'PUT',
      onCancel: () => history.push(backPath),
      onSuccess: () => history.push(backPath),
      clearFields: {
        end_date: formState => formState.status === 'current',
      },
    },
    state: allergy.attributes,
    rules: {
      source: { required: true },
      allergy_type: { required: true },
      name: { required: true },
      severity: { required: true },
      instructions: { required: true },
    },
    layout: [
      {
        title: 'Edit Allergy',
        fields: [
          [
            {
              label: 'Source',
              field: 'source',
              placeholder: 'Enter source',
              type: 'text',
              size: 8,
            },
          ],
          [
            {
              label: 'Allergy Type',
              field: 'allergy_type',
              placeholder: 'Medicine or Environmental',
              type: 'tabs',
              size: 8,
              options: [
                {
                  label: 'Environmental',
                  value: 'environmental',
                },
                {
                  label: 'Medicine',
                  value: 'medicine',
                },
              ],
            },
          ],
          [
            {
              label: 'Allergy',
              field: 'name',
              placeholder: 'Search for allergy',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/allergy_options',
              queryParams: (formState: any) => {
                if (formState.allergy_type === 'environmental') {
                  return { allergy_type: 'environmental' };
                }

                return { allergy_type: 'medication' };
              },
              size: 8,
              formatTypeaheadResult: (result: any) => {
                return {
                  label: result.attributes.name,
                  value: result.attributes.name,
                };
              },
            },
          ],
          [
            {
              label: 'Severity',
              field: 'severity',
              placeholder: 'Enter severity',
              type: 'text',
              size: 4,
            },
            {
              label: 'End Date',
              field: 'end_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
              disableIf: state => state.status === 'current',
            },
          ],
          [
            {
              label: 'Patient is currently allergic',
              field: 'status',
              type: 'checkbox',
              size: 4,
              offset: 4,
              checkedValue: 'current',
              uncheckedValue: 'previous',
            },
          ],
          [
            {
              label: 'Instructions',
              field: 'instructions',
              placeholder: 'Enter instructions',
              type: 'text',
              size: 8,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Allergies"
      config={config}
    />
  );
};

export default AllergiesEdit;
