import React, { useMemo } from 'react';

import Button from '@/components/button/Button';
import {
  create,
  ReadingThreshold,
  ReadingType,
  readingTypes,
} from '@/types/v2/reading_threshold';
import { updateArrayIndex } from '@/utils/arrayUtils';
import ReadingThresholdGroup from './ReadingThresholdGroup';

export interface ThresholdEdit extends ReadingThreshold {
  index: number;
}

interface Props {
  onUpdateThresholds: (thresholds: ReadingThreshold[]) => void;
  readingThresholds: ReadingThreshold[];
}

const ReadingThresholdList = ({
  onUpdateThresholds,
  readingThresholds,
}: Props): JSX.Element => {
  const handleChangeReadingType = (
    from: ReadingType,
    to: ReadingType,
  ): void => {
    const newThresholds = readingThresholds.map(threshold => {
      if (threshold.reading_type === from) {
        return {
          ...threshold,
          reading_type: to,
        };
      }

      return threshold;
    });

    onUpdateThresholds(newThresholds);
  };

  const handleUpdateThresholds = (
    index: number,
    field: string,
    value: string,
  ): void => {
    const updatedThresholds = [...readingThresholds];
    updatedThresholds[index][field] = value;

    onUpdateThresholds(updatedThresholds);
  };

  const handleAddReadingType = (): void => {
    const readingType = readingTypes.find(type => !thresholds[type].length);
    handleAddAlertLevel(readingType);
  };

  const handleAddAlertLevel = (readingType: ReadingType): void => {
    const newThreshold = create({ reading_type: readingType });
    onUpdateThresholds([...readingThresholds, newThreshold]);
  };

  const handleDeleteRecord = (index: number): void => {
    const record = readingThresholds[index];

    if (record.id) {
      const newRecord: ReadingThreshold = {
        ...record,
        _destroy: true,
      };

      const newThresholds = updateArrayIndex(
        readingThresholds,
        index,
        newRecord,
      );
      onUpdateThresholds(newThresholds);
    } else {
      const newThresholds = [...readingThresholds];
      newThresholds.splice(index, 1);
      onUpdateThresholds(newThresholds);
    }
  };

  const thresholds = useMemo(() => {
    const thresholdState = {};

    for (const readingType of readingTypes) {
      thresholdState[readingType] = [];
    }

    for (const [index, threshold] of readingThresholds.entries()) {
      if (!threshold._destroy) {
        thresholdState[threshold.reading_type].push({
          ...threshold,
          index,
        });
      }
    }

    return thresholdState;
  }, [readingThresholds]);

  const renderThresholdGroups = useMemo(() => {
    const orderedReadingTypes = readingThresholds.map(
      threshold => threshold.reading_type,
    );
    const uniqueReadingTypes = [
      ...new Set([...orderedReadingTypes, ...readingTypes]),
    ];

    return uniqueReadingTypes.map(readingType => {
      const filteredThresholds = thresholds[readingType];

      if (filteredThresholds && filteredThresholds.length) {
        return (
          <ReadingThresholdGroup
            key={readingType}
            onAddAlertLevel={handleAddAlertLevel}
            onChange={handleUpdateThresholds}
            onChangeReadingType={handleChangeReadingType}
            onDelete={handleDeleteRecord}
            readingType={readingType}
            readingThresholds={filteredThresholds}
          />
        );
      }
    });
  }, [thresholds]);

  const canAddGroup = (): boolean => {
    return !!readingTypes.find(readingType => !thresholds[readingType].length);
  };

  const renderAddGroup = (() => {
    if (canAddGroup()) {
      return (
        <div className="columns">
          <div className="column">
            <Button style="flat-large add" onClick={handleAddReadingType}>
              Add Reading Type
            </Button>
          </div>
        </div>
      );
    }
  })();

  return (
    <div className="reading-threshold-list">
      {renderThresholdGroups}
      {renderAddGroup}
    </div>
  );
};

export default ReadingThresholdList;
