import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import ModalContent from '@/components/modal/ModalContent';
import { getSelectedPatient } from '@/selectors/api';
import { getManualEntryReasonOptions } from '@/selectors/options';
import { selectIsCurrentUserAnyAdmin } from '@/selectors/users';
import { Actions, FormComponentConfig } from '@/types/form';
import { Attributable } from '@/types/generic';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { callStatusOptions } from '@/utils/dropdownUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { formatShortDate } from '@/utils/dateUtils';
import { breakDuration, buildDurationFromForm, formatTime } from '@/utils/timeUtils';
import { fullName } from '@/utils/userUtils';
import PatientForm from '../../components/PatientForm';
import { UUID } from '@/types/generic';

interface Params {
  patient_id: UUID;
  id: UUID;
}



const CallsEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { patient_id, id } = useParams<Params>();

  const manualEntryReasonOptions = useSelector(getManualEntryReasonOptions);
  const patient = useSelector(getSelectedPatient);
  const isAdmin = useSelector(selectIsCurrentUserAnyAdmin);

  const [response, setResponse] = useState(null);

  const serviceOptions = useMemo(() => {
    return patient.attributes.patient_services.data.map(service => ({
      label: service.attributes.name,
      value: service.attributes.service_id,
    }));
  }, [patient]);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'calls',
    url: `api/v1/calls/${id}?include=care_manager`,
    onSuccess: (res: any) => {
      const data = parseResponseData(res);
      const duration = data.attributes.duration;

      const [hours, minutes, seconds] = breakDuration(duration);

      data.attributes.hours = hours;
      data.attributes.minutes = minutes;
      data.attributes.seconds = seconds;

      if (!hours && !minutes && !seconds) {
        // what's the purpose of this?
        data.attributes.minutes = 1;
      }

      setResponse(data);
    },
  });

  useEffect(() => {
    getEntry();
  }, []);

  const [query] = returnTabQuery();

  const backPath = buildPath(
    query.service === 'rpm'
      ? Routes.patientsCareManagementRpm
      : Routes.patientsCareManagement,
    { id: patient_id },
    query,
  );

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const initialSelection = () => {
    const careManager = response.attributes.care_manager;

    return {
      label: `${careManager.first_name} ${careManager.last_name}`,
      value: careManager.id,
    };
  };

  // FIXME: updating the service type doesn't update the call-time totals

  const commonConfig: Omit<FormComponentConfig, "rules"|"layout"> = {
    actions: {
      alterFormBeforeSubmit: (formState: any) =>
        buildDurationFromForm(formState),
      path: `api/v1/calls/${id}`,
      model: 'call',
      method: 'PUT',
      onSuccess: () => history.push(backPath),
    },
    state: response.attributes
  };

  const adminConfig: FormComponentConfig = {
    ...commonConfig,
    rules: {
      user_id: { required: true },
      patient_phone_number: { required: true },
      start_date: { required: true },
      // FIXME: duration validator ignores changes to the form
      duration: { required: true },
      interaction_reason: { required: true },
      manual_entry_reason: { required: true },
      service_id: { required: true },
      status: { required: true },
    },
    layout: [
      {
        title: 'Add Manual Entry',
        fields: [
          [
            {
              label: 'Care Manager',
              field: 'user_id',
              placeholder: 'Search for care manager',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/care_managers',
              size: 4,
              initialSelection: initialSelection(),
              formatTypeaheadResult: (result: any) => {
                return {
                  label: fullName(result),
                  value: result.id,
                };
              },
            },
            {
              label: 'Phone Number',
              field: 'patient_phone_number',
              type: 'select',
              size: 4,
              placeholder: 'Select phone number',
              options: [
                {
                  label: patient.attributes.phone_number,
                  value: patient.attributes.phone_number,
                },
              ],
            },
          ],
          [
            {
              label: 'Date Worked',
              field: 'start_at',
              placeholder: 'Select date',
              type: 'datetime',
              size: 4,
            },
            {
              label: 'Duration Worked',
              field: 'hours',
              placeholder: 'Hours',
              type: 'number',
              size: 1,
            },
            {
              field: 'minutes',
              placeholder: 'Minutes',
              type: 'number',
              size: 1,
            },
            {
              field: 'seconds',
              placeholder: 'Seconds',
              type: 'number',
              size: 1,
            },
          ],
          [
            {
              label: 'Interaction Reason',
              field: 'interaction_reason',
              type: 'select',
              size: 4,
              placeholder: 'Select interaction reason',
              options: manualEntryReasonOptions,
            },
            {
              label: 'Manual Entry Reason',
              field: 'manual_entry_reason',
              type: 'select',
              size: 4,
              placeholder: 'Select manual entry reason',
              options: manualEntryReasonOptions,
            },
          ],
          [
            {
              label: 'Service Type',
              field: 'service_id',
              type: 'select',
              size: 4,
              placeholder: 'Select service',
              options: serviceOptions,
            },
            {
              label: 'Call Status',
              field: 'status',
              type: 'select',
              size: 4,
              placeholder: 'Select status',
              options: callStatusOptions,
            },
          ],
          [
            {
              label: 'Include Time Spent on Phone',
              field: 'duration_includes_time_spent_on_phone',
              type: 'checkbox',
              size: 4,
            },
          ],
        ],
      },
    ],
  };

  const nonAdminConfig: FormComponentConfig = {
    ...commonConfig,
    rules: {
      service_id: { required: true },
    },
    layout: [
      {
        title: 'Add Manual Entry',
        fields: [
	  [
            {
              type: 'custom',
              size: 4,
              // include this so it's easier for user to see if they clicked the correct entry
              renderCustom: formState => {
                const text = `${formatShortDate(formState.start_at)} at ${formatTime(
                  formState.start_at,
                )}`;

                return <ModalContent label={'Call Time'} value={text} />;
              }
            }
          ],
          [
            {
              label: 'Service Type',
              field: 'service_id',
              type: 'select',
              size: 4,
              placeholder: 'Select service',
              options: serviceOptions,
            }
	  ]
	]
      }
    ]
  };

  return (
    <PatientForm backPath={backPath} backText="Back to Calls" config={isAdmin ? adminConfig : nonAdminConfig} />
  );
};

export default CallsEdit;
