import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import ModalContent from '@/components/modal/ModalContent';
import { AdminEntry } from '@/types/admin_entry';
import { UUID } from '@/types/generic';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { formatShortDateTime } from '@/utils/dateUtils';
import { buildPath } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';

interface Props {
  adminEntryId: UUID;
}

const AdminEntryModal = (props: Props) => {
  const { adminEntryId } = props;

  const dispatch = useDispatch();

  const [entry, setEntry] = useState<AdminEntry>(null);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    model: 'calls',
    url: buildPath('api/v1/admin_entries/:id', { id: adminEntryId }),
    onSuccess: (response: any) => {
      setEntry(parseResponseData(response));
    },
  });

  useEffect(() => {
    if (adminEntryId) {
      getEntry();
    }
  }, [adminEntryId]);

  if (!entry || isLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className="patient-calls__modal-wrapper">
      <h3 className="patient-calls__modal-call-header">Call Summary</h3>

      <ModalContent
        label="Date"
        value={formatShortDateTime(entry.attributes.created_at)}
      />

      <ModalContent label="Service" value={entry.attributes.service.name} />

      <ModalContent
        label="Reason"
        value={titleizeSnakeCase(entry.attributes.manual_entry_reason)}
      />

      <ModalContent label="Description" value={entry.attributes.notes} />
    </div>
  );
};

export default AdminEntryModal;
