import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Api from '@/api/Api';
import BackLink from '@/components/button/BackLink';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { UUID } from '@/types/generic';
import { SearchQuery } from '@/types/table';
import { Practice } from '@/types/v2/practice';
import useLoadingState from '@/utils/api/useLoadingState';
import useSimpleForm from '@/utils/form/useSimpleForm';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import PracticeHeader from './PracticeHeader';
import PracticeSpecialistsTable from './PracticeSpecialistsTable';
import SearchHeader, { FormState } from './SearchHeader';

interface Params {
  practice_id: UUID;
}

const initialSearchQuery: FormState = {
  search: '',
  per: '24',
};

const Providers = () => {
  const backPath = Routes.chart.providers.root;
  const form = useSimpleForm<typeof initialSearchQuery>(initialSearchQuery);

  const loader = useLoadingState('practice');
  const notifier = useNotifier();

  const { practice_id } = useParams<Params>();

  const [searchInput, setSearchInput] = useState<string>('');
  const [practice, setPractice] = useState<Practice>(null);

  const handleChangeSearch = (value: string): void => {
    setSearchInput(value);
  };

  const handleClearFilters = useCallback((): void => {
    form.set(initialSearchQuery);
  }, []);

  const handleStartSearch = useCallback((): void => {
    form.update('search', searchInput);
  }, [searchInput]);

  const getPractice = async (): Promise<void> => {
    loader.startLoading('practice');

    try {
      const url = buildPath(Routes.api2.practice, { id: practice_id }, null, [
        'addresses',
        'hospital',
        'health_system',
      ]);

      const response = await Api.utility.get(url);
      const resource = new Modeler<Practice>(response.data).build();

      setPractice(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('practice');
  };

  useEffect(() => {
    if (practice_id) {
      getPractice();
    }
  }, [practice_id]);

  const renderPractice = (() => {
    if (!loader.isLoading('practice') && practice) {
      return <PracticeHeader practice={practice} />;
    }

    return <loader.activity />;
  })();

  return (
    <>
      <Container>
        <BackLink to={backPath}>Back</BackLink>
      </Container>

      <Panel>
        <Container>{renderPractice}</Container>
      </Panel>

      <Panel>
        <Container>
          <SearchHeader
            form={form.data}
            onChangeForm={form.update}
            onChangeSearch={handleChangeSearch}
            onClearFilters={handleClearFilters}
            onStartSearch={handleStartSearch}
            searchQuery={searchInput}
          />

          {/* This Typing is needed to force compatibility in the form interface */}
          <PracticeSpecialistsTable
            practiceId={practice_id}
            query={(form.data as unknown) as SearchQuery}
          />
        </Container>
      </Panel>
    </>
  );
};

export default Providers;
