import FullCalendar, {
  EventClickArg,
  EventSourceFunc,
} from '@fullcalendar/react';
import React, { useEffect, useRef } from 'react';
import { useResponsive } from 'react-hooks-responsive';

// This must be imported after FullCalendar
import dayGridPlugin from '@fullcalendar/daygrid';

import Api from '@/api/Api';
import Button from '@/components/button/Button';
import { CallAttributes } from '@/types/call';
import { SideEffect } from '@/types/generic';
import { Patient } from '@/types/patient';
import { fullCalendarProps } from '@/utils/calendarUtils';
import Modeler from '@/utils/modeler/modeler';
import { buildPath } from '@/utils/routeUtils';
import { breakpoints } from '@/utils/screenUtils';
import { fullName } from '@/utils/userUtils';
import { careManagementService } from '../../utils/patientUtils';

interface Props {
  onClickEvent: SideEffect;
  onClickSchedule: SideEffect;
  patient: Patient;
}

const CallCalendar = (props: Props) => {
  const { onClickEvent, onClickSchedule, patient } = props;

  const calendarRef = useRef(null);

  const { screenIsAtMost } = useResponsive(breakpoints);

  const isMobile = screenIsAtMost('mobile');

  const service = { service: careManagementService() };

  var startDate;
  var endDate;

  useEffect(() => {
    if (!calendarRef.current) {
      return;
    }

    if (isMobile && calendarRef.current) {
      calendarRef.current.getApi().changeView('dayGridDay');
    } else if (calendarRef.current) {
      calendarRef.current.getApi().changeView('dayGridMonth');
    }
  }, [isMobile]);

  const fetchEvents: EventSourceFunc = (info, onSuccess) => {
    startDate = info.startStr
    startDate = startDate.slice(0,10) +' '+ startDate.slice(11,19) 
    endDate = info.endStr
    endDate = endDate.slice(0,10) +' '+ endDate.slice(11,19) 
    const url = buildPath(
      'api/v1/calls',
      {},
      {
        ...service,
        end_at: endDate,
        include: 'care_manager',
        patient_id: patient.id,
        per: null,
        start_at: startDate,
 
      },
    );

    Api.utility.get(url).then(response => {
      const calls = new Modeler<CallAttributes[]>(response.data).build();
      const formattedCalls = calls.map(formatEvent);

      onSuccess(formattedCalls);
    });
  };

  const formatEvent = (call: CallAttributes) => ({
    id: call.id,
    date: call.start_at,
    title: fullName(call.care_manager),
  });

  const handleClickAddCall = () => {
    onClickSchedule();
  };

  const handleSelectEvent = (event: EventClickArg): void => {
    const eventId = event.event._def.publicId;
    onClickEvent(eventId);
  };

  return (
    <>
      <div className="columns">
        <div className="column is-12-mobile is-6-tablet" />

        <div className="column is-12-mobile is-6-tablet table__button-wrapper">
          <Button color="secondary" onClick={handleClickAddCall}>
            Schedule Call
          </Button>
        </div>
      </div>

      <FullCalendar
        eventClick={handleSelectEvent}
        events={fetchEvents}
        plugins={[dayGridPlugin]}
        ref={calendarRef}
        {...fullCalendarProps}
      />
    </>
  );
};

export default CallCalendar;