import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getPatientSpecialistsOptions } from '@/selectors/api';
import { FormComponentConfig } from '@/types/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { useSelector } from 'react-redux';
import PatientForm from '../../components/PatientForm';
import { IdParams } from '@/types/params';

const AppointmentsNew = () => {
  const history = useHistory();

  const { id } = useParams<IdParams>();

  const patientOptions = useSelector(getPatientSpecialistsOptions);

  const [query] = returnTabQuery();

  const backPath = buildPath(Routes.patientsAppointments, { id }, query);

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/appointments',
      model: 'appointment',
      mergeFields: {
        patient_id: id,
      },
      method: 'POST',
      onSuccess: () => history.push(backPath),
    },
    state: {
      date: '',
      facility: '',
      reason: '',
      summary: '',
      is_conducted: false,
      specialist_id: '',
    },
    rules: {
      date: { required: true },
      facility: { required: true },
      specialist_id: { required: true },
      reason: { required: true },
    },
    layout: [
      {
        title: 'Add Appointment',
        fields: [
          [
            {
              label: 'Date',
              field: 'date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
          ],
          [
            {
              label: 'Facility',
              field: 'facility',
              placeholder: 'Enter facility',
              type: 'text',
              size: 4,
            },
            {
              label: 'Provider',
              field: 'specialist_id',
              placeholder: 'Select provider',
              type: 'select',
              size: 4,
              options: patientOptions,
            },
          ],
          [
            {
              label: 'Reason',
              field: 'reason',
              placeholder: 'Enter reason',
              type: 'text',
              size: 8,
            },
          ],
          [
            {
              label: 'Appointment was conducted',
              field: 'is_conducted',
              type: 'checkbox',
              size: 4,
            },
          ],
          [
            {
              label: 'Apointment Summary',
              field: 'summary',
              placeholder: 'Enter appointment summary',
              type: 'text',
              size: 8,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Appointments"
      config={config}
    />
  );
};

export default AppointmentsNew;
