import classNames from 'classnames';
import React from 'react';

import { SideEffect } from '@/types/generic';

interface Props {
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: SideEffect;
  onChange?: SideEffect;
  onFocus?: SideEffect;
  onPressEnter?: SideEffect;
  placeholder?: string;
  value: string;
  type?: string;
}

export const Input = (props: Props) => {
  const {
    isDisabled,
    isInvalid,
    onChange,
    onPressEnter,
    type,
    value,
    ...inputProps
  } = props;

  const formatValue = (newValue: string) => {
    if (type === 'number') {
      return newValue.replace(/\D/g, '');
    }

    if (type === 'phonenumber') {
      return newValue.replace(/\D/g, '').slice(0, 12);
    }

    if (type === 'zipcode') {
      return newValue.replace(/\D/g, '').slice(0, 5);
    }

    return newValue;
  };

  const handleChange = event => {
    const newValue = formatValue(event.target.value);

    if (onChange) {
      onChange(newValue);
    }
  };

  // FIXME: `props.value` may be a number, despite its definition as a string. Symptom: `0` hours is treated as the field being empty.
  const isFilled = !!(props.value && props.value !== '');

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onPressEnter) {
      onPressEnter();
    }
  };

  const fieldType = () => {
    switch (type) {
      case 'number':
        return 'number';

      case 'password':
        return 'password';

      default:
        return 'text';
    }
  };

  return (
    <input
      className={classNames('form-input', {
        'is-filled': isFilled,
      })}
      disabled={isDisabled}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      type={fieldType()}
      value={value || ''}
      {...inputProps}
    />
  );
};

export default Input;
