import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import CheckboxGroup from '@/components/form/CheckboxGroup';
import ErrorMessage from '@/components/form/ErrorMessage';
import InputGroup from '@/components/form/InputGroup';
import RadioList from '@/components/form/RadioList';
import SearchGroup from '@/components/form/SearchGroup';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Modal from '@/components/modal/Modal';
import Panel from '@/components/panel/Panel';
import { getSelectedPatient } from '@/selectors/api';
import { Validations } from '@/types/form';
import useDelRequest from '@/utils/api/useDelRequest';
import useGetRequest from '@/utils/api/useGetRequest';
import usePutRequest from '@/utils/api/usePutRequest';
import { parseErrorData, parseResponseData } from '@/utils/apiUtils';
import {
  mathOptions,
  notifyCareManagerOptions,
  notifyOptions,
} from '@/utils/dropdownUtils';
import { validate } from '@/utils/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { usePrevious } from '@/utils/stateUtils';
import { fullName } from '@/utils/userUtils';
import AlertRecipientsNew from './AlertRecipientsNew';
import { IdParams } from '@/types/params';

const AlertsEdit = () => {
  const patient = useSelector(getSelectedPatient);

  const history = useHistory();

  const dispatch = useDispatch();

  const { id } = useParams<IdParams>();

  const [query] = returnTabQuery();

  const FORM_STATE = {
    alert_type_id: '',
    alert_recipient_id: '',
    care_manager_id: '',
    device_category_id: '',
    device_id: '',
    device_make_id: '',
    device_model_id: '',
    device_name_id: '',
    device_type_id: '',
    math_option: '',
    notify_alert: '',
    notify_care_manager: '',
    patient_id: patient.id,
    range_high: '',
    range_low: '',
    user_id: '',
    visibility: '',
    email: '',
    notify_email: false,
    notify_sms: false,
    sms_phone_number: '',
  };

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});

  const [recipients, setRecipients] = useState<any>([]);
  const [recipientOptions, setRecipientOptions] = useState<any>([]);

  const [deviceCategories, setDeviceCategories] = useState<any[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<any[]>([]);
  const [deviceMakes, setDeviceMakes] = useState<any[]>([]);
  const [deviceNames, setDeviceNames] = useState<any[]>([]);
  const [deviceModels, setDeviceModels] = useState<any[]>([]);
  const [alertTypes, setAlertTypes] = useState<any[]>([]);
  const [devices, setDevices] = useState<any[]>([]);

  const [response, setResponse] = useState(null);

  const backPath = buildPath(
    Routes.patientsRemotePatientCare,
    { id: patient.id },
    query,
  );

  const previousForm = usePrevious(form);

  const shouldNotifyAlert = form.notify_alert === 'yes';

  useEffect(() => {
    if (response) {
      const formatted = {
        ...response.attributes,
        device_category_id: response.attributes.device_category?.id,
        device_make_id: response.attributes.device_make?.id,
        device_model_id: response.attributes.device_model?.id,
        device_name_id: response.attributes.device_name?.id,
        device_type_id: response.attributes.device_type?.id,
        email: response.attributes.alert_recipient
          ? response.attributes.alert_recipient.email
          : '',
        sms_phone_number: response.attributes.alert_recipient
          ? response.attributes.alert_recipient.sms_phone_number
          : '',
        notify_email: response.attributes.alert_recipient
          ? response.attributes.alert_recipient.notify_email
          : '',
        notify_sms: response.attributes.alert_recipient
          ? response.attributes.alert_recipient.notify_sms
          : '',
      };
      setForm(formatted);
    }
  }, [response]);

  const formatDeviceEntry = (entry: any) => ({
    label: entry.attributes.name,
    value: entry.id,
  });

  const filterDeviceEntries = (entries: any[], attribute: string) => {
    return entries.filter(
      entry => entry.attributes[attribute] === form[attribute],
    );
  };

  const buildDeviceOptions = (entries: any[], attribute: string) => {
    return filterDeviceEntries(entries, attribute).map(formatDeviceEntry);
  };

  const deviceCategoryOptions = useMemo(() => {
    return deviceCategories.map(formatDeviceEntry);
  }, [deviceCategories]);

  const deviceTypeOptions = useMemo(() => {
    return buildDeviceOptions(deviceTypes, 'device_category_id');
  }, [form.device_category_id]);

  const deviceMakeOptions = useMemo(() => {
    return buildDeviceOptions(deviceMakes, 'device_type_id');
  }, [form.device_type_id]);

  const deviceNameOptions = useMemo(() => {
    return buildDeviceOptions(deviceNames, 'device_make_id');
  }, [form.device_make_id]);

  const deviceModelOptions = useMemo(() => {
    return buildDeviceOptions(deviceModels, 'device_name_id');
  }, [form.device_name_id]);

  const alertTypeOptions = useMemo(() => {
    return buildDeviceOptions(alertTypes, 'device_type_id');
  }, [form.device_type_id]);

  const deviceOptions = useMemo(() => {
    return filterDeviceEntries(devices, 'device_model_id').map(device => ({
      label: device.attributes.serial_number,
      value: device.id,
    }));
  }, [form.device_model_id]);

  useEffect(() => {
    if (
      previousForm &&
      // @ts-ignore
      previousForm.device_category_id !== ''
    ) {
      updateField('device_type_id', '');
    }
  }, [form.device_category_id]);

  useEffect(() => {
    if (
      previousForm &&
      // @ts-ignore
      previousForm.device_type_id !== ''
    ) {
      updateMultipleFields('device_make_id', 'alert_type_id', '');
    }
  }, [form.device_type_id]);

  useEffect(() => {
    if (
      previousForm &&
      // @ts-ignore
      previousForm.device_make_id !== ''
    ) {
      updateField('device_name_id', '');
    }
  }, [form.device_make_id]);

  useEffect(() => {
    if (
      previousForm &&
      // @ts-ignore
      previousForm.device_name_id !== ''
    ) {
      updateField('device_model_id', '');
    }
  }, [form.device_name_id]);

  useEffect(() => {
    if (
      previousForm &&
      // @ts-ignore
      previousForm.device_model_id !== ''
    ) {
      updateField('device_id', '');
    }
  }, [form.device_model_id]);

  useEffect(() => {
    if (
      previousForm &&
      // @ts-ignore
      previousForm.math_option !== ''
    ) {
      updateMultipleFields('range_high', 'range_low', '');
    }
  }, [form.math_option]);

  useEffect(() => {
    if (!shouldNotifyAlert) {
      clearRecipientFields();
    }
  }, [form.notify_alert]);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    model: 'alert_triggers',
    url: buildPath(
      `api/v1/alert_triggers/${id}`,
      {},
      {
        include:
          'alert_recipient,care_manager,creator,device_model,device_name,device_make,device_type,device_category',
      },
    ),
    onSuccess: (res: any) => {
      setResponse(parseResponseData(res));
    },
  });

  const rules = {
    alert_type_id: { required: true },
    device_category_id: { required: true },
    device_id: { required: true },
    device_make_id: { required: true },
    device_model_id: { required: true },
    device_name_id: { required: true },
    device_type_id: { required: true },
    email: { type: 'email' },
    math_option: { required: true },
    notify_alert: { required: true },
    notify_care_manager: { required: true },
    range_high: { type: 'number' },
    range_low: { type: 'number' },
    sms_phone_number: { type: 'phone' },
    user_id: { required: true },
  };

  const [getAlertRecipients] = useGetRequest({
    dispatch,
    model: 'alert_recipients',
    url: buildPath('api/v1/alert_recipients', {}, { patient_id: patient.id }),
    onSuccess: (res: any) => {
      const formatted = res.data.map(item => ({
        label: item.attributes.name,
        value: item.id,
      }));

      setRecipients(res);
      setRecipientOptions(formatted);
    },
  });

  const [getDevices] = useGetRequest({
    dispatch,
    model: 'devices',
    url: buildPath('api/v1/devices/heirarchy', {}, { patient_id: patient.id }),
    onSuccess: (res: any) => {
      setDeviceCategories(res.device_categories.data);
      setDeviceTypes(res.device_types.data);
      setDeviceMakes(res.device_makes.data);
      setDeviceNames(res.device_names.data);
      setDeviceModels(res.device_models.data);
      setAlertTypes(res.alert_types.data);
      setDevices(res.devices.data);
    },
  });

  useEffect(() => {
    getEntry();
    getAlertRecipients();
    getDevices();
  }, []);

  const formatBody = () => {
    const {
      email,
      notify_email,
      notify_sms,
      sms_phone_number,
      notify_alert,
      alert_recipient_id,
      ...formFields
    } = form;

    if (notify_alert === 'no') {
      return {
        alert_trigger: {
          ...formFields,
          notify_alert,
          alert_recipient_id,
        },
      };
    }

    return {
      alert_trigger: {
        ...formFields,
        alert_recipient_id,
        notify_alert,
        alert_recipient_attributes: {
          id: alert_recipient_id,
          email,
          notify_email,
          notify_sms,
          sms_phone_number,
        },
      },
    };
  };

  const handleSuccess = () => {
    dispatch(flashSuccess('Alert edited'));

    history.push(backPath);
  };

  const handleFailure = (res: any) => {
    const errorMessage = parseErrorData(res);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const [submitForm, isSubmitting] = usePutRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: `api/v1/alert_triggers/${id}`,
  });

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);

    if (isValid) {
      setValidations({});
    } else {
      return setValidations(validationMessages);
    }

    submitForm();
  };

  const handleBackPath = () => {
    history.push(backPath);
  };

  const [deleteAlertTrigger] = useDelRequest({
    dispatch,
    onSuccess: handleBackPath,
    url: `api/v1/alert_triggers/${id}`,
  });

  const handleDeleteAlertTrigger = () => {
    deleteAlertTrigger();
  };

  const formatUserResult = (result: any) => {
    return {
      label: fullName(result),
      value: result.id,
    };
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const updateMultipleFields = (
    field: string,
    fieldTwo: string,
    value: any,
  ) => {
    setForm({
      ...form,
      [field]: value,
      [fieldTwo]: value,
    });
  };

  const updateRecipientFields = (field: string, value: any) => {
    const alertRecipient = recipients.data.find(
      recipient => recipient.id === value,
    );

    const autoFillRecipientInfo = {
      ['email']: alertRecipient.attributes.email,
      ['sms_phone_number']: alertRecipient.attributes.sms_phone_number,
      ['voice_phone_number']: alertRecipient.attributes.voice_phone_number,
    };

    setForm({
      ...form,
      ...autoFillRecipientInfo,
      [field]: value,
    });
  };

  const clearRecipientFields = () => {
    setForm({
      ...form,
      ['alert_recipient_id']: '',
      ['email']: '',
      ['sms_phone_number']: '',
      ['notify_email']: false,
      ['notify_sms']: false,
    });
  };

  const initialSelection = () => {
    if (response.attributes.care_manager) {
      return {
        label: fullName(response.attributes.care_manager),
        value: response.attributes.care_manager.id,
      };
    }
  };

  const handleClickNewRecipient = () => {
    const url = buildPath(
      Routes.patientsEditAlertTriggersRecipient,
      { patient_id: patient.id, id },
      query,
    );

    history.push(url);
  };

  const handleCloseModal = () => {
    const url = buildPath(
      Routes.patientsAlertTriggersEdit,
      { patient_id: patient.id, id },
      query,
    );

    history.push(url);
  };

  const renderModal = () => {
    const modalBackPath = buildPath(
      Routes.patientsAlertTriggersEdit,
      { patient_id: patient.id, id },
      query,
    );

    const onNewRecipientSuccess = () => {
      history.push(modalBackPath);
      getAlertRecipients();
    };

    if (location.pathname.includes(`recipient`)) {
      return (
        <Modal isVisible={true} onCloseModal={handleCloseModal}>
          <div className="practice-form__modal">
            <h3 className="practice-form__modal-header">Add New Recipeint</h3>

            <AlertRecipientsNew
              isModal
              modalBackPath={modalBackPath}
              onSuccess={onNewRecipientSuccess}
            />
          </div>
        </Modal>
      );
    }
  };

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const renderValueField = () => {
    const valueField =
      form.math_option === 'less_than' ? 'range_high' : 'range_low';

    if (form.math_option === 'range') {
      return (
        <div className="columns is-mobile">
          <div className="column is-6-mobile">
            <InputGroup
              isDisabled={!form.math_option}
              label="Min Value"
              onChange={value => updateField('range_low', value)}
              placeholder="Enter Min Value"
              validationMessage={validations.range_low}
              value={form.range_low}
            />
          </div>

          <div className="column is-6-mobile">
            <InputGroup
              isDisabled={!form.math_option}
              label="Max Value"
              onChange={value => updateField('range_high', value)}
              placeholder="Enter Max Value"
              validationMessage={validations.range_high}
              value={form.range_high}
            />
          </div>
        </div>
      );
    }

    return (
      <InputGroup
        isDisabled={!form.math_option}
        label="Value"
        onChange={value => updateField(valueField, value)}
        placeholder="Enter Value"
        validationMessage={validations.range_high}
        value={
          form.math_option === 'less_than' ? form.range_high : form.range_low
        }
      />
    );
  };

  return (
    <>
      <div className="patient-remote-care-new">
        <Container>
          <BackLink to={backPath}>Back to Manage Alerts</BackLink>
        </Container>

        <Constraint size="large">
          <Panel>
            <Section title="Add Alert">
              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Device Category"
                    onChange={value => updateField('device_category_id', value)}
                    options={deviceCategoryOptions}
                    validationMessage={validations.device_category_id}
                    value={form.device_category_id}
                  />
                </div>

                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    isDisabled={!form.device_category_id}
                    label="Device Type"
                    onChange={value => updateField('device_type_id', value)}
                    options={deviceTypeOptions}
                    validationMessage={validations.device_type_id}
                    value={form.device_type_id}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    isDisabled={!form.device_type_id}
                    label="Device Make"
                    onChange={value => updateField('device_make_id', value)}
                    options={deviceMakeOptions}
                    validationMessage={validations.device_make_id}
                    value={form.device_make_id}
                  />
                </div>
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    isDisabled={!form.device_make_id}
                    label="Device Name"
                    onChange={value => updateField('device_name_id', value)}
                    options={deviceNameOptions}
                    validationMessage={validations.device_name_id}
                    value={form.device_name_id}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    isDisabled={!form.device_name_id}
                    label="Device Model"
                    onChange={value => updateField('device_model_id', value)}
                    options={deviceModelOptions}
                    validationMessage={validations.device_model_id}
                    value={form.device_model_id}
                  />
                </div>
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    isDisabled={!form.device_model_id}
                    label="Alert Type"
                    onChange={value => updateField('alert_type_id', value)}
                    options={alertTypeOptions}
                    validationMessage={validations.alert_type_id}
                    value={form.alert_type_id}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    isDisabled={!form.alert_type_id}
                    label="Serial Number"
                    onChange={value => updateField('device_id', value)}
                    options={deviceOptions}
                    validationMessage={validations.device_id}
                    value={form.device_id}
                  />
                </div>

                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Math Option"
                    onChange={value => updateField('math_option', value)}
                    options={mathOptions}
                    validationMessage={validations.math_option}
                    value={form.math_option}
                  />
                </div>
              </div>

              <div className="columns is-mobile">
                <div className="column is-hidden-mobile is-6-tablet is-4-desktop" />

                <div className="column is-6-tablet is-4-desktop">
                  {renderValueField()}
                </div>
              </div>

              <div className="columns">
                <div className="column is-4 patient-remote-care-new__field has-no-padding-bottom">
                  <RadioList
                    options={notifyOptions}
                    value={form.notify_alert}
                    fieldLabel="Notify Recipient"
                    onChange={value => updateField('notify_alert', value)}
                    validationMessage={validations.notify_alert}
                    isFlex
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop patient-remote-care-new__recipient-dropdown">
                  <SelectGroup
                    isDisabled={!shouldNotifyAlert}
                    label="Recipient"
                    onChange={value =>
                      updateRecipientFields('alert_recipient_id', value)
                    }
                    options={recipientOptions}
                    validationMessage={validations.alert_recipient_id}
                    value={form.alert_recipient_id}
                  />
                </div>

                <div className="column is-6-tablet is-4-desktop">
                  <Button
                    style="flat-large add"
                    onClick={handleClickNewRecipient}>
                    New Recipient
                  </Button>
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <div>
                    <CheckboxGroup
                      isDisabled={!shouldNotifyAlert}
                      label="Email Alert"
                      onChange={value => updateField('notify_email', value)}
                      value={form.notify_email}
                    />

                    <div className="patient-remote-care-new__email-field">
                      <InputGroup
                        isDisabled={!shouldNotifyAlert}
                        label="Email"
                        onChange={value => updateField('email', value)}
                        placeholder="Enter Email"
                        validationMessage={validations.email}
                        value={form.email}
                      />
                    </div>
                  </div>
                </div>

                <div className="column is-6-tablet is-4-desktop">
                  <CheckboxGroup
                    isDisabled={!shouldNotifyAlert}
                    label="SMS Alert"
                    onChange={value => updateField('notify_sms', value)}
                    value={form.notify_sms}
                  />

                  <div className="patient-remote-care-new__phone-field">
                    <InputGroup
                      isDisabled={!shouldNotifyAlert}
                      label="SMS Phone Number"
                      onChange={value => updateField('sms_phone_number', value)}
                      placeholder="Enter SMS Phone Number"
                      validationMessage={validations.sms_phone_number}
                      value={form.sms_phone_number}
                    />
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop patient-remote-care-new__field has-no-padding-bottom">
                  <RadioList
                    options={notifyCareManagerOptions}
                    value={form.notify_care_manager}
                    fieldLabel="Notify Care Manager"
                    onChange={value =>
                      updateField('notify_care_manager', value)
                    }
                    validationMessage={validations.notify_care_manager}
                    isFlex
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop patient-remote-care-new__field has-no-padding-top">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatUserResult}
                    guideValue={form.care_manager_id}
                    label="Care Manager"
                    minimumInputLength={1}
                    onChange={value => updateField('care_manager_id', value)}
                    placeholder="Search Care Managers"
                    searchPath="api/v1/care_managers"
                    validationMessage={validations.care_manager_id}
                    initialSelection={initialSelection()}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatUserResult}
                    guideValue={form.user_id}
                    label="Alert Set By"
                    minimumInputLength={1}
                    onChange={value => updateField('user_id', value)}
                    placeholder="Search"
                    searchPath="api/v1/users"
                    initialSelection={{
                      label: fullName(response.attributes.creator),
                      value: response.attributes.creator.id,
                    }}
                    validationMessage={validations.user_id}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6-tablet is-4-desktop">
                  <CheckboxGroup
                    label="Alert Visible To Patient"
                    onChange={value => updateField('visibility', value)}
                    value={form.visibility}
                    checkedValue="everyone"
                    uncheckedValue="internal"
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <ErrorMessage isRightSide message={error} />

          <div className="form__actions">
            <div className="form__actions-left patient-remote-care-edit__remove-button">
              <Button style="delete" onClick={handleDeleteAlertTrigger}>
                Remove Alert
              </Button>
            </div>
            <div className="form__actions-right">
              <Button color="white" onClick={handleBackPath}>
                Cancel
              </Button>

              <Button
                color="secondary"
                isSubmitting={isSubmitting}
                onClick={handleClickSubmit}>
                Save
              </Button>
            </div>
          </div>
        </Constraint>
      </div>

      {renderModal()}
    </>
  );
};

export default AlertsEdit;
