import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { refreshPatientAction } from '@/actions/sagas/patientSaga';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { Cell, Header, Row } from '@/components/table';
import {
  getSelectedPatient,
  getSelectedPatientIncluded,
} from '@/selectors/api';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientHeader from '../../components/PatientHeader';
import { displayConfig } from '../../utils/carePlanUtils';
import { DeviceReading } from '@/types/device_reading';
import DeviceReadingsGraph from './DeviceReadingsGraph';

interface ChartData {
  labels: any[];
  datasets: any[];
}

const INITIAL_CHART_DATA = {
  labels: [],
  datasets: [],
};

const CarePlan = () => {
  const dispatch = useDispatch();

  const patient = useSelector(getSelectedPatient);
  const includes = useSelector(getSelectedPatientIncluded);

  const [bpChartData, setBPChartData] = useState<ChartData>(INITIAL_CHART_DATA);
  const [bgChartData, setBGChartData] = useState<ChartData>(INITIAL_CHART_DATA);
  const [boChartData, setBOChartData] = useState<ChartData>(INITIAL_CHART_DATA);
  const [weightChartData, setWeightChartData] = useState<ChartData>(
    INITIAL_CHART_DATA,
  );
  const [heartRateChartData, setHeartRateChartData] = useState<ChartData>(INITIAL_CHART_DATA);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();


  const formatChartData = (chartType: string, data: ChartData) => {
    if (data.datasets.length < 1) {
      return null;
    }

    return JSON.stringify({
      type: chartType,
      data: data,
    });
  };

  useEffect(() => {
    dispatch(refreshPatientAction(patient.id));
  }, []);

  const renderTable = () => {
    return (
      <Panel>
        <Container>
          <div className="column">
            <div className="column">
              <div className="care-plan__download">
                <a
                  className="button"
                  href={buildPath(
                    Routes.patientsCarePlanDownload,
                    {
                      id: patient.id,
                    },
                    {
                      bp_data: formatChartData('line', bpChartData),
                      bg_data: formatChartData('line', bgChartData),
                      bo_data: formatChartData('line', boChartData),
                      weight_data: formatChartData('line', weightChartData),
                      pulse_data: formatChartData('line', heartRateChartData),
                      starts_date: startDate === undefined ? 'nil' : startDate,
                      ends_date:endDate === undefined ? 'nil' : endDate,
                    },
                  )}
                  target="_blank">
                  Download
                </a>
              </div>
            </div>
          </div>

          <DeviceReadingsGraph
            setBPChartData={setBPChartData}
            setBGChartData={setBGChartData}
            setBOChartData={setBOChartData}
            setWeightChartData={setWeightChartData}
            setHeartRateChartData={setHeartRateChartData}
            setStartDate={setStartDate}
            setEndDate={setEndDate}

          />

          <div className="display-table">
            {displayConfig.map(section => renderSection(section))}
          </div>
        </Container>
      </Panel>
    );
  };

  const renderSection = section => {
    return (
      <div className="display-table__section" key={section.title}>
        <div className="display-table__section-header">
          <h3>{section.title}</h3>
        </div>

        {section.subsections.map(subsection => renderSubsection(subsection))}
      </div>
    );
  };

  const renderSubsection = subsection => {
    const data = includes.filter(include => {
      if (subsection.filter) {
        return (
          include.type === subsection.relationship && subsection.filter(include)
        );
      }

      return include.type === subsection.relationship;
    });

    const widthPercent = (1 / data.length) * 100;

    const headers = subsection.columns.map((column, index) => (
      <Header key={index} style={{ width: `${widthPercent}%` }}>
        {column.name}
      </Header>
    ));

    return (
      <div className="display-table__subsection" key={subsection.title}>
        {subsection.title && <h4>{subsection.title}</h4>}

        <div className="table__wrapper">
          <table className="table">
            <thead>
              <Row>{headers}</Row>
            </thead>
            <tbody>{renderDataRows(subsection, data)}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderDataRows = (subsection, data) => {
    if (!data.length) {
      return (
        <Row>
          <td
            colSpan={subsection.columns.length}
            className="display-table__empty-row">
            {subsection.emptyText}
          </td>
        </Row>
      );
    }

    const widthPercent = (1 / subsection.columns.length) * 100;

    return data.map((row, index) => {
      return (
        <Row key={index}>
          {subsection.columns.map(column => {
            const value = row.attributes[column.field];

            const displayValue = column.formatField
              ? column.formatField(value)
              : value;

            return (
              <Cell
                key={`${column.field}-${index}`}
                style={{ width: `${widthPercent}%` }}>
                {displayValue}
              </Cell>
            );
          })}
        </Row>
      );
    });
  };

  return (
    <>
      <PatientHeader withDetailsExpanded />
      {renderTable()}
    </>
  );
};

export default CarePlan;
