import React from 'react';

import Paginator from '@/components/paginator/Paginator';
import { Voicemail } from '@/types/voicemail';
import UserVoicemail from './UserVoicemail';

interface Props {
  currentPage: number;
  onChangePage: (page: number) => void;
  onClickDelete: (id: string) => void;
  onNoDuration: (id: string) => void;
  totalPages: number;
  voicemails: Voicemail[];
}

const UserVoicemails = (props: Props) => {
  const {
    currentPage,
    onChangePage,
    onClickDelete,
    onNoDuration,
    totalPages,
    voicemails,
  } = props;

  const renderVoicemails = () => {
    if (!voicemails.length) {
      return (
        <div className="voicemail__empty">
          <div className="columns">
            <div className="column">
              <p>No voicemails</p>
            </div>
          </div>
        </div>
      );
    }

    return voicemails.map((voicemail: Voicemail) => {
      return (
        <UserVoicemail
          key={voicemail.id}
          onClickDelete={onClickDelete}
          onNoDuration={onNoDuration}
          voicemail={voicemail}
        />
      );
    });
  };

  const renderPaginator = () => {
    if (totalPages > 1) {
      return (
        <Paginator
          currentPage={currentPage}
          onChangePage={onChangePage}
          totalPages={totalPages}
        />
      );
    }
  };

  return (
    <>
      <h3 className="dashboard-header">Voicemail</h3>
      {renderVoicemails()}
      {renderPaginator()}
    </>
  );
};

export default UserVoicemails;
