import { Option } from '../form';
import { UUID } from '../generic';
import { VirtualAppointment } from './virtual_appointment';

export type QuestionnaireableType = 'Practice' | 'AdminUser';
export type QuestionnaireStatus = 'standard' | 'default';
export type QuestionType = 'free_text' | 'single_answer' | 'multi_answer';
export type VirtualAppointmentQuestionnaireStatus =
  | 'assigned'
  | 'started'
  | 'completed';

export interface Questionnaire {
  id: UUID;
  name: string;
  questionnaireable_id: UUID;
  questionnaireable_type: string;
  status: QuestionnaireStatus;
  questionnaire_questions: QuestionnaireQuestion[];
}

export interface QuestionnaireQuestion {
  id: UUID;
  position: number;
  question_type: QuestionType;
  text: string;
  questionnaire_answers: QuestionnaireAnswer[];
}

export interface QuestionnaireAnswer {
  id: UUID;
  text: string;
}

export interface VirtualAppointmentQuestionnaire {
  id: UUID;
  status: VirtualAppointmentQuestionnaireStatus;
  questionnaire?: Questionnaire;
  virtual_appointment?: VirtualAppointment;
  questionnaire_responses?: QuestionnaireResponse[];
}

export interface QuestionnaireResponse {
  free_text: string;
  questionnaire_question_id: UUID;
  questionnaire_question?: QuestionnaireQuestion;
  questionnaire_answers?: QuestionnaireAnswer[];
  questionnaire_response_answers?: QuestionnaireResponseAnswer[];
}

export interface QuestionnaireResponseAnswer {
  id?: UUID;
  _destroy?: boolean;
  questionnaire_response_id?: UUID;
  questionnaire_answer_id: UUID;
  questionnaire_response?: QuestionnaireResponse[];
  questionnaire_answer?: QuestionnaireAnswer[];
}

export const questionTypeOptions: Option[] = [
  { label: 'Free Text', value: 'free_text' },
  { label: 'Single Answer', value: 'single_answer' },
  { label: 'Multi Answer', value: 'multi_answer' },
];
