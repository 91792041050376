import moment from 'moment';

import { Option, Rules, Validations, Validator } from '@/types/form';
import { titleizeSnakeCase } from '../stringUtils';

export const areFieldsUpdated = (
  source: { [key: string]: any },
  form: { [key: string]: any },
  fieldsToCheck: string[],
) => {
  const selectFields = fieldsToCheck.length ? fieldsToCheck : Object.keys(form);

  return (
    selectFields
      .map(field => source[field] === form[field])
      .find(equal => !equal) !== undefined
  );
};

export const isObjectEmpty = (object: object) => {
  return Object.keys(object).length === 0 && object.constructor === Object;
};

export const isValueEmpty = (field: any) => {
  if (typeof field === 'string' && field === '') {
    return true;
  }

  return typeof field === 'undefined' || field === null;
};

export const replaceAt = (array: any[], index: number, value: any) => {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
};

export const validate = (
  state: { [key: string]: any },
  rules: Rules,
): [boolean, Validations] => {
  const validations: Validations = {};

  Object.keys(state).forEach(field => {
    const value = state[field];
    const rule = rules[field];

    if (!rule || value === undefined) {
      return;
    }

    if (isValueEmpty(value)) {
      return rule.required ? (validations[field] = 'Field is required') : null;
    }

    if (rule.mustMatch) {
      const mustMatchValue = state[rule.mustMatch];

      if (value !== mustMatchValue) {
        return (validations[field] =
          rule.mustMatchMessage || 'Field does not match');
      }
    }

    if (rule.mustOccurBefore) {
      const mustOccurBeforeValue = moment(state[rule.mustOccurBefore]);
      const actualValue = moment(value);

      if (actualValue.isAfter(mustOccurBeforeValue)) {
        return (validations[field] = `Must occur before ${titleizeSnakeCase(
          rule.mustOccurBefore,
        )}`);
      }
    }

    if (rule.maxDurationAfter) {
      const { amount, field: durationField, units } = rule.maxDurationAfter;
      const startTime = moment(state[durationField]);
      const endTime = moment(value);

      if (endTime.diff(startTime, units) > amount) {
        return (validations[
          field
        ] = `Duration must be no greater than ${amount} ${units}`);
      }
    }

    if (rule.type) {
      const result = validateField(value, rule.type, rule.validator);
      if (result !== true) {
        validations[field] = result;
      }
    }
  });

  if (isObjectEmpty(validations)) {
    return [true, {}];
  }

  return [false, validations];
};

export const validateField = (
  value: any,
  type: boolean | string = false,
  validator?: Validator,
) => {
  if (!type) {
    return true;
  }

  switch (type) {
    case 'email':
      return validateEmailAddress(value) || 'Invalid email address';

    case 'password':
      return (
        validatePassword(value) ||
        'Your password must contain 7 characters and at least one number and special symbol'
      );

    case 'phone':
      return validatePhoneNumber(value) || 'Invalid phone number';

    case 'custom':
      if (validator) {
        return (
          validator.method(value) || validator.message || 'Field is invalid'
        );
      }
  }

  return true;
};

export const validateEmailAddress = (emailAddress: string) => {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(emailAddress);
};

export const validatePassword = (password: string) => {
  const pattern = /^(?=.{7,})(?=.*[0-9])(?=.*[`~!@#$%^&*()\-_+=\[\]|{}<>,.?:]).*$/;
  return pattern.test(password);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  const pattern = /^[\d|+]{10,12}$/;
  return pattern.test(phoneNumber);
};

export const withInitialEmptySelect = (
  options: Option[],
  label: string = '',
): Option[] => {
  return [{ label: label || '', value: '' }, ...options];
};
