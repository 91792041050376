import { DeviceReadingLabel, ReadingType } from '@/types/v2/device_reading';

export const bloodGlucoseColumns = [
  {
    header: 'mg/dL',
    isSortable: true,
    isVisibleMobile: true,
    attribute: 'value_1',
    sortField: 'value_1::int',
  },
];

export const bloodPressureColumns = [
  {
    header: 'SYS (mmHg)',
    isSortable: true,
    isVisibleMobile: true,
    attribute: 'value_1',
    sortField: 'value_1::int',
  },
  {
    header: 'DIA (mmHg)',
    isSortable: true,
    isVisibleMobile: true,
    attribute: 'value_2',
    sortField: 'value_2::int',
  },
  {
    header: 'Pulse',
    isSortable: true,
    isVisibleMobile: true,
    attribute: 'value_3',
    sortField: 'value_3::int',
  },
];

export const bloodO2SaturationColumns = [
  {
    header: 'SpO2 (%)',
    isSortable: true,
    isVisibleMobile: true,
    attribute: 'value_1',
    sortField: 'value_1::int',
  },
  {
    header: 'Pulse',
    isSortable: true,
    isVisibleMobile: true,
    attribute: 'value_2',
    sortField: 'value_2::int',
  },
];

export const weightColumns = [
  {
    header: 'Weight',
    isSortable: true,
    isVisibleMobile: true,
    attribute: 'value_1',
    sortField: 'value_1::int',
  }
];

export const labelMap = {
  blood_glucose: '1',
  blood_pressure: '2',
  blood_oxygen: '7',
  weight: '8',
};

export const typeLabelToNumber = (label: DeviceReadingLabel): ReadingType => {
  return labelMap[label] as ReadingType;
};
