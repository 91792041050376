import React, { useCallback, useEffect, useState } from 'react';

import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { SearchQuery } from '@/types/table';
import useSimpleForm from '@/utils/form/useSimpleForm';
import ProvidersTable from './ProvidersTable';
import SearchHeader, { FormState } from './SearchHeader';

const initialSearchQuery: FormState = {
  health_system_id: '',
  hospital_id: '',
  practice_id: '',
  search: '',
  per: '24',
};

const Providers = () => {
  const form = useSimpleForm<typeof initialSearchQuery>(initialSearchQuery);

  const [searchInput, setSearchInput] = useState<string>('');

  const handleChangeSearch = (value: string): void => {
    setSearchInput(value);
  };

  const handleClearFilters = useCallback((): void => {
    form.set(initialSearchQuery);
  }, []);

  const handleStartSearch = useCallback((): void => {
    form.update('search', searchInput);
  }, [searchInput]);

  useEffect((): void => {
    form.update('hospital_id', '');
  }, [form.data.health_system_id]);

  useEffect((): void => {
    form.update('practice_id', '');
  }, [form.data.hospital_id]);

  return (
    <Panel>
      <Container>
        <SearchHeader
          form={form.data}
          onChangeForm={form.update}
          onChangeSearch={handleChangeSearch}
          onClearFilters={handleClearFilters}
          onStartSearch={handleStartSearch}
          searchQuery={searchInput}
        />

        {/* This Typing is needed to force compatibility in the form interface */}
        <ProvidersTable query={(form.data as unknown) as SearchQuery} />
      </Container>
    </Panel>
  );
};

export default Providers;
