import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@/components/button/Button';
import { getSelectedPatient } from '@/selectors/api';
import { buildPath, Routes } from '@/utils/routeUtils';
import { getStepInformation } from '../../helpers/steps';

const ConsentStep5 = () => {
  const history = useHistory();

  const patient = useSelector(getSelectedPatient);

  const stepInformation = getStepInformation('consent', patient);

  const handleClickNo = () => {
    const path = buildPath(Routes.patientsShow, {
      id: patient.id,
    });

    history.push(path);
  };

  const handleClickYes = () => {
    const path = buildPath(Routes.patientsEnrollment, {
      id: patient.id,
      step: stepInformation.nextStep,
    });

    history.push(path);
  };

  return (
    <div className="consent__form">
      <h5>Would you like to begin the Care Management plan now?</h5>
      <p>
        (this takes around 10-15 minutes and should be done together with
        patient)
      </p>

      <div className="consent__buttons">
        <Button color="white" onClick={handleClickNo}>
          No, Finish Later
        </Button>

        <Button color="secondary" onClick={handleClickYes} style="filled">
          Yes, Begin
        </Button>
      </div>
    </div>
  );
};

export default ConsentStep5;
