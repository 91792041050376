import { create, FeatureFlagState } from '@/state/feature_flags';
import {
  FeatureFlagAction,
  FeatureFlagActions,
} from '../actions/feature_flags';

export default function reducer(
  state: FeatureFlagState = create(),
  action: FeatureFlagAction,
): FeatureFlagState {
  switch (action.type) {
    case FeatureFlagActions.SET_FEATURE_FLAGS:
      return {
        ...state,
        flags: action.payload.flags,
      };

    default:
      return state;
  }
}
