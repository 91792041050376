import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@/components/modal/Modal';
import PatientEditConfirmModal from './PatientEditConfirmModal';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';

import ErrorMessage from '@/components/form/ErrorMessage';
import SearchGroup from '@/components/form/SearchGroup';
import SelectGroup from '@/components/form/SelectGroup';
import Panel from '@/components/panel/Panel';

import { Validations } from '@/types/form';
import { Patient } from '@/types/patient';
import { Response } from '@/types/response';
import usePostRequest from '@/utils/api/usePostRequest';
import { parseErrorData } from '@/utils/apiUtils';
import {
  languageOptions,
  patientStatuses,

} from '@/utils/dropdownUtils';
import { validate } from '@/utils/form';
import { buildPath, Routes } from '@/utils/routeUtils';

import { SideEffect } from '@/types/generic';
import { Hub } from '@sentry/browser';

const FORM_STATE = {
  
  care_manager_id: '',
  default_specialist_id:'',  

  language: '',

  status: '',
 
};
interface Props {

  patients_id: string[];
  onCloseModal?: SideEffect;
  patients_name: string[];
  setPatient_id:any;
  practice_id:string[];


  
  }
  

const PatientEditAllModal = (props: Props) => {
  const dispatch = useDispatch();
  //const { patients_id ,setPatient_id} = props;
  const history = useHistory();
  const [isDevicesModalVisible,SetDeviceModalVisible]=useState(false);

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});
  const [CareManagerNames,SetCareManagerNames]=useState([]);
  const [SpecialistNames,SetSpecialistNames]=useState([]);
  const [care_manager_name,Set_care_manager_name]=useState('');
  const [specialist_name,Set_specialist_name]=useState('');

  const backPath = buildPath(Routes.patientEditAll);

 
  const clearSpecialists = () => {
    //updateField('default_specialist_id', '');
    //updateField('specialist_name',getSpecialistName());

  };

  const handleFailure = (response: any) => {
    const errorMessage = parseErrorData(response);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const handleSuccess = (response: Response<Patient>) => {
   

    dispatch(flashSuccess('Patient updated'));
    setForm(FORM_STATE);

    props.onCloseModal();
    
    history.push(backPath);
    
  };



  // const formatBody = () => {
  //   const {
      
  //     ...formFields
  //   } = form;
  //   return {
      
  //       ...formFields,
  //       patients_id,
       
       
    
     
  //   };
  // };

  // const rules = {
  //   care_manager_id: { required: true },
  //   date_of_birth: { required: true },
  //   email: { type: 'email', required: true },
  //   default_specialist_id: { required: true },
  //   first_name: { required: true },
  //   health_system_id: { required: true },
  //   hospital_id: { required: true },
  //   insurance_carrier: { required: true },
  //   insurance_group_id: { required: true },
  //   insurance_member_id: { required: true },
  //   last_name: { required: true },
  //   phone_number: { required: true },
  //   practice_id: { required: true },
  // };

  // const [submitForm, isSubmitting] = usePostRequest({
  //   body: formatBody(),
  //   dispatch,
  //   onFailure: handleFailure,
  //   onSuccess: handleSuccess,
  //   url: 'api/v1/patients/bulk_edit',
  // });

  const formatCareManagerResult = (result: any) => {
    CareManagerNames.push({name: result.attributes.first_name + " " +result.attributes.last_name ,
    id: result.id})
    return {
      label: result.attributes.first_name + " " +result.attributes.last_name ,
      value: result.id,
    };
  };

  // var CMname = (CareManagerNames.filter(e => (e.id == form.care_manager_id))).map(obj=>obj.name); 

  const formatSpecialistResult = (result: any) => {
    SpecialistNames.push({name: result.attributes.name , id: result.id})
    return {
      label: result.attributes.name,
      value: result.id,
    };
  };

  const getCareManagerName=()=>
  {
    var CMname = (CareManagerNames.filter(e => (e.id == form.care_manager_id))).map(obj=>obj.name); 
    Set_care_manager_name(CMname[0])
    //return CMname[0];
  }
  const getSpecialistName=()=>
  {
    var SpecialistName = (SpecialistNames.filter(e => (e.id == form.default_specialist_id))).map(obj=>obj.name); 
    Set_specialist_name(SpecialistName[0]);

    //return SpecialistName[0];
  }

  const handleClickCancel = () => {
    props.onCloseModal();

    history.push(backPath);
  };

  
  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
    if(field=='care_manager_id')
    {
      


    }

    if(field=='default_specialist_id')
    {

     
    }
  };

  const careManagerQueryParams = {
       practice_array: props.practice_id
  };

  const specialistsQueryParams = {
    practice_array: props.practice_id
  };
  const closeEditModal = () => {
    //props.onCloseModal();
    SetDeviceModalVisible(false);




  };
    const Validate=()=>{
  
    }

  const handleClickSubmit = () => {
    if(form.care_manager_id=='' && form.default_specialist_id=='' && form.language=='' && form.status==''){
      dispatch(flashError('Fill at least one field '));
      return;
    }
  getCareManagerName();
   getSpecialistName();
      
  
    SetDeviceModalVisible(true);

  };

  return (
    <div className="patient-new__wrapper">
 

      <Constraint size="large">
        <Container>
          <div className="patient-new__header">
            <h1>
             Patients Edit
            </h1>

            
          </div>
        </Container>
   
   
         
  


        <Panel>


            <div className="columns">
              <div className="column is-4">
                <SearchGroup
                  clearFalseResultOnBlur
                  formatResult={formatCareManagerResult}
                  guideValue={form.care_manager_id}

                  label="Care Manager "
                  minimumInputLength={1}
                  onChange={value => updateField('care_manager_id', value)}
                  placeholder="Search Care Managers"
                  queryParams={careManagerQueryParams}
                  searchPath="api/v1/care_managers"
                  validationMessage={validations.care_manager_id}
                />
              </div>

              <div className="column is-4">
                <SearchGroup
                  clearFalseResultOnBlur
                  formatResult={formatSpecialistResult}
                  guideValue={form.default_specialist_id}
                  label="Enrolling Provider "
                  minimumInputLength={0}
                  onChange={value =>
                    updateField('default_specialist_id', value)
                  }
                  placeholder="Search Providers"
                  queryParams={specialistsQueryParams}
                  searchPath="api/v1/specialists"
                  validationMessage={validations.default_specialist_id} 
                />
              </div>
            </div>
        </Panel>

     

        

        <Panel>
            <div className="columns">
              <div className="column is-4">
                <SelectGroup
                  label="Patient Status"
                  onChange={value => updateField('status', value)}
                  options={patientStatuses}
                  validationMessage={validations.status}
                  value={form.status}
                />
              </div>
            
            </div>

           
        </Panel>
        <Panel>
         
         <div className="columns">
            

           <div className="column is-4">
             <SelectGroup
               label="Language"
               onChange={value => updateField('language', value)}
               options={languageOptions()}
               validationMessage={validations.language}
               value={form.language}
             />
           </div>
         </div>
     </Panel>
     <Modal isVisible={isDevicesModalVisible} onCloseModal={closeEditModal}>

<PatientEditConfirmModal patients_id={props.patients_id}
 patients_name={props.patients_name}
  forms={JSON.stringify(form)}
   care_manager_name={care_manager_name}
    specialist_name={specialist_name}
    onCloseModal={closeEditModal}
    practice_id={props.practice_id}
    ></PatientEditConfirmModal>
</Modal>

        <ErrorMessage isRightSide message={error} />

        <div className="form__actions">
          <div className="form__actions-left" />
          <div className="form__actions-right">
            <Button color="white" onClick={handleClickCancel}>
              Cancel
            </Button>

            <Button
              color="secondary"
              //isSubmitting={isSubmitting}
              onClick={handleClickSubmit}>
              Proceed
            </Button>
          </div>
        </div>
      </Constraint>
    </div>
  );
};

export default PatientEditAllModal;