import React from 'react';
import { Link } from 'react-router-dom';

import Button, { Color } from '@/components/button/Button';
import { AdminUser } from '@/types/v2/admin_user';
import { Practice } from '@/types/v2/practice';
import { AppointmentsTab, FormState } from './Appointments';
import AppointmentsFilterHeader from './AppointmentsFilterHeader';

interface Props {
  currentTab: AppointmentsTab;
  form: FormState;
  onChangeForm: (field: string, value: string) => void;
  onClearFilters: () => void;
  practices: Practice[];
  providers: AdminUser[];
  scheduleAppointmentLink: string;
  setCurrentTab: (tab: AppointmentsTab) => void;
}

const AppointmentsHeader = (props: Props): JSX.Element => {
  const {
    currentTab,
    form,
    onChangeForm,
    onClearFilters,
    practices,
    providers,
    scheduleAppointmentLink,
    setCurrentTab,
  } = props;

  const tabColor = (tab: AppointmentsTab): Color => {
    return currentTab === tab ? 'alt-1' : 'alt-1-outline';
  };

  return (
    <>
      <div className="columns">
        <div className="column is-6">
          <h2 className="appointment-header__header">Appointments</h2>
        </div>

        <div className="columns column is-6 is-12-mobile has-no-horizontal-margin has-no-horizontal-padding is-justified-right-tablet appointment-header__top-container">
          <div className="is-hidden-desktop column is-6 is-12-mobile">
            <AppointmentsFilterHeader
              currentTab={currentTab}
              form={form}
              isMobile
              onChangeForm={onChangeForm}
              onClearFilters={onClearFilters}
              practices={practices}
              providers={providers}
            />
          </div>

          <div className="column is-6">
            <Link
              className="button is-secondary is-full-width"
              to={scheduleAppointmentLink}>
              Schedule Appointment
            </Link>
          </div>
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column is-3-tablet is-6-mobile has-small-horizontal-padding">
          <Button
            color={tabColor('upcoming')}
            isFullWidth
            onClick={() => setCurrentTab('upcoming')}
            style="narrow">
            Upcoming Appointments
          </Button>
        </div>

        <div className="column is-6-mobile is-3-tablet has-small-horizontal-padding">
          <Button
            color={tabColor('past')}
            isFullWidth
            onClick={() => setCurrentTab('past')}
            style="narrow">
            Past Appointments
          </Button>
        </div>
      </div>

      <div className="is-hidden-touch">
        <AppointmentsFilterHeader
          currentTab={currentTab}
          form={form}
          onChangeForm={onChangeForm}
          onClearFilters={onClearFilters}
          practices={practices}
          providers={providers}
        />
      </div>
    </>
  );
};

export default AppointmentsHeader;
