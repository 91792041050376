import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  isFlex?: boolean;
}

const FillHeight = (props: Props) => {
  const { children, isFlex } = props;

  return (
    <div
      className={classNames('fill-height', {
        'is-flex': isFlex,
      })}>
      {children}
    </div>
  );
};

export default FillHeight;
