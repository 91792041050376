import React from 'react';

import CircleButton, {
  CircleButtonColor,
  Props as CircleButtonProps,
} from '@/components/button/CircleButton';
import { IconName } from '@/components/icon/Icon';
import { FlagState } from './VideoCallInterface';

export interface Props {
  flags: FlagState;
  onClickDisconnect: () => void;
  onClickMute: () => void;
  onClickShareScreen: () => void;
}

type AdditionalButtonProps = {
  color: CircleButtonColor;
  icon: IconName;
};

const Controls = (props: Props): JSX.Element => {
  const { flags, onClickDisconnect, onClickMute, onClickShareScreen } = props;

  const buttonProps = (flag: string, icon: string): AdditionalButtonProps => {
    if (flags[flag]) {
      return {
        color: 'white',
        icon: `${icon}-enable` as IconName,
      };
    }

    return {
      color: 'blue',
      icon: `${icon}-disable` as IconName,
    };
  };

  return (
    <div className="video-call-controls">
      <div className="video-call-controls__button">
        <CircleButton
          onClick={onClickMute}
          withShadow
          {...buttonProps('mute', 'mic')}>
          {flags.mute ? 'Unmute' : 'Mute'}
        </CircleButton>
      </div>

      <div className="video-call-controls__button">
        <CircleButton
          color="red"
          icon="phoneWhite"
          onClick={onClickDisconnect}
          withShadow>
          Leave Visit
        </CircleButton>
      </div>

      <div className="video-call-controls__button">
        <CircleButton
          onClick={onClickShareScreen}
          withShadow
          {...buttonProps('share', 'screen')}>
          Share Screen
        </CircleButton>
      </div>
    </div>
  );
};

export default Controls;
