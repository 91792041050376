import moment from 'moment';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { refreshPatientAction } from '@/actions/sagas/patientSaga';
import { getSelectedPatient } from '@/selectors/api';
import { getCurrentUser } from '@/selectors/users';
import { FormComponentConfig } from '@/types/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';
import { useDispatch, useSelector } from 'react-redux';
import PatientForm from '../../components/PatientForm';
import { IdParams } from '@/types/params';

const ScheduledCallsNew = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { id } = useParams<IdParams>();

  const patient = useSelector(getSelectedPatient);

  const [query] = returnTabQuery();

  const backPath = buildPath(
    query.service === 'rpm'
      ? Routes.patientsCareManagementRpm
      : Routes.patientsCareManagement,
    { id },
    query,
  );

  const careManager = useSelector(getCurrentUser);

  const serviceOptions = useMemo(() => {
    return patient.attributes.patient_services.data.map(service => ({
      label: service.attributes.name,
      value: service.attributes.service_id,
    }));
  }, [patient]);

  const initialSelection = {
    label: fullName(careManager.data),
    value: careManager.data.id,
  };

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      alterFormBeforeSubmit: (formState: any) => {
        const { start_date, start_time, ...form } = formState;

        const date = moment(start_date);
        const time = moment(start_time);

        date.set('hour', time.hour());
        date.set('minute', time.minute());

        return {
          ...form,
          start_at: date.toISOString(),
          patient_id: id,
          call_type: 'standard',
          status: 'scheduled',
        };
      },
      path: 'api/v1/calls',
      model: 'call',
      method: 'POST',
      onSuccess: () => {
        dispatch(refreshPatientAction(id));
        history.push(backPath);
      },
    },
    state: {
      user_id: '',
      start_date: '',
      start_time: '',
      service_id: serviceOptions.length === 1 ? serviceOptions[0].value : '',
    },
    rules: {
      user_id: { required: true },
      start_date: { required: true },
      start_time: { required: true },
      service_id: { required: true },
    },
    layout: [
      {
        title: 'Schedule Call',
        fields: [
          [
            {
              label: 'Date',
              field: 'start_date',
              type: 'date',
              size: 4,
            },
            {
              label: 'Time',
              field: 'start_time',
              type: 'time',
              size: 4,
            },
          ],
          [
            {
              label: 'Care Manager',
              field: 'user_id',
              placeholder: 'Search for care manager',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/care_managers',
              size: 4,
              initialSelection,
              formatTypeaheadResult: (result: any) => {
                return {
                  label: fullName(result),
                  value: result.id,
                };
              },
            },
            {
              label: 'Service Type',
              field: 'service_id',
              type: 'select',
              size: 4,
              placeholder: 'Select service',
              options: serviceOptions,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Call Schedule"
      config={config}
    />
  );
};

export default ScheduledCallsNew;
