import { createSelector } from 'reselect';

import { FeatureFlagState } from '@/state/feature_flags';
import { AppState } from '@/types/app';

export const selectFeatureFlagState = (state: AppState): FeatureFlagState =>
  state.featureFlags;

export const selectFeatureFlags = createSelector(
  selectFeatureFlagState,
  state => state.flags,
);
