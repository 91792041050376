import React from 'react';

import { SimpleBlock } from '@/components/dashboard';
import { CareManagerResponse } from '@/state/rpm';
import { buildPath, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';

type Style = 'high_alert' | 'no_readings';

interface Props {
  careManager: CareManagerResponse;
  footer: string;
  style: Style;
}

const CareManagerPatientBlock = ({
  careManager,
  footer,
  style,
}: Props): JSX.Element => {
  const careManagerUrl = buildPath(Routes.dashboardCareManager, {
    care_manager_id: careManager.id,
  });

  const text = (() => {
    const textNumber =
      careManager.patients === 1
        ? '1 Patient'
        : `${careManager.patients} Patients`;

    if (style === 'high_alert') {
      return `${textNumber} with High Alerts`;
    }

    return `${textNumber} with 0 Readings`;
  })();

  return (
    <SimpleBlock
      footer={footer}
      linkTo={careManagerUrl}
      text={text}
      title={fullName(careManager)}
    />
  );
};

export default CareManagerPatientBlock;
