import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import ErrorMessage from '@/components/form/ErrorMessage';
import InputGroup from '@/components/form/InputGroup';
import SearchGroup from '@/components/form/SearchGroup';
import Section from '@/components/form/Section';
import Panel from '@/components/panel/Panel';
import PracticeAddressPanel from '@/contexts/provider/practice/components/PracticeAddressPanel';
import { Validations } from '@/types/form';
import { Practice } from '@/types/v2/practice';
import useLoadingState from '@/utils/api/useLoadingState';
import usePutRequest from '@/utils/api/usePutRequest';
import { parseErrorData } from '@/utils/apiUtils';
import { updateArrayIndex } from '@/utils/arrayUtils';
import { validate } from '@/utils/form';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import { usePrevious } from '@/utils/stateUtils';
import { UUID } from '@/types/generic';

const blankAddress = {
  line_1: '',
  line_2: '',
  city: '',
  state: '',
  zip_code: '',
};

const FORM_STATE = {
  name: '',
  health_system_id: '',
  hospital_id: '',
  addresses: [blankAddress],
};

interface Params {
  practice_id: UUID;
}

const PracticesEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const loader = useLoadingState('specialist');
  const notifier = useNotifier();

  const { practice_id } = useParams<Params>();

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [practice, setPractice] = useState<Practice>(null);
  const [validations, setValidations] = useState<Validations>({});
  const [addressValidations, setAddressValidations] = useState<Validations[]>([
    {},
  ]);

  const previousHealthSystemId = usePrevious(form.health_system_id);

  const backPath = buildPath(Routes.provider.practice.show.providers);

  const getPractice = async () => {
    loader.startLoading('practice');

    try {
      const url = buildPath(
        Routes.api2.practice,
        {
          id: practice_id,
        },
        null,
        ['addresses', 'health_system', 'hospital'],
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<Practice>(response.data, {
        generations: 2,
      }).build();

      setPractice(resource);
      setForm(formatForm(resource));
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('practice');
  };

  const formatForm = res => {
    return {
      addresses: res.addresses,
      health_system_id: res.health_system.id,
      hospital_id: res.hospital.id,
      name: res.name,
    };
  };

  useEffect(() => {
    if (practice_id) {
      getPractice();
    }
  }, []);

  useEffect(() => {
    if (
      previousHealthSystemId &&
      previousHealthSystemId !== form.health_system_id
    ) {
      updateField('hospital_id', '');
    }
  }, [form.health_system_id]);

  const handleFailure = (res: any) => {
    const errorMessage = parseErrorData(res);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const handleSuccess = () => {
    dispatch(flashSuccess('Practice edited'));

    history.push(backPath);
  };

  const formatBody = () => {
    const { addresses, ...formFields } = form;

    return {
      practice: {
        ...formFields,
        addresses_attributes: addresses,
      },
    };
  };

  const rules = {
    name: { required: true },
    health_system_id: { required: true },
    hospital_id: { required: true },
  };

  const addressRules = {
    line_1: { required: true },
    city: { required: true },
    state: { required: true },
    zip_code: { required: true },
  };

  const [submitForm, isSubmitting] = usePutRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: buildPath(Routes.api2.practice, { id: practice_id }, null, [
      'addresses',
      'health_system',
      'hospital',
    ]),
  });

  const formatSearchResult = (result: any) => ({
    label: result.attributes.name,
    value: result.id,
  });

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);
    const addressValidationMessages = form.addresses.map(address =>
      validate(address, addressRules),
    );

    const areAddressesInvalid = addressValidationMessages.find(
      validation => validation[0] === false,
    );

    if (isValid && !areAddressesInvalid) {
      setValidations({});
      submitForm();
    } else {
      setValidations(validationMessages);
      setAddressValidations(
        addressValidationMessages.map(validation => validation[1]),
      );
    }
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const updateAddressField = (index: number, field: string, value: any) => {
    const formAddresses = [...form.addresses];
    const address = formAddresses[index];

    const updatedAddress = {
      ...address,
      [field]: value,
    };

    const updatedForm = {
      ...form,
      addresses: updateArrayIndex(form.addresses, index, updatedAddress),
    };

    setForm(updatedForm);
  };

  if (loader.isLoading('practice') || !practice) {
    return <loader.activity />;
  }

  return (
    <>
      <div className="practice-form__wrapper">
        <Container>
          <BackLink to={backPath}>Cancel</BackLink>
        </Container>

        <Constraint size="large">
          <Panel withNarrowMargin>
            <Section title="Practice Details">
              <div className="columns is-mobile practice-form__details-wrapper">
                <div className="column is-12-mobile is-6-tablet is-4-desktop">
                  <InputGroup
                    label="Practice Name"
                    onChange={value => updateField('name', value)}
                    placeholder="Enter Practice Name"
                    validationMessage={validations.name}
                    value={form.name}
                  />
                </div>

                <div className="column is-12-mobile is-6-tablet is-4-desktop">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatSearchResult}
                    initialSelection={{
                      label: practice.health_system.name,
                      value: practice.health_system.id,
                    }}
                    guideValue={form.health_system_id}
                    label="Health System"
                    minimumInputLength={1}
                    onChange={value => updateField('health_system_id', value)}
                    placeholder="Search Health Systems"
                    searchPath="api/v1/health_systems"
                    validationMessage={validations.health_system_id}
                  />
                </div>

                <div className="column is-12-mobile is-6-tablet is-4-desktop">
                  <SearchGroup
                    clearFalseResultOnBlur
                    isDisabled={!form.health_system_id}
                    initialSelection={{
                      label: practice.hospital.name,
                      value: practice.hospital.id,
                    }}
                    guideValue={form.hospital_id}
                    formatResult={formatSearchResult}
                    label="Hospital"
                    minimumInputLength={1}
                    onChange={value => updateField('hospital_id', value)}
                    placeholder="Search Hospitals"
                    queryParams={{
                      health_system_id: form.health_system_id,
                    }}
                    searchPath="api/v1/hospitals"
                    validationMessage={validations.hospital_id}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <PracticeAddressPanel
            addresses={form.addresses}
            onUpdateAddressField={updateAddressField}
            addressValidations={addressValidations}
          />

          <ErrorMessage isRightSide message={error} />

          <div className="form__actions">
            <div className="form__actions-right">
              <Button color="white" onClick={handleClickCancel}>
                Cancel
              </Button>

              <Button color="secondary" onClick={handleClickSubmit}>
                Save
              </Button>
            </div>
          </div>
        </Constraint>
      </div>
    </>
  );
};

export default PracticesEdit;
