import classNames from 'classnames';
import React, { ReactNode } from 'react';

type Height = 'small' | 'medium' | 'large' | 'full';
type Size = 'regular' | 'medium' | 'extended' | 'large';

interface Props {
  children: ReactNode;
  height?: Height;
  shouldFillHeight?: boolean;
  size?: Size;
}

const Constraint = (props: Props) => {
  const { children, height, shouldFillHeight, size } = props;

  return (
    <div
      className={classNames('constraint', {
        'is-regular': size === 'regular',
        'is-large': size === 'large',
        'is-extended': size === 'extended',
        'is-medium': size === 'medium',
        'is-height-small': height === 'small',
        'is-height-medium': height === 'medium',
        'is-height-full': height === 'full',
      })}>
      {children}
    </div>
  );
};

export default Constraint;
