import React from 'react';

import { SideEffect } from '@/types/generic';
import Key from './Key';

interface Props {
  onAddNumber: SideEffect;
}

const Keypad = (props: Props) => {
  const { onAddNumber } = props;

  const handleClickKey = (key: string) => {
    onAddNumber(key);
  };

  const renderKeys = (keys: string[]) => {
    return keys.map(key => (
      <Key key={key} onClick={() => handleClickKey(key)} text={key} />
    ));
  };

  return (
    <div className="keypad">
      <div className="keypad__row">{renderKeys(['1', '2', '3'])}</div>

      <div className="keypad__row">{renderKeys(['4', '5', '6'])}</div>

      <div className="keypad__row">{renderKeys(['7', '8', '9'])}</div>

      <div className="keypad__row">{renderKeys(['*', '0', '#'])}</div>
    </div>
  );
};

export default Keypad;
