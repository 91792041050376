import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BhiChecklist from '@/components/form/BhiChecklist';
import EnrollmentHeader from '@/contexts/enrollment/components/EnrollmentHeader';
import { getStepInformation } from '@/contexts/enrollment/helpers/steps';
import { getSelectedPatient } from '@/selectors/api';
import { selectCurrentCarePlan } from '@/selectors/bhi';
import { getBhiConditionOptions, getBhiServiceId } from '@/selectors/options';
import { Condition } from '@/types/condition';
import { UUID } from '@/types/generic';
import { Response } from '@/types/response';
import { formatIsoDateTime } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useHistory } from 'react-router-dom';

const BhiConditions = () => {
  const history = useHistory();

  const bhiServiceId = useSelector(getBhiServiceId);
  const carePlan = useSelector(selectCurrentCarePlan);
  const conditionOptions = useSelector(getBhiConditionOptions);
  const patient = useSelector(getSelectedPatient);

  const [originalOptions, setOriginalOptions] = useState<UUID[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<UUID[]>([]);

  useEffect(() => {
    if (carePlan) {
      const ids = carePlan.data.attributes.conditions.map(
        (c: Condition) => c.id,
      );
      setOriginalOptions(ids);
      setSelectedOptions(ids);
    } else {
      setOriginalOptions([]);
      setSelectedOptions([]);
    }
  }, [carePlan]);

  const stepInformation = getStepInformation('bhi_conditions', patient);

  const nextScreen = buildPath(Routes.patientsEnrollment, {
    id: patient.id,
    step: stepInformation.nextStep,
  });

  const formatBody = () => {
    if (carePlan) {
      return {
        care_plan: {
          condition_ids: selectedOptions,
          conditions_completed_at: formatIsoDateTime(),
        },
      };
    } else {
      return {
        care_plan: {
          condition_ids: selectedOptions,
          patient_id: patient.id,
          service_id: bhiServiceId,
          conditions_completed_at: formatIsoDateTime(),
        },
      };
    }
  };

  const handleSuccess = (res: Response) => {
    history.push(nextScreen);
  };

  return (
    <div className="enrollment-wrapper">
      <EnrollmentHeader
        description="Which chronic conditions would the patient like to better manage?"
        linkTo={nextScreen}
        linkText="Symptoms"
        title="Conditions"
      />

      <BhiChecklist
        carePlan={carePlan}
        formatBody={formatBody}
        isSubmitDisabled={!selectedOptions.length}
        onChange={newOptions => setSelectedOptions(newOptions)}
        onSuccess={handleSuccess}
        options={conditionOptions}
        originalSelectedIds={originalOptions}
        selectedIds={selectedOptions}
        shouldHideGroupName
      />
    </div>
  );
};

export default BhiConditions;
