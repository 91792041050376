import { useLocation, useRouteMatch } from 'react-router-dom';

import { Patient } from '@/types/patient';
import { Routes } from '@/utils/routeUtils';

export const careManagementService = (): string => {
  const match = useRouteMatch(Routes.patientsCareManagementRpm);

  return match ? 'rpm' : 'ccm';
};

export const isCcmService = (): boolean => {
  return careManagementService() === 'ccm';
};

export const isRpmService = (): boolean => {
  return careManagementService() === 'rpm';
};

export const isEnrolled = (patient: Patient): boolean => {
  return !!patient?.attributes.enrollment_date;
};

export const isEnrollmentPath = () => {
  const location = useLocation();

  return location.pathname.includes('enrollment');
};

export const getMedicalHistoryType = () => {
  const location = useLocation();
  const types = ['medical', 'surgical', 'family', 'social'];

  for (const type of types) {
    if (location.pathname.includes(`${type}_history`)) {
      return type;
    }
  }

  return null;
};

export const pathSelect = (
  regularPath: string,
  enrollmentPath: string,
): string => {
  return isEnrollmentPath() ? enrollmentPath : regularPath;
};
