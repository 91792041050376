import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Video, { Participant, Room } from 'twilio-video';

import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import {
  getCurrentUser,
  selectCurrentUserRole,
  selectIsCurrentUserProvider,
} from '@/selectors/users';
import { selectVideoCallsToken } from '@/selectors/video_calls';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import { isBrowserSafari } from '@/utils/browserUtils';
import useSimpleForm from '@/utils/form/useSimpleForm';
import useNotifier from '@/utils/messages/useNotifier';
import { buildPath, Routes } from '@/utils/routeUtils';
import { usePrevious } from '@/utils/stateUtils';
import ChatInterface from '../chat/ChatInterface';
import Controls from './Controls';
import DrawerContent from './DrawerContent';
import NotesDrawer from './NotesDrawer';
import QuestionnaireDrawer from './QuestionnaireDrawer';
import Sidebar from './Sidebar';
import VideoParticipant from './VideoParticipant';

interface Props {
  appointment: VirtualAppointment;
  onClickDisconnect: () => void;
}

const initialFlags = {
  chat: false,
  mute: false,
  notes: false,
  patient: false,
  questionnaire: false,
  share: false,
};

export type FlagState = typeof initialFlags;

const VideoCallInterface = (props: Props): JSX.Element => {
  const { appointment, onClickDisconnect } = props;

  const noteTimerRef = useRef<number>(null);
  const trackRef = useRef<any>(null);

  const isSpecialist = useSelector(selectIsCurrentUserProvider);
  const token = useSelector(selectVideoCallsToken);
  const user = useSelector(getCurrentUser);
  const userRole = useSelector(selectCurrentUserRole);

  const [notes, setNotes] = useState<string>(appointment?.specialist_notes);
  const [room, setRoom] = useState<Room>(null);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const state = useSimpleForm<FlagState>(initialFlags);

  const prevNotes = usePrevious(notes);
  const notifier = useNotifier();

  const isSafari: boolean = isBrowserSafari();

  const joinVideoCall = async () => {
    // Create callbacks that will modify state when participants are added or joined
    const participantConnected = (participant: Participant): void => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant: Participant): void => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant),
      );
    };

    Video.connect(token).then((connectedRoom: Room) => {
      setRoom(connectedRoom);

      connectedRoom.on('participantConnected', participantConnected);
      connectedRoom.on('participantDisconnected', participantDisconnected);

      // Add participants already connected to room using same callback for new connections
      connectedRoom.participants.forEach(participantConnected);
    });
  };

  const toggleFlag = (flag: string): void => {
    state.set({
      ...state.data,
      [flag]: !state.data[flag],
    });
  };

  const saveNotes = async (): Promise<void> => {
    try {
      const url = buildPath(Routes.api2.virtualAppointment, {
        id: appointment.id,
      });
      const body = {
        virtual_appointment: {
          specialist_notes: notes,
        },
      };

      await Api.utility.patch(url, body);

      state.success('Notes saved');
    } catch (err) {
      state.error(err);
    }
  };

  const startNoteSaveTimeout = () => {
    if (noteTimerRef.current) {
      clearTimeout(noteTimerRef.current);
    }

    if (prevNotes !== undefined && notes && prevNotes !== notes) {
      noteTimerRef.current = window.setTimeout(() => {
        saveNotes();
      }, 3000);
    }
  };

  const handleShareScreenPress = () => {
    // NOTE: Safari requires calls to .getDisplayMedia()
    // to happen from user gesture handlers (eg button presses)
    // so it was failing just within our useEffect below. We call it
    // here for Safari users and don't call it for Safari users under
    // our useEffect
    if (isSafari) {
      if (!state.data.share && !trackRef.current) {
        (navigator.mediaDevices as any)
          .getDisplayMedia({
            video: true,
          })
          .then(stream => {
            trackRef.current = new Video.LocalVideoTrack(stream.getTracks()[0]);
            room.localParticipant.publishTrack(trackRef.current);
          })
          .catch(err => {
            notifier.error('Unable to share screen');
          });
      } else if (state.data.share && trackRef.current) {
        room.localParticipant.unpublishTrack(trackRef.current);
        trackRef.current.stop();
        trackRef.current = null;
      }
    }
    toggleFlag('share');
  };

  useEffect(() => {
    if (token) {
      joinVideoCall();

      // When unmounting component, stop all participant tracks and disconnect from the room if the user is currently connected
      return () => {
        setRoom(currentRoom => {
          if (
            currentRoom &&
            currentRoom.localParticipant.state === 'connected'
          ) {
            currentRoom.localParticipant.tracks.forEach(trackPublication => {
              // For some reason the Twilio typings are incorrect here
              // @ts-ignore
              trackPublication.track.stop();
            });

            currentRoom.disconnect();
            return null;
          } else {
            return currentRoom;
          }
        });
      };
    }
  }, [token]);

  useEffect(() => {
    startNoteSaveTimeout();
  }, [notes]);

  useEffect(() => {
    if (room) {
      if (state.data.mute) {
        room.localParticipant.audioTracks.forEach(publication => {
          publication.track.disable();
        });
      } else {
        room.localParticipant.audioTracks.forEach(publication => {
          publication.track.enable();
        });
      }
    }
  }, [state.data.mute, room]);

  // Handle screen sharing
  useEffect(() => {
    if (room) {
      // If screen is set to share but a track has not yet been created
      if (state.data.share && !trackRef.current) {
        if (!isSafari) {
          // mediaDevices does not have proper type declarations so it needs to be set to any
          (navigator.mediaDevices as any)
            .getDisplayMedia()
            .then(stream => {
              trackRef.current = new Video.LocalVideoTrack(
                stream.getTracks()[0],
              );
              room.localParticipant.publishTrack(trackRef.current);
            })
            .catch(err => {
              notifier.error('Unable to share screen');
            });
        }
        // Otherwise if share is set to false and a track currently exists.
        // This prevents issues when first loading the room and attempting to unpublish undefined
      } else if (!state.data.share && trackRef.current) {
        room.localParticipant.unpublishTrack(trackRef.current);
        trackRef.current.stop();
        trackRef.current = null;
      }
    }
  }, [state.data.share, room]);

  const renderParticipants = participants.map(participant => (
    <div className="video-call__other" key={participant.sid}>
      <VideoParticipant participant={participant} role="other" />
    </div>
  ));

  const renderSidebar = (() => {
    if (isSpecialist) {
      return (
        <Sidebar
          flags={state.data}
          onClickChat={() => toggleFlag('chat')}
          onClickQuestionnaire={() => toggleFlag('questionnaire')}
          onClickNotes={() => toggleFlag('notes')}
          onClickPatient={() => {
            window.open(
              `/provider/patients/${appointment?.patient?.id}/virtual_appointments`,
              '_blank',
            );
          }}
        />
      );
    }

    return (
      <Sidebar
        flags={state.data}
        onClickChat={() => toggleFlag('chat')}
        onClickQuestionnaire={() => toggleFlag('questionnaire')}
      />
    );
  })();

  if (room) {
    return (
      <div className="video-call-wrapper">
        <div className="video-call">
          <VideoParticipant
            participant={room.localParticipant}
            role="self"
            shouldForceFullscreen={!participants.length}
          />

          {!!renderParticipants.length && (
            <div className="video-call__others">{renderParticipants}</div>
          )}

          <Controls
            flags={state.data}
            onClickDisconnect={onClickDisconnect}
            onClickMute={() => toggleFlag('mute')}
            onClickShareScreen={handleShareScreenPress}
          />

          {renderSidebar}
        </div>

        <DrawerContent isVisible={state.data.questionnaire}>
          <QuestionnaireDrawer
            appointment={appointment}
            onClickClose={() => toggleFlag('questionnaire')}
          />
        </DrawerContent>

        <DrawerContent isVisible={state.data.chat}>
          <ChatInterface
            appointmentId={appointment.id}
            messages={appointment.messages}
            onClickClose={() => toggleFlag('chat')}
            authorId={user.data.id}
            authorType={userRole}
          />
        </DrawerContent>

        {isSpecialist && (
          <DrawerContent isHalf isVisible={state.data.notes}>
            <NotesDrawer
              onClickClose={() => toggleFlag('notes')}
              onChange={value => setNotes(value)}
              value={notes}
            />
          </DrawerContent>
        )}
      </div>
    );
  }

  return <ActivityIndicator />;
};

export default VideoCallInterface;
