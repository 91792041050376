import { Patient } from '@/types/patient';
import { titleizeSnakeCase } from '@/utils/stringUtils';

export const StepListCCM = [
  'conditions',
  'symptoms',
  'goals',
  'barriers',
  'outcomes',
  'support',
  'allergies',
  'medications',
  'tracking',
  'medical_history',
  'surgical_history',
  'family_history',
  'social_history',
  'contacts',
];

export const StepListRPM = [
  'conditions',
  'symptoms',
  'goals',
  'barriers',
  'outcomes',
  'tracking',
];

export const StepListBHI = [
  'bhi_conditions',
  'bhi_goals',
  'bhi_barriers',
  'bhi_symptoms',
  'bhi_support',
  'bhi_outcomes',
  'bhi_tracking',
];

export const getPatientEnrollmentSteps = (
  patient: Patient,
  isComplete?: boolean,
) => {
  let baseList = isComplete ? [] : ['consent'];

  if (patient.attributes.is_enrolled_ccm && !isComplete) {
    baseList = baseList.concat(StepListCCM);
  } else if (patient.attributes.is_enrolled_rpm && !isComplete) {
    baseList = baseList.concat(StepListRPM);
  }

  if (patient.attributes.is_enrolled_bhi) {
    baseList = baseList.concat(StepListBHI);
  }

  return baseList;
};

const getStepTitle = (step: string) => {
  return step === 'contacts' ? 'Family Contacts' : titleizeSnakeCase(step);
};

export const getStepInformation = (currentStep: string, patient: Patient) => {
  const steps = getPatientEnrollmentSteps(patient);

  const stepNumber = steps.indexOf(currentStep);
  const nextStep = steps[stepNumber + 1];

  return {
    currentStep,
    currentStepTitle: getStepTitle(currentStep),
    nextStep,
    nextStepNumber: nextStep ? stepNumber + 1 : null,
    nextStepTitle: nextStep ? getStepTitle(nextStep) : null,
  };
};
