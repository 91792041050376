import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Routes } from '@/utils/routeUtils';
import ProfileEdit from './ProfileEdit';
import ProfilePatientEdit from './ProfilePatientEdit';
import ProfileShow from './ProfileShow';
import ProfileSpecialistEdit from './ProfileSpecialistEdit';

const Profile = () => {
  return (
    <Switch>
      <Route exact path={Routes.profile.show} component={ProfileShow} />
      <Route exact path={Routes.profile.edit} component={ProfileEdit} />
      <Route
        exact
        path={Routes.profile.patient.edit}
        component={ProfilePatientEdit}
      />
      <Route
        exact
        path={Routes.profile.provider.edit}
        component={ProfileSpecialistEdit}
      />
    </Switch>
  );
};

export default Profile;
