import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GetPatientPracticeDeviceModels,
  getPatientPracticeDeviceModelsFailure,
  GetPatientPracticeDeviceModelsFailure,
  getPatientPracticeDeviceModelsSuccess,
  PatientsActions,
} from '@/actions/actions/patients';
import Api from '@/api/Api';
import { DeviceModel } from '@/types/device_model';
import { parseErrorData } from '@/utils/apiUtils';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import { flashError } from '../messageSaga';

export function* getPatientPracticeDevicesSaga(
  action: GetPatientPracticeDeviceModels,
) {
  try {
    const { practiceId } = action.payload;

    const url = buildPath(
      Routes.api.deviceModels,
      {},
      { per: null, practice_id: practiceId },
    );

    const response = yield call(Api.utility.get, url);

    const deviceModels = new Modeler<DeviceModel[]>(response.data).build();

    yield put(getPatientPracticeDeviceModelsSuccess(deviceModels, practiceId));
  } catch (err) {
    const message = parseErrorData(err);

    yield put(getPatientPracticeDeviceModelsFailure(message));
  }
}

export function* getPatientPracticeDeviceModelsFailureSaga(
  action: GetPatientPracticeDeviceModelsFailure,
) {
  yield put(flashError(action.payload.message));
}

export function* watchGetPatientPracticeDevices() {
  yield takeLatest(
    PatientsActions.GET_PRACTICE_DEVICE_MODELS,
    getPatientPracticeDevicesSaga,
  );
  yield takeLatest(
    PatientsActions.GET_PRACTICE_DEVICE_MODELS_FAILURE,
    getPatientPracticeDeviceModelsFailureSaga,
  );
}
