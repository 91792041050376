import { EmptyBlock } from '@/components/dashboard';
import { DeviceReading } from '@/types/v2/device_reading';
import React from 'react';
import ReadingBlock from '../components/ReadingBlock';

interface Props {
  readings: DeviceReading[];
}

const LatestReadings = ({ readings }: Props): JSX.Element => {
  const renderReadings = (() => {
    if (readings.length) {
      return readings.map(reading => (
        <div className="column is-4" key={reading.id}>
          <ReadingBlock deviceReading={reading} style="compact" />
        </div>
      ));
    }

    return (
      <div className="column">
        <EmptyBlock style="compact">No readings</EmptyBlock>
      </div>
    );
  })();

  return <div className="columns">{renderReadings}</div>;
};

export default LatestReadings;
