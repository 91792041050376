import { EmptyBlock } from '@/components/dashboard';
import { VirtualAppointmentQuestionnaire } from '@/types/v2/questionnaire';
import React from 'react';
import QuestionnaireResponse from './QuestionnaireResponse';

interface Props {
  onClickEdit?: () => void;
  questionnaires: VirtualAppointmentQuestionnaire[];
}

const QuestionnairesList = ({
  onClickEdit,
  questionnaires,
}: Props): JSX.Element => {
  if (!questionnaires.length) {
    return <EmptyBlock style="compact">No questionnaires</EmptyBlock>;
  }

  return (
    <>
      {questionnaires.map(questionnaire => (
        <QuestionnaireResponse
          key={questionnaire.id}
          onClickEdit={onClickEdit}
          questionnaire={questionnaire}
        />
      ))}
    </>
  );
};

export default QuestionnairesList;
