import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { generateToken } from '@/actions/actions/video_calls';
import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import AppointmentDetails from '@/modules/appointments/AppointmentDetails';
import AppointmentPatient from '@/modules/appointments/AppointmentPatient';
import AppointmentTime from '@/modules/appointments/AppointmentTime';
import QuestionnaireResponse from '@/modules/questionniares/display/QuestionnaireResponse';
import QuestionnairesList from '@/modules/questionniares/display/QuestionnairesList';
import VideoCallInterface from '@/modules/video_call/VideoCallInterface';
import {
  selectIsFetchingToken,
  selectIsVideoCallActive,
} from '@/selectors/video_calls';
import { UUID } from '@/types/generic';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';

interface Params {
  appointment_id: UUID;
}

const Appointment = (): JSX.Element => {
  const backPath = buildPath(Routes.provider.calendar.root);

  const dispatch = useDispatch();
  const history = useHistory();
  const loader = useLoadingState('appointment');
  const notifier = useNotifier();

  const isFetchingToken = useSelector(selectIsFetchingToken);
  const isCallActive = useSelector(selectIsVideoCallActive);

  const [appointment, setAppointment] = useState<VirtualAppointment>(null);

  const { appointment_id: id } = useParams<Params>();

  const handleJoinAppointment = () => {
    dispatch(generateToken(appointment));
  };

  const handleDisconnect = () => {
    const url = buildPath(Routes.provider.calendar.root);
    history.push(url);
    window.location.reload();
  };

  const getAppointment = async (): Promise<void> => {
    loader.startLoading('appointment');

    try {
      const url = buildPath(Routes.api2.virtualAppointment, { id }, null, [
        'messages',
        'patient',
        'patient.care_manager',
        'virtual_appointment_questionnaires',
        'virtual_appointment_questionnaires.questionnaire',
        'virtual_appointment_questionnaires.questionnaire_responses',
        'virtual_appointment_questionnaires.questionnaire_responses.questionnaire_answers',
        'virtual_appointment_questionnaires.questionnaire_responses.questionnaire_question',
      ]);
      const response = await Api.utility.get(url);
      const resource = new Modeler<VirtualAppointment>(response.data, {
        generations: 3,
      }).build();

      setAppointment(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('appointment');
  };

  useEffect(() => {
    getAppointment();
  }, []);

  useEffect(() => {
    // If the provider leaves and then returns to the waiting room automatically start the appointment
    if (appointment && appointment.appointment_status === 'started') {
      dispatch(generateToken(appointment));
    }
  }, [appointment]);

  const renderAppointmenTime = (() => {
    if (loader.doneLoading('appointment') && appointment) {
      return (
        <Constraint height="small" size="medium">
          <AppointmentTime
            appointment={appointment}
            isLoading={isFetchingToken}
            onClickJoin={handleJoinAppointment}
          />
        </Constraint>
      );
    }

    return (
      <Constraint height="small" size="medium">
        <ActivityIndicator />
      </Constraint>
    );
  })();

  const renderPatientDetails = (() => {
    if (loader.doneLoading('appointment') && appointment) {
      return (
        <Constraint height="medium" size="medium">
          <AppointmentDetails
            appointment={appointment}
            name={fullName(appointment.patient)}
          />

          <AppointmentPatient appointment={appointment} />

          <QuestionnairesList
            questionnaires={appointment.virtual_appointment_questionnaires}
          />
        </Constraint>
      );
    }

    return (
      <Constraint height="medium" size="medium">
        <ActivityIndicator />
      </Constraint>
    );
  })();

  const renderPageContent = (() => {
    if (isCallActive) {
      return (
        <VideoCallInterface
          appointment={appointment}
          onClickDisconnect={handleDisconnect}
        />
      );
    }

    return (
      <Container>
        <Panel>{renderAppointmenTime}</Panel>
        <Panel>{renderPatientDetails}</Panel>
      </Container>
    );
  })();

  return (
    <>
      <Container>
        <BackLink to={backPath}>Exit</BackLink>
      </Container>

      {renderPageContent}
    </>
  );
};

export default Appointment;
