import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setCurrentUser } from '@/actions/reducers/users';
import { flashSuccess } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Container from '@/components/container/Container';
import Form from '@/components/form/Form';
import Page from '@/components/page/Page';
import { getCurrentUser } from '@/selectors/users';
import { FormComponentConfig } from '@/types/form';
import { User } from '@/types/user';
import { Patient } from '@/types/v2/patient';
import useLoadingState from '@/utils/api/useLoadingState';
import { stateOptions } from '@/utils/dropdownUtils';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';

const ProfilePatientEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const user = useSelector(getCurrentUser);

  const loader = useLoadingState('patient');
  const notifier = useNotifier();

  const [patient, setPatient] = useState<Patient>(null);
  const [form, setForm] = useState(null);

  const getPatient = async () => {
    loader.startLoading('patient');

    try {
      const url = buildPath(
        Routes.api2.patient,
        {
          id: user.data.id,
        },
        null,
        ['address'],
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<Patient>(response.data).build();

      setPatient(resource);
      setForm(formatForm(resource));
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('patient');
  };

  useEffect(() => {
    if (user) {
      getPatient();
    }
  }, []);

  const path = buildPath(Routes.api2.patient, { id: user.data.id }, null, [
    'address',
  ]);

  const alterForm = (formState: any) => {
    const {
      line_1,
      line_2,
      city,
      state,
      zip_code,
      id,
      ...formFields
    } = formState;

    return {
      ...formFields,
      address_attributes: {
        line_1,
        line_2,
        city,
        state,
        zip_code,
      },
    };
  };

  const formatForm = (res: Patient) => {
    const { address, ...attributes } = res;
    const { line_1, line_2, city, state, zip_code } = address || {};

    return {
      ...attributes,
      line_1,
      line_2,
      city,
      state,
      zip_code,
    };
  };

  const handleSuccess = (res: User) => {
    dispatch(setCurrentUser(res));
    dispatch(flashSuccess('Profile updated'));
    history.push(Routes.profile.show);
  };

  const config: FormComponentConfig = {
    actions: {
      alterFormBeforeSubmit: alterForm,
      path,
      model: 'patient',
      method: 'PUT',
      onSuccess: handleSuccess,
      onCancel: () => history.push(Routes.profile.show),
    },
    state: form,
    rules: {
      first_name: { required: true },
      last_name: { required: true },
      email: { required: true, type: 'email' },
      phone_number: { required: true, type: 'phonenumber' },
      line_1: { required: true },
      city: { required: true },
      state: { required: true },
      zip_code: { required: true },
    },
    layout: [
      {
        title: 'Profile Details',
        renderPanel: true,
        fields: [
          [
            {
              label: 'First Name',
              field: 'first_name',
              placeholder: 'Enter first Name',
              type: 'text',
              size: 4,
            },
            {
              label: 'Last Name',
              field: 'last_name',
              placeholder: 'Enter last Name',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Email Address',
              field: 'email',
              placeholder: 'Enter email Address',
              type: 'text',
              size: 4,
            },
            {
              label: 'Phone Number',
              field: 'phone_number',
              placeholder: 'Enter phone number',
              type: 'phonenumber',
              size: 4,
            },
          ],
        ],
      },
      {
        title: 'Profile Details',
        renderPanel: true,
        fields: [
          [
            {
              label: 'Street Address',
              field: 'line_1',
              placeholder: 'Enter street address',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Address Line 2',
              field: 'line_2',
              placeholder: 'Enter address',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'City',
              field: 'city',
              placeholder: 'Enter city',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'State',
              field: 'state',
              placeholder: 'Select state',
              type: 'select',
              options: stateOptions,
              size: 2,
            },
            {
              label: 'Zip Code',
              field: 'zip_code',
              placeholder: 'Enter zip code',
              type: 'zipcode',
              size: 2,
            },
          ],
        ],
      },
    ],
  };

  if (loader.areAnyLoading || !patient) {
    return <ActivityIndicator />;
  }

  return (
    <Page>
      <Container>
        <BackLink to={Routes.profile.show}>Cancel</BackLink>
      </Container>

      <Form config={config} />
    </Page>
  );
};

export default ProfilePatientEdit;
