import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { setErrorCode } from '@/actions/reducers/errors';
import FeatureFlag from '@/components/feature_flags/FeatureFlag';
import Admin from '@/contexts/admin/Admin';
import Calls from '@/contexts/calls/Calls';
import Chart from '@/contexts/chart/Chart';
import Dashboard from '@/contexts/dashboard/Dashboard';
import Devices from '@/contexts/devices/Devices';
import Errors from '@/contexts/errors/Errors';
import HealthSystems from '@/contexts/health_systems/HealthSystems';
import Hospitals from '@/contexts/hospitals/Hospitals';
import MedicationsRequest from '@/contexts/medications/MedicationsRequest';
import Patients from '@/contexts/patients/Patients';
import Practices from '@/contexts/practices/Practices';
import Profile from '@/contexts/profile/Profile';
import Provider from '@/contexts/provider/Provider';
import Reports from '@/contexts/reports/Reports';
import Specialists from '@/contexts/specialists/Specialists';
import Users from '@/contexts/users/Users';
import ManageSendNotification from '@/contexts/users/ManageSendNotification';
import PatientEditAll from '../patients/PatientsEditAll';
import { selectHasError } from '@/selectors/errors';
import { selectCurrentUserRole } from '@/selectors/users';
import { Routes } from '@/utils/routeUtils';
import ExternalUsers from '../users/ExternalUsers';
import UserCompletedTasks from '../tasks/UserCompletedTasks';

const Router = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const hasError = useSelector(selectHasError);
  const userRole = useSelector(selectCurrentUserRole);

  useEffect(() => {
    if (hasError) {
      dispatch(setErrorCode(null));
    }
  }, [location.pathname]);

  if (hasError) {
    return <Errors />;
  }

  if (location.pathname === '/') {
    switch (userRole) {
      case 'billing_admin':
        return <Redirect to={Routes.reports} />;

      case 'patient':
        return <Redirect to={Routes.chart.root} />;

      case 'specialist':
        return <Redirect to={Routes.provider.root} />;

      default:
        return <Redirect to={Routes.dashboard} />;
    }
  }

  return (
    <Switch>
      <Route component={Calls} path={Routes.calls} />
      <Route component={Patients} path={Routes.patients} />
      <Route component={Profile} path={Routes.profile.show} />
      <Route component={Users} path={Routes.users} />
      <Route component={ManageSendNotification} path={Routes.send_notification} />
      <Route component={PatientEditAll} path={Routes.patientEditAll} />
      <Route component={ExternalUsers} path={Routes.externalUsers.root} />
      <Route component={Dashboard} path={Routes.dashboard} />
      <Route component={HealthSystems} path={Routes.healthSystems} />
      <Route component={Hospitals} path={Routes.hospitals} />
      <Route component={Practices} path={Routes.practices} />
      <Route component={Reports} path={Routes.reports} />
      <Route component={Admin} path={Routes.admin} />
      <Route component={Devices} path={Routes.devices} />
      <Route component={Specialists} path={Routes.specialists} />
      <Route
        component={MedicationsRequest}
        path={Routes.medicationsRequestNew}
      />
      <Route component={UserCompletedTasks} path={Routes.userTaskCompleted} />



      <FeatureFlag flag="virtual_visits">
        <Route component={Chart} path={Routes.chart.root} />
        <Route component={Provider} path={Routes.provider.root} />
      </FeatureFlag>
    </Switch>
  );
};

export default Router;
