import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ActionHistoryIndex from '@/contexts/patients/modules/action_history/ActionHistoryIndex';
import { Routes } from '@/utils/routeUtils';

const Patients = () => {
  return (
    <Switch>
      <Route
        path={Routes.patientsActionHistoryShow}
        component={ActionHistoryIndex}
      />
      <Route
        path={Routes.patientsActionHistory}
        component={ActionHistoryIndex}
      />
    </Switch>
  );
};

export default Patients;
