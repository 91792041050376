import classNames from 'classnames';
import React from 'react';

import { SideEffect } from '@/types/generic';
import Checkbox from './Checkbox';
import ErrorMessage from './ErrorMessage';

interface Props {
  checkedValue?: any;
  isDisabled?: boolean;
  label: string;
  onChange: SideEffect;
  uncheckedValue?: any;
  validationMessage?: string;
  value: any;
}

const CheckboxGroup = (props: Props) => {
  const {
    checkedValue,
    isDisabled,
    label,
    onChange,
    uncheckedValue,
    validationMessage,
    value,
  } = props;

  const getIsChecked = () => {
    if (!checkedValue || !uncheckedValue) {
      return !!value;
    }

    return value === checkedValue;
  };

  const isChecked = getIsChecked();

  const handleClickLabel = () => {
    if (isDisabled) {
      onChange(value);
    } else if (checkedValue && uncheckedValue) {
      onChange(isChecked ? uncheckedValue : checkedValue);
    } else {
      onChange(!value);
    }
  };

  return (
    <>
      <label className="form-checkbox-wrapper" onClick={handleClickLabel}>
        <Checkbox isChecked={isChecked} isDisabled={isDisabled} />
        <span className={classNames({ 'is-disabled': isDisabled })}>
          {label}
        </span>
      </label>
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </>
  );
};

export default CheckboxGroup;
