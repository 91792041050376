import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setCurrentUser } from '@/actions/reducers/users';
import { flashSuccess } from '@/actions/sagas/messageSaga';
import BackLink from '@/components/button/BackLink';
import Container from '@/components/container/Container';
import Form from '@/components/form/Form';
import Page from '@/components/page/Page';
import { getCurrentUser } from '@/selectors/users';
import { FormComponentConfig } from '@/types/form';
import { User } from '@/types/user';
import { AdminUser } from '@/types/v2/admin_user';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';

const Profile = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const user = useSelector(getCurrentUser);
  const adminUser = new Modeler<AdminUser>(user).build();

  const initialState = (() => {
    const { first_name, last_name, email } = adminUser;

    return {
      first_name,
      last_name,
      email,
    };
  })();

  const handleSuccess = (res: User) => {
    dispatch(setCurrentUser(res));
    dispatch(flashSuccess('Profile updated'));
    history.push(Routes.profile.show);
  };

  const path = buildPath(Routes.api2.adminUser, { id: adminUser.id });

  const config: FormComponentConfig = {
    actions: {
      path,
      model: 'admin_user',
      method: 'PUT',
      onSuccess: handleSuccess,
      onCancel: () => history.push(Routes.profile.show),
    },
    state: initialState,
    rules: {
      first_name: { required: true },
      last_name: { required: true },
      email: { required: true, type: 'email' },
    },
    layout: [
      {
        title: 'Profile Details',
        renderPanel: true,
        fields: [
          [
            {
              label: 'First Name',
              field: 'first_name',
              placeholder: 'Enter first Name',
              type: 'text',
              size: 4,
            },
            {
              label: 'Last Name',
              field: 'last_name',
              placeholder: 'Enter last Name',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Email Address',
              field: 'email',
              placeholder: 'Enter email Address',
              type: 'text',
              size: 4,
            },
          ],
        ],
      },
    ],
  };

  return (
    <Page>
      <Container>
        <BackLink to={Routes.profile.show}>Cancel</BackLink>
      </Container>

      <Form config={config} />
    </Page>
  );
};

export default Profile;
