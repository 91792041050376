import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import AccessControl from '@/components/access_control/AccessControl';
import Button from '@/components/button/Button';
import SelectGroup from '@/components/form/SelectGroup';
import FillHeight from '@/components/layout/FillHeight';
import Modal from '@/components/modal/Modal';
import Panel from '@/components/panel/Panel';
import { PolicyGroups } from '@/components/router';
import PatientStat from '@/components/stat/PatientStat';
import DateTimeCell from '@/components/table/DateTimeCell';
import Table from '@/components/table/Table';
import { Tab, Tabs } from '@/components/tabs';
import { getSelectedPatient } from '@/selectors/api';
import { selectIsCurrentUserAnyAdmin } from '@/selectors/users';
import { UUID } from '@/types/generic';
import { TableConfig } from '@/types/table';
import { formatShortDate } from '@/utils/dateUtils';
import { monthOptions, renderYearOptions } from '@/utils/dropdownUtils';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { formatSeconds, formatSecondsToHours } from '@/utils/timeUtils';
import { fullName } from '@/utils/userUtils';
import PatientLayout from '../../components/PatientLayout';
import {
  careManagementService,
  isCcmService,
  isEnrolled,
  isRpmService,
} from '../../utils/patientUtils';
import AdminEntryModal from './AdminEntryModal';
import CallCalendar from './CallCalendar';
import CallModal from './CallModal';

const ADMIN_ENTRIES_SEARCH_PATH = 'api/v1/admin_entries';
const CALLS_SEARCH_PATH = 'api/v1/calls';
const NOTES_SEARCH_PATH = 'api/v1/notes';

interface Params {
  admin_entry_id: UUID;
  call_id: UUID;
}

const CallsIndex = () => {
  const history = useHistory();

  const patient = useSelector(getSelectedPatient);
  const isAdmin = useSelector(selectIsCurrentUserAnyAdmin);
  const isPatientEnrolled = isEnrolled(patient);

  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const [queryOptions, setQueryOptions] = useState({
    month: '',
    year: '',
  });

  const [_query, initialTab] = returnTabQuery();
  const serviceName = careManagementService();
  const service = { service: serviceName };

  const [selectedTab, setSelectedTab] = useState<string>(initialTab || 'calls');

  const query = { return_tab: selectedTab };
  const isRpm = isRpmService();

  const { admin_entry_id, call_id } = useParams<Params>();

  const handleApplyFilter = () => {
    setQueryOptions({
      ...queryOptions,
      year,
      month,
    });
  };

  const handleClickCall = (callId: UUID) => {
    const url = buildPath(
      isRpm ? Routes.patientsCallsShowRpm : Routes.patientsCallsShow,
      {
        id: patient.id,
        call_id: callId,
      },
      query,
    );

    history.push(url);
  };

  const handleClickScheduled = () => {
    const url = buildPath(
      isRpm ? Routes.patientsCallsScheduleRpm : Routes.patientsCallsSchedule,
      {
        id: patient.id,
      },
      { ...query, ...service },
    );

    history.push(url);
  };

  const isAdminEntryModalVisible = !!admin_entry_id;
  const isCallViewModalVisible = !!call_id;

  const closeModal = () => {
    const path = buildPath(
      isRpm ? Routes.patientsCareManagementRpm : Routes.patientsCareManagement,
      { id: patient.id },
    );
    history.push(path);
  };

  const handleAddCall = () => {
    const callPath = buildPath(
      Routes.patientsCallsNew,
      { id: patient.id },
      { ...query, ...service },
    );
    history.push(callPath);
  };

  const handleAddAdminEntry = () => {
    const callPath = buildPath(
      Routes.patientsAdminEntriesNew,
      { id: patient.id },
      { ...query, ...service },
    );
    history.push(callPath);
  };

  const handleAddNote = () => {
    const notePath = buildPath(
      Routes.patientsNotesNew,
      { id: patient.id },
      { ...query, ...service },
    );
    history.push(notePath);
  };

  const callsTableConfig: TableConfig = {
    searchPath: CALLS_SEARCH_PATH,
    initialSort: 'start_at:desc',
    searchQuery: {
      ...queryOptions,
      ...service,
      patient_id: patient.id,
      sort: 'created_at:desc',
      scheduled: false,
      include: 'care_manager',
    },
    emptyState: 'No calls added yet',
    columns: [
      {
        header: 'Call Time',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'start_at',
        formatEntry: entry => {
          return <DateTimeCell date={entry.start_at} time={entry.start_at} />;
        },
      },
      {
        header: 'Call Status',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'status',
        formatAttribute: titleizeSnakeCase,
      },
      {
        header: 'Contact',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'patient_phone_number',
        formatAttribute: formatPhoneNumber,
      },
      {
        header: 'Duration',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'duration',
        formatAttribute: formatSeconds,
      },
      {
        header: 'Care Manager',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'care_manager_id',
        formatEntry: (entry: any) => fullName(entry.care_manager),
      },
      {
        header: 'After Enroll',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'is_after_enroll',
        formatEntry: entry => {
          if (entry.is_after_enroll) {
            return 'Yes';
          }

          return 'No';
        },
      },
    ],
    rows: {
      viewId: 'call_id',
      viewPath: buildPath(
        isCcmService() ? Routes.patientsCallsShow : Routes.patientsCallsShowRpm,
        {
          id: patient.id,
        },
        service,
      ),
      editPath:
        buildPath(
          Routes.patientsCallsEdit,
          {
            patient_id: patient.id,
          },
          service,
        ),
    },
  };

  const notesTableConfig: TableConfig = {
    searchPath: NOTES_SEARCH_PATH,
    searchQuery: {
      ...queryOptions,
      ...service,
      patient_id: patient.id,
      sort: 'created_at:desc',
    },
    emptyState: 'No notes added yet',
    columns: [
      {
        header: 'Note',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'content',
        width: 2,
      },
      {
        header: 'Created By',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'name',
        formatEntry: (entry, entryIncluded = []) => {
          const userId = entry.relationships.creator.data.id;
          const user = entryIncluded.find(include => include.id === userId);
          return fullName(user);
        },
      },
      {
        header: 'Created Date',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'created_at',
        formatEntry: entry => {
          return <DateTimeCell date={entry.created_at} />;
        },
      },
    ],
    rows: {
      editPath: buildPath(
        Routes.patientsNotesEdit,
        {
          patient_id: patient.id,
        },
        { ...query, ...service },
      ),
    },
  };

  const adminEntriesConfig: TableConfig = {
    searchPath: ADMIN_ENTRIES_SEARCH_PATH,
    initialSort: 'created_at:desc',
    searchQuery: {
      ...queryOptions,
      ...service,
      patient_id: patient.id,
      sort: 'created_at:desc',
    },
    emptyState: 'No admin entries added yet',
    columns: [
      {
        header: 'Date',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'start_at',
        formatAttribute: formatShortDate,
      },
      {
        header: 'Duration',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'duration',
        formatAttribute: formatSecondsToHours,
      },
      {
        header: 'Reason',
        isSortable: false,
        attribute: 'manual_entry_reason',
        formatAttribute: titleizeSnakeCase,
      },
      {
        header: 'Description',
        isSortable: false,
        attribute: 'notes',
      },
    ],
    rows: {
      viewId: 'admin_entry_id',
      viewPath: buildPath(
        isCcmService()
          ? Routes.patientsAdminEntriesShow
          : Routes.patientsAdminEntriesShowRpm,
        {
          id: patient.id,
        },
        service,
      ),
      editPath:
        isAdmin &&
        buildPath(
          Routes.patientsAdminEntriesEdit,
          {
            patient_id: patient.id,
          },
          { ...query, ...service },
        ),
    },
  };

  const renderTabContent = () => {
    if (selectedTab === 'calls') {
      return <Table config={callsTableConfig} key="calls" />;
    } else if (selectedTab === 'notes') {
      return <Table config={notesTableConfig} key="notes" />;
    } else if (selectedTab === 'admin_entries') {
      return <Table config={adminEntriesConfig} key="admin_entries" />;
    }

    return (
      <CallCalendar
        onClickEvent={handleClickCall}
        onClickSchedule={handleClickScheduled}
        patient={patient}
      />
    );
  };

  const renderAddButton = () => {
    switch (selectedTab) {
      case 'admin_entries':
        return (
          <AccessControl allowedRoles={PolicyGroups.atLeastAdmin}>
            <Button color="secondary" onClick={handleAddAdminEntry}>
              Add Manual Log
            </Button>
          </AccessControl>
        );

      case 'calls':
        return (
          <AccessControl allowedRoles={PolicyGroups.atLeastAdmin}>
            <Button
              color="secondary"
              isDisabled={!isPatientEnrolled}
              onClick={handleAddCall}>
              Add Manual Log
            </Button>
          </AccessControl>
        );

      case 'notes':
        return (
          <Button color="secondary" onClick={handleAddNote}>
            Add note
          </Button>
        );

      case 'schedule':
        return (
          <Button color="secondary" onClick={handleAddCall}>
            Schedule Call
          </Button>
        );
    }
  };

  const displayScheduled = selectedTab === 'schedule';

  const yearOptions = renderYearOptions(patient.attributes.enrollment_date);

  const renderTime = (attribute: string) => {
    const time = patient.attributes.monthly_stats[attribute] || 0;

    return formatSecondsToHours(time);
  };

  return (
    <>
      <PatientLayout>
        <Panel>
          <FillHeight isFlex>
            <Tabs
              currentTab={selectedTab}
              isDisabled={!isPatientEnrolled}
              onSelectTab={setSelectedTab}>
              <Tab value="calls">Call List</Tab>
              <Tab value="admin_entries">Admin</Tab>
              <Tab value="notes">Notes</Tab>
              <Tab value="schedule">Call Schedule</Tab>
            </Tabs>

            <div className="columns">
              <div className="column">
                <div className="patient-stats">
                  <PatientStat
                    label={`Monthly ${serviceName.toUpperCase()} Call Time`}
                    value={renderTime(`total_${serviceName}_phone`)}
                  />

                  <PatientStat
                    label={`Monthly ${serviceName.toUpperCase()} Admin Time`}
                    value={renderTime(`total_${serviceName}_admin`)}
                  />

                  <PatientStat
                    label={`Total Monthly ${serviceName.toUpperCase()} Time`}
                    value={renderTime(`total_${serviceName}_time`)}
                  />
                </div>
              </div>
            </div>

            {!displayScheduled && (
              <div className="columns">
                <div className="column is-12-mobile is-6-tablet">
                  <div className="columns table__filter-wrapper">
                    <div className="column is-5">
                      <SelectGroup
                        isDisabled={!isPatientEnrolled}
                        label="Year"
                        onChange={value => setYear(value)}
                        options={yearOptions}
                        placeholder="Filter year"
                        value={year}
                      />
                    </div>

                    <div className="column is-5">
                      <SelectGroup
                        isDisabled={!isPatientEnrolled}
                        label="Month"
                        onChange={value => setMonth(value)}
                        options={monthOptions}
                        placeholder="Filter month"
                        value={month}
                      />
                    </div>

                    <div className="column is-2">
                      <Button
                        color="white"
                        isDisabled={!isPatientEnrolled}
                        onClick={handleApplyFilter}>
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="column is-12-mobile is-6-tablet table__button-wrapper">
                  {renderAddButton()}
                </div>
              </div>
            )}

            {renderTabContent()}
          </FillHeight>
        </Panel>
      </PatientLayout>

      <Modal isVisible={isCallViewModalVisible} onCloseModal={closeModal}>
        <CallModal callId={call_id} patientId={patient.id} />
      </Modal>

      <Modal isVisible={isAdminEntryModalVisible} onCloseModal={closeModal}>
        <AdminEntryModal adminEntryId={admin_entry_id} />
      </Modal>
    </>
  );
};

export default CallsIndex;
