export const fullCalendarProps = {
  defaultTimedEventDuration: '00:15',
  headerToolbar: { center: 'dayGridMonth,dayGridWeek,dayGridDay' },
  initialView: 'dayGridMonth',
  moreLinkClick: 'day',
  views: {
    dayGridMonth: {
      dayMaxEventRows: 4,
    },
  },
};
