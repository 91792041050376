import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Api from '@/api/Api';
import { getSelectedPatient } from '@/selectors/api';
import { Patient } from '@/types/v2/patient';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientHeader from './PatientHeader';
import PatientSidebar from './PatientSidebar';

interface Props {
  children: ReactNode;
}

const PatientLayout = (props: Props) => {
  const { children } = props;

  const loader = useLoadingState('appointment');
  const notifier = useNotifier();

  const selectedPatient = useSelector(getSelectedPatient);

  const [patient, setPatient] = useState<Patient>(null);

  const getPatient = async (): Promise<void> => {
    loader.startLoading('patient');

    try {
      const url = buildPath(
        Routes.api2.patient,
        { id: selectedPatient.id },
        null,
        [
          'address',
          'emergency_contact',
          'previous_names',
          'practice',
          'practice.hospital',
          'practice.health_system',
          'default_specialist',
          'specialists',
        ],
      );
      const response = await Api.utility.get(url);
      const resource = new Modeler<Patient>(response.data, {
        generations: 2,
      }).build();

      setPatient(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('patient');
  };

  useEffect(() => {
    if (selectedPatient) {
      getPatient();
    }
  }, [selectedPatient]);

  if (!selectedPatient || !patient) {
    return null;
  }

  return (
    <>
      <PatientHeader patient={patient} />

      <div className="patient-layout">
        <div className="columns is-multiline">
          <div className="column is-full-touch is-2 has-no-vertical-padding-touch">
            <PatientSidebar patient={patient} />
          </div>

          <div className="column is-full-touch is-10 patient-layout__column">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientLayout;
