import classNames from 'classnames';
import React, { useState } from 'react';

import { SideEffect } from '@/types/generic';
import Icon from '../icon/Icon';

interface Props {
  text: string;
  onClick: SideEffect;
}

const lookup = {
  '2': 'ABC',
  '3': 'DEF',
  '4': 'GHI',
  '5': 'JKL',
  '6': 'MNO',
  '7': 'PQRS',
  '8': 'TUV',
  '9': 'WXYZ',
  '0': '+',
};

const Key = (props: Props) => {
  const { text, onClick } = props;

  const [showFlash, setShowFlash] = useState<boolean>(false);

  const clearFlash = () => {
    setShowFlash(false);
  };

  const flash = () => {
    setShowFlash(true);
    setTimeout(clearFlash, 500);
  };

  const handleClick = () => {
    flash();
    onClick();
  };

  const lowerText = lookup[text];

  const renderContent = () => {
    if (text === '*') {
      return <Icon icon="asterisk" />;
    }

    if (text === '#') {
      return <Icon icon="pound" />;
    }

    return (
      <>
        <span>{text}</span>
        <span className="keypad__lower">{lowerText}</span>
      </>
    );
  };

  return (
    <button
      className={classNames('keypad__key', {
        'is-flashed': showFlash,
      })}
      onClick={handleClick}>
      {renderContent()}
    </button>
  );
};

export default Key;
