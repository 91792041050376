import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Table } from '@/components/table';
import { UUID } from '@/types/generic';
import { SearchQuery, TableConfig } from '@/types/table';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import { formatShortDateTime } from '@/utils/dateUtils';
import { buildPath, includes, Routes } from '@/utils/routeUtils';
import { titleize } from '@/utils/stringUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  practiceId: UUID;
  query: SearchQuery;
}

const PracticeVisitsTable = ({ practiceId, query }: Props): JSX.Element => {
  const history = useHistory();

  const handleClickView = (entry: VirtualAppointment): void => {
    const path = buildPath(Routes.provider.practice.show.visits, null, {
      appointment: entry.id,
    });
    history.push(path);
  };

  const config = useMemo((): TableConfig => {
    const searchQuery = {
      ...query,
      practice_id: practiceId,
      include: includes('admin_user', 'admin_user.specialist', 'patient'),
    };

    return {
      initialSort: 'scheduled_start_at:desc',
      searchPath: Routes.api2.virtualAppointments,
      searchQuery,
      columns: [
        {
          header: 'Visit Date',
          isSortable: false,
          isVisibleMobile: true,
          attribute: 'scheduled_start_at',
          formatEntry: (entry: VirtualAppointment) => {
            if (entry.appointment_status === 'completed') {
              return formatShortDateTime(entry.actual_start_at);
            }

            return formatShortDateTime(entry.scheduled_start_at);
          },
        },
        {
          header: 'Visit Status',
          isSortable: false,
          isVisibleMobile: true,
          attribute: 'appointment_status',
          formatAttribute: titleize,
        },
        {
          header: 'Patient',
          isSortable: false,
          attribute: 'patients.first_name',
          formatEntry: (entry: VirtualAppointment) => {
            const url = buildPath(
              Routes.provider.patients.show.virtualVisits.root,
              {
                patient_id: entry.patient.id,
              },
              {
                return: window.location.pathname,
              },
            );

            return <Link to={url}>{fullName(entry.patient)}</Link>;
          },
        },
        {
          header: 'Patient ID',
          isSortable: false,
          attribute: 'patients.id',
          formatEntry: (entry: VirtualAppointment) => entry.patient.id,
        },
        {
          header: 'Provider',
          isSortable: false,
          attribute: 'specialists.name',
          formatEntry: (entry: VirtualAppointment) => {
            if (entry.admin_user_id) {
              const url = buildPath(
                Routes.provider.practice.show.provider,
                {
                  provider_id: entry.admin_user.id,
                },
                { return: window.location.pathname },
              );

              return <Link to={url}>{entry.admin_user.specialist.name}</Link>;
            }

            return 'Not selected';
          },
        },
      ],
      rows: {
        viewPath: handleClickView,
      },
    };
  }, [query]);

  return <Table config={config} shouldUseModeler modelerGenerations={2} />;
};

export default PracticeVisitsTable;
