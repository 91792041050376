import React, { useState } from 'react';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Caller from '@/components/caller/Caller';
import Checkbox from '@/components/form/Checkbox';
import { ContactStatus, Patient } from '@/types/patient';
import { serviceTags } from '@/types/service';
import { formatShortDate } from '@/utils/dateUtils';
import { longName } from '@/utils/patientUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { formatSeconds } from '@/utils/timeUtils';
import { useDispatch } from 'react-redux';

interface Props {
  patient: Patient;
  shouldDisplayCallModule?: boolean;
}

const PatientDetails = (props: Props) => {
  const { patient, shouldDisplayCallModule } = props;

  const dispatch = useDispatch();

  const [contactStatus, setContactStatus] = useState<ContactStatus>(
    patient.attributes.contact_status,
  );

  const patientAttributes = patient.attributes;

  const address = patientAttributes.address;

  const patientId = patient.id;

  const path = buildPath(Routes.patientsEdit, { id: patientId });

  const handleToggleContactStatus = async (): Promise<any> => {
    const newStatus =
      contactStatus === 'contacted' ? 'needs_contact' : 'contacted';

    setContactStatus(newStatus);

    const url = `api/v1/patients/${patientId}/status`;

    const body = {
      patient: {
        contact_status: newStatus,
      },
    };

    try {
      await Api.utility.patch(url, body);

      dispatch(flashSuccess('Patient updated'));
    } catch (err) {
      dispatch(flashError('Error updating patient'));
    }
  };

  const renderCallModule = () => {
    if (shouldDisplayCallModule) {
      return (
        <div className="column">
          <Caller editPath={path} patient={patient} />
        </div>
      );
    }
  };

  const renderCcmCallData = () => {
    if (shouldDisplayCallModule && patient.attributes.is_enrolled_ccm) {
      const totalTime = patient.attributes.monthly_stats.total_ccm_phone;
      const totalGoal = patient.attributes.monthly_stats.ccm_goal;

      return (
        <div className="columns">
          <div className="column has-no-vertical-padding">
            <span className="patient-details__highlight">
              {`CCM: ${formatSeconds(totalTime, true)} of ${formatSeconds(
                totalGoal * 60,
                true,
              )} logged this month`}
            </span>
          </div>
        </div>
      );
    }
  };

  const renderRpmCallData = () => {
    if (shouldDisplayCallModule && patient.attributes.is_enrolled_rpm) {
      const totalTime = patient.attributes.monthly_stats.total_rpm_phone;
      const totalGoal = patient.attributes.monthly_stats.rpm_goal;

      return (
        <div className="columns">
          <div className="column has-no-vertical-padding">
            <span className="patient-details__highlight">
              {`RPM: ${formatSeconds(totalTime, true)} of ${formatSeconds(
                totalGoal * 60,
                true,
              )} logged this month`}
            </span>
          </div>
        </div>
      );
    }
  };

  const renderServices = () => {
    const serviceBadges = serviceTags
      .map((service, index) => {
        if (patient.attributes[`is_enrolled_${service}`]) {
          return (
            <div className="tab" key={index}>
              {service.toUpperCase()}
            </div>
          );
        }

        return null;
      })
      .filter(n => n);

    return (
      <div className="columns">
        <div className="column has-no-top-padding">
          <div className="patient-details__services">{serviceBadges}</div>
        </div>
      </div>
    );
  };

  const renderPatientNotes = () => {
    if (patientAttributes.patient_notes) {
      return (
        <div className="columns">
          <div className="column">
            <h4 className="patient-details__highlight">Patient Notes</h4>
            <p className="patient-details__notes">
              {patientAttributes.patient_notes}
            </p>
          </div>
        </div>
      );
    }
  };

  const renderPatientDetails = (() => {
    const fields = [
      `DOB: ${formatShortDate(patientAttributes.date_of_birth)}`,
      `${titleizeSnakeCase(patientAttributes.gender)}`,
    ];

    if (address) {
      fields.push(`${address.city}, ${address.state}`);
    }

    return fields.join(' | ');
  })();

  return (
    <div className="patient-details">
      <div className="columns">
        <div className="column">
          <div className="patient-details__contact-status">
            <h2 className="patient-details__name">{longName(patient)}</h2>

            {shouldDisplayCallModule && (
              <label
                className="form-checkbox-wrapper"
                onClick={handleToggleContactStatus}>
                <Checkbox isChecked={contactStatus === 'contacted'} />
                <span>Contacted</span>
              </label>
            )}
          </div>
        </div>

        {renderCallModule()}
      </div>

      {renderServices()}

      <div className="columns">
        <div className="column is-6">
          <div className="columns">
            <div className="column has-no-vertical-padding">
              <h4 className="patient-details__highlight">
                {renderPatientDetails}
              </h4>
            </div>
          </div>

          <div className="columns">
            <div className="column is-6 has-no-vertical-padding">
              <span className="patient-details__highlight">
                {titleizeSnakeCase(patientAttributes.status)}
              </span>
            </div>
          </div>
        </div>

        <div className="column is-6">
          {renderCcmCallData()}
          {renderRpmCallData()}
        </div>
      </div>

      {renderPatientNotes()}
    </div>
  );
};

export default PatientDetails;
