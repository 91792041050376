import classNames from 'classnames';
import React from 'react';

import { SideEffect } from '@/types/generic';
import Button from '../button/Button';
import Icon from '../icon/Icon';

interface Props {
  isInvalid?: boolean;
  onChange?: SideEffect;
  onClick?: SideEffect;
  onPressEnter?: SideEffect;
  placeholder?: string;
  value: string;
}

export const InputIcon = (props: Props) => {
  const {
    isInvalid,
    onChange,
    onClick,
    onPressEnter,
    placeholder,
    ...inputProps
  } = props;

  const handleChange = event => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onPressEnter) {
      onPressEnter();
    }
  };

  return (
    <div className="form-input-icon-wrapper">
      <input
        className="form-input-icon"
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        type="text"
        placeholder={placeholder}
        {...inputProps}
      />
      <Button onClick={onClick} color="secondary" style="icon">
        {<Icon icon="search" />}
      </Button>
    </div>
  );
};

export default InputIcon;
