import moment from 'moment';
import React, { useEffect, useState } from 'react';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import { Call } from '@/types/call';
import useGetRequest from '@/utils/api/useGetRequest';
import { formatShortDate } from '@/utils/dateUtils';
import { buildPath } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { fullName } from '@/utils/userUtils';
import { useDispatch } from 'react-redux';

interface Props {
  queryOptions: { [key: string]: any };
  startAt: string;
}

const CallScheduleModal = (props: Props) => {
  const { queryOptions, startAt } = props;

  const dispatch = useDispatch();

  const [calls, setCalls] = useState(null);

  const startAtUtc = moment(startAt)
    .utc()
    .format();

  const endAtUtc = moment(startAt)
    .endOf('day')
    .utc()
    .format();

  const [getCalls, isCallsLoading] = useGetRequest({
    dispatch,
    url: buildPath(
      'api/v1/calls',
      {},
      {
        ...queryOptions,
        start_at: startAtUtc,
        end_at: endAtUtc,
        start_date: 'start_at',
        per: null,
        include: 'care_manager,patient,service',
      },
    ),
    model: 'calls',
    onSuccess: (response: any) => {
      setCalls(response);
    },
  });

  useEffect(() => {
    if (startAt) {
      getCalls();
    }
  }, [startAt]);

  const renderCallType = (call: Call) => {
    const service = call.attributes.service.name;

    return (
      <div className="tab call-schedule-patient-modal__tab">{service}</div>
    );
  };

  if (!calls || isCallsLoading) {
    return <ActivityIndicator />;
  }

  const renderEvents = () => {
    return calls.data.map(call => {
      const dateText = [
        formatShortDate(call.attributes.start_at),
        moment(call.attributes.start_at).format('h:mm a'),
      ]
        .filter(n => n)
        .join(' at ');

      return (
        <div className="call-schedule-patient-modal__wrapper" key={call.id}>
          <div className="call-schedule-patient-modal__details">
            <div className="call-schedule-patient-modal__details-top">
              <span>{dateText}</span>
              <span>
                Call Status: {titleizeSnakeCase(call.attributes.status)}
              </span>
            </div>

            <span>Care Manager: {fullName(call.attributes.care_manager)}</span>
            <span>Patient: {fullName(call.attributes.patient)}</span>
          </div>

          {renderCallType(call)}
        </div>
      );
    });
  };
  return (
    <div className="call-schedule-patient-modal">
      <h3 className="call-schedule-patient-modal__header">
        {moment(startAt).format('ll')}
      </h3>
      {renderEvents()}
    </div>
  );
};

export default CallScheduleModal;
