import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Api from '@/api/Api';
import BackLink from '@/components/button/BackLink';
import ButtonLink from '@/components/button/ButtonLink';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Actions from '@/components/form/Actions';
import Panel from '@/components/panel/Panel';
import Profile from '@/contexts/provider/components/Profile';
import { UUID } from '@/types/generic';
import { AdminUser } from '@/types/v2/admin_user';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';

interface Params {
  specialist_id: UUID;
}

const Provider = (): JSX.Element => {
  const backPath = buildPath(Routes.chart.providers.root);

  const { specialist_id } = useParams<Params>();

  const [adminUser, setAdminUser] = useState<AdminUser>(null);

  const loader = useLoadingState('specialist');
  const notifier = useNotifier();

  const getSpecialist = useCallback(async () => {
    loader.startLoading('specialist');

    try {
      const url = buildPath(
        Routes.api2.adminUser,
        { id: specialist_id },
        null,
        [
          'practice',
          'practice.hospital',
          'practice.health_system',
          'specialist',
        ],
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<AdminUser>(response.data, {
        generations: 2,
      }).build();

      setAdminUser(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('specialist');
  }, [specialist_id]);

  useEffect(() => {
    if (specialist_id) {
      getSpecialist();
    }
  }, [specialist_id]);

  const renderPageContent = (() => {
    if (loader.areAllComplete && adminUser) {
      return (
        <Constraint size="medium">
          <Profile user={adminUser} />
        </Constraint>
      );
    }

    return <loader.activity />;
  })();

  const scheduleAppointmentLink = buildPath(
    Routes.chart.appointments.new,
    null,
    { provider: specialist_id },
  );

  return (
    <Container>
      <BackLink to={backPath}>Back</BackLink>
      <Panel>{renderPageContent}</Panel>

      <Constraint size="medium">
        <Actions>
          <ButtonLink to={scheduleAppointmentLink}>
            Schedule Appointment
          </ButtonLink>
        </Actions>
      </Constraint>
    </Container>
  );
};

export default Provider;
