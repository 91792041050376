import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';
import { getCurrentUser, selectCurrentUserId } from '@/selectors/users';
import { TableConfig } from '@/types/table';
import { Patient } from '@/types/v2/patient';
import { formatShortDate } from '@/utils/dateUtils';
import useSimpleForm from '@/utils/form/useSimpleForm';
import { buildPath, includes, Routes } from '@/utils/routeUtils';
import SearchHeader, { FormState } from './SearchHeader';

const initialSearchQuery: FormState = {
  search: '',
  per: '24',
};

const Patients = () => {
  const form = useSimpleForm<typeof initialSearchQuery>(initialSearchQuery);

  const history = useHistory();
  const user = useSelector(getCurrentUser);

  const [searchInput, setSearchInput] = useState<string>('');

  const searchQuery = {
    ...form.data,
    include: includes('practice', 'default_specialist'),
    practice_id: user.data.attributes.practice_id,
  };

  const handleChangeSearch = (value: string): void => {
    setSearchInput(value);
  };

  const handleClearFilters = (): void => {
    form.update('search', '');
  };

  const handleStartSearch = useCallback((): void => {
    form.update('search', searchInput);
  }, [searchInput]);

  const tableConfig: TableConfig = {
    searchPath: Routes.api2.patients,
    searchQuery,
    initialSort: 'first_name:asc',
    columns: [
      {
        header: 'First Name',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'first_name',
        className: 'table__cell-highlight',
      },
      {
        header: 'Last Name',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'last_name',
        className: 'table__cell-highlight',
      },
      {
        header: 'DOB',
        isSortable: true,
        attribute: 'date_of_birth',
        formatAttribute: formatShortDate,
      },
      {
        header: 'Provider',
        isSortable: true,
        attribute: 'specialists.name',
        formatEntry: (entry: Patient) =>
          entry.default_specialist ? entry.default_specialist.name : '',
      },
      {
        header: 'Practice',
        isSortable: true,
        attribute: 'practices.name',
        formatEntry: (entry: Patient) => entry.practice.name,
      },
    ],
    rows: {
      onClickPath: (entry: any) => {
        history.push(
          buildPath(Routes.provider.patients.show.virtualVisits.root, {
            patient_id: entry.id,
          }),
        );
      },
    },
  };

  return (
    <Panel>
      <Container>
        <SearchHeader
          form={form.data}
          onChangeForm={form.update}
          onChangeSearch={handleChangeSearch}
          onClearFilters={handleClearFilters}
          onStartSearch={handleStartSearch}
          searchQuery={searchInput}
        />

        <Table config={tableConfig} modelerGenerations={2} shouldUseModeler />
      </Container>
    </Panel>
  );
};

export default Patients;
