import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Api from '@/api/Api';
import Button from '@/components/button/Button';
import Modal from '@/components/modal/Modal';
import { selectIsCallActive } from '@/selectors/call_manager';
import { useInterval } from '@/utils/activityUtils';
import { Routes } from '@/utils/routeUtils';

const EXPIRATION_MINUTES = process.env.SESSION_TIMEOUT_MINUTES
  ? parseInt(process.env.SESSION_TIMEOUT_MINUTES, 10)
  : 10;

const TimeoutWarning = () => {
  const inactivityTime = useRef<number>(0);
  const callActive = useRef<boolean>(false);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const isCallActive = useSelector(selectIsCallActive);

  useEffect(() => {
    callActive.current = isCallActive;
  }, [isCallActive]);

  useInterval(() => {
    if (callActive.current) {
      sendEmptyRequest();
    } else {
      inactivityTime.current = inactivityTime.current + 1;
    }

    if (inactivityTime.current === EXPIRATION_MINUTES - 1) {
      setIsModalVisible(true);
    } else if (inactivityTime.current === EXPIRATION_MINUTES) {
      window.location.reload();
    }
  }, 1000 * 60);

  useEffect(() => {
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keyup', resetTimer);
  }, []);

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const resetTimer = () => {
    if (inactivityTime.current > 0) {
      sendEmptyRequest();
    }

    inactivityTime.current = 0;
  };

  const sendEmptyRequest = async () => {
    try {
      await Api.utility.get('/api/v1/users/activity');
    } catch (_err) {
      // An error indicates a 401 because the user was logged out; refresh the app
      window.location.reload();
    }
  };

  return (
    <Modal onCloseModal={handleCloseModal} isVisible={isModalVisible}>
      <div className="modal__content">
        <h3 className="modal__header">Inactivity Warning</h3>
        <span className="modal__info">
          For security purposes you will be logged out soon.
        </span>
      </div>

      <div className="modal__actions">
        <a
          className="button is-white"
          data-method="delete"
          href={Routes.authLogout}>
          Log Out
        </a>

        <Button color="secondary" onClick={handleCloseModal}>
          Stay Logged In
        </Button>
      </div>
    </Modal>
  );
};

export default TimeoutWarning;
