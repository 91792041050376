import { useState } from 'react';
import { Dispatch } from 'redux';

interface RequestConfig {
  actionType?: 'add';
  body?: object;
  dispatch: Dispatch;
  dispatchToStore?: boolean;
  initializeAsLoading?: boolean;
  key?: string;
  onFailure?: (responseData: object) => void;
  onSuccess?: (responseData: object) => void;
  payload?: object;
  url: string;
  storeData?: object;
  config?: any;
}

/* NOTE: The config items will have the following behavior:
1. actionType - The type of action you need to perform in redux.
   If it's not provided but dispatchToStore is true, it will simply
   set the data under `key` in store to either the API response data or the storeData
2. body - The request body to send in the PUT request
3. dispatch - The dispatch function [REQUIRED], should be provided by either connect() or useDispatch()
4. dispatchToStore - Whether or not the data from the response or storeData should be set in the redux store
5. key: The key of the redux data that will be modified - for instance, if you wanted to set data under
   `api.user` you would provide "user"
6. onFailure - Function to be run when the request fails
7. onSuccess - Function to be run when the request succeeds, it should take one argument for the API response data
8. url - The URL of the request
9. storeData - If you don't want the API data to be sent to the redux store, you may provide your own data to be sent
*/

function usePutRequest(config: RequestConfig): [() => void, boolean] {
  const {
    actionType,
    dispatch,
    url,
    body,
    dispatchToStore,
    key,
    initializeAsLoading,
    onSuccess,
    onFailure,
    storeData,
  } = config;
  const [isLoading, setIsLoading] = useState(
    initializeAsLoading ? true : false,
  );

  const makeRequest = () => {
    setIsLoading(true);

    dispatch({
      type: 'PUT_DATA_SAGA',
      payload: {
        url,
        key,
        dispatchToStore,
        body: body || {},
        actionType,
        storeData,
      },
      onSuccess: (response: object) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      onFailure: (response: object) => {
        if (onFailure) {
          onFailure(response);
        }
      },
      onComplete: () => setIsLoading(false),
    });
  };

  return [makeRequest, isLoading];
}

export default usePutRequest;
