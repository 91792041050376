import { Option } from '../form';
import { Nullable, UUID } from '../generic';
import { AdminUser } from './admin_user';
import { Message } from './message';
import { Patient } from './patient';
import { Practice } from './practice';
import { VirtualAppointmentQuestionnaire } from './questionnaire';

type AppointmentStatus =
  | 'created'
  | 'confirmed'
  | 'started'
  | 'completed'
  | 'cancelled';

export interface VirtualAppointment {
  id: UUID;
  actual_end_at: Nullable<string>;
  actual_start_at: Nullable<string>;
  admin_user_id: UUID;
  appointment_status: AppointmentStatus;
  duration: number;
  patient_confirmed_at: Nullable<string>;
  patient_id: UUID;
  room: string;
  scheduled_end_at: string;
  scheduled_start_at: string;
  specialist_confirmed_at: Nullable<string>;
  specialist_notes: string;
  admin_user?: AdminUser;
  messages?: Message[];
  patient?: Patient;
  practice?: Practice;
  virtual_appointment_questionnaires?: VirtualAppointmentQuestionnaire[];
}

export const upcomingStatusOptions: Option[] = [
  { label: 'Created', value: 'created' },
  { label: 'Confirmed', value: 'confirmed' },
];

export const pastStatusOptions: Option[] = [
  { label: 'Completed', value: 'completed' },
  { label: 'Cancelled', value: 'cancelled' },
];

export const virtualAppointmentStatusOptions: Option[] = [
  { label: 'Created', value: 'created' },
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Completed', value: 'completed' },
];

const viewableStatues: AppointmentStatus[] = [
  'created',
  'confirmed',
  'started',
];
const joinableStatues: AppointmentStatus[] = ['confirmed', 'started'];

export const isViewable = (appointment: VirtualAppointment): boolean => {
  return viewableStatues.includes(appointment.appointment_status);
};

export const isJoinable = (appointment: VirtualAppointment): boolean => {
  return joinableStatues.includes(appointment.appointment_status);
};

export const isCreated = (appointment: VirtualAppointment): boolean => {
  return appointment.appointment_status === 'created';
};

export const hasAnyQuestionnaires = (
  appointment: VirtualAppointment,
): boolean => {
  return appointment.virtual_appointment_questionnaires.length > 0;
};

export const hasAnyStartedQuestionnaires = (
  appointment: VirtualAppointment,
): boolean => {
  return !!appointment.virtual_appointment_questionnaires.find(
    questionnaire => questionnaire.status !== 'assigned',
  );
};

export const hasProviderSelected = (appointment: VirtualAppointment): boolean =>
  !!appointment.admin_user_id;
