import React from 'react';

import Constraint from '@/components/constraint/Constraint';
import CloseButton from '@/components/modal/CloseButton';
import Panel from '@/components/panel/Panel';

export interface Props {
  onChange: (value: string) => void;
  onClickClose: () => void;
  value: string;
}

const NotesDrawer = ({ onChange, onClickClose, value }: Props): JSX.Element => {
  const handleChangeText = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    onChange(event.target.value);
  };

  return (
    <Panel>
      <CloseButton onClick={onClickClose} />
      <Constraint size="large">
        <h3 className="video-drawer-header">Call Notes</h3>
        <textarea
          className="video-call-notes"
          maxLength={2500}
          onChange={handleChangeText}
          placeholder="Enter notes here..."
          value={value}
        />
      </Constraint>
    </Panel>
  );
};

export default NotesDrawer;
