export const isGeneralOption = (option, condition, generalConditionId) => {
  return (
    condition === 'general' &&
    option.attributes.condition.id === generalConditionId
  );
};

export const createOptions = (
  conditions: any,
  data: any,
  generalConditionId: string,
  generalCategory: string,
) => {
  const formattedOptions = conditions
    .map(condition => {
      const options = data.data
        .filter(
          symptom =>
            symptom.attributes.condition.id === condition.id ||
            isGeneralOption(symptom, condition, generalConditionId),
        )
        .map(symptom => ({
          label: symptom.attributes.description,
          value: symptom.id,
        }));

      if (!options.length) {
        return null;
      }

      return {
        name:
          condition.attributes?.description ||
          condition.description ||
          generalCategory,
        options,
      };
    })
    .filter(n => n);

  return formattedOptions;
};

export const createEntryOptions = (conditions: any, data: any) => {
  const formattedOptions = conditions
    .map(condition => {
      const options = data.data
        .filter(option => option.attributes.condition.id === condition.id)
        .map(option => ({
          type: option.attributes.option_type,
          label: option.attributes.description,
          value: option.id,
        }));

      if (!options.length) {
        return null;
      }

      return {
        name: condition.attributes?.description || condition.description,
        options,
      };
    })
    .filter(n => n);

  return formattedOptions;
};
