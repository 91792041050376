import React from 'react';
import { useResponsive } from 'react-hooks-responsive';

import Panel from '@/components/panel/Panel';
import { Sidebar, SidebarLink } from '@/components/sidebar';
import { Routes } from '@/utils/routeUtils';
import { breakpoints } from '@/utils/screenUtils';

const PracticeSidebar = () => {
  const { screenIsAtMost } = useResponsive(breakpoints);
  const isTouch = screenIsAtMost('tablet');

  const sidebarLinks = () => {
    return (
      <Sidebar>
        <SidebarLink to={Routes.provider.practice.show.providers}>
          Providers
        </SidebarLink>

        <SidebarLink to={Routes.provider.practice.show.visits}>
          Visits
        </SidebarLink>

        <SidebarLink to={Routes.provider.practice.show.calendar}>
          Calendar
        </SidebarLink>

        <SidebarLink to={Routes.provider.practice.show.questionnaires.root}>
          Questionnaires
        </SidebarLink>

        <SidebarLink to={Routes.provider.practice.show.billing}>
          Billing
        </SidebarLink>
      </Sidebar>
    );
  };

  if (isTouch) {
    return <div className="sidebar__touch">{sidebarLinks()}</div>;
  }

  return <Panel withNarrowPadding>{sidebarLinks()}</Panel>;
};

export default PracticeSidebar;
