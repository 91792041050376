import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Button from '@/components/button/Button';
import { canUserModifyAppointments } from '@/selectors/users';
import { UUID } from '@/types/generic';
import {
  hasProviderSelected,
  VirtualAppointment,
} from '@/types/v2/virtual_appointment';
import { buildPath, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';
import { Link } from 'react-router-dom';
import QuestionnaireResponse from '../questionniares/display/QuestionnaireResponse';
import AppointmentDetails from './AppointmentDetails';
import AppointmentModalAction from './AppointmentModalAction';
import AppointmentPatient from './AppointmentPatient';
import AppointmentProvider from './AppointmentProvider';
import { Display } from './types';

interface Props {
  appointment: VirtualAppointment;
  displayFor: Display;
  isConfirmDisabled?: boolean;
  onClickCancel: () => void;
  onClickConfirmAppointment: () => void;
  onEditQuestionnaires?: () => void;
  userId: UUID;
}

const AppointmentModalContent = ({
  appointment,
  displayFor,
  isConfirmDisabled,
  onClickCancel,
  onClickConfirmAppointment,
  onEditQuestionnaires,
  userId,
}: Props): JSX.Element => {
  const isAdmin = useSelector(canUserModifyAppointments);

  const canModify =
    userId === appointment.admin_user_id ||
    userId === appointment.patient_id ||
    isAdmin;
  const canConfirm = hasProviderSelected(appointment) && canModify;

  const renderAttendeeInformation = (() => {
    if (displayFor === 'patient') {
      const renderQuestionnaires = (() => {
        return appointment.virtual_appointment_questionnaires.map(
          questionnaire => (
            <QuestionnaireResponse
              key={questionnaire.id}
              onClickEdit={canModify ? onEditQuestionnaires : null}
              questionnaire={questionnaire}
            />
          ),
        );
      })();

      return (
        <>
          <AppointmentProvider appointment={appointment} />
          {renderQuestionnaires}
        </>
      );
    }

    return (
      <AppointmentPatient
        appointment={appointment}
        shouldDisplayProvider={isAdmin}
      />
    );
  })();

  const reschedulePath = useMemo((): string => {
    const path =
      displayFor === 'patient'
        ? Routes.chart.appointments.edit
        : Routes.provider.appointments.edit;

    const url = buildPath(
      path,
      {
        appointment_id: appointment.id,
      },
      { return: window.location.pathname },
    );

    return url;
  }, [appointment, displayFor]);

  const name =
    displayFor === 'patient'
      ? appointment.admin_user?.specialist.name || 'any Provider'
      : fullName(appointment.patient);

  const renderActions = (() => {
    if (canModify) {
      const updateText =
        isAdmin && !hasProviderSelected(appointment)
          ? 'Assign Provider'
          : 'Reschedule Appointment';

      return (
        <div className="columns">
          <div className="column is-6">
            <Button color="white" isFullWidth onClick={onClickCancel}>
              Cancel Appointment
            </Button>
          </div>

          <div className="column is-6">
            <Link className="button is-white" to={reschedulePath}>
              {updateText}
            </Link>
          </div>
        </div>
      );
    }
  })();

  return (
    <>
      <div className="modal__content-tight">
        {renderActions}

        <AppointmentDetails
          appointment={appointment}
          name={name}
          shouldShowStatus
        />

        {renderAttendeeInformation}

        {canConfirm && (
          <AppointmentModalAction
            appointment={appointment}
            displayFor={displayFor}
            isConfirmDisabled={isConfirmDisabled}
            shouldDisabledJoin={isAdmin}
            onClickConfirmAppointment={onClickConfirmAppointment}
          />
        )}
      </div>
    </>
  );
};

export default AppointmentModalContent;
