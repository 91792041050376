import { call, put, takeLatest } from 'redux-saga/effects';

import api from '@/api/Api';
import { UUID } from '@/types/generic';
import Saga from '@/types/saga';
import { buildPath } from '@/utils/routeUtils';

interface RefreshPatient extends Saga {
  payload: {
    id: UUID;
  };
}

export const refreshPatientAction = (id: UUID) => {
  return {
    type: 'REFRESH_PATIENT',
    payload: {
      id,
    },
  };
};

export function* refreshPatient(action: RefreshPatient) {
  try {
    const { id } = action.payload;

    const url = `api/v1/patients/${id}`;

    const response = yield call(api.utility.get, url);

    yield put({
      type: 'API_FETCH_SUCCESS',
      payload: {
        options: {
          key: 'selectedPatient',
        },
        data: response.data,
      },
    });

    const callsUrl = buildPath(
      'api/v1/calls',
      {},
      { patient_id: id, status: 'scheduled' },
    );

    const callsResponse = yield call(api.utility.get, callsUrl);

    yield put({
      type: 'API_FETCH_SUCCESS',
      payload: {
        options: {
          key: 'selectedPatientCalls',
        },
        data: callsResponse.data,
      },
    });

    if (action.onSuccess) {
      action.onSuccess(response.data);
    }
  } catch (err) {
    if (action.onFailure) {
      action.onFailure(err);
    }
  } finally {
    if (action.onComplete) {
      action.onComplete();
    }
  }
}

export default function* patientSaga() {
  yield takeLatest('REFRESH_PATIENT', refreshPatient);
}
