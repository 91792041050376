import { useDispatch } from 'react-redux';

import {
  flashError,
  flashNotice,
  flashSuccess,
  flashWarning,
} from '@/actions/sagas/messageSaga';
import { Response } from '@/types/response';
import { parseErrorData } from '../apiUtils';

type Message = string | Response<any>;

const getMessage = (message: Message): string => {
  if (typeof message === 'string') {
    return message;
  } else {
    return parseErrorData(message);
  }
};

const useNotifier = () => {
  const dispatch = useDispatch();

  const error = (message: Message): void => {
    dispatch(flashError(getMessage(message)));
  };

  const notice = (message: Message): void => {
    dispatch(flashNotice(getMessage(message)));
  };

  const success = (message: Message): void => {
    dispatch(flashSuccess(getMessage(message)));
  };

  const warn = (message: Message): void => {
    dispatch(flashWarning(getMessage(message)));
  };

  return {
    error,
    notice,
    success,
    warn,
  };
};

export default useNotifier;
