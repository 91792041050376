import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { refreshPatientAction } from '@/actions/sagas/patientSaga';
import Button from '@/components/button/Button';
import MedicalRecordsIndex from '@/contexts/patients/modules/medical_records/MedicalRecordsIndex';
import { getSelectedPatient } from '@/selectors/api';
import usePutRequest from '@/utils/api/usePutRequest';
import { buildPath, Routes } from '@/utils/routeUtils';
import EnrollmentActions from '../components/EnrollmentActions';
import EnrollmentHeader from '../components/EnrollmentHeader';
import { getStepInformation } from '../helpers/steps';

const MedicalHistory = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const patient = useSelector(getSelectedPatient);

  const stepInformation = getStepInformation('medical_history', patient);

  const nextScreen = buildPath(Routes.patientsEnrollment, {
    id: patient.id,
    step: stepInformation.nextStep,
  });

  const body = {
    enrollment_step: stepInformation.currentStep,
    patient: {},
  };

  const handleNext = () => {
    history.push(nextScreen);
  };

  const [saveForm, isSubmitting] = usePutRequest({
    dispatch,
    url: `api/v1/patients/${patient.id}`,
    body,
    dispatchToStore: true,
    key: 'selectedPatient',
    onSuccess: () => {
      dispatch(refreshPatientAction(patient.id));
    },
  });

  useEffect(() => {
    saveForm();
  }, []);

  return (
    <div className="enrollment-wrapper">
      <EnrollmentHeader
        description="Does the patient have any previous medical conditions?"
        linkTo={nextScreen}
        linkText={stepInformation.nextStepTitle}
        title={stepInformation.currentStepTitle}
      />

      <div className="enrollment-table-wrapper">
        <MedicalRecordsIndex />
      </div>

      <EnrollmentActions>
        <Button
          color="secondary"
          onClick={handleNext}
          isSubmitting={isSubmitting}>
          Next
        </Button>
      </EnrollmentActions>
    </div>
  );
};

export default MedicalHistory;
