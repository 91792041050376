import React, { ReactNode } from 'react';

import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Form from '@/components/form/Form';
import Panel from '@/components/panel/Panel';
import { FormComponentConfig } from '@/types/form';

interface Props {
  backText: string;
  backPath: string;
  children?: ReactNode;
  config: FormComponentConfig;
}

const CallForm = (props: Props) => {
  const { backPath, backText, children, config } = props;

  return (
    <>
      <Container>
        <BackLink to={backPath}>{backText}</BackLink>
      </Container>

      <Panel>
        <Constraint size="large">
          {children}
          <Form config={config} />
        </Constraint>
      </Panel>
    </>
  );
};

export default CallForm;
