import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import ErrorMessage from '@/components/form/ErrorMessage';
import ConfirmationModal from '@/contexts/modals/ConfirmationModal';
import { Patient } from '@/types/patient';
import { parseErrorData } from '@/utils/apiUtils';
import { formatShortDateTime } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';

interface Props {
  patient: Patient;
}

const PatientPassword = (props: Props): JSX.Element => {
  const { patient } = props;

  const dispatch = useDispatch();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState<boolean>(
    false,
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);

  const isPasswordSet = !!patient.attributes.password_delivered_at;
  const noticeMessage = `Password last sent at ${formatShortDateTime(
    patient.attributes.password_delivered_at,
  )}`;

  const closeConfirmationModal = () => {
    setIsConfirmationVisible(false);
  };

  const handleClickEmailPassword = () => {
    setIsConfirmationVisible(true);
  };

  const deliverPatientPassword = async (): Promise<void> => {
    setIsSubmitting(true);
    closeConfirmationModal();

    try {
      const url = buildPath(
        Routes.api.patient,
        { id: patient.id },
        { generate_password: 'true' },
      );

      await Api.utility.patch(url);

      dispatch(flashSuccess('Password updated and sent to patient'));
      setIsSent(true);
    } catch (err) {
      dispatch(flashError(parseErrorData(err)));
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <div className="patient-password">
        <Button
          color="white"
          isDisabled={isSent}
          isSubmitting={isSubmitting}
          onClick={handleClickEmailPassword}>
          Email Patient Password
        </Button>

        {isPasswordSet && <ErrorMessage isNotice message={noticeMessage} />}
      </div>

      <ConfirmationModal
        acceptText="Reset Password"
        description="This will reset the patient's password. Do you want to continue?"
        header="Are you sure?"
        isVisible={isConfirmationVisible}
        onAccept={deliverPatientPassword}
        onClose={closeConfirmationModal}
        rejectText="Cancel"
      />
    </>
  );
};

export default PatientPassword;
