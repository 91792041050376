import React from 'react';

import FileListCell from '@/components/table/FileListCell';
import { formatShortDate } from '@/utils/dateUtils';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { formatSecondsToHours } from '@/utils/timeUtils';

export const displayConfig = [
  {
    title: 'Medical Records',
    subsections: [
      {
        title: 'Medical History',
        relationship: 'medical_history',
        emptyText: 'No medical history added',
        columns: [
          {
            name: 'ICD Code',
            field: 'icd_code',
          },
          {
            name: 'Start Date',
            field: 'start_date',
            formatField: formatShortDate,
          },
          {
            name: 'End Date',
            field: 'end_date',
            formatField: formatShortDate,
          },
          {
            name: 'Comment',
            field: 'comments',
          },
        ],
      },
      {
        title: 'Surgical History',
        relationship: 'other_history',
        filter: include => include.attributes.history_type === 'surgical',
        emptyText: 'No surgical history added',
        columns: [
          {
            name: 'Description',
            field: 'description',
          },
          {
            name: 'Date',
            field: 'date',
            formatField: formatShortDate,
          },
        ],
      },
      {
        title: 'Family History',
        relationship: 'other_history',
        filter: include => include.attributes.history_type === 'family',
        emptyText: 'No family history added',
        columns: [
          {
            name: 'Description',
            field: 'description',
          },
          {
            name: 'Date',
            field: 'date',
            formatField: formatShortDate,
          },
        ],
      },
      {
        title: 'Social History',
        relationship: 'other_history',
        filter: include => include.attributes.history_type === 'social',
        emptyText: 'No other history added',
        columns: [
          {
            name: 'Description',
            field: 'description',
          },
          {
            name: 'Date',
            field: 'date',
            formatField: formatShortDate,
          },
        ],
      },
    ],
  },
  {
    title: 'Medications',
    subsections: [
      {
        title: 'Current Medications',
        relationship: 'medication',
        filter: include => include.attributes.status === 'current',
        emptyText: 'No current medications added',
        columns: [
          {
            name: 'Medication Name',
            field: 'name',
          },
          {
            name: 'Directions',
            field: 'directions',
          },
          {
            name: 'Quantity',
            field: 'quantity',
          },
          {
            name: 'Date Started',
            field: 'start_date',
            formatField: formatShortDate,
          },
        ],
      },
      {
        title: 'Previous Medications',
        relationship: 'medication',
        filter: include => include.attributes.status === 'previous',
        emptyText: 'No previous medications added',
        columns: [
          {
            name: 'Medication Name',
            field: 'name',
          },
          {
            name: 'Directions',
            field: 'directions',
          },
          {
            name: 'Quantity',
            field: 'quantity',
          },
          {
            name: 'Date Started',
            field: 'start_date',
            formatField: formatShortDate,
          },
          {
            name: 'Date Ended',
            field: 'end_date',
            formatField: formatShortDate,
          },
        ],
      },
    ],
  },
  {
    title: 'Allergies',
    subsections: [
      {
        relationship: 'allergy',
        emptyText: 'No allergies added',
        columns: [
          {
            name: 'Source',
            field: 'source',
          },
          {
            name: 'Type',
            field: 'allergy_type',
            formatField: titleizeSnakeCase,
          },
          {
            name: 'Allergy',
            field: 'name',
          },
          {
            name: 'Severity',
            field: 'severity',
          },
          {
            name: 'End Date',
            field: 'end_date',
            formatField: formatShortDate,
          },
          {
            name: 'Instructions',
            field: 'instructions',
          },
        ],
      },
    ],
  },
  {
    title: 'Labs',
    subsections: [
      {
        title: 'Labs',
        relationship: 'lab',
        emptyText: 'No labs added',
        columns: [
          {
            name: 'Test Type',
            field: 'test_type',
          },
          {
            name: 'Test Date',
            field: 'test_date',
            formatField: formatShortDate,
          },
          {
            name: 'Result',
            field: 'result',
          },
          {
            name: 'Remarks',
            field: 'remarks',
          },
          {
            name: 'File',
            field: 'scan_files',
            formatField: value => <FileListCell files={value} />,
          },
        ],
      },
      {
        title: 'Screenings',
        relationship: 'screeing',
        emptyText: 'No screenings added',
        columns: [
          {
            name: 'Screening',
            field: 'screening_type',
          },
          {
            name: 'Last Date',
            field: 'last_date',
            formatField: formatShortDate,
          },
          {
            name: 'Next Date',
            field: 'next_date',
            formatField: formatShortDate,
          },
          {
            name: 'Comments',
            field: 'comments',
          },
        ],
      },
    ],
  },
  {
    title: 'Appointments',
    subsections: [
      {
        title: 'Upcoming Appointments',
        relationship: 'appointment',
        filter: include => !include.attributes.is_conducted,
        emptyText: 'No appointments added',
        columns: [
          {
            name: 'Provider Name',
            field: 'specialist_name',
          },
          {
            name: 'Date',
            field: 'date',
            formatField: formatShortDate,
          },
          {
            name: 'Facility',
            field: 'facility',
          },
          {
            name: 'Reason',
            field: 'reason',
          },
          {
            name: 'Summary',
            field: 'summary',
          },
        ],
      },
      {
        title: 'Past Appointments',
        relationship: 'appointment',
        filter: include => include.attributes.is_conducted,
        emptyText: 'No appointments added',
        columns: [
          {
            name: 'Provider Name',
            field: 'specialist_name',
          },
          {
            name: 'Date',
            field: 'date',
            formatField: formatShortDate,
          },
          {
            name: 'Facility',
            field: 'facility',
          },
          {
            name: 'Reason',
            field: 'reason',
          },
          {
            name: 'Summary',
            field: 'summary',
          },
        ],
      },
    ],
  },
  {
    title: 'Immunizations',
    subsections: [
      {
        relationship: 'immunization',
        emptyText: 'No immunizations added',
        columns: [
          {
            name: 'Immunization',
            field: 'name',
          },
          {
            name: 'Last Date',
            field: 'last_date',
            formatField: formatShortDate,
          },
          {
            name: 'Next Date',
            field: 'next_date',
            formatField: formatShortDate,
          },
          {
            name: 'Comments',
            field: 'comments',
          },
        ],
      },
    ],
  },
  {
    title: 'Specialists',
    subsections: [
      {
        relationship: 'specialist',
        emptyText: 'No specialists added',
        columns: [
          {
            name: 'Provider Name',
            field: 'name',
          },
          {
            name: 'Speciality',
            field: 'specialty',
          },
          {
            name: 'Phone Number',
            field: 'phone_number',
            formatField: formatPhoneNumber,
          },
        ],
      },
    ],
  },
  {
    title: 'Agreements',
    subsections: [
      {
        title: 'Condition Complexities',
        relationship: 'condition_complexity',
        emptyText: 'No agreements added',
        columns: [
          {
            name: 'Activity Date',
            field: 'date',
            formatField: formatShortDate,
          },
          {
            name: 'Time Spent',
            field: 'duration',
            formatField: formatSecondsToHours,
          },
          {
            name: 'Provider',
            field: 'specialist_name',
          },
          {
            name: 'Complexity',
            field: 'cpt_codes',
            formatField: entries => {
              return entries.map(entry => (
                <span className="display-table__block" key={entry.code}>
                  {entry.code}
                </span>
              ));
            },
          },
        ],
      },
      {
        title: 'Consent',
        relationship: 'consent_agreement',
        emptyText: 'No agreements added',
        columns: [
          {
            name: 'Date',
            field: 'date',
            formatField: formatShortDate,
          },
          {
            name: 'Relation to Patient',
            field: 'relation_to_patient',
          },
        ],
      },
    ],
  },
];
