import React from 'react';

interface Props {
  onChange: (value: string) => void;
  value: string;
}

const FreeTextResponse = ({ onChange, value }: Props): JSX.Element => {
  return (
    <div className="questionnaire-question__text">
      <textarea
        onChange={event => onChange(event.target.value)}
        value={value}
      />
    </div>
  );
};

export default FreeTextResponse;
