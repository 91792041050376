import { UUID } from '@/types/generic';
import {
  TrackingQuestionAttributes,
  TrackingQuestionSet,
} from '@/types/tracking_question';
import React from 'react';
import ImplicationDisplay from './ImplicationDisplay';
import TrackingAnswerRow from './TrackingAnswerRow';
import { TrackingAnswer } from './TrackingQuestionManager';

interface Props {
  answers: TrackingAnswer[];
  onSetAnswerValue: (questionId: UUID, value: number) => void;
  questionSet: TrackingQuestionSet;
}

const TrackingQuestionSetDisplay = (props: Props) => {
  const { answers, onSetAnswerValue, questionSet } = props;

  return (
    <div className="tracking-display">
      <h4>{questionSet.attributes.description}</h4>
      {questionSet.attributes.details && (
        <p>{questionSet.attributes.details}</p>
      )}

      <div className="tracking-display__answers">
        {questionSet.attributes.tracking_questions.map(
          (question: TrackingQuestionAttributes) => (
            <TrackingAnswerRow
              answers={answers}
              key={question.id}
              onSetAnswerValue={onSetAnswerValue}
              question={question}
            />
          ),
        )}
      </div>

      <ImplicationDisplay answers={answers} questionSet={questionSet} />
    </div>
  );
};

export default TrackingQuestionSetDisplay;
