import React from 'react';

import { VirtualAppointment } from '@/types/v2/virtual_appointment';

interface Props {
  appointment: VirtualAppointment;
}

const AppointmentProvider = ({ appointment }: Props): JSX.Element => {
  return (
    <>
      <div className="columns">
        <div className="column">
          <h4 className="modal__section-title">Provider Details</h4>

          <ul className="modal__text-list">
            {/* TODO: Link to provider details in card ch15391 */}
            <li>
              Provider:{' '}
              {appointment.admin_user?.specialist.name || 'Not Selected'}
            </li>
            <li>Practice: {appointment.practice.name}</li>
            <li>
              Specialty: {appointment.admin_user?.specialist.specialty || 'N/A'}
            </li>
            {/* TODO: Add rate in card ch15422 */}
            <li>Rate: -</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AppointmentProvider;
