import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '@/api/Api';
import { parseResponseData } from '@/utils/apiUtils';
import {
  BhiActions,
  FetchCurrentCarePlan,
  fetchCurrentCarePlanFailure,
  FetchCurrentCarePlanFailure,
  fetchCurrentCarePlanSuccess,
} from '../reducers/bhi';
import { flashError } from './messageSaga';

function* fetchCurrentCarePlanSaga(action: FetchCurrentCarePlan) {
  try {
    const id = action.payload.id;

    if (id) {
      const url = `api/v1/care_plans/${id}`;
      const response = yield call(Api.utility.get, url);

      const carePlan = parseResponseData(response);

      yield put(fetchCurrentCarePlanSuccess(carePlan));
    } else {
      yield put(fetchCurrentCarePlanSuccess(null));
    }
  } catch (err) {
    yield put(fetchCurrentCarePlanFailure());
  }
}

function* fetchCurrentCarePlanFailureSaga(action: FetchCurrentCarePlanFailure) {
  yield put(flashError('Unable to get care plan'));
}

export default function* bhiSaga() {
  yield takeLatest(
    BhiActions.FETCH_CURRENT_CARE_PLAN,
    fetchCurrentCarePlanSaga,
  );
  yield takeLatest(
    BhiActions.FETCH_CURRENT_CARE_PLAN_FAILURE,
    fetchCurrentCarePlanFailureSaga,
  );
}
