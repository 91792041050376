import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { flashError } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Label from '@/components/form/Label';
import { SideEffect } from '@/types/generic';
import {
  fileToBase64,
  isFileSizeUnderLimit,
  maxFileSize,
} from '@/utils/fileUtils';
import { randomString } from '@/utils/stringUtils';
import ActivityIndicator from '../activity/ActivityIndicator';
import ErrorMessage from './ErrorMessage';

interface Props {
  fileType?: string;
  isInvalid?: boolean;
  label: string;
  onChange?: SideEffect;
  validationMessage?: string;
  initialUrl?: string;
}

const ImagePreviewUpload = (props: Props) => {
  const {
    fileType,
    isInvalid,
    label,
    onChange,
    validationMessage,
    initialUrl,
  } = props;

  const [inputId] = useState(randomString());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>(initialUrl || '');

  const dispatch = useDispatch();

  const addFile = async file => {
    setIsLoading(true);

    try {
      const data = await fileToBase64(file);

      const newFile = {
        data,
        id: randomString(),
        name: file.name,
      };

      const body = {
        preview_image: {
          image: newFile,
        },
      };

      const response = await Api.utility.post('api/v1/preview_images', body);

      const url = response.data.data.attributes.image_thumbnail.url;

      setPreviewUrl(url);

      if (onChange) {
        onChange(response.data.data.id);
      }

      setIsLoading(false);
    } catch (_err) {
      setIsLoading(false);
    }
  };

  const handleChangeFile = e => {
    const selectedFiles = e.target.files;

    if (selectedFiles) {
      for (const file of selectedFiles) {
        if (isFileSizeUnderLimit(file)) {
          addFile(file);
        } else {
          dispatch(flashError(`File size cannot exceed ${maxFileSize}`));
        }
      }
    }
  };

  const renderButton = () => {
    if (previewUrl) {
      return (
        <label className="form-file-upload__link" htmlFor={inputId}>
          Replace Logo
        </label>
      );
    }

    return (
      <label className="form-file-upload__button" htmlFor={inputId}>
        Upload
      </label>
    );
  };

  const renderPreview = () => {
    if (previewUrl) {
      return (
        <div className="form-file-upload__image">
          <img src={previewUrl} />
        </div>
      );
    } else if (isLoading) {
      return (
        <div className="form-file-upload__activity">
          <ActivityIndicator />
        </div>
      );
    }
  };

  return (
    <div className="form-file-upload">
      <Label isInvalid={isInvalid}>{label}</Label>
      {renderPreview()}
      <div className="form-file-upload__content">
        <div className="form-file-upload__input-image">
          {renderButton()}
          <input
            accept={fileType}
            disabled={isLoading}
            onChange={handleChangeFile}
            id={inputId}
            type="file"
          />
        </div>
      </div>
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </div>
  );
};

export default ImagePreviewUpload;
