import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import { selectBrandLogo } from '@/selectors/brand';
import { getCurrentUser } from '@/selectors/users';
import { User } from '@/types/user';
import { Routes } from '@/utils/routeUtils';
import DesktopHeaderLinks from './DesktopHeaderLinks';

interface Props {
  user?: User;
}

const DesktopHeader = (props: Props) => {
  const user = props.user || useSelector(getCurrentUser);

  const LOGO = useSelector(selectBrandLogo);

  const renderLinks = () => {
    if (user) {
      return <DesktopHeaderLinks />;
    } else {
      return (
        <div className="header__nav-link">
          <NavLink className="header__nav-item" to={Routes.root}>
            Log In
          </NavLink>
        </div>
      );
    }
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header__nav">
          <div className="header__brand">
            <Link className="header__nav-item" to={Routes.root}>
              <img className="header__brand-image" src={LOGO} />
            </Link>
          </div>

          {renderLinks()}
        </div>
      </div>
    </header>
  );
};

export default DesktopHeader;
