import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import { Routes } from '@/utils/routeUtils';
import MedicationsRequestNew from './MedicationsRequestNew';

const Medications = () => {
  return (
    <Protected allowedRoles={PolicyGroups.nonBillingAny}>
      <Switch>
        <Route
          path={Routes.medicationsRequestNew}
          component={MedicationsRequestNew}
        />
      </Switch>
    </Protected>
  );
};

export default Medications;
