import React from 'react';
import { Link } from 'react-router-dom';

import Constraint from '@/components/constraint/Constraint';
import Panel from '@/components/panel/Panel';
import { Questionnaire } from '@/types/v2/questionnaire';
import { buildPath, Routes } from '@/utils/routeUtils';
import QuestionDisplay from './QuestionDisplay';

interface Props {
  constraintSize?: 'medium' | 'large';
  type?: 'specialist' | 'practice';
  questionnaire: Questionnaire;
}

const QuestionnaireDisplay = ({
  constraintSize = 'medium',
  type = 'specialist',
  questionnaire,
}: Props): JSX.Element => {
  const renderQuestions = (() => {
    return questionnaire.questionnaire_questions.map(question => (
      <QuestionDisplay key={question.id} question={question} />
    ));
  })();

  const editPath = (() => {
    if (type === 'practice') {
      return buildPath(Routes.provider.practice.show.questionnaires.edit, {
        questionnaire_id: questionnaire.id,
      });
    }

    return buildPath(Routes.provider.questionnaires.edit, {
      questionnaire_id: questionnaire.id,
    });
  })();

  let title = questionnaire.name;
  if (questionnaire.status === 'default') {
    title = `${title} (Default)`;
  }

  return (
    <Panel>
      <Constraint size={constraintSize}>
        <div className="questionnaire-display">
          <div className="columns">
            <div className="column is-10">
              <h4>{title}</h4>
            </div>

            <div className="column is-2">
              <div className="is-justified-right-tablet">
                <Link to={editPath}>Edit</Link>
              </div>
            </div>
          </div>

          {renderQuestions}
        </div>
      </Constraint>
    </Panel>
  );
};

export default QuestionnaireDisplay;
