import { ApiState } from '@/types/api';

const initialState: ApiState = {
  answer_sets: { data: [] },
  barriers: { data: [] },
  billing_codes: { data: [] },
  caller_ids: { data: [] },
  conditions: { data: [], included: {} },
  condition_groups: { data: [], included: {} },
  ccm_complexities: { data: [] },
  device_categories: { data: [] },
  device_makes: { data: [] },
  device_names: { data: [] },
  device_types: { data: [] },
  goals: { data: [] },
  icd_codes: { data: [] },
  immunization_names: { data: [] },
  manual_entry_reasons: { data: [] },
  metrics: { data: [] },
  outcomes: { data: [] },
  protocols: { data: [] },
  screening_types: { data: [] },
  symptoms: { data: [] },
  selectedPatient: null,
  selectedPatientLastCall: null,
  selectedPatientCalls: { data: [] },
  services: { data: [] },
  support_options: { data: [] },
  tracking_question_sets: { data: [] },
};

interface Payload {
  data: any;
  options: {
    key: string;
  };
}

interface Action {
  type: string;
  payload: Payload;
}

export type ApiAction = { type: 'API_FETCH_SUCCESS'; payload: Payload };

export const apiAction = (key: string, data: any): ApiAction => ({
  type: 'API_FETCH_SUCCESS',
  payload: {
    data,
    options: {
      key,
    },
  },
});

export default function reducer(
  state: ApiState = initialState,
  action: Action,
): ApiState {
  const { type, payload } = action;

  switch (type) {
    case 'API_FETCH_SUCCESS':
      return {
        ...state,
        [payload.options.key]: payload.data,
      };

    case 'API_POST_SUCCESS_ADD':
      return {
        ...state,
        [payload.options.key]: [...state[payload.options.key], payload.data],
      };

    default:
      return state;
  }
}
