export const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const isFileSizeUnderLimit = file => {
  const maxSize = 5000000;

  if (file.size > maxSize) {
    return false;
  }

  return true;
};

export const maxFileSize = '5 MB';
