import React from 'react';
import { Link } from 'react-router-dom';

import AddressBlock from '@/components/addresses/AddressBlock';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { Practice } from '@/types/v2/practice';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { buildPath, Routes } from '@/utils/routeUtils';

interface Props {
  practice: Practice;
}

const PracticeHeader = ({ practice }: Props): JSX.Element => {
  if (!practice) {
    return null;
  }

  const editLink = buildPath(Routes.provider.practice.edit, {
    practice_id: practice.id,
  });

  const renderAddresses = (() => {
    if (!practice.addresses.length) {
      return null;
    }

    return practice.addresses.map(address => (
      <div className="columns" key={address.id}>
        <div className="column">
          <span className="practice-details__address">
            <AddressBlock address={address} />
          </span>
        </div>
      </div>
    ));
  })();

  return (
    <>
      <Panel>
        <Container>
          <div className="practice-details">
            <div className="columns">
              <div className="column">
                <h2 className="practice-details__name">{practice.name}</h2>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <ul className="practice-details__systems">
                  <li>{practice.hospital.name}</li>
                  <li>{practice.health_system.name}</li>
                </ul>
              </div>
            </div>

            {renderAddresses}

            <div className="columns">
              <div className="column">
                <div className="practice-details__contact">
                  {formatPhoneNumber(practice.twillio_phone_number)}
                  <br />
                  {practice.contact_email}
                </div>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <Link className="practice-details__edit-link" to={editLink}>
                Edit Details
              </Link>
            </div>
          </div>
        </Container>
      </Panel>
    </>
  );
};

export default PracticeHeader;
