import { createSelector } from 'reselect';

import { ApiState } from '@/types/api';
import { AppState } from '@/types/app';
import { semanticJoin } from '@/utils/stringUtils';
import { getCcmServiceId } from './options';

export const getApiState = (state: AppState): ApiState => state.data;

export const getSelectedPatient = createSelector(getApiState, state => {
  return state.selectedPatient?.data;
});

export const selectSelectedPatientId = createSelector(
  getSelectedPatient,
  patient => patient?.id,
);

export const selectSelectedPatientPracticeId = createSelector(
  getSelectedPatient,
  patient => patient?.attributes.practice.id,
);

export const selectSelectedPatientCalls = createSelector(getApiState, state => {
  return state.selectedPatientCalls?.data;
});

export const selectSelectedLastCall = createSelector(getApiState, state => {
  return state.selectedPatientLastCall;
});

export const getPatientIncludedData = createSelector(getApiState, state => {
  return state.selectedPatient?.included;
});

export const getPatientSpecialists = createSelector(
  getPatientIncludedData,
  state => {
    if (!state) {
      return [];
    }

    return state.filter(entry => entry.type === 'specialist');
  },
);

export const getPatientSpecialistsOptions = createSelector(
  getPatientSpecialists,
  state => {
    return state.map(specialist => {
      return {
        label: specialist.attributes.name,
        value: specialist.id,
      };
    });
  },
);

export const getSelectedPatientIncluded = createSelector(getApiState, state => {
  return state.selectedPatient?.included;
});

export const getSelectPatientCcmConditions = createSelector(
  [getSelectedPatientIncluded, getCcmServiceId],
  (state, serviceId) => {
    return [
      ...state.filter(
        include =>
          include.type === 'condition' &&
          include.attributes.service_id === serviceId,
      ),
      'general',
    ];
  },
);

export const getSelectedPatientCareManager = createSelector(
  getSelectedPatientIncluded,
  state => {
    if (!state) {
      return {};
    }
    return state.find(include => include.type === 'care_manager_index') || {};
  },
);

export const getSelectPatientConditionIds = createSelector(
  getSelectPatientCcmConditions,
  state => {
    return state.map(condition => condition.id);
  },
);

export const getSelectedPatientEnrollment = createSelector(
  getSelectedPatientIncluded,
  state => {
    return state.find(include => include.type === 'patient_enrollment') || {};
  },
);

export const getSelectedPatientEnrollmentStatus = createSelector(
  getSelectedPatientEnrollment,
  state => {
    return state.attributes ? state.attributes.status : null;
  },
);

export const getHasPatientConsented = createSelector(
  getSelectedPatientEnrollment,
  state => {
    const e = state.attributes;
    return !!(
      e.patient_agreed_at &&
      e.patient_enrollment_date &&
      e.patient_verified_understanding_at
    );
  },
);

export const getIsConditionSelected = createSelector(
  getSelectedPatientEnrollment,
  state => {
    return !!state.attributes.conditions_completed_at;
  },
);

export const selectSelectedPatientServiceNames = createSelector(
  getSelectedPatient,
  patient =>
    patient.attributes.patient_services.data.map(
      service => service.attributes.name,
    ),
);

export const selectIsSelectedPatientEnrolledBhi = createSelector(
  selectSelectedPatientServiceNames,
  services => services.includes('BHI'),
);

export const selectIsSelectedPatientEnrolledCcm = createSelector(
  selectSelectedPatientServiceNames,
  services => services.includes('CCM'),
);

export const selectIsSelectedPatientEnrolledRpm = createSelector(
  selectSelectedPatientServiceNames,
  services => services.includes('RPM'),
);

export const selectIsSelectedPatientEnrolledStandard = createSelector(
  [selectIsSelectedPatientEnrolledCcm, selectIsSelectedPatientEnrolledRpm],
  (isEnrolledCcm, isEnrolledRpm) => isEnrolledCcm || isEnrolledRpm,
);

export const selectSelectedPatientProgramText = createSelector(
  selectSelectedPatientServiceNames,
  services => {
    return services.length === 1
      ? `${services[0]} program`
      : `${semanticJoin(services)} programs`;
  },
);

export const selectSelectedPatientServiceText = createSelector(
  selectSelectedPatientServiceNames,
  services => {
    return services.length === 1
      ? `${services[0]} Service`
      : `${semanticJoin(services)} Services`;
  },
);
