import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import ModalContent from '@/components/modal/ModalContent';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { formatShortDate } from '@/utils/dateUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import Modeler from '@/utils/modeler/modeler';
import useNotifier from '@/utils/messages/useNotifier';
import Api from '@/api/Api';
import { PatientDevice } from '@/types/v2/patient_device';
import { Device } from '@/types/v2/device';

interface Props {
  deviceId: string;
}

const DevicesModal = (props: Props) => {
  const { deviceId } = props;

  const dispatch = useDispatch();
  const notifier = useNotifier();

  const [device, setDevice] = useState(null);
  const [patientDevice, setPatientDevice] = useState<PatientDevice>();

  const getDevice = async () => {
    try {
      const url = buildPath(Routes.api2.patientDevice, { id: deviceId }, {}, [
        'device',
        'device.device_type',
        'device.device_category',
      ]);

      const response = await Api.utility.get(url);

      const resource = new Modeler<any>(response.data, {
        generations: 2,
      }).build();

      setPatientDevice(resource);
      setDevice(resource.device);
    } catch (err) {
      notifier.error(err);
    }
  };

  useEffect(() => {
    if (deviceId) {
      getDevice();
    }
  }, [deviceId]);

  if (!device) {
    return <ActivityIndicator />;
  }

  return (
    <div className="patient-remote-care__modal-wrapper">
      <h3 className="patient-remote-care__modal-header">Device</h3>
      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Device Category"
            value={device.device_category.name}
          />
        </div>
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent label="Device Type" value={device.device_type.name} />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent label="Device Make" value={device.device_make.name} />
        </div>
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent label="Device Name" value={device.device_name.name} />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent label="Device Model" value={device.device_model.name} />
        </div>
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent label="Serial Number" value={device.serial_number} />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Issue Date"
            value={
              patientDevice?.issue_date
                ? formatShortDate(patientDevice.issue_date)
                : formatShortDate(device.issue_date)
            }
          />
        </div>
        {/* This is for sim Number in devise modal */}
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent label="Sim Number" value={device.sim_number?device.sim_number:" "} />
        </div>
      </div>
    </div>
  );
};

export default DevicesModal;
