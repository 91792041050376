import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import { Routes } from '@/utils/routeUtils';
import MedicationsPortalIndex from './MedicationsPortalIndex';
import PortalContent from './PortalContent';
import PortalEdit from './PortalEdit';
import PortalLayout from './PortalLayout';
import PortalNew from './PortalNew';

const Admin = () => {
  return (
    <Protected allowedRoles={PolicyGroups.atLeastSuperAdmin}>
      <Switch>
        <Route path={Routes.adminResourceNew} component={PortalNew} />
        <Route path={Routes.adminResourceEdit} component={PortalEdit} />
        <Route path="/admin/medication_names">
          <PortalLayout>
            <MedicationsPortalIndex />
          </PortalLayout>
        </Route>
        <Route path="/admin/:resource">
          <PortalLayout>
            <PortalContent />
          </PortalLayout>
        </Route>
      </Switch>
    </Protected>
  );
};

export default Admin;
