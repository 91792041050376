import { UUID } from '@/types/generic';
import { Response } from '@/types/response';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';

export enum VideoCallsActions {
  GENERATE_TOKEN = 'VIDEO_CALLS/GENERATE_TOKEN',
  GENERATE_TOKEN_SUCCESS = 'VIDEO_CALLS/GENERATE_TOKEN_SUCCESS',
  GENERATE_TOKEN_FAILURE = 'VIDEO_CALLS/GENERATE_TOKEN_FAILURE',
  AUTO_JOIN_CALL = 'VIDEO_CALLS/AUTO_JOIN_CALL',
}

export interface GenerateToken {
  type: typeof VideoCallsActions.GENERATE_TOKEN;
  payload: {
    appointment: VirtualAppointment;
  };
}

export interface GenerateTokenSuccess {
  type: typeof VideoCallsActions.GENERATE_TOKEN_SUCCESS;
  payload: {
    token: string;
  };
}

export interface GenerateTokenFailure {
  type: typeof VideoCallsActions.GENERATE_TOKEN_FAILURE;
  payload: {
    error: Response<any>;
  };
}

export interface AutoJoinCall {
  type: typeof VideoCallsActions.AUTO_JOIN_CALL;
  payload: {
    id: UUID;
  };
}

export type VideoCallsAction =
  | GenerateToken
  | GenerateTokenSuccess
  | GenerateTokenFailure
  | AutoJoinCall;

export const generateToken = (
  appointment: VirtualAppointment,
): VideoCallsAction => ({
  type: VideoCallsActions.GENERATE_TOKEN,
  payload: { appointment },
});

export const generateTokenSuccess = (token: string): VideoCallsAction => ({
  type: VideoCallsActions.GENERATE_TOKEN_SUCCESS,
  payload: { token },
});

export const generateTokenFailure = (
  error: Response<any>,
): VideoCallsAction => ({
  type: VideoCallsActions.GENERATE_TOKEN_FAILURE,
  payload: { error },
});

export const autoJoinCall = (id: UUID): VideoCallsAction => ({
  type: VideoCallsActions.AUTO_JOIN_CALL,
  payload: { id },
});
