import React from 'react';

import InputIcon from '@/components/form/InputIcon';
import SelectGroup from '@/components/form/SelectGroup';
import { perPageOptions } from '@/utils/tableUtils';

export interface FormState {
  search: string;
  per: string;
}

interface Props {
  form: FormState;
  onChangeForm: (field: string, value: string) => void;
  onChangeSearch: (value: string) => void;
  onClearFilters: () => void;
  onStartSearch: () => void;
  searchQuery: string;
}

const SearchHeader = (props: Props): JSX.Element => {
  const {
    form,
    onChangeForm,
    onChangeSearch,
    onClearFilters,
    onStartSearch,
    searchQuery,
  } = props;

  const renderSearchDisplay = () => {
    const activeFilters: string[] = [];

    for (const key of Object.keys(form)) {
      if (form[key]) {
        if (key === 'search') {
          activeFilters.push(`'${form[key]}'`);
        }
      }
    }

    if (!activeFilters.length) {
      return null;
    }

    const filterList = activeFilters.join(', ');

    return (
      <div className="columns">
        <div className="column">
          <div className="filter-header__clear">
            <span>Search Results for: {filterList}</span>
            <a onClick={onClearFilters}>Clear search</a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="columns is-mobile">
        <div className="column is-6">
          <h2 className="patients-index__header">Patients</h2>
        </div>

        <div className="column is-6">
          <InputIcon
            onChange={value => onChangeSearch(value)}
            onClick={onStartSearch}
            onPressEnter={onStartSearch}
            placeholder="Search"
            value={searchQuery}
          />
        </div>
      </div>

      {renderSearchDisplay()}

      <div className="columns is-mobile filter-header__bottom">
        <div className="column is-3-tablet is-2-desktop">
          <SelectGroup
            label="Show"
            options={perPageOptions}
            value={form.per}
            onChange={(value: string) => onChangeForm('per', value)}
          />
        </div>
      </div>
    </>
  );
};

export default SearchHeader;
