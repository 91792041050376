import React from 'react';

interface Props {
  canMoveDown?: boolean;
  canMoveUp?: boolean;
  onChangePosition: (direction: PositionDirection) => void;
}

export type PositionDirection = 1 | -1;

const PositionControl = ({
  canMoveDown,
  canMoveUp,
  onChangePosition,
}: Props): JSX.Element => {
  return (
    <div className="questionnaire-form-position">
      {canMoveUp && (
        <a
          className="questionnaire-action"
          onClick={() => onChangePosition(-1)}>
          Move Up
        </a>
      )}

      {canMoveDown && (
        <a className="questionnaire-action" onClick={() => onChangePosition(1)}>
          Move Down
        </a>
      )}
    </div>
  );
};

export default PositionControl;
