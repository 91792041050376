import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FormComponentConfig } from '@/types/form';
import { Attributable, UUID } from '@/types/generic';
import { stateOptions } from '@/utils/dropdownUtils';
import { formatAddressAttributes } from '@/utils/requestUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';
import { isEnrollmentPath } from '../../utils/patientUtils';

interface Params {
  id: UUID;
  step: string;
}

const ContactsNew = () => {
  const history = useHistory();

  const { id, step } = useParams<Params>();

  const isEnrollment = isEnrollmentPath();

  const backPath = (() => {
    const queryParams = new URLSearchParams(location.search || '');
    const returnTo = queryParams.get('return_to');

    if (returnTo) {
      return returnTo;
    }

    return isEnrollment
      ? buildPath(Routes.patientsEnrollment, { id, step })
      : buildPath(Routes.patientsContacts, { id });
  })();

  const alterFormBeforeSubmit = (form: Attributable) => {
    return {
      ...formatAddressAttributes(form),
      patient_id: id,
    };
  };

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/contacts',
      model: 'contact',
      method: 'POST',
      onSuccess: () => history.push(backPath),
      alterFormBeforeSubmit,
    },
    state: {
      city: '',
      email: '',
      fax_number: '',
      line_1: '',
      line_2: '',
      name: '',
      phone_number: '',
      relation: '',
      state: '',
      zip_code: '',
    },
    rules: {
      email: { required: false, type: 'email' },
      fax_number: { required: false, type: 'phone' },
      name: { required: true },
      phone_number: { required: false, type: 'phone' },
      relation: { required: true },
    },
    layout: [
      {
        title: 'Add Contact',
        fields: [
          [
            {
              label: 'Name',
              field: 'name',
              placeholder: 'Enter contact name',
              type: 'text',
              size: 4,
            },
            {
              label: 'Relation',
              field: 'relation',
              placeholder: 'Enter relation',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Email Address',
              field: 'email',
              placeholder: 'Enter email address',
              type: 'text',
              size: 4,
            },
            {
              label: 'Phone Number',
              field: 'phone_number',
              placeholder: 'Enter phone number',
              type: 'phonenumber',
              size: 4,
            },
            {
              label: 'Fax Number',
              field: 'fax_number',
              placeholder: 'Enter fax number',
              type: 'phonenumber',
              size: 4,
            },
          ],
          [
            {
              label: 'Street Address',
              field: 'line_1',
              placeholder: 'Enter street address',
              type: 'text',
              size: 4,
            },
            {
              label: 'Address Line 2',
              field: 'line_2',
              placeholder: 'Enter address',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'City',
              field: 'city',
              placeholder: 'Enter city',
              type: 'text',
              size: 4,
            },
            {
              label: 'State',
              field: 'state',
              placeholder: 'Select state',
              type: 'select',
              options: stateOptions,
              size: 4,
            },
            {
              label: 'Zip Code',
              field: 'zip_code',
              placeholder: 'Enter zip code',
              type: 'zipcode',
              size: 4,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Contacts"
      config={config}
    />
  );
};

export default ContactsNew;
