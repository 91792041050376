import React from 'react';

import Button from '@/components/button/Button';
import InputIcon from '@/components/form/InputIcon';
import SearchGroup from '@/components/form/SearchGroup';
import SelectGroup from '@/components/form/SelectGroup';
import Modal from '@/components/modal/Modal';
import { SideEffect } from '@/types/generic';
import { userStatuses } from '@/utils/dropdownUtils';
import { perPageOptions } from '@/utils/tableUtils';
import { fullName } from '@/utils/userUtils';
import AdminDashboardFilterModal from './AdminDashboardFilterModal';
import AdminDashboardTable from './AdminDashboardTable';

interface Props {
  closeModal: SideEffect;
  mobileQueryOptions: { [key: string]: any };
  modalVisible: boolean;
  onApplyMobileClick: SideEffect;
  onFilterChange: SideEffect;
  onFilterClick: SideEffect;
  onMobileFilterChange: SideEffect;
  onPerPageChange: SideEffect;
  onSearchChange: SideEffect;
  onSearchClick: SideEffect;
  perPage: any;
  searchValue: string;
  queryOptions: { [key: string]: any };
}

const AdminDashboardActivity = (props: Props) => {
  const {
    closeModal,
    mobileQueryOptions,
    modalVisible,
    onApplyMobileClick,
    onFilterChange,
    onFilterClick,
    onMobileFilterChange,
    onPerPageChange,
    searchValue,
    onSearchChange,
    onSearchClick,
    perPage,
    queryOptions,
  } = props;

  const formatCareManagerResult = (result: any) => {
    return {
      label: fullName(result),
      value: result.id,
    };
  };

  return (
    <>
      <div className="admin-dashboard-activity admin-dashboard-panel">
        <div className="columns is-mobile admin-dashboard-activity__header-wrapper">
          <div className="column is-12-mobile is-5-tablet is-7-desktop">
            <h2>Current Activity</h2>
          </div>

          <div className="column is-12-mobile is-7-tablet is-5-desktop">
            <InputIcon
              value={searchValue}
              onChange={onSearchChange}
              placeholder="Search"
              onClick={() => onSearchClick('search', searchValue)}
              onPressEnter={() => onSearchClick('search', searchValue)}
            />
          </div>
        </div>

        <div className="columns is-mobile">
          <div className="column is-6 is-hidden-touch">
            <div className="admin-dashboard-activity__filter-wrapper">
              <div className="columns">
                <div className="column admin-dashboard-activity__filter-title">
                  <h3>Filter</h3>
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatCareManagerResult}
                    guideValue={queryOptions.care_manager_id}
                    label="Care Manager"
                    minimumInputLength={1}
                    onChange={value => onFilterChange('care_manager_id', value)}
                    placeholder="Search Care Managers"
                    searchPath="api/v1/care_managers"
                  />
                </div>

                <div className="column is-4">
                  <SelectGroup
                    label="Care Manager Status"
                    options={userStatuses}
                    value={queryOptions.status}
                    onChange={changedValue =>
                      onFilterChange('status', changedValue)
                    }
                    placeholder="Select Status"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="column is-6 is-hidden-desktop admin-dashboard-activity__filter-button-wrapper">
            <Button color="white" onClick={onFilterClick}>
              Filter
            </Button>
          </div>

          <div className="column is-6-mobile is-offset-3-tablet">
            <SelectGroup
              label="Show"
              options={perPageOptions}
              value={perPage}
              onChange={changedValue => onPerPageChange(changedValue)}
            />
          </div>
        </div>

        <AdminDashboardTable perPage={perPage} queryOptions={queryOptions} />
      </div>

      <Modal isVisible={modalVisible} onCloseModal={closeModal}>
        <AdminDashboardFilterModal
          mobileQueryOptions={mobileQueryOptions}
          onChange={onMobileFilterChange}
          onClick={onApplyMobileClick}
        />
      </Modal>
    </>
  );
};

export default AdminDashboardActivity;
