import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  getCareManagerDashboard,
  toggleDashboardCollapse,
} from '@/actions/actions/rpm';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import Constraint from '@/components/constraint/Constraint';
import {
  DashboardHeader,
  SectionRow,
  VisibleSection,
} from '@/components/dashboard';
import Panel from '@/components/panel/Panel';
import {
  hasAttemptedPatientFetch,
  selectCareManagerDailyHighAlerts,
  selectCareManagerHighAlertPatients,
  selectCareManagerLatestReadings,
  selectDashboardLastLoadedAt,
  selectHasRpmPatients,
  selectIsDashboardCollapsed,
  selectIsFetchingDashboard,
} from '@/selectors/rpm';
import { DeviceReadingLabel } from '@/types/v2/device_reading';
import { buildPath, Routes } from '@/utils/routeUtils';
import HighestDailyAlerts from './HighestDailyAlerts';
import LatestReadings from './LatestReadings';
import PatientsHighAlerts from './PatientsHighAlerts';

const latestAlertTablePath = (type: DeviceReadingLabel): string => {
  return buildPath(Routes.rpm.latest, { reading_type: type });
};

const RpmCareManager = (): JSX.Element => {
  const dispatch = useDispatch();

  const dailyHighAlerts = useSelector(selectCareManagerDailyHighAlerts);
  const dashboardLastLoadedAt = useSelector(selectDashboardLastLoadedAt);
  const hasFetchedPatients = useSelector(hasAttemptedPatientFetch);
  const hasRpmPatients = useSelector(selectHasRpmPatients);
  const highAlertPatients = useSelector(selectCareManagerHighAlertPatients);
  const isDashboardCollapsed = useSelector(selectIsDashboardCollapsed);
  const isLoadingData = useSelector(selectIsFetchingDashboard);
  const latestReadings = useSelector(selectCareManagerLatestReadings);

  const handleClickCollapse = () => {
    dispatch(toggleDashboardCollapse());
  };

  const shouldReloadDashboard = (() => {
    if (!dashboardLastLoadedAt) {
      return true;
    }

    const lastLoad = moment(dashboardLastLoadedAt);
    const now = moment();

    return now.diff(lastLoad, 'seconds') > 60;
  })();

  useEffect(() => {
    if (shouldReloadDashboard) {
      dispatch(getCareManagerDashboard());
    }
  }, []);

  if (!hasRpmPatients) {
    return null;
  }

  if (isLoadingData && !hasFetchedPatients) {
    return <ActivityIndicator />;
  }

  return (
    <Panel>
      <Constraint size="large">
        <DashboardHeader
          isCollapsed={isDashboardCollapsed}
          onClickCollapse={handleClickCollapse}>
          Dashboard
        </DashboardHeader>

        <VisibleSection isVisible={!isDashboardCollapsed}>
          <SectionRow title="Highest Daily Reading">
            <HighestDailyAlerts
              bloodGlucoseReading={dailyHighAlerts.bloodGlucose}
              bloodOxygenReading={dailyHighAlerts.bloodOxygen}
              bloodPressureReading={dailyHighAlerts.bloodPressure}
            />
          </SectionRow>

          <SectionRow
            title="Patients with High Readings This Week"
            viewLink={Routes.rpm.high}>
            <PatientsHighAlerts patients={highAlertPatients} />
          </SectionRow>

          <SectionRow
            title="Latest Blood Pressure Readings"
            viewLink={latestAlertTablePath('blood_pressure')}>
            <LatestReadings readings={latestReadings.bloodPressure} />
          </SectionRow>

          <SectionRow
            title="Latest Glucometer Readings"
            viewLink={latestAlertTablePath('blood_glucose')}>
            <LatestReadings readings={latestReadings.bloodGlucose} />
          </SectionRow>

          <SectionRow
            title="Latest Oximiter Readings"
            viewLink={latestAlertTablePath('blood_oxygen')}>
            <LatestReadings readings={latestReadings.bloodOxygen} />
          </SectionRow>

          <div className="columns is-centered">
            <div className="column is-8-tablet is-6-desktop">
              <Link
                className="button is-white is-full-width"
                to={Routes.rpm.all}>
                View All Readings
              </Link>
            </div>
          </div>
        </VisibleSection>
      </Constraint>
    </Panel>
  );
};

export default RpmCareManager;
