import {
  create,
  reduceAdminDashboardSuccess,
  reduceCareManagerDashboardSuccess,
  RpmState,
} from '@/state/rpm';
import { RpmAction, RpmActions } from '../actions/rpm';

export default function reducer(
  state: RpmState = create(),
  action: RpmAction,
): RpmState {
  switch (action.type) {
    case RpmActions.GET_CARE_MANAGER_DASHBOARD:
      return {
        ...state,
        isFetchingDashboard: true,
      };

    case RpmActions.GET_CARE_MANAGER_DASHBOARD_SUCCESS:
      return reduceCareManagerDashboardSuccess(state, action.payload.data);

    case RpmActions.GET_CARE_MANAGER_DASHBOARD_FAILURE:
      return {
        ...state,
        isFetchingDashboard: false,
      };

    case RpmActions.GET_ADMIN_DASHBOARD:
      return {
        ...state,
        isFetchingDashboard: true,
      };

    case RpmActions.GET_ADMIN_DASHBOARD_SUCCESS:
      return reduceAdminDashboardSuccess(state, action.payload.data);

    case RpmActions.GET_ADMIN_DASHBOARD_FAILURE:
      return {
        ...state,
        isFetchingDashboard: false,
      };

    case RpmActions.TOGGLE_DASHBOARD_COLLAPSE:
      return {
        ...state,
        isDashboardCollapsed: !state.isDashboardCollapsed,
      };

    default:
      return state;
  }
}
