export const colors = {
  goldenrod: '#fcba04',
  peacockBlue: '#006989',
  slateBlue: '#21597c',
};

export const themeColors = {
  calendarStandard: colors.peacockBlue,
  calendarNotice: colors.goldenrod,
};
