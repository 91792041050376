import React from 'react';

import AccessControl from '@/components/access_control/AccessControl';
import Button from '@/components/button/Button';
import SearchGroup from '@/components/form/SearchGroup';
import { PolicyGroups } from '@/components/router';
import { SideEffect } from '@/types/generic';
import { fullName } from '@/utils/userUtils';

interface Props {
  mobileQueryOptions: { [key: string]: any };
  onChange: SideEffect;
  onClick: SideEffect;
}

const CallsScheduleFilterModal = (props: Props) => {
  const { mobileQueryOptions, onChange, onClick } = props;

  const formatSearchResult = (result: any) => ({
    label: result.attributes.name,
    value: result.id,
  });

  const formatCareManagerResult = (result: any) => {
    return {
      label: fullName(result),
      value: result.id,
    };
  };

  return (
    <div className="call-schedule__modal">
      <h3 className="call-schedule__modal-header">Filter</h3>

      <div className="columns is-mobile">
        <div className="column is-6">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatSearchResult}
            guideValue={mobileQueryOptions.health_system_id}
            label="Health System"
            minimumInputLength={1}
            onChange={value => onChange('health_system_id', value)}
            placeholder="Search Health Systems"
            searchPath="api/v1/health_systems"
          />
        </div>

        <div className="column is-6">
          <SearchGroup
            clearFalseResultOnBlur
            isDisabled={!mobileQueryOptions.health_system_id}
            guideValue={mobileQueryOptions.hospital_id}
            formatResult={formatSearchResult}
            label="Hospital"
            minimumInputLength={1}
            onChange={value => onChange('hospital_id', value)}
            placeholder="Search Hospitals"
            queryParams={{
              health_system_id: mobileQueryOptions.health_system_id,
            }}
            searchPath="api/v1/hospitals"
          />
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column is-6">
          <SearchGroup
            clearFalseResultOnBlur
            isDisabled={!mobileQueryOptions.hospital_id}
            formatResult={formatSearchResult}
            guideValue={mobileQueryOptions.practice_id}
            label="Practice"
            minimumInputLength={1}
            onChange={value => onChange('practice_id', value)}
            placeholder="Search Practices"
            queryParams={{ hospital_id: mobileQueryOptions.hospital_id }}
            searchPath="api/v1/practices"
          />
        </div>

        <AccessControl allowedRoles={PolicyGroups.atLeastAdmin}>
          <div className="column is-6">
            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatCareManagerResult}
              guideValue={mobileQueryOptions.user_id}
              label="Care Manager"
              minimumInputLength={1}
              onChange={value => onChange('user_id', value)}
              placeholder="Search Care Managers"
              queryParams={{ user_id: mobileQueryOptions.user_id }}
              searchPath="api/v1/care_managers"
            />
          </div>
        </AccessControl>
      </div>

      <div className="columns is-mobile">
        <div className="column">
          <div className="call-schedule__modal-button">
            <Button color="secondary" onClick={onClick}>
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallsScheduleFilterModal;
