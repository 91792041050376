import React, { useMemo } from 'react';

import RadioList from '@/components/form/RadioList';
import { UUID } from '@/types/generic';
import { QuestionnaireAnswer } from '@/types/v2/questionnaire';
import { FormResponseAnswer } from './types';

interface Props {
  answers: QuestionnaireAnswer[];
  onSelectAnswer: (id: UUID) => void;
  selectedAnswer: FormResponseAnswer;
}

const SingleAnswerResponse = ({
  answers,
  onSelectAnswer,
  selectedAnswer,
}: Props): JSX.Element => {
  const options = useMemo(() => {
    return answers.map(answer => ({
      label: answer.text,
      value: answer.id,
    }));
  }, []);

  return (
    <div className="questionnaire-question__single-answer">
      <RadioList
        onChange={onSelectAnswer}
        options={options}
        value={selectedAnswer.questionnaire_answer_id}
      />
    </div>
  );
};

export default SingleAnswerResponse;
