import { Nameable, Serialized } from '@/types/generic';
import { titleizeSnakeCase } from './stringUtils';

export const fullName = (user: Serialized<Nameable> | Nameable): string => {
  if (!user) {
    return '';
  }

  // @ts-ignore
  const userAttributes = user.attributes || user;

  return [userAttributes.first_name, userAttributes.last_name].join(' ');
};

const statusDescriptions = ['Active', 'Deactivated'];

export const userStatusEnumOptions = statusDescriptions.map(
  (description: string, index: number) => ({
    label: description,
    value: index.toString(),
  }),
);

export const displayUserStatus = (status: number | string = 0) => {
  if (typeof status === 'string') {
    return titleizeSnakeCase(status);
  }

  if (status < 0 || status > 8) {
    throw new Error('Invalid status option');
  }

  return statusDescriptions[status];
};
