import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '@/utils/routeUtils';

interface Props {
  onPressLink: () => void;
}

export const SpecialistLinks = ({ onPressLink }: Props) => {
  return (
    <>
      <Link
        className="header__nav-dropdown-content"
        to={Routes.provider.patients.root}
        onClick={onPressLink}>
        Patients
      </Link>

      <Link
        className="header__nav-dropdown-content"
        to={Routes.provider.appointments.root}
        onClick={onPressLink}>
        Appointments
      </Link>

      <Link
        className="header__nav-dropdown-content"
        to={Routes.provider.alerts.root}
        onClick={onPressLink}>
        Alerts
      </Link>

      <Link
        className="header__nav-dropdown-content"
        to={Routes.provider.practice.show.providers}
        onClick={onPressLink}>
        Practice
      </Link>
    </>
  );
};

export default SpecialistLinks;
