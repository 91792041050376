import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import BackLink from '@/components/button/BackLink';
import Container from '@/components/container/Container';
import { Form } from '@/components/form/Form';
import { formatModelName } from '@/components/form/SearchGroup';
import Page from '@/components/page/Page';
import { FormComponentConfig } from '@/types/form';
import { UUID } from '@/types/generic';
import { adminUserRoleOptions } from '@/types/v2/admin_user';
import useNotifier from '@/utils/messages/useNotifier';
import { buildPath, formAction, Routes } from '@/utils/routeUtils';

const formState = {
  email: '',
  first_name: '',
  last_name: '',
  practice_id: '',
  role: '',
  specialist_id: '',
};

type FormState = typeof formState;
type Body = {
  admin_user: FormState;
};

type Params = {
  user_id?: UUID;
};

const backPath = buildPath(Routes.externalUsers.root);

const ExternalUsersForm = () => {
  const history = useHistory();
  const notifier = useNotifier();
  const { user_id } = useParams<Params>();

  const isEditForm = !!user_id;

  const path = (() => {
    if (isEditForm) {
      buildPath(Routes.api2.adminUser, { id: user_id });
    }

    return Routes.api2.adminUsers;
  })();

  const handleSuccess = () => {
    notifier.success('User invited');
    history.push(backPath);
  };

  const handleCancel = () => {
    history.push(backPath);
  };

  const alterBodyBeforeSubmit = (state: Body): any => {
    const { role, ...fields } = state.admin_user;

    return {
      admin_user: fields,
      role,
    };
  };

  const config: FormComponentConfig = {
    actions: {
      path,
      alterBodyBeforeSubmit,
      model: 'admin_user',
      method: formAction(user_id),
      onSuccess: handleSuccess,
      onCancel: handleCancel,
    },
    state: formState,
    rules: {
      email: { required: true, type: 'email' },
      first_name: { required: true },
      last_name: { required: true },
      practice_id: { required: true },
      role: { required: true },
    },
    layout: [
      {
        title: 'User Details',
        renderPanel: true,
        fields: [
          [
            {
              label: 'First Name',
              field: 'first_name',
              placeholder: 'Enter first name',
              type: 'text',
              size: 4,
            },
            {
              label: 'Last Name',
              field: 'last_name',
              placeholder: 'Enter last name',
              type: 'text',
              size: 4,
            },
            {
              label: 'Email Address',
              field: 'email',
              placeholder: 'Enter email address',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Role',
              field: 'role',
              placeholder: 'Select role',
              type: 'select',
              options: adminUserRoleOptions,
              size: 4,
            },
            {
              label: 'Practice',
              field: 'practice_id',
              placeholder: 'Select practice',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: Routes.api.practices,
              size: 4,
              formatTypeaheadResult: formatModelName,
              shouldUseModeler: true,
            },
            {
              label: 'Link Provider (optional)',
              field: 'specialist_id',
              placeholder: 'Link to existing provider',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: Routes.api2.specialists,
              queryParams: {
                filter: 'without_profile_link',
              },
              size: 4,
              formatTypeaheadResult: formatModelName,
              ignoreIf: (state: FormState) => state.role !== 'provider',
              shouldUseModeler: true,
            },
          ],
        ],
      },
    ],
  };

  return (
    <Page>
      <Container>
        <BackLink to={backPath}>Cancel</BackLink>
      </Container>

      <Form config={config} />
    </Page>
  );
};

export default ExternalUsersForm;
