import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { Tab, Tabs } from '@/components/tabs';
import { getPatientSpecialists, getSelectedPatient } from '@/selectors/api';
import { TableConfig } from '@/types/table';
import { formatShortDate } from '@/utils/dateUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import PatientLayout from '../../components/PatientLayout';

const SEARCH_PATH = 'api/v1/appointments';

const AppointmentsIndex = () => {
  const patient = useSelector(getSelectedPatient);

  const [_query, initialTab] = returnTabQuery();

  const [selectedTab, setSelectedTab] = useState<string>(
    initialTab || 'upcoming',
  );

  const query = { return_tab: selectedTab };

  const specialists = useSelector(getPatientSpecialists);

  const formatStatus = status => {
    if (status.is_conducted) {
      return 'Conducted';
    }

    return 'Not conducted';
  };

  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      patient_id: patient.id,
      type: selectedTab,
    },
    emptyState: 'No appointments added yet',
    columns: [
      {
        header: 'Provider Name',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'specialist_name',
        // formatEntry: state =>
        //   specialists.find(specialist => specialist.id === state.specialist_id)
        //     .attributes.name,
        // width: 2,
      },
      {
        header: 'Date',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'date',
        formatAttribute: formatShortDate,
      },
      {
        header: 'Facility',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'facility',
      },
      {
        header: 'Reason',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'reason',
      },
      {
        header: 'Status',
        isSortable: false,
        isVisibleMobile: false,
        attribute: 'is_conducted',
        formatEntry: entry => formatStatus(entry),
      },
    ],
    rows: {
      editPath: buildPath(
        Routes.patientsAppointmentsEdit,
        {
          patientId: patient.id,
        },
        query,
      ),
    },
    header: {
      addPath: buildPath(
        Routes.patientsAppointmentsNew,
        { id: patient.id },
        query,
      ),
    },
  };

  return (
    <PatientLayout>
      <Panel>
        <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
          <Tab value="upcoming">Upcoming Appointments</Tab>
          <Tab value="past">Past Appointments</Tab>
        </Tabs>

        <Table config={tableConfig} />
      </Panel>
    </PatientLayout>
  );
};

export default AppointmentsIndex;