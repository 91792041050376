export const arraysMatch = (array1: any[], array2: any[]) => {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return false;
  }

  if (array1.length !== array2.length) {
    return false;
  }

  const testArray1 = array1.sort();
  const testArray2 = array2.sort();

  for (let i = 0; i < testArray1.length; i++) {
    if (testArray1[i] !== testArray2[i]) {
      return false;
    }
  }

  return true;
};

export const updateArrayIndex = (
  array: any[],
  index: number,
  value: any,
): any[] => {
  const newArray = [...array];
  newArray[index] = value;

  return newArray;
};

export const switchIndexes = (array: any[], i1: number, i2: number): any[] => {
  const oldValue = array[i1];
  const tempArray = updateArrayIndex(array, i1, array[i2]);
  return updateArrayIndex(tempArray, i2, oldValue);
};
