import React from 'react';

import RectangleContainer from '@/components/container/RectangleContainer';
import { formatMinutesToHours, formatSecondsToHours } from '@/utils/timeUtils';

interface Performance {
  complete_calls: number;
  monthly_ccm_goal: number;
  monthly_rpm_goal: number;
  monthly_time: number;
  todays_calls: number;
  total_ccm_threshold: number;
  total_rpm_threshold: number;
  total_readings_threshold: number;
}

interface Props {
  performance: Performance;
}

const DashboardPerformance = (props: Props) => {
  const { performance } = props;

  return (
    <div className="dashboard-panel">
      <div className="columns is-mobile dashboard-performance__call-wrapper">
        {/* <div className="column is-12-mobile is-one-fifth-tablet">
          <RectangleContainer withLargePadding>
            <div className="dashboard-performance__wrapper">
              <p className="dashboard-performance__title">
                Calls Scheduled Today
              </p>
              <p className="dashboard-performance__result">
                {performance.todays_calls || 0}
              </p>
            </div>
          </RectangleContainer>
        </div> */}

        <div className="column is-12-mobile is-one-fifth-tablet">
          <RectangleContainer withLargePadding>
            <div className="dashboard-performance__wrapper">
              <p className="dashboard-performance__title">
                Calls Completed Today
              </p>
              <p className="dashboard-performance__result">
                {performance.complete_calls || 0}
              </p>
            </div>
          </RectangleContainer>
        </div>

        <div className="column is-12-mobile is-one-fifth-tablet">
          <RectangleContainer withLargePadding>
            <div className="dashboard-performance__wrapper">
              <p className="dashboard-performance__title">
                Minimum CCM Standard Care
              </p>
              <p className="dashboard-performance__result">
                {performance.total_ccm_threshold || 0}
              </p>
            </div>
          </RectangleContainer>
        </div>

        <div className="column is-12-mobile is-one-fifth-tablet">
          <RectangleContainer withLargePadding>
            <div className="dashboard-performance__wrapper">
              <p className="dashboard-performance__title">
                Minimum RPM Standard Care
              </p>
              <p className="dashboard-performance__result">
                {performance.total_rpm_threshold || 0}
              </p>
            </div>
          </RectangleContainer>
        </div>

        <div className="column is-12-mobile is-one-fifth-tablet">
          <RectangleContainer withLargePadding>
            <div className="dashboard-performance__wrapper">
              <p className="dashboard-performance__title">
                Minimum Reading Count
              </p>
              <p className="dashboard-performance__result">
                {performance.total_readings_threshold || 0}
              </p>
            </div>
          </RectangleContainer>
        </div>

        {/* <div className="column is-12-mobile is-one-fifth-tablet">
          <RectangleContainer withLargePadding>
            <div className="dashboard-performance__wrapper">
              <p className="dashboard-performance__title">
                Time with Patients this Month
              </p>
              <p className="dashboard-performance__result">
                {formatSecondsToHours(performance.monthly_time || 0)}
              </p>
            </div>
          </RectangleContainer>
        </div> */}

        {/* <div className="column is-12-mobile is-one-fifth-tablet">
          <RectangleContainer withLargePadding>
            <div className="dashboard-performance__wrapper">
              <p className="dashboard-performance__title">Monthly CCM Goal</p>
              <p className="dashboard-performance__result">
                {formatMinutesToHours(performance.monthly_ccm_goal || 0)}
              </p>
            </div>
          </RectangleContainer>
        </div>

        <div className="column is-12-mobile is-one-fifth-tablet">
          <RectangleContainer withLargePadding>
            <div className="dashboard-performance__wrapper">
              <p className="dashboard-performance__title">Monthly RPM Goal</p>
              <p className="dashboard-performance__result">
                {formatMinutesToHours(performance.monthly_rpm_goal || 0)}
              </p>
            </div>
          </RectangleContainer>
        </div> */}
      </div>
    </div>
  );
};

export default DashboardPerformance;
