import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Datepicker from '@/components/form/Datepicker';
import ErrorMessage from '@/components/form/ErrorMessage';
import InputGroup from '@/components/form/InputGroup';
import InputIcon from '@/components/form/InputIcon';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Panel from '@/components/panel/Panel';
import Table from '@/components/table/Table';
import { getSelectedPatient } from '@/selectors/api';
import { selectPracticeDeviceModels } from '@/selectors/patients';
import { Validations } from '@/types/form';
import useGetRequest from '@/utils/api/useGetRequest';
import usePostRequest from '@/utils/api/usePostRequest';
import { parseErrorData } from '@/utils/apiUtils';
import { validate } from '@/utils/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { longName } from '@/utils/patientUtils';
import { TableConfig } from '@/types/table';
import { SideEffect } from '@/types/generic';
import { IdParams } from '@/types/params';
import useNotifier from '@/utils/messages/useNotifier';
import Api from '@/api/Api';

const DevicesNew = () => {
  const patient = useSelector(getSelectedPatient);
  const practiceDevices = useSelector(selectPracticeDeviceModels);
  const hasPracticeDevices = !!practiceDevices.length;

  const history = useHistory();
  const dispatch = useDispatch();
  const notifier = useNotifier();

  const { id } = useParams<IdParams>();

  const [query] = returnTabQuery();

  const todaysDate = moment().format();

  const FORM_STATE = {
    device_category_id: '',
    device_model_id: '',
    device_make_id: '',
    device_name_id: '',
    device_type_id: '',
    issue_date: todaysDate,
    patient_id: patient.id,
    serial_number: '',
  };

  const INITIAL_QUERY = {
    search: '',
    orphan_status: 'orphan',
    context: 'DevicesNew',
  };

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});
  const [searchInput, setSearchInput] = useState('');
  const [queryOptions, setQueryOptions] = useState(INITIAL_QUERY);
  const [orphanDevices, setOrphanDevices] = useState<any[]>([]);
  const [perPage, setPerPage] = useState<number>(24);
  const [shouldForceUpdate, setShouldForceUpdate] = useState<boolean>(false);

  const [deviceCategories, setDeviceCategories] = useState<any[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<any[]>([]);
  const [deviceMakes, setDeviceMakes] = useState<any[]>([]);
  const [deviceNames, setDeviceNames] = useState<any[]>([]);
  const [deviceModels, setDeviceModels] = useState<any[]>([]);

  const backPath = buildPath(Routes.patientsRemotePatientCare, { id }, query);

  const formatDeviceEntry = (entry: any) => ({
    label: entry.attributes.name,
    value: entry.id,
  });

  const filterDeviceEntries = (entries: any[], attribute: string) => {
    return entries.filter(
      entry => entry.attributes[attribute] === form[attribute],
    );
  };

  const buildDeviceOptions = (entries: any[], attribute: string) => {
    return filterDeviceEntries(entries, attribute).map(formatDeviceEntry);
  };

  const deviceCategoryOptions = useMemo(() => {
    return deviceCategories.map(formatDeviceEntry);
  }, [deviceCategories]);

  const deviceTypeOptions = useMemo(() => {
    return buildDeviceOptions(deviceTypes, 'device_category_id');
  }, [form.device_category_id]);

  const deviceMakeOptions = useMemo(() => {
    return buildDeviceOptions(deviceMakes, 'device_type_id');
  }, [form.device_type_id]);

  const deviceNameOptions = useMemo(() => {
    return buildDeviceOptions(deviceNames, 'device_make_id');
  }, [form.device_make_id]);

  const deviceModelOptions = useMemo(() => {
    return buildDeviceOptions(deviceModels, 'device_name_id');
  }, [form.device_name_id]);

  const allDeviceModelOptions = useMemo(() => {
    return deviceModels.map(formatDeviceEntry);
  }, [deviceModels]);

  const practiceDeviceOptions = useMemo(() => {
    return practiceDevices.map(practiceDevice => ({
      label: practiceDevice.name,
      value: practiceDevice.id,
    }));
  }, [practiceDevices]);

  const updateFieldUtil = (deviceOptions, optionId, dependentOptionId) => {
    const option = deviceOptions.find(option => option.id === form[optionId]);
    updateField(dependentOptionId, option?.attributes[dependentOptionId]);
  };

  useEffect(() => {
    updateFieldUtil(deviceModels, 'device_model_id', 'device_name_id');
  }, [form.device_model_id]);

  useEffect(() => {
    updateFieldUtil(deviceNames, 'device_name_id', 'device_make_id');
  }, [form.device_name_id]);

  useEffect(() => {
    updateFieldUtil(deviceMakes, 'device_make_id', 'device_type_id');
  }, [form.device_make_id]);

  useEffect(() => {
    updateFieldUtil(deviceTypes, 'device_type_id', 'device_category_id');
  }, [form.device_type_id]);

  const rules = (() => {
    if (hasPracticeDevices) {
      return {
        device_model_id: { required: true },
        issue_date: { required: true },
        serial_number: { required: true },
      };
    }

    return {
      device_category_id: { required: true },
      device_make_id: { required: true },
      device_model_id: { required: true },
      device_name_id: { required: true },
      device_type_id: { required: true },
      issue_date: { required: true },
      serial_number: { required: true },
    };
  })();

  const [getDevices] = useGetRequest({
    dispatch,
    model: 'devices',
    url: buildPath('api/v1/devices/heirarchy', {}, { patient_id: patient.id }),
    onSuccess: (res: any) => {
      setDeviceCategories(res.device_categories.data);
      setDeviceTypes(res.device_types.data);
      setDeviceMakes(res.device_makes.data);
      setDeviceNames(res.device_names.data);
      setDeviceModels(res.device_models.data);
    },
  });

  useEffect(() => {
    getDevices();
  }, []);

  const [getOrphanDevices] = useGetRequest({
    dispatch,
    model: 'devices',
    url: Routes.api2.devices,
    params: { orphan_status: 'orphan' },
    onSuccess: (res: any) => {
      setOrphanDevices(orphanDevices => [...orphanDevices, ...res.data]);
    },
  });

  useEffect(() => {
    getOrphanDevices();
  }, []);

  const formatBody = () => {
    const { ...formFields } = form;

    return {
      device: {
        ...formFields,
      },
    };
  };

  const handleSuccess = () => {
    dispatch(flashSuccess('Device added'));
    history.push(backPath);
  };

  const handleFailure = (response: any) => {
    const errorMessage = parseErrorData(response);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const [submitForm, isSubmitting] = usePostRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: 'api/v1/devices',
  });

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);

    if (isValid) {
      setValidations({});
    } else {
      return setValidations(validationMessages);
    }

    submitForm();
  };

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleSearchChange = (value: any) => {
    setSearchInput(value);
  };

  const handleSearchInput = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const toggleShouldForceUpdate = () => {
    setShouldForceUpdate(!shouldForceUpdate);
  };

  const associateDeviceWithPatient = async (device, patient): Promise<void> => {
    try {
      const url = Routes.api2.patientDevices;
      const body = {
        patient_device: {
          device_id: device.id,
          patient_id: patient.id,
          status: 'active',
        },
      };

      await Api.utility.post(url, body);

      notifier.success('Device assigned');
      history.push(backPath);
    } catch (err) {
      notifier.error(err);
    }
  };

  const onDevicePatientAssociation = () => {
    setShouldForceUpdate(false);
  };

  const renderAssociateDeviceWithPatientButton = (entry, patient) => {
    return (
      <Button
        color="secondary"
        onClick={() => associateDeviceWithPatient(entry, patient)}>
        Associate to {longName(patient)}
      </Button>
    );
  };

  const SEARCH_PATH = Routes.api2.devices;

  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      ...queryOptions,
      per: perPage,
    },
    columns: [
      {
        header: 'Device Model',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'device_model_id',
        className: 'table__cell-highlight',
        width: 1,
        formatEntry: entry => {
          return entry.device_model.name || null;
        },
      },
      {
        header: 'Serial Number',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'serial_number',
        width: 2,
      },
      {
        header: 'Associate Device With Patient',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'patient_id',
        width: 1,
        formatEntry: entry =>
          renderAssociateDeviceWithPatientButton(entry, patient),
      },
    ],
  };

  const renderDeviceHierarchy = (() => {
    // If the patient's practice has specifically assigned devices, render a selection of those
    // Otherwise, show the full device hierarchy to the care manager for selection.
    if (hasPracticeDevices) {
      return (
        <div className="columns">
          <div className="column is-6-tablet is-4-desktop">
            <SelectGroup
              label="Device Model"
              onChange={value => updateField('device_model_id', value)}
              options={practiceDeviceOptions}
              validationMessage={validations.device_model_id}
              value={form.device_model_id}
            />
          </div>

          <div className="column is-6-tablet is-4-desktop">
            <InputGroup
              label="Serial Number"
              onChange={value => updateField('serial_number', value)}
              placeholder="Enter Serial Number"
              validationMessage={validations.serial_number}
              value={form.serial_number}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="columns">
          <div className="column is-6-tablet is-4-desktop">
            <SelectGroup
              label="Device Model"
              onChange={value => updateField('device_model_id', value)}
              options={allDeviceModelOptions}
              validationMessage={validations.device_model_id}
              value={form.device_model_id}
            />
          </div>

          <div className="column is-6-tablet is-4-desktop">
            <InputGroup
              label="Serial Number"
              onChange={value => updateField('serial_number', value)}
              placeholder="Enter Serial Number"
              validationMessage={validations.serial_number}
              value={form.serial_number}
            />
          </div>
        </div>
      </>
    );
  })();

  return (
    <div className="patient-remote-care-new">
      <Container>
        <BackLink to={backPath}>Back to Devices</BackLink>
      </Container>

      <Constraint size="large">
        <Panel>
          <InputIcon
            value={searchInput}
            onChange={handleSearchChange}
            placeholder="Search by serial number"
            onClick={() => handleSearchInput('search', searchInput)}
            onPressEnter={() => handleSearchInput('search', searchInput)}
          />
          <Table
            config={tableConfig}
            forceUpdate={shouldForceUpdate}
            onForceUpdate={onDevicePatientAssociation}
          />
        </Panel>

        {/* NOTE: As of 5/10/21, we are hiding this feature and this screen is only for
        assigning devices to patients, not creating new devices
         <Panel>
          <Section title="Add Device">
            {renderDeviceHierarchy}

            <div className="columns">
              <div className="column is-6-tablet is-4-desktop">
                <Datepicker
                  label="Issue Date"
                  onChange={value => updateField('issue_date', value)}
                  validationMessage={validations.issue_date}
                  value={form.issue_date}
                />
              </div>
            </div>
          </Section>
        </Panel>
        <div className="form__actions">
          <div className="form__actions-left" />
          <div className="form__actions-right">
            <Button color="white" onClick={handleClickCancel}>
              Cancel
            </Button>

            <Button
              color="secondary"
              isSubmitting={isSubmitting}
              onClick={handleClickSubmit}>
              Save
            </Button>
          </div>
        </div>
        
        */}

        <ErrorMessage isRightSide message={error} />
      </Constraint>
    </div>
  );
};

export default DevicesNew;
