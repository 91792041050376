import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Datepicker from '@/components/form/Datepicker';
import SearchGroup, {
  formatModelFullName,
  formatSearchFullName,
} from '@/components/form/SearchGroup';
import Section from '@/components/form/Section';
import Timepicker from '@/components/form/Timepicker';
import Panel from '@/components/panel/Panel';
import {
  canUserModifyAppointments,
  selectCurrentUser,
} from '@/selectors/users';
import { Rules } from '@/types/form';
import { UUID } from '@/types/generic';
import { AdminUser } from '@/types/v2/admin_user';
import { Patient } from '@/types/v2/patient';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import useLoadingState from '@/utils/api/useLoadingState';
import useSimpleForm from '@/utils/form/useSimpleForm';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import { breakDateAndTime, combineDateAndTime } from '@/utils/timeUtils';
import moment from 'moment';

interface Params {
  appointment_id?: UUID;
}

const VirtualAppointmentForm = () => {
  const history = useHistory();
  const loader = useLoadingState('patient', 'appointment');
  const location = useLocation();
  const notifier = useNotifier();
  const user = useSelector(selectCurrentUser) as AdminUser;
  const isAdmin = useSelector(canUserModifyAppointments);

  const initialFormState = {
    admin_user_id: isAdmin ? '' : user.id,
    patient_id: '',
    date: '',
    start_time: moment()
      .set({ hour: 12, minute: 0 })
      .utc()
      .format(),
    end_time: moment()
      .set({ hour: 13, minute: 0 })
      .utc()
      .format(),
  };

  type FormState = typeof initialFormState;

  const [appointment, setAppointment] = useState<VirtualAppointment>(null);
  const [defaultPatient, setDefaultPatient] = useState<Patient>(null);

  // Patient ID is an optional param from the query string used to prepopulate the default selected provider for the form
  const params = new URLSearchParams(location.search);
  const patientId = params.get('patient');
  const returnPath = params.get('return');

  const { appointment_id } = useParams<Params>();

  const form = useSimpleForm<FormState>(initialFormState);

  const isEditForm = !!appointment_id;

  const getDefaultPatient = async (): Promise<void> => {
    loader.startLoading('patient');

    try {
      const url = buildPath(Routes.api2.patient, { id: patientId });

      const response = await Api.utility.get(url);
      const resource = new Modeler<Patient>(response.data).build();

      setDefaultPatient(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('patient');
  };

  const getVirtualAppointment = async (): Promise<void> => {
    loader.startLoading('appointment');

    try {
      const url = buildPath(
        Routes.api2.virtualAppointment,
        { id: appointment_id },
        null,
        ['patient', 'admin_user'],
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<VirtualAppointment>(response.data, {
        generations: 2,
      }).build();

      setAppointment(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('appointment');
  };

  const backPath = (() => {
    return returnPath || Routes.provider.appointments.root;
  })();

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const handleClickSave = () => {
    const rules: Rules = {
      admin_user_id: { required: true },
      patient_id: { required: true },
      date: { required: true },
      start_time: { required: true, mustOccurBefore: 'end_time' },
      end_time: {
        required: true,
        maxDurationAfter: {
          amount: 90,
          field: 'start_time',
          units: 'minutes',
        },
      },
    };

    if (form.validate(rules)) {
      submitForm();
    }
  };

  const submitForm = async () => {
    form.submit();

    const { admin_user_id, date, end_time, patient_id, start_time } = form.data;

    const scheduledStartAt = combineDateAndTime(date, start_time);
    const scheduledEndAt = combineDateAndTime(date, end_time);

    try {
      const body = {
        virtual_appointment: {
          admin_user_id,
          scheduled_end_at: scheduledEndAt,
          scheduled_start_at: scheduledStartAt,
          patient_id,
        },
      };

      if (isEditForm) {
        const url = buildPath(Routes.api2.virtualAppointment, {
          id: appointment_id,
        });
        await Api.utility.patch(url, body);
      } else {
        const url = buildPath(Routes.api2.virtualAppointments);
        await Api.utility.post(url, body);
      }

      form.success('Appointment request saved');
      history.push(backPath);
    } catch (err) {
      form.error(err);
    }

    form.done();
  };

  useEffect((): void => {
    if (patientId) {
      getDefaultPatient();
    }

    if (isEditForm) {
      getVirtualAppointment();
    }
  }, []);

  useEffect((): void => {
    if (appointment) {
      const [startDate, startTime] = breakDateAndTime(
        appointment.scheduled_start_at,
      );
      const [endDate, endTime] = breakDateAndTime(appointment.scheduled_end_at);

      const appointmentFormState: FormState = {
        admin_user_id: appointment.admin_user_id,
        patient_id: appointment.patient.id,
        date: startDate,
        end_time: endTime,
        start_time: startTime,
      };

      form.set(appointmentFormState);
    }
  }, [appointment]);

  const initialPatientSelection = useMemo(() => {
    if (appointment) {
      return formatModelFullName(appointment.patient);
    } else if (defaultPatient) {
      return formatModelFullName(defaultPatient);
    }
  }, [appointment, defaultPatient]);

  const initialUserSelection = useMemo(() => {
    if (appointment?.admin_user) {
      return formatModelFullName(appointment.admin_user);
    }
  }, [appointment]);

  const renderProviderSelect = (() => {
    if (isAdmin) {
      return (
        <div className="column is-4">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatModelFullName}
            guideValue={form.data.admin_user_id}
            initialSelection={initialUserSelection}
            isDisabled={!!(isEditForm && appointment?.admin_user_id)}
            label="Provider"
            minimumInputLength={1}
            onChange={value => form.update('admin_user_id', value)}
            queryParams={{ practice_id: user.practice_id, role: 'provider' }}
            placeholder="Select Provider"
            searchPath={Routes.api2.adminUsers}
            validationMessage={form.validations.admin_user_id}
          />
        </div>
      );
    }
  })();

  if (loader.areAnyLoading) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <Container>
        <BackLink to={backPath}>Back</BackLink>
      </Container>

      <Panel>
        <Constraint size="large">
          <Section title="Schedule Appointment">
            <div className="columns">
              <div className="column is-4">
                <SearchGroup
                  clearFalseResultOnBlur
                  formatResult={formatSearchFullName}
                  guideValue={form.data.patient_id}
                  initialSelection={initialPatientSelection}
                  isDisabled={isEditForm}
                  label="Patient"
                  minimumInputLength={1}
                  onChange={value => form.update('patient_id', value)}
                  queryParams={{ practice_id: user.practice_id }}
                  placeholder="Select Patient"
                  searchPath={Routes.api.patients}
                  validationMessage={form.validations.patient_id}
                />
              </div>

              {renderProviderSelect}
            </div>

            <div className="columns">
              <div className="column is-4">
                <Datepicker
                  label="Date"
                  limit="future"
                  onChange={value => form.update('date', value)}
                  placeholder="Select date"
                  validationMessage={form.validations.date}
                  value={form.data.date}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-4">
                <Timepicker
                  label="Start Time"
                  onChange={value => form.update('start_time', value)}
                  placeholder="Select start time"
                  value={form.data.start_time}
                  validationMessage={form.validations.start_time}
                />
              </div>

              <div className="column is-4">
                <Timepicker
                  label="End Time"
                  onChange={value => form.update('end_time', value)}
                  placeholder="Select end time"
                  value={form.data.end_time}
                />
              </div>
            </div>
          </Section>
        </Constraint>
      </Panel>

      <Constraint size="large">
        <div className="form__actions">
          <div className="form__actions-left" />

          <div className="form__actions-right">
            <Button color="white" onClick={handleClickCancel}>
              Cancel
            </Button>

            <Button
              color="secondary"
              isSubmitting={form.isSubmitting}
              onClick={handleClickSave}>
              Send Request
            </Button>
          </div>
        </div>
      </Constraint>
    </>
  );
};

export default VirtualAppointmentForm;
