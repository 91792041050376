import { create, MessagesState } from '@/types/v2/message';
import { MessagesAction, MessagesActions } from '../actions/messages';

export default function reducer(
  state: MessagesState = create(),
  action: MessagesAction,
): MessagesState {
  switch (action.type) {
    case MessagesActions.SET_MESSAGES:
      return {
        ...state,
        messages: action.payload.messages,
      };

    case MessagesActions.ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload.message],
      };

    default:
      return state;
  }
}
