import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GenerateToken,
  GenerateTokenFailure,
  generateTokenFailure,
  generateTokenSuccess,
  VideoCallsActions,
} from '@/actions/actions/video_calls';
import Api from '@/api/Api';
import { parseErrorData } from '@/utils/apiUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { flashError } from '../messageSaga';

function* generateTokenSaga(action: GenerateToken) {
  try {
    const url = buildPath(Routes.api2.videoCalls);
    const body = { appointment_id: action.payload.appointment.id };

    const response = yield call(Api.utility.post, url, body);

    yield put(generateTokenSuccess(response.data.token));
  } catch (err) {
    yield put(generateTokenFailure(err));
  }
}

function* generateTokenFailureSaga(action: GenerateTokenFailure) {
  yield put(flashError(parseErrorData(action.payload.error)));
}

export function* watchGenerateToken() {
  yield takeLatest(VideoCallsActions.GENERATE_TOKEN, generateTokenSaga);
  yield takeLatest(
    VideoCallsActions.GENERATE_TOKEN_FAILURE,
    generateTokenFailureSaga,
  );
}
