import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import { FormComponentConfig } from '@/types/form';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { stateOptions } from '@/utils/dropdownUtils';
import { formatAddressAttributes } from '@/utils/requestUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';
import { isEnrollmentPath } from '../../utils/patientUtils';
import { UUID } from '@/types/generic';

interface Params {
  patient_id: UUID;
  id: UUID;
  step: string;
}

const ContactsEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { patient_id, id, step } = useParams<Params>();

  const [response, setResponse] = useState<any>();

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'contacts',
    url: `api/v1/contacts/${id}?include=address`,
    onSuccess: (res: any) => {
      const responseData = parseResponseData(res);

      if (!responseData.attributes.address) {
        responseData.attributes.address = {
          city: '',
          line_1: '',
          line_2: '',
          state: '',
          zip_code: '',
        };
      }

      const formattedResponse = {
        ...responseData,
        attributes: {
          ...responseData.attributes,
          ...responseData.attributes.address,
        },
      };

      setResponse(formattedResponse);
    },
  });

  useEffect(() => {
    getEntry();
  }, []);

  const isEnrollment = isEnrollmentPath();

  const backPath = (() => {
    const queryParams = new URLSearchParams(location.search || '');
    const returnTo = queryParams.get('return_to');

    if (returnTo) {
      return returnTo;
    }

    return isEnrollment
      ? buildPath(Routes.patientsEnrollment, { id: patient_id, step })
      : buildPath(Routes.patientsContacts, { id: patient_id });
  })();

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: `api/v1/contacts/${id}`,
      model: 'contact',
      method: 'PUT',
      onCancel: () => history.push(backPath),
      onSuccess: () => history.push(backPath),
      alterFormBeforeSubmit: formatAddressAttributes,
    },
    state: response.attributes,
    rules: {
      email: { required: false, type: 'email' },
      fax_number: { required: false, type: 'phone' },
      name: { required: true },
      phone_number: { required: false, type: 'phone' },
      relation: { required: true },
    },
    layout: [
      {
        title: 'Add Contact',
        fields: [
          [
            {
              label: 'Name',
              field: 'name',
              placeholder: 'Enter contact name',
              type: 'text',
              size: 4,
            },
            {
              label: 'Relation',
              field: 'relation',
              placeholder: 'Enter relation',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Email Address',
              field: 'email',
              placeholder: 'Enter email address',
              type: 'text',
              size: 4,
            },
            {
              label: 'Phone Number',
              field: 'phone_number',
              placeholder: 'Enter phone number',
              type: 'phonenumber',
              size: 4,
            },
            {
              label: 'Fax Number',
              field: 'fax_number',
              placeholder: 'Enter fax number',
              type: 'phonenumber',
              size: 4,
            },
          ],
          [
            {
              label: 'Street Address',
              field: 'line_1',
              placeholder: 'Enter street address',
              type: 'text',
              size: 4,
            },
            {
              label: 'Address Line 2',
              field: 'line_2',
              placeholder: 'Enter address',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'City',
              field: 'city',
              placeholder: 'Enter city',
              type: 'text',
              size: 4,
            },
            {
              label: 'State',
              field: 'state',
              placeholder: 'Select state',
              type: 'select',
              options: stateOptions,
              size: 4,
            },
            {
              label: 'Zip Code',
              field: 'zip_code',
              placeholder: 'Enter zip code',
              type: 'zipcode',
              size: 4,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Contacts"
      config={config}
    />
  );
};

export default ContactsEdit;
