import React from 'react';

import { formatSeconds } from '@/utils/timeUtils';

interface Props {
  duration: number;
}

const TimerDisplay = (props: Props) => {
  const { duration } = props;

  return (
    <div className="caller__timer-display">
      <span>{formatSeconds(duration, true)}</span>
    </div>
  );
};

export default TimerDisplay;
