import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getServiceOptions } from '@/selectors/options';
import {
  getCurrentUser,
  selectIsCurrentUserCareManager,
} from '@/selectors/users';
import { FormComponentConfig } from '@/types/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';
import { useSelector } from 'react-redux';
import CallForm from './components/CallForm';
import { UUID } from '@/types/generic';

interface Params {
  id: UUID;
}

const CallsScheduleNew = () => {
  const history = useHistory();

  const { id } = useParams<Params>();

  const isCareManager = useSelector(selectIsCurrentUserCareManager);

  const [query] = returnTabQuery();

  const backPath = buildPath(Routes.calls, query);

  const careManager = useSelector(getCurrentUser);

  const initialSelection = () => {
    if (isCareManager) {
      return {
        label: fullName(careManager.data),
        value: careManager.data.id,
      };
    }
  };

  const serviceOptions = useSelector(getServiceOptions);

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      alterFormBeforeSubmit: (formState: any) => {
        const { start_date, start_time, ...form } = formState;

        const date = moment(start_date);
        const time = moment(start_time);

        date.set('hour', time.hour());
        date.set('minute', time.minute());

        return {
          ...form,
          start_at: date.toISOString(),
          call_type: 'standard',
          status: 'scheduled',
        };
      },
      path: 'api/v1/calls',
      model: 'call',
      method: 'POST',
      onSuccess: () => history.push(backPath),
    },
    state: {
      patient_id: '',
      user_id: '',
      start_date: '',
      start_time: '',
      service_id: '',
    },
    rules: {
      patient_id: { required: true },
      user_id: { required: true },
      start_at: { required: true },
      start_time: { required: true },
      service_id: { required: true },
    },
    layout: [
      {
        title: 'Schedule Call',
        fields: [
          [
            {
              label: 'Patient',
              field: 'patient_id',
              placeholder: 'Search for patient',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/patients',
              size: 4,
              formatTypeaheadResult: (result: any) => {
                return {
                  label: fullName(result),
                  value: result.id,
                };
              },
            },
          ],
          [
            {
              label: 'Date',
              field: 'start_date',
              type: 'date',
              size: 4,
            },
            {
              label: 'Time',
              field: 'start_time',
              type: 'time',
              size: 4,
            },
          ],
          [
            {
              label: 'Care Manager',
              field: 'user_id',
              placeholder: 'Search for care manager',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/care_managers',
              size: 4,
              initialSelection: initialSelection(),
              formatTypeaheadResult: (result: any) => {
                return {
                  label: fullName(result),
                  value: result.id,
                };
              },
            },
            {
              label: 'Service Type',
              field: 'service_id',
              type: 'select',
              size: 4,
              placeholder: 'Select service',
              options: serviceOptions,
            },
          ],
        ],
      },
    ],
  };

  return (
    <CallForm
      backPath={backPath}
      backText="Back to Call Schedule"
      config={config}
    />
  );
};

export default CallsScheduleNew;
