import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PolicyGroups, Protected } from '@/components/router';
import { Routes } from '@/utils/routeUtils';
import Alerts from './alerts/Alerts';
import Appointment from './appointments/Appointment';
import Appointments from './appointments/Appointments';
import VirtualAppointmentForm from './appointments/VirtualAppointmentForm';
import Calendar from './calendar/Calendar';
import Practice from './practices/Practice';
import Provider from './providers/Provider';
import Providers from './providers/Providers';

const Calls = () => {
  return (
    <Protected allowedRoles={PolicyGroups.patientOnly}>
      <Switch>
        <Route
          path={Routes.chart.appointments.new}
          component={VirtualAppointmentForm}
        />
        <Route
          path={Routes.chart.appointments.edit}
          component={VirtualAppointmentForm}
        />
        <Route path={Routes.chart.appointments.show} component={Appointment} />
        <Route path={Routes.chart.appointments.root} component={Appointments} />
        <Route path={Routes.chart.alerts.root} component={Alerts} />
        <Route path={Routes.chart.providers.show} component={Provider} />
        <Route path={Routes.chart.providers.root} component={Providers} />
        <Route path={Routes.chart.practices.show} component={Practice} />
        <Route path={Routes.chart.root} component={Appointments} />
      </Switch>
    </Protected>
  );
};

export default Calls;
