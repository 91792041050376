import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  isVisible: boolean;
}

const VisibleSection = ({ children, isVisible }: Props): JSX.Element => {
  if (isVisible) {
    return <>{children}</>;
  }

  return null;
};

export default VisibleSection;
