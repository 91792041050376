import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentCarePlan } from '@/actions/reducers/bhi';
import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import EnrollmentActions from '@/contexts/enrollment/components/EnrollmentActions';
import EnrollmentHeader from '@/contexts/enrollment/components/EnrollmentHeader';
import {
  selectCurrentCarePlan,
  selectCurrentCarePlanStatus,
  selectedFormattedTrackingData,
  selectTotalNumberOfQuestions,
} from '@/selectors/bhi';
import { UUID } from '@/types/generic';
import { TrackingQuestionSet } from '@/types/tracking_question';
import { parseResponseData } from '@/utils/apiUtils';
import { formatIsoDateTime } from '@/utils/dateUtils';
import { usePrevious } from '@/utils/stateUtils';
import TrackingQuestionManager, {
  TrackingAnswer,
} from './tracking/TrackingQuestionManager';

const BhiTracking = () => {
  const dispatch = useDispatch();

  const carePlan = useSelector(selectCurrentCarePlan);
  const carePlanStatus = useSelector(selectCurrentCarePlanStatus);
  const totalQuestions: number = useSelector(selectTotalNumberOfQuestions);
  const trackingData: TrackingQuestionSet[] = useSelector(
    selectedFormattedTrackingData,
  );

  const [answers, setAnswers] = useState<TrackingAnswer[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const prevCarePlanStatus = usePrevious(carePlanStatus);

  useEffect(() => {
    if (carePlan) {
      const formattedAnswers: TrackingAnswer[] = carePlan.data.attributes.care_plan_tracking_questions.map(
        // This is fast way to rip keys x, y, and z from an object and then create a new object with just those keys
        ({ id, tracking_question_id, value }) => ({
          id,
          tracking_question_id,
          value,
        }),
      );
      setAnswers(formattedAnswers);
    }
  }, [carePlan]);

  const handleSaveCarePlan = async () => {
    if (answers.length < totalQuestions) {
      return dispatch(flashError('Not all questions answered'));
    }

    setIsSubmitting(true);

    try {
      const body = {
        care_plan: {
          care_plan_tracking_questions_attributes: answers,
          tracking_completed_at: formatIsoDateTime(),
        },
      };

      const url = `api/v1/care_plans/${carePlan.data.id}`;

      const response = await Api.utility.patch(url, body);

      if (
        prevCarePlanStatus === 'in_progress' &&
        response.data.data.attributes.status === 'ready_to_submit'
      ) {
        dispatch(
          flashSuccess(
            'Care plan finished. Select "Complete Plan" to continue.',
          ),
        );
      }

      dispatch(setCurrentCarePlan(parseResponseData(response)));
    } catch (err) {
      dispatch(flashError('Unable to save care plan'));
    }

    setIsSubmitting(false);
  };

  const onSetAnswerValue = (questionId: UUID, value: number) => {
    const newAnswers = [...answers];

    const index = answers.findIndex(
      (a: TrackingAnswer) => a.tracking_question_id === questionId,
    );

    let originalAnswer = {};

    if (index !== -1) {
      originalAnswer = newAnswers.splice(index, 1);
    }

    const newAnswer: TrackingAnswer = {
      ...originalAnswer[0],
      tracking_question_id: questionId,
      value,
    };

    setAnswers([...newAnswers, newAnswer]);
  };

  return (
    <div className="enrollment-wrapper">
      <EnrollmentHeader
        description="How does the patient respond to the following questions?"
        title="Tracking"
      />

      <TrackingQuestionManager
        answers={answers}
        onSetAnswerValue={onSetAnswerValue}
        questionStructure={trackingData}
      />

      <EnrollmentActions shouldDisplayAction>
        <Button
          color="secondary"
          onClick={handleSaveCarePlan}
          isSubmitting={isSubmitting}>
          Save
        </Button>
      </EnrollmentActions>
    </div>
  );
};

export default BhiTracking;
