import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BhiPlanIndex from '@/contexts/patients/modules/bhi_plan/BhiPlanIndex';
import { Routes } from '@/utils/routeUtils';

const Patients = () => {
  return (
    <Switch>
      <Route path={Routes.patientsBhiPlanShow} component={BhiPlanIndex} />
      <Route path={Routes.patientsBhiPlan} component={BhiPlanIndex} />
    </Switch>
  );
};

export default Patients;
