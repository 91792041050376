import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';

import { getPatientEnrollmentSteps } from '@/contexts/enrollment/helpers/steps';
import Allergies from '@/contexts/enrollment/modules/Allergies';
import Barriers from '@/contexts/enrollment/modules/Barriers';
import Conditions from '@/contexts/enrollment/modules/Conditions';
import Consent from '@/contexts/enrollment/modules/consent/Consent';
import Contacts from '@/contexts/enrollment/modules/Contacts';
import FamilyHistory from '@/contexts/enrollment/modules/FamilyHistory';
import Goals from '@/contexts/enrollment/modules/Goals';
import MedicalHistory from '@/contexts/enrollment/modules/MedicalHistory';
import Medications from '@/contexts/enrollment/modules/Medications';
import Outcomes from '@/contexts/enrollment/modules/Outcomes';
import SocialHistory from '@/contexts/enrollment/modules/SocialHistory';
import Support from '@/contexts/enrollment/modules/Support';
import SurgicalHistory from '@/contexts/enrollment/modules/SurgicalHistory';
import Symptoms from '@/contexts/enrollment/modules/Symptoms';
import Tracking from '@/contexts/enrollment/modules/Tracking';
import BhiBarriers from '@/contexts/patients/modules/bhi/BhiBarriers';
import BhiConditions from '@/contexts/patients/modules/bhi/BhiConditions';
import BhiGoals from '@/contexts/patients/modules/bhi/BhiGoals';
import BhiOutcomes from '@/contexts/patients/modules/bhi/BhiOutcomes';
import BhiSupport from '@/contexts/patients/modules/bhi/BhiSupport';
import BhiSymptoms from '@/contexts/patients/modules/bhi/BhiSymptoms';
import BhiTracking from '@/contexts/patients/modules/bhi/BhiTracking';
import { getSelectedPatient } from '@/selectors/api';
import { buildPath, Routes } from '@/utils/routeUtils';
import { IdParams } from '@/types/params';

const components = {
  allergies: Allergies,
  barriers: Barriers,
  conditions: Conditions,
  consent: Consent,
  contacts: Contacts,
  family_history: FamilyHistory,
  goals: Goals,
  medical_history: MedicalHistory,
  medications: Medications,
  outcomes: Outcomes,
  social_history: SocialHistory,
  support: Support,
  surgical_history: SurgicalHistory,
  symptoms: Symptoms,
  tracking: Tracking,
  bhi_conditions: BhiConditions,
  bhi_goals: BhiGoals,
  bhi_barriers: BhiBarriers,
  bhi_symptoms: BhiSymptoms,
  bhi_outcomes: BhiOutcomes,
  bhi_support: BhiSupport,
  bhi_tracking: BhiTracking,
};

const EnrollmentForm = () => {
  const { id } = useParams<IdParams>();

  const patient = useSelector(getSelectedPatient);

  const renderSteps = () => {
    return getPatientEnrollmentSteps(patient).map(step => {
      const path = ['consent', 'bhi_support'].includes(step)
        ? buildPath(Routes.patientsEnrollmentStep, { id, step })
        : buildPath(Routes.patientsEnrollment, { id, step });

      const Component = components[step];

      return <Route key={step} path={path} component={Component} />;
    });
  };

  return (
    <Switch>
      {renderSteps()}
      <Route
        exact
        path={buildPath(Routes.patientsEnrollment, { id, step: 'bhi_support' })}
        component={BhiSupport}
      />
    </Switch>
  );
};

export default EnrollmentForm;
