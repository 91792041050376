import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getSelectedPatient } from '@/selectors/api';
import { getManualEntryReasonOptions } from '@/selectors/options';
import { getCurrentUser } from '@/selectors/users';
import { FormComponentConfig } from '@/types/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { buildDurationFromForm } from '@/utils/timeUtils';
import { fullName } from '@/utils/userUtils';
import { useSelector } from 'react-redux';
import PatientForm from '../../components/PatientForm';
import { IdParams } from '@/types/params';

const AdminEntriesNew = () => {
  const history = useHistory();

  const { id } = useParams<IdParams>();

  const patient = useSelector(getSelectedPatient);

  const [query] = returnTabQuery();

  const backPath = buildPath(
    query.service === 'rpm'
      ? Routes.patientsCareManagementRpm
      : Routes.patientsCareManagement,
    { id },
    query,
  );

  const manualEntryReasonOptions = useSelector(getManualEntryReasonOptions);

  const careManager = useSelector(getCurrentUser);

  const initialSelection = () => {
    return {
      label: `${careManager.data.attributes.first_name} ${careManager.data.attributes.last_name}`,
      value: careManager.data.id,
    };
  };

  const serviceOptions = useMemo(() => {
    return patient.attributes.patient_services.data.map(service => ({
      label: service.attributes.name,
      value: service.attributes.service_id,
    }));
  }, [patient]);

  const config: FormComponentConfig = {
    actions: {
      alterFormBeforeSubmit: (formState: any) =>
        buildDurationFromForm(formState),
      path: 'api/v1/admin_entries',
      model: 'admin_entry',
      method: 'POST',
      onSuccess: () => history.push(backPath),
    },
    state: {
      duration: '',
      hours: '',
      manual_entry_reason: '',
      minutes: '',
      notes: '',
      patient_id: patient.id,
      service_id: serviceOptions.length === 1 ? serviceOptions[0].value : '',
      start_at: '',
      user_id: careManager.data.id,
    },
    rules: {
      manual_entry_reason: { required: true },
      notes: { required: false },
      service_id: { required: true },
      start_at: { required: true },
    },
    layout: [
      {
        title: 'Edit Admin Entry',
        fields: [
          [
            {
              label: 'Notes',
              field: 'notes',
              type: 'text',
              size: 4,
            },
            {
              label: 'Reason',
              field: 'manual_entry_reason',
              type: 'select',
              size: 4,
              placeholder: 'Select interaction reason',
              options: manualEntryReasonOptions,
            },
            {
              label: 'Duration Worked',
              field: 'hours',
              placeholder: 'Hours',
              type: 'number',
              size: 2,
            },
            {
              field: 'minutes',
              placeholder: 'Minutes',
              type: 'number',
              size: 2,
            },
          ],
          [
            {
              label: 'Date Worked',
              field: 'start_at',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
            {
              label: 'Service Type',
              field: 'service_id',
              type: 'select',
              size: 4,
              placeholder: 'Select service',
              options: serviceOptions,
            },
            {
              label: 'Care Manager',
              field: 'user_id',
              placeholder: 'Search for care manager',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/care_managers',
              size: 4,
              initialSelection: initialSelection(),
              formatTypeaheadResult: (result: any) => {
                return {
                  label: fullName(result),
                  value: result.id,
                };
              },
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Admin Entries"
      config={config}
    />
  );
};

export default AdminEntriesNew;
