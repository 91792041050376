import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AppointmentsEdit from '@/contexts/patients/modules/appointments/AppointmentsEdit';
import AppointmentsIndex from '@/contexts/patients/modules/appointments/AppointmentsIndex';
import AppointmentsNew from '@/contexts/patients/modules/appointments/AppointmentsNew';
import { Routes } from '@/utils/routeUtils';

const Patients = () => {
  return (
    <Switch>
      <Route
        path={Routes.patientsAppointmentsEdit}
        component={AppointmentsEdit}
      />
      <Route
        path={Routes.patientsAppointmentsNew}
        component={AppointmentsNew}
      />
      <Route path={Routes.patientsAppointments} component={AppointmentsIndex} />
    </Switch>
  );
};

export default Patients;
