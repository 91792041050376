export enum BrandActions {
  SET_CURRENT_BRAND = 'BRAND/SET_CURRENT_BRAND',
}

export interface SetCurrentBrand {
  type: typeof BrandActions.SET_CURRENT_BRAND;
  payload: {
    brand: string;
  };
}

export type BrandAction = SetCurrentBrand;

export const setCurrentBrand = (brand: string = 'default'): BrandAction => ({
  type: BrandActions.SET_CURRENT_BRAND,
  payload: { brand },
});
