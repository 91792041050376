import React from 'react';

import AudioPlayer from '@/components/audio/AudioPlayer';
import { selectSelectedLastCall } from '@/selectors/api';
import { formatShortDate } from '@/utils/dateUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { formatTime } from '@/utils/timeUtils';
import { useSelector } from 'react-redux';

const PatientCalls = () => {
  const call = useSelector(selectSelectedLastCall);

  if (!call || !call.data || !call.data[0]) {
    return null;
  }

  const callAttributes = call.data[0].attributes;

  return (
    <div className="patient-calls">
      <div className="columns">
        <div className="column">
          <h3 className="patient-calls__header">Last Call Overview</h3>
        </div>
      </div>

      <div className="columns">
        <div className="column is-3-tablet">
          <h4>Call Care Manager</h4>
          <span>{`${callAttributes.care_manager.first_name} ${callAttributes.care_manager.last_name}`}</span>

          <h4>Call Status</h4>
          <span>{titleizeSnakeCase(callAttributes.status)}</span>

          <h4>Call Date</h4>
          <span>{formatShortDate(callAttributes.start_at)}</span>

          <h4>Call Time</h4>
          <span>{formatTime(callAttributes.start_at)}</span>
        </div>

        <div className="column is-9-tablet">
          {callAttributes.notes && (
            <>
              <h4 className="patient-details__highlight">Call Notes</h4>
              <p className="patient-details__notes">{callAttributes.notes}</p>
            </>
          )}

          {callAttributes.recording_url && (
            <>
              <h4>Call Recording</h4>
              <AudioPlayer url={callAttributes.recording_url} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientCalls;
