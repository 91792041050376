import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const Sidebar = (props: Props) => {
  const { children } = props;

  return <nav className="sidebar">{children}</nav>;
};

export default Sidebar;
