import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setCurrentUser } from '@/actions/reducers/users';
import { flashSuccess } from '@/actions/sagas/messageSaga';
import BackLink from '@/components/button/BackLink';
import Container from '@/components/container/Container';
import Form from '@/components/form/Form';
import Page from '@/components/page/Page';
import { getCurrentUser } from '@/selectors/users';
import { FormComponentConfig } from '@/types/form';
import { Response } from '@/types/response';
import { User } from '@/types/user';
import { stateOptions } from '@/utils/dropdownUtils';
import { fillUndefinedKeys } from '@/utils/objectUtils';
import { Routes } from '@/utils/routeUtils';

const Profile = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const user = useSelector(getCurrentUser);

  const alterForm = (form: any) => {
    const { line_1, line_2, city, state, zip_code, id, ...formFields } = form;

    return {
      ...formFields,
      address_attributes: {
        line_1,
        line_2,
        city,
        state,
        zip_code,
      },
    };
  };

  const initialState = () => {
    const { address, ...attributes } = user.data.attributes;
    const { line_1, line_2, city, state, zip_code } = address || {};

    return fillUndefinedKeys({
      ...attributes,
      line_1,
      line_2,
      city,
      state,
      zip_code,
    });
  };

  const handleSuccess = (res: Response<User>) => {
    // @ts-ignore
    dispatch(setCurrentUser(res));
    dispatch(flashSuccess('Profile updated'));
    history.push(Routes.profile.show);
  };

  const config: FormComponentConfig = {
    actions: {
      alterFormBeforeSubmit: alterForm,
      path: `api/v1/users/${user.data.id}`,
      model: 'user',
      method: 'PUT',
      onSuccess: handleSuccess,
      onCancel: () => history.push(Routes.profile.show),
    },
    state: initialState(),
    rules: {
      first_name: { required: true },
      last_name: { required: true },
      email: { required: true, type: 'email' },
    },
    layout: [
      {
        title: 'Profile Details',
        renderPanel: true,
        fields: [
          [
            {
              label: 'First Name',
              field: 'first_name',
              placeholder: 'Enter first Name',
              type: 'text',
              size: 4,
            },
            {
              label: 'Last Name',
              field: 'last_name',
              placeholder: 'Enter last Name',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Email Address',
              field: 'email',
              placeholder: 'Enter email Address',
              type: 'text',
              size: 4,
            },
            {
              label: 'Extension',
              disableIf: () => true,
              field: 'phone_number_extension',
              placeholder: 'Enter extension',
              size: 4,
            },
          ],
        ],
      },
      {
        title: 'Profile Details',
        renderPanel: true,
        fields: [
          [
            {
              label: 'Street Address',
              field: 'line_1',
              placeholder: 'Enter street address',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Address Line 2',
              field: 'line_2',
              placeholder: 'Enter address',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'City',
              field: 'city',
              placeholder: 'Enter city',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'State',
              field: 'state',
              placeholder: 'Select state',
              type: 'select',
              options: stateOptions,
              size: 2,
            },
            {
              label: 'Zip Code',
              field: 'zip_code',
              placeholder: 'Enter zip code',
              type: 'zipcode',
              size: 2,
            },
          ],
        ],
      },
    ],
  };

  return (
    <Page>
      <Container>
        <BackLink to={Routes.profile.show}>Cancel</BackLink>
      </Container>

      <Form config={config} />
    </Page>
  );
};

export default Profile;
