import React from 'react';
import { useHistory } from 'react-router-dom';

import { FormComponentConfig } from '@/types/form';
import { stateOptions, statusOptions } from '@/utils/dropdownUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import HealthSystemForm from './components/HealthSystemForm';

interface Props {
  hidePanel?: boolean;
  isModal?: boolean;
  modalBackPath?: string;
}

const HealthSystemsNew = (props: Props) => {
  const { hidePanel, isModal, modalBackPath } = props;

  const history = useHistory();

  const backPath = modalBackPath
    ? modalBackPath
    : buildPath(Routes.healthSystems);

  const colSize = isModal ? 12 : 4;
  const addressColSize = isModal ? 6 : 2;

  const alterForm = (form: any) => {
    const { line_1, line_2, city, state, zip_code, ...formFields } = form;

    return {
      ...formFields,
      address_attributes: {
        line_1,
        line_2,
        city,
        state,
        zip_code,
      },
    };
  };

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      alterFormBeforeSubmit: alterForm,
      path: 'api/v1/health_systems',
      model: 'health_system',
      method: 'POST',
      onSuccess: () => {
        history.push(backPath);
      },
      onCancel: () => history.push(backPath),
      submitText: 'Save',
    },
    state: {
      image_id: '',
      name: '',
      phone_number: '',
      status: 'active',
      line_1: '',
      line_2: '',
      city: '',
      state: '',
      zip_code: '',
    },
    rules: {
      name: { required: true },
      phone_number: { required: true, type: 'phone' },
      status: { required: true },
      line_1: { required: true },
      city: { required: true },
      state: { required: true },
      zip_code: { required: true },
    },
    layout: [
      {
        title: 'Health System Details',
        renderPanel: hidePanel ? false : true,
        fields: [
          [
            {
              label: 'Health System Name',
              field: 'name',
              placeholder: 'Enter Health System Name',
              type: 'text',
              size: colSize,
            },
          ],
          [
            {
              label: 'Phone Number',
              field: 'phone_number',
              placeholder: 'Enter phone number',
              type: 'phonenumber',
              size: colSize,
            },
          ],
          [
            {
              label: 'Status',
              field: 'status',
              placeholder: 'Select status',
              type: 'select',
              options: statusOptions,
              size: colSize,
            },
          ],
          [
            {
              label: 'Logo',
              field: 'image_id',
              type: 'image',
              size: colSize,
            },
          ],
        ],
      },
      {
        title: 'Address',
        renderPanel: hidePanel ? false : true,
        fields: [
          [
            {
              label: 'Street Address',
              field: 'line_1',
              placeholder: 'Enter street address',
              type: 'text',
              size: colSize,
            },
          ],
          [
            {
              label: 'Address Line 2',
              field: 'line_2',
              placeholder: 'Enter address',
              type: 'text',
              size: colSize,
            },
          ],
          [
            {
              label: 'City',
              field: 'city',
              placeholder: 'Enter city',
              type: 'text',
              size: colSize,
            },
          ],
          [
            {
              label: 'State',
              field: 'state',
              placeholder: 'Select state',
              type: 'select',
              options: stateOptions,
              size: addressColSize,
            },
            {
              label: 'Zip Code',
              field: 'zip_code',
              placeholder: 'Enter zip code',
              type: 'zipcode',
              size: addressColSize,
            },
          ],
        ],
      },
    ],
  };

  const renderHealthSystemForm = () => {
    if (isModal) {
      return (
        <HealthSystemForm
          backPath={backPath}
          config={config}
          removePanel={true}
        />
      );
    }

    return (
      <div className="health-system-new">
        <HealthSystemForm
          backPath={backPath}
          backText="Cancel"
          config={config}
          removePanel={true}
        />
      </div>
    );
  };

  return renderHealthSystemForm();
};

export default HealthSystemsNew;
