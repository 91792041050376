import React, { useEffect, useMemo, useState } from 'react';

import Icon from '@/components/icon/Icon';
import { SideEffect } from '@/types/generic';
import { usePrevious } from '@/utils/stateUtils';
import Typeahead, { FormattedResult } from './Typeahead';

interface Result {
  label: string;
  value: string;
}

export interface Props {
  formatResult: (result: any) => FormattedResult;
  initialValue?: any[];
  isDisabled?: boolean;
  isInvalid?: boolean;
  maxSelections?: number;
  onChange?: SideEffect;
  placeholder?: string;
  searchPath: string;
  value: string[];
}

const TypeaheadSelect = (props: Props) => {
  const {
    isDisabled,
    initialValue,
    maxSelections,
    onChange,
    value,
    ...typeaheadProps
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<any[]>(
    initialValue || [],
  );
  const [typeaheadValue, setTypeaheadValue] = useState<string>('');

  const previousOptions = usePrevious(selectedOptions);

  const isAtMax = maxSelections && value.length >= maxSelections;

  useEffect(() => {
    if (
      typeof previousOptions === 'undefined' ||
      // @ts-ignore
      selectedOptions.length > previousOptions.length
    ) {
      setTypeaheadValue('');
    }

    if (onChange && typeof previousOptions !== 'undefined') {
      onChange(selectedOptions.map((option: Result) => option.value));
    }
  }, [selectedOptions]);

  const handleRemoveOption = (optionValue: string) => {
    setSelectedOptions(
      selectedOptions.filter((option: Result) => option.value !== optionValue),
    );
  };

  const handleSelectResult = (textValue: string, objectValue: any) => {
    if (objectValue && !isAtMax) {
      setTypeaheadValue(textValue);
      setSelectedOptions([...selectedOptions, objectValue]);
    }
  };

  const renderSelections = () => {
    return selectedOptions.map((option: Result) => {
      return (
        <div className="typeahead-select__option" key={option.value}>
          <span>{option.label}</span>
          <a onClick={() => handleRemoveOption(option.value)}>
            <Icon icon="close" size="tiny" />
          </a>
        </div>
      );
    });
  };

  const ignoreValues = useMemo(() => {
    return (selectedOptions || []).map((option: Result) => option.value);
  }, [selectedOptions]);

  return (
    <div className="typeahead-select">
      <div className="typeahead-select__search">
        <Typeahead
          isDisabled={isAtMax || isDisabled}
          guideValue={typeaheadValue}
          ignoreValues={ignoreValues}
          onChange={handleSelectResult}
          {...typeaheadProps}
        />
      </div>

      <div className="typeahead-select__results">{renderSelections()}</div>
    </div>
  );
};

export default TypeaheadSelect;
