import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { selectUnreadAlertCount } from '@/selectors/users';
import { Routes } from '@/utils/routeUtils';
import NavLinkAccent from './NavLinkAccent';

export const SpecialistLinksLeft = () => {
  return (
    <>
      <NavLink
        className="header__nav-item"
        activeClassName="header__nav-item is-active"
        exact
        to={Routes.provider.patients.root}>
        Patients
      </NavLink>

      <NavLink
        className="header__nav-item"
        activeClassName="header__nav-item is-active"
        exact
        to={Routes.provider.appointments.root}>
        Appointments
      </NavLink>
    </>
  );
};

export const SpecialistLinksRight = () => {
  const alertCount = useSelector(selectUnreadAlertCount);

  return (
    <>
      <NavLink
        className="header__nav-item"
        activeClassName="header__nav-item is-active"
        exact
        to={Routes.provider.alerts.root}>
        Alerts
        {alertCount > 0 && <NavLinkAccent>{alertCount}</NavLinkAccent>}
      </NavLink>

      <NavLink
        className="header__nav-item"
        activeClassName="header__nav-item is-active"
        exact
        to={Routes.provider.practice.show.providers}>
        Practice
      </NavLink>
    </>
  );
};
