import React, { useMemo } from 'react';

import {
  ImplicationResponseAttributes,
  TrackingQuestionSet,
} from '@/types/tracking_question';
import { TrackingAnswer } from './TrackingQuestionManager';

interface Props {
  answers: TrackingAnswer[];
  questionSet: TrackingQuestionSet;
}

const ImplicationDisplay = (props: Props) => {
  const { answers, questionSet } = props;

  const questionIds = useMemo(() => {
    return questionSet.attributes.tracking_questions.map(q => q.id);
  }, [questionSet]);

  const implicationValue = useMemo(() => {
    const relevantAnswers = answers.filter(a =>
      questionIds.includes(a.tracking_question_id),
    );

    return relevantAnswers.reduce((total: number, answer: TrackingAnswer) => {
      return total + answer.value;
    }, 0);
  }, [answers, questionIds]);

  const orderedImplications = useMemo(() => {
    const sortDescending = (
      a: ImplicationResponseAttributes,
      b: ImplicationResponseAttributes,
    ) => {
      if (a.minimum_threshold === b.minimum_threshold) {
        return 0;
      }

      return a.minimum_threshold > b.minimum_threshold ? -1 : 1;
    };

    return questionSet.attributes.implication_responses.sort(sortDescending);
  }, [questionSet]);

  const implicationText = useMemo(() => {
    for (const implication of orderedImplications) {
      if (implicationValue >= implication.minimum_threshold) {
        return implication.text;
      }
    }

    return 'No implication';
  }, [implicationValue, orderedImplications]);

  return (
    <div className="tracking-implication">
      <div className="tracking-implication__value">
        <h5>Patient Total</h5>
        <span>{implicationValue}</span>
      </div>

      <p>{implicationText}</p>
    </div>
  );
};

export default ImplicationDisplay;
