import { createSelector } from 'reselect';

import AppState from '@/types/app';
import { ApplicationState } from '@/types/application';

export const selectApplicationState = (state: AppState): ApplicationState =>
  state.application;

export const selectisFetchingInitialData = createSelector(
  selectApplicationState,
  state => state.isFetchingInitialData,
);
