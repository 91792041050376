import React from 'react';

import { EmptyBlock } from '@/components/dashboard';
import { HighAlertPatient } from '@/state/rpm';
import PatientBlock from '../components/PatientBlock';

interface Props {
  patients: HighAlertPatient[];
}

const PatientsHighAlerts = ({ patients }: Props): JSX.Element => {
  const renderReadings = (() => {
    if (patients.length) {
      return patients.map(patient => (
        <div className="column is-4" key={patient.patient_id}>
          <PatientBlock patient={patient} />
        </div>
      ));
    }

    return (
      <div className="column">
        <EmptyBlock style="compact">No readings</EmptyBlock>
      </div>
    );
  })();

  return <div className="columns">{renderReadings}</div>;
};

export default PatientsHighAlerts;
