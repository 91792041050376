import React from 'react';
import { useResponsive } from 'react-hooks-responsive';

import Panel from '@/components/panel/Panel';
import { Sidebar, SidebarLink } from '@/components/sidebar';
import { buildPath, Routes } from '@/utils/routeUtils';
import { breakpoints } from '@/utils/screenUtils';
import { NavLink } from 'react-router-dom';
import { getResourceName, PortalSideBarLinks } from './helpers/links';

const PortalSidebar = () => {
  const { screenIsAtMost } = useResponsive(breakpoints);

  const isTouch = screenIsAtMost('tablet');

  const renderSideBar = () => {
    if (isTouch) {
      return (
        <div className="sidebar-scroll">
          {PortalSideBarLinks.map((link, index) => {
            return (
              <NavLink
                activeClassName="sidebar-scroll__link is-active"
                className="sidebar-scroll__link"
                key={index}
                to={buildPath(Routes.adminResource, { resource: link })}>
                {getResourceName(link)}
              </NavLink>
            );
          })}
        </div>
      );
    }

    return (
      <Panel withNarrowPadding>
        <Sidebar>
          {PortalSideBarLinks.map((link, index) => {
            return (
              <SidebarLink
                key={index}
                to={buildPath(Routes.adminResource, { resource: link })}>
                {getResourceName(link)}
              </SidebarLink>
            );
          })}
        </Sidebar>
      </Panel>
    );
  };

  return renderSideBar();
};

export default PortalSidebar;
