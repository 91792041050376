import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Section from '@/components/form/Section';
import Panel from '@/components/panel/Panel';
import { getSelectedPatient } from '@/selectors/api';
import { selectPracticeDeviceModels } from '@/selectors/patients';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { IdParams } from '@/types/params';
import Api from '@/api/Api';
import Modeler from '@/utils/modeler/modeler';
import { Device } from '@/types/v2/device';
import useNotifier from '@/utils/messages/useNotifier';
import useLoadingState from '@/utils/api/useLoadingState';
import { PatientDevice } from '@/types/v2/patient_device';
import Datepicker from '@/components/form/Datepicker';

const DevicesEdit = () => {
  const patient = useSelector(getSelectedPatient);
  const practiceDevices = useSelector(selectPracticeDeviceModels);
  const hasPracticeDevices = !!practiceDevices.length;

  const history = useHistory();
  const notifier = useNotifier();
  const loader = useLoadingState('device');

  const { id } = useParams<IdParams>();

  const [query] = returnTabQuery();

  const [device, setDevice] = useState<Device>();
  const [patientDevice, setPatientDevice] = useState<PatientDevice>();
  const [issueDate, setIssueDate] = useState<string>();

  const backPath = buildPath(Routes.patientsRemotePatientCare, { id }, query);

  const getDevice = async () => {
    loader.startLoading('device');

    try {
      const url = buildPath(Routes.api2.patientDevice, { id: id }, {}, [
        'device',
      ]);

      const response = await Api.utility.get(url);

      const resource = new Modeler<PatientDevice>(response.data, {
        generations: 2,
      }).build();

      setPatientDevice(resource);
      setIssueDate(resource.issue_date);
      setDevice(resource.device);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('device');
  };

  useEffect(() => {
    getDevice();
  }, []);

  const handleBackPath = () => {
    history.push(backPath);
  };

  const handleRemoveDevice = async () => {
    try {
      const url = buildPath(`/api/v2/patient_devices/${patientDevice.id}/deactivate`, {}, {}, ['device'])
      await Api.utility.patch(url);
      history.push(backPath);
      notifier.success('Device removed from patient');
    } catch (err) {
      notifier.error(err);
    }
  };

  const handleClickSubmit = async () => {
    try {
      const url = buildPath(Routes.api2.patientDevice, {
        id: patientDevice.id,
      });

      const body = {
        patient_device: {
          issue_date: issueDate,
        },
      };

      await Api.utility.patch(url, body);

      history.push(backPath);
      notifier.success('Device updated');
    } catch (err) {
      notifier.error(err);
    }
  };

  if (loader.isLoading('device') || !device) {
    return <ActivityIndicator />;
  }

  return (
    <div className="patient-remote-care-new">
      <Container>
        <BackLink to={backPath}>Back to Devices</BackLink>
      </Container>

      <Constraint size="large">
        <Panel>
          <Section title="Edit Device">
            <div className="columns">
              <div className="column is-4">
                <Datepicker
                  label="Issue Date"
                  onChange={value => setIssueDate(value)}
                  value={issueDate}
                />
              </div>
            </div>
          </Section>
        </Panel>

        <div className="form__actions">
          <div className="form__actions-left patient-remote-care-edit__remove-button">
            <Button style="delete" onClick={handleRemoveDevice}>
              Remove Device from Patient
            </Button>
          </div>
          <div className="form__actions-right">
            <Button color="white" onClick={handleBackPath}>
              Cancel
            </Button>

            <Button color="secondary" onClick={handleClickSubmit}>
              Save
            </Button>
          </div>
        </div>
      </Constraint>
    </div>
  );
};

export default DevicesEdit;
