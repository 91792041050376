import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { flashError } from '@/actions/sagas/messageSaga';
import Label from '@/components/form/Label';
import { SideEffect } from '@/types/generic';
import {
  fileToBase64,
  isFileSizeUnderLimit,
  maxFileSize,
} from '@/utils/fileUtils';
import { randomString } from '@/utils/stringUtils';
import ErrorMessage from './ErrorMessage';
import FileSelect from './FileSelect';

interface File {
  _destroy?: boolean;
  id?: string;
  data: any;
  name: string;
  type: string;
  url?: string;
}

interface Props {
  fileType?: string;
  isInvalid?: boolean;
  label: string;
  onChange?: SideEffect;
  validationMessage?: string;
  value: File[];
}

const FileUpload = (props: Props) => {
  const {
    fileType,
    isInvalid,
    label,
    onChange,
    validationMessage,
    value: files,
  } = props;

  const [inputId] = useState(randomString());

  const dispatch = useDispatch();

  const addFile = async file => {
    const data = await fileToBase64(file);

    const newFile = {
      data,
      id: randomString(),
      name: file.name,
    };

    if (onChange) {
      onChange([...files, newFile]);
    }
  };

  const handleChangeFile = e => {
    const selectedFiles = e.target.files;

    if (selectedFiles) {
      for (const file of selectedFiles) {
        if (isFileSizeUnderLimit(file)) {
          addFile(file);
        } else {
          dispatch(flashError(`File size cannot exceed ${maxFileSize}`));
        }
      }
    }
  };

  const handleRemoveFile = (file: File) => {
    if (!onChange) {
      return;
    }

    if (file.url) {
      const newFiles = files.filter(f => f.id !== file.id);
      newFiles.push({
        ...file,
        _destroy: true,
      });

      onChange(newFiles);
    } else {
      onChange(files.filter(f => f.id !== file.id));
    }
  };

  const renderFiles = () => {
    return files
      .filter(file => !file._destroy)
      .map((file, index) => {
        return (
          <FileSelect
            file={file}
            key={index}
            onRemove={() => handleRemoveFile(file)}
          />
        );
      });
  };

  return (
    <div className="form-file-upload">
      <Label isInvalid={isInvalid}>{label}</Label>
      <div className="form-file-upload__content">
        <div className="form-file-upload__input">
          <label htmlFor={inputId}>Upload</label>
          <input
            accept={fileType}
            onChange={handleChangeFile}
            id={inputId}
            type="file"
          />
        </div>
        <div className="form-file-upload__list">{renderFiles()}</div>
      </div>
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </div>
  );
};

export default FileUpload;
