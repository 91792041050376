import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { refreshPatientAction } from '@/actions/sagas/patientSaga';
import Button from '@/components/button/Button';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import { getSelectedPatient } from '@/selectors/api';
import { FormComponentConfig } from '@/types/form';
import useGetRequest from '@/utils/api/useGetRequest';
import usePostRequest from '@/utils/api/usePostRequest';
import { stateOptions } from '@/utils/dropdownUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useDispatch, useSelector } from 'react-redux';
import PatientForm from '../../components/PatientForm';
import { isEnrollmentPath } from '../../utils/patientUtils';
import { IdParams } from '@/types/params';

interface Params extends IdParams {
  step: string;
}

const SpecialistsNew = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { id, step } = useParams<Params>();

  const patient = useSelector(getSelectedPatient);

  const [specialistId, setSpecialistId] = useState('');
  const [specialists, setSpecialists] = useState([]);

  const isEnrollment = isEnrollmentPath();

  const [getSpecialists, isLoadingSpecialists] = useGetRequest({
    dispatch,
    url: buildPath(
      'api/v1/specialists',
      {},
      { practice_id: patient.attributes.practice_id },
    ),
    model: 'specialist',
    onSuccess: (res: any) => {
      const formatted = res.data.map(item => ({
        label: item.attributes.name,
        value: item.id,
      }));

      setSpecialists(formatted);
    },
  });

  useEffect(() => {
    getSpecialists();
  }, []);

  const handleAssignSuccess = () => {
    dispatch(refreshPatientAction(id));
    history.push(backPath);
  };

  const [addProvider, isAssingProvider] = usePostRequest({
    dispatch,
    url: `api/v1/specialists/${specialistId}/assign`,
    body: {
      patient_id: id,
    },
    onSuccess: handleAssignSuccess,
  });
  const handleAddProvider = () => {
    addProvider();
  };

  const backPath = (() => {
    const queryParams = new URLSearchParams(location.search || '');
    const returnTo = queryParams.get('return_to');

    if (returnTo) {
      return returnTo;
    }

    return isEnrollment
      ? buildPath(Routes.patientsEnrollment, { id, step })
      : buildPath(Routes.patientsSpecialists, { id });
  })();

  const alterForm = (form: any) => {
    const { line_1, line_2, city, state, zip_code, ...formFields } = form;

    return {
      ...formFields,
      patient_id: id,
      practice_id: patient.attributes.practice_id,
      address_attributes: {
        line_1,
        line_2,
        city,
        state,
        zip_code,
      },
    };
  };

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      alterFormBeforeSubmit: alterForm,
      path: 'api/v1/specialists',
      model: 'specialist',
      mergeFields: {
        patient_id: id,
      },
      method: 'POST',
      onSuccess: () => {
        dispatch(refreshPatientAction(id));
        history.push(backPath);
      },
      onCancel: () => history.push(backPath),
    },
    state: {
      name: '',
      specialty: '',
      email: '',
      phone_number: '',
      fax_number: '',
      line_1: '',
      line_2: '',
      city: '',
      state: '',
      zip_code: '',
    },
    rules: {
      name: { required: true },
      specialty: { required: true },
      email: { required: true, type: 'email' },
      phone_number: { required: true, type: 'phone' },
      fax_number: { type: 'phone' },
    },
    layout: [
      {
        title: 'Create New Specialist',
        fields: [
          [
            {
              label: 'Provider',
              field: 'name',
              placeholder: 'Enter provider name',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Specialty',
              field: 'specialty',
              placeholder: 'Search for specialty',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/specialty_types',
              size: 8,
              formatTypeaheadResult: (result: any) => {
                return {
                  label: result.attributes.name,
                  value: result.attributes.name,
                };
              },
            },
          ],
          [
            {
              label: 'Email Address',
              field: 'email',
              placeholder: 'Enter email address',
              type: 'text',
              size: 4,
            },
            {
              label: 'Phone Number',
              field: 'phone_number',
              placeholder: 'Enter phone number',
              type: 'phonenumber',
              size: 4,
            },
            {
              label: 'Fax Number',
              field: 'fax_number',
              placeholder: 'Enter fax number',
              type: 'phonenumber',
              size: 4,
            },
          ],
          [
            {
              label: 'Street Address',
              field: 'line_1',
              placeholder: 'Enter street address',
              type: 'text',
              size: 4,
            },
            {
              label: 'Address Line 2',
              field: 'line_2',
              placeholder: 'Enter address',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'City',
              field: 'city',
              placeholder: 'Enter city',
              type: 'text',
              size: 4,
            },
            {
              label: 'State',
              field: 'state',
              placeholder: 'Select state',
              type: 'select',
              options: stateOptions,
              size: 4,
            },
            {
              label: 'Zip Code',
              field: 'zip_code',
              placeholder: 'Enter zip code',
              type: 'zipcode',
              size: 4,
            },
          ],
        ],
      },
    ],
  };

  const renderSelectPatient = () => {
    return (
      <>
        <Section title="Select Provider">
          <div className="columns">
            <div className="column is-4">
              <SelectGroup
                label="Provider"
                onChange={value => setSpecialistId(value)}
                options={specialists}
                value={specialistId}
              />
            </div>
          </div>
        </Section>
        <div className="form__actions">
          <div className="form__actions-left" />
          <div className="form__actions-right">
            <Button
              color="secondary"
              isDisabled={!specialistId || isAssingProvider}
              onClick={handleAddProvider}>
              Add Provider
            </Button>
          </div>
        </div>
        <hr />
      </>
    );
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Specialists"
      config={config}>
      {renderSelectPatient()}
    </PatientForm>
  );
};

export default SpecialistsNew;
