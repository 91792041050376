import React from 'react';

import Icon, { Size } from '@/components/icon/Icon';
import { Nullable } from '@/types/generic';

export type Level = 'high' | 'medium' | 'low';

interface Props {
  level: Nullable<Level>;
  size?: Size;
}

const AlertLevel = (props: Props) => {
  const { level, size } = props;

  if (!level) {
    return null;
  }

  return <Icon icon={level} size={size} />;
};

export default AlertLevel;
