import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  htmlFor?: string;
  isInvalid?: boolean;
}

export const Label = (props: Props) => {
  const { children, htmlFor, isInvalid } = props;

  return <label className="form-label">{children}</label>;
};

export default Label;
