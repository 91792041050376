import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import useGetRequest from '@/utils/api/useGetRequest';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { IdParams } from '@/types/params';

const HospitalsShow = () => {
  const dispatch = useDispatch();

  const { id } = useParams<IdParams>();

  const [hospital, setHospital] = useState(null);

  const backPath = buildPath(Routes.hospitals);
  const editPath = buildPath(Routes.hospitalsEdit, { id });

  const [getHospital, isLoadingHospital] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    id,
    model: 'hospitals',
    onSuccess: (response: any) => {
      setHospital(response);
    },
  });

  useEffect(() => {
    getHospital();
  }, []);

  const renderLocations = () => {
    return hospital.data.attributes.addresses.map(address => {
      const hospitalPath = buildPath(Routes.hospitalsShow, { id: hospital.id });

      return (
        <Panel key={address.id} withNarrowMargin>
          <Container>
            <div className="hospital-show__wrapper">
              <h4>Address</h4>
              <p>{address.line_1}</p>
              <p>{address.line_2}</p>
              <p>{`${address.city}, ${address.state}, ${address.zip_code}`}</p>
            </div>
          </Container>
        </Panel>
      );
    });
  };

  if (!hospital || isLoadingHospital) {
    return <ActivityIndicator />;
  }

  const attributes = hospital.data.attributes;

  const renderLogo = () => {
    if (attributes.logo_thumbnail_url) {
      return (
        <div className="panel__header-logo">
          <img src={attributes.logo_thumbnail_url} />
        </div>
      );
    }
  };

  return (
    <div className="hospital-show">
      <Container>
        <BackLink to={backPath}>Back</BackLink>
      </Container>

      <Constraint size="large">
        <Panel withNarrowMargin>
          <Container>
            <div className="hospital-show__header-wrapper">
              <div className="panel__header">
                {renderLogo()}
                <h2>{attributes.name}</h2>
              </div>

              <span className="hospital-show__subheader">
                {formatPhoneNumber(
                  hospital.data.attributes.twillio_phone_number,
                )}
              </span>
              <span className="hospital-show__subheader">
                {hospital.data.attributes.health_system.name}
              </span>
            </div>

            <div className="hospital-show__wrapper">
              <Link className="hospital-show__link" to={editPath}>
                Edit Hospital
              </Link>
            </div>
          </Container>
        </Panel>

        <Container>
          <h3>Locations</h3>
        </Container>

        {renderLocations()}
      </Constraint>
    </div>
  );
};

export default HospitalsShow;
