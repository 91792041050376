import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@/components/button/Button';
import SelectGroup from '@/components/form/SelectGroup';
import { Patient } from '@/types/patient';
import { monthOptions, renderYearOptions } from '@/utils/dropdownUtils';

interface Props {
  link: string;
  linkText: string;
  month: string;
  onApplyClick: VoidFunction;
  onMonthUpdate: (value: string) => void;
  onYearUpdate: (value: string) => void;
  patient: Patient;
  year: string;
}

const PatientTableHeader = (props: Props) => {
  const {
    link,
    linkText,
    month,
    onApplyClick,
    onMonthUpdate,
    onYearUpdate,
    patient,
    year,
  } = props;

  const yearOptions = renderYearOptions(patient.attributes.enrollment_date);

  return (
    <div className="columns">
      <div className="column is-12-mobile is-6-tablet">
        <div className="columns table__filter-wrapper">
          <div className="column is-5">
            <SelectGroup
              label="Year"
              onChange={value => onYearUpdate(value)}
              options={yearOptions}
              placeholder="Filter year"
              value={year}
            />
          </div>

          <div className="column is-5">
            <SelectGroup
              label="Month"
              onChange={value => onMonthUpdate(value)}
              options={monthOptions}
              placeholder="Filter month"
              value={month}
            />
          </div>

          <div className="column is-2">
            <Button color="white" onClick={onApplyClick}>
              Apply
            </Button>
          </div>
        </div>
      </div>

      <div className="column is-12-mobile is-6-tablet table__button-wrapper">
        <Link className="button is-secondary" to={link}>
          {linkText}
        </Link>
      </div>
    </div>
  );
};

export default PatientTableHeader;
