import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { SideEffect } from '@/types/generic';

export type Color =
  | 'primary'
  | 'error'
  | 'secondary'
  | 'white'
  | 'alt-1'
  | 'alt-1-outline';

type Style =
  | 'filled'
  | 'flat'
  | 'flat primary'
  | 'delete'
  | 'flat-large'
  | 'flat-large add'
  | 'text'
  | 'dropdown'
  | 'dropdown back'
  | 'icon'
  | 'icon sort'
  | 'narrow'
  | 'hide';

interface Props {
  children: ReactNode;
  color?: Color;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isCompressed?: boolean;
  isSubmitting?: boolean;
  onClick?: SideEffect;
  style?: Style;
}

const Button = (props: Props) => {
  const {
    children,
    color,
    isDisabled,
    isFullWidth,
    isCompressed,
    isSubmitting,
    onClick,
    style,
  } = props;

  const styles = classNames('button', {
    'is-alt-1': color === 'alt-1',
    'is-alt-1-outline': color === 'alt-1-outline',
    'is-compressed': isCompressed,
    'is-delete': style === 'delete',
    'is-disabled': isDisabled,
    'is-dropdown back': style === 'dropdown back',
    'is-dropdown': style === 'dropdown',
    'is-error': color === 'error',
    'is-flat': style === 'flat',
    'is-flat primary': style === 'flat primary',
    'is-flat-large': style === 'flat-large',
    'is-flat-large add': style === 'flat-large add',
    'is-full-width': isFullWidth,
    'is-icon sort': style === 'icon sort',
    'is-icon': style === 'icon',
    'is-narrow': style === 'narrow',
    'is-primary': color === 'primary',
    'is-secondary': color === 'secondary',
    'is-text': style === 'text',
    'is-white': color === 'white',
    'is-hidden': style === 'hide',
  });

  return (
    <button
      className={styles}
      disabled={isDisabled || isSubmitting}
      onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
