import { Nullable, UUID } from '../generic';
import { Patient } from './patient';

export type DeviceReadingLabel =
  | 'blood_glucose'
  | 'blood_pressure'
  | 'blood_oxygen'
  | 'weight';
export type DeviceReadingValue = 'value_1' | 'value_2' | 'value_3';
export type DeviceReadingAlertLevel = 'high' | 'medium' | 'low';
export type ReadingType = '1' | '2' | '7' | '8';

export interface DeviceReading {
  id: UUID;
  alert_level: Nullable<DeviceReadingAlertLevel>;
  reading_label: DeviceReadingLabel;
  reading_time: string;
  reading_time_local: string;
  reading_type: ReadingType;
  time_slot: string;
  value_1: string;
  value_2: string;
  value_3: string;
  patient: Patient;
}

export const deviceReadingValues: DeviceReadingValue[] = [
  'value_1',
  'value_2',
  'value_3',
];

export const valueLabel = (
  value: DeviceReadingValue,
  type: DeviceReadingLabel,
): string => {
  switch (type) {
    case 'blood_glucose':
      return (
        {
          value_1: 'mg/dL',
        }[value] || ''
      );

    case 'blood_oxygen':
      return (
        {
          value_1: 'spO2 (%)',
          value_2: 'Pulse (Beats/Min)',
        }[value] || ''
      );

    case 'blood_pressure':
      return (
        {
          value_1: 'SYS (mmHg)',
          value_2: 'DIA (mmHg)',
          value_3: 'Pulse (Beats/Min)',
        }[value] || ''
      );
    case 'weight':
      return (
        {
          value_1: 'lbs',
        }[value] || ''
      );
  }
};
