import { createSelector } from 'reselect';

import { RpmState } from '@/state/rpm';
import { AppState } from '@/types/app';

export const selectRpmState = (state: AppState): RpmState => state.rpm;

export const selectHasRpmPatients = createSelector(
  selectRpmState,
  state => !!state.hasRpmPatients,
);

export const hasAttemptedPatientFetch = createSelector(
  selectHasRpmPatients,
  hasPatients => hasPatients !== null,
);

export const selectIsDashboardCollapsed = createSelector(
  selectRpmState,
  state => state.isDashboardCollapsed,
);

export const selectIsFetchingDashboard = createSelector(
  selectRpmState,
  state => state.isFetchingDashboard,
);

export const selectDashboardLastLoadedAt = createSelector(
  selectRpmState,
  state => state.dashboardLastLoadedAt,
);

export const selectCareManagerData = createSelector(
  selectRpmState,
  state => state.careManagerData,
);

export const selectCareManagerDailyHighAlerts = createSelector(
  selectCareManagerData,
  data => data.dailyHighAlerts,
);

export const selectCareManagerLatestReadings = createSelector(
  selectCareManagerData,
  data => data.latestReadings,
);

export const selectCareManagerHighAlertPatients = createSelector(
  selectCareManagerData,
  data => data.highAlertPatients,
);

export const selectAdminData = createSelector(
  selectRpmState,
  state => state.adminData,
);

export const selectAdminHighAlerts = createSelector(
  selectAdminData,
  data => data.careManagerHighAlerts,
);

export const selectAdminMostCalls = createSelector(
  selectAdminData,
  data => data.careManagerMostCalls,
);

export const selectAdminNoReadings = createSelector(
  selectAdminData,
  data => data.careManagerNoReadings,
);
