type Nullable<T> = T | null;

type AttributeValue = boolean | null | Model | number | string;

interface Attributes {
  [key: string]: AttributeValue;
}

interface DataModel {
  data: IdModel;
}

interface IdModel {
  id: string;
  type: string;
}

interface AttributeModel extends IdModel {
  attributes: Attributes;
  relationships?: Relationships;
}

type GetAttributes =
  | AttributeValue
  | AttributeValue[]
  | Attributes
  | Attributes[];

interface ModelData {
  data: AttributeModel | AttributeModel[];
  included?: AttributeModel[];
}

interface Model {
  [key: string]: any;
}

interface Relationships {
  [key: string]: DataModel;
}

interface Modeler<T> {
  build(): T;
  get(...attributes: string[]): GetAttributes;
}

type ModelerModel<T> = T | {};

interface Options {
  generations?: number;
  includes?: Nullable<AttributeModel[]>;
}

class Modeler<T> {
  private _data: Nullable<AttributeModel | AttributeModel[]> = null;
  private _generation: number = 1;
  private _indexData = [];
  private _includes: AttributeModel[] = [];
  private _isIndex: boolean = false;
  private _isIndexBuilt: boolean = false;
  private _isModelBuilt: boolean = false;
  private _model: ModelerModel<T> | ModelerModel<T>[];

  public constructor(data: any, options: Options = {}) {
    // If the provided data is an invalid JSON format the model returned is null
    if (!data || typeof data !== 'object' || !('data' in data) || !data.data) {
      this._data = {
        id: null,
        type: null,
        attributes: {},
      };

      this._model = null;
      this._isModelBuilt = true;

      return null;
    }

    this._data = data.data;

    if (Array.isArray(this._data)) {
      this._isIndex = true;
      this._model = [];
    } else {
      this._model = {};
    }

    if (options.includes) {
      this._includes = options.includes;
    } else if (data.included) {
      this._includes = data.included;
    }

    // Set the current generation of the model. If null, no more generations will
    // be fetched
    if (typeof options.generations === 'number') {
      if (options.generations < 0) {
        throw new Error('Invalid generations argument');
      }

      this._generation = options.generations;
    }
  }

  // build() will create the full model and store it as cached data
  public build = (): T => {
    if (this._isIndex) {
      return this.buildIndex();
    }

    return this.buildModel();
  };

  // get() returns only a sample of attributes/relationships from the provided data
  public get = (...attributes: string[]): GetAttributes => {
    if (this._isIndex) {
      return this.getIndexAttributes(attributes);
    }

    return this.getModelAttributes(attributes);
  };

  // ***************
  // PRIVATE METHODS
  // ***************

  private decrementGeneration = () => {
    return this._generation > 0 ? this._generation - 1 : 0;
  };

  // For an index (array) of data, create a Modeler instance for each one and build() it
  private buildIndex = () => {
    if (this._isIndexBuilt) {
      return this._indexData.map((entity: Modeler<T>) => entity.build());
    } else {
      this.createIndexModelers();
      this._isIndexBuilt = true;

      return this.buildIndex();
    }
  };

  // Build a single, non array data model
  private buildModel = (): T => {
    if (this._isModelBuilt) {
      return this._model as T;
    }

    const data = this._data as AttributeModel;

    // The ID of the model is added as a top level attribute alongside all others
    const keys = ['id', ...Object.keys(data.attributes || {})];

    if ('relationships' in data && this._generation !== 0) {
      keys.push(...Object.keys(data.relationships));
    }

    this.getModelAttributes(keys);

    this._isModelBuilt = true;

    return this._model as T;
  };

  private createIndexModelers = () => {
    for (const entity of this._data as AttributeModel[]) {
      const modeler = new Modeler(this.createModelData(entity), {
        includes: this._includes,
        generations: this._generation,
      });

      this._indexData.push(modeler);
    }
  };

  private createModelData = (model: AttributeModel): ModelData => {
    return {
      data: model,
    };
  };

  private findRelationship = (id: string, type: string) => {
    return this._includes.find((include: AttributeModel) => include.id === id);
  };

  private getIndexAttributes = (attributes: string[]) => {
    if (this._isIndexBuilt) {
      return this._indexData.map((entity: Modeler<T>) =>
        entity.get(...attributes),
      );
    } else {
      this.createIndexModelers();
      this._isIndexBuilt = true;

      return this.getIndexAttributes(attributes);
    }
  };

  private getModelAttributes = (attributes: string[]) => {
    const pulledAttributes = {};

    for (const key of attributes) {
      pulledAttributes[key] = this.getSingleAttribute(key);
    }

    if (attributes.length > 1) {
      return pulledAttributes;
    }

    return pulledAttributes[attributes[0]];
  };

  private getSingleAttribute = (attribute: string): AttributeValue => {
    // Returned saved attribute value if it exists
    if (!this._model) {
      return undefined;
    }

    // Return saved value if available
    if (attribute in this._model) {
      return this._model[attribute];
    }

    const data = this._data as AttributeModel;

    if (attribute === 'id') {
      // ID is the only attribute that resides top level in the data and must be
      // pulled specifically
      const value = data.id;
      this._model[attribute] = value;

      return value;
    } else if (attribute in (data.attributes || {})) {
      // All other attributes reside in the attributes object of the data
      const value = data.attributes[attribute];
      this._model[attribute] = value;

      return value;
    } else if (data.relationships && attribute in data.relationships) {
      // Handling for data pulls of relationships, which require a search through
      // the includes of the returned data
      const relationship = data.relationships[attribute];

      // Handling for one-to-many relationships
      if (Array.isArray(relationship.data)) {
        const builtData = relationship.data.map((relation: IdModel) => {
          const included = this.findRelationship(relation.id, relation.type);

          if (included === undefined) {
            return {
              id: relation.id,
            };
          }

          return new Modeler(this.createModelData(included), {
            includes: this._includes,
            generations: this.decrementGeneration(),
          }).build();
        });

        this._model[attribute] = builtData;

        return builtData;
      } else {
        // Handling for one-to-one relationships
        if (relationship.data === null) {
          this._model[attribute] = null;
          return null;
        }

        const included = this.findRelationship(
          relationship.data.id,
          relationship.data.type,
        );

        if (included === undefined) {
          const emptyBuildData = {
            id: relationship.data.id,
          };

          this._model[attribute] = emptyBuildData;

          return emptyBuildData;
        }

        const builtData = new Modeler(this.createModelData(included), {
          includes: this._includes,
          generations: this.decrementGeneration(),
        }).build();

        this._model[attribute] = builtData;

        return builtData;
      }
    }

    return undefined;
  };
}

export default Modeler;
