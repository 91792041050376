import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import { setCurrentBrand } from '@/actions/actions/brand';
import { getApplicationData } from '@/actions/reducers/application';
import { setCurrentUser } from '@/actions/reducers/users';
import { flashSuccess } from '@/actions/sagas/messageSaga';
import LoadingScreen from '@/components/activity/LoadingScreen';
import CallControls from '@/components/caller/CallControls';
import CallManager from '@/components/caller/CallManager';
import ErrorBoundary from '@/components/errors/ErrorBoundary';
import Footer from '@/components/footer/Footer';
import Header from '@/components/header/Header';
import FillHeight from '@/components/layout/FillHeight';
import Router from '@/contexts/app/Router';
import { selectisFetchingInitialData } from '@/selectors/application';
import {
  getCurrentUser,
  selectCurrentUserRole,
  selectIsCurrentUserAnyCareManager,
} from '@/selectors/users';
import { Nullable } from '@/types/generic';
import { User } from '@/types/user';
import SubscriptionHandler from './SubscriptionHandler';
import TimeoutWarning from './TimeoutWarning';

interface Props {
  brand?: string;
  flash?: string;
  user: Nullable<User>;
}

const Home = (props: Props) => {
  const { brand, flash, user } = props;

  const dispatch = useDispatch();

  const history = useHistory();

  const currentUser = useSelector(getCurrentUser);
  const isLoadingData = useSelector(selectisFetchingInitialData);
  const userRole = useSelector(selectCurrentUserRole);
  const isCurrentUserCareManager = useSelector(
    selectIsCurrentUserAnyCareManager,
  );

  if (user && !currentUser) {
    dispatch(setCurrentUser(user));
  }

  useEffect(() => {
    dispatch(setCurrentBrand(brand));

    if (flash) {
      dispatch(flashSuccess(flash));
    }

    history.listen(() => {
      const scroller = document.getElementById('scroller');

      if (scroller) {
        scroller.scrollIntoView();
      }
    });
  }, []);

  useEffect(() => {
    // We need to ensure that the user type is set before calling getApplicationData.
    // Most fetches will be skipped if the user is not a care manager.
    if (userRole) {
      dispatch(getApplicationData());
    }
  }, [userRole]);

  if (isLoadingData) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Header />
      <FillHeight>
        <ErrorBoundary>
          <ToastContainer transition={Slide} />
          {isCurrentUserCareManager && <CallManager />}
          {isCurrentUserCareManager && <CallControls />}
          <Router />
        </ErrorBoundary>
      </FillHeight>
      <Footer />
      <TimeoutWarning />
      {/* TODO: Re-enable sockets */}
      {/* <SubscriptionHandler /> */}
    </>
  );
};

export default Home;
