import React, { ReactNode } from 'react';
import Label from '../form/Label';

interface Props {
  children?: ReactNode;
  isHidden?: boolean;
  label?: string;
  value?: any;
}

const ModalContent = (props: Props) => {
  const { children, isHidden, label, value } = props;

  if (isHidden) {
    return null;
  }

  return (
    <div>
      {label && <Label>{label}</Label>}
      <div className="modal__content">{value}</div>

      {children && <div className="modal__children">{children}</div>}
    </div>
  );
};

export default ModalContent;
