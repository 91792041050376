import classNames from 'classnames';
import React from 'react';
import Flatpickr from 'react-flatpickr';

import ErrorMessage from '@/components/form/ErrorMessage';
import Label from '@/components/form/Label';
import { SideEffect } from '@/types/generic';
import { formatTime } from '@/utils/dateUtils';
import Icon from '../icon/Icon';

interface Props {
  isDisabled?: boolean;
  label?: string;
  onChange: SideEffect;
  placeholder?: string;
  validationMessage?: string;
  value: string;
}

const Timepicker = (props: Props) => {
  const {
    isDisabled,
    label,
    onChange,
    placeholder,
    validationMessage,
    value,
  } = props;

  const handleChangeDate = (date: object[]) => {
    if (onChange && typeof onChange === 'function') {
      onChange(formatTime(date[0]));
    }
  };

  const isInvalid = !!validationMessage;

  const options = {
    dateFormat: 'h:iK',
    enableTime: true,
    noCalendar: true,
  };

  return (
    <div className="form-input-group">
      <Label isInvalid={isInvalid}>{label}</Label>
      <div
        className={classNames('form-select', { 'has-selected-date': !!value })}>
        <Flatpickr
          data-enable-time
          disabled={isDisabled}
          onChange={handleChangeDate}
          options={options}
          value={value}
          placeholder={placeholder}
        />
        <div className="form-select__icon">
          <Icon icon="caretDown" />
        </div>
      </div>
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </div>
  );
};

export default Timepicker;
