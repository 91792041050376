import { createSelector } from 'reselect';

import { AppState } from '@/types/app';
import { VideoCallsState } from '@/types/v2/video_calls';

export const selectVideoCallsState = (state: AppState): VideoCallsState =>
  state.videoCalls;

export const selectIsFetchingToken = createSelector(
  selectVideoCallsState,
  state => state.isFetchingToken,
);

export const selectVideoCallsToken = createSelector(
  selectVideoCallsState,
  state => state.token,
);

export const selectVideoCallsAppointment = createSelector(
  selectVideoCallsState,
  state => state.selectedAppointment,
);

export const selectVideoCallStatus = createSelector(
  selectVideoCallsState,
  state => state.callStatus,
);

export const selectIsVideoCallActive = createSelector(
  selectVideoCallStatus,
  status => status === 'active',
);
