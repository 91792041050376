import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';

import ErrorMessage from '@/components/form/ErrorMessage';

import { Patient } from '@/types/patient';
import { Response } from '@/types/response';
import usePostRequest from '@/utils/api/usePostRequest';
import { parseErrorData } from '@/utils/apiUtils';

import { validate } from '@/utils/form';
import { buildPath, Routes } from '@/utils/routeUtils';

import { SideEffect } from '@/types/generic';
  
const FORM_STATE = {
  
  care_manager_id: '',

  default_specialist_id:'',  


  language: '',

  status: '',
 
};

interface Props {

  patients_id: string[];
  onCloseModal?: SideEffect;
  patients_name: string[];
  forms:string;
  care_manager_name:string;
  specialist_name:string;
  practice_id:string[];

  }
  

const PatientEditConfirmModal = (props: Props) => {
  const dispatch = useDispatch();
  const { patients_id } = props;
  const {patients_name}=props;
  const {care_manager_name}=props;
  const {specialist_name}=props;
 const  {forms}=props
  const history = useHistory();
  const formData= JSON.parse(forms)

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const[PatientIdArray,setPatientIdArray]=useState(patients_id);
  const [tags, setTags] = useState(patients_name)

  const backPath = buildPath(Routes.patientEditAll);

 


  const handleFailure = (response: any) => {
    const errorMessage = parseErrorData(response);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const handleSuccess = (response: Response<Patient>) => {
   
    setTags([]);
    setPatientIdArray([]);
    dispatch(flashSuccess('Patient updated'));
    //setForm(FORM_STATE);

    props.onCloseModal();
    
    history.push(backPath);
    
  };


  const formatBody = () => {
    const {
      
      ...formFields
    } = form;
    return {
      
        ...formFields,
        patients_id,
       
       
    
     
    };
  };
  


  const [submitForm, isSubmitting] = usePostRequest({
    body: {'patients_id':PatientIdArray,
    'care_manager_id':formData.care_manager_id,
    'default_specialist_id':formData.default_specialist_id,
    'language':formData.language,
    'status':formData.status,
      'practice_array':props.practice_id},
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: 'api/v1/patients/bulk_edit',
  });



  const handleClickCancel = () => {
    props.onCloseModal();

    history.push(backPath);
  };

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }


    submitForm();
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };


  return (
    <div className="patient-new__wrapper">
 

      <Constraint size="large">
        <Container>
          <div className="patient-new__header">
            <h1>
             Patients names
            </h1>

            
          </div>
        </Container>
        <Container>
        <div className="box is-scrollable">
    
        <div className="tags-input-container">
            { tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                    <span className="text">{tag}</span>
                </div>
            )) }
        </div>
         
            </div>
            </Container>
    
            <Container>
          <div className="patient-new__header">
            <h1>
             Fields to be edited
            </h1>    </div>
            </Container>
            <Container>
         <div className="box">
          <div className="columns">
            { (care_manager_name!=undefined)?
            <div className="column is-half">
              Care Manger : {care_manager_name}
            </div> : null}
            { specialist_name!=undefined?
            <div className="column is-half">
              Specialist : {specialist_name}
            </div>: null}
          </div>
          <div className="columns">
    
            { formData.status!=''?
            <div className="column is-half">
              Status : {formData.status}
            </div>: null}
            {formData.language!=''?
            <div className="column is-half">
              Language : {formData.language}
            </div>:null}</div></div>
            
          
        </Container>


        <ErrorMessage isRightSide message={error} />

        <div className="form__actions">
          <div className="form__actions-left" />
          <div className="form__actions-right PatientEditModal">
            <Button color="white" onClick={handleClickCancel}>
              Cancel
            </Button>

            <Button
              color="secondary"
              isSubmitting={isSubmitting}
              onClick={handleClickSubmit}>
              Update
            </Button>
          </div>
        </div>
      </Constraint>
    </div>
  );
};

export default PatientEditConfirmModal;