import React from 'react';

import StaticPage from '@/components/page/StaticPage';

const AccessRestrictedPage = () => {
  return (
    <StaticPage
      content="You don’t have access to view this page. Please contact your administrator if you believe this is an error."
      title="Access Restricted"
    />
  );
};

export default AccessRestrictedPage;
