import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Api from '@/api/Api';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import { UUID } from '@/types/generic';
import { Patient } from '@/types/patient';
import { TableConfig } from '@/types/table';
import { beginCall, endCall } from '@/actions/sagas/callManagerSaga';
import {
  setCallModalVisibility,
  setCallService,
  setDialerModalVisibility,
  setSelectedCallPatient,
  setSelectedPhoneNumber,
} from '@/actions/reducers/call_manager';
import Table from '@/components/table/Table';
import PhoneNumberModal from '@/components/caller/PhoneNumberModal';
import DialerModal from '@/components/caller/DialerModal';
import {
  selectIsCallModalVisible,
  selectIsDialerModalVisible,
} from '@/selectors/call_manager';

const PatientRanks = (): JSX.Element => {
  const dispatch = useDispatch();

  const loader = useLoadingState('appointment');
  const notifier = useNotifier();

  const isCallModalVisible = useSelector(selectIsCallModalVisible);
  const isDialerModalVisible = useSelector(selectIsDialerModalVisible);

  const [patient, setPatient] = useState<Patient>(null);

  const getPatient = async (patientId): Promise<void> => {
    loader.startLoading('patient');

    try {
      const url = `api/v1/patients/${patientId}`;
      const response = await Api.utility.get(url);
      const patient = response.data.data;
      dispatch(setSelectedCallPatient(patient))
      setPatient(patient);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('patient');
  };

  const handleOpenCallModal = async entry => {
    getPatient(entry.attributes.patient_id);
    dispatch(setCallModalVisibility(true));
    dispatch(setSelectedPhoneNumber(entry.attributes.phone_number));
  };

  const handleChangePhoneNumber = (phoneNumber: string) => {
    dispatch(setSelectedPhoneNumber(phoneNumber));
  };

  const handleChangeService = (service: UUID) => {
    dispatch(setCallService(service));
  };

  const handleDialNumber = () => {
    dialNumber();
  };

  const handleCloseCallModal = () => {
    dispatch(setCallModalVisibility(false));
  };

  const handleEndCall = () => {
    dispatch(endCall());
  };

  const dialNumber = () => {
    dispatch(setSelectedPhoneNumber(''));
    dispatch(setCallModalVisibility(false));
    dispatch(setDialerModalVisibility(true));
  };

  const handleCallPatient = () => {
    if (patient.attributes.phone_number === 'dial') {
      dialNumber();
    } else {
      dispatch(setDialerModalVisibility(false));
      dispatch(setSelectedCallPatient(patient));
      dispatch(beginCall());
    }
  };

  const SEARCH_PATH = 'api/v2/patient_ranks';

  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {},
    initialSort: 'rank:asc',
    columns: [
      {
        header: 'First Name',
        isSortable: false,
        isVisibleMobile: true,
        attribute: 'first_name',
      },
      {
        header: 'Last Name',
        attribute: 'last_name'
      },
      {
        header: 'Rank',
        attribute: 'rank',
      },
      {},
    ].filter(n => n),
    rows: {
      customText: 'Call patient',
      customAction: entry => handleOpenCallModal(entry),
    },
  };

  return (
    <>
      <h1>Patient ranks</h1>
      <Table config={tableConfig} />
      {patient && (
        <PhoneNumberModal
          isVisible={isCallModalVisible}
          onChangePhoneNumber={handleChangePhoneNumber}
          onChangeService={handleChangeService}
          onClickCall={handleCallPatient}
          onClickDial={handleDialNumber}
          onCloseModal={handleCloseCallModal}
          selectedPhoneNumber={patient.attributes.phone_number}
          passedPatient={patient}
        />
      )}
      {patient && (
        <DialerModal
          isVisible={isDialerModalVisible}
          onDialPatient={handleCallPatient}
        />
      )}
    </>
  );
};

export default PatientRanks;
