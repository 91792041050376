import React from 'react';

const Dot = () => {
  return (
    <div className="table__dot-container">
      <span className="table__dot" />
    </div>
  );
};

export default Dot;
