import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import ModalContent from '@/components/modal/ModalContent';
import { Attributable } from '@/types/generic';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { formatShortDate } from '@/utils/dateUtils';
import { buildPath } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { formatSecondsToHours } from '@/utils/timeUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  actionHistoryId: string;
  patient: Attributable;
}

const ActionHistoryModal = (props: Props) => {
  const { actionHistoryId, patient } = props;

  const dispatch = useDispatch();

  const [actionLog, setActionLog] = useState(null);

  const [getActionLog, isActionLogLoading] = useGetRequest({
    dispatch,
    model: 'action_logs',
    url: buildPath(
      'api/v1/action_logs/:id',
      { id: actionHistoryId },
      { include: 'patient,user' },
    ),
    onSuccess: (response: any) => {
      setActionLog(parseResponseData(response));
    },
  });

  useEffect(() => {
    if (actionHistoryId) {
      getActionLog();
    }
  }, [actionHistoryId]);

  const renderContent = () => {
    if (!actionLog) {
      return null;
    }

    const logType = actionLog.attributes.action_type;

    switch (logType) {
      case 'admin_entry_added':
        return (
          <ModalContent
            label="Duration"
            value={formatSecondsToHours(
              actionLog.attributes.recorded_changes.duration,
            )}
          />
        );

      case 'call_executed':
        return (
          <>
            <ModalContent
              label="Duration"
              value={formatSecondsToHours(
                actionLog.attributes.recorded_changes.duration,
              )}
            />

            <ModalContent
              label="Status"
              value={titleizeSnakeCase(
                actionLog.attributes.recorded_changes.status,
              )}
            />
          </>
        );

      case 'note_added':
        return (
          <ModalContent
            label="Note"
            value={actionLog.attributes.recorded_changes.content}
          />
        );

      default:
        return null;
    }
  };

  if (!actionLog || isActionLogLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className="patient-action-logs__modal-wrapper">
      <h3 className="patient-action-logs__modal-header">Action History</h3>

      <ModalContent
        label="Action Type"
        value={titleizeSnakeCase(actionLog.attributes.action_type)}
      />

      {renderContent()}

      <ModalContent
        label="Action By"
        value={fullName(actionLog.attributes.user)}
      />

      <ModalContent
        label="Patient By"
        value={fullName(patient.attributes.care_manager)}
      />

      <ModalContent
        label="Action Date"
        value={formatShortDate(actionLog.attributes.date)}
      />
    </div>
  );
};

export default ActionHistoryModal;
