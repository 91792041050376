import React from 'react';
import { useResponsive } from 'react-hooks-responsive';

import { getPatientEnrollmentSteps } from '@/contexts/enrollment/helpers/steps';
import { isEnrolled } from '@/contexts/patients/utils/patientUtils';
import {
  getHasPatientConsented,
  getIsConditionSelected,
  getSelectedPatient,
  getSelectedPatientEnrollment,
  getSelectedPatientIncluded,
} from '@/selectors/api';
import { selectCurrentCarePlan } from '@/selectors/bhi';
import { breakpoints } from '@/utils/screenUtils';
import { useSelector } from 'react-redux';
import EnrollmentSidebarLink from './EnrollmentSidebarLink';

const getCount = (data: any): number => {
  if (data?.data) {
    return data.data.length;
  }

  return 0;
};

const EnrollmentSidebar = () => {
  const carePlan = useSelector(selectCurrentCarePlan);
  const enrollment = useSelector(getSelectedPatientEnrollment);
  const hasPatientConsented: boolean = useSelector(getHasPatientConsented);
  const hasSelectedConditions = useSelector(getIsConditionSelected);
  const includes = useSelector(getSelectedPatientIncluded);
  const patient = useSelector(getSelectedPatient);

  const isComplete = enrollment.attributes?.status === 'complete';

  const getHistoryCount = (type: string): number => {
    return includes.filter(include => include.attributes.history_type === type)
      .length;
  };

  const { screenIsAtMost } = useResponsive(breakpoints);

  const isMobile = screenIsAtMost('mobile');

  const getLinkText = (stepName: string) => {
    switch (stepName) {
      case 'bhi_support':
        const total =
          getCount(patient.relationships.specialists) +
          getCount(patient.relationships.contacts);
        return `${total} added`;

      case 'support':
        return `${getCount(patient.relationships.specialists)} added`;

      case 'contacts':
        return `${getCount(patient.relationships.contacts)} added`;

      case 'allergies':
        return `${getCount(patient.relationships.allergies)} added`;

      case 'medications':
        return `${getCount(patient.relationships.medications)} added`;

      case 'medical_history':
        return `${getCount(patient.relationships.medical_histories)} added`;

      case 'surgical_history':
        return `${getHistoryCount('surgical')} added`;

      case 'family_history':
        return `${getHistoryCount('family')} added`;

      case 'social_history':
        return `${getHistoryCount('social')} added`;

      case 'consent':
        const steps = [
          'patient_agreed_at',
          'patient_enrollment_date',
          'patient_verified_understanding_at',
        ];

        const stepsCompleted = !steps.find(
          (step: string) => !enrollment.attributes[step],
        );

        return `${+stepsCompleted} of 1 part completed`;

      default:
        if (stepName.includes('bhi_')) {
          if (!carePlan) {
            return '0 of 1 part completed';
          }

          const cleanStepName = stepName.replace('bhi_', '');

          const completed = !!carePlan.data.attributes[
            `${cleanStepName}_completed_at`
          ];

          return `${+completed} of 1 part completed`;
        } else {
          const completed = !!enrollment.attributes[`${stepName}_completed_at`];
          return `${+completed} of 1 part completed`;
        }
    }
  };

  const isDisabled = (stepName: string): boolean => {
    if (stepName === 'consent') {
      return false;
    }

    if (stepName.includes('bhi_') && hasPatientConsented) {
      return false;
    }

    if (stepName === 'conditions' && !hasPatientConsented) {
      return false;
    }

    if (stepName !== 'conditions' && !hasSelectedConditions) {
      return true;
    }
  };

  return (
    <>
      {isMobile && <div id="scroller" />}
      <div className="enrollment-sidebar">
        {getPatientEnrollmentSteps(patient, isComplete).map(
          (stepName, index) => {
            const stepText = (() => {
              if (isEnrolled) {
                return `Step ${index + 1}`;
              } else {
                return index === 0 ? 'Pre-Enrollment' : `Step ${index}`;
              }
            })();

            return (
              <EnrollmentSidebarLink
                highlightText={getLinkText(stepName)}
                isDisabled={isDisabled(stepName)}
                key={stepName}
                stepName={stepName}
                stepNumber={stepText}
              />
            );
          },
        )}
      </div>
    </>
  );
};

export default EnrollmentSidebar;
