import { Option } from '@/types/form';

export const typeOptions: Option[] = [
  { label: 'Glucometer', value: '1' },
  { label: 'Blood Pressure', value: '2' },
  { label: 'Oximiter', value: '7' },
  { label: 'Scale', value: '8' },
];

export const timePeriodOptions: Option[] = [
  { label: 'Any', value: '' },
  { label: 'All day', value: 'all_day' },
  { label: 'Wake up', value: 'wake_up' },
  { label: 'Morning', value: 'morning' },
  { label: 'Before breakfast', value: 'before_breakfast' },
  { label: 'After breakfast', value: 'after_breakfast' },
  { label: 'Before lunch', value: 'before_lunch' },
  { label: 'After lunch', value: 'after_lunch' },
  { label: 'Noon', value: 'noon' },
  { label: 'Afternoon', value: 'afternoon' },
  { label: 'Before dinner', value: 'before_dinner' },
  { label: 'After dinner', value: 'after_dinner' },
  { label: 'Evening', value: 'evening' },
  { label: 'Bedtime', value: 'bedtime' },
  { label: 'Midnight', value: 'midnight' },
];
