import React from 'react';

import Button from '@/components/button/Button';
import { fileToBase64 } from '@/utils/fileUtils';
import { randomString } from '@/utils/stringUtils';
import TextArea from '../form/TextArea';

export interface File {
  data: string;
  id: string;
  name: string;
}

interface Props {
  onChangeText: (text: string) => void;
  onClickSend: () => void;
  onPressEnter: () => void;
  onSelectFile: (file: File) => void;
  inputValue: string;
  isDisabled: boolean;
}

const Controls = ({
  isDisabled,
  onChangeText,
  onClickSend,
  onSelectFile,
  onPressEnter,
  inputValue,
}: Props): JSX.Element => {
  const handleSelectFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files[0];
    const data = (await fileToBase64(file)) as string;

    const newFile: File = {
      data,
      id: randomString(),
      name: file.name,
    };

    onSelectFile(newFile);
  };

  return (
    <div className="chat-controls">
      <div className="chat-controls__input">
        <TextArea
          onChange={value => onChangeText(value)}
          onPressEnter={onPressEnter}
          value={inputValue}
          placeholder="Type to chat"
        />
      </div>

      <div className="chat-controls__actions">
        <div className="chat-controls__button">
          <div className="chat-controls__upload">
            <label className="button is-white" htmlFor="chat-upload">
              Upload
            </label>
            <input
              id="chat-upload"
              disabled={isDisabled}
              onChange={handleSelectFile}
              type="file"
            />
          </div>
        </div>

        <div className="chat-controls__button">
          <Button
            color="secondary"
            isDisabled={isDisabled}
            onClick={onClickSend}>
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Controls;
