import React from 'react';

import Button from '@/components/button/Button';
import SearchGroup from '@/components/form/SearchGroup';
import SelectGroup from '@/components/form/SelectGroup';
import { SideEffect } from '@/types/generic';
import { userStatuses } from '@/utils/dropdownUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  mobileQueryOptions: { [key: string]: any };
  onChange: SideEffect;
  onClick: SideEffect;
}

const AdminDashboardFilterModal = (props: Props) => {
  const { mobileQueryOptions, onChange, onClick } = props;

  const formatCareManagerResult = (result: any) => {
    return {
      label: fullName(result),
      value: result.id,
    };
  };

  return (
    <div className="admin-dashboard-activity__modal">
      <h3 className="admin-dashboard-activity__modal-header">Filter</h3>

      <div className="columns is-mobile">
        <div className="column is-6">
          <SearchGroup
            clearFalseResultOnBlur
            formatResult={formatCareManagerResult}
            guideValue={mobileQueryOptions.care_manager_id}
            label="Care Manager"
            minimumInputLength={1}
            onChange={value => onChange('care_manager_id', value)}
            placeholder="Search Care Managers"
            searchPath="api/v1/care_managers"
          />
        </div>

        <div className="column is-6">
          <SelectGroup
            label="Care Manager Status"
            options={userStatuses}
            value={mobileQueryOptions.status}
            onChange={changedValue => onChange('status', changedValue)}
            placeholder="Select Status"
          />
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column">
          <div className="admin-dashboard-activity__modal-button">
            <Button color="secondary" onClick={onClick}>
              {'Apply'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardFilterModal;
