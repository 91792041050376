import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import ModalContent from '@/components/modal/ModalContent';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { buildPath } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';

interface Props {
  alertTriggerId: string;
}

const AlertTriggersModal = (props: Props) => {
  const { alertTriggerId } = props;

  const dispatch = useDispatch();

  const [alertTrigger, setAlertTrigger] = useState(null);

  const includes = [
    'alert_recipient',
    'alert_type',
    'device',
    'creator',
    'device_model',
    'device_name',
    'device_make',
    'device_type',
    'device_category',
  ];

  const [getAlertTrigger, isAlertTriggerLoading] = useGetRequest({
    dispatch,
    model: 'alert_triggers',
    url: buildPath(
      'api/v1/alert_triggers/:id',
      { id: alertTriggerId },
      { include: includes.join(',') },
    ),
    onSuccess: (response: any) => {
      setAlertTrigger(parseResponseData(response));
    },
  });

  useEffect(() => {
    if (alertTriggerId) {
      getAlertTrigger();
    }
  }, [alertTriggerId]);

  if (!alertTrigger || isAlertTriggerLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className="patient-remote-care__modal-wrapper">
      <h3 className="patient-remote-care__modal-header">Alert</h3>
      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Device Category"
            value={alertTrigger.attributes.device_category?.name}
          />
        </div>
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Device Type"
            value={alertTrigger.attributes.device_type?.name}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Device Make"
            value={alertTrigger.attributes.device_make?.name}
          />
        </div>
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Device Name"
            value={alertTrigger.attributes.device_name?.name}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Device Model"
            value={alertTrigger.attributes.device_model?.name}
          />
        </div>
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Alert Type"
            value={alertTrigger.attributes.alert_type?.name}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Serial Number"
            value={alertTrigger.attributes.device?.serial_number}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Math Option"
            value={titleizeSnakeCase(alertTrigger.attributes.math_option)}
          />
        </div>

        <div className="column is-6">
          <div className="columns">
            <div className="column is-4 patient-remote-care__modal-column">
              <ModalContent
                label="Min Value"
                value={alertTrigger.attributes.range_low}
              />
            </div>

            <div className="column is-4 patient-remote-care__modal-column">
              <ModalContent
                label="Max Value"
                value={alertTrigger.attributes.range_high}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Notify Alert"
            value={titleizeSnakeCase(alertTrigger.attributes.notify_alert)}
          />
        </div>
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Recipient"
            value={alertTrigger.attributes.alert_recipient?.name}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Email"
            value={alertTrigger.attributes.alert_recipient?.email}
          />
        </div>
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="SMS Phone"
            value={alertTrigger.attributes.alert_recipient?.sms_phone_number}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Voice Phone"
            value={alertTrigger.attributes.alert_recipient?.voice_phone_number}
          />
        </div>
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Alert Set By"
            value={`${alertTrigger.attributes.creator?.first_name} ${alertTrigger.attributes.creator?.last_name}`}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 patient-remote-care__modal-column">
          <ModalContent
            label="Visible to Patient"
            value={titleizeSnakeCase(alertTrigger.attributes.visibility)}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertTriggersModal;
