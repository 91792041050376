import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import SelectGroup from '@/components/form/SelectGroup';
import Panel from '@/components/panel/Panel';
import { PageHeader, Table } from '@/components/table';
import { CareManagerResponse } from '@/state/rpm';
import { TableConfig } from '@/types/table';
import { formatIsoDateTime } from '@/utils/dateUtils';
import { createWeeklyDateOptions } from '@/utils/dropdownUtils';
import useSimpleForm from '@/utils/form/useSimpleForm';
import { buildPath, Routes } from '@/utils/routeUtils';
import { perPageOptions } from '@/utils/tableUtils';
import { fullName } from '@/utils/userUtils';

const [dateOptions, startDate] = createWeeklyDateOptions(moment());

const state = {
  start_at: startDate,
  per: '24',
};

type FormState = typeof state;

const RpmHighAlertCareManagers = (): JSX.Element => {
  const form = useSimpleForm<FormState>(state);

  const config: TableConfig = {
    initialSort: 'patients:desc',
    searchPath: Routes.api2.deviceReadings,
    searchQuery: {
      ...form.data,
      end_at: formatIsoDateTime(moment(form.data.start_at).add(1, 'week')),
      report: 'high_alert_care_managers',
    },
    emptyState: 'No readings',
    rows: {
      viewId: 'care_manager_id',
      viewPath: buildPath(Routes.dashboardCareManager, null, {
        return: window.location.pathname,
      }),
    },
    columns: [
      {
        header: 'Care Manager',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'first_name',
        formatEntry: (entry: CareManagerResponse) => {
          const name = fullName(entry);
          const url = buildPath(
            Routes.dashboardCareManager,
            { care_manager_id: entry.id },
            { return: window.location.pathname },
          );

          return <Link to={url}>{name}</Link>;
        },
      },
      {
        header: 'Patients',
        isSortable: true,
        attribute: 'patients',
      },
    ],
  };

  return (
    <>
      <Constraint size="large">
        <div className="columns">
          <div className="column">
            <BackLink to={Routes.patients}>Back to List</BackLink>
          </div>
        </div>
      </Constraint>

      <Panel>
        <Constraint size="large">
          <div className="columns">
            <div className="column is-8">
              <PageHeader>Care Managers: High Alert Patients</PageHeader>
            </div>

            <div className="column is-2">
              <SelectGroup
                label="Week Start"
                options={dateOptions}
                onChange={value => form.update('start_at', value)}
                value={form.data.start_at}
              />
            </div>

            <div className="column is-2">
              <SelectGroup
                label="Show"
                options={perPageOptions}
                onChange={value => form.update('per', value)}
                value={form.data.per}
              />
            </div>
          </div>

          <Table config={config} />
        </Constraint>
      </Panel>
    </>
  );
};

export default RpmHighAlertCareManagers;
