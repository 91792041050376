import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import api from '@/api/Api';
import Saga from '@/types/saga';
import { parseErrorData } from '@/utils/apiUtils';

interface RequestAction extends Saga {
  payload: {
    url: string;
    dispatchToStore: boolean;
    key?: string;
    params: object;
  };
}

interface PostRequestAction extends Saga {
  payload: {
    url: string;
    dispatchToStore: boolean;
    key?: string;
    body: object;
    storeData?: object;
    actionType?: string;
    config?: object;
  };
}

interface DeleteRequestAction extends Saga {
  payload: {
    url: string;
  };
}

export function* getData(action: RequestAction) {
  try {
    const { url, key, params } = action.payload;

    const response = yield call(api.utility.get, url, { params });

    const parsedData = response.data;

    if (action.payload.dispatchToStore) {
      yield put({
        type: 'API_FETCH_SUCCESS',
        payload: {
          options: {
            key,
          },
          data: parsedData,
        },
      });
    }

    if (action.onSuccess) {
      action.onSuccess(response.data);
    }
  } catch (err) {
    if (action.onFailure) {
      action.onFailure(err);
    }
  } finally {
    if (action.onComplete) {
      action.onComplete();
    }
  }
}

export function* postData(action: PostRequestAction) {
  try {
    const { url, key, body } = action.payload;

    const response = yield call(api.utility.post, url, body);

    const dataForStore = action.payload.storeData
      ? action.payload.storeData
      : response.data;

    if (action.payload.dispatchToStore) {
      if (action.payload.actionType === 'add') {
        yield put({
          type: 'API_POST_SUCCESS_ADD',
          payload: {
            options: {
              key,
            },
            data: dataForStore,
          },
        });
      } else {
        yield put({
          type: 'API_POST_SUCCESS',
          payload: {
            options: {
              key,
            },
            data: dataForStore,
          },
        });
      }
    }

    if (action.onSuccess) {
      action.onSuccess(response.data);
    }
  } catch (err) {
    if (action.onFailure) {
      action.onFailure(err);
    }
  } finally {
    if (action.onComplete) {
      action.onComplete();
    }
  }
}

export function* putData(action: PostRequestAction) {
  try {
    const { url, key, body, config } = action.payload;

    const response = yield call(api.utility.patch, url, body, config);

    const dataForStore = action.payload.storeData
      ? action.payload.storeData
      : response.data;

    if (action.payload.dispatchToStore) {
      yield put({
        type: 'API_FETCH_SUCCESS',
        payload: {
          options: {
            key,
          },
          data: dataForStore,
        },
      });
    }

    if (action.onSuccess) {
      action.onSuccess(response.data);
    }
  } catch (err) {
    if (action.onFailure) {
      action.onFailure(err);
    }
  } finally {
    if (action.onComplete) {
      action.onComplete();
    }
  }
}

export function* deleteData(action: DeleteRequestAction) {
  try {
    const { url } = action.payload;

    yield call(api.utility.delete, url);

    if (action.onSuccess) {
      action.onSuccess();
    }
  } catch (err) {
    if (action.onFailure) {
      action.onFailure(err);
    }
  } finally {
    if (action.onComplete) {
      action.onComplete();
    }
  }
}

export default function* requestSaga() {
  yield takeEvery('GET_DATA_SAGA', getData);
  yield takeLatest('POST_DATA_SAGA', postData);
  yield takeLatest('PUT_DATA_SAGA', putData);
  yield takeLatest('DELETE_DATA_SAGA', deleteData);
}
