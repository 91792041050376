import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type ButtonColor = 'white' | 'secondary';

interface Props {
  color?: ButtonColor;
  children: ReactNode;
  to: string;
}

const ButtonLink = (props: Props) => {
  const { children, color = 'secondary', to } = props;

  return (
    <div className="back-link">
      <Link className={`button is-${color}`} to={to}>
        {children}
      </Link>
    </div>
  );
};

export default ButtonLink;
