import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { selectUnreadAlertCount } from '@/selectors/users';
import { Routes } from '@/utils/routeUtils';
import NavLinkAccent from './NavLinkAccent';

export const PatientLinksLeft = () => {
  return (
    <>
      <NavLink
        className="header__nav-item"
        activeClassName="header__nav-item is-active"
        exact
        to={Routes.chart.appointments.root}>
        Appointments
      </NavLink>

      <NavLink
        className="header__nav-item"
        activeClassName="header__nav-item is-active"
        exact
        to={Routes.chart.providers.root}>
        Providers
      </NavLink>
    </>
  );
};

export const PatientLinksRight = () => {
  const alertCount = useSelector(selectUnreadAlertCount);

  return (
    <NavLink
      className="header__nav-item"
      activeClassName="header__nav-item is-active"
      exact
      to={Routes.chart.alerts.root}>
      Alerts
      {alertCount > 0 && <NavLinkAccent>{alertCount}</NavLinkAccent>}
    </NavLink>
  );
};
