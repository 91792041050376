import moment from 'moment';

import { Validations } from '@/types/form';

export const validateBeforeSubmit = (formState: any): Validations => {
  const { next_date, last_date } = formState;

  if (!next_date) {
    return null;
  }

  const endDate = moment(next_date);
  const startDate = moment(last_date);

  const differenceInDays = endDate.diff(startDate, 'd');

  if (differenceInDays <= 0) {
    return {
      next_date: 'Next date must occur after previous date',
    };
  }
};
