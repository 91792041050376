import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import AccessRestrictedPage from '@/components/page/AccessRestrictedPage';
import {
  getCurrentUser,
  selectCurrentUserRole,
  selectUserCapabilities,
} from '@/selectors/users';
import { Capability, UserRole } from '@/types/user';
import { Routes } from '@/utils/routeUtils';

interface Props {
  allowedRoles?: (Capability | UserRole)[];
  children: ReactNode;
}

export const Protected = (props: Props) => {
  const { allowedRoles, children } = props;

  const currentUser = useSelector(getCurrentUser);
  const currentUserRole = useSelector(selectCurrentUserRole);
  const userCapabilities = useSelector(selectUserCapabilities);

  if (!currentUser) {
    return <Redirect to={Routes.root} />;
  }

  const isAllowed = (() => {
    const allRoles = [currentUserRole, ...userCapabilities];
    return allowedRoles.some(role => allRoles.includes(role));
  })();

  if (allowedRoles && !isAllowed) {
    return <AccessRestrictedPage />;
  }

  return <>{children}</>;
};

export default Protected;
