import { Snapshot } from '@/types/generic';

export interface SetSnapshot<T> {
  type: T;
  payload: {
    snapshot: Snapshot;
  };
}

export const setSnapshot = (snapshot: Snapshot, type: any) => {
  return {
    type,
    payload: {
      snapshot,
    },
  };
};
