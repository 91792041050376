import { Attributable } from '@/types/generic';
import { pullAt } from 'lodash';

export const areObjectsEqual = (ob1: object, ob2: object) => {
  if (!ob1 || !ob2) {
    return false;
  }

  if (Object.keys(ob1).length !== Object.keys(ob2).length) {
    return false;
  }

  const keysToCheck = Object.keys(ob1);

  for (const key of keysToCheck) {
    if (ob1[key] !== ob2[key]) {
      return false;
    }
  }

  return true;
};

export const fillUndefinedKeys = (
  data: Attributable,
  newValue: any = '',
): Attributable => {
  const newData = { ...data };

  for (const key of Object.keys(data)) {
    if (data[key] === undefined) {
      newData[key] = newValue;
    }
  }

  return newData;
};

export const mergeObjects = (...objects: object[]): object => {
  const filteredObjects = objects.filter(n => n);

  const combinedObject = {};

  for (const obj of filteredObjects) {
    Object.assign(combinedObject, obj);
  }

  return combinedObject;
};

export const pullAttributes = (ob: object, ...attributes: string[]): object => {
  const newOb = {};

  for (const attr of attributes) {
    if (attr.includes('.')) {
      const association = attr.substr(0, attr.indexOf('.'));
      const relationshipAttribute = attr.substr(attr.indexOf('.') + 1);

      const relationship = ob[association];

      const key = attr.replace('.', '_');
      newOb[key] = relationship[relationshipAttribute];
    } else {
      newOb[attr] = ob[attr];
    }
  }

  return newOb;
};

export const getKeyByValue = (ob: object, value: any) => {
  return Object.keys(ob).find(key => ob[key] === value);
};
