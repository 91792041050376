import React, { useEffect, useState } from 'react';
import { useResponsive } from 'react-hooks-responsive';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PatientsActions } from '@/actions/actions/patients';
import { setSnapshot } from '@/actions/reducers/generic';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import Paginator from '@/components/paginator/Paginator';
import { Cell, Header, Row } from '@/components/table';
import {
  Nullable,
  Serialized,
  SideEffect,
  Snapshot,
  UUID,
} from '@/types/generic';
import { Column, TableConfig } from '@/types/table';
import { useInterval } from '@/utils/activityUtils';
import { buildRequestPath, flatten, parseResponseData } from '@/utils/apiUtils';
import Modeler from '@/utils/modeler/modeler';
import { areObjectsEqual } from '@/utils/objectUtils';
import { buildPath } from '@/utils/routeUtils';
import { usePrevious } from '@/utils/stateUtils';
import ActivityIndicator from '../activity/ActivityIndicator';
import { ParametricSelector } from 'reselect';

const breakpoints = { xs: 0, mobile: 768 };

interface Props {
  config: TableConfig;
  development?: boolean;
  forceUpdate?: boolean;
  onForceUpdate?: SideEffect;
  snapshot?: Snapshot;
  shouldUseModeler?: boolean;
  modelerGenerations?: number;
  FormattedData?:(params:any)=>any;
  whichModule?:string;
}

export const Table = (props: Props) => {
  const {
    config,
    development,
    forceUpdate,
    onForceUpdate,
    snapshot,
    shouldUseModeler,
    modelerGenerations,
    FormattedData,
    whichModule,
  } = props;

  const initialSort = config.initialSort
    ? config.initialSort.split(':')
    : [null, null];
 
  const dispatch = useDispatch();

  const history = useHistory();

  const { screenIsAtMost } = useResponsive(breakpoints);

  const [data, setData] = useState<any[]>([]);
  const [included, setIncluded] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setisLoading] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sortColumn, setSortColumn] = useState<Nullable<string>>(
    initialSort[0],
  );
  const [sortDirection, setSortDirection] = useState<Nullable<string>>(
    initialSort[1],
  );
  const prevQuery = usePrevious(config.searchQuery);

  const isMobile = screenIsAtMost('mobile');

  useEffect(() => {
    if (snapshot) {
      setCurrentPage(snapshot.currentPage);
      setTotalPages(snapshot.totalPages);
      setData(snapshot.data);
    }
  }, []);

  useEffect(() => {
    if (prevQuery === undefined) {
      return;
    }

    if (!prevQuery || !areObjectsEqual(config.searchQuery, prevQuery)) {
      setCurrentPage(1);
      getTablePage();
    }
  }, [config.searchQuery]);

  useEffect(() => {
    getTablePage();
  }, [currentPage, sortColumn, sortDirection]);

  if (config.reloadSeconds) {
    useInterval(() => {
      getTablePage();
    }, 1000 * (config.reloadSeconds || 0));
  }

  useEffect(() => {
    createSnapshot();
  }, [data]);

  useEffect(() => {
    if (forceUpdate) {
      getTablePage();
      onForceUpdate();
    }
  }, [forceUpdate]);

  const reducer = (total, column) => {
    if (isMobile && !column.isVisibleMobile) {
      return total;
    }

    return (column.width || 1) + total;
  };

  const handleSortColumn = (columnName: string) => {
    if (columnName === sortColumn) {
      const newSort = sortDirection === 'asc' ? 'desc' : 'asc';
      setSortDirection(newSort);
    } else {
      setSortColumn(columnName);
      setSortDirection('asc');
    }
  };

  const hasEditAction = !!config.rows?.editPath || config.rows?.editPath === '';
  const hasViewAction = !!config.rows?.viewPath || config.rows?.viewPath === '';
  const hasCustomAction = !!config.rows?.customText;

  const hasRowActions = hasEditAction || hasViewAction || hasCustomAction;

  const totalColumnSpace = config.columns.reduce(reducer, 0) + hasRowActions;

  const columnSortDirection = (column: Column) => {
    if (column.attribute === sortColumn || column.sortField === sortColumn) {
      return sortDirection;
    }

    return null;
  };

  const createSnapshot = () => {
    const newSnapshot = {
      currentPage,
      data,
      sortBy: null,
      sortDirection: null,
      totalPages,
    };
     dispatch(setSnapshot(newSnapshot, PatientsActions.SET_SNAPSHOT));
  };

  const formatCell = (entry: any, column: Column) => {
    const value = entry[column.attribute];

    if (column.formatAttribute) {
      return column.formatAttribute(value);
    }

    if (column.formatEntry) {
      return column.formatEntry(entry, included);
    }

    return value;
  };

  const getTablePage = () => {
    setisLoading(true);

    const queryParams = {
      page: currentPage,
      sort: '',
      ...(config.searchQuery || {}),
    };

    if (sortColumn) {
      const sortValue = `${sortColumn}:${sortDirection}`;
      queryParams.sort = sortValue;
    }

    const searchPath = buildRequestPath(config.searchPath, queryParams);

    Api.utility.get(searchPath).then(response => {
      const responseData = parseResponseData(response);

      const formattedData = (() => {
        if (shouldUseModeler) {
          return new Modeler(response.data, {
            generations: modelerGenerations || 1,
          }).build();
        } else {
          return responseData.data;
        }
      })();

      if(whichModule=="PatientEditAll"){
       FormattedData(formattedData);}

      if (development) {
        // console.log('TABLE FETCH RESPONSE');
        // console.log('Response data', responseData);
         //console.log('Formatted data', formattedData);
      }
 

      if(responseData.de_identify!=undefined){
        sessionStorage.setItem('de_identify',responseData.de_identify);
        }
      if(responseData.patient_export!=undefined){
        sessionStorage.setItem('patient_export',responseData.patient_export);
        }
      setTotalPages(responseData.meta.total);
      setData(formattedData);
      setIncluded(responseData.included);

      setisLoading(false);
    });
  };

  const handleAddEntry = () => {
    if (config.header?.addPath) {
      history.push(config.header.addPath);
    }
  };

  const handleChangePage = (toPage: number) => {
    if (!isLoading) {
      setCurrentPage(toPage);
    }
  };

  const handleClickRow = (entry: any) => {
    if (config.rows?.onClickPath) {
      const action: any = config.rows.onClickPath;

      if (typeof action === 'string') {
        const path = buildPath(config.rows.onClickPath as string, {
          id: entry.id,
        });
        history.push(path);
      } else if (typeof action === 'function') {
        action(entry);
      }
    }
  };

  const handleEditEntry = (entry: any) => {
    if (config.rows.editId) {
      const editId = config.rows.editId;
      history.push(buildPath(config.rows.editPath, { [editId]: entry.id }));
    } else {
      history.push(buildPath(config.rows.editPath, { id: entry.id }));
    }
  };

  const handleViewEntry = (entry: any) => {
    if (typeof config.rows.viewPath === 'function') {
      return config.rows.viewPath(entry);
    }

    if (config.rows.viewId) {
      const viewId = config.rows.viewId;
      history.push(buildPath(config.rows.viewPath, { [viewId]: entry.id }));
    } else {
      history.push(buildPath(config.rows.viewPath, { id: entry.id }));
    }
  };

  const handleCustomAction = (entry: any) => {
    if (config.rows.customAction) {
      config.rows.customAction(entry);
    }
  };

  const handleRemoveAction = (entry: any) => {
    if (config.rows.removeAction) {
      config.rows.removeAction(entry);
    }
  };

  const renderActionColumn = (entry: any) => {
    if (!hasRowActions) {
      return null;
    }

    const columnWidthPercent = (1 / totalColumnSpace) * 100;

    const renderCustomAction = () => {
      const text =
        typeof config.rows.customText === 'function'
          ? config.rows.customText(entry)
          : config.rows.customText;

      if (!text) {
        return null;
      }

      return (
        <Button
          color="white"
          isCompressed
          onClick={() => handleCustomAction(entry)}>
          {text}
        </Button>
      );
    };

    const showEdit = (() => {
      if (config.rows.shouldShowEdit) {
        return config.rows.shouldShowEdit(entry);
      } else {
        return true;
      }
    })();

    return (
      <Cell isAlignedRight style={{ width: `${columnWidthPercent}%` }}>
        <div className="table__action-column">
          {config.rows.customText && renderCustomAction()}

          {config.rows.viewPath && (
            <Button
              color="white"
              isCompressed
              onClick={() => handleViewEntry(entry)}>
              View
            </Button>
          )}

          {config.rows.editPath && showEdit && (
            <Button
              color="white"
              isCompressed
              onClick={() => handleEditEntry(entry)}>
              Edit
            </Button>
          )}

          {config.rows.removeAction && (
            <Button
              color="white"
              isCompressed
              onClick={() => handleRemoveAction(entry)}>
              Remove
            </Button>
          )}
        </div>
      </Cell>
    );
  };

  const renderBody = () => {
    if (isLoading && !data.length) {
      return null;
    }
    
    return data.map((entry: Serialized<any>) => {
      const additionalClass = config.rows?.addRowClass
        ? config.rows.addRowClass(entry)
        : null;

      return (
        <Row
          additionalClass={additionalClass}
          key={entry.id}
          onClickRow={
            config.rows?.onClickPath ? () => handleClickRow(entry) : null
          }>
          {renderColumns(flatten(entry))}
          {renderActionColumn(entry)}
        </Row>
      );
    });
  };

  const renderColumns = (entry: any) => {
    return config.columns.map((column: Column, index: number) => {
      if (isMobile && !column.isVisibleMobile) {
        return null;
      }

      if (column.hideColumn) {
        return null;
      }

      const displayValue = formatCell(entry, column);

      const columnWidth = column.width || 1;
      const columnWidthPercent = (columnWidth / totalColumnSpace) * 100;

      const flashValue = column.flashValue
        ? column.flashValue(entry)
        : displayValue;

      return (
        <Cell
          className={column.className}
          flashValue={column.isLive ? flashValue : null}
          key={`${entry.id}-${index}`}
          shouldHideData={column.hideData}
          style={{ width: `${columnWidthPercent}%` }}>
          {displayValue}
        </Cell>
      );
    });
  };

  const renderHeaderActions = () => {
    if (!config.header?.addPath) {
      return null;
    }

    return (
      <div className="table__action-header">
        <Button color="secondary" onClick={handleAddEntry}>
          {config.header?.addText || 'Add'}
        </Button>
      </div>
    );
  };

  const renderHeaders = () => {
    return config.columns.map((column, index) => {
      if (isMobile && !column.isVisibleMobile) {
        return null;
      }

      if (column.hideColumn) {
        return null;
      }

      const columnWidth = column.width || 1;
      const columnWidthPercent = (columnWidth / totalColumnSpace) * 100;

      const headerSortDirection = columnSortDirection(column);

      return (
        <Header
          key={index}
          shouldHideData={column.hideData}
          onClickHeader={() =>
            handleSortColumn(column.sortField || column.attribute)
          }
          isSortable={column.isSortable}
          sortDirection={headerSortDirection}
          style={{ width: `${columnWidthPercent}%` }}>
          {column.header || ''}
        </Header>
      );
    });
  };

  const headerWidthPercent = (1 / totalColumnSpace) * 100;

  return (
    <>
      {renderHeaderActions()}

      <div className="table__wrapper">
        <table className="table">
          <thead>
            <Row>
              {renderHeaders()}
              {hasRowActions && (
                <Header
                  isAlignedRight
                  style={{ width: `${headerWidthPercent}%` }}>
                  Actions
                </Header>
              )}
            </Row>
          </thead>
          <tbody>{renderBody()}</tbody>
        </table>

        {!isLoading && !data.length && config.emptyState && (
          <p className="table__is-empty">{config.emptyState}</p>
        )}

        {totalPages > 1 && (
          <Paginator
            currentPage={currentPage}
            onChangePage={handleChangePage}
            totalPages={totalPages}
          />
        )}

        {isLoading && <ActivityIndicator />}
      </div>
    </>
  );
};

export default Table;
