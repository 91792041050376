import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Router } from 'react-router-dom';

import store from '@/actions/configureStore';
import Header from '@/components/header/Header';
import history from '@/contexts/app/history';
import Home from '@/contexts/app/Home';

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'production' ? process.env.SENTRY_DSN_URL : null,
  ignoreErrors: ['ResizeObserver'],
});

document.addEventListener('DOMContentLoaded', () => {
  require('@rails/ujs').start();
  require('@rails/activestorage').start();
  // require('channels');

  const appNode = document.getElementById('app');
  const navNode = document.getElementById('nav-data');
  const userNode = document.getElementById('user-data');

  const getFlashData = () => {
    if (userNode) {
      return userNode.getAttribute('flash');
    }

    return '';
  };

  const getUserData = (node: any) => {
    if (node) {
      return JSON.parse(node.getAttribute('user'));
    }

    return {};
  };

  const getBrandData = (node: any) => {
    if (node) {
      return node.getAttribute('brand');
    }

    return 'default';
  };

  if (navNode) {
    const user = getUserData(navNode);
    const brand = getBrandData(navNode);

    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <Header brand={brand} user={user} />
        </BrowserRouter>
      </Provider>,
      navNode,
    );
  } else if (appNode) {
    const flash = getFlashData();
    const user = getUserData(userNode);
    const brand = getBrandData(userNode);

    ReactDOM.render(
      <Provider store={store}>
        <Router history={history}>
          <Home brand={brand} flash={flash} user={user} />
        </Router>
      </Provider>,
      appNode,
    );
  }
});
