import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AllergiesEdit from '@/contexts/patients/modules/allergies/AllergiesEdit';
import AllergiesIndex from '@/contexts/patients/modules/allergies/AllergiesIndex';
import AllergiesNew from '@/contexts/patients/modules/allergies/AllergiesNew';
import { Routes } from '@/utils/routeUtils';

const Patients = () => {
  return (
    <Switch>
      <Route path={Routes.patientsAllergiesEdit} component={AllergiesEdit} />
      <Route path={Routes.patientsAllergiesNew} component={AllergiesNew} />
      <Route path={Routes.patientsAllergies} component={AllergiesIndex} />
    </Switch>
  );
};

export default Patients;
