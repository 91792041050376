import { Attributable } from '@/types/generic';
import moment from 'moment';
import { formatIsoDateTime } from './dateUtils';
import { toInt } from './numberUtils';

export const formatSeconds = (
  totalSeconds: number = 0,
  withHours: boolean = false,
): string => {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);

  const minutes = totalMinutes % 60;
  const seconds = totalSeconds % 60;

  if (withHours) {
    return `${padZero(totalHours)}:${padZero(minutes)}:${padZero(seconds)}`;
  }

  return `${padZero(totalMinutes)}:${padZero(seconds)}`;
};

export const formatMinutes = (totalMinutes: number = 0): string => {
  const totalHours = Math.floor(totalMinutes / 60);

  const minutes = totalMinutes % 60;

  return `${padZero(totalHours)}:${padZero(minutes)}`;
};

/**
 * Takes a duration in seconds and returns an array of the duration in hours, minutes, seconds
 * @param  {number|string} duration
 * @param  {boolean} isInSeconds?
 * @returns number
 */

export const breakDuration = (
  duration: number | string,
): [number, number, number] => {
  const durationInt = toInt(duration);

  const hours = Math.floor(durationInt / (60 * 60));
  const remainingMinutesInSeconds = durationInt % (60 * 60);

  const minutes = Math.floor(remainingMinutesInSeconds / 60);
  const seconds = remainingMinutesInSeconds % 60;

  return [hours, minutes, seconds];
};

/**
 * Takes hours, minutes, seconds and returns total duration in seconds.
 * Nullish arguments are treated as zeros.
 * @param  {number|string} hours
 * @param  {number|string} minutes
 * @param  {number|string} seconds
 * @returns number
 */

export const buildDuration = (
  hours: number | string | null | undefined,
  minutes: number | string | null | undefined,
  seconds: number | string | null | undefined = 0,
): number => {
  const hoursInt = toInt(hours ?? 0);
  const minutesInt = toInt(minutes ?? 0);
  const secondsInt = toInt(seconds ?? 0);

  return (hoursInt * 60 + minutesInt) * 60 + secondsInt;
};

export const buildDurationFromForm = (form: Attributable): Attributable => {
  const { hours, minutes, seconds } = form;

  return {
    ...form,
    duration: buildDuration(hours, minutes, seconds),
  };
};

export const formatMinutesToHours = (minutes: number): string => {
  return formatSecondsToHours(minutes * 60);
};

export const formatSecondsToHours = (totalSeconds: number): string => {
  if (!totalSeconds) {
    return '00:00:00';
  }

  const minutes = Math.floor(totalSeconds / 60);
  const secondsRemaining = totalSeconds % 60;

  const hours = Math.floor(minutes / 60);
  const minutesRemaining = minutes % 60;

  return `${padZero(hours)}:${padZero(minutesRemaining)}:${padZero(
    secondsRemaining,
  )}`;
};

export const formatTime = (date: string, invalidFormat: string = '') => {
  const dateObject = moment(date);

  return dateObject.isValid() ? moment(date).format('h:mma') : invalidFormat;
};

export const padZero = (digit: number): string => {
  return digit < 10 ? `0${digit}` : digit.toString();
};

/**
 * Adds sets the provided date to the specified time. This also accounts for a lapse in day due to UTC timezone offsets. Note: The time param expects the value to come from a <TimePicker> component, which will use a Date object for storage.
 * @param  {string} date
 * @param  {string} time
 * @returns string
 */

export const combineDateAndTime = (date: string, time: string): string => {
  const dateObject = moment(date);
  const timeObject = moment(time);

  // Set time on the date
  dateObject.set({
    hour: timeObject.get('hour'),
    minute: timeObject.get('minute'),
  });

  // If the time string is not today (ex if UTC time is the following day) we need to add an extra day to the date object
  const today = moment();

  if (!today.isSame(timeObject, 'date')) {
    dateObject.add(1, 'day');
  }

  return formatIsoDateTime(dateObject);
};

export const breakDateAndTime = (date: string): [string, string] => {
  const dateObject = moment(date).local();
  const timeObject = moment(date).utc();

  const formattedDate = dateObject.startOf('day').format();

  const hours = timeObject.get('hours');
  const minutes = timeObject.get('minutes');

  const time = moment()
    .utc()
    .startOf('day')
    .add(hours, 'hours');
  time.add(minutes, 'minutes');

  const formattedTime = time.format();

  return [formattedDate, formattedTime];
};
