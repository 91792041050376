import React from 'react';
import { Link } from 'react-router-dom';

import AccessControl from '@/components/access_control/AccessControl';
import Button from '@/components/button/Button';
import Icon from '@/components/icon/Icon';
import { PolicyGroups } from '@/components/router';
import { buildPath, Routes } from '@/utils/routeUtils';

interface Props {
  onPress: () => void;
  onPressLink: () => void;
}

const SmallHeaderSubLinks = (props: Props) => {
  const { onPress, onPressLink } = props;

  return (
    <div className="header__mobile-nav-dropdown">
      <Button onClick={onPress} style="dropdown back">
        {<Icon icon="caretLeft" />}
        <p>Back</p>
      </Button>

      <AccessControl allowedRoles={PolicyGroups.nonBillingAdmins}>
        <Link
          className="header__nav-dropdown-content"
          to={Routes.users}
          onClick={onPressLink}>
          Manage Care Managers
        </Link>

        <Link
          className="header__nav-dropdown-content"
          to={Routes.externalUsers.root}
          onClick={onPressLink}>
          Manage External Users
        </Link>

        <Link
          className="header__nav-dropdown-content"
          to={Routes.root}
          onClick={onPressLink}>
          Manage Practices
        </Link>

        <Link
          className="header__nav-dropdown-content"
          to={Routes.hospitals}
          onClick={onPressLink}>
          Manage Hospitals
        </Link>

        <Link
          className="header__nav-dropdown-content"
          to={Routes.healthSystems}
          onClick={onPressLink}>
          Manage Health Systems
        </Link>

        <Link
          className="header__nav-dropdown-content"
          to={Routes.devices}
          onClick={onPressLink}>
          Manage Devices
        </Link>

        <Link
          className="header__nav-dropdown-content"
          to={Routes.specialists}
          onClick={onPressLink}>
          Manage Specialists
        </Link>
      </AccessControl>

      <AccessControl allowedRoles={PolicyGroups.atLeastBillingAdmin}>
        <Link
          className="header__nav-dropdown-content"
          to={Routes.reports}
          onClick={onPressLink}>
          Reports
        </Link>
      </AccessControl>

      <AccessControl allowedRoles={PolicyGroups.nonBillingAny}>
        <Link
          className="header__nav-dropdown-content is-borderless-bottom"
          to={Routes.medicationsRequestNew}
          onClick={onPressLink}>
          Request Medication
        </Link>
      </AccessControl>

      <AccessControl allowedRoles={PolicyGroups.atLeastSuperAdmin}>
        <Link
          className="header__nav-dropdown-content is-last"
          to={buildPath(Routes.adminResource, { resource: 'alert_types' })}
          onClick={onPressLink}>
          Portal Configuration
        </Link>
      </AccessControl>
    </div>
  );
};

export default SmallHeaderSubLinks;
