import React, { useState, useEffect } from 'react';

import { ColumnState } from '@/contexts/patients/PatientsIndex';
import { SideEffect } from '@/types/generic';
import SimpleChecklist from '@/components/form/SimpleChecklist';
import Button from '@/components/button/Button';
import { titleizeSnakeCase } from '@/utils/stringUtils';

interface Props {
  columns: ColumnState,
  onCloseModal: SideEffect;
  updateColumnState: SideEffect;
}

const ColumnSelectModalEdit = (props: Props) => {
  const { columns, onCloseModal, updateColumnState } = props;

  const [nextColumnState, setNextColumnState] = useState(columns);

  useEffect(() => {
    const userColumnDefaults = localStorage.getItem('userColumnDefaults_edit');
    if (userColumnDefaults) {
      setNextColumnState(JSON.parse(userColumnDefaults));
    }
  }, []);

  const updateNextColumnState = (column: string) => {
    const columnKey = Object.keys(nextColumnState).find(key => key === column);
    const columnValue = nextColumnState[columnKey];
    setNextColumnState(nextColumnState => ({
      ...nextColumnState,
      [columnKey]: !columnValue,
    }));
  };

  const handleClickApply = () => {
    updateColumnState(nextColumnState);
    onCloseModal();
  }

  const formatOptions = columns => {
    let formattedOptions = [];

    for (const column in columns) {
      const option = {
        label: titleizeSnakeCase(column),
        isChecked: !columns[column],
        onClick: () => updateNextColumnState(column),
      };
      formattedOptions.push(option);
    }

    return formattedOptions;
  };

  return (
    <>
      <SimpleChecklist
        options={formatOptions(nextColumnState)}
        label="Table Columns"
      />
      <div className="simple-checklist-wrapper">
        <Button
          color="secondary"
          onClick={handleClickApply}>
          Apply
        </Button>
      </div>
    </>
  );
};

export default ColumnSelectModalEdit;
