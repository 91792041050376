import React from 'react';

import { EmptyBlock } from '@/components/dashboard';
import { VirtualAppointmentQuestionnaire } from '@/types/v2/questionnaire';

interface Props {
  onClickEdit?: () => void;
  questionnaire: VirtualAppointmentQuestionnaire;
}

const QuestionnaireResponse = ({
  onClickEdit,
  questionnaire,
}: Props): JSX.Element => {
  const renderResponses = (() => {
    if (!questionnaire.questionnaire_responses.length) {
      return (
        <EmptyBlock style="compact">
          No questionnaire responses added
        </EmptyBlock>
      );
    }

    return questionnaire.questionnaire_responses.map(response => {
      const answerText = (() => {
        if (response.questionnaire_question.question_type === 'free_text') {
          return response.free_text;
        }

        return response.questionnaire_answers
          .map(answer => answer.text)
          .join(', ');
      })();

      return (
        <div
          className="questionnaire-response__answer"
          key={response.questionnaire_question.id}>
          <h5>{response.questionnaire_question.text}</h5>
          <p>{answerText}</p>
        </div>
      );
    });
  })();

  return (
    <div className="questionnaire-response">
      <div className="questionnaire-response__header">
        <h4>{questionnaire.questionnaire.name}</h4>
        {onClickEdit && <a onClick={onClickEdit}>Edit</a>}
      </div>

      {renderResponses}
    </div>
  );
};

export default QuestionnaireResponse;
