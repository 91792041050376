import React from 'react';

import { EmptyBlock } from '@/components/dashboard';
import { CareManagerResponse } from '@/state/rpm';
import { formatShortDate } from '@/utils/dateUtils';
import CareManagerBlock from '../components/CareManagerBlock';

interface Props {
  careManagers: CareManagerResponse[];
  endAt: string;
  startAt: string;
}

const CareManagersMostCalls = ({
  careManagers,
  endAt,
  startAt,
}: Props): JSX.Element => {
  const displayDate = (() => {
    const start = formatShortDate(startAt);
    const end = formatShortDate(endAt);

    return `${start} - ${end}`;
  })();

  const renderCareManagers = (() => {
    if (careManagers.length) {
      return careManagers.map(careManager => (
        <div className="column is-4" key={careManager.id}>
          <CareManagerBlock careManager={careManager} date={displayDate} />
        </div>
      ));
    }

    return (
      <div className="column">
        <EmptyBlock style="compact">No calls</EmptyBlock>
      </div>
    );
  })();

  return <div className="columns">{renderCareManagers}</div>;
};

export default CareManagersMostCalls;
