import classNames from 'classnames';
import React, { ReactNode } from 'react';

type Color = 'alt-4' | 'gold';

interface Props {
  children: ReactNode;
  color?: Color;
  withLargeHeight?: boolean;
  withLargePadding?: boolean;
}

const RectangleContainer = (props: Props) => {
  const { children, color, withLargeHeight, withLargePadding } = props;

  const styles = classNames('rectangle-container', {
    'has-large-height': withLargeHeight,
    'has-large-padding': withLargePadding,
    'is-alt-4': color === 'alt-4',
    'is-gold': color === 'gold',
  });

  return <div className={styles}>{children}</div>;
};

export default RectangleContainer;
