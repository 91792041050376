import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import { FormComponentConfig } from '@/types/form';
import { UUID } from '@/types/generic';
import { IdParams } from '@/types/params';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';

interface Params extends IdParams {
  patient_id: UUID;
}

const LabsEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { patient_id, id } = useParams<Params>();

  const [response, setResponse] = useState(null);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'labs',
    onSuccess: (res: any) => {
      setResponse(parseResponseData(res));
    },
  });

  useEffect(() => {
    getEntry();
  }, []);

  const [query] = returnTabQuery();

  const backPath = buildPath(Routes.patientsLabs, { id: patient_id }, query);

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: `api/v1/labs/${id}`,
      model: 'lab',
      method: 'PUT',
      onCancel: () => history.push(backPath),
      onSuccess: () => history.push(backPath),
    },
    state: response.attributes,
    rules: {
      test_type: { required: true },
      test_date: { required: true },
      result: { required: true },
      remarks: { required: true },
    },
    layout: [
      {
        title: 'Edit Lab',
        fields: [
          [
            {
              label: 'Source',
              field: 'test_type',
              placeholder: 'Search for source',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/lab_tests',
              size: 4,
              formatTypeaheadResult: (result: any) => {
                return {
                  label: result.attributes.test_type,
                  value: result.attributes.test_type,
                };
              },
            },
            {
              label: 'Test Date',
              field: 'test_date',
              placeholder: 'Select date',
              type: 'date',
              size: 4,
            },
          ],
          [
            {
              label: 'Result',
              field: 'result',
              placeholder: 'Enter result',
              type: 'text',
              size: 8,
            },
          ],
          [
            {
              label: 'Remarks',
              field: 'remarks',
              placeholder: 'Enter remarks',
              type: 'text',
              size: 8,
            },
          ],
          [
            {
              label: 'Upload Scan File',
              field: 'scan_files',
              type: 'upload',
              size: 8,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm backPath={backPath} backText="Back to Labs" config={config} />
  );
};

export default LabsEdit;
