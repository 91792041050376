import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { buildPath, Routes } from '@/utils/routeUtils';
import {
  getSelectedPatient,
  selectSelectedPatientPracticeId,
} from '@/selectors/api';
import BackLink from '@/components/button/BackLink';
import { CareManager } from '@/types/v2/care_manager';
import { UUID } from '@/types/generic';
import Label from '@/components/form/Label';
import useNotifier from '@/utils/messages/useNotifier';
import Api from '@/api/Api';
import Modeler from '@/utils/modeler/modeler';
import { fullName } from '@/utils/userUtils';
import Icon from '@/components/icon/Icon';
import { selectCurrentUserId } from '@/selectors/users';
import Constraint from '@/components/constraint/Constraint';
import Button from '@/components/button/Button';
import ErrorMessage from '@/components/form/ErrorMessage';

type Inputs = {
  name: string;
  assignee: UUID;
  notes: string;
};

interface AssigneeOption {
  label: string;
  value: UUID;
}

const TasksNew = (): JSX.Element => {
  const history = useHistory();
  const notifier = useNotifier();

  const patient = useSelector(getSelectedPatient);
  const patientPracticeId = useSelector(selectSelectedPatientPracticeId);
  const currentUserId = useSelector(selectCurrentUserId);

  const [assigneeOptions, setAssigneeOptions] = useState<AssigneeOption[]>([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = async (data): Promise<void> => {
    try {
      const body = {
        task: {
          name: data.name,
          notes: data.notes,
          created_by_id: currentUserId,
          assigned_to_id: data.assignee,
          patient_id: patient.id,
        },
      };

      const url = buildPath(Routes.api2.tasks);

      const response = await Api.utility.post(url, body);

      notifier.success('Task created');

      history.push(
        buildPath(Routes.patientsCareManagement, {
          id: patient.id,
        }),
      );
    } catch (err) {
      notifier.error(err);
    }
  };

  const getCareManagers = async (): Promise<void> => {
    try {
      const url = buildPath(
        Routes.api2.careManagers,
        null,
        {
          practice_id: patientPracticeId,
        },
        [],
      );

      const response = await Api.utility.get(url);

      const resource = new Modeler<CareManager[]>(response.data, {
        generations: 1,
      }).build();

      const assigneeOptions = resource.map((careManager: CareManager) => ({
        label: fullName(careManager),
        value: careManager.id,
      }));

      setAssigneeOptions(assigneeOptions);
    } catch (err) {
      notifier.error(err);
    }
  };

  useEffect(() => {
    getCareManagers();
  }, []);

    return (
      <>
        <Constraint size="large">
          <BackLink onClick={() => history.goBack()}>Cancel</BackLink>
        </Constraint>
        <Panel>
          <Constraint size="extended">
            <Container>
              <div className="tasks-new">
                <h1 className="tasks-new__header">Create Task</h1>

                <form onSubmit={e => e.preventDefault()}>
                  <div className="form-input-group with-margin-bottom">
                    <Label>Task Description</Label>
                    <input
                      className="form-input"
                      placeholder="Enter the task"
                      {...register('name', { required: true })}
                    />

                    {errors.name && (
                      <ErrorMessage message="Field is required" />
                    )}
                  </div>

                  <div className="form-input-group with-margin-bottom">
                    <Label>Assignee</Label>
                    {assigneeOptions.length === 0 ? <Label>No care managers assigned to this practice</Label> :
                      <div className="form-select">
                        <select
                          defaultValue={
                            assigneeOptions.length > 0 && assigneeOptions[0].value
                          }
                          className="form-select__input"
                          {...register('assignee', { required: true })}>
                          {assigneeOptions.map((option: AssigneeOption) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <div className="form-select__icon">
                          <Icon icon="caretDown" color="secondary" />
                        </div>
                      </div>
                    }
                  </div>

                  <div className="form-input-group with-margin-bottom">
                    <Label>Task Notes (optional)</Label>
                    <textarea
                      className="form-text-area with-no-resize"
                      {...register('notes')}
                    />
                  </div>

                  <div className="tasks-new__actions-container">
                    <Button color="white" onClick={() => history.goBack()}>
                      Cancel
                    </Button>

                    <Button color="secondary" onClick={handleSubmit(onSubmit)}>
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </Container>
          </Constraint>
        </Panel>
      </>
    );
  
};

export default TasksNew;
