import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Datepicker from '@/components/form/Datepicker';
import FileUpload from '@/components/form/FileUpload';
import InputGroup from '@/components/form/InputGroup';
import RadioList from '@/components/form/RadioList';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Panel from '@/components/panel/Panel';
import { Tab, Tabs } from '@/components/tabs';
import {
  getSelectedPatient,
  getSelectedPatientIncluded,
} from '@/selectors/api';
import { Form } from '@/utils/form';
import { FormConfig, useForm } from '@/utils/form/useForm';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';
import { useSelector } from 'react-redux';
import AgreementPreview from './AgreementPreview';
import { IdParams } from '@/types/params';

const INITIAL_STATE = {
  agreement_type: 'ccm_consent',
  attachments: [],
  date: '',
  patient_name: '',
  signatory_type: 'self',
  relation_to_patient: '',
};

const ConsentAgreementsNew = () => {
  const history = useHistory();

  const { id } = useParams<IdParams>();

  const includedRecords = useSelector(getSelectedPatientIncluded);
  const patient = useSelector(getSelectedPatient);

  const getDefaultSpecialist = () => {
    const specialistId = patient?.relationships.default_specialist?.data?.id;

    if (!specialistId) {
      return {};
    }

    return includedRecords.find(record => record.id === specialistId);
  };

  const defaultSpecialist = getDefaultSpecialist();

  const [selectedTab, setSelectedTab] = useState('new');

  const alterFormBeforeSubmit = (formState: any) => {
    if (selectedTab === 'upload') {
      return {
        ...formState,
        signatory_type: 'self',
        agreement_format: 'upload',
        patient_name: fullName(patient),
        patient_id: id,
      };
    } else {
      return {
        ...formState,
        attachments: [],
        agreement_format: 'generated',
        patient_id: id,
      };
    }
  };

  const formConfig: FormConfig = {
    alterFormBeforeSubmit,
    method: 'POST',
    model: 'consent_agreement',
    path: 'api/v1/consent_agreements',
    rules: {
      agreement_type: { required: true },
      date: { required: true },
    },
    onSuccess: () => history.push(backPath),
  };

  const form: Form = useForm(INITIAL_STATE, formConfig);

  const [query] = returnTabQuery();

  const backPath = buildPath(Routes.patientsAgreements, { id }, query);

  const agreementTypes = [
    {
      label: 'CCM Consent Form',
      value: 'ccm_consent',
    },
  ];

  const signatoryOptions = [
    {
      label: 'Sign by self as beneficiary',
      value: 'self',
    },
    {
      label: 'Sign by representative',
      value: 'representative',
    },
  ];

  const renderFormContent = () => {
    if (selectedTab === 'new') {
      return renderAddForm();
    }

    return renderUploadForm();
  };

  const renderAddForm = () => {
    return (
      <>
        <div className="columns">
          <div className="column is-4">
            <RadioList options={signatoryOptions} {...form.signatory_type} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-4">
            <InputGroup label="Print Name" {...form.patient_name} />
          </div>
        </div>

        {form.formState.signatory_type === 'representative' && (
          <div className="columns">
            <div className="column is-4">
              <InputGroup
                label="Relation to Patient"
                {...form.relation_to_patient}
              />
            </div>
          </div>
        )}

        <AgreementPreview
          date={form.formState.date}
          enrollmentDate={patient.attributes.enrollment_date}
          name={form.formState.patient_name}
          patient={fullName(patient)}
          relation={form.formState.relation_to_patient}
          shouldDisplayRelation={
            form.formState.signatory_type === 'representative'
          }
          specialist={defaultSpecialist}
        />
      </>
    );
  };

  const renderUploadForm = () => {
    return (
      <div className="columns">
        <div className="column is-4">
          <FileUpload label="Upload New Agreement" {...form.attachments} />
        </div>
      </div>
    );
  };

  return (
    <>
      <Container>
        <BackLink to={backPath}>Back to Agreements</BackLink>
      </Container>

      <Panel>
        <Constraint size="large">
          <Tabs currentTab={selectedTab} onSelectTab={setSelectedTab}>
            <Tab value="new">Add New</Tab>
            <Tab value="upload">Upload</Tab>
          </Tabs>

          <Section title="Add New Agreement">
            <div className="columns">
              <div className="column is-4">
                <SelectGroup
                  label="Agreement Type"
                  placeholder="Select agreement type"
                  options={agreementTypes}
                  {...form.agreement_type}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-4">
                <Datepicker
                  label="Date"
                  {...form.date}
                  placeholder="Select Date"
                />
              </div>
            </div>

            {renderFormContent()}

            <div className="form__actions">
              <div className="form__actions-left" />
              <div className="form__actions-right">
                <Link className="button is-white" to={backPath}>
                  Cancel
                </Link>

                <Button color="secondary" {...form.submit}>
                  Submit
                </Button>
              </div>
            </div>
          </Section>
        </Constraint>
      </Panel>
    </>
  );
};

export default ConsentAgreementsNew;
