import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { refreshPatientAction } from '@/actions/sagas/patientSaga';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import { FormComponentConfig } from '@/types/form';
import useGetRequest from '@/utils/api/useGetRequest';
import usePostRequest from '@/utils/api/usePostRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { stateOptions } from '@/utils/dropdownUtils';
import { flattenIncludes } from '@/utils/responseUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import PatientForm from '../../components/PatientForm';
import { isEnrollmentPath } from '../../utils/patientUtils';
import { IdParams } from '@/types/params';
import { UUID } from '@/types/generic';

interface Params extends IdParams {
  patient_id: UUID;
  step: string;
}

const SpecialistsEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { patient_id, id, step } = useParams<Params>();

  const isEnrollment = isEnrollmentPath();

  const backPath = (() => {
    const queryParams = new URLSearchParams(location.search || '');
    const returnTo = queryParams.get('return_to');

    if (returnTo) {
      return returnTo;
    }

    return isEnrollment
      ? buildPath(Routes.patientsEnrollment, { id: patient_id, step })
      : buildPath(Routes.patientsSpecialists, { id: patient_id });
  })();

  const [response, setResponse] = useState(null);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'specialists',
    onSuccess: (res: any) => {
      const responseData = parseResponseData(res);
      setResponse(flattenIncludes(responseData, ['address']));
    },
  });

  useEffect(() => {
    getEntry();
  }, []);

  const handleAssignSuccess = () => {
    dispatch(refreshPatientAction(id));
    history.push(backPath);
  };

  const [unassignSpecialist] = usePostRequest({
    dispatch,
    url: `api/v1/specialists/${id}/unassign`,
    body: {
      patient_id,
    },
    onSuccess: handleAssignSuccess,
  });

  const alterForm = (form: any) => {
    const { line_1, line_2, city, state, zip_code, ...formFields } = form;

    return {
      ...formFields,
      address_attributes: {
        line_1,
        line_2,
        city,
        state,
        zip_code,
      },
    };
  };

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      alterFormBeforeSubmit: alterForm,
      deleteText: 'Remove Specialist',
      path: `api/v1/specialists/${id}`,
      model: 'specialist',
      method: 'PUT',
      onDelete: unassignSpecialist,
      onSuccess: () => {
        dispatch(refreshPatientAction(patient_id));
        history.push(backPath);
      },
      onCancel: () => history.push(backPath),
    },
    state: {
      line_1: '',
      line_2: '',
      city: '',
      state: '',
      zip_code: '',
      ...response.attributes,
    },
    rules: {
      name: { required: true },
      specialty: { required: true },
      email: { required: true, type: 'email' },
      phone_number: { required: true, type: 'phone' },
      fax_number: { type: 'phone' },
    },
    layout: [
      {
        title: 'Add Specialist',
        fields: [
          [
            {
              label: 'Provider',
              field: 'name',
              placeholder: 'Enter provider name',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'Specialty',
              field: 'specialty',
              placeholder: 'Search for specialty',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/specialty_types',
              size: 8,
              formatTypeaheadResult: (result: any) => {
                return {
                  label: result.attributes.name,
                  value: result.attributes.name,
                };
              },
            },
          ],
          [
            {
              label: 'Email Address',
              field: 'email',
              placeholder: 'Enter email address',
              type: 'text',
              size: 4,
            },
            {
              label: 'Phone Number',
              field: 'phone_number',
              placeholder: 'Enter phone number',
              type: 'phonenumber',
              size: 4,
            },
            {
              label: 'Fax Number',
              field: 'fax_number',
              placeholder: 'Enter fax number',
              type: 'phonenumber',
              size: 4,
            },
          ],
          [
            {
              label: 'Street Address',
              field: 'line_1',
              placeholder: 'Enter street address',
              type: 'text',
              size: 4,
            },
            {
              label: 'Address Line 2',
              field: 'line_2',
              placeholder: 'Enter address',
              type: 'text',
              size: 4,
            },
          ],
          [
            {
              label: 'City',
              field: 'city',
              placeholder: 'Enter city',
              type: 'text',
              size: 4,
            },
            {
              label: 'State',
              field: 'state',
              placeholder: 'Select state',
              type: 'select',
              options: stateOptions,
              size: 4,
            },
            {
              label: 'Zip Code',
              field: 'zip_code',
              placeholder: 'Enter zip code',
              type: 'zipcode',
              size: 4,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Specialists"
      config={config}
    />
  );
};

export default SpecialistsEdit;
