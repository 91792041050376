import { SetSnapshot } from '@/actions/reducers/generic';
import { DeviceModel } from '@/types/device_model';
import { UUID } from '@/types/generic';
import { ReadingThreshold } from '@/types/v2/reading_threshold';

// Action types
export enum PatientsActions {
  SET_SNAPSHOT = 'PATIENTS/SET_SNAPSHOT',
  GET_PRACTICE_DEVICE_MODELS = 'PATIENTS/GET_PRACTICE_DEVICE_MODELS',
  GET_PRACTICE_DEVICE_MODELS_SUCCESS = 'PATIENTS/GET_PRACTICE_DEVICE_MODELS_SUCCESS',
  GET_PRACTICE_DEVICE_MODELS_FAILURE = 'PATIENTS/GET_PRACTICE_DEVICE_MODELS_FAILURE',
  GET_PRACTICE_READING_THRESHOLDS = 'PATIENTS/GET_PRACTICE_READING_THRESHOLDS',
  GET_PRACTICE_READING_THRESHOLDS_SUCCESS = 'PATIENTS/GET_PRACTICE_READING_THRESHOLDS_SUCCESS',
  GET_PRACTICE_READING_THRESHOLDS_FAILURE = 'PATIENTS/GET_PRACTICE_READING_THRESHOLDS_FAILURE',
}

// Action interfaces
export interface GetPatientPracticeDeviceModels {
  type: typeof PatientsActions.GET_PRACTICE_DEVICE_MODELS;
  payload: {
    practiceId: UUID;
  };
}

export interface GetPatientPracticeDeviceModelsSuccess {
  type: typeof PatientsActions.GET_PRACTICE_DEVICE_MODELS_SUCCESS;
  payload: {
    deviceModels: DeviceModel[];
    practiceId: UUID;
  };
}

export interface GetPatientPracticeDeviceModelsFailure {
  type: typeof PatientsActions.GET_PRACTICE_DEVICE_MODELS_FAILURE;
  payload: {
    message: string;
  };
}

export interface GetPatientPracticeReadingThresholds {
  type: typeof PatientsActions.GET_PRACTICE_READING_THRESHOLDS;
  payload: {
    practiceId: UUID;
  };
}

export interface GetPatientPracticeReadingThresholdsSuccess {
  type: typeof PatientsActions.GET_PRACTICE_READING_THRESHOLDS_SUCCESS;
  payload: {
    readingThresholds: ReadingThreshold[];
  };
}

export interface GetPatientPracticeReadingThresholdsFailure {
  type: typeof PatientsActions.GET_PRACTICE_READING_THRESHOLDS_FAILURE;
  payload: {
    message: string;
  };
}

// Action type list
export type PatientsActionTypes =
  | SetSnapshot<PatientsActions.SET_SNAPSHOT>
  | GetPatientPracticeDeviceModels
  | GetPatientPracticeDeviceModelsSuccess
  | GetPatientPracticeDeviceModelsFailure
  | GetPatientPracticeReadingThresholds
  | GetPatientPracticeReadingThresholdsSuccess
  | GetPatientPracticeReadingThresholdsFailure;

// Action creators
export const getPatientPracticeDeviceModels = (
  practiceId: UUID,
): PatientsActionTypes => ({
  type: PatientsActions.GET_PRACTICE_DEVICE_MODELS,
  payload: { practiceId },
});

export const getPatientPracticeDeviceModelsSuccess = (
  deviceModels: DeviceModel[],
  practiceId: UUID,
): PatientsActionTypes => ({
  type: PatientsActions.GET_PRACTICE_DEVICE_MODELS_SUCCESS,
  payload: { deviceModels, practiceId },
});

export const getPatientPracticeDeviceModelsFailure = (
  message: string = '',
): PatientsActionTypes => ({
  type: PatientsActions.GET_PRACTICE_DEVICE_MODELS_FAILURE,
  payload: { message },
});

export const getPatientPracticeReadingThresholds = (
  practiceId: UUID,
): PatientsActionTypes => ({
  type: PatientsActions.GET_PRACTICE_READING_THRESHOLDS,
  payload: { practiceId },
});

export const getPatientPracticeReadingThresholdsSuccess = (
  readingThresholds: ReadingThreshold[],
): PatientsActionTypes => ({
  type: PatientsActions.GET_PRACTICE_READING_THRESHOLDS_SUCCESS,
  payload: { readingThresholds },
});

export const getPatientPracticeReadingThresholdsFailure = (
  message: string = '',
): PatientsActionTypes => ({
  type: PatientsActions.GET_PRACTICE_READING_THRESHOLDS_FAILURE,
  payload: { message },
});
