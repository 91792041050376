import React, { useEffect, useRef } from 'react';

import {
  setDialerModalVisibility,
  setSelectedPhoneNumber,
} from '@/actions/reducers/call_manager';
import Keypad from '@/components/keypad/Keypad';
import Modal from '@/components/modal/Modal';
import { selectSelectedPhoneNumber } from '@/selectors/call_manager';
import { SideEffect } from '@/types/generic';
import { useDispatch, useSelector } from 'react-redux';
import KeypadDisplay from '../keypad/KeypadDisplay';
import CallButton from './CallButton';

interface Props {
  isVisible: boolean;
  onDialPatient: SideEffect;
}

const DialerModal = (props: Props) => {
  const { isVisible, onDialPatient } = props;

  const isVisibleRef = useRef(false);
  const enteredNumberRef = useRef('');

  const dispatch = useDispatch();

  const enteredNumber = useSelector(selectSelectedPhoneNumber);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    isVisibleRef.current = isVisible;
  }, [isVisible]);

  useEffect(() => {
    enteredNumberRef.current = enteredNumber;
  }, [enteredNumber]);

  const handleAddNumber = (key: string) => {
    const currentNumber = enteredNumberRef.current;

    if (key === 'Backspace' && currentNumber.length > 1) {
      return dispatch(setSelectedPhoneNumber(currentNumber.slice(0, -1)));
    }

    if (currentNumber.length >= 11) {
      return;
    }

    if (currentNumber.length === 0 && key !== '1') {
      dispatch(setSelectedPhoneNumber(`1${key}`));
    } else {
      dispatch(setSelectedPhoneNumber(`${currentNumber}${key}`));
    }
  };

  const handleClickCall = () => {
    if (enteredNumber.length === 11) {
      onDialPatient();
    }
  };

  const handleCloseModal = () => {
    dispatch(setSelectedPhoneNumber(''));
    dispatch(setDialerModalVisibility(false));
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (isVisibleRef.current && /\d|Backspace/.test(e.key)) {
      handleAddNumber(e.key);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal isVisible onCloseModal={handleCloseModal}>
      <div className="columns is-centered">
        <div className="column is-7-touch is-7-desktop">
          <KeypadDisplay formatDigits value={enteredNumber} />
          <Keypad onAddNumber={handleAddNumber} />

          <CallButton
            isDisabled={enteredNumber.length < 11}
            onClick={handleClickCall}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DialerModal;
