import classNames from 'classnames';
import React, { useMemo } from 'react';

import Icon from '@/components/icon/Icon';
import { ReadingType } from '@/types/device_reading';
import {
  convertIntToType,
  ReadingThreshold,
} from '@/types/v2/reading_threshold';
import { byValue } from '../utils';

interface Props {
  readingType: ReadingType;
  thresholds?: ReadingThreshold[];
  isVertical?: boolean;
}

const ReadingLegend = ({
  isVertical = false,
  readingType,
  thresholds = [],
}: Props) => {
  const readingTypeDisplays = {
    '1': [
      ['65-120', 'low'],
      ['120-200', 'medium'],
      ['200+', 'high'],
    ], // Blood Glucose readings
    '2': [
      ['85-130', 'low'],
      ['130-180', 'medium'],
      ['180+', 'high'],
    ], // Blood Pressure, systolic readings
  };

  const readingTypeNames = {
    '1': 'Blood Glucose',
    '2': 'Systolic',
    '7': 'Blood Oxygen',
  };

  const readingThresholds = useMemo(() => {
    const readingTypeString = convertIntToType(readingType);
    return thresholds
      .filter(threshold => threshold.reading_type === readingTypeString)
      .sort(byValue);
  }, [readingType]);

  const values = useMemo(() => {
    if (readingThresholds.length) {
      return readingThresholds.map(threshold => {
        const text = threshold.max_value
          ? `${threshold.min_value}-${threshold.max_value}`
          : `${threshold.min_value}+`;

        return [text, threshold.level];
      });
    }

    return readingTypeDisplays[readingType];
  }, [readingThresholds]);

  if (!values || !values.length) {
    return null;
  }

  const renderLegend = (() => {
    return values.map((value, index) => (
      <div className="reading_legend__entry" key={index}>
        <Icon icon={value[1]} />
        <span>{value[0]}</span>
      </div>
    ));
  })();

  return (
    <div className="reading_legend">
      <h4>{readingTypeNames[readingType]} Readings</h4>

      <div
        className={classNames('reading_legend__entries', {
          'is-vertical': isVertical,
        })}>
        {renderLegend}
      </div>
    </div>
  );
};

export default ReadingLegend;
