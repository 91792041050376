import React from 'react';

import { UUID } from '@/types/generic';
import { VirtualAppointmentQuestionnaire } from '@/types/v2/questionnaire';
import { updateArrayIndex } from '@/utils/arrayUtils';
import Question from './Question';
import { FormResponse, ResponseState } from './types';

interface Props {
  onUpdateResponses: (
    questionnaireId: UUID,
    questionnaireResponses: ResponseState[],
  ) => void;
  questionnaire: VirtualAppointmentQuestionnaire;
  responses: ResponseState[];
}

const AppointmentQuestionnaire = ({
  onUpdateResponses,
  questionnaire,
  responses,
}: Props): JSX.Element => {
  const setResponses = (value: ResponseState[]): void => {
    onUpdateResponses(questionnaire.id, value);
  };

  const handleUpdateText = (questionId: UUID, text: string): void => {
    const responseIndex = responses.findIndex(
      response => response.questionnaire_question_id === questionId,
    );

    if (responseIndex === -1) {
      // If no response is available, create a new one
      const newResponse: FormResponse = {
        free_text: text,
        questionnaire_question_id: questionId,
      };

      setResponses([...responses, newResponse]);
    } else {
      const updatedResponse = {
        ...responses[responseIndex],
        free_text: text,
      };

      setResponses(updateArrayIndex(responses, responseIndex, updatedResponse));
    }
  };

  const handleSelectAnswer = (questionId: UUID, answerId: UUID): void => {
    const responseIndex = responses.findIndex(
      response => response.questionnaire_question_id === questionId,
    );

    if (responseIndex === -1) {
      const newAnswer = {
        questionnaire_answer_id: answerId,
      };

      const newResponse: FormResponse = {
        free_text: null,
        questionnaire_question_id: questionId,
        questionnaire_response_answers: [newAnswer],
      };

      setResponses([...responses, newResponse]);
    } else {
      const response = { ...responses[responseIndex] };

      const cleanedAnswers = response.questionnaire_response_answers.filter(
        answer => answer.id,
      );

      const newAnswer = {
        questionnaire_answer_id: answerId,
      };

      // Update the list of linked answers based on whether or not they are current
      // records that must be deleted or newly added records that can be filtered
      // out before the request is made
      if (!cleanedAnswers.length) {
        cleanedAnswers.push(newAnswer);
      } else if (cleanedAnswers[0].questionnaire_answer_id === answerId) {
        delete cleanedAnswers[0]._destroy;
      } else {
        cleanedAnswers[0]._destroy = true;
        cleanedAnswers.push(newAnswer);
      }

      response.questionnaire_response_answers = cleanedAnswers;
      setResponses(updateArrayIndex(responses, responseIndex, response));
    }
  };

  const handleToggleAnswer = (questionId: UUID, answerId: UUID): void => {
    const responseIndex = responses.findIndex(
      response => response.questionnaire_question_id === questionId,
    );

    const newAnswer = {
      questionnaire_answer_id: answerId,
    };

    if (responseIndex === -1) {
      const newResponse: FormResponse = {
        free_text: null,
        questionnaire_question_id: questionId,
        questionnaire_response_answers: [newAnswer],
      };

      setResponses([...responses, newResponse]);
    } else {
      const response = { ...responses[responseIndex] };

      const answerIndex = response.questionnaire_response_answers.findIndex(
        answer => answer.questionnaire_answer_id === answerId,
      );

      // Make changes to response based on toggled data
      if (answerIndex === -1) {
        response.questionnaire_response_answers.push(newAnswer);
      } else {
        const answer = {
          ...response.questionnaire_response_answers[answerIndex],
        };

        if (answer.id) {
          answer._destroy = !answer._destroy;
          response.questionnaire_response_answers = updateArrayIndex(
            response.questionnaire_response_answers,
            answerIndex,
            answer,
          );
        } else {
          response.questionnaire_response_answers.splice(answerIndex, 1);
        }
      }

      setResponses(updateArrayIndex(responses, responseIndex, response));
    }
  };

  const renderQuestions = (() => {
    return questionnaire.questionnaire.questionnaire_questions.map(question => {
      const questionResponse = responses.find(
        response => response.questionnaire_question_id === question.id,
      );

      return (
        <Question
          key={question.id}
          onSelectAnswer={handleSelectAnswer}
          onToggleAnswer={handleToggleAnswer}
          onUpdateText={handleUpdateText}
          question={question}
          response={questionResponse}
        />
      );
    });
  })();

  return (
    <div className="appointment-questionnaire">
      <div className="columns">
        <div className="column">
          <h5>{questionnaire.questionnaire.name}</h5>
        </div>
      </div>

      {renderQuestions}
    </div>
  );
};

export default AppointmentQuestionnaire;
