import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getSelectedPatient } from '@/selectors/api';
import { FormComponentConfig } from '@/types/form';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { useSelector } from 'react-redux';
import PatientForm from '../../components/PatientForm';
import { IdParams } from '@/types/params';

const NotesNew = () => {
  const history = useHistory();

  const { id } = useParams<IdParams>();

  const patient = useSelector(getSelectedPatient);

  const [query] = returnTabQuery();

  const backPath = buildPath(
    query.service === 'rpm'
      ? Routes.patientsCareManagementRpm
      : Routes.patientsCareManagement,
    { id },
    query,
  );

  const serviceOptions = useMemo(() => {
    return patient.attributes.patient_services.data.map(service => ({
      label: service.attributes.name,
      value: service.attributes.service_id,
    }));
  }, [patient]);

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/notes',
      model: 'note',
      mergeFields: {
        patient_id: id,
      },
      method: 'POST',
      onCancel: () => history.push(backPath),
      onSuccess: () => history.push(backPath),
      submitText: 'Save',
    },
    state: {
      content: '',
      service_id: serviceOptions.length === 1 ? serviceOptions[0].value : '',
    },
    rules: {
      content: { required: true },
      service_id: { required: true },
    },
    layout: [
      {
        title: 'Add Note',
        fields: [
          [
            {
              field: 'content',
              type: 'textarea',
              size: 12,
            },
          ],
          [
            {
              label: 'Service Type',
              field: 'service_id',
              type: 'select',
              size: 4,
              placeholder: 'Select service',
              options: serviceOptions,
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Notes List"
      config={config}
    />
  );
};

export default NotesNew;
