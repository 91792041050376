import React from 'react';

import { formatShortDate } from '@/utils/dateUtils';
import { formatTime } from '@/utils/timeUtils';

interface Props {
  date: string;
  time?: string;
}

const DateTimeCell = (props: Props) => {
  const { date, time } = props;

  return (
    <div className="table__date-time">
      <span>{formatShortDate(date)}</span>
      <span>{formatTime(time || date)}</span>
    </div>
  );
};

export default DateTimeCell;
