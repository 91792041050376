import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import { FormComponentConfig } from '@/types/form';
import useGetRequest from '@/utils/api/useGetRequest';
import { parseResponseData } from '@/utils/apiUtils';
import { buildPath, returnTabQuery, Routes } from '@/utils/routeUtils';
import { fullName } from '@/utils/userUtils';
import { useDispatch } from 'react-redux';
import PatientForm from '../../components/PatientForm';
import { isRpmService } from '../../utils/patientUtils';
import { UUID } from '@/types/generic';

interface Params {
  call_id: UUID;
  patient_id: UUID;
}

const ScheduledCallsNew = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { call_id, patient_id } = useParams<Params>();

  const [query] = returnTabQuery();

  const isRpm = isRpmService();

  const backPath = buildPath(
    isRpm ? Routes.patientsCareManagementRpm : Routes.patientsCareManagement,
    { id: patient_id },
    query,
  );

  const [response, setResponse] = useState(null);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id: call_id,
    model: 'calls',
    url: buildPath(
      'api/v1/calls/:id',
      { id: call_id },
      { include: 'care_manager' },
    ),
    onSuccess: (res: any) => {
      const data = parseResponseData(res);
      data.attributes.user_id = data.attributes.care_manager.id;
      data.attributes.start_date = data.attributes.start_at;
      data.attributes.start_time = data.attributes.start_at;
      setResponse(data);
    },
  });

  useEffect(() => {
    getEntry();
  }, []);

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  const initialSelection = () => {
    return {
      label: `${response.attributes.care_manager.first_name} ${response.attributes.care_manager.last_name}`,
      value: response.attributes.care_manager.id,
    };
  };

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: `api/v1/calls/${call_id}`,
      model: 'call',
      method: 'PUT',
      onSuccess: () => history.push(backPath),
      alterFormBeforeSubmit: (formState: any) => {
        const { start_date, start_time, ...form } = formState;

        const date = moment(start_date);
        const time = moment(start_time);

        date.set('hour', time.hour());
        date.set('minute', time.minute());

        return {
          ...form,
          start_at: date.toISOString(),
          patient_id,
          call_type: 'standard',
          status: 'scheduled',
        };
      },
    },
    state: response.attributes,
    rules: {
      user_id: { required: true },
      start_at: { required: true },
      service_id: { required: true },
    },
    layout: [
      {
        title: 'Edit Scheduled Call',
        fields: [
          [
            {
              label: 'Date',
              field: 'start_date',
              type: 'date',
              size: 4,
            },
            {
              label: 'Time',
              field: 'start_time',
              type: 'time',
              size: 4,
            },
          ],
          [
            {
              label: 'Care Manager',
              field: 'user_id',
              placeholder: 'Search for care manager',
              type: 'typeahead',
              clearFalseResultOnBlur: true,
              typeaheadSearchPath: 'api/v1/care_managers',
              size: 4,
              initialSelection: initialSelection(),
              formatTypeaheadResult: (result: any) => {
                return {
                  label: fullName(result),
                  value: result.id,
                };
              },
            },
          ],
        ],
      },
    ],
  };

  return (
    <PatientForm
      backPath={backPath}
      backText="Back to Call Schedule"
      config={config}
    />
  );
};

export default ScheduledCallsNew;
