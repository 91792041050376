import React from 'react';

import { AdminUser } from '@/types/v2/admin_user';

interface Props {
  user: AdminUser;
}

const Profile = ({ user }: Props) => {
  const renderImage = () => {
    if (user.specialist.image_thumbnail_url) {
      return (
        <div className="profile-display__image">
          <img src={user.specialist.image_thumbnail_url} />
        </div>
      );
    }
  };

  return (
    <div className="profile-display">
      <div className="profile-display__header-wrapper">
        {renderImage()}
        <h2>{user.specialist.name}</h2>
      </div>

      <div className="profile-display__section">
        <div className="profile-display__text">
          <span>{user.specialist.specialty}</span>
        </div>

        <span>{user.specialist.scheduling_notes}</span>

        <div className="profile-display__text has-margin-top">
          <span>{user.practice.name}</span>
          <span>{user.practice.hospital.name}</span>
          <span>{user.practice.health_system.name}</span>
        </div>
      </div>
    </div>
  );
};

export default Profile;
