import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { getCurrentUser } from '@/selectors/users';
import { SearchQuery } from '@/types/table';
import { Specialist } from '@/types/v2/specialist';
import useSimpleForm from '@/utils/form/useSimpleForm';
import Modeler from '@/utils/modeler/modeler';
import PracticeSpecialistsTable from '../components/PracticeSpecialistTable';
import SearchHeader, { FormState } from '../components/SearchHeader';

const initialSearchQuery: FormState = {
  search: '',
  per: '24',
};

const Providers = () => {
  const user = useSelector(getCurrentUser);

  const specialist = useMemo(() => {
    return new Modeler<Specialist>(user).build();
  }, [user]);

  const form = useSimpleForm<typeof initialSearchQuery>(initialSearchQuery);
  const [searchInput, setSearchInput] = useState<string>('');

  const handleChangeSearch = (value: string): void => {
    setSearchInput(value);
  };

  const handleClearFilters = useCallback((): void => {
    form.set(initialSearchQuery);
  }, []);

  const handleStartSearch = useCallback((): void => {
    form.update('search', searchInput);
  }, [searchInput]);

  return (
    <>
      <Panel>
        <Container>
          <SearchHeader
            form={form.data}
            onChangeForm={form.update}
            onChangeSearch={handleChangeSearch}
            onClearFilters={handleClearFilters}
            onStartSearch={handleStartSearch}
            searchQuery={searchInput}
          />

          {/* This Typing is needed to force compatibility in the form interface */}
          <PracticeSpecialistsTable
            practiceId={specialist.practice_id}
            query={(form.data as unknown) as SearchQuery}
          />
        </Container>
      </Panel>
    </>
  );
};

export default Providers;
