import React from 'react';

import { SideEffect } from '@/types/generic';
import Radio from './Radio';

interface Props {
  label: string;
  onChange: SideEffect;
  selectedValue: any;
  value: any;
}

const RadioGroup = (props: Props) => {
  const { label, onChange, selectedValue, value } = props;

  const isChecked = value === selectedValue;

  const handleClickLabel = () => {
    onChange(value);
  };

  return (
    <label className="form-radio-wrapper" onClick={handleClickLabel}>
      <Radio isChecked={isChecked} />
      <span>{label}</span>
    </label>
  );
};

export default RadioGroup;
