import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@/components/button/Button';
import RectangleContainer from '@/components/container/RectangleContainer';
import { SideEffect } from '@/types/generic';
import { useInterval } from '@/utils/activityUtils';
import useGetRequest from '@/utils/api/useGetRequest';
import { formatSecondsToHours } from '@/utils/timeUtils';

interface Props {
  onViewClick: SideEffect;
}

const AdminDashboardLiveView = (props: Props) => {
  const { onViewClick } = props;

  const dispatch = useDispatch();

  const [data, setData] = useState<any>({});

  const [getData] = useGetRequest({
    dispatch,
    url: 'api/v1/dashboards/admin',
    model: 'dashboards',
    onSuccess: res => setData(res.data),
  });

  useEffect(() => {
    getData();
  }, []);

  useInterval(() => {
    getData();
  }, 1000 * 10);

  return (
    <div className="admin-dashboard-live admin-dashboard-panel">
      <div className="columns">
        <div className="column">
          <h2>Care Manager Live View</h2>
        </div>
      </div>

      <div className="columns is-mobile admin-dashboard-live__care-manager-wrapper">
        <div className="column is-12-mobile is-6-tablet">
          <RectangleContainer color="alt-4" withLargePadding withLargeHeight>
            <div className="admin-dashboard-live__wrapper">
              <p className="admin-dashboard-live__title">
                Care Managers on Patient Call
              </p>
              <p className="admin-dashboard-live__result">{data.connections}</p>
              <div className="admin-dashboard-live__view">
                <Button
                  color="primary"
                  style="flat"
                  onClick={() => onViewClick('call_status', 'active')}>
                  View
                </Button>
              </div>
            </div>
          </RectangleContainer>
        </div>

        <div className="column is-12-mobile is-6-tablet">
          <RectangleContainer color="gold" withLargePadding withLargeHeight>
            <div className="admin-dashboard-live__wrapper">
              <p className="admin-dashboard-live__title">
                Care Manager Available for Patient Call
              </p>
              <p className="admin-dashboard-live__result">
                {data.care_managers_available}
              </p>
              <div className="admin-dashboard-live__view">
                <Button
                  color="primary"
                  style="flat"
                  onClick={() => onViewClick('call_status', 'inactive')}>
                  View
                </Button>
              </div>
            </div>
          </RectangleContainer>
        </div>
      </div>

      <div className="columns is-mobile admin-dashboard-live__care-manager-wrapper">
        <div className="column is-12-mobile is-4-tablet">
          <RectangleContainer withLargePadding>
            <div className="admin-dashboard-live__wrapper">
              <p className="admin-dashboard-live__title">
                Calls Scheduled Today
              </p>
              <p className="admin-dashboard-live__result">
                {data.todays_calls}
              </p>
            </div>
          </RectangleContainer>
        </div>

        <div className="column is-12-mobile is-4-tablet">
          <RectangleContainer withLargePadding>
            <div className="admin-dashboard-live__wrapper">
              <p className="admin-dashboard-live__title">
                Calls Completed Today
              </p>
              <p className="admin-dashboard-live__result">
                {data.complete_calls}
              </p>
            </div>
          </RectangleContainer>
        </div>

        <div className="column is-12-mobile is-4-tablet">
          <RectangleContainer withLargePadding>
            <div className="admin-dashboard-live__wrapper">
              <p className="admin-dashboard-live__title">
                Time with Patients this Month
              </p>
              <p className="admin-dashboard-live__result">
                {formatSecondsToHours(data.monthly_time)}
              </p>
            </div>
          </RectangleContainer>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardLiveView;
