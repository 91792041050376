import React from 'react';

import FilterHeader from '@/components/filters/FilterHeader';
import InputIcon from '@/components/form/InputIcon';

export interface FormState {
  search: string;
  per: string;
}

interface Props {
  form: FormState;
  onChangeForm: (field: string, value: string) => void;
  onChangeSearch: (value: string) => void;
  onClearFilters: () => void;
  onStartSearch: () => void;
  searchQuery: string;
}

const SearchHeader = (props: Props): JSX.Element => {
  const {
    form,
    onChangeForm,
    onChangeSearch,
    onClearFilters,
    onStartSearch,
    searchQuery,
  } = props;

  return (
    <>
      <div className="columns is-mobile">
        <div className="column is-6">
          <h2 className="patients-index__header">Providers</h2>
        </div>

        <div className="column is-6">
          <InputIcon
            onChange={value => onChangeSearch(value)}
            onClick={onStartSearch}
            onPressEnter={onStartSearch}
            placeholder="Search"
            value={searchQuery}
          />
        </div>
      </div>

      <FilterHeader
        includePerPage
        onClearFilters={onClearFilters}
        onChangeQuery={onChangeForm}
        query={form}
        perPageDesktopClass="is-2"
        perPageTabletClass="is-3"
        shouldAddColumnClasses
      />
    </>
  );
};

export default SearchHeader;
