import { UUID } from '../generic';
import { NotificationConfiguration } from './notification_configuration';
import { Practice } from './practice';
import {
  Questionnaire,
  VirtualAppointmentQuestionnaire,
} from './questionnaire';
import { Specialist } from './specialist';

export interface AdminUser {
  id: UUID;
  first_name: string;
  invitation_accepted_at: string;
  last_name: string;
  email: string;
  notification_configuration: NotificationConfiguration;
  practice_id: UUID;
  questionnaires?: Questionnaire[];
  specialist?: Specialist;
  practice?: Practice;
  virtual_appointment_questionnaires?: VirtualAppointmentQuestionnaire[];
}

export const formatSpecialistProfile = (result: AdminUser) => ({
  label: result.specialist.name,
  value: result.id,
});

export const adminUserRoleOptions = [
  { label: 'Provider', value: 'provider' },
  { label: 'Practice Admin', value: 'practice admin' },
];
