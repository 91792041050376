import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { buildPath, Routes } from '@/utils/routeUtils';

import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';

const SpecialistsIndex = () => {

  const [perPage, setPerPage] = useState<number>(24);
  const [queryOptions, setQueryOptions] = useState<object>({
    search: '',
  });

  const handlePerPageChange = (value: number) => {
    setPerPage(value);
  };

  const SEARCH_PATH = Routes.api.specialists;

  const tableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: {
      ...queryOptions,
      per: perPage,
    },
    columns: [
      {
        header: 'Name',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'name',
        className: 'table__cell-highlight',
        width: 2,
      },
      {
        header: 'Specialty',
        isSortable: true,
        isVisibleMobile: true,
        attribute: 'specialty',
        className: 'table__cell-highlight',
        width: 2,
      },
    ],
    rows: {
      editPath: buildPath(Routes.specialistsEdit),
    },
  };

  return (
    <Panel>
      <Container>
        <Table config={tableConfig} />
      </Container>
    </Panel>
  );
};

export default SpecialistsIndex;
