import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import RectangleContainer from '@/components/container/RectangleContainer';
import { Routes } from '@/utils/routeUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { fullName } from '@/utils/userUtils';

interface Props {
  calls: any;
  formattedDate: string;
}

const DashboardCalls = (props: Props) => {
  const { calls, formattedDate } = props;

  const callData = calls.data;

  const scheduledToday = callData.filter(
    entry => moment(entry.attributes.start_at).format('L') === formattedDate,
  );

  return (
    <div className="dashboard-panel">
      <div className="dashboard-calls__header-wrapper">
        <h2 className="dashboard-calls__header">Today's Calls</h2>
        <Link className="dashboard-calls__link" to={Routes.calls}>
          View Call Schedule
        </Link>
      </div>

      <div className="dashboard-calls__date-wrapper">
        <p className="dashboard-calls__date">{moment().format('ll')}</p>
      </div>

      {scheduledToday &&
        scheduledToday.map(call => {
          return (
            <div className="dashboard-calls__schedule" key={call.id}>
              <RectangleContainer>
                <div className="dashboard-calls__status-wrapper">
                  <p>{`${moment(call.attributes.start_at).format(
                    'l',
                  )} at ${moment(call.attributes.start_at).format(
                    'h:mm a',
                  )}`}</p>

                  <span className="dashboard-calls__schedule_text">
                    {`Call Status: ${titleizeSnakeCase(
                      call.attributes.status,
                    )}`}
                  </span>
                </div>
                <p>{`Patient: ${fullName(call.attributes.patient)}`}</p>
              </RectangleContainer>
            </div>
          );
        })}
    </div>
  );
};

export default DashboardCalls;
