import React, { useEffect, useState } from 'react';

import Preview from '@/components/pdf/Preview';
import { selectBrandLogo } from '@/selectors/brand';
import { Specialist } from '@/types/specialist';
import { formatAddressSingleLine } from '@/utils/addressUtils';
import useGetRequest from '@/utils/api/useGetRequest';
import { formatShortDate } from '@/utils/dateUtils';
import { formatPhoneNumber } from '@/utils/phoneUtils';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  enrollmentDate: string;
  date: string;
  name: string;
  patient: string;
  relation: string;
  shouldDisplayRelation: boolean;
  specialist: Specialist;
}

const AgreementPreview = (props: Props) => {
  const {
    date,
    enrollmentDate,
    name,
    patient,
    relation,
    shouldDisplayRelation,
    specialist,
  } = props;

  const [address, setAddress] = useState({});

  const dispatch = useDispatch();

  const LOGO = useSelector(selectBrandLogo);

  const [getSpecialist] = useGetRequest({
    dispatch,
    url: `api/v1/specialists/${specialist.id}`,
    model: 'specialist',
    onSuccess: (res: any) => {
      const addressId = res?.data.relationships?.address?.data.id;

      if (addressId) {
        setAddress(res.included.find(resource => resource.id === addressId));
      }
    },
  });

  useEffect(() => {
    if (specialist.id) {
      getSpecialist();
    }
  }, [specialist]);

  const renderRelationField = () => {
    if (!shouldDisplayRelation) {
      return null;
    }

    return (
      <tr>
        <td>Relation : {relation}</td>
        <td />
      </tr>
    );
  };

  return (
    <div className="columns">
      <div className="column">
        <Preview>
          <div className="pdf-preview">
            <div className="pdf-preview__logo">
              <img src={LOGO} />
            </div>

            <h1>CONSENT AGREEMENT</h1>
            <h2>FOR PROVISION OF CHRONIC CARE MANAGEMENT</h2>

            <p>
              As a patient with two or more chronic conditions, you may benefit
              from Chronic Care Management (CCM) offered to all Medicare
              patients. Medicare is now offering CCM to help you manage your
              conditions. Our goal is to provide you with the best quality care
              possible, to help you stay away from hospitals, and to ensure
              minimum costs and inconvenience to you due to unnecessary doctor
              and emergency room visits, or hospitals admissions.
            </p>

            <p>
              By signing this Agreement, you consent to HealthClix (referred to
              as “Provider”), providing Chronic Care Management services
              (referred to as “CCM Services”) to you as more fully described
              below.
            </p>

            <h3>Beneficiary Acknowledgment and Authorization.</h3>
            <p className="no-margin">
              By signing this Agreement, you agree to the following:
            </p>
            <ul>
              <li>
                - You consent to the Provider providing CCM Services to you.
              </li>
              <li>
                - You acknowledge that only one practitioner can furnish CCM
                Services to you during a calendar month.
              </li>
              <li>
                - You understand that cost-sharing will apply to CCM Services,
                so you may be responsible for a copay.
              </li>
            </ul>

            <h3>Beneficiary Rights.</h3>
            <p className="no-margin">
              You have the following rights with respect to CCM Services:
            </p>
            <ul>
              <li>
                - The Provider will provide you with a written or electronic
                copy of your care plan.
              </li>
              <li>
                - You have the right to stop CCM Services at any time by
                revoking this Agreement effective at the end of the then-current
                month.
              </li>
              <li>
                - You may revoke this agreement verbally (by calling{' '}
                <u>312 846-5100</u>) or in writing (to{' '}
                <u>111 N Wabash Ave, Suite 1300, Chicago, Illinois, 60602</u>).
                Upon receipt of your revocation, the Provider will give you
                written confirmation (including the effective date) of
                revocation.
              </li>
            </ul>

            <p>
              A care manager who is familiar with your chronic conditions will
              be assigned to you. You care manager will soon contact you to
              discuss the next steps. Your care managers will be available to
              you 24 /7 to assist you with your care plan, ensuring that you are
              achieving your goals.
            </p>

            <table>
              <tbody>
                <tr>
                  <td>Patient Name : {patient}</td>
                  <td>Provider Name : {specialist?.attributes?.name}</td>
                </tr>

                <tr>
                  <td>Signature :</td>
                  <td>
                    {address
                      ? `Provider Address : ${formatAddressSingleLine(address)}`
                      : null}
                  </td>
                </tr>

                <tr>
                  <td>Date : {formatShortDate(date)}</td>
                  <td>
                    {`Provider Phone : ${formatPhoneNumber(
                      specialist?.attributes?.phone_number,
                    )}`}
                  </td>
                </tr>

                {renderRelationField()}

                <tr>
                  <td>Print Name : {name}</td>
                  <td>Enrollment Date : {formatShortDate(enrollmentDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Preview>
      </div>
    </div>
  );
};

export default AgreementPreview;
