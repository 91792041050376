import classNames from 'classnames';
import React from 'react';

interface Props {
  isChecked?: boolean;
  isDisabled?: boolean;
  onClick?: VoidFunction;
}

const Checkbox = (props: Props) => {
  const { isChecked, isDisabled, onClick } = props;

  const CHECKBOX_ICON = require('assets/images/icons/check.svg');

  return (
    <div
      onClick={onClick}
      className={classNames('form-checkbox', {
        'is-checked': isChecked,
        'is-disabled': isDisabled,
      })}>
      {isChecked && <img src={CHECKBOX_ICON} />}
    </div>
  );
};

export default Checkbox;
