const RESOURCES = {
  alert_types: 'Alert Types',
  allergy_options: 'Allergies',
  barriers: 'Barriers',
  billing_codes: 'Billing Codes',
  caller_ids: 'Caller IDs',
  ccm_complexities: 'CCM Complexities',
  condition_groups: 'Condition Groups',
  conditions: 'Conditions',
  cpt_codes: 'CPT Codes',
  device_categories: 'Device Categories',
  device_makes: 'Device Makes',
  device_models: 'Device Models',
  device_names: 'Device Names',
  device_types: 'Device Types',
  goals: 'Goals',
  icd_codes: 'ICD Codes',
  immunization_names: 'Immunizations',
  lab_tests: 'Lab Tests',
  manual_entry_reasons: 'Manual Entry Reasons',
  medication_names: 'Medications',
  metrics: 'Metrics',
  outcomes: 'Expected Outcomes',
  screening_types: 'Screening Types',
  specialty_types: 'Specialty Types',
  symptoms: 'Symptoms',
};

export const PortalSideBarLinks = Object.keys(RESOURCES);

export const getResourceName = (resource: string) => {
  return RESOURCES[resource];
};
