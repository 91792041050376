import classNames from 'classnames';
import React from 'react';

type Color = 'primary' | 'secondary';
type Rotation = 0 | 90 | 180 | 270;
export type Size = 'small' | 'normal' | 'large' | 'tiny';

export type IconName =
  | 'asterisk'
  | 'caretDown'
  | 'caretDownFilled'
  | 'caretLeft'
  | 'caretRight'
  | 'caretWhite'
  | 'close'
  | 'menu'
  | 'pound'
  | 'search'
  | 'searchBlack'
  | 'high'
  | 'low'
  | 'medium'
  | 'phoneWhite'
  | 'avatar-blue'
  | 'avatar-white'
  | 'chat-blue'
  | 'chat-white'
  | 'mic-disable'
  | 'mic-enable'
  | 'paragraph-blue'
  | 'paragraph-white'
  | 'question-blue'
  | 'question-white'
  | 'screen-disable'
  | 'screen-enable';

interface Props {
  color?: Color;
  icon: IconName;
  rotation?: Rotation;
  size?: Size;
}

export type IconProps = Omit<Props, 'source'>;

const getIconSource = (icon: IconName) => {
  if (iconSources[icon]) {
    return iconSources[icon];
  }

  return require(`assets/images/icons/${icon}.svg`);
};

const iconSources = {
  asterisk: require('assets/images/icons/asterisk.svg'),
  caretDown: require('assets/images/icons/caret-down.svg'),
  caretDownFilled: require('assets/images/icons/caret-down-filled.svg'),
  caretLeft: require('assets/images/icons/caret-left.svg'),
  caretRight: require('assets/images/icons/caret-right.svg'),
  caretWhite: require('assets/images/icons/caret-white.svg'),
  close: require('assets/images/icons/close.svg'),
  menu: require('assets/images/icons/menu.svg'),
  pound: require('assets/images/icons/pound.svg'),
  search: require('assets/images/icons/search.svg'),
  searchBlack: require('assets/images/icons/search-black.svg'),
  high: require('assets/images/icons/high.svg'),
  low: require('assets/images/icons/low.svg'),
  medium: require('assets/images/icons/medium.svg'),
  phoneWhite: require('assets/images/icons/phone-white.svg'),
};

const Icon = (props: Props) => {
  const { icon, rotation, size } = props;

  const iconStyles = classNames('icon__image', {
    'is-rotated-90': rotation === 90,
    'is-rotated-180': rotation === 180,
    'is-rotated-270': rotation === 270,
    'is-large': size === 'large',
  });

  return (
    <div
      className={classNames('icon', {
        'is-small': size === 'small',
        'is-tiny': size === 'tiny',
      })}>
      <img className={iconStyles} src={getIconSource(icon)} />
    </div>
  );
};

export default Icon;
