import { BrandState, create } from '@/types/brand';
import { BrandAction, BrandActions } from '../actions/brand';

export default function reducer(
  state: BrandState = create(),
  action: BrandAction,
): BrandState {
  const { type, payload } = action;

  switch (type) {
    case BrandActions.SET_CURRENT_BRAND:
      return {
        ...state,
        brand: payload.brand,
      };

    default:
      return state;
  }
}
