import { createSelector } from 'reselect';

import { ApiState } from '@/types/api';
import { AppState } from '@/types/app';
import { IcdCode } from '@/types/icdCode';
import { ManualEntryReason } from '@/types/manualEntryReason';

export const getApiState = (state: AppState): ApiState => state.data;

export const getServices = createSelector(getApiState, state => state.services);

export const getCcmServiceId = createSelector(getServices, state => {
  const service = state.data.find(n => n.attributes.name === 'CCM');
  return service.id;
});

export const getRpmServiceId = createSelector(getServices, state => {
  const service = state.data.find(n => n.attributes.name === 'RPM');
  return service.id;
});

export const getBhiServiceId = createSelector(getServices, state => {
  const service = state.data.find(n => n.attributes.name === 'BHI');
  return service.id;
});

export const getConditions = createSelector(
  getApiState,
  state => state.conditions.data,
);

export const getConditionGroups = createSelector(
  getApiState,
  state => state.condition_groups.data,
);

export const getCcmConditionGroups = createSelector(
  [getConditionGroups, getCcmServiceId],
  (groups, serviceId) =>
    groups.filter(g => g.attributes.service_id === serviceId),
);

export const getBhiConditionGroups = createSelector(
  [getConditionGroups, getBhiServiceId],
  (groups, serviceId) =>
    groups.filter(g => g.attributes.service_id === serviceId),
);

export const getConditionGroupOptions = createSelector(
  getConditionGroups,
  state => {
    return state.map(option => ({
      label: option.attributes.name,
      value: option.id,
    }));
  },
);

export const getAdditionalConditions = createSelector(
  [getConditionGroups, getConditions],
  (groups, conditions) => {
    const additionalGroup = groups.find(
      group => group.attributes.condition_group_role === 'additional',
    );

    if (!additionalGroup) {
      return [];
    }

    return conditions.filter(
      condition =>
        condition.attributes.condition_group.id === additionalGroup.id,
    );
  },
);

export const getGeneralConditionId = createSelector(getConditions, state => {
  return state.find(
    condition => condition.attributes.condition_role === 'general',
  ).id;
});

export const getOtherConditionId = createSelector(getConditions, state => {
  return state.find(
    condition => condition.attributes.condition_role === 'other',
  ).id;
});

export const getCcmConditionOptions = createSelector(
  [getCcmConditionGroups, getConditions],
  (groups, conditions) => {
    if (!groups.length || !conditions.length) {
      return [];
    }

    const filteredGroups = groups.filter(
      group => group.attributes.condition_select_status === 'visible',
    );

    return filteredGroups.map(group => {
      const groupOptions = conditions
        .filter(condition => {
          return condition.attributes.condition_group?.id === group?.id;
        })
        .map(condition => {
          return {
            label: condition.attributes.description,
            value: condition?.id,
          };
        });

      return {
        name: group.attributes.name,
        options: groupOptions,
      };
    });
  },
);

export const getBhiConditionOptions = createSelector(
  [getBhiConditionGroups, getConditions],
  (groups, conditions) => {
    if (!groups.length || !conditions.length) {
      return [];
    }

    const filteredGroups = groups.filter(
      group => group.attributes.condition_select_status === 'visible',
    );

    return filteredGroups.map(group => {
      const groupOptions = conditions
        .filter(condition => {
          return condition.attributes.condition_group.id === group.id;
        })
        .map(condition => {
          return {
            label: condition.attributes.description,
            value: condition.id,
          };
        });

      return {
        name: group.attributes.name,
        options: groupOptions,
      };
    });
  },
);

export const getBarriers = createSelector(getApiState, state => state.barriers);

export const getSupportOptions = createSelector(
  getApiState,
  state => state.support_options,
);

export const getCcmComplexities = createSelector(
  getApiState,
  state => state.ccm_complexities,
);

export const getCcmComplexityOptions = createSelector(
  getCcmComplexities,
  state => {
    return state.data.map(option => ({
      label: option.attributes.name,
      value: option.id,
    }));
  },
);

export const getGoals = createSelector(getApiState, state => state.goals);

export const getMetrics = createSelector(getApiState, state => state.metrics);

export const getOutcomes = createSelector(getApiState, state => state.outcomes);

export const getSymptoms = createSelector(getApiState, state => state.symptoms);

export const getIcdCodes = createSelector(
  getApiState,
  state => state.icd_codes.data,
);

export const getIcdCodeOptions = createSelector(getIcdCodes, state => {
  return state.map((code: IcdCode) => {
    return {
      label: code.attributes.code,
      value: code.attributes.code,
    };
  });
});

export const getManualEntryReasons = createSelector(
  getApiState,
  state => state.manual_entry_reasons.data,
);

export const getManualEntryReasonOptions = createSelector(
  getManualEntryReasons,
  state => {
    return state.map((reason: ManualEntryReason) => {
      return {
        label: reason.attributes.reason,
        value: reason.attributes.reason,
      };
    });
  },
);

export const getScreeningTypes = createSelector(
  getApiState,
  state => state.screening_types.data,
);

export const getImmunizationNames = createSelector(
  getApiState,
  state => state.immunization_names,
);

export const getImmunizationNamesOptions = createSelector(
  getImmunizationNames,
  state => {
    if (!state.data) {
      return [];
    }

    return state.data.map(immunization => ({
      label: immunization.attributes.name,
      value: immunization.attributes.name,
    }));
  },
);

export const getProtocols = createSelector(
  getApiState,
  state => state.protocols,
);

export const getProtocolsOptions = createSelector(getProtocols, state => {
  return state.data.map(option => ({
    label: option.attributes.name,
    value: option.id,
  }));
});

export const getServiceOptions = createSelector(getServices, state => {
  return state.data.map(option => ({
    label: option.attributes.name,
    value: option.id,
  }));
});

export const selectBillingCodes = createSelector(
  getApiState,
  state => state.billing_codes,
);

export const selectCcmBillingCodeOptions = createSelector(
  selectBillingCodes,
  state => {
    return state.data
      .filter(code => code.attributes.service.name === 'CCM')
      .map(option => ({
        label: `${option.attributes.code} | ${option.attributes.name}`,
        value: option.id,
      }));
  },
);

export const selectRpmBillingCodeOptions = createSelector(
  selectBillingCodes,
  state => {
    return state.data
      .filter(code => code.attributes.service.name === 'RPM')
      .map(option => ({
        label: `${option.attributes.code} | ${option.attributes.name}`,
        value: option.id,
      }));
  },
);

export const getDeviceCategories = createSelector(
  getApiState,
  state => state.device_categories.data,
);

export const getDeviceCategoryOptions = createSelector(
  getDeviceCategories,
  state => {
    return state.map(option => ({
      label: option.attributes.name,
      value: option.id,
    }));
  },
);

export const getDeviceTypes = createSelector(
  getApiState,
  state => state.device_types.data,
);

export const getDeviceTypeOptions = createSelector(getDeviceTypes, state => {
  return state.map(option => ({
    label: option.attributes.name,
    value: option.id,
  }));
});

export const getDeviceMakes = createSelector(
  getApiState,
  state => state.device_makes.data,
);

export const getDeviceMakeOptions = createSelector(getDeviceMakes, state => {
  return state.map(option => ({
    label: option.attributes.name,
    value: option.id,
  }));
});

export const getDeviceNames = createSelector(
  getApiState,
  state => state.device_names.data,
);

export const getDeviceNameOptions = createSelector(getDeviceNames, state => {
  return state.map(option => ({
    label: option.attributes.name,
    value: option.id,
  }));
});

export const getCallerIds = createSelector(
  getApiState,
  state => state.caller_ids.data,
);

export const getCallerIdOptions = createSelector(getCallerIds, state => {
  return state.map(option => ({
    label: option.attributes.phone_number,
    value: option.id,
  }));
});

export const selectTrackingQuestionSets = createSelector(
  getApiState,
  state => state.tracking_question_sets,
);

export const selectAnswerSets = createSelector(
  getApiState,
  state => state.answer_sets,
);
