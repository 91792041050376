import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import useGetRequest from '@/utils/api/useGetRequest';
import usePutRequest from '@/utils/api/usePutRequest';
import { buildPath, Routes } from '@/utils/routeUtils';
import { parseErrorData } from '@/utils/apiUtils';
import { validate } from '@/utils/form';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';

import { selectPracticeDeviceModels } from '@/selectors/patients';

import { SelectGroup } from '@/components/form/SelectGroup';
import { InputGroup } from '@/components/form/InputGroup';
import Section from '@/components/form/Section';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import Button from '@/components/button/Button';
import BackLink from '@/components/button/BackLink';
import ActivityIndicator from '@/components/activity/ActivityIndicator';

import { Validations } from '@/types/form';
import { Response } from '@/types/response';
import { IdParams } from '@/types/params';
import { DeviceCategory } from '@/types/device_category';
import { DeviceType } from '@/types/device_type';
import { DeviceMake } from '@/types/device_make';
import { DeviceName } from '@/types/device_name';
import { DeviceModel } from '@/types/device_model';

const DevicesEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<IdParams>();

  const practiceDevices = useSelector(selectPracticeDeviceModels);
  const hasPracticeDevices = !!practiceDevices.length;

  const FORM_STATE = {
    device_category_id: '',
    device_model_id: '',
    device_make_id: '',
    device_name_id: '',
    device_type_id: '',
    serial_number: '',
    box_number: '',
    sim_number:'',
    shipping_invoice: '',
  };

  const [device, setDevice] = useState(null);
  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});
  const [deviceCategories, setDeviceCategories] = useState<DeviceCategory[]>(
    [],
  );
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [deviceMakes, setDeviceMakes] = useState<DeviceMake[]>([]);
  const [deviceNames, setDeviceNames] = useState<DeviceName[]>([]);
  const [deviceModels, setDeviceModels] = useState<DeviceModel[]>([]);

  const backPath = buildPath(Routes.devices);

  const [getDevice, isLoadingDevice] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    url: buildPath(Routes.api2.device, { id: id }),
    model: 'devices',
    onSuccess: (response: Response) => {
      const { data } = response;
      setDevice(data);
    },
    onFailure: (response: Response) => {
      handleFailure(response);
    },
  });

  const [getDeviceModels, isLoadingDeviceModels] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    url: buildPath(Routes.api.deviceModels),
    model: 'device_models',
    onSuccess: (response: Response) => {
      const { data } = response;
      setDeviceModels([...data]);
    },
    onFailure: (response: Response) => {
      handleFailure(response);
    },
  });

  const [getDeviceNames, isLoadingDeviceNames] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    url: buildPath(Routes.api.deviceNames),
    model: 'device_names',
    onSuccess: (response: Response) => {
      const { data } = response;
      setDeviceNames([...data]);
    },
    onFailure: (response: Response) => {
      handleFailure(response);
    },
  });

  const [getDeviceMakes, isLoadingDeviceMakes] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    url: buildPath(Routes.api.deviceMakes),
    model: 'device_makes',
    onSuccess: (response: Response) => {
      const { data } = response;
      setDeviceMakes([...data]);
    },
    onFailure: (response: Response) => {
      handleFailure(response);
    },
  });

  const [getDeviceTypes, isLoadingDeviceTypes] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    url: buildPath(Routes.api.deviceTypes),
    model: 'device_types',
    onSuccess: (response: Response) => {
      const { data } = response;
      setDeviceTypes([...data]);
    },
    onFailure: (response: Response) => {
      handleFailure(response);
    },
  });

  const [getDeviceCategories, isLoadingDeviceCategories] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    url: buildPath(Routes.api.deviceCategories),
    model: 'device_categories',
    onSuccess: (response: Response) => {
      const { data } = response;
      setDeviceCategories([...data]);
    },
    onFailure: (response: Response) => {
      handleFailure(response);
    },
  });

  const rules = (() => {
    if (hasPracticeDevices) {
      return {
        device_model_id: { required: true },
        issue_date: { required: true },
        serial_number: { required: true },
      };
    }

    return {
      device_category_id: { required: true },
      device_make_id: { required: true },
      device_model_id: { required: true },
      device_name_id: { required: true },
      device_type_id: { required: true },
      serial_number: { required: true },
    };
  })();

  const formatBody = () => {
    const { ...formFields } = form;

    return {
      device: {
        ...formFields,
      },
    };
  };

  const handleSuccess = () => {
    dispatch(flashSuccess('Device edited'));

    history.push(backPath);
  };

  const handleFailure = (res: any) => {
    const errorMessage = parseErrorData(res);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const [submitForm, isSubmitting] = usePutRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: buildPath(Routes.api2.device, { id: id }),
  });

  useEffect(() => {
    getDeviceModels();
    getDeviceNames();
    getDeviceMakes();
    getDeviceTypes();
    getDeviceCategories();
    getDevice();
  }, []);

  useEffect(() => {
    if (device) {
      const formattedDevice = {
        ...device.attributes,
      };
      setForm(formattedDevice);
    }
  }, [device]);

  useEffect(() => {
    updateFieldUtil(deviceModels, 'device_model_id', 'device_name_id');
  }, [form.device_model_id]);

  useEffect(() => {
    updateFieldUtil(deviceNames, 'device_name_id', 'device_make_id');
  }, [form.device_name_id]);

  useEffect(() => {
    updateFieldUtil(deviceMakes, 'device_make_id', 'device_type_id');
  }, [form.device_make_id]);

  useEffect(() => {
    updateFieldUtil(deviceTypes, 'device_type_id', 'device_category_id');
  }, [form.device_type_id]);

  const formatDeviceEntry = (entry: any) => ({
    label: entry.attributes.name,
    value: entry.id,
  });

  const allDeviceModelOptions = useMemo(() => {
    return deviceModels.map(formatDeviceEntry);
  }, [deviceModels]);

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const updateFieldUtil = (deviceOptions, optionId, dependentOptionId) => {
    const option = deviceOptions.find(option => option.id === form[optionId]);
    updateField(dependentOptionId, option?.attributes[dependentOptionId]);
  };

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);

    if (isValid) {
      setValidations({});
    } else {
      return setValidations(validationMessages);
    }

    submitForm();
  };

  const renderDevice = () => {
    if (!device || isLoadingDevice) {
      return <ActivityIndicator />;
    } else {
      return (
        <>
          <div className="columns">
            <div className="column is-4">
              <SelectGroup
                label="Device Model"
                onChange={value => updateField('device_model_id', value)}
                options={allDeviceModelOptions}
                validationMessage={validations.device_model_id}
                value={form.device_model_id}
              />
            </div>

            <div className="column is-4">
              <InputGroup
                label="Serial Number"
                onChange={value => updateField('serial_number', value)}
                placeholder="Enter Serial Number"
                validationMessage={validations.serial_number}
                value={form.serial_number}
              />
            </div>

            <div className=" column is-4">
              <InputGroup
                label="SimCard Number"
                onChange={value => updateField('sim_number', value)}
                placeholder="Enter Sim Number"
                validationMessage={validations.sim_number}
                value={form.sim_number}
              />
            </div>
          </div>
          <div className="columns ">
          <div className="column is-4 ">
              <InputGroup
                label="Box Number"
                onChange={value => updateField('box_number', value)}
                placeholder="Enter Box Number"
                validationMessage={validations.box_number}
                value={form.box_number}
              />
            </div>

            <div className="column is-4 ">
              <InputGroup
                label="Shipping Invoice"
                onChange={value => updateField('shipping_invoice', value)}
                placeholder="Enter Shipping Invoice"
                validationMessage={validations.shipping_invoice}
                value={form.shipping_invoice}
              />
            </div>
            </div>
          <Button
            color="secondary"
            isSubmitting={isSubmitting}
            onClick={handleClickSubmit}>
            Save
          </Button>
        </>
      );
    }
  };

  return (
    <>
      <Container>
        <BackLink to={backPath}>Back to Devices</BackLink>
      </Container>
      <Constraint size="large">
        <Panel>
          <Section title="Edit Device">
            <Container>{renderDevice()}</Container>
          </Section>
        </Panel>
      </Constraint>
    </>
  );
};

export default DevicesEdit;
