import React, { useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { setCurrentCarePlan } from '@/actions/reducers/bhi';
import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import Button from '@/components/button/Button';
import EnrollmentActions from '@/contexts/enrollment/components/EnrollmentActions';
import UnsavedConfirmationModal from '@/contexts/modals/UnsavedConfirmationModal';
import { isEnrollmentPath } from '@/contexts/patients/utils/patientUtils';
import { getSelectedPatient } from '@/selectors/api';
import { CarePlan } from '@/types/care_plan';
import { Nullable, SideEffect } from '@/types/generic';
import { parseErrorData, parseResponseData } from '@/utils/apiUtils';
import { arraysMatch } from '@/utils/arrayUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from './Checkbox';

interface Group {
  name: string;
  options: Option[];
}

interface Option {
  label: string;
  value: string;
}

interface Props {
  carePlan: Nullable<CarePlan>;
  formatBody?: (options: any) => object;
  isSubmitDisabled?: boolean;
  onChange: SideEffect;
  onSuccess?: SideEffect;
  options: Group[];
  originalSelectedIds: string[];
  saveText?: string;
  selectedIds: any[];
  shouldHideGroupName?: boolean;
  type?: 'id' | 'entry';
}

const BhiChecklist = (props: Props) => {
  const {
    carePlan,
    formatBody,
    isSubmitDisabled,
    onChange,
    onSuccess,
    options,
    originalSelectedIds,
    saveText,
    selectedIds,
    shouldHideGroupName,
  } = props;

  const dispatch = useDispatch();

  const history = useHistory();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState<boolean>(
    false,
  );
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [navigationPath, setNavigationPath] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmittingBack, setIsSubmittingBack] = useState<boolean>(false);

  const patient = useSelector(getSelectedPatient);

  if (!options) {
    return null;
  }

  const isFormUpdated = !arraysMatch(selectedIds, originalSelectedIds);

  const body = formatBody(selectedIds);

  const isEnrollment = isEnrollmentPath();

  const defaultSaveText = isEnrollment ? 'Save and Next' : 'Save';

  const handleSuccess = (res: CarePlan) => {
    setIsSuccessful(true);
    dispatch(setCurrentCarePlan(res));

    if (navigationPath) {
      handleNavigate();
    } else if (!isEnrollment) {
      dispatch(flashSuccess('Patient updated'));
    } else if (onSuccess) {
      onSuccess();
    }
  };

  const handleSuccessAndBack = (res: CarePlan) => {
    setIsSuccessful(true);
    dispatch(setCurrentCarePlan(res));

    history.push(buildPath(Routes.patientsEnrollmentIndex, { id: patient.id }));
  };

  const saveForm = async () => {
    setIsSubmitting(true);

    let response = null;

    try {
      if (carePlan) {
        const url = `api/v1/care_plans/${carePlan.data.id}`;
        response = await Api.utility.patch(url, body);
      } else {
        const url = 'api/v1/care_plans';
        response = await Api.utility.post(url, body);
      }

      setIsSubmitting(false);
      handleSuccess(parseResponseData(response));
    } catch (err) {
      setIsSubmitting(false);
      dispatch(flashError(parseErrorData(err)));
    }
  };

  const saveFormAndBack = async () => {
    setIsSubmittingBack(true);

    let response = null;

    try {
      if (carePlan) {
        const url = `api/v1/care_plans/${carePlan.id}`;
        response = await Api.utility.patch(url, body);
      } else {
        const url = 'api/v1/care_plans';
        response = await Api.utility.post(url, body);
      }

      setIsSubmittingBack(false);
      handleSuccessAndBack(parseResponseData(response));
    } catch (err) {
      setIsSubmittingBack(false);
      dispatch(flashError(parseErrorData(err)));
    }
  };

  const handleCloseModal = () => {
    setNavigationPath(null);
    setIsConfirmationVisible(false);
  };

  const handleNavigate = () => {
    history.push(navigationPath);
  };

  const handleSaveConfirm = () => {
    saveForm();
  };

  const handlePromptWarning = location => {
    if (isSuccessful || !isFormUpdated || isConfirmationVisible) {
      return true;
    }

    setIsConfirmationVisible(true);
    setNavigationPath(location.pathname);

    return false;
  };

  const handleToggleInput = (value: string) => {
    if (selectedIds.includes(value)) {
      onChange(selectedIds.filter(id => id !== value));
    } else {
      onChange([...selectedIds, value]);
    }
  };

  const renderGroup = (group: Group) => {
    return (
      <div className="column is-6" key={group.name}>
        <div className="form-checklist__group">
          {shouldHideGroupName || <h5>{group.name}</h5>}
          <div className="form-checkslist__group-options">
            {group.options.map(option => {
              const isChecked = selectedIds.includes(option.value);

              return (
                <div className="form-checklist__option" key={option.value}>
                  <label
                    className="form-checkbox-wrapper"
                    onClick={() => handleToggleInput(option.value)}>
                    <Checkbox isChecked={isChecked} />
                    <span>{option.label}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="form-checklist">
        <div className="columns is-multiline">
          {options.map((group: Group) => {
            return renderGroup(group);
          })}
        </div>
      </div>

      <EnrollmentActions>
        <Button
          color="secondary"
          isDisabled={isSubmitDisabled}
          isSubmitting={isSubmitting || isSubmittingBack}
          onClick={saveForm}>
          {saveText || defaultSaveText}
        </Button>

        {isEnrollment && (
          <Button
            color="white"
            isDisabled={isSubmitDisabled}
            isSubmitting={isSubmitting || isSubmittingBack}
            onClick={saveFormAndBack}>
            Save And Back To Sections List
          </Button>
        )}
      </EnrollmentActions>

      <Prompt message={handlePromptWarning} />

      <UnsavedConfirmationModal
        isVisible={isConfirmationVisible}
        onClose={handleCloseModal}
        onNavigate={handleNavigate}
        onSave={handleSaveConfirm}
      />
    </>
  );
};

export default BhiChecklist;
