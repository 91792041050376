import { Nullable, UUID } from '@/types/generic';
import { DeviceReading, DeviceReadingLabel } from '@/types/v2/device_reading';
import { formatTime } from '@/utils/dateUtils';
import Modeler from '@/utils/modeler/modeler';

export interface HighAlertPatient {
  end_date: string;
  patient_id: UUID;
  patient_name: string;
  reading_type: DeviceReadingLabel;
  start_date: string;
  total_readings: number;
}

export interface CareManagerResponse {
  id: UUID;
  first_name: string;
  last_name: string;
  patients: number;
  calls: number;
}

export interface AdminData {
  careManagerHighAlerts: CareManagerResponse[];
  careManagerMostCalls: CareManagerResponse[];
  careManagerNoReadings: CareManagerResponse[];
}

interface CareManagerData {
  dailyHighAlerts: {
    bloodGlucose: DeviceReading;
    bloodOxygen: DeviceReading;
    bloodPressure: DeviceReading;
  };
  highAlertPatients: HighAlertPatient[];
  latestReadings: {
    bloodGlucose: DeviceReading[];
    bloodOxygen: DeviceReading[];
    bloodPressure: DeviceReading[];
  };
}

export interface RpmState {
  adminData: AdminData;
  careManagerData: CareManagerData;
  dashboardLastLoadedAt: Nullable<string>;
  hasRpmPatients: boolean;
  isDashboardCollapsed: boolean;
  isFetchingDashboard: boolean;
}

export interface AdminDashboardResponseData {
  care_manager_high_alerts: any[];
  care_manager_most_calls: any[];
  care_manager_no_readings: any[];
}

export interface CareManagerDashboardResponseData {
  highest_daily_blood_glucose_alert: any;
  highest_daily_blood_oxygen_alert: any;
  highest_daily_blood_pressure_alert: any;
  latest_blood_glucose_readings: any[];
  latest_blood_oxygen_readings: any[];
  latest_blood_pressure_readings: any[];
  high_alert_patients: HighAlertPatient[];
  patients: Nullable<boolean>;
}

export const create = (data: Partial<RpmState> = {}): RpmState => ({
  adminData: {
    careManagerHighAlerts: [],
    careManagerMostCalls: [],
    careManagerNoReadings: [],
  },
  careManagerData: {
    latestReadings: {
      bloodGlucose: [],
      bloodOxygen: [],
      bloodPressure: [],
    },
    dailyHighAlerts: {
      bloodGlucose: null,
      bloodOxygen: null,
      bloodPressure: null,
    },
    highAlertPatients: [],
  },
  hasRpmPatients: null,
  dashboardLastLoadedAt: null,
  isDashboardCollapsed: false,
  isFetchingDashboard: false,
  ...data,
});

export const reduceAdminDashboardSuccess = (
  state: RpmState,
  payload: AdminDashboardResponseData,
): RpmState => ({
  ...state,
  dashboardLastLoadedAt: formatTime(),
  isFetchingDashboard: false,
  adminData: {
    careManagerHighAlerts: payload.care_manager_high_alerts,
    careManagerMostCalls: payload.care_manager_most_calls,
    careManagerNoReadings: payload.care_manager_no_readings,
  },
});

export const reduceCareManagerDashboardSuccess = (
  state: RpmState,
  payload: CareManagerDashboardResponseData,
): RpmState => ({
  ...state,
  dashboardLastLoadedAt: formatTime(),
  hasRpmPatients: payload.patients,
  isFetchingDashboard: false,
  careManagerData: {
    dailyHighAlerts: {
      bloodGlucose: new Modeler<DeviceReading>(
        payload.highest_daily_blood_glucose_alert,
      ).build(),
      bloodOxygen: new Modeler<DeviceReading>(
        payload.highest_daily_blood_oxygen_alert,
      ).build(),
      bloodPressure: new Modeler<DeviceReading>(
        payload.highest_daily_blood_pressure_alert,
      ).build(),
    },
    latestReadings: {
      bloodGlucose: new Modeler<DeviceReading[]>(
        payload.latest_blood_glucose_readings,
      ).build(),
      bloodOxygen: new Modeler<DeviceReading[]>(
        payload.latest_blood_oxygen_readings,
      ).build(),
      bloodPressure: new Modeler<DeviceReading[]>(
        payload.latest_blood_pressure_readings,
      ).build(),
    },
    highAlertPatients: payload.high_alert_patients,
  },
});
