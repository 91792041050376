import React, { MouseEvent, useState } from 'react';

import Checkbox from '@/components/form/Checkbox';
import { UUID } from '@/types/generic';
import { ContactStatus } from '@/types/patient';

type ContactStatusInt = 0 | 1;

interface Props {
  initialValue: ContactStatusInt;
  onToggleStatus: (id: UUID, value: ContactStatus) => void;
  patientId: UUID;
}

const intToStatus = (n: ContactStatusInt): ContactStatus => {
  return (['needs_contact', 'contacted'] as ContactStatus[])[n];
};

const ContactCheckbox = (props: Props) => {
  const { initialValue, onToggleStatus, patientId } = props;

  const [value, setValue] = useState<boolean>(!!initialValue);

  const handleClickCheckbox = (e: MouseEvent) => {
    e.stopPropagation();

    onToggleStatus(patientId, intToStatus(+!value as ContactStatusInt));
    setValue(!value);
  };

  return (
    <div className="patients-index__contact">
      <button onClick={handleClickCheckbox}>
        <Checkbox isChecked={value} />
      </button>
    </div>
  );
};

export default ContactCheckbox;
