import { Serialized, UUID } from '@/types/generic';
import { Option } from './form';

export type PermissibleType = 'HealthSystem' | 'Hospital' | 'Practice';

export interface PermissionAttributes {
  id?: UUID;
  level: UUID;
  permissible: any;
  permissible_id: UUID;
  permissible_type: PermissibleType;
  user_id: UUID;
}

export type Permission = Serialized<PermissionAttributes>;

export type Level = 'standard' | 'admin' | 'billing_admin' | 'super_admin';

export const permissionLevelOptions: Option[] = [
  { label: 'Care Manager', value: 'standard' },
  { label: 'Admin', value: 'admin' },
  { label: 'Billing Admin', value: 'billing_admin' },
  { label: 'Super Admin', value: 'super_admin' },
];

export const adminEditRoles: Level[] = ['standard', 'admin'];
export const superAdminEditRoles: Level[] = [
  'standard',
  'admin',
  'billing_admin',
  'super_admin',
];

/**
 * Checks if a user is allowed to add permissons of the provided level based on the user's own permission level
 * @param  {Level} userRole
 * @param  {Level} level
 * @returns boolean
 */

export const canUserEditRole = (userRole: Level, level: Level): boolean => {
  const list =
    userRole === 'super_admin' ? superAdminEditRoles : adminEditRoles;
  return list.includes(level);
};

/**
 * Creates a filtered list of user role options based on the permission level of the user
 * @param  {Level} userRole
 * @returns Option
 */

export const userPermissionLevelOptions = (userRole: Level): Option[] => {
  return permissionLevelOptions.filter(o =>
    canUserEditRole(userRole, o.value as Level),
  );
};

export const permissionLevels: Level[] = [
  'standard',
  'admin',
  'billing_admin',
  'super_admin',
];
