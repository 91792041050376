import React, { useEffect, useState } from 'react';

import Label from '@/components/form/Label';

import Constraint from '@/components/constraint/Constraint';

import Container from '@/components/container/Container';

import Panel from '@/components/panel/Panel';

import { useDispatch } from 'react-redux';

import Button from '@/components/button/Button';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import { buildRequestPath } from '@/utils/apiUtils';
import usePostRequest from '@/utils/api/usePostRequest';
import { Routes } from '@/utils/routeUtils';


interface Props {

deviceId: string;
deviceType: string;

}


const PushMessageFormModal = (props: Props) => {


    

const { deviceId } = props;

const { deviceType } = props;

const dispatch = useDispatch();

const [title, setTitle] = useState("");

const [description, setDescription] = useState("");


const [message, setMessage] = useState("");


let handleSubmit = async (e) => {

e.preventDefault();

    if(title === null || title.match(/^ *$/) !== null)
    {
        dispatch(flashError('All fields are mandatory to be fill'));
    }

    else if( description === null || description.match(/^ *$/) !== null)
    {
        dispatch(flashError('All fields are mandatory to be fill'));
    }
    else{
        submitForm();
    }
    
    
}
// body: JSON.stringify({





const handleSucces = () => {
    setTitle("");
    setDescription("");
    dispatch(flashSuccess('Message sent successfully'))
}

const handleFailure = () => {
    dispatch(flashError('Something went wrong'))
}

// const handleCarePlanSuccess = () => {
//     dispatch(setCurrentCarePlan(null));
//     handleEnrollSuccess();
//   };

const [submitForm, isSubmitting] = usePostRequest({
    body: {
        title: title,
        description: description,
        device_token: deviceId,
        device_type: deviceType,
    },
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSucces,
    url: Routes.api.patientsNotification,
  });


return (

<div className="App">

<Constraint size="extended">

<Container>

<div className="tasks-new">

<h1 className="tasks-new__header">Send Notification</h1>

<form >


<div className="form-input-group with-margin-bottom">

<Label>Title</Label>

<input

type="text"

value={title}

className="form-input"

onChange={(e) => setTitle(e.target.value)}

/>

</div>

<div className="form-input-group with-margin-bottom">

<Label>Description</Label>

<textarea

className="form-text-area with-no-resize"

value={description}

onChange={(e) => setDescription(e.target.value)}

/>

</div>

<Button color="secondary" style="filled" onClick={handleSubmit} >Send </Button>


<div className="message">{message ? <p>{message}</p> : null}</div>

</form>

</div>

</Container>

</Constraint>

</div>

);

};


export default PushMessageFormModal;

