import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import { buildPath, Routes } from '@/utils/routeUtils';
import Api from '@/api/Api';
import Modeler from '@/utils/modeler/modeler';
import { getSelectedPatient } from '@/selectors/api';
import { Task } from '@/types/v2/tasks';
import Button from '@/components/button/Button';
import TaskCard from './components/TaskCard';
import { selectCurrentUserId } from '@/selectors/users';
import Paginator from '@/components/paginator/Paginator';
import { EmptyBlock } from '@/components/dashboard';

const Tasks = (): JSX.Element => {
  const history = useHistory();
  const loader = useLoadingState('tasks');
  const notifier = useNotifier();

  const patient = useSelector(getSelectedPatient);
  const currentUserId = useSelector(selectCurrentUserId);

  const [tasks, setTasks] = useState<Task[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  if (!tasks) {
    return null;
  }

  const getTasks = async (): Promise<void> => {
    loader.startLoading('tasks');

    try {
      const url = buildPath(
        Routes.api2.tasks,
        {},
        {
          patient_id: patient.id,
          page: page.toString(),
          per: '10',
          task_status: 'pending',
        },
        ['created_by', 'assigned_to', 'completed_by', 'patient'],
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<Task[]>(response.data, {
        generations: 2,
      }).build();

      setTotalPages(response.data.meta.total);
      setTasks(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('tasks');
  };

  useEffect(() => {
    getTasks();
  }, [page]);

  const renderTasks = () => {
    if (tasks && tasks.length > 0) {
      return tasks.map((task: Task) => (
        <TaskCard
          key={task.id}
          onCheckTaskPress={handleCheckTaskPress}
          onTaskCardPress={handleTaskCardPress}
          task={task}
        />
      ));
    } else if (!loader.isLoading('tasks')) {
      return <EmptyBlock style="compact">There are no open tasks</EmptyBlock>;
    }
  };

  const checkCompletedTasks = (tasks: Task[]) => {
    const complete = (task: Task) => task.task_status === "completed" || task.task_status === "archived";
    return tasks.some(complete)
  }

  const handleTaskCardPress = (task: Task) => {
    const path = buildPath(Routes.tasksShow, {
      id: patient.id,
      task_id: task.id,
    });

    history.push(path);
  };

  const handleCheckTaskPress = async (task: Task): Promise<void> => {
    try {
      const body = {
        task: {
          completed_by_id: currentUserId,
          task_status: 'completed',
        },
      };

      const url = buildPath(Routes.api2.task, { id: task.id });

      const response = await Api.utility.patch(url, body);

      const resource = new Modeler<Task>(response.data, {
        generations: 2,
      }).build();

      setTasks(tasks => tasks.filter(task => task.id !== resource.id));
    } catch (err) {
      notifier.error(err);
    }
  };

  return (
    <div className="tasks">
      <div className="columns tasks__top">
        <div className="column is-6">
          <h3 className="tasks__header">Tasks</h3>
        </div>

        <div className="column is-6 tasks__actions-container">
          <Button
            color="white"
            isDisabled={!checkCompletedTasks(tasks)}
            onClick={() =>
              history.push(buildPath(Routes.tasksCompleted, { id: patient.id }))
            }>
            See Completed Tasks
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              history.push(
                buildPath(Routes.tasksNew, {
                  id: patient.id,
                }),
              );
            }}>
            Create Task
          </Button>
        </div>
      </div>

      {renderTasks()}

      {totalPages > 1 && (
        <Paginator
          currentPage={page}
          onChangePage={page => setPage(page)}
          totalPages={totalPages}
        />
      )}
    </div>
  );
};

export default Tasks;
