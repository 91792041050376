import RadioList from '@/components/form/RadioList';
import { AnswerAttributes } from '@/types/answer';
import { UUID } from '@/types/generic';
import { TrackingQuestionAttributes } from '@/types/tracking_question';
import React, { useMemo } from 'react';
import { TrackingAnswer } from './TrackingQuestionManager';

interface Props {
  answers: TrackingAnswer[];
  onSetAnswerValue: (questionId: UUID, value: number) => void;
  question: TrackingQuestionAttributes;
}

const TrackingAnswerRow = (props: Props) => {
  const { answers, onSetAnswerValue, question } = props;

  const options = useMemo(() => {
    return question.answer_set.attributes.answers.map(
      (answer: AnswerAttributes) => ({
        label: answer.text,
        value: answer.value,
      }),
    );
  }, [question]);

  const listValue = useMemo(() => {
    const answer = answers.find(
      (a: TrackingAnswer) => a.tracking_question_id === question.id,
    );

    return answer ? answer.value : null;
  }, [answers]);

  const handleSelectAnswer = (value: number) => {
    onSetAnswerValue(question.id, value);
  };

  return (
    <div className="tracking-question">
      <h5>{question.text}</h5>

      <RadioList
        isEven
        isFlex
        onChange={handleSelectAnswer}
        options={options}
        value={listValue}
      />
    </div>
  );
};

export default TrackingAnswerRow;
