import moment from 'moment';

import { Validations } from '@/types/form';

export const validateBeforeSubmit = (formState: any): Validations => {
  const { end_date, start_date, status } = formState;

  if (status === 'previous' && !end_date) {
    return {
      end_date: 'Field is required',
    };
  }

  const endDate = moment(end_date);
  const startDate = moment(start_date);

  const differenceInDays = endDate.diff(startDate, 'd');

  if (differenceInDays <= 0) {
    return {
      end_date: 'End date must occur after start date',
    };
  }

  return null;
};
