import moment, { Moment } from 'moment';

export const formatIsoDate = (date: object | string | Moment = new Date()) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatIsoDateTime = (date: object | string = new Date()) => {
  return moment(date).toISOString();
};

export const formatIsoDateLocal = (date: object | string = new Date()) => {
  return moment(date)
    .local()
    .format('YYYY-MM-DD HH:mm:ss');
};

export const formatTime = (date: object = new Date()) => {
  return moment(date).toISOString();
};

export const formatTimeString = (date: string) => {
  return date
    ? moment(date)
        .utc()
        .format('HH:mm')
    : '';
};

export const formatShortDate = (
  date: string | object,
  invalidFormat: string = '',
) => {
  if (!date) {
    return '';
  }

  const dateObject = moment(date).local();

  return dateObject.isValid()
    ? moment(date).format('MM/DD/YYYY')
    : invalidFormat;
};

export const formatShortDateTime = (
  date: string,
  invalidFormat: string = '',
) => {
  if (!date) {
    return '';
  }

  const dateObject = moment(date);

  return dateObject.isValid()
    ? moment(date).format('MM/DD/YYYY h:mma')
    : invalidFormat;
};

export const formatDateTimeWithoutTimezone = (
  date: string,
  invalidFormat: string = '',
) => {
  if (!date) {
    return '';
  }

  const dateObject = date;

  return dateObject
    ? date.slice(0,10) +' '+ date.slice(11,19)
    : invalidFormat;
};

export const formatFriendlyDateTime = (date: string) => {
  const formattedTime = moment(date).format('h:mma');
  const formattedDate = moment(date).format('MM/DD/YYYY');

  return `${formattedTime} on ${formattedDate}`;
};
