import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { getPatientIncludedData } from '@/selectors/api';
import { Patient } from '@/types/patient';
import { formatPhoneNumber } from '@/utils/phoneUtils';

interface Props {
  isVisible: boolean;
  patient: Patient;
}

const PatientDetailsExpanded = (props: Props) => {
  const { isVisible, patient } = props;

  const patientAttributes = patient.attributes;

  const included = useSelector(getPatientIncludedData);

  const address = included.find(entry => entry.type === 'address') || {};
  const addressAttributes = address.attributes;

  const emergencyContact =
    included.find(entry => entry.type === 'emergency_contact') || {};
  const emergencyContactAttr = emergencyContact.attributes || {};

  const previousNames = included.filter(
    entry => entry.type === 'previous_name',
  );

  const renderTracking = () => {
    const tracking = patientAttributes.metrics
      .map(metric => `${metric.description}`)
      .join(', ');

    return (
      <p className="patient-details-expanded__details">Tracking: {tracking}</p>
    );
  };

  const renderPreviousName = name => {
    return (
      <p className="patient-details-expanded__details">
        Previous Name: {name.attributes.first_name} {name.attributes.last_name}
      </p>
    );
  };

  return (
    <div>
      {isVisible && (
        <div className="columns">
          <div className="column is-4-tablet">
            <div className="patient-details-expanded__wrapper">
              <h4 className="patient-details-expanded__title">
                Healthcare Details
              </h4>
              <p className="patient-details-expanded__details">
                Health System: {patientAttributes.health_system.name}
              </p>
              <p className="patient-details-expanded__details">
                Hospital: {patientAttributes.hospital.name}
              </p>
              <p className="patient-details-expanded__details">
                Practice: {patientAttributes.practice.name}
              </p>
              {patientAttributes.default_specialist && (
                <p className="patient-details-expanded__details">{`Provider: ${
                  patientAttributes.default_specialist
                    ? patientAttributes.default_specialist.name
                    : ''
                }`}</p>
              )}
              {patientAttributes.specialists &&
                patientAttributes.specialists.data.map(specialist => {
                  return (
                    <p
                      className="patient-details-expanded__details"
                      key={
                        specialist.name
                      }>{`Additional Provider: ${specialist.name}`}</p>
                  );
                })}

              <p className="patient-details-expanded__details">
                Insurance Carrier: {patientAttributes.insurance_carrier}
              </p>
              <p className="patient-details-expanded__details">
                Insurance Group ID: {patientAttributes.insurance_group_id}
              </p>
              <p className="patient-details-expanded__details">
                Insurance Member ID: {patientAttributes.insurance_member_id}
              </p>
            </div>
          </div>

          <div className="column is-4-tablet">
            <div className="patient-details-expanded__wrapper">
              <h4 className="patient-details-expanded__title">CCM Details</h4>
              <p className="patient-details-expanded__details">{`Care Manager: ${patientAttributes.care_manager.first_name} ${patientAttributes.care_manager.last_name}`}</p>
              {patientAttributes.emr && (
                <p className="patient-details-expanded__details">{`EMR: ${patientAttributes.emr}`}</p>
              )}
              {patientAttributes.ccm_complexity && (
                <p className="patient-details-expanded__details">{`CCM Complexity: ${patientAttributes.ccm_complexity.name}`}</p>
              )}
              {patientAttributes.enrollment_date && (
                <p className="patient-details-expanded__details">{`Enrollment Date: ${moment(
                  patientAttributes.enrollment_date,
                ).format('MM/DD/YYYY')}`}</p>
              )}
              {patientAttributes.metrics && renderTracking()}
            </div>
          </div>

          <div className="column is-4-tablet">
            <div className="patient-details-expanded__wrapper">
              <h4 className="patient-details-expanded__title">User Details</h4>
              <p className="patient-details-expanded__details">{`Phone Number: ${formatPhoneNumber(
                patientAttributes.phone_number,
              )}`}</p>
              {emergencyContactAttr && emergencyContactAttr.phone_number && (
                <p className="patient-details-expanded__details">{`Emergency Phone: ${formatPhoneNumber(
                  emergencyContactAttr.phone_number,
                )}`}</p>
              )}
              <p className="patient-details-expanded__details">{`Email: ${patientAttributes.email}`}</p>

              {addressAttributes && (
                <div>
                  <p>{`Address: ${addressAttributes.line_1}`}</p>
                  <p>{addressAttributes.line_2}</p>
                  <p className="patient-details-expanded__address">{`${addressAttributes.city}, ${addressAttributes.state}, ${addressAttributes.zip_code}`}</p>
                </div>
              )}

              {patientAttributes.language && (
                <p className="patient-details-expanded__details">{`Language: ${patientAttributes.language}`}</p>
              )}

              {previousNames &&
                previousNames.map(name => {
                  return renderPreviousName(name);
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientDetailsExpanded;
