import React from 'react';

import ErrorMessage from '@/components/form/ErrorMessage';
import Input from '@/components/form/Input';
import Label from '@/components/form/Label';
import { SideEffect } from '@/types/generic';

interface Props {
  isDisabled?: boolean;
  label: string;
  onChange?: SideEffect;
  onPressEnter?: SideEffect;
  placeholder?: string;
  type?: string;
  validationMessage?: string;
  value: string;
}

export const InputGroup = (props: Props) => {
  const { label, validationMessage, ...inputProps } = props;

  const isInvalid = !!validationMessage;

  return (
    <div className="form-input-group">
      <Label isInvalid={isInvalid}>{label}</Label>
      <Input isInvalid={isInvalid} {...inputProps} />
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </div>
  );
};

export default InputGroup;
