import { all, call } from 'redux-saga/effects';

import applicationSaga from './applicationSaga';
import bhiSaga from './bhiSaga';
import callManagerSaga from './callManagerSaga';
import messageSaga from './messageSaga';
import patients from './patients';
import patientSaga from './patientSaga';
import requestSaga from './requestSaga';
import rpm from './rpm';
import videoCalls from './video_calls';

const sagas = [
  applicationSaga,
  bhiSaga,
  callManagerSaga,
  messageSaga,
  patientSaga,
  requestSaga,
  ...patients,
  ...rpm,
  ...videoCalls,
];

export default function* rootSaga() {
  yield all(sagas.map(saga => call(saga)));
}
