import { DeviceModel } from '@/types/device_model';
import { Snapshot, UUID } from '@/types/generic';
import { ReadingThreshold } from '@/types/v2/reading_threshold';
import { createEmptySnapshot } from '@/utils/storeUtils';

export interface PatientsState {
  isLoadingPracticeDeviceModels: boolean;
  practiceDeviceModels: DeviceModel[];
  practiceDeviceModelsPracticeId: UUID;
  practiceReadingThresholds: ReadingThreshold[];
  snapshot: Snapshot;
}

export const create = (data: Partial<PatientsState> = {}): PatientsState => ({
  isLoadingPracticeDeviceModels: false,
  practiceDeviceModels: [],
  practiceDeviceModelsPracticeId: null,
  practiceReadingThresholds: [],
  snapshot: createEmptySnapshot(),
  ...data,
});
