import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Routes } from '@/utils/routeUtils';
import SpecialistsIndex from '@/contexts/specialists/SpecialistsIndex';
import SpecialistsEdit from '@/contexts/specialists/SpecialistsEdit';

const Specialists = () => {
  return (
    <Switch>
      <Route exact path={Routes.specialists} component={SpecialistsIndex} />
      <Route path={Routes.specialistsEdit} component={SpecialistsEdit} />
    </Switch>
  )
}

export default Specialists;
