import React from 'react';

import Button from '@/components/button/Button';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import { getPatientEnrollmentSteps } from '@/contexts/enrollment/helpers/steps';
import {
  getSelectedPatient,
  getSelectedPatientEnrollmentStatus,
} from '@/selectors/api';
import { Patient } from '@/types/patient';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const PatientHeader = () => {
  const history = useHistory();

  const patient: Patient = useSelector(getSelectedPatient);
  const status = useSelector(getSelectedPatientEnrollmentStatus);

  if (!patient || status === 'complete') {
    return null;
  }

  const handleClickEnroll = () => {
    const path = buildPath(Routes.patientsEnrollmentStep, {
      id: patient.id,
      step: getPatientEnrollmentSteps(patient)[0],
      sub_step: '1',
    });

    history.push(path);
  };

  return (
    <Panel>
      <Container>
        <div className="patient-enrollment">
          <span>Patient enrollment is not complete.</span>
          <Button onClick={handleClickEnroll}>Enroll</Button>
        </div>
      </Container>
    </Panel>
  );
};

export default PatientHeader;
