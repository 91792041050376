import React, { ReactNode } from 'react';

import Constraint from '@/components/constraint/Constraint';
import Form from '@/components/form/Form';
import Panel from '@/components/panel/Panel';
import { FormComponentConfig } from '@/types/form';
import { SideEffect } from '@/types/generic';

interface Props {
  config: FormComponentConfig;
  onFormReset?: SideEffect;
  resetForm?: boolean;
}

const MedicationRequestForm = (props: Props) => {
  const { ...formProps } = props;

  return (
    <>
      <Panel>
        <Constraint size="medium">
          <Form {...formProps} />
        </Constraint>
      </Panel>
    </>
  );
};

export default MedicationRequestForm;
