import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Checklist from '@/components/form/Checklist';
import EnrollmentHeader from '@/contexts/enrollment/components/EnrollmentHeader';
import {
  getSelectedPatient,
  getSelectedPatientIncluded,
  getSelectPatientCcmConditions,
} from '@/selectors/api';
import { getGeneralConditionId, getOutcomes } from '@/selectors/options';
import { buildPath, Routes } from '@/utils/routeUtils';
import { getStepInformation } from '../helpers/steps';
import { createOptions } from '../utils/optionUtils';

const Outcomes = () => {
  const history = useHistory();

  const generalConditionId = useSelector(getGeneralConditionId);
  const conditions = useSelector(getSelectPatientCcmConditions);
  const included = useSelector(getSelectedPatientIncluded);
  const patient = useSelector(getSelectedPatient);
  const outcomes = useSelector(getOutcomes);

  const [originalOptions, setOriginalOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const stepInformation = getStepInformation('outcomes', patient);

  const nextScreen = buildPath(Routes.patientsEnrollment, {
    id: patient.id,
    step: stepInformation.nextStep,
  });

  const formatBody = ids => {
    return {
      enrollment_step: stepInformation.currentStep,
      patient: {
        outcome_ids: ids,
      },
    };
  };

  const handleSuccess = () => {
    history.push(nextScreen);
  };

  const getSelectedOptions = () => {
    if (!Array.isArray(included)) {
      return [];
    }

    const options = included
      .filter(include => include.type === 'outcome')
      .map(include => include.id);

    setSelectedOptions(options);
    setOriginalOptions(options);
  };

  useEffect(() => {
    getSelectedOptions();
  }, []);

  const outcomeOptions = createOptions(
    conditions,
    outcomes,
    generalConditionId,
    'Overall health outcomes',
  );

  return (
    <div className="enrollment-wrapper">
      <EnrollmentHeader
        description="Which expected outcomes would the patient like to see?"
        linkTo={nextScreen}
        linkText={stepInformation.nextStepTitle}
        title={stepInformation.currentStepTitle}
      />

      <Checklist
        formatBody={formatBody}
        onChange={newOptions => setSelectedOptions(newOptions)}
        onSuccess={handleSuccess}
        options={outcomeOptions}
        originalSelectedIds={originalOptions}
        selectedIds={selectedOptions}
        url={`api/v1/patients/${patient.id}`}
      />
    </div>
  );
};

export default Outcomes;
