import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { selectFeatureFlags } from '@/selectors/feature_flags';
import { Feature } from '@/types/feature_flags';

interface Props {
  children: ReactNode;
  flag: Feature;
}

const FeatureFlag = ({ children, flag }: Props): JSX.Element => {
  const featureFlags = useSelector(selectFeatureFlags);

  if (featureFlags[flag]) {
    return <>{children}</>;
  }

  return null;
};

export default FeatureFlag;
