import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const Page = (props: Props) => {
  const { children } = props;

  return <div className="page-full">{children}</div>;
};

export default Page;
