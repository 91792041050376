import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { generateToken } from '@/actions/actions/video_calls';
import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import AppointmentDetails from '@/modules/appointments/AppointmentDetails';
import AppointmentProvider from '@/modules/appointments/AppointmentProvider';
import AppointmentTime from '@/modules/appointments/AppointmentTime';
import QuestionnaireResponse from '@/modules/questionniares/display/QuestionnaireResponse';
import QuestionnairesList from '@/modules/questionniares/display/QuestionnairesList';
import VideoCallInterface from '@/modules/video_call/VideoCallInterface';
import { selectIsVideoCallActive } from '@/selectors/video_calls';
import { UUID } from '@/types/generic';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useDispatch, useSelector } from 'react-redux';
import QuestionnaireModal from '../questionnaires/QuestionnaireModal';

interface Params {
  appointment_id: UUID;
}

const Appointment = (): JSX.Element => {
  const backPath = buildPath(Routes.chart.appointments.root);

  const dispatch = useDispatch();
  const history = useHistory();
  const loader = useLoadingState('appointment');
  const notifier = useNotifier();

  const isCallActive = useSelector(selectIsVideoCallActive);

  const [appointment, setAppointment] = useState<VirtualAppointment>(null);
  const [questionnaireAppointmentId, setQuestionnaireAppointmentId] = useState<
    UUID
  >(null);

  const { appointment_id: id } = useParams<Params>();

  const handleEditQuestionnaire = (): void => {
    setQuestionnaireAppointmentId(id);
  };

  const handleCloseQuestionnaires = (): void => {
    setQuestionnaireAppointmentId(null);
  };

  const getAppointment = async (): Promise<void> => {
    loader.startLoading('appointment');

    try {
      const url = buildPath(Routes.api2.virtualAppointment, { id }, null, [
        'messages',
        'practice',
        'admin_user',
        'admin_user.specialist',
        'virtual_appointment_questionnaires',
        'virtual_appointment_questionnaires.questionnaire',
        'virtual_appointment_questionnaires.questionnaire_responses',
        'virtual_appointment_questionnaires.questionnaire_responses.questionnaire_answers',
        'virtual_appointment_questionnaires.questionnaire_responses.questionnaire_question',
      ]);
      const response = await Api.utility.get(url);
      const resource = new Modeler<VirtualAppointment>(response.data, {
        generations: 3,
      }).build();

      setAppointment(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('appointment');
  };

  const handleDisconnect = () => {
    const url = buildPath(Routes.chart.appointments.root);
    history.push(url);
    window.location.reload();
  };

  useEffect(() => {
    getAppointment();
  }, []);

  useEffect(() => {
    // If the patient joins and the appointment has already started, automatically join the call
    if (appointment && appointment.appointment_status === 'started') {
      dispatch(generateToken(appointment));
    }
  }, [appointment]);

  const renderAppointmenTime = (() => {
    if (loader.areAllComplete && appointment) {
      return (
        <Constraint height="small" size="medium">
          <AppointmentTime appointment={appointment} />
        </Constraint>
      );
    }

    return (
      <Constraint height="small" size="medium">
        <ActivityIndicator />
      </Constraint>
    );
  })();

  const renderProviderDetails = (() => {
    if (loader.areAllComplete && appointment) {
      return (
        <Constraint height="medium" size="medium">
          <AppointmentDetails
            appointment={appointment}
            name={appointment.admin_user.specialist.name}
          />

          <AppointmentProvider appointment={appointment} />

          <QuestionnairesList
            questionnaires={appointment.virtual_appointment_questionnaires}
            onClickEdit={handleEditQuestionnaire}
          />
        </Constraint>
      );
    }

    return (
      <Constraint height="medium" size="medium">
        <ActivityIndicator />
      </Constraint>
    );
  })();

  const renderPageContent = (() => {
    if (isCallActive) {
      return (
        <VideoCallInterface
          appointment={appointment}
          onClickDisconnect={handleDisconnect}
        />
      );
    }

    return (
      <Container>
        <Panel>{renderAppointmenTime}</Panel>
        <Panel>{renderProviderDetails}</Panel>
      </Container>
    );
  })();

  return (
    <>
      <Container>
        <BackLink to={backPath}>Exit</BackLink>
      </Container>

      {renderPageContent}

      <QuestionnaireModal
        appointmentId={questionnaireAppointmentId}
        onCloseModal={handleCloseQuestionnaires}
      />
    </>
  );
};

export default Appointment;
