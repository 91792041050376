import React from 'react';

import CheckboxGroup from '@/components/form/CheckboxGroup';
import { UUID } from '@/types/generic';
import { QuestionnaireAnswer } from '@/types/v2/questionnaire';
import { FormResponseAnswer } from './types';

interface Props {
  answers: QuestionnaireAnswer[];
  onToggleAnswer: (id: UUID) => void;
  selectedAnswers: FormResponseAnswer[];
}

const MultiAnswerResponse = ({
  answers,
  onToggleAnswer,
  selectedAnswers,
}: Props): JSX.Element => {
  const handleToggleAnswer = (answerId: UUID): void => {
    onToggleAnswer(answerId);
  };

  const renderCheckboxes = (() => {
    return answers.map(answer => {
      const isChecked = !!selectedAnswers.find(
        selectedAnswer =>
          !selectedAnswer._destroy &&
          selectedAnswer.questionnaire_answer_id === answer.id,
      );

      return (
        <div className="questionnaire-question__checkbox" key={answer.id}>
          <CheckboxGroup
            label={answer.text}
            onChange={() => handleToggleAnswer(answer.id)}
            value={isChecked}
          />
        </div>
      );
    });
  })();

  return (
    <div className="questionnaire-question__multi-answer">
      {renderCheckboxes}
    </div>
  );
};

export default MultiAnswerResponse;
