import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Checklist from '@/components/form/Checklist';
import EnrollmentHeader from '@/contexts/enrollment/components/EnrollmentHeader';
import {
  getSelectedPatient,
  getSelectedPatientIncluded,
  getSelectPatientCcmConditions,
} from '@/selectors/api';
import {
  getAdditionalConditions,
  getGeneralConditionId,
  getGoals,
  getOtherConditionId,
} from '@/selectors/options';
import usePutRequest from '@/utils/api/usePutRequest';
import { buildPath, Routes } from '@/utils/routeUtils';
import { getStepInformation } from '../helpers/steps';
import { createOptions } from '../utils/optionUtils';

const Goals = () => {
  const history = useHistory();

  const additionalOptions = useSelector(getAdditionalConditions);
  const conditions = useSelector(getSelectPatientCcmConditions);
  const generalConditionId = useSelector(getGeneralConditionId);
  const goals = useSelector(getGoals);
  const included = useSelector(getSelectedPatientIncluded);
  const otherConditionId = useSelector(getOtherConditionId);
  const patient = useSelector(getSelectedPatient);

  const [originalOptions, setOriginalOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const hasOtherCondition = !!conditions.find(
    condition => condition.id === otherConditionId,
  );

  const stepInformation = getStepInformation('goals', patient);

  const nextScreen = buildPath(Routes.patientsEnrollment, {
    id: patient.id,
    step: stepInformation.nextStep,
  });

  const formatBody = ids => {
    return {
      enrollment_step: stepInformation.currentStep,
      patient: {
        goal_ids: ids,
      },
    };
  };

  const handleSuccess = () => {
    history.push(nextScreen);
  };

  const getSelectedOptions = () => {
    if (!Array.isArray(included)) {
      return [];
    }

    const options = included
      .filter(include => include.type === 'goal')
      .map(include => include.id);

    setSelectedOptions(options);
    setOriginalOptions(options);
  };

  useEffect(() => {
    getSelectedOptions();
  }, []);

  const conditionList = () => {
    if (hasOtherCondition) {
      return conditions
        .slice(0, -1)
        .concat(additionalOptions)
        .concat(['general']);
    }

    return conditions;
  };

  const goalOptions = createOptions(
    conditionList(),
    goals,
    generalConditionId,
    'General personal health goals',
  );

  return (
    <div className="enrollment-wrapper">
      <EnrollmentHeader
        description="Which goals would the patient like to achieve?"
        linkTo={nextScreen}
        linkText={stepInformation.nextStepTitle}
        title={stepInformation.currentStepTitle}
      />

      <Checklist
        formatBody={formatBody}
        onChange={newOptions => setSelectedOptions(newOptions)}
        onSuccess={handleSuccess}
        options={goalOptions}
        originalSelectedIds={originalOptions}
        selectedIds={selectedOptions}
        url={`api/v1/patients/${patient.id}`}
      />
    </div>
  );
};

export default Goals;
