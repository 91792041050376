import FullCalendar, {
  EventClickArg,
  EventSourceFunc,
} from '@fullcalendar/react';
import React, { useEffect, useRef, useState } from 'react';
import { useResponsive } from 'react-hooks-responsive';
import { useHistory, useLocation } from 'react-router-dom';

// This must be imported after FullCalendar
import dayGridPlugin from '@fullcalendar/daygrid';

import Api from '@/api/Api';
import Container from '@/components/container/Container';
import Modal from '@/components/modal/Modal';
import Panel from '@/components/panel/Panel';
import {
  getCurrentUser,
  selectIsCurrentUserCareManager,
} from '@/selectors/users';
import { CallAttributes } from '@/types/call';
import { fullCalendarProps } from '@/utils/calendarUtils';
import { formatIsoDate } from '@/utils/dateUtils';
import Modeler from '@/utils/modeler/modeler';
import { areObjectsEqual } from '@/utils/objectUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { breakpoints } from '@/utils/screenUtils';
import { usePrevious } from '@/utils/stateUtils';
import { fullName } from '@/utils/userUtils';
import { useSelector } from 'react-redux';
import CallScheduleModal from './components/CallScheduleModal';
import CallSchedulePatientModal from './components/CallSchedulePatientModal';
import CallsScheduleHeader from './components/CallsScheduleHeader';

const CallsScheduleIndex = () => {
  const calendarRef = useRef(null);

  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const patientId = params.get('patient_id');
  const startAt = params.get('start_at');

  const { screenIsAtMost } = useResponsive(breakpoints);

  const isCareManager = useSelector(selectIsCurrentUserCareManager);
  const user = useSelector(getCurrentUser);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  var startDate;
  var endDate;
  const intialQuery = {
    health_system_id: '',
    hospital_id: '',
    practice_id: '',
    search: '',
    user_id: '',
  };

  const [queryOptions, setQueryOptions] = useState(intialQuery);

  const [mobileQueryOptions, setMobileQueryOptions] = useState({
    health_system_id: '',
    hospital_id: '',
    practice_id: '',
    user_id: '',
  });

  const previousQuery = usePrevious(queryOptions);

  const isMobile = screenIsAtMost('mobile');

  useEffect(() => {
    if (!calendarRef.current) {
      return;
    }

    if (isMobile && calendarRef.current) {
      calendarRef.current.getApi().changeView('dayGridDay');
    } else if (calendarRef.current) {
      calendarRef.current.getApi().changeView('dayGridMonth');
    }
  }, [isMobile]);

  const handleQueryChange = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleSearchChange = (value: any) => {
    setSearchInput(value);
  };

  const handleSearchInput = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const handleModalApplyClick = () => {
    setQueryOptions({
      ...queryOptions,
      ...mobileQueryOptions,
    });

    setIsModalVisible(false);
  };

  const handlFilterClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleClosePatientModal = () => {
    const url = buildPath(Routes.calls);

    history.push(url);
  };

  const fetchEvents: EventSourceFunc = (info, onSuccess) => {
    startDate = info.startStr
    startDate = startDate.slice(0,10) +' '+ startDate.slice(11,19) 
    endDate = info.endStr
    endDate = endDate.slice(0,10) +' '+ endDate.slice(11,19) 
  
    const url = buildPath(
      'api/v1/calls',
      {},
      {
        ...queryOptions,
        end_at: endDate,
        include: 'care_manager,patient',
        per: null,
        start_at: startDate,
        user_id: isCareManager ? user.data.id : queryOptions.user_id || '',
      },
    );

    Api.utility.get(url).then(response => {
      const calls = new Modeler<CallAttributes[]>(response.data).build();
      const formattedCalls = calls.map(formatEvent);

      onSuccess(formattedCalls);
    });
  };

  const formatEvent = (call: CallAttributes) => ({
    id: call.id,
    date: call.start_at,
    patientId: call.patient.id,
    showDate: call.start_at,
    title: isCareManager ? fullName(call.patient) : fullName(call.care_manager),
  });

  useEffect(() => {
    if (previousQuery && !areObjectsEqual(queryOptions, previousQuery)) {
      calendarRef.current.getApi().refetchEvents();
    }
  }, [queryOptions]);

  const handleClickScheduled = () => {
    const url = buildPath(Routes.callsScheduleNew);

    history.push(url);
  };

  const handleClearFilters = () => {
    setQueryOptions(intialQuery);
  };

  const handleSelectEvent = (event: EventClickArg): void => {
    const extendedProps = event.event._def.extendedProps;

    if (isCareManager) {
      const eventPatientId = extendedProps.patientId;
      const startOfDay = formatIsoDate(extendedProps.showDate);

      const url = buildPath(
        Routes.calls,
        {},
        {
          patient_id: eventPatientId,
          start_at: startOfDay,
        },
      );

      history.push(url);
    } else {
      const startOfDay = formatIsoDate(extendedProps.showDate);

      const url = buildPath(
        Routes.calls,
        {},
        {
          start_at: startOfDay,
        },
      );

      history.push(url);
    }
  };

  const handleMobileQueryChange = (field: string, value: any) => {
    setMobileQueryOptions({
      ...mobileQueryOptions,
      [field]: value,
    });
  };

  const renderModals = () => {
    if (patientId) {
      return (
        <Modal isVisible={true} onCloseModal={handleClosePatientModal}>
          <CallSchedulePatientModal patientId={patientId} startAt={startAt} />
        </Modal>
      );
    } else if (startAt) {
      return (
        <Modal isVisible={true} onCloseModal={handleClosePatientModal}>
          <CallScheduleModal queryOptions={queryOptions} startAt={startAt} />
        </Modal>
      );
    }
  };

  return (
    <>
      <Container>
        <Panel withNarrowMargin>
          <div className="call-schedule">
            <h2>Call Schedule</h2>

            <CallsScheduleHeader
              closeModal={handleCloseModal}
              mobileQueryOptions={mobileQueryOptions}
              modalVisible={isModalVisible}
              onClearFilters={handleClearFilters}
              onFilterChange={handleQueryChange}
              onFilterClick={handlFilterClick}
              onModalApplyClick={handleModalApplyClick}
              onModalFilterChange={handleMobileQueryChange}
              onScheduleClick={handleClickScheduled}
              onSearchChange={handleSearchChange}
              onSearchClick={handleSearchInput}
              queryOptions={queryOptions}
              searchValue={searchInput}
            />

            <FullCalendar
              eventClick={handleSelectEvent}
              events={fetchEvents}
              plugins={[dayGridPlugin]}
              ref={calendarRef}
              {...fullCalendarProps}
            />
          </div>
        </Panel>
      </Container>

      {renderModals()}
    </>
  );
};

export default CallsScheduleIndex;
