import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Datepicker from '@/components/form/Datepicker';
import Timepicker from '@/components/form/Timepicker';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Panel from '@/components/panel/Panel';
import ConfirmDeleteModal from '@/contexts/modals/ConfirmDeleteModal';
import { Device, DeviceReading } from '@/types/device';
import { Option, Validations } from '@/types/form';
import { UUID } from '@/types/generic';
import { IdParams } from '@/types/params';
import { parseErrorData } from '@/utils/apiUtils';
import { formatIsoDateLocal } from '@/utils/dateUtils';
import { typeLabelName } from '@/utils/deviceUtils';
import { validate } from '@/utils/form';
import Modeler from '@/utils/modeler/modeler';
import { pullAttributes } from '@/utils/objectUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { useDispatch } from 'react-redux';
import DeviceReadingValues from './DeviceReadingValues';

const intiialFormState = {
  device_id: '',
  reading_time: '',
  reading_type: '',
  value_1: '',
  value_2: '',
  value_3: '',
};

interface Params extends IdParams {
  reading_id?: UUID;
}

const DeviceReadingsForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, reading_id } = useParams<Params>();

  const isEditForm = !!reading_id;

  const backPath = buildPath(Routes.patientsRemotePatientCare, { id });

  const [devices, setDevices] = useState<Device[]>([]);
  const [form, setForm] = useState<typeof intiialFormState>(intiialFormState);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState<boolean>(
    false,
  );
  const [isFetchingDevices, setIsFetchingDevices] = useState<boolean>(true);
  const [isFetchingRecord, setIsFetchingRecord] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [validations, setValidations] = useState<Validations>({});

  useEffect(() => {
    getPatientDevices();
    getDeviceReading();
  }, []);

  const getPatientDevices = async () => {
    try {
      const url = buildPath(Routes.api2.devices, {}, { patient_id: id, context: 'DeviceReadingsForm' },);

      const response = await Api.utility.get(url);
      const model = new Modeler<Device[]>(response.data, {
        generations: 2,
      }).build();

      setDevices(model);
    } catch (err) {
      dispatch(flashError(parseErrorData(err)));
    }

    setIsFetchingDevices(false);
  };

  const getDeviceReading = async () => {
    if (!isEditForm) {
      return;
    }

    setIsFetchingRecord(true);

    try {
      const url = buildPath(Routes.api2.deviceReading, { id: reading_id });
      const response = await Api.utility.get(url);

      const model = new Modeler<DeviceReading>(response.data).build();

      const formData = pullAttributes(
        model,
        'reading_time',
        'reading_type',
        'value_1',
        'value_2',
        'value_3',
        'device.id',
      );

      setForm(formData as typeof intiialFormState);
    } catch (err) {
      dispatch(flashError(parseErrorData(err)));
    }

    setIsFetchingRecord(false);
  };

  const deviceOptions: Option[] = useMemo(() => {
    return devices.map(device => ({
      label: `${device.device_type.name} (${device.serial_number})`,
      value: device.id,
    }));
  }, [devices]);

  const typeOptions: Option[] = useMemo(() => {
    const selectedDevice = devices.find(d => d.id === form.device_id);

    if (!selectedDevice) {
      return [];
    }

    const alertTypeValues = selectedDevice.device_type.alert_types.map(
      alertType => alertType.type_label,
    );

    const uniqueValues = [...new Set(alertTypeValues)];

    return uniqueValues.map(v => ({
      label: typeLabelName(v),
      value: v.toString(),
    }));
  }, [form.device_id, devices]);

  useEffect(() => {
    if (!typeOptions.length) {
      updateField('reading_type', '');
    } else {
      updateField('reading_type', typeOptions[0].value);
    }
  }, [typeOptions]);

  const updateField = (field: string, value: string): void => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleClearValues = () => {
    setForm({
      ...form,
      value_1: '',
      value_2: '',
      value_3: '',
    });
  };

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const handleClickDelete = () => {
    setIsConfirmationVisible(true);
  };

  const handleDeleteRecord = async () => {
    setIsSubmitting(true);

    try {
      const url = buildPath(Routes.api2.deviceReading, { id: reading_id });
      await Api.utility.delete(url);
      history.push(backPath);
    } catch (err) {
      dispatch(flashError(parseErrorData(err)));
    }

    setIsSubmitting(false);
  };

  const handleClickSave = () => {
    const rules = {
      device_id: { required: true },
      reading_time: { required: true },
      reading_type: { required: true },
      value_1: { required: true },
      value_2: {},
      value_3: {},
    };

    if (form.reading_type === '2') {
      rules.value_2 = { required: true };
      rules.value_3 = { required: true };
    } else if (form.reading_type === '7') {
      rules.value_2 = { required: true };
    }

    const [isValid, validationMessages] = validate(form, rules);

    if (isValid) {
      setValidations({});
    } else {
      return setValidations(validationMessages);
    }

    submitForm();
  };

  const submitForm = async () => {
    setIsSubmitting(true);

    try {
      const body = {
        device_reading: {
          ...form,
          patient_id: id,
          reading_time_local: formatIsoDateLocal(form.reading_time),
        },
      };

      if (isEditForm) {
        const url = buildPath(Routes.api2.deviceReading, { id: reading_id });
        await Api.utility.patch(url, body);
      } else {
        const url = buildPath(Routes.api2.deviceReadings);
        await Api.utility.post(url, body);
      }

      dispatch(flashSuccess('Device reading saved'));
      history.push(backPath);
    } catch (err) {
      dispatch(flashError(parseErrorData(err)));
    }

    setIsSubmitting(false);
  };

  const renderDeleteButton = (() => {
    if (isEditForm) {
      return (
        <Button
          color="error"
          isSubmitting={isSubmitting}
          onClick={handleClickDelete}
          style="text">
          Delete
        </Button>
      );
    }
  })();

  if (isFetchingDevices || isFetchingRecord) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <Container>
        <BackLink to={backPath}>Back to Remote Patient Care</BackLink>
      </Container>

      <Panel>
        <Constraint size="large">
          <Section title="Device Reading">
            <div className="columns ">
              <div className="column is-3">
                <SelectGroup
                  label="Device"
                  onChange={value => updateField('device_id', value)}
                  options={deviceOptions}
                  value={form.device_id}
                  validationMessage={validations.device_id}
                />
              </div>

              <div className="column is-3">
                <SelectGroup
                  isDisabled={typeOptions.length < 2}
                  label="Reading Type"
                  onChange={value => updateField('reading_type', value)}
                  options={typeOptions}
                  value={form.reading_type}
                  validationMessage={validations.reading_type}
                />
              </div>

              <div className="column is-3">
                <Datepicker
                  enableTime
                  label="Reading Date"
                  onChange={value => updateField('reading_time', value)}
                  value={form.reading_time}
                  validationMessage={validations.reading_time}
                />
               
              </div>
              <div className="column is-3">
                <Timepicker
                  
                  label="Reading Time"
                  onChange={value => updateField('reading_time', value)}
                  value={form.reading_time}
                  validationMessage={validations.reading_time}
                />
               
              </div>
              
            </div>
            

            <DeviceReadingValues
              onClearValues={handleClearValues}
              onUpdateField={updateField}
              readingType={form.reading_type}
              validations={validations}
              value1={form.value_1}
              value2={form.value_2}
              value3={form.value_3}
            />
          </Section>
        </Constraint>
      </Panel>

      <Constraint size="large">
        <div className="form__actions">
          <div className="form__actions-left">{renderDeleteButton}</div>

          <div className="form__actions-right">
            <Button color="white" onClick={handleClickCancel}>
              Cancel
            </Button>

            <Button
              color="secondary"
              isSubmitting={isSubmitting}
              onClick={handleClickSave}>
              Save Reading
            </Button>
          </div>
        </div>
      </Constraint>

      <ConfirmDeleteModal
        isVisible={isConfirmationVisible}
        onAccept={handleDeleteRecord}
        onClose={() => setIsConfirmationVisible(false)}
      />
    </>
  );
};

export default DeviceReadingsForm;
