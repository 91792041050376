import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { AudioTrack, Participant, VideoTrack } from 'twilio-video';

type ParticipantRole = 'self' | 'other';

interface Props {
  participant: Participant;
  role?: ParticipantRole;
  shouldForceFullscreen?: boolean;
}

const VideoParticipant = ({
  participant,
  role = 'other',
  shouldForceFullscreen,
}: Props) => {
  const [videoTracks, setVideoTracks] = useState<VideoTrack[]>([]);
  const [audioTracks, setAudioTracks] = useState<AudioTrack[]>([]);

  const videoRef = useRef(null);
  const audioRef = useRef(null);

  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter(track => track !== null);

  // When the participant is updated, subscribe to the audio and video tracks
  useEffect(() => {
    const trackSubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(prevVideoTracks => [...prevVideoTracks, track]);
      } else {
        setAudioTracks(prevAudioTracks => [...prevAudioTracks, track]);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(prevVideoTracks =>
          prevVideoTracks.filter(v => v !== track),
        );
      } else {
        setAudioTracks(prevAudioTracks =>
          prevAudioTracks.filter(a => a !== track),
        );
      }
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    let videoTrack = videoTracks[0];

    if (videoTracks[1]) {
      videoTrack = videoTracks[1];
    }

    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div
      className={classNames('video-call__participant', {
        'is-self': role === 'self' && !shouldForceFullscreen,
      })}>
      <video ref={videoRef} autoPlay={true} />
      <audio ref={audioRef} autoPlay={true} />
    </div>
  );
};

export default VideoParticipant;
