import { Nullable, UUID } from '../generic';
import { Patient } from './patient';
import { Specialist } from './specialist';

export type MessageAuthorType = 'Specialist' | 'Patient';
export type MessageAuthor = Specialist | Patient;
export type MessageType = 'text' | 'file';

interface Attachment {
  id: string;
  name: string;
  url: string;
}

export interface Message {
  id: UUID;
  attachment: Nullable<Attachment>;
  author_id: UUID;
  author_type: MessageAuthorType;
  message_type: MessageType;
  text: string;
  author?: MessageAuthor;
}

export interface MessagesState {
  messages: Message[];
}

export const create = (data: Partial<MessagesState> = {}): MessagesState => ({
  messages: [],
  ...data,
});
