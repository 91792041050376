import React from 'react';
import { Route, Switch } from 'react-router-dom';

import VirtualAppointmentForm from '@/contexts/provider/appointments/VirtualAppointmentForm';
import { Routes } from '@/utils/routeUtils';
import VirtualVisitsIndex from './VirtualVisitsIndex';

const VirtualVisits = () => {
  return (
    <Switch>
      <Route
        path={Routes.provider.patients.show.virtualVisits.new}
        component={VirtualAppointmentForm}
      />

      <Route
        path={Routes.provider.patients.show.virtualVisits.root}
        component={VirtualVisitsIndex}
      />
    </Switch>
  );
};

export default VirtualVisits;
