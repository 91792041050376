import classNames from 'classnames';
import React from 'react';

import { SideEffect } from '@/types/generic';

interface Props {
  onClick: SideEffect;
  type: 'accept' | 'reject';
}

const AnswerButton = (props: Props) => {
  const { onClick, type } = props;

  const ICON = require('assets/images/icons/phone.png');

  return (
    <div
      className={classNames('call-control__call-button', {
        'is-accept': type === 'accept',
        'is-reject': type === 'reject',
      })}>
      <button onClick={onClick}>
        <img src={ICON} alt="A green phone icon" />
      </button>

      <span>{type === 'accept' ? 'Answer' : 'Reject'}</span>
    </div>
  );
};

export default AnswerButton;
