import React from 'react';
import { useSelector } from 'react-redux';

import { selectBrandLogo } from '@/selectors/brand';
import ActivityIndicator from './ActivityIndicator';

const LoadingScreen = () => {
  const logo = useSelector(selectBrandLogo);

  return (
    <div className="loading-screen">
      <div className="loading-screen__img">
        <img src={logo} />
      </div>

      <h1>Loading Application</h1>

      <div className="loading-screen__activity">
        <ActivityIndicator />
      </div>
    </div>
  );
};

export default LoadingScreen;
