import moment from 'moment';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  setCallModalVisibility,
  setCallService,
  setCompleteModalVisibility,
  setDialerModalVisibility,
  setIsTimerPaused,
  setSelectedCallPatient,
  setSelectedPhoneNumber,
  setStartAt,
  setTimerType,
  startTimer,
  stopTimer,
} from '@/actions/reducers/call_manager';
import { beginCall, endCall } from '@/actions/sagas/callManagerSaga';
import Button from '@/components/button/Button';
import {
  selectIsCallActive,
  selectIsCallModalVisible,
  selectIsDialerModalVisible,
  selectIsTimerPaused,
  selectIsTimerRunning,
  selectSelectedCallPatient,
  selectSelectedPhoneNumber,
  selectTimerAmount,
} from '@/selectors/call_manager';
import { UUID } from '@/types/generic';
import { Patient } from '@/types/patient';
import { useDispatch, useSelector } from 'react-redux';
import DialerModal from './DialerModal';
import PauseButton from './PauseButton';
import PhoneNumberModal from './PhoneNumberModal';
import TimerDisplay from './TimerDisplay';

interface Props {
  editPath: string;
  patient: Patient;
}

const Caller = (props: Props) => {
  const { editPath, patient } = props;

  const dispatch = useDispatch();

  const selectedPhoneNumber = useSelector(selectSelectedPhoneNumber);
  const selectedCallPatient = useSelector(selectSelectedCallPatient);
  const isCallModalVisible = useSelector(selectIsCallModalVisible);
  const isDialerModalVisible = useSelector(selectIsDialerModalVisible);
  const isTimerPaused = useSelector(selectIsTimerPaused);
  const isTimerRunning = useSelector(selectIsTimerRunning);
  const timerAmount = useSelector(selectTimerAmount);
  const isCallActive = useSelector(selectIsCallActive);

  const isViewingInactivePatient = !!(
    isTimerRunning &&
    selectedCallPatient &&
    selectedCallPatient.id !== patient.id
  );

  useEffect(() => {
    dispatch(setSelectedPhoneNumber(patient.attributes.phone_number));
  }, []);

  const dialNumber = () => {
    dispatch(setSelectedPhoneNumber(''));
    dispatch(setCallModalVisibility(false));
    dispatch(setDialerModalVisibility(true));
  };

  const handleCallPatient = () => {
    if (selectedPhoneNumber === 'dial') {
      dialNumber();
    } else {
      dispatch(setDialerModalVisibility(false));
      dispatch(setSelectedCallPatient(patient));
      dispatch(beginCall());
    }
  };

  const handleChangeService = (service: UUID) => {
    dispatch(setCallService(service));
  };

  const handleChangePhoneNumber = (phoneNumber: string) => {
    dispatch(setSelectedPhoneNumber(phoneNumber));
  };

  const handleCloseCallModal = () => {
    dispatch(setCallModalVisibility(false));
  };

  const handleDialNumber = () => {
    dialNumber();
  };

  const handleEndCall = () => {
    dispatch(endCall());
  };

  const handleOpenCallModal = () => {
    dispatch(setCallModalVisibility(true));
    dispatch(setSelectedPhoneNumber(patient.attributes.phone_number || ''));
  };

  const handleStartTimer = () => {
    dispatch(setSelectedCallPatient(patient));
    dispatch(setTimerType('admin'));
    dispatch(startTimer());
    dispatch(setStartAt(moment().format()));
  };

  const handleStopTimer = () => {
    dispatch(stopTimer());
    dispatch(setCompleteModalVisibility(true));
  };

  const handleTogglePause = () => {
    dispatch(setIsTimerPaused(!isTimerPaused));
  };

  const isTimerRunningAlone = isTimerRunning && !isCallActive;

  const renderActions = () => {
    if (isViewingInactivePatient) {
      return renderNoActivity();
    }

    if (isCallActive) {
      return renderCallActions();
    }

    if (isTimerRunningAlone) {
      return renderTimerActions();
    }

    return renderNoActivity();
  };

  const renderNoActivity = () => {
    return (
      <>
        <Button
          color="white"
          isDisabled={isViewingInactivePatient}
          onClick={handleOpenCallModal}>
          Call Patient
        </Button>
        <Button
          color="secondary"
          isDisabled={isViewingInactivePatient}
          onClick={handleStartTimer}>
          Start Timer
        </Button>
      </>
    );
  };

  const renderCallActions = () => {
    return (
      <>
        {renderTimer()}
        <Button color="secondary" onClick={handleEndCall}>
          End Call
        </Button>
      </>
    );
  };

  const renderTimer = () => {
    return (
      <div className="caller__timer">
        <TimerDisplay duration={timerAmount} />
        <PauseButton isPaused={isTimerPaused} onClick={handleTogglePause} />
      </div>
    );
  };

  const renderTimerActions = () => {
    return (
      <>
        {renderTimer()}
        <Button color="secondary" onClick={handleStopTimer}>
          Stop Timer
        </Button>
      </>
    );
  };

  return (
    <>
      <div className="caller">
        <div className="caller__actions">{renderActions()}</div>

        <div className="caller__edit-link">
          <Link to={editPath}>Edit Patient</Link>
        </div>
      </div>

      <PhoneNumberModal
        isVisible={isCallModalVisible}
        onChangePhoneNumber={handleChangePhoneNumber}
        onChangeService={handleChangeService}
        onClickCall={handleCallPatient}
        onClickDial={handleDialNumber}
        onCloseModal={handleCloseCallModal}
        selectedPhoneNumber={selectedPhoneNumber}
      />

      <DialerModal
        isVisible={isDialerModalVisible}
        onDialPatient={handleCallPatient}
      />
    </>
  );
};

export default Caller;
