export const formatPhoneNumber = (phoneNumber: string = '') => {
  if (!phoneNumber) {
    return '';
  }

  const phone = phoneNumber.replace(/^\+[0-9]/, '');

  return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const formatPhoneNumberProgress = (phoneNumber: string = '') => {
  let match = phoneNumber.match(/^(1)(\d{3})(\d{3})(\d{1,4})$/);
  if (match) {
    return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }

  match = phoneNumber.match(/^(1)(\d{3})(\d{1,3})$/);

  if (match) {
    return `${match[1]} (${match[2]}) ${match[3]}`;
  }

  match = phoneNumber.match(/^(1)(\d{1,3})$/);

  if (match) {
    return `${match[1]} (${match[2]})`;
  }

  match = phoneNumber.match(/^(1)$/);

  if (match) {
    return `${match[1]}`;
  }

  return '';
};
