import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import { refreshPatientAction } from '@/actions/sagas/patientSaga';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import CheckboxGroup from '@/components/form/CheckboxGroup';
import Datepicker from '@/components/form/Datepicker';
import ErrorMessage from '@/components/form/ErrorMessage';
import InputGroup from '@/components/form/InputGroup';
import SearchGroup from '@/components/form/SearchGroup';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import TextAreaGroup from '@/components/form/TextAreaGroup';
import TypeaheadSelectGroup from '@/components/form/TypeaheadSelectGroup';
import Panel from '@/components/panel/Panel';
import {
  formatSearchResult,
  formatSearchResultCode,
} from '@/components/typeahead/Typeahead';
import {
  getBhiServiceId,
  getCcmComplexityOptions,
  getCcmServiceId,
  getRpmServiceId,
} from '@/selectors/options';
import { Validations } from '@/types/form';
import { Patient } from '@/types/patient';
import { Response } from '@/types/response';
import { serviceTags } from '@/types/service';
import useDelRequest from '@/utils/api/useDelRequest';
import useGetRequest from '@/utils/api/useGetRequest';
import usePutRequest from '@/utils/api/usePutRequest';
import { parseErrorData, parseResponseData } from '@/utils/apiUtils';
import {
  genderOptions,
  languageOptions,
  patientStatuses,
  stateOptions,
  suffixOptions,
  titleOptions,
} from '@/utils/dropdownUtils';
import { validate } from '@/utils/form';
import { flattenIncludes, getInclude } from '@/utils/responseUtils';
import { buildPath, Routes } from '@/utils/routeUtils';
import { usePrevious } from '@/utils/stateUtils';
import { fullName } from '@/utils/userUtils';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';
import PatientPassword from './components/PatientPassword';
import { scrollToTop } from '@/utils/windowUtils';
import { IdParams } from '@/types/params';

const FORM_STATE = {
  address_city: '',
  address_line_1: '',
  address_line_2: '',
  address_state: '',
  address_zip_code: '',
  bhi_billing_code_ids: [],
  bhi_complexity_id: '',
  bhi_enrollment_date: '',
  billing_cycle: '',
  care_manager_id: '',
  ccm_billing_code_ids: [],
  ccm_complexity_id: '',
  ccm_enrollment_date: '',
  date_of_birth: '',default_specialist_id: '',
  email: '',
  emergency_contact_phone_number: '',
  emr: '',
  enrolling_provider_id: '',
  first_name: '',
  gender: '',
  health_system_id: '',
  hospital_id: '',
  initial_bhi_billing_code_ids: [],
  initial_ccm_billing_code_ids: [],
  initial_rpm_billing_code_ids: [],
  insurance_carrier: '',
  insurance_group_id: '',
  insurance_member_id: '',
  is_ccm: false,
  is_enrolled_bhi: false,
  is_enrolled_ccm: false,
  is_enrolled_rpm: false,
  is_testimonial_worthy: false,
  language: '',
  last_name: '',
  middle_name: '',
  patient_notes: '',
  phone_number: '',
  practice_id: '',
  preferred_name: '',
  rpm_billing_code_ids: [],
  rpm_enrollment_date: '',
  status: 'active',
  suffix: '',
  title: '',
};
import { getCurrentUser } from '@/selectors/users';

const PatientsEdit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { id } = useParams<IdParams>();

  const [response, setResponse] = useState(null);

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});

  const [
    isConfirmDeleteModalVisible,
    setIsConfirmDeleteModalVisible,
  ] = useState<boolean>(false);

  const previousHealthSystemId = usePrevious(form.health_system_id);
  const previousHospitalId = usePrevious(form.hospital_id);
  const previousPracticeId = usePrevious(form.practice_id);

  const careManager = getInclude(response, 'care_manager');
  const healthSystem = getInclude(response, 'health_system');
  const hospital = getInclude(response, 'hospital');
  const practice = getInclude(response, 'practice');

  const enrollingProviderSelection = (() => {
    const enrollingProvider = response?.attributes.default_specialist;

    if (enrollingProvider) {
      return {
        label: enrollingProvider.name,
        value: enrollingProvider.name,
      };
    }
  })();

  const ccmComplexityOptions = useSelector(getCcmComplexityOptions);

  const ccmServiceId = useSelector(getCcmServiceId);
  const rpmServiceId = useSelector(getRpmServiceId);
  const bhiServiceId = useSelector(getBhiServiceId);

  useEffect(() => {
    getEntry();
  }, []);

  useEffect(() => {
    if (response) {
      setForm(response.attributes);
    }
  }, [response]);

  useEffect(() => {
    if (
      previousHealthSystemId &&
      previousHealthSystemId !== form.health_system_id
    ) {
      updateField('hospital_id', '');
    }

    if (previousHospitalId && previousHospitalId !== form.hospital_id) {
      updateField('practice_id', '');
    }

    if (previousPracticeId && previousPracticeId !== form.practice_id) {
      clearSpecialists();
    }
  }, [form.health_system_id, form.hospital_id, form.practice_id]);

  const [getEntry, isLoading] = useGetRequest({
    dispatch,
    id,
    model: 'patients',
    onSuccess: (res: any) => {
      const responseData = parseResponseData(res);

      const patient = flattenIncludes(
        responseData,
        ['address', 'emergency_contact', 'care_manager_index'],
        true,
      );

      const patientCareManager = getPatientCareManager(patient);

      patient.attributes.care_manager_id = patientCareManager
        ? patientCareManager.id
        : '';

      for (const service of serviceTags) {
        const patientService = patient.attributes.patient_services.data.find(
          s => s.attributes.name === service.toUpperCase(),
        );

        if (patientService) {
          patient.attributes[`${service}_enrollment_date`] =
            patientService.attributes.enrollment_date;
          patient.attributes[`${service}_complexity_id`] =
            patientService.attributes.ccm_complexity_id;
          patient.attributes['billing_cycle'] =
            patientService.attributes.billing_cycle;

          const billingCodes = patient.attributes.patient_services.included.filter(
            b =>
              b.attributes.service_id === patientService.attributes.service_id,
          );

          patient.attributes[`${service}_billing_code_ids`] = billingCodes.map(
            b => b.id,
          );
          patient.attributes[
            `initial_${service}_billing_code_ids`
          ] = billingCodes.map(b => formatSearchResultCode(b));
        } else {
          patient.attributes[`${service}_enrollment_date`] = '';
          patient.attributes[`${service}_complexity_id`] = '';
          patient.attributes[`${service}_billing_code_ids`] = [];
        }
      }

      setResponse(patient);
    },
  });

  const clearSpecialists = () => {
    updateField('default_specialist_id', '');
    updateField('specialist_ids', []);
  };

  const getPatientCareManager = patient => {
    return patient?.included?.find(
      include => include.type === 'care_manager_index',
    );
  };

  const handleFailure = (res: any) => {
    const errorMessage = parseErrorData(res);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const handleSuccess = (res: Response<Patient>) => {
    const patientId = res.data.id;
    const careManagementPath = buildPath(Routes.patientsCareManagement, {
      id: patientId,
    });
    const bhiPath = buildPath(Routes.patientsBhiPlan, { id: patientId });
    const patientAttributes = res.data.attributes;

    dispatch(refreshPatientAction(patientId));
    dispatch(flashSuccess('Patient edited'));

    if (
      patientAttributes.is_enrolled_ccm ||
      patientAttributes.is_enrolled_rpm
    ) {
      history.push(careManagementPath);
    } else {
      history.push(bhiPath);
    }
  };

  const handleDeleteSuccess = () => {
    const path = buildPath(Routes.patients);

    history.push(path);
  };

  const backPath = buildPath(Routes.patientsShow, { id });

  const formatBody = () => {
    const {
      address_city,
      address_line_1,
      address_line_2,
      address_state,
      address_zip_code,
      bhi_billing_code_ids,
      bhi_complexity_id,
      bhi_enrollment_date,
      billing_cycle,
      ccm_billing_code_ids,
      ccm_complexity_id,
      ccm_enrollment_date,
      emergency_contact_phone_number,
      is_enrolled_bhi,
      is_enrolled_ccm,
      is_enrolled_rpm,
      rpm_billing_code_ids,
      rpm_enrollment_date,
      ...formFields
    } = form;

    return {
      patient: {
        ...formFields,
        address_attributes: {
          line_1: address_line_1,
          line_2: address_line_2,
          city: address_city,
          state: address_state,
          zip_code: address_zip_code,
        },
        emergency_contact_attributes: {
          phone_number: emergency_contact_phone_number,
        },
      },
      services: {
        bhi_billing_code_ids,
        bhi_complexity_id,
        bhi_enrollment_date,
        billing_cycle,
        ccm_billing_code_ids,
        ccm_complexity_id,
        ccm_enrollment_date,
        is_enrolled_bhi,
        is_enrolled_ccm,
        is_enrolled_rpm,
        rpm_billing_code_ids,
        rpm_enrollment_date,
      },
    };
  };

  const rules = {
    care_manager_id: { required: true },
    date_of_birth: { required: true },
    email: { type: 'email', required: true },
    first_name: { required: true },
    health_system_id: { required: true },
    hospital_id: { required: true },
    insurance_carrier: { required: true },
    insurance_group_id: { required: true },
    insurance_member_id: { required: true },
    is_ccm: { required: true },
    last_name: { required: true },
    phone_number: { required: true },
    practice_id: { required: true },
  };

  const [submitForm, isSubmitting] = usePutRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: `api/v1/patients/${id}`,
  });

  const [deletePatient] = useDelRequest({
    dispatch,
    onSuccess: handleDeleteSuccess,
    url: `api/v1/patients/${id}`,
  });

  const [deactivatePatient, isDeactivating] = usePutRequest({
    body: {
      patient: {
        status: 'deactivated',
      },
    },
    dispatch,
    onSuccess: handleSuccess,
    onFailure: handleFailure,
    url: `api/v1/patients/${id}`,
  });

  const formatCareManagerResult = (result: any) => {
    return {
      label: fullName(result),
      value: result.id,
    };
  };

  const formatSpecialistResult = (result: any) => {
    return {
      label: result.attributes.name,
      value: result.id,
    };
  };

  const handleClickDelete = () => {
    setIsConfirmDeleteModalVisible(true);
  };

  const handleCloseDelete = () => {
    setIsConfirmDeleteModalVisible(false);
  };

  const handleDeletePatient = () => {
    deletePatient();
  };

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);
    let isCustomValid = true;

    if (
      !form.is_enrolled_ccm &&
      !form.is_enrolled_rpm &&
      !form.is_enrolled_bhi
    ) {
      isCustomValid = false;

      for (const service of serviceTags) {
        Object.assign(validationMessages, {
          [`is_enrolled_${service}`]: 'At least one service must be selected',
        });
      }
    }

    for (const service of serviceTags) {
      if (form[`is_enrolled_${service}`]) {
        if (!form[`${service}_complexity_id`] && service !== 'rpm') {
          isCustomValid = false;

          Object.assign(validationMessages, {
            [`${service}_complexity_id`]: 'Field is required',
          });
        }
      }
    }

    if (isValid && isCustomValid) {
      setValidations({});
    } else {
      scrollToTop();
      dispatch(
        flashError(
          'Update patient error. Please complete the required fields.',
        ),
      );
      return setValidations(validationMessages);
    }

    submitForm();
  };

  const currentUser = useSelector(getCurrentUser);

  const disableDeleteButton = () => {
    if (currentUser.data.attributes.user_role == 'care_manager') {
      return false;
    }else{
      return true;
    }
  };

  const handleDeactivatePatient = () => {
    if (isDeactivating) {
      return;
    }

    deactivatePatient();
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const careManagerQueryParams = {
    health_system_id: form.health_system_id,
    hospital_id: form.hospital_id,
    practice_id: form.practice_id,
  };

  const specialistsQueryParams = {
    practice_id: form.practice_id,
  };

  if (isLoading || !response) {
    return <ActivityIndicator />;
  }

  


  return (
    <>
      <div className="patient-new__wrapper">
        <Container>
          <BackLink to={backPath}>Back to Patient</BackLink>
        </Container>

        <Constraint size="large">
          <Panel>
            <Section title="Patient Details">
              <div className="columns">
                <div className="column is-4">
                  <InputGroup
                    label="First Name"
                    onChange={value => updateField('first_name', value)}
                    placeholder="First name"
                    validationMessage={validations.first_name}
                    value={form.first_name}
                  />
                </div>

                <div className="column is-4">
                  <InputGroup
                    label="Last Name"
                    onChange={value => updateField('last_name', value)}
                    placeholder="Last name"
                    validationMessage={validations.last_name}
                    value={form.last_name}
                  />
                </div>

                <div className="column is-4">
                  <InputGroup
                    label="EMR"
                    onChange={value => updateField('emr', value)}
                    placeholder="EMR"
                    validationMessage={validations.emr}
                    value={form.emr}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <InputGroup
                    label="Middle name (optional)"
                    onChange={value => updateField('middle_name', value)}
                    placeholder="Middle name"
                    value={form.middle_name}
                  />
                </div>

                <div className="column is-4">
                  <InputGroup
                    label="Preferred name (optional)"
                    onChange={value => updateField('preferred_name', value)}
                    placeholder="Preferred name"
                    value={form.preferred_name}
                  />
                </div>

                <div className="column is-2">
                  <SelectGroup
                    label="Title (optional)"
                    onChange={value => updateField('title', value)}
                    options={titleOptions}
                    value={form.title}
                  />
                </div>

                <div className="column is-2">
                  <SelectGroup
                    label="Suffix (optional)"
                    onChange={value => updateField('suffix', value)}
                    options={suffixOptions}
                    value={form.suffix}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <SelectGroup
                    label="Gender"
                    onChange={value => updateField('gender', value)}
                    options={genderOptions}
                    validationMessage={validations.gender}
                    value={form.gender}
                  />
                </div>

                <div className="column is-4">
                  <Datepicker
                    label="Date of Birth"
                    onChange={value => updateField('date_of_birth', value)}
                    validationMessage={validations.date_of_birth}
                    value={form.date_of_birth}
                  />
                </div>

                <div className="column is-4">
                  <SelectGroup
                    label="Language"
                    onChange={value => updateField('language', value)}
                    options={languageOptions()}
                    validationMessage={validations.language}
                    value={form.language}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel>
            <Section title="Platform Details">
              <div className="columns">
                <div className="column is-4">
                  <PatientPassword patient={response} />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel>
            <Section title="Contact">
              <div className="columns">
                <div className="column">
                  <InputGroup
                    label="Email Address"
                    onChange={value => updateField('email', value)}
                    placeholder="Email"
                    validationMessage={validations.email}
                    value={form.email}
                  />
                </div>

                <div className="column">
                  <InputGroup
                    label="Phone Number"
                    onChange={value => updateField('phone_number', value)}
                    placeholder="Phone Number"
                    type="phonenumber"
                    validationMessage={validations.phone_number}
                    value={form.phone_number}
                  />
                </div>

                <div className="column">
                  <InputGroup
                    label="Emergency Phone Number"
                    onChange={value =>
                      updateField('emergency_contact_phone_number', value)
                    }
                    placeholder="Emergency Phone Number"
                    type="phonenumber"
                    validationMessage={
                      validations.emergency_contact_phone_number
                    }
                    value={form.emergency_contact_phone_number}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel>
            <Section title="Address">
              <div className="columns">
                <div className="column is-4">
                  <InputGroup
                    label="Street Address"
                    onChange={value => updateField('address_line_1', value)}
                    placeholder="Street Address"
                    validationMessage={validations.address_line_1}
                    value={form.address_line_1}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <InputGroup
                    label="Address Line 2"
                    onChange={value => updateField('address_line_2', value)}
                    placeholder="Address Line 2"
                    validationMessage={validations.address_line_2}
                    value={form.address_line_2}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <InputGroup
                    label="City"
                    onChange={value => updateField('address_city', value)}
                    placeholder="City"
                    validationMessage={validations.address_city}
                    value={form.address_city}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-2">
                  <SelectGroup
                    label="State"
                    onChange={value => updateField('address_state', value)}
                    options={stateOptions}
                    validationMessage={validations.address_state}
                    value={form.address_state}
                  />
                </div>

                <div className="column is-2">
                  <InputGroup
                    label="Zip Code"
                    onChange={value => updateField('address_zip_code', value)}
                    placeholder="Zip Code"
                    type="zipcode"
                    validationMessage={validations.address_zip_code}
                    value={form.address_zip_code}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel>
            <Section title="Care Details">
              <div className="columns">
                <div className="column is-4">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatSearchResult}
                    guideValue={form.health_system_id}
                    initialSelection={{
                      label: healthSystem.attributes.name,
                      value: healthSystem.id,
                    }}
                    label="Health System"
                    minimumInputLength={1}
                    onChange={value => updateField('health_system_id', value)}
                    placeholder="Search Health Systems"
                    searchPath="api/v1/health_systems"
                    validationMessage={validations.health_system_id}
                  />
                </div>

                <div className="column is-4">
                  <SearchGroup
                    clearFalseResultOnBlur
                    guideValue={form.hospital_id}
                    formatResult={formatSearchResult}
                    initialSelection={{
                      label: hospital.attributes.name,
                      value: hospital.id,
                    }}
                    isDisabled={!form.health_system_id}
                    label="Hospital"
                    minimumInputLength={1}
                    onChange={value => updateField('hospital_id', value)}
                    placeholder="Search Hospitals"
                    queryParams={{ health_system_id: form.health_system_id }}
                    searchPath="api/v1/hospitals"
                    validationMessage={validations.hospital_id}
                  />
                </div>

                <div className="column is-4">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatSearchResult}
                    guideValue={form.practice_id}
                    initialSelection={{
                      label: practice.attributes.name,
                      value: practice.id,
                    }}
                    isDisabled={!form.hospital_id}
                    label="Practice"
                    minimumInputLength={1}
                    onChange={value => updateField('practice_id', value)}
                    placeholder="Search Practices"
                    queryParams={{ hospital_id: form.hospital_id }}
                    searchPath="api/v1/practices"
                    validationMessage={validations.practice_id}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatCareManagerResult}
                    guideValue={form.care_manager_id}
                    initialSelection={{
                      label: fullName(careManager),
                      value: careManager.id,
                    }}
                    label="Initial Care Manager"
                    minimumInputLength={1}
                    onChange={value => updateField('care_manager_id', value)}
                    placeholder="Search Care Managers"
                    queryParams={careManagerQueryParams}
                    searchPath="api/v1/care_managers"
                    validationMessage={validations.care_manager_id}
                  />
                </div>

                <div className="column is-4">
                  <SearchGroup
                    clearFalseResultOnBlur
                    formatResult={formatSpecialistResult}
                    isDisabled={!form.practice_id}
                    guideValue={form.default_specialist_id}
                    initialSelection={enrollingProviderSelection}
                    label="Enrolling Provider (optional)"
                    minimumInputLength={0}
                    onChange={value =>
                      updateField('default_specialist_id', value)
                    }
                    placeholder="Search Providers"
                    queryParams={specialistsQueryParams}
                    searchPath="api/v1/specialists"
                    validationMessage={validations.default_specialist_id}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel>
            <Section title="Insurance Information">
              <div className="columns">
                <div className="column is-4">
                  <InputGroup
                    label="Carrier"
                    onChange={value => updateField('insurance_carrier', value)}
                    placeholder="Carrier"
                    validationMessage={validations.insurance_carrier}
                    value={form.insurance_carrier}
                  />
                </div>

                <div className="column is-4">
                  <InputGroup
                    label="Group ID"
                    onChange={value => updateField('insurance_group_id', value)}
                    placeholder="Group ID"
                    validationMessage={validations.insurance_group_id}
                    value={form.insurance_group_id}
                  />
                </div>

                <div className="column is-4">
                  <InputGroup
                    label="Member ID"
                    onChange={value =>
                      updateField('insurance_member_id', value)
                    }
                    placeholder="Member ID"
                    validationMessage={validations.insurance_member_id}
                    value={form.insurance_member_id}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel>
            <Section title="Notes">
              <div className="columns">
                <div className="column is-12">
                  <TextAreaGroup
                    label="Patient Notes"
                    onChange={value => updateField('patient_notes', value)}
                    validationMessage={validations.patient_notes}
                    value={form.patient_notes}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel>
            <Section title="Protocol">
              <div className="columns">
                <div className="column is-4">
                  <SelectGroup
                    label="Patient Status"
                    onChange={value => updateField('status', value)}
                    options={patientStatuses}
                    validationMessage={validations.status}
                    value={form.status}
                  />
                </div>

                <div className="column is-4 is-flex">
                  <CheckboxGroup
                    label="Testimonial Worthy"
                    onChange={value =>
                      updateField('is_testimonial_worthy', value)
                    }
                    value={form.is_testimonial_worthy}
                  />
                </div>
              </div>

              <div className="columns has-no-bottom-margin">
                <div className="column is-4">
                  <CheckboxGroup
                    label="Enroll in CCM"
                    onChange={value => updateField('is_enrolled_ccm', value)}
                    validationMessage={validations.is_enrolled_ccm}
                    value={form.is_enrolled_ccm}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <SelectGroup
                    isDisabled={!form.is_enrolled_ccm}
                    label="CCM Complexity"
                    onChange={value => updateField('ccm_complexity_id', value)}
                    options={ccmComplexityOptions}
                    validationMessage={validations.ccm_complexity_id}
                    value={form.ccm_complexity_id}
                  />
                </div>

                <div className="column is-4">
                  <TypeaheadSelectGroup
                    initialValue={form.initial_ccm_billing_code_ids}
                    isDisabled={!form.is_enrolled_ccm}
                    label="CCM Billing Codes"
                    maxSelections={3}
                    minimumInputLength={0}
                    onChange={value =>
                      updateField('ccm_billing_code_ids', value)
                    }
                    queryParams={{ service_id: ccmServiceId }}
                    searchPath="api/v1/billing_codes"
                    formatResult={formatSearchResultCode}
                    value={form.ccm_billing_code_ids}
                  />
                </div>

                <div className="column is-4">
                  <Datepicker
                    isDisabled={!form.is_enrolled_ccm}
                    label="Enrollment Date"
                    onChange={value =>
                      updateField('ccm_enrollment_date', value)
                    }
                    validationMessage={validations.ccm_enrollment_date}
                    value={form.ccm_enrollment_date}
                  />
                </div>
              </div>

              <div className="columns has-no-bottom-margin">
                <div className="column is-4">
                  <CheckboxGroup
                    label="Enroll in RPM"
                    onChange={value => updateField('is_enrolled_rpm', value)}
                    validationMessage={validations.is_enrolled_rpm}
                    value={form.is_enrolled_rpm}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <TypeaheadSelectGroup
                    initialValue={form.initial_rpm_billing_code_ids}
                    isDisabled={!form.is_enrolled_rpm}
                    label="RPM Complexity"
                    maxSelections={3}
                    minimumInputLength={0}
                    onChange={value =>
                      updateField('rpm_billing_code_ids', value)
                    }
                    queryParams={{ service_id: rpmServiceId }}
                    searchPath="api/v1/billing_codes"
                    formatResult={formatSearchResultCode}
                    value={form.rpm_billing_code_ids}
                  />
                </div>

                <div className="column is-4">
                  <Datepicker
                    isDisabled={!form.is_enrolled_rpm}
                    label="Enrollment Date"
                    onChange={value =>
                      updateField('rpm_enrollment_date', value)
                    }
                    validationMessage={validations.rpm_enrollment_date}
                    value={form.rpm_enrollment_date}
                  />
                </div>

                <div className="column is-4">
                  <Datepicker
                    label="Billing Cycle"
                    onChange={value => updateField('billing_cycle', value)}
                    validationMessage={validations.billing_cycle}
                    value={form.billing_cycle}
                  />
                </div>
              </div>

              <div className="columns has-no-bottom-margin">
                <div className="column is-4">
                  <CheckboxGroup
                    label="Enroll in BHI"
                    onChange={value => updateField('is_enrolled_bhi', value)}
                    validationMessage={validations.is_enrolled_bhi}
                    value={form.is_enrolled_bhi}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <SelectGroup
                    isDisabled={!form.is_enrolled_bhi}
                    label="BHI Complexity"
                    onChange={value => updateField('bhi_complexity_id', value)}
                    options={ccmComplexityOptions}
                    validationMessage={validations.bhi_complexity_id}
                    value={form.bhi_complexity_id}
                  />
                </div>

                <div className="column is-4">
                  <TypeaheadSelectGroup
                    initialValue={form.initial_bhi_billing_code_ids}
                    isDisabled={!form.is_enrolled_bhi}
                    label="BHI Billing Codes"
                    maxSelections={3}
                    minimumInputLength={0}
                    onChange={value =>
                      updateField('bhi_billing_code_ids', value)
                    }
                    queryParams={{ service_id: bhiServiceId }}
                    searchPath="api/v1/billing_codes"
                    formatResult={formatSearchResultCode}
                    value={form.bhi_billing_code_ids}
                  />
                </div>

                <div className="column is-4">
                  <Datepicker
                    isDisabled={!form.is_enrolled_bhi}
                    label="Enrollment Date"
                    onChange={value =>
                      updateField('bhi_enrollment_date', value)
                    }
                    validationMessage={validations.bhi_enrollment_date}
                    value={form.bhi_enrollment_date}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <ErrorMessage isRightSide message={error} />

          <div className="form__actions">
            <div className="form__actions-left patient-edit__button">
            <Button
                style="delete"
                onClick={handleClickDelete}
                isDisabled={!disableDeleteButton()}>
                Delete Patient
              </Button>
            </div>
            <div className="form__actions-right">
              <Button
                color="white"
                isSubmitting={isDeactivating}
                onClick={handleDeactivatePatient}>
                Deactivate Patient
              </Button>

              <Button
                color="secondary"
                isSubmitting={isSubmitting}
                onClick={handleClickSubmit}>
                Update Patient
              </Button>
            </div>
          </div>
        </Constraint>
      </div>

      <ConfirmDeleteModal
        isVisible={isConfirmDeleteModalVisible}
        onClose={handleCloseDelete}
        onAccept={handleDeletePatient}
      />
    </>
  );
};

export default PatientsEdit;