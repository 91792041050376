import React from 'react';

import Icon from '@/components/icon/Icon';
import Panel from '@/components/panel/Panel';
import { Sidebar, SidebarLink } from '@/components/sidebar';
import { getSelectedPatientEnrollmentStatus } from '@/selectors/api';
import { Patient } from '@/types/patient';
import { buildPath, Routes } from '@/utils/routeUtils';
import { breakpoints } from '@/utils/screenUtils';
import { titleizeSnakeCase } from '@/utils/stringUtils';
import { useResponsive } from 'react-hooks-responsive';
import { useSelector } from 'react-redux';

interface Props {
  patient: Patient;
}

const PatientSidebar = (props: Props) => {
  const { patient } = props;

  const { screenIsAtMost } = useResponsive(breakpoints);
  const isTouch = screenIsAtMost('tablet');

  const status = useSelector(getSelectedPatientEnrollmentStatus);
  const isDisabled = status !== 'complete';

  const id = patient.id;

  const isCcm = patient.attributes.is_enrolled_ccm;
  const isRpm = patient.attributes.is_enrolled_rpm;
  const isBhi = patient.attributes.is_enrolled_bhi;

  const renderResources = () => {
    if (!isCcm && !isRpm) {
      return null;
    }

    const resources = [
      'conditions',
      'symptoms',
      'goals',
      'barriers',
      'outcomes',
      'metrics',
    ];

    return resources.map((resource: string) => {
      return (
        <SidebarLink
          isHidden={!isCcm && !isRpm}
          key={resource}
          to={buildPath(Routes.patientsResource, { id, resource })}
          isDisabled={isDisabled}>
          {resource === 'outcomes'
            ? 'Expected Outcomes '
            : titleizeSnakeCase(resource)}
        </SidebarLink>
      );
    });
  };

  const renderCarePlanLink = () => {
    if (isDisabled) {
      return <div className="sidebar__link is-disabled">Care Plan</div>;
    }

    return (
      <a
        className="sidebar__link"
        href={buildPath(Routes.patientsCarePlan, { id })}
        target="_blank">
        <span>Care Plan</span>

        <div className="display-inactive">
          <Icon icon="caretRight" />
        </div>
      </a>
    );
  };

  const sidebarLinks = () => {
    return (
      <Sidebar>
        <SidebarLink
          isHidden={!isRpm}
          to={buildPath(Routes.patientsCareManagementRpm, { id })}
          isDisabled={isDisabled}>
          RPM Care Management
        </SidebarLink>

        <SidebarLink
          isHidden={!isCcm}
          to={buildPath(Routes.patientsCareManagement, { id })}
          isDisabled={isDisabled}>
          CCM Care Management
        </SidebarLink>

        <SidebarLink
          isHidden={!isBhi}
          to={buildPath(Routes.patientsBhiPlan, { id })}
          isDisabled={isDisabled}>
          BHI Plan
        </SidebarLink>

        <SidebarLink
          isHidden={!isRpm}
          to={buildPath(Routes.patientsRemotePatientCare, { id })}
          isDisabled={isDisabled}>
          Remote Patient Care
        </SidebarLink>

        <SidebarLink
          isHidden={!isCcm && !isRpm}
          to={buildPath(Routes.patientsMedicalRecords, { id })}
          isDisabled={isDisabled}>
          Medical Records
        </SidebarLink>

        <SidebarLink
          isHidden={!isCcm && !isRpm}
          to={buildPath(Routes.patientsMedications, { id })}
          isDisabled={isDisabled}>
          Medications
        </SidebarLink>

        <SidebarLink
          isHidden={!isCcm && !isRpm}
          to={buildPath(Routes.patientsAllergies, { id })}
          isDisabled={isDisabled}>
          Allergies
        </SidebarLink>

        <SidebarLink
          isHidden={!isCcm && !isRpm}
          to={buildPath(Routes.patientsLabs, { id })}
          isDisabled={isDisabled}>
          Labs
        </SidebarLink>

        <SidebarLink
          isHidden={!isCcm && !isRpm}
          to={buildPath(Routes.patientsAppointments, { id })}
          isDisabled={isDisabled}>
          Appointments
        </SidebarLink>

        <SidebarLink
          isHidden={!isCcm && !isRpm}
          to={buildPath(Routes.patientsImmunizations, { id })}
          isDisabled={isDisabled}>
          Immunizations
        </SidebarLink>

        <SidebarLink
          isHidden={!isCcm && !isBhi && !isRpm}
          to={buildPath(Routes.patientsSpecialists, { id })}
          isDisabled={isDisabled}>
          Specialists
        </SidebarLink>

        <SidebarLink
          isHidden={!isCcm && !isBhi && !isRpm}
          to={buildPath(Routes.patientsContacts, { id })}
          isDisabled={isDisabled}>
          Family Contacts
        </SidebarLink>

        {renderResources()}

        {renderCarePlanLink()}

        <SidebarLink
          isHidden={!isCcm && !isRpm}
          to={buildPath(Routes.patientsActionHistory, { id })}
          isDisabled={isDisabled}>
          Action History
        </SidebarLink>

        <SidebarLink
          isHidden={!isCcm && !isRpm}
          to={buildPath(Routes.patientsAgreements, { id })}
          isDisabled={isDisabled}>
          Agreements
        </SidebarLink>
      </Sidebar>
    );
  };

  if (isTouch) {
    return <div className="sidebar__touch">{sidebarLinks()}</div>;
  }

  return <Panel withNarrowPadding>{sidebarLinks()}</Panel>;
};

export default PatientSidebar;
