import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { flashError, flashSuccess } from '@/actions/sagas/messageSaga';
import BackLink from '@/components/button/BackLink';
import Button from '@/components/button/Button';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import ErrorMessage from '@/components/form/ErrorMessage';
import ImagePreviewUpload from '@/components/form/ImagePreviewUpload';
import InputGroup from '@/components/form/InputGroup';
import SearchGroup from '@/components/form/SearchGroup';
import Section from '@/components/form/Section';
import SelectGroup from '@/components/form/SelectGroup';
import Modal from '@/components/modal/Modal';
import Panel from '@/components/panel/Panel';
import { getCallerIdOptions } from '@/selectors/options';
import { Validations } from '@/types/form';
import usePostRequest from '@/utils/api/usePostRequest';
import { parseErrorData } from '@/utils/apiUtils';
import { updateArrayIndex } from '@/utils/arrayUtils';
import { statusOptions } from '@/utils/dropdownUtils';
import { timeOptions } from '@/utils/dropdownUtils';
import { validate } from '@/utils/form';
import { buildPath, Routes } from '@/utils/routeUtils';
import { usePrevious } from '@/utils/stateUtils';
import HealthSystemsNew from '../health_systems/HealthSystemsNew';
import AddressPanel from './components/AddressPanel';
import HospitalModal from './components/HospitalModal';
import ReadingThresholdList from './components/ReadingThresholdList';

const blankAddress = {
  label: '',
  line_1: '',
  line_2: '',
  city: '',
  state: '',
  zip_code: '',
  status: 'active',
};

const FORM_STATE = {
  addresses: [blankAddress],
  caller_id_id: '',
  health_system_id: '',
  hospital_id: '',
  image_id: '',
  name: '',
  status: 'active',
  twillio_phone_number: '',
  time_zone:'',
  reading_thresholds: [],
};

const PracticesNew = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const location = useLocation();

  const [error, setError] = useState('');
  const [form, setForm] = useState(FORM_STATE);
  const [validations, setValidations] = useState<Validations>({});
  const [addressValidations, setAddressValidations] = useState<Validations[]>([
    {},
  ]);

  const callerIdOptions = useSelector(getCallerIdOptions);

  const backPath = buildPath(Routes.practices);

  const previousHealthSystemId = usePrevious(form.health_system_id);

  useEffect(() => {
    if (
      previousHealthSystemId &&
      previousHealthSystemId !== form.health_system_id
    ) {
      updateField('hospital_id', '');
    }
  }, [form.health_system_id]);

  const rules = {
    name: { required: true },
    twillio_phone_number: { required: true, type: 'phone' },
    health_system_id: { required: true },
    time_zone: { required: true },
    hospital_id: { required: true },
    status: { required: true },
  };

  const addressRules = {
    line_1: { required: true },
    city: { required: true },
    state: { required: true },
    zip_code: { required: true },
  };

  const handleCloseModal = () => {
    const url = buildPath(Routes.practicesNew);

    history.push(url);
  };

  const handleClickHealthSystem = () => {
    const url = buildPath(Routes.practicesHealthSystem);

    history.push(url);
  };

  const handleClickHospital = () => {
    const url = buildPath(Routes.practicesHospital);

    history.push(url);
  };

  const handleFailure = (response: any) => {
    const errorMessage = parseErrorData(response);

    dispatch(flashError(errorMessage));
    setError(errorMessage || '');
  };

  const handleSuccess = () => {
    dispatch(flashSuccess('Practice added'));

    history.push(backPath);
  };

  const formatBody = () => {
    return {
      practice: {
        name: form.name,
        twillio_phone_number: form.twillio_phone_number,
        health_system_id: form.health_system_id,
        hospital_id: form.hospital_id,
        image_id: form.image_id,
        status: form.status,
        time_zone:form.time_zone,
        addresses_attributes: form.addresses,
        reading_thresholds_attributes: form.reading_thresholds,
      },
      caller_id_id: form.caller_id_id,
    };
  };

  const [submitForm, isSubmitting] = usePostRequest({
    body: formatBody(),
    dispatch,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    url: 'api/v1/practices',
  });

  const formatSearchResult = (result: any) => ({
    label: result.attributes.name,
    value: result.id,
  });

  const handleClickCancel = () => {
    history.push(backPath);
  };

  const handleClickSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const [isValid, validationMessages] = validate(form, rules);

    const addressValidationMessages = form.addresses.map(address =>
      validate(address, addressRules),
    );

    const areAddressesInvalid = addressValidationMessages.find(
      validation => validation[0] === false,
    );

    if (isValid && !areAddressesInvalid) {
      setValidations({});
      submitForm();
    } else {
      setValidations(validationMessages);
      setAddressValidations(
        addressValidationMessages.map(validation => validation[1]),
      );
    }
  };

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const updateAddressField = (index: number, field: string, value: any) => {
    const formAddresses = [...form.addresses];
    const address = formAddresses[index];

    const updatedAddress = {
      ...address,
      [field]: value,
    };

    const updatedForm = {
      ...form,
      addresses: updateArrayIndex(form.addresses, index, updatedAddress),
    };

    setForm(updatedForm);
  };

  const handleAddNewLocation = () => {
    setForm({
      ...form,
      addresses: [...form.addresses, blankAddress],
    });
  };

  const renderModals = () => {
    const modalBackPath = buildPath(Routes.practicesNew);

    if (location.pathname.includes(`health_systems`)) {
      return (
        <Modal isVisible={true} onCloseModal={handleCloseModal}>
          <div className="practice-form__modal">
            <h3 className="practice-form__modal-header">
              Add New Health System
            </h3>
            <HealthSystemsNew hidePanel isModal modalBackPath={modalBackPath} />
          </div>
        </Modal>
      );
    } else if (location.pathname.includes(`hospitals`)) {
      return (
        <Modal isVisible={true} onCloseModal={handleCloseModal}>
          <div className="practice-form__modal">
            <h3 className="practice-form__modal-header">Add New Hospital</h3>
            <HospitalModal backPath={modalBackPath} />
          </div>
        </Modal>
      );
    }
  };

  return (
    <>
      <div className="practice-form__wrapper">
        <Container>
          <BackLink to={backPath}>Cancel</BackLink>
        </Container>

        <Constraint size="large">
          <Panel withNarrowMargin>
            <Section title="Practice Details">
              <div className="columns is-mobile practice-form__details-wrapper">
                <div className="column is-8-desktop">
                  <div className="columns practice-form__search-group-wrapper">
                    <div className="column is-12-mobile is-6-tablet practice-form__add-new-wrapper">
                      <SearchGroup
                        clearFalseResultOnBlur
                        formatResult={formatSearchResult}
                        guideValue={form.health_system_id}
                        label="Health System"
                        minimumInputLength={1}
                        onChange={value =>
                          updateField('health_system_id', value)
                        }
                        placeholder="Search Health Systems"
                        searchPath="api/v1/health_systems"
                        validationMessage={validations.health_system_id}
                      />

                      <div className="practice-form__add-new-button">
                        <Button
                          style="flat-large add"
                          onClick={handleClickHealthSystem}>
                          Add to New Health System
                        </Button>
                      </div>
                    </div>

                    <div className="column is-12-mobile is-6-tablet practice-form__add-new-wrapper">
                      <SearchGroup
                        clearFalseResultOnBlur
                        isDisabled={!form.health_system_id}
                        guideValue={form.hospital_id}
                        formatResult={formatSearchResult}
                        label="Hospital"
                        minimumInputLength={1}
                        onChange={value => updateField('hospital_id', value)}
                        placeholder="Search Hospitals"
                        queryParams={{
                          health_system_id: form.health_system_id,
                        }}
                        searchPath="api/v1/hospitals"
                        validationMessage={validations.hospital_id}
                      />

                      <div className="practice-form__add-new-button">
                        <Button
                          style="flat-large add"
                          onClick={handleClickHospital}>
                          Add to New Hospital
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column is-12-mobile is-6-tablet is-4-desktop">
                  <InputGroup
                    label="Practice Name"
                    onChange={value => updateField('name', value)}
                    placeholder="Enter Practice Name"
                    validationMessage={validations.name}
                    value={form.name}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <ImagePreviewUpload
                    label="Logo"
                    onChange={(value: string) => updateField('image_id', value)}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel withNarrowMargin>
            <Section title="Status">
              <div className="columns is-mobile">
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Status"
                    onChange={value => updateField('status', value)}
                    options={statusOptions}
                    validationMessage={validations.status}
                    value={form.status}
                  />
                </div>
              </div>
            </Section>
          </Panel>

          <Panel withNarrowMargin>
            <Section title="Contact Details">
              <div className="columns is-mobile">
                <div className="column is-6-tablet is-4-desktop">
                  <InputGroup
                    label="Phone Number"
                    onChange={value =>
                      updateField('twillio_phone_number', value)
                    }
                    placeholder="Enter phone number"
                    validationMessage={validations.twillio_phone_number}
                    value={form.twillio_phone_number}
                  />
                </div>

                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Time Zone"
                    onChange={value => updateField('time_zone', value)}
                    placeholder="Enter Time Zone"
                    options={timeOptions}
                    shouldAllowNull
                    validationMessage={validations.time_zone}
                    value={form.time_zone}
                  />
                </div>
                <div className="column is-6-tablet is-4-desktop">
                  <SelectGroup
                    label="Caller ID (optional)"
                    onChange={value => updateField('caller_id_id', value)}
                    options={callerIdOptions}
                    shouldAllowNull
                    validationMessage={validations.caller_id_id}
                    value={form.caller_id_id}
                  />
                </div>
                
              </div>
              
            </Section>
          </Panel>

          <Panel>
            <Section title="Alert Levels">
              <ReadingThresholdList
                onUpdateThresholds={value =>
                  updateField('reading_thresholds', value)
                }
                readingThresholds={form.reading_thresholds}
              />
            </Section>
          </Panel>

          <AddressPanel
            addresses={form.addresses}
            onUpdateAddressField={updateAddressField}
            addressValidations={addressValidations}
          />

          <div className="practice-form__location-button-wrapper">
            <Button style="flat-large add" onClick={handleAddNewLocation}>
              Add Location
            </Button>
          </div>

          <ErrorMessage isRightSide message={error} />

          <div className="form__actions">
            <div className="form__actions-right">
              <Button color="white" onClick={handleClickCancel}>
                Cancel
              </Button>

              <Button color="secondary" onClick={handleClickSubmit}>
                Save
              </Button>
            </div>
          </div>
        </Constraint>
      </div>

      {renderModals()}
    </>
  );
};

export default PracticesNew;
