import { Patient } from '@/types/patient';
import { Patient as V2Patient } from '@/types/v2/patient';

const statusDescriptions = [
  'Active',
  'Deactivated',
  'New Enrollment',
  'On Hold',
  'Ineligible Condition',
  'Ineligible Insurance',
  'Patient Deceased',
  'Not Recommended',
  'Declined',
  'Considering',
  'Switched PCP',
  'On Hold - not answering phone',
  'On Hold - not using device',
  'Device Issue',
];

export const patientStatusEnumOptions = statusDescriptions.map(
  (description: string, index: number) => ({
    label: description,
    value: index.toString(),
  }),
);

export const displayPatientStatus = (status: number = 0) => {
  if (status < 0 || status > 15) {
    throw new Error('Invalid status option');
  }

  return statusDescriptions[status];
};

/**
 * Returns the full formatted name including title, suffix, and preferred name
 * @param  {Patient} patient
 * @returns string
 */

export const longName = (patient: any): string => {
  const a = patient.attributes;

  const middleInitial = a.middle_name ? `${a.middle_name.slice(0, 1)}.` : null;

  const names = [a.title, a.first_name, middleInitial, a.last_name];

  if (a.suffix) {
    names.push(`, ${a.suffix}`);
  }

  if (a.preferred_name) {
    names.push(`(${a.preferred_name})`);
  }

  return names
    .filter(n => n)
    .join(' ')
    .replace(' ,', ',');
};

export const modeledLongName = (patient: V2Patient): string => {
  const a = patient;

  const middleInitial = a.middle_name ? `${a.middle_name.slice(0, 1)}.` : null;

  const names = [a.title, a.first_name, middleInitial, a.last_name];

  if (a.suffix) {
    names.push(`, ${a.suffix}`);
  }

  if (a.preferred_name) {
    names.push(`(${a.preferred_name})`);
  }

  return names
    .filter(n => n)
    .join(' ')
    .replace(' ,', ',');
};
