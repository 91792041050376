import React from 'react';

import { MissedCall } from '@/types/v2/missed_call';
import UserMissedCall from './UserMissedCall';

interface Props {
  missedCalls: MissedCall[];
}

const UserMissedCalls = ({ missedCalls }: Props) => {
  if (!missedCalls.length) {
    return null;
  }

  const renderMissedCalls = (() => {
    return missedCalls.map((missedCall: MissedCall) => {
      return <UserMissedCall missedCall={missedCall} key={missedCall.id} />;
    });
  })();

  return (
    <>
      <h3 className="dashboard-header">Missed Calls</h3>
      {renderMissedCalls}
    </>
  );
};

export default UserMissedCalls;
