import React from 'react';

import Button from '@/components/button/Button';
import {
  isJoinable,
  isViewable,
  VirtualAppointment,
} from '@/types/v2/virtual_appointment';
import { formatTime } from '@/utils/timeUtils';

interface Props {
  appointment: VirtualAppointment;
  isLoading?: boolean;
  onClickJoin?: () => void;
}

const AppointmentTime = ({
  appointment,
  isLoading,
  onClickJoin,
}: Props): JSX.Element => {
  const noticeText = (() => {
    if (onClickJoin) {
      return 'The patient will be able to join once you are in the appointment.';
    }

    return 'You will automatically join when the provider begins the appointment.';
  })();

  const renderJoinButton = (() => {
    const shouldDisplayJoinButton = isViewable(appointment);
    const isJoinButtonDisabled = !isJoinable(appointment);

    if (onClickJoin && shouldDisplayJoinButton) {
      return (
        <div className="appointment-time__join">
          <Button
            color="secondary"
            isDisabled={isLoading || isJoinButtonDisabled}
            onClick={onClickJoin}>
            Join
          </Button>
        </div>
      );
    }
  })();

  return (
    <>
      <div className="columns">
        <div className="column has-text-centered">
          <h4 className="appointment-time__header">
            The appointment begins at
          </h4>
        </div>
      </div>

      <div className="columns">
        <div className="column has-text-centered">
          <h3 className="appointment-time__time">
            {formatTime(appointment.scheduled_start_at)}
          </h3>
        </div>
      </div>

      <div className="columns">
        <div className="column has-text-centered">
          {renderJoinButton}
          <p className="appointment-time__label">{noticeText}</p>
        </div>
      </div>
    </>
  );
};

export default AppointmentTime;
