import React, { useMemo } from 'react';

import Button from '@/components/button/Button';
import Icon from '@/components/icon/Icon';
import { UUID } from '@/types/generic';
import { VirtualAppointmentQuestionnaire } from '@/types/v2/questionnaire';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import { formatShortDate } from '@/utils/dateUtils';
import { formatTime } from '@/utils/timeUtils';

interface Props {
  appointment: VirtualAppointment;
  onCompleteQuestionnaire: (appointmentId: UUID) => void;
  onConfirmAppointment: (appointment: VirtualAppointment) => void;
  onViewDetails: (appointment: VirtualAppointment) => void;
}

const AppointmentCard = (props: Props): JSX.Element => {
  const {
    appointment,
    onCompleteQuestionnaire,
    onConfirmAppointment,
    onViewDetails,
  } = props;

  const isPending: boolean = appointment.appointment_status === 'created';
  const isConfirmed: boolean = appointment.appointment_status === 'confirmed';
  const isUpcoming: boolean = isPending || isConfirmed;
  const needsPatientConfirmation: boolean =
    isPending && !appointment.patient_confirmed_at;
  const needsSpecialistConfirmation: boolean =
    appointment.patient_confirmed_at && !appointment.specialist_confirmed_at;

  const incompleteQuestionnaire = useMemo(() => {
    return appointment.virtual_appointment_questionnaires.filter(
      (questionnaire: VirtualAppointmentQuestionnaire) =>
        questionnaire.status !== 'completed',
    )[0];
  }, [appointment]);

  const formatDateAndTime = (date: string): string => {
    return [
      formatShortDate(appointment.scheduled_start_at),
      formatTime(appointment.scheduled_start_at),
    ]
      .filter(n => n)
      .join(' at ');
  };

  const renderStatus = (): string => {
    switch (appointment.appointment_status) {
      case 'created':
        return 'Pending';

      case 'confirmed':
        return 'Confirmed';

      case 'completed':
        return 'Completed';

      case 'cancelled':
        return 'Cancelled';

      case 'started':
        return 'Started';
    }
  };

  const renderAlertText = (): string => {
    if (isPending) {
      if (needsPatientConfirmation) {
        return 'Please confirm this appointment';
      } else if (needsSpecialistConfirmation) {
        return 'Awaiting Provider Confirmation';
      }
    } else if (isConfirmed) {
      if (incompleteQuestionnaire) {
        return 'Please complete questionnaire';
      }
    }
  };

  const renderAlert = () => {
    if (renderAlertText()) {
      return (
        <div className="appointment-card__alert">
          <Icon icon="medium" size="small" />
          <span className="appointment-card__alert-text">
            {renderAlertText()}
          </span>
        </div>
      );
    }
  };

  const renderConfirm = () => {
    if (needsPatientConfirmation) {
      return (
        <div className="appointment-card__action appointment-card__action--secondary">
          <Button
            color="secondary"
            isFullWidth
            onClick={() => onConfirmAppointment(appointment)}>
            Confirm Appointment
          </Button>
        </div>
      );
    }
  };

  const renderCompleteQuestionnaire = () => {
    if (incompleteQuestionnaire) {
      return (
        <div className="appointment-card__action appointment-card__action--secondary">
          <Button
            color="secondary"
            isFullWidth
            onClick={() => onCompleteQuestionnaire(appointment.id)}>
            Complete Questionnaire
          </Button>
        </div>
      );
    }
  };

  const renderSecondaryAction = () => {
    if (isUpcoming) {
      return isPending ? renderConfirm() : renderCompleteQuestionnaire();
    }
  };

  const renderActions = () => {
    return (
      <>
        <div className="appointment-card__action">
          <Button
            color="white"
            isFullWidth
            onClick={() => onViewDetails(appointment)}>
            View Details
          </Button>
        </div>
        {renderSecondaryAction()}
      </>
    );
  };

  return (
    <div className="columns">
      <div className="column">
        <div className="appointment-card">
          <div className="appointment-card__top">
            <span className="appointment-card__text">
              {formatDateAndTime(appointment.scheduled_start_at)}
            </span>
            <span className="appointment-card__text--small">
              Status: {renderStatus()}
            </span>
          </div>

          {appointment.admin_user && (
            <div className="appointment-card__middle">
              <span className="appointment-card__text">
                Provider: {appointment.admin_user.first_name}{' '}
                {appointment.admin_user.last_name}
              </span>
            </div>
          )}

          <div className="appointment-card__bottom">
            {renderAlert()}
            <div className="appointment-card__actions">{renderActions()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;
