import React, { useState } from 'react';

import Button from '@/components/button/Button';
import Container from '@/components/container/Container';
import FilterHeader from '@/components/filters/FilterHeader';
import Datepicker from '@/components/form/Datepicker';
import InputIcon from '@/components/form/InputIcon';
import SearchGroup, {
  formatSearchFullName,
  formatSearchName,
} from '@/components/form/SearchGroup';
import SelectGroup from '@/components/form/SelectGroup';
import ActionHeader from '@/components/page/ActionHeader';
import Panel from '@/components/panel/Panel';
import { Table } from '@/components/table';
import DateTimeCell from '@/components/table/DateTimeCell';
import { getCcmComplexityOptions } from '@/selectors/options';
import { cleanComplexityCode } from '@/types/ccm_complexity';
import { Attributable, UUID } from '@/types/generic';
import { TableConfig } from '@/types/table';
import { buildRequestPath } from '@/utils/apiUtils';
import { formatShortDate } from '@/utils/dateUtils';
import {
  ccmMinutes,
  monthOptions,
  renderYearOptions,
} from '@/utils/dropdownUtils';
import { patientStatusEnumOptions } from '@/utils/patientUtils';
import { formatSecondsToHours } from '@/utils/timeUtils';
import { fullName } from '@/utils/userUtils';
import moment from 'moment';
import { useSelector } from 'react-redux';

const SEARCH_PATH = 'api/v1/reports/patients';

const today = moment();
const initialStateMonth = moment(today).startOf('month');

const INITIAL_QUERY = {
  birth_month: '',
  birth_year: '',
  care_manager_id: '',
  ccm_complexity_code: '',
  ccm_minutes: '',
  enrollment_month: '',
  enrollment_year: '',
  health_system_id: '',
  hospital_id: '',
  last_call_date: '',
  month: initialStateMonth.format('MM'),
  per: 24,
  practice_id: '',
  role: '',
  rpm_complexity_code: '',
  rpm_minutes: '',
  search: '',
  specialist_id: '',
  status: '',
  year: initialStateMonth.format('YYYY'),
};

interface Entry {
  care_manager_first_name: string;
  care_manager_id: UUID;
  care_manager_last_name: string;
  complexity_name: string;
  date_of_birth: string;
  emr: string;
  enrollment_date: string;
  first_name: string;
  id: UUID;
  last_name: string;
  monthly_minutes_goal: number;
  most_recent_call_date: string;
  most_recent_call_status: number;
  specialist_id: UUID;
  specialist_name: string;
  status: number;
  total_call_duration: number;
  total_ccm_duration: number;
}

const PatientsReport = () => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [queryOptions, setQueryOptions] = useState<Attributable>(INITIAL_QUERY);

  const ccmComplexityOptions = useSelector(getCcmComplexityOptions);

  const handleClearFilters = () => {
    setQueryOptions(INITIAL_QUERY);
  };

  const handleClickExport = () => {
    const path = buildRequestPath(
      '/api/v1/reports/patients.csv',
      formatSearchQuery(),
    );
    window.open(path, '_blank');
  };

  const handleSearchChange = (value: any) => {
    setSearchInput(value);
  };

  const handleChangeQuery = (field: string, value: any) => {
    setQueryOptions({
      ...queryOptions,
      [field]: value,
    });
  };

  const formatSearchQuery = () => {
    const { month, year } = queryOptions;

    const startDate = moment()
      .year(parseInt(year, 10))
      .month(parseInt(month, 10) - 1)
      .startOf('month');
    const endDate = moment(startDate).endOf('month');

    const tableSearchQuery = {
      ...queryOptions,
      last_call_date: moment(queryOptions.last_call_date)
        .utc()
        .format(),
      start_date: startDate.utc().format(),
      end_date: endDate.utc().format(),
    };

    return tableSearchQuery;
  };

  const tableConfig: TableConfig = {
    searchPath: SEARCH_PATH,
    searchQuery: formatSearchQuery(),
    columns: [
      {
        header: 'First Name',
        isSortable: true,
        attribute: 'first_name',
        isVisibleMobile: true,
        formatEntry: (entry: Entry) => {
          const name = fullName(entry);

          return name;
        },
      },
      {
        header: 'Date of Birth',
        isSortable: true,
        attribute: 'date_of_birth',
        formatAttribute: formatShortDate,
      },
      {
        header: 'EMR',
        isSortable: true,
        attribute: 'emr',
      },
      {
        header: 'Provider',
        isSortable: true,
        attribute: 'specialists.name',
        formatEntry: (entry: Entry) => entry.specialist_name,
      },
      {
        header: 'Enrollment',
        isSortable: true,
        attribute: 'enrollment_date',
        formatAttribute: formatShortDate,
      },
      {
        header: 'CCM Complexity',
        isSortable: true,
        attribute: 'ccm_complexity',
        formatAttribute: cleanComplexityCode,
      },
      {
        header: 'CCM Billing Codes',
        attribute: 'ccm_billing_codes',
      },
      {
        header: 'CCM Time',
        isSortable: true,
        attribute: 'total_ccm_phone',
        formatAttribute: formatSecondsToHours,
      },
      {
        header: 'RPM Complexity',
        isSortable: true,
        attribute: 'rpm_complexity',
        formatAttribute: cleanComplexityCode,
      },
      {
        header: 'RPM Billing Codes',
        attribute: 'rpm_billing_codes',
      },
      {
        header: 'RPM Time',
        isSortable: true,
        attribute: 'total_rpm_phone',
        formatAttribute: formatSecondsToHours,
      },
      {
        header: 'Last Contact',
        attribute: 'last_call',
        formatEntry: (entry: Entry) => {
          if (!entry.most_recent_call_date) {
            return '';
          }

          return (
            <DateTimeCell
              date={entry.most_recent_call_date}
              time={entry.most_recent_call_date}
            />
          );
        },
      },
    ],
  };

  return (
    <Panel>
      <Container>
        <ActionHeader title="Patients">
          <InputIcon
            onChange={handleSearchChange}
            onClick={() => handleChangeQuery('search', searchInput)}
            onPressEnter={() => handleChangeQuery('search', searchInput)}
            placeholder="Search"
            value={searchInput}
          />
          <Button color="secondary" onClick={handleClickExport}>
            Export to CSV
          </Button>
        </ActionHeader>

        <FilterHeader
          onChangeQuery={handleChangeQuery}
          onClearFilters={handleClearFilters}
          query={queryOptions}
          perPageDesktopClass="is-2"
          perPageTabletClass="is-3">
          <>
            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatSearchName}
              guideValue={queryOptions.health_system_id}
              label="Health System"
              minimumInputLength={1}
              onChange={(value: string) =>
                handleChangeQuery('health_system_id', value)
              }
              placeholder="Search Health Systems"
              searchPath="api/v1/health_systems"
            />

            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatSearchName}
              guideValue={queryOptions.hospital_id}
              label="Hospital"
              minimumInputLength={1}
              onChange={(value: string) =>
                handleChangeQuery('hospital_id', value)
              }
              placeholder="Search Hospitals"
              searchPath="api/v1/hospitals"
            />

            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatSearchName}
              guideValue={queryOptions.practice_id}
              label="Practice"
              minimumInputLength={1}
              onChange={(value: string) =>
                handleChangeQuery('practice_id', value)
              }
              placeholder="Search Practices"
              searchPath="api/v1/practices"
            />

            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatSearchFullName}
              guideValue={queryOptions.care_manager_id}
              label="Care Manager"
              minimumInputLength={1}
              onChange={(value: string) =>
                handleChangeQuery('care_manager_id', value)
              }
              placeholder="Search Care Managers"
              searchPath="api/v1/care_managers"
            />

            <SearchGroup
              clearFalseResultOnBlur
              formatResult={formatSearchName}
              guideValue={queryOptions.specialist_id}
              label="Provider"
              minimumInputLength={1}
              onChange={value => handleChangeQuery('specialist_id', value)}
              placeholder="Search Providers"
              searchPath="api/v1/specialists"
            />

            <Datepicker
              label="Last Contact"
              onChange={value => handleChangeQuery('last_call_date', value)}
              value={queryOptions.last_call_date}
            />

            <SelectGroup
              label="Year"
              options={renderYearOptions('2000')}
              value={queryOptions.year}
              onChange={value => handleChangeQuery('year', value)}
              placeholder="Year"
            />

            <SelectGroup
              label="Month"
              options={monthOptions}
              value={queryOptions.month}
              onChange={value => handleChangeQuery('month', value)}
              placeholder="Month"
            />
          </>

          <>
            <div className="columns is-mobile">
              <div className="column is-6">
                <SelectGroup
                  label="Date of Birth"
                  options={renderYearOptions('1900')}
                  value={queryOptions.birth_year}
                  onChange={value => handleChangeQuery('birth_year', value)}
                  placeholder="Year"
                />
              </div>
              <div className="column is-6">
                <SelectGroup
                  options={monthOptions}
                  value={queryOptions.birth_month}
                  onChange={value => handleChangeQuery('birth_month', value)}
                  placeholder="Month"
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-6">
                <SelectGroup
                  label="Enrollment Date"
                  options={renderYearOptions('2000')}
                  value={queryOptions.enrollment_year}
                  onChange={value =>
                    handleChangeQuery('enrollment_year', value)
                  }
                  placeholder="Year"
                />
              </div>
              <div className="column is-6">
                <SelectGroup
                  options={monthOptions}
                  value={queryOptions.enrollment_month}
                  onChange={value =>
                    handleChangeQuery('enrollment_month', value)
                  }
                  placeholder="Month"
                />
              </div>
            </div>

            <SelectGroup
              label="Patient Status"
              options={patientStatusEnumOptions}
              value={queryOptions.status}
              onChange={value => handleChangeQuery('status', value)}
              placeholder="Select Patient Status"
            />

            <SelectGroup
              label="CCM Minutes"
              options={ccmMinutes}
              value={queryOptions.ccm_minutes}
              onChange={value => handleChangeQuery('ccm_minutes', value)}
              placeholder="Select CCM Minutes"
              skipEmptyPlaceholder
            />

            <SelectGroup
              label="CCM Complexity"
              options={ccmComplexityOptions}
              value={queryOptions.ccm_complexity_code}
              onChange={value =>
                handleChangeQuery('ccm_complexity_code', value)
              }
              placeholder="Select CCM Complexity"
            />

            <SelectGroup
              label="RPM Minutes"
              options={ccmMinutes}
              value={queryOptions.rpm_minutes}
              onChange={value => handleChangeQuery('rpm_minutes', value)}
              placeholder="Select RPM Minutes"
              skipEmptyPlaceholder
            />

            <SelectGroup
              label="RPM Complexity"
              options={ccmComplexityOptions}
              value={queryOptions.rpm_complexity_code}
              onChange={value =>
                handleChangeQuery('rpm_complexity_code', value)
              }
              placeholder="Select RPM Complexity"
            />
          </>
        </FilterHeader>

        <Table config={tableConfig} />
      </Container>
    </Panel>
  );
};

export default PatientsReport;
