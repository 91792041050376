import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { getSelectedPatient } from '@/selectors/api';
import { buildPath, Routes } from '@/utils/routeUtils';
import EnrollmentHeader from '../../components/EnrollmentHeader';
import { getStepInformation } from '../../helpers/steps';
import ConsentStep1 from './ConsentStep1';
import ConsentStep2 from './ConsentStep2';
import ConsentStep3 from './ConsentStep3';
import ConsentStep4 from './ConsentStep4';
import ConsentStep5 from './ConsentStep5';

interface Params {
  sub_step: string;
}

const Consent = () => {
  const { sub_step } = useParams<Params>();

  const patient = useSelector(getSelectedPatient);

  const stepInformation = getStepInformation('consent', patient);

  const currentSubStep = sub_step || '1';
  const currentSubStepInt = parseInt(currentSubStep, 10);

  const nextScreenRoute = () => {
    if (currentSubStep === '5') {
      return buildPath(Routes.patientsEnrollment, {
        id: patient.id,
        step: 'consent',
        sub_step: (currentSubStepInt + 1).toString(),
      });
    }

    return buildPath(Routes.patientsEnrollment, {
      id: patient.id,
      step: stepInformation.nextStep,
    });
  };

  const nextScreen = buildPath(Routes.patientsEnrollment, {
    id: patient.id,
    step: stepInformation.nextStep,
  });

  const renderStepDetails = () => {
    const consentSteps = [
      ConsentStep1,
      ConsentStep2,
      ConsentStep3,
      ConsentStep4,
      ConsentStep5,
    ];
    const component = consentSteps[currentSubStepInt - 1];

    return React.createElement(component);
  };

  return (
    <div className="enrollment-wrapper">
      <EnrollmentHeader
        description="Please ensure the patient has agreed and understands the following."
        shouldDisplayLink
        title="Consent"
      />

      <p className="consent__step">Step {currentSubStep} of 5</p>

      {renderStepDetails()}
    </div>
  );
};

export default Consent;
