import React from 'react';

import SearchGroup, {
  Props as SearchGroupProps,
} from '@/components/form/SearchGroup';
import { SideEffect } from '@/types/generic';

interface Props extends SearchGroupProps {
  onChange: SideEffect;
}

const Permission = (props: Props) => {
  const {
    formatResult,
    guideValue,
    ignoreValues,
    initialSelection,
    isDisabled,
    label,
    onChange,
    placeholder,
    searchPath,
    validationMessage,
  } = props;

  return (
    <div className="columns">
      <div className="column is-4">
        <SearchGroup
          clearFalseResultOnBlur
          formatResult={formatResult}
          guideValue={guideValue}
          ignoreValues={ignoreValues}
          initialSelection={initialSelection}
          isDisabled={isDisabled}
          label={label}
          minimumInputLength={1}
          onChange={onChange}
          placeholder={placeholder}
          searchPath={searchPath}
          validationMessage={validationMessage}
        />
      </div>
    </div>
  );
};

export default Permission;
