import React from 'react';

import ErrorMessage from '@/components/form/ErrorMessage';
import Label from '@/components/form/Label';
import { Tab, Tabs } from '@/components/tabs';
import { Option } from '@/types/form';
import { SideEffect } from '@/types/generic';

interface Props {
  label: string;
  onChange?: SideEffect;
  options: Option[];
  validationMessage?: string;
  value: string;
}

export const TabGroup = (props: Props) => {
  const { label, onChange, options, validationMessage, value } = props;

  const isInvalid = !!validationMessage;

  return (
    <div className="form-input-group">
      <Label isInvalid={isInvalid}>{label}</Label>
      <Tabs currentTab={value} onSelectTab={onChange}>
        {options.map(option => (
          <Tab key={option.value} value={option.value}>
            {option.label}
          </Tab>
        ))}
      </Tabs>
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </div>
  );
};

export default TabGroup;
