import React, { useCallback, useEffect, useState } from 'react';

import Api from '@/api/Api';
import ActivityIndicator from '@/components/activity/ActivityIndicator';
import Constraint from '@/components/constraint/Constraint';
import Modal from '@/components/modal/Modal';
import ConfirmationModal from '@/contexts/modals/ConfirmationModal';
import { UUID } from '@/types/generic';
import {
  hasAnyQuestionnaires,
  VirtualAppointment,
} from '@/types/v2/virtual_appointment';
import useLoadingState from '@/utils/api/useLoadingState';
import useNotifier from '@/utils/messages/useNotifier';
import Modeler from '@/utils/modeler/modeler';
import { buildPath, Routes } from '@/utils/routeUtils';
import AppointmentModalContent from './AppointmentModalContent';
import { Display } from './types';

interface Props {
  appointmentId?: UUID;
  displayFor: Display;
  isCancelModalVisible?: boolean;
  onCancelAppointment?: () => void;
  onCloseConfirmationModal?: () => void;
  onCloseModal: () => void;
  onOpenConfirmationModal?: () => void;
  onStartQuestionnaires?: (appointmentId: UUID) => void;
  onEditQuestionnaires?: () => void;
  userId: UUID;
}

const includes = [
  'patient',
  'patient.care_manager',
  'practice',
  'admin_user',
  'admin_user.specialist',
  'virtual_appointment_questionnaires',
  'virtual_appointment_questionnaires.questionnaire',
  'virtual_appointment_questionnaires.questionnaire_responses',
  'virtual_appointment_questionnaires.questionnaire_responses.questionnaire_answers',
  'virtual_appointment_questionnaires.questionnaire_responses.questionnaire_question',
];

const AppointmentModal = ({
  appointmentId,
  isCancelModalVisible,
  onCancelAppointment,
  onCloseConfirmationModal,
  onCloseModal,
  onOpenConfirmationModal,
  onStartQuestionnaires,
  ...extraProps
}: Props) => {
  const loader = useLoadingState('appointment', 'confirm');
  const notifier = useNotifier();

  const [appointment, setAppointment] = useState<VirtualAppointment>(null);

  const isVisible = !!appointmentId;

  const getAppointment = async () => {
    loader.startLoading('appointment');

    try {
      const url = buildPath(
        Routes.api2.virtualAppointment,
        {
          id: appointmentId,
        },
        null,
        includes,
      );

      const response = await Api.utility.get(url);
      const resource = new Modeler<VirtualAppointment>(response.data, {
        generations: 3,
      }).build();

      setAppointment(resource);
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('appointment');
  };

  const handleClickConfirmAppointment = useCallback(async () => {
    loader.startLoading('confirm');

    try {
      const url = buildPath(
        Routes.api2.virtualAppointment,
        { id: appointmentId },
        null,
        includes,
      );

      // This does not follow the standard Rails body format because it will be handled through a service to determine which attribute to update
      const body = {
        status: 'confirmed',
      };

      const response = await Api.utility.patch(url, body);
      const resource = new Modeler<VirtualAppointment>(response.data, {
        generations: 2,
      }).build();

      notifier.success('Appointment confirmed');

      setAppointment(resource);

      // This will only be triggered when viewed by a patient
      if (onStartQuestionnaires && appointmentId) {
        onCloseModal();

        if (hasAnyQuestionnaires(resource)) {
          onStartQuestionnaires(appointmentId);
        }
      }
    } catch (err) {
      notifier.error(err);
    }

    loader.stopLoading('confirm');
  }, [appointmentId]);

  useEffect(() => {
    if (appointmentId) {
      getAppointment();
    }
  }, [appointmentId]);

  const renderModalContent = (() => {
    if (loader.isLoading('appointment') || !appointment) {
      return <ActivityIndicator />;
    }

    return (
      <AppointmentModalContent
        appointment={appointment}
        isConfirmDisabled={loader.isLoading('confirm')}
        onClickCancel={onOpenConfirmationModal}
        onClickConfirmAppointment={handleClickConfirmAppointment}
        {...extraProps}
      />
    );
  })();

  return (
    <>
      <Modal isVisible={isVisible} onCloseModal={onCloseModal}>
        <Constraint height="medium">{renderModalContent}</Constraint>
      </Modal>

      <ConfirmationModal
        acceptText="Cancel Appointment"
        description="Are you sure you want to cancel this appointment?"
        header="Cancel appointment?"
        isVisible={isCancelModalVisible}
        onAccept={onCancelAppointment}
        onClose={onCloseConfirmationModal}
        rejectText="Keep Appointment"
      />
    </>
  );
};

export default AppointmentModal;
