import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import Flatpickr from 'react-flatpickr';

import ErrorMessage from '@/components/form/ErrorMessage';
import Label from '@/components/form/Label';
import { SideEffect } from '@/types/generic';
import { formatIsoDate, formatIsoDateTime } from '@/utils/dateUtils';
import Icon from '../icon/Icon';

type Limit = 'future' | 'past' | 'all';

interface Props {
  enableTime?: boolean;
  isDisabled?: boolean;
  isRange?: boolean;
  label?: string;
  limit?: Limit;
  maxDate?: string;
  onChange: SideEffect;
  placeholder?: string;
  validationMessage?: string;
  value: string | string[];
}

const Datepicker = (props: Props) => {
  const inputWrapperRef = useRef(null);

  const {
    enableTime,
    isDisabled,
    isRange,
    label,
    limit,
    maxDate,
    onChange,
    placeholder,
    validationMessage,
    value,
  } = props;

  // A bug in Flatpickr prevents the disabled attribute from being changed once an altInput is rendered
  // This ensures the raw HTML element's disabled property is updated correctly
  useEffect(() => {
    const input = inputWrapperRef.current.getElementsByTagName('input')[1];
    input.disabled = isDisabled;
  }, [isDisabled]);

  const handleChangeDate = (date: object[]) => {
    if (onChange && isRange) {
      const startDate = date[0] ? formatIsoDate(date[0]) : null;
      const endDate = date[1] ? formatIsoDate(date[1]) : null;
      onChange([startDate, endDate]);
    } else if (onChange && typeof onChange === 'function') {
      if (enableTime) {
        onChange(formatIsoDateTime(date[0]));
      } else {
        onChange(formatIsoDate(date[0]));
      }
    }
  };

  const isInvalid = !!validationMessage;

  const options = {
    altInput: true,
    altFormat: enableTime ? 'm/d/Y h:iK' : 'm/d/Y',
    enableTime,
    maxDate,
    mode: isRange ? 'range' : 'single',
    minDate: undefined,
  };

  if (limit === 'future') {
    options.minDate = 'today';
  }

  return (
    <div className="form-input-group">
      <Label isInvalid={isInvalid}>{label}</Label>
      <div
        className={classNames('form-select', { 'has-selected-date': !!value })}
        ref={inputWrapperRef}>
        <Flatpickr
          disabled={isDisabled}
          onChange={handleChangeDate}
          // @ts-ignore
          options={options}
          value={value}
          placeholder={placeholder}
        />
        <div className="form-select__icon">
          <Icon icon="caretDown" />
        </div>
      </div>
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </div>
  );
};

export default Datepicker;
