import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import ActivityIndicator from '@/components/activity/ActivityIndicator';
import BackLink from '@/components/button/BackLink';
import Constraint from '@/components/constraint/Constraint';
import Container from '@/components/container/Container';
import Panel from '@/components/panel/Panel';
import useGetRequest from '@/utils/api/useGetRequest';
import { buildPath, Routes } from '@/utils/routeUtils';

import { Response } from '@/types/response';
import { IdParams } from '@/types/params';

const DevicesShow = () => {
  const dispatch = useDispatch();

  const { id } = useParams<IdParams>();

  const [devices, setDevices] = useState(null);

  const backPath = buildPath(Routes.devices);

  const [getDevice, isLoadingDevice] = useGetRequest({
    dispatch,
    dispatchToStore: true,
    id,
    model: 'devices',
    onSuccess: (response: Response) => {
      setDevices(response);
    },
  });

  useEffect(() => {
    getDevice();
  }, []);

  const renderDevice = () => {
    if (!devices || isLoadingDevice) {
      return <ActivityIndicator />;
    } else {
      return <div>Nothing to see here...</div>;
    }
  };

  return <div>{renderDevice()}</div>;
};

export default DevicesShow;
