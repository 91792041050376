import React from 'react';

import Constraint from '@/components/constraint/Constraint';
import CloseButton from '@/components/modal/CloseButton';
import Panel from '@/components/panel/Panel';
import { VirtualAppointment } from '@/types/v2/virtual_appointment';
import QuestionnairesList from '../questionniares/display/QuestionnairesList';

interface Props {
  appointment: VirtualAppointment;
  onClickClose: () => void;
}

const QuestionnaireDrawer = ({
  appointment,
  onClickClose,
}: Props): JSX.Element => {
  return (
    <Panel>
      <CloseButton onClick={onClickClose} />
      <Constraint size="large">
        <QuestionnairesList
          questionnaires={appointment.virtual_appointment_questionnaires}
        />
      </Constraint>
    </Panel>
  );
};

export default QuestionnaireDrawer;
