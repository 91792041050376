import React from 'react';

import Button from '@/components/button/Button';
import Modal from '@/components/modal/Modal';
import { SideEffect } from '@/types/generic';

interface Props {
  isVisible: boolean;
  onClose: SideEffect;
  onNavigate: SideEffect;
  onSave: SideEffect;
}

const UnsavedConfirmationModal = (props: Props) => {
  const { isVisible, onClose, onNavigate, onSave } = props;

  return (
    <Modal isVisible={isVisible} onCloseModal={onClose}>
      <div className="modal__content">
        <h3 className="modal__header">Unsaved updates</h3>
        <span className="modal__info">
          Some information on this page has not been saved, would you like to
          save changes?
        </span>
      </div>

      <div className="modal__actions">
        <Button color="white" onClick={onNavigate}>
          Don't Save
        </Button>

        <Button color="secondary" onClick={onSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default UnsavedConfirmationModal;
