import React from 'react';

import { PolicyGroups, Protected } from '@/components/router';
import PatientsReport from './PatientsReport';

const Reports = () => {
  return (
    <Protected allowedRoles={PolicyGroups.atLeastBillingAdmin}>
      <PatientsReport />
    </Protected>
  );
};

export default Reports;
