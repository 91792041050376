import { Attributable } from '@/types/generic';

export const formatAddressAttributes = (
  form: Attributable,
  required: boolean = false,
): any => {
  const { city, line_1, line_2, state, zip_code, ...formFields } = form;

  if (!city && !line_1 && !line_2 && !state && !zip_code && !required) {
    return formFields;
  }

  return {
    ...formFields,
    address_attributes: {
      city,
      line_1,
      line_2,
      state,
      zip_code,
    },
  };
};
