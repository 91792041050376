import { FormComponentConfig, Option } from '@/types/form';
import { Attributable, SideEffect } from '@/types/generic';
import {
  allergyTypes,
  screenTypes,
  statusOptions,
} from '@/utils/dropdownUtils';
import { buildPath, Routes } from '@/utils/routeUtils';

interface AdditionalOptions {
  serviceOptions: Option[];
}

export const buildFormConfig = (
  resource: string = '',
  history,
  options: SideEffect,
  resourceId: string = '',
  response: Attributable = {},
  initialSelection: Attributable = {},
  additionalOptions?: AdditionalOptions,
) => {
  const FORM_CONFIGS = {
    alert_types: {
      actions: {
        model: 'alert_type',
      },
      state: {
        name: '',
        device_type_id: '',
        type_label: '',
        value_label: '',
      },
      rules: {
        name: { required: true },
        device_type_id: { required: true },
        type_label: { required: true },
        value_label: { required: true },
      },
      layout: [
        {
          title: 'Alert Type',
          fields: [
            [
              {
                label: 'Device Type',
                field: 'device_type_id',
                placeholder: 'Select device type',
                size: 4,
                type: 'select',
                options,
              },
              {
                label: 'Name',
                field: 'name',
                type: 'text',
                placeholder: 'Enter name',
                size: 4,
              },
              {
                label: 'Type Label',
                field: 'type_label',
                type: 'text',
                placeholder: 'Enter value',
                size: 2,
              },
              {
                label: 'Value Label',
                field: 'value_label',
                type: 'text',
                placeholder: 'Enter value',
                size: 2,
              },
            ],
          ],
        },
      ],
    },
    allergy_options: {
      actions: {
        model: 'allergy_option',
      },
      state: {
        allergy_type: '',
        name: '',
      },
      rules: {
        allergy_type: { required: true },
        name: { required: true },
      },
      layout: [
        {
          title: 'Allergy',
          fields: [
            [
              {
                label: 'Allergy Type',
                field: 'allergy_type',
                type: 'select',
                placeholder: 'Select allergy type',
                size: 4,
                options: allergyTypes,
              },
              {
                label: 'Allergy Name',
                field: 'name',
                placeholder: 'Enter allergy name',
                type: 'text',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    billing_codes: {
      actions: {
        model: 'billing_code',
      },
      state: {
        code: '',
        name: '',
        service_id: '',
      },
      rules: {
        code: { required: true },
        name: { required: true },
        service_id: { required: true },
      },
      layout: [
        {
          title: 'Billing Code',
          fields: [
            [
              {
                label: 'Code',
                field: 'code',
                type: 'text',
                placeholder: 'Enter code',
                size: 4,
              },
              {
                label: 'Name',
                field: 'name',
                type: 'text',
                placeholder: 'Enter name',
                size: 4,
              },
            ],
            [
              {
                label: 'Service',
                field: 'service_id',
                placeholder: 'Select service',
                type: 'select',
                size: 4,
                options,
              },
            ],
          ],
        },
      ],
    },
    barriers: {
      actions: {
        model: 'barrier',
      },
      state: {
        description: '',
        condition_id: '',
      },
      rules: {
        description: { required: true },
        condition_id: { required: true },
      },
      layout: [
        {
          title: 'Barrier',
          fields: [
            [
              {
                label: 'Description',
                field: 'description',
                type: 'text',
                placeholder: 'Enter Description',
                size: 4,
              },
              {
                label: 'Condition',
                field: 'condition_id',
                placeholder: 'Search for condition',
                type: 'typeahead',
                clearFalseResultOnBlur: true,
                typeaheadSearchPath: 'api/v1/conditions',
                size: 4,
                initialSelection,
                formatTypeaheadResult: (result: any) => {
                  return {
                    label: result.attributes.description,
                    value: result.id,
                  };
                },
              },
            ],
          ],
        },
      ],
    },
    caller_ids: {
      actions: {
        model: 'caller_id',
      },
      state: {
        phone_number: '',
      },
      rules: {
        phone_number: { required: true, type: 'phone' },
      },
      layout: [
        {
          title: 'Caller ID',
          fields: [
            [
              {
                label: 'Phone Number',
                field: 'phone_number',
                type: 'phonenumber',
                placeholder: 'Enter phone number',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    ccm_complexities: {
      actions: {
        model: 'ccm_complexity',
      },
      state: {
        name: '',
        phone_minutes: '',
        admin_minutes: '',
      },
      rules: {
        name: { required: true },
        phone_minutes: { required: true },
        admin_minutes: { required: true },
      },
      layout: [
        {
          title: 'CCM Complexity',
          fields: [
            [
              {
                label: 'Complexity',
                field: 'name',
                placeholder: 'Enter complexity',
                type: 'text',
                size: 4,
              },
              {
                label: 'Phone Minutes',
                field: 'phone_minutes',
                placeholder: 'Enter phone minutes',
                type: 'number',
                size: 4,
              },
            ],
            [
              {
                label: 'CCM Mintes',
                field: 'admin_minutes',
                placeholder: 'Enter admin minutes',
                type: 'number',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    condition_groups: {
      actions: {
        model: 'condition_group',
      },
      state: {
        name: '',
        service_id: '',
      },
      rules: {
        name: { required: true },
        service_id: { required: true },
      },
      layout: [
        {
          title: 'Condition Group',
          fields: [
            [
              {
                label: 'Name',
                field: 'name',
                placeholder: 'Enter name',
                type: 'text',
                size: 4,
              },
              {
                label: 'Service',
                field: 'service_id',
                placeholder: 'Select service',
                type: 'select',
                size: 4,
                options: additionalOptions.serviceOptions,
              },
            ],
          ],
        },
      ],
    },
    conditions: {
      actions: {
        model: 'condition',
      },
      state: {
        description: '',
        condition_group_id: '',
        service_id: '',
      },
      rules: {
        condition_group_id: { required: true },
        description: { required: true },
        service_id: { required: true },
      },
      layout: [
        {
          title: 'Condition',
          fields: [
            [
              {
                label: 'Description',
                field: 'description',
                placeholder: 'Enter description',
                type: 'text',
                size: 4,
              },
              {
                label: 'Group',
                field: 'condition_group_id',
                placeholder: 'Select group',
                type: 'select',
                size: 4,
                options,
              },
              {
                label: 'Service',
                field: 'service_id',
                placeholder: 'Select service',
                type: 'select',
                size: 4,
                options: additionalOptions.serviceOptions,
              },
            ],
          ],
        },
      ],
    },
    cpt_codes: {
      actions: {
        model: 'cpt_code',
      },
      state: {
        code: '',
      },
      rules: {
        code: { required: true },
      },
      layout: [
        {
          title: 'CPT Code',
          fields: [
            [
              {
                label: 'Code',
                field: 'code',
                placeholder: 'Enter code',
                type: 'text',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    device_categories: {
      actions: {
        model: 'device_category',
      },
      state: {
        name: '',
      },
      rules: {
        name: { required: true },
      },
      layout: [
        {
          title: 'Device Category',
          fields: [
            [
              {
                label: 'Name',
                field: 'name',
                placeholder: 'Enter name',
                type: 'text',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    device_makes: {
      actions: {
        model: 'device_make',
      },
      state: {
        name: '',
        device_type_id: '',
      },
      rules: {
        name: { required: true },
        device_type_id: { required: true },
      },
      layout: [
        {
          title: 'Device Make',
          fields: [
            [
              {
                label: 'Device Type',
                field: 'device_type_id',
                placeholder: 'Select device type',
                size: 4,
                type: 'select',
                options,
              },
              {
                label: 'Name',
                field: 'name',
                type: 'text',
                placeholder: 'Enter name',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    device_models: {
      actions: {
        model: 'device_model',
      },
      state: {
        name: '',
        device_name_id: '',
      },
      rules: {
        name: { required: true },
        device_name_id: { required: true },
      },
      layout: [
        {
          title: 'Device Model',
          fields: [
            [
              {
                label: 'Device Name',
                field: 'device_name_id',
                placeholder: 'Select device name',
                size: 4,
                type: 'select',
                options,
              },
              {
                label: 'Name',
                field: 'name',
                type: 'text',
                placeholder: 'Enter name',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    device_names: {
      actions: {
        model: 'device_name',
      },
      state: {
        name: '',
        device_make_id: '',
      },
      rules: {
        name: { required: true },
        device_make_id: { required: true },
      },
      layout: [
        {
          title: 'Device Name',
          fields: [
            [
              {
                label: 'Device Make',
                field: 'device_make_id',
                placeholder: 'Select device make',
                size: 4,
                type: 'select',
                options,
              },
              {
                label: 'Name',
                field: 'name',
                type: 'text',
                placeholder: 'Enter name',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    device_types: {
      actions: {
        model: 'device_type',
      },
      state: {
        name: '',
        device_category_id: '',
      },
      rules: {
        name: { required: true },
        device_category_id: { required: true },
      },
      layout: [
        {
          title: 'Device Type',
          fields: [
            [
              {
                label: 'Device Category',
                field: 'device_category_id',
                placeholder: 'Select device category',
                size: 4,
                type: 'select',
                options,
              },
              {
                label: 'Name',
                field: 'name',
                type: 'text',
                placeholder: 'Enter name',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    goals: {
      actions: {
        model: 'goal',
      },
      state: {
        description: '',
        condition_id: '',
      },
      rules: {
        description: { required: true },
        condition_id: { required: true },
      },
      layout: [
        {
          title: 'Goal',
          fields: [
            [
              {
                label: 'Description',
                field: 'description',
                type: 'text',
                placeholder: 'Enter Description',
                size: 4,
              },
              {
                label: 'Condition',
                field: 'condition_id',
                placeholder: 'Search for condition',
                size: 4,
                type: 'typeahead',
                clearFalseResultOnBlur: true,
                typeaheadSearchPath: 'api/v1/conditions',
                initialSelection,
                formatTypeaheadResult: (result: any) => {
                  return {
                    label: result.attributes.description,
                    value: result.id,
                  };
                },
              },
            ],
          ],
        },
      ],
    },
    icd_codes: {
      actions: {
        model: 'icd_code',
      },
      state: {
        code: '',
        status: '',
      },
      rules: {
        code: { required: true },
        status: { required: true },
      },
      layout: [
        {
          title: 'ICD Code',
          fields: [
            [
              {
                label: 'Code',
                field: 'code',
                placeholder: 'Enter code',
                type: 'text',
                size: 4,
              },
              {
                label: 'Status',
                field: 'status',
                type: 'select',
                placeholder: 'Select status',
                size: 4,
                options: statusOptions,
              },
            ],
          ],
        },
      ],
    },
    immunization_names: {
      actions: {
        model: 'immunization_name',
      },
      state: {
        name: '',
      },
      rules: {
        name: { required: true },
      },
      layout: [
        {
          title: 'Immunization',
          fields: [
            [
              {
                label: 'Name',
                field: 'name',
                placeholder: 'Enter name',
                type: 'text',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    lab_tests: {
      actions: {
        model: 'lab_test',
      },
      state: {
        test_type: '',
      },
      rules: {
        test_type: { required: true },
      },
      layout: [
        {
          title: 'Lab Test',
          fields: [
            [
              {
                label: 'Test Type',
                field: 'test_type',
                placeholder: 'Enter test type',
                type: 'text',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    manual_entry_reasons: {
      actions: {
        model: 'manual_entry_reason',
      },
      state: {
        reason: '',
      },
      rules: {
        reason: { required: true },
      },
      layout: [
        {
          title: 'Manual Entry Reason',
          fields: [
            [
              {
                label: 'Reason',
                field: 'reason',
                placeholder: 'Enter reason',
                type: 'text',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    medication_names: {
      actions: {
        model: 'medication_name',
      },
      state: {
        group: '',
        medication_type: '',
        name: '',
        status: 'approved',
      },
      rules: {
        group: { required: true },
        medication_type: { required: true },
        name: { required: true },
      },
      layout: [
        {
          title: 'Medication',
          fields: [
            [
              {
                label: 'Medication Name',
                field: 'name',
                placeholder: 'Enter medication name',
                type: 'text',
                size: 4,
              },
              {
                label: 'Medication Group',
                field: 'group',
                placeholder: 'Enter medication group',
                type: 'text',
                size: 4,
              },
            ],
            [
              {
                label: 'Medication Type',
                field: 'medication_type',
                placeholder: 'Enter medication type',
                type: 'text',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    metrics: {
      actions: {
        model: 'metric',
      },
      state: {
        description: '',
      },
      rules: {
        description: { required: true },
      },
      layout: [
        {
          title: 'Metric',
          fields: [
            [
              {
                label: 'Description',
                field: 'description',
                placeholder: 'Enter description',
                type: 'text',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    outcomes: {
      actions: {
        model: 'outcome',
      },
      state: {
        description: '',
        condition_id: '',
      },
      rules: {
        description: { required: true },
        condition_id: { required: true },
      },
      layout: [
        {
          title: 'Outcome',
          fields: [
            [
              {
                label: 'Description',
                field: 'description',
                type: 'text',
                placeholder: 'Enter Description',
                size: 4,
              },
              {
                label: 'Condition',
                field: 'condition_id',
                placeholder: 'Search for condition',
                type: 'typeahead',
                clearFalseResultOnBlur: true,
                typeaheadSearchPath: 'api/v1/conditions',
                size: 4,
                initialSelection,
                formatTypeaheadResult: (result: any) => {
                  return {
                    label: result.attributes.description,
                    value: result.id,
                  };
                },
              },
            ],
          ],
        },
      ],
    },
    screening_types: {
      actions: {
        model: 'screening_type',
      },
      state: {
        name: '',
        screen_type: '',
      },
      rules: {
        name: { required: true },
        screen_type: { required: true },
      },
      layout: [
        {
          title: 'Screening Type',
          fields: [
            [
              {
                label: 'Name',
                field: 'name',
                placeholder: 'Enter name',
                type: 'text',
                size: 4,
              },
              {
                label: 'Screen Type',
                field: 'screen_type',
                type: 'select',
                placeholder: 'Select screen type',
                size: 4,
                options: screenTypes,
              },
            ],
          ],
        },
      ],
    },
    specialty_types: {
      actions: {
        model: 'specialty_type',
      },
      state: {
        name: '',
      },
      rules: {
        name: { required: true },
      },
      layout: [
        {
          title: 'Specialty Type',
          fields: [
            [
              {
                label: 'Name',
                field: 'name',
                placeholder: 'Enter name',
                type: 'text',
                size: 4,
              },
            ],
          ],
        },
      ],
    },
    symptoms: {
      actions: {
        model: 'symptom',
      },
      state: {
        description: '',
        condition_id: '',
      },
      rules: {
        description: { required: true },
        condition_id: { required: true },
      },
      layout: [
        {
          title: 'Symptom',
          fields: [
            [
              {
                label: 'Description',
                field: 'description',
                type: 'text',
                placeholder: 'Enter Description',
                size: 4,
              },
              {
                label: 'Condition',
                field: 'condition_id',
                placeholder: 'Search for condition',
                type: 'typeahead',
                clearFalseResultOnBlur: true,
                typeaheadSearchPath: 'api/v1/conditions',
                size: 4,
                initialSelection,
                formatTypeaheadResult: (result: any) => {
                  return {
                    label: result.attributes.description,
                    value: result.id,
                  };
                },
              },
            ],
          ],
        },
      ],
    },
  };

  const formConfig = FORM_CONFIGS[resource];
  const backPath = buildPath(Routes.adminResource, { resource });

  const path = resourceId
    ? `/api/v1/${resource}/${resourceId}`
    : `/api/v1/${resource}`;

  const config: FormComponentConfig = {
    ...formConfig,
    actions: {
      ...formConfig.actions,
      path,
      method: resourceId ? 'PUT' : 'POST',
      onSuccess: () => history.push(backPath),
      onCancel: () => history.push(backPath),
    },
    state: resourceId ? response.attributes : formConfig.state,
  };

  return config;
};
