import React from 'react';
import { useParams } from 'react-router-dom';

import BackLink from '@/components/button/BackLink';
import Container from '@/components/container/Container';
import PatientHeader from '@/contexts/patients/components/PatientHeader';
import AllergiesNew from '@/contexts/patients/modules/allergies/AllergiesNew';
import ContactsNew from '@/contexts/patients/modules/contacts/ContactsNew';
import MedicalHistoryNew from '@/contexts/patients/modules/medical_records/MedicalHistoryNew';
import OtherHistoryNew from '@/contexts/patients/modules/medical_records/OtherHistoryNew';
import MedicationsNew from '@/contexts/patients/modules/medications/MedicationsNew';
import SpecialistsNew from '@/contexts/patients/modules/specialists/SpecialistsNew';
import { buildPath, Routes } from '@/utils/routeUtils';
import EnrollmentBanner from '../components/EnrollmentBanner';
import { UUID } from '@/types/generic';

interface Params {
  id: UUID;
  step: string;
}

const EnrollmentNew = () => {
  const { id, step } = useParams<Params>();

  const backPath = buildPath(Routes.patientsCareManagement, { id });

  const forms = {
    allergies: AllergiesNew,
    contacts: ContactsNew,
    family_history: OtherHistoryNew,
    medical_history: MedicalHistoryNew,
    medications: MedicationsNew,
    social_history: OtherHistoryNew,
    support: SpecialistsNew,
    surgical_history: OtherHistoryNew,
  };

  const Component = forms[step];

  return (
    <>
      <Container>
        <BackLink to={backPath}>Back to Patient</BackLink>
      </Container>
      <PatientHeader />
      <EnrollmentBanner />
      <Component />
    </>
  );
};

export default EnrollmentNew;
