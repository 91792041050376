import classNames from 'classnames';
import React, { useMemo } from 'react';

import Button from '@/components/button/Button';
import SelectGroup from '@/components/form/SelectGroup';
import { monthOptions, renderYearOptions } from '@/utils/dropdownUtils';
import moment from 'moment';

export interface Props {
  isHiddenMobile?: boolean;
  onChangeDate: (date: string) => void;
  onClickApplyDate: () => void;
  selectedDate: string;
}

const CalendarDateSelector = ({
  isHiddenMobile,
  onChangeDate,
  onClickApplyDate,
  selectedDate,
}: Props): JSX.Element => {
  const yearOptions = useMemo(() => {
    return renderYearOptions('2020');
  }, []);

  const handleChangeDate = (field: any, value: string): void => {
    const newDate = moment(selectedDate);
    const intValue = parseInt(value, 10);

    const newValue = field === 'month' ? intValue - 1 : intValue;
    newDate.set(field, newValue);

    onChangeDate(newDate.format());
  };

  return (
    <div
      className={classNames('columns', {
        'is-hidden-mobile': isHiddenMobile,
      })}>
      <div className="column is-3-tablet is-2-mobile">
        <SelectGroup
          label="Year"
          onChange={value => handleChangeDate('year', value)}
          options={yearOptions}
          value={moment(selectedDate).format('YYYY')}
        />
      </div>

      <div className="column is-3-tablet is-2-mobile">
        <SelectGroup
          label="Month"
          onChange={value => handleChangeDate('month', value)}
          options={monthOptions}
          value={moment(selectedDate).format('MM')}
        />
      </div>

      <div className="column is-3-tablet is-2-mobile">
        <div className="is-aligned-bottom is-full-height">
          <Button color="white" onClick={onClickApplyDate}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CalendarDateSelector;
