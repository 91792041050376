import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Icon from '@/components/icon/Icon';
import { SideEffect } from '@/types/generic';

interface Props {
  children: ReactNode;
  onClick?: SideEffect;
  to?: string;
}

const BackLink = (props: Props) => {
  const { children, onClick, to } = props;

  if (to) {
    return (
      <div className="back-link">
        <Link to={to}>
          <Icon icon="caretLeft" />
          <span>{children}</span>
        </Link>
      </div>
    );
  } else if (onClick) {
    return (
      <div className="back-link" onClick={onClick}>
        <Icon icon="caretLeft" />
        <span>{children}</span>
      </div>
    );
  }
};

export default BackLink;
