import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { flashSuccess } from '@/actions/sagas/messageSaga';
import { FormComponentConfig } from '@/types/form';
import { buildPath, Routes } from '@/utils/routeUtils';
import MedicationRequestForm from './components/MedicationRequestForm';

const MedicationsRequestNew = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [resetForm, setResetForm] = useState(false);

  const handleSuccess = () => {
    const path = buildPath(Routes.medicationsRequestNew);

    setResetForm(true);

    dispatch(flashSuccess('Request submitted'));

    history.push(path);
  };

  const handleResetForm = () => {
    setResetForm(false);
  };

  const config: FormComponentConfig = {
    actions: {
      allowDelete: true,
      path: 'api/v1/medication_names',
      model: 'medication_name',
      method: 'POST',
      onSuccess: handleSuccess,
      submitText: 'Submit Request',
    },
    state: {
      group: '',
      medication_type: '',
      name: '',
    },
    rules: {
      name: { required: true },
    },
    layout: [
      {
        title: 'Request Medication',
        fields: [
          [
            {
              label: 'Medication Name',
              field: 'name',
              placeholder: 'Enter medication name',
              type: 'text',
            },
          ],
          [
            {
              label: 'Medication Group',
              field: 'group',
              placeholder: 'Enter medication group',
              type: 'text',
            },
          ],
          [
            {
              label: 'Medication Type',
              field: 'medication_type',
              placeholder: 'Enter medication type',
              type: 'text',
            },
          ],
        ],
      },
    ],
  };

  return (
    <MedicationRequestForm
      config={config}
      onFormReset={handleResetForm}
      resetForm={resetForm}
    />
  );
};

export default MedicationsRequestNew;
