import React from 'react';

import CircleButton, {
  CircleButtonColor,
} from '@/components/button/CircleButton';
import { IconName } from '@/components/icon/Icon';
import { FlagState } from './VideoCallInterface';

export interface Props {
  flags: FlagState;
  onClickChat: () => void;
  onClickNotes?: () => void;
  onClickPatient?: () => void;
  onClickQuestionnaire: () => void;
}

interface IconState {
  color: CircleButtonColor;
  icon: IconName;
}

const Sidebar = (props: Props): JSX.Element => {
  const {
    flags,
    onClickNotes,
    onClickQuestionnaire,
    onClickChat,
    onClickPatient,
  } = props;

  const iconProps = (icon: string, state: string): IconState => {
    const isEnabled = flags[state];

    if (isEnabled) {
      return {
        color: 'blue',
        icon: `${icon}-white` as IconName,
      };
    }

    return {
      color: 'white',
      icon: `${icon}-blue` as IconName,
    };
  };

  return (
    <div className="video-call-sidebar">
      {onClickNotes && (
        <div className="video-call-sidebar__button">
          <CircleButton
            color="white"
            icon="paragraph-blue"
            onClick={onClickNotes}
            withShadow
            {...iconProps('paragraph', 'notes')}>
            Call Notes
          </CircleButton>
        </div>
      )}

      <div className="video-call-sidebar__button">
        <CircleButton
          onClick={onClickQuestionnaire}
          withShadow
          {...iconProps('question', 'questionnaire')}>
          Questionnaire
        </CircleButton>
      </div>

      <div className="video-call-sidebar__button">
        <CircleButton
          onClick={onClickChat}
          withShadow
          {...iconProps('chat', 'chat')}>
          Chat
        </CircleButton>
      </div>

      {onClickPatient && (
        <div className="video-call-sidebar__button">
          <CircleButton
            color="white"
            icon="avatar-blue"
            onClick={onClickPatient}
            withShadow
            {...iconProps('avatar', 'patient')}>
            Patient Info
          </CircleButton>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
