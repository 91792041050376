import { Snapshot } from '@/types/generic';

export const createEmptySnapshot = (): Snapshot => {
  return {
    data: [],
    currentPage: 1,
    sortBy: null,
    sortDirection: null,
    totalPages: 0,
  };
};
