import React from 'react';

import Button from '@/components/button/Button';
import InputIcon from '@/components/form/InputIcon';
import SelectGroup from '@/components/form/SelectGroup';
import { SideEffect } from '@/types/generic';
import {
  statusFilterOptions,
  orphanDeviceStatusFilterOptions,
} from '@/utils/dropdownUtils';
import { perPageOptions } from '@/utils/tableUtils';

interface Props {
  buttonText: string;
  onAddClick: SideEffect;
  onPerPageChange: SideEffect;
  onQueryChange?: SideEffect;
  onSearchChange?: SideEffect;
  onSearchClick?: SideEffect;
  perPage: any;
  queryOptions?: { [key: string]: any };
  searchValue?: string;
  title: string;
  isDevicesHeader?: boolean;
}

const ManageFilterHeader = (props: Props) => {
  const {
    buttonText,
    onAddClick,
    onPerPageChange,
    onQueryChange,
    onSearchChange,
    onSearchClick,
    perPage,
    queryOptions,
    searchValue,
    title,
    isDevicesHeader,
  } = props;

  const renderPlaceholder = () => {
    return isDevicesHeader ? 'Search by serial number' : 'Search';
  }

  return (
    <div className="manage-filter-header">
      <div className="columns is-mobile manage-filter-header__header-wrapper">
        <div className="column is-6-mobile is-3-tablet is-4-dektop">
          <h2>{title}</h2>
        </div>

        {onSearchChange && (
          <div className="column is-hidden-mobile is-5-tablet">
            <InputIcon
              value={searchValue}
              onChange={onSearchChange}
              placeholder={renderPlaceholder()}
              onClick={() => onSearchClick('search', searchValue)}
              onPressEnter={() => onSearchClick('search', searchValue)}
            />
          </div>
        )}

        <div className="column is-6-mobile is-3-tablet manage-filter-header__button-wrapper">
          <Button color="secondary" onClick={onAddClick}>
            {buttonText}
          </Button>
        </div>
      </div>

      {onSearchChange && (
        <div className="columns is-hidden-tablet">
          <div className="column is-12-mobile">
            <InputIcon
              value={searchValue}
              onChange={onSearchChange}
              placeholder={renderPlaceholder()}
              onClick={() => onSearchClick('search', searchValue)}
              onPressEnter={() => onSearchClick('search', searchValue)}
            />
          </div>
        </div>
      )}

      <div className="columns manage-filter-header__dropdown-wrapper">
        {queryOptions && !isDevicesHeader && (
          <div className="column is-6-mobile is-3-tablet is-2-desktop">
            <SelectGroup
              label="Status"
              options={statusFilterOptions}
              value={queryOptions.patient_id}
              onChange={changedValue => onQueryChange('status', changedValue)}
              placeholder="Select Status"
            />
          </div>
        )}

        {queryOptions && isDevicesHeader && (
          <div className="column is-6-mobile is-3-tablet is-2-desktop">
            <SelectGroup
              label="Status"
              options={orphanDeviceStatusFilterOptions}
              value={queryOptions.orphan_status}
              onChange={changedValue =>
                onQueryChange('orphan_status', changedValue)
              }
              placeholder="Select orphan/non-orphan status"
            />
          </div>
        )}

        <div className="column is-6-mobile is-3-tablet is-2-desktop">
          <SelectGroup
            label="Show"
            options={perPageOptions}
            value={perPage}
            onChange={changedValue => onPerPageChange(changedValue)}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageFilterHeader;
