import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import Icon from '@/components/icon/Icon';

interface Props {
  children: ReactNode;
  isDisabled?: boolean;
  isHidden?: boolean;
  to: string;
}

export const SidebarLink = (props: Props) => {
  const { children, isDisabled, isHidden, to } = props;

  if (isHidden) {
    return null;
  }

  const renderContent = () => {
    return (
      <>
        <span>{children}</span>

        <div className="display-active">
          <Icon icon="caretWhite" />
        </div>

        <div className="display-inactive">
          <Icon icon="caretRight" />
        </div>
      </>
    );
  };

  if (isDisabled) {
    return <div className="sidebar__link is-disabled">{renderContent()}</div>;
  }

  return (
    <NavLink activeClassName="is-active" className="sidebar__link" to={to}>
      {renderContent()}
    </NavLink>
  );
};

export default SidebarLink;
